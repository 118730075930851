import gql from "graphql-tag";

export const POINT_OF_SALE_ORDER_FIELD_VALUES = gql`
  query getPointOfSaleOrderFieldValues($cartId: ID!) {
    pointOfSaleOrderFieldValues(id: $cartId) {
      pointOfSaleFieldKey
      value
    }
  }
`;
