import React, { FunctionComponent } from "react";
import { Elements as StripeElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CheckoutPage, CheckoutStep } from "./CheckoutPage";
import { useCheckoutContext } from "../../providers/CheckoutProvider";
import { BookerIntention } from "../Order/BookingPage";
import { BookingDetailForm } from "../Order/BookingDetailForm";
import { hasWonCart, WonCartAlert } from "../../components/WonCartAlert";
import { useWeblinkSettings } from "../../hooks/useWeblinkSettings";
import { Stripe } from "../../generated/weblinkTypes";
import { useScript } from "../../hooks/useScript";

const STRIPE_API_VERSION = "2023-10-16";

export const BookingDetails: FunctionComponent = () => {
  const { viewer, refetch: refetchViewer } = useCheckoutContext();
  const weblinkSettings = useWeblinkSettings();

  const cart = viewer?.lastCart ?? null;

  const wonCart = hasWonCart(cart);

  const stripePaymentProvider = weblinkSettings.stripePaymentMethod;

  const stripePublicKey = stripePaymentProvider
    ? (stripePaymentProvider as Stripe).publicKey
    : null;

  const stripePromise = stripePublicKey
    ? loadStripe(stripePublicKey, { apiVersion: STRIPE_API_VERSION })
    : null;

  useScript(weblinkSettings.convergeJSUrl);
  useScript(weblinkSettings.converge3DSUrl);

  return (
    <CheckoutPage step={CheckoutStep.BookingDetails}>
      <StripeElements stripe={stripePromise}>
        {cart && cart.id && viewer?.contact?.account && (
          <BookingDetailForm
            account={viewer?.contact?.account}
            cart={cart}
            wonCart={wonCart}
            bookerIntention={BookerIntention.Self}
            usage="checkout"
            refetchViewer={refetchViewer}
          />
        )}
      </StripeElements>
      <WonCartAlert cart={cart} />
    </CheckoutPage>
  );
};
