import gql from "graphql-tag";

export const FULFIL_OBJECTIVE = gql`
  mutation fulfilObjective(
    $eventId: ID!
    $objectiveId: ID!
    $learningPathRegistrationId: ID!
  ) {
    registerable {
      fulfilObjective(
        input: {
          eventId: $eventId
          objectiveId: $objectiveId
          learningPathRegistrationId: $learningPathRegistrationId
        }
      ) {
        registerable {
          id
          contactId
          title
        }
        errors {
          label
          value
          message
        }
      }
    }
  }
`;
