import { useEffect, useState } from "react";
import { QueryHookOptions } from "@apollo/react-hooks";
import { useUpdateCartFinancialUnit } from "./useUpdateCartFinancialUnit";
import { Cart } from "../generated/weblinkTypes";
import { useWeblinkSettings } from "./useWeblinkSettings";

export const useViewersLastCart = (options?: QueryHookOptions) => {
  const [cart, setCart] = useState<Cart | null>(null);

  const {
    cart: queriedCart,
    loading,
    refetch,
    networkStatus,
    updateCartFinancialUnit,
  } = useUpdateCartFinancialUnit(options);

  const { currencyCode } = useWeblinkSettings();

  const cartId = cart?.id;
  const isCartInTokens = !!cart?.tokenType;

  useEffect(() => {
    if (!loading && queriedCart) {
      setCart(queriedCart);
    }
  }, [queriedCart, loading]);

  // Inside this effect, if the Cart is priced in Training Tokens we switch it back to Currency.
  // This is so that if the Cart gets switched to Training Tokens in the Checkout and we navigate
  // away from the Checkout to somewhere like the Catalog, we can still add items to the Cart as
  // we don't currently support Token prices in the Catalog.
  useEffect(() => {
    const switchCartFinancialUnit = async () => {
      if (cartId && isCartInTokens) {
        const { data } = await updateCartFinancialUnit({
          variables: {
            input: { cartId, financialUnitId: currencyCode },
          },
        });

        const updatedCart = data?.cart?.updateFinancialUnit.cart ?? null;
        setCart(updatedCart);
      }
    };
    switchCartFinancialUnit();
  }, [cartId, isCartInTokens, currencyCode, updateCartFinancialUnit, refetch]);

  return {
    cart,
    loading,
    refetch,
    networkStatus,
  };
};
