import gql from "graphql-tag";
import {
  PAGE_INFO_FRAGMENT,
  EVENT_FRAGMENT,
  CANCELLATION_REQUEST_FRAGMENT,
  ERROR_FRAGMENT,
} from "./fragments";

export const CANCELLATION_REASONS_QUERY = gql`
  query cancellationReasons {
    cancellationReasons {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          id
          text
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

export const CANCELLATION_REQUEST_MUTATION = gql`
  mutation cancellationRequest(
    $learnerId: ID
    $reasonId: ID
    $reasonNotes: String
  ) {
    cancellationRequest {
      create(
        input: {
          learnerId: $learnerId
          reasonId: $reasonId
          reasonNotes: $reasonNotes
        }
      ) {
        cancellationRequest {
          ...CancellationRequest
        }
        errors {
          ...Error
        }
      }
    }
  }
  ${CANCELLATION_REQUEST_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

export const CANCELLATION_REQUESTS_QUERY = gql`
  query cancellationRequests(
    $first: Int
    $offset: Int
    $filters: [CancellationRequestFieldFilter]
  ) {
    cancellationRequests(first: $first, offset: $offset, filters: $filters) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          ...CancellationRequest
          event {
            ...Event
          }
        }
      }
    }
  }
  ${CANCELLATION_REQUEST_FRAGMENT}
  ${EVENT_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;
