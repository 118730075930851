import React, { useState, useEffect, FunctionComponent } from "react";
import {
  FutureArticleHeader,
  FutureSelectFilter,
  FilterList,
  Button,
} from "@administrate/piston-ux";
import { useFormValues } from "@administrate/piston-ux/lib/Form";
import { observe } from "mobx";
import { useHistory } from "../../useHistory";
import {
  EventBooking,
  LearningPathBooking,
} from "../../generated/weblinkTypes";
import { useTranslation } from "react-i18next";

export type BookingLearnersFilters = {
  search: string;
  learnerStatus?: { value: "active" | "cancelled" };
  learnerType?: { value: "named" | "unnamed" };
};

export const BookingLearnersHeader: FunctionComponent<{
  booking: EventBooking | LearningPathBooking;
  type: "Event" | "LearningPath";
  loading: boolean;
  onChange: (args: BookingLearnersFilters) => void;
  canAddMoreLearners: () => boolean;
}> = ({ type, loading, onChange, canAddMoreLearners, booking }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isEvent = type === "Event";

  const [search, setSearch] = useState("");
  const values = useFormValues({
    learnerStatus: undefined,
    learnerType: undefined,
  });

  observe(values, change => {
    onChange({
      search,
      learnerStatus: change.object["learnerStatus"],
      learnerType: change.object["learnerType"],
    });
  });

  useEffect(() => {
    onChange({
      search,
      learnerStatus: values.learnerStatus,
      learnerType: values.learnerType,
    });
  }, [onChange, search, values.learnerStatus, values.learnerType]);

  return (
    <FutureArticleHeader
      loading={loading}
      title={t("learners")}
      search={{
        label: t("learner"),
        onChange: (value: string) => setSearch(value),
      }}
      filters={{ values }}
      extra={
        canAddMoreLearners() && (
          <Button
            type="primary"
            label={t("addLearner")}
            onClick={() =>
              history.push({
                pathname: `/learner-management/bookings/${booking.id}/${
                  isEvent ? "event" : "learning-path"
                }/addLearner`,
                state: { booking },
              })
            }
            id="booking__add-learner"
          />
        )
      }
      id="booking-header"
    >
      <FutureSelectFilter
        name="learnerStatus"
        label={t("status")}
        direction="right"
        options={[
          { label: t("active"), value: "active" },
          { label: t("cancelled"), value: "cancelled" },
        ]}
        id="booking__status-filter"
      />
      {(isEvent &&
        ((
          <React.Fragment>
            <FutureSelectFilter
              name="learnerType"
              label={t("learnerType")}
              direction="right"
              options={[
                { label: t("named"), value: "named" },
                { label: t("unnamed"), value: "unnamed" },
              ]}
              id="booking__learner-type-filter"
            />
          </React.Fragment>
        ))) as FilterList}
      {/* TODO: Fix the above in piston-ux, we can't conditionally render anything in FutureArticleHeader without casting */}
    </FutureArticleHeader>
  );
};
