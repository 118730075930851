import {
  AccountAssociation,
  Cart,
  CartLineItem,
  Category,
  Course,
  Event,
  EventFieldFilter,
  LearningPath,
  LearningTag,
} from "../generated/weblinkTypes";
import { PaymentType } from "../pages/Order/BookingDetailForm";
import { getEncodedType } from "../utils/convertID";
import { extractNodes } from "../utils/extractNodes";

enum AnalyticsEvents {
  PAGE_VIEW = "page_view",
  PRODUCT_VIEW = "view_item",
  SEARCH = "search",
  CART_ADD = "add_to_cart",
  CART_REMOVE = "remove_from_cart",
  CART_VIEW = "view_cart",
  CHECKOUT_BEGIN = "begin_checkout",
  CHECKOUT_BOOKER_DETAILS_SUBMITTED = "add_shipping_info",
  CHECKOUT_LEARNER_DETAILS_SUBMITTED = "add_learner_info",
  CHECKOUT_PAYMENT_DETAILS_SUBMITTED = "add_payment_info",
  CHECKOUT_COMPLETE = "purchase",
}

enum SearchType {
  CATALOGUE = "catalogue",
  EVENTS = "events",
  LEARNING_PATH_OBJECTIVES = "learning_path_objectives",
}

enum PaymentTypes {
  CARD = "card",
  INVOICE = "invoice",
  TRAINING_TOKEN = "training_token",
}

interface SearchFilter {
  field: string;
  operation: string;
  value?: string | null;
  values?: string[];
}
interface EcommerceItem {
  item_id: string | null;
  item_variant: string | null;
  item_name: string | null;
  item_category: string | null;
  item_category2: string | null;
  item_category3: string | null;
  item_category4: string | null;
  item_category5: string | null;
  price_level?: string | null;
  price: Number;
  tax?: Number;
  quantity?: Number;
  code: string | null;
  location_id?: string | null;
  location_name?: string | null;
  start_datetime?: string | null;
  learning_tags: string[];
  account_associations: { name: string; type: string }[];
  is_featured: boolean;
}

interface EcommerceDetails {
  currency?: string | null;
  value?: Number;
  items?: EcommerceItem[];
  search_type?: SearchType;
  search_term?: string | null;
  filters?: SearchFilter[];
  result_count?: Number;
  transaction_id?: string;
  payment_type?: PaymentTypes | null;
}
export abstract class BaseEvent {
  event: AnalyticsEvents;
  user_id: String | null;
  page_location: String | null;
  page_title: String | null;
  language: String | null;
  ecommerce: EcommerceDetails | undefined;

  constructor(analyticsEvent: AnalyticsEvents) {
    this.event = analyticsEvent;
    this.user_id = null;
    this.page_location = null;
    this.page_title = null;
    this.language = null;
  }
}

export class PageViewEvent extends BaseEvent {
  constructor() {
    super(AnalyticsEvents.PAGE_VIEW);
  }
}

export class SearchEvent extends BaseEvent {
  constructor(
    searchType: SearchType,
    resultCount: Number,
    filters?: SearchFilter[] | null,
    searchTerm?: string,
  ) {
    super(AnalyticsEvents.SEARCH);
    this.ecommerce = {
      search_type: searchType,
      search_term: searchTerm ?? null,
      filters: filters ?? [],
      result_count: resultCount,
    };
  }

  static fromCatalogueSearch(
    resultCount: Number,
    searchTerm: string,
    filters: SearchFilter[],
  ) {
    return new SearchEvent(
      SearchType.CATALOGUE,
      resultCount,
      filters,
      searchTerm,
    );
  }

  static fromEventsSearch(resultCount: Number, filters: SearchFilter[]) {
    return new SearchEvent(SearchType.EVENTS, resultCount, filters);
  }
  static fromLearningPathObjectivesSearch(
    resultCount: Number,
    filters: EventFieldFilter[],
  ) {
    const strippedFilters = filters.map(({ values, ...rest }) => ({
      ...rest,
      values: values?.filter(value => !!value),
    }));
    return new SearchEvent(
      SearchType.LEARNING_PATH_OBJECTIVES,
      resultCount,
      strippedFilters,
    );
  }
}

export class ProductViewEvent extends BaseEvent {
  constructor(product: Course | LearningPath, itemVariant: string) {
    super(AnalyticsEvents.PRODUCT_VIEW);
    this.ecommerce = getEcommercePayloadFromProduct(product, itemVariant);
  }

  static fromCourseView(course: Course) {
    return new ProductViewEvent(course, "course");
  }

  static fromLearningPathView(path: LearningPath) {
    return new ProductViewEvent(path, "learningPath");
  }
}

function getEcommercePayloadFromProduct(
  product: Course | LearningPath,
  itemVariant: string,
) {
  const commonItemPayload = {
    item_id: product.id,
    item_name: product.name,
    item_variant: itemVariant,
    code: product.code ?? null,
    is_featured: product.isFeatured,
    ...mapCategories(product.categories),
    learning_tags: mapLearningTags(extractNodes(product.learningTags.edges)),
    account_associations: mapAccountAssociations(
      extractNodes(product.accountAssociations.edges),
    ),
  };

  switch (itemVariant) {
    case "course": {
      const { priceRange } = product as Course;
      const amount = Number(priceRange?.normalPrice?.amount);
      return {
        currency: priceRange?.normalPrice?.financialUnit?.code,
        value: amount,
        items: [
          {
            ...commonItemPayload,
            price: amount,
          },
        ],
      };
    }

    case "learningPath": {
      const { price } = product as LearningPath;
      const amount = Number(price?.amount);
      return {
        currency: price?.financialUnit?.code,
        value: amount,
        items: [
          {
            ...commonItemPayload,
            price: amount,
          },
        ],
      };
    }

    default:
      throw new Error("Unsupported product");
  }
}

export class CartEvent extends BaseEvent {
  constructor(
    eventType: AnalyticsEvents,
    cart: Cart,
    item?: CartLineItem,
    paymentType?: PaymentTypes | null,
  ) {
    super(eventType);
    this.ecommerce = getEcommercePayloadFromCart(cart, item, paymentType);
  }

  static fromCartAdd(cart: Cart, productOptionId: string) {
    const newItem =
      cart?.items?.find(item => item?.productOption?.id === productOptionId) ??
      null;
    if (!newItem) {
      return null;
    }
    return new CartEvent(AnalyticsEvents.CART_ADD, cart, newItem);
  }
  static fromCartRemove(cart: Cart, removedItem: CartLineItem) {
    return new CartEvent(AnalyticsEvents.CART_REMOVE, cart, removedItem);
  }
  static fromCartView(cart: Cart) {
    return new CartEvent(AnalyticsEvents.CART_VIEW, cart);
  }

  static fromCheckoutBegin(cart: Cart) {
    return new CartEvent(AnalyticsEvents.CHECKOUT_BEGIN, cart);
  }
  static fromBookerDetailsSubmitted(cart: Cart) {
    return new CartEvent(
      AnalyticsEvents.CHECKOUT_BOOKER_DETAILS_SUBMITTED,
      cart,
    );
  }
  static fromLearnerDetailsSubmitted(cart: Cart) {
    return new CartEvent(
      AnalyticsEvents.CHECKOUT_LEARNER_DETAILS_SUBMITTED,
      cart,
    );
  }
  static fromPaymentDetailsSubmitted(cart: Cart, paymentType: PaymentType) {
    if (!paymentType) return null;
    return new CartEvent(
      AnalyticsEvents.CHECKOUT_PAYMENT_DETAILS_SUBMITTED,
      cart,
      undefined,
      PAYMENT_PROVIDER_TO_TYPE_MAP[paymentType],
    );
  }
  static fromCheckoutComplete(cart: Cart, paymentType: PaymentType) {
    const paymentMethod = paymentType
      ? PAYMENT_PROVIDER_TO_TYPE_MAP[paymentType]
      : null;
    return new CartEvent(
      AnalyticsEvents.CHECKOUT_COMPLETE,
      cart,
      undefined,
      paymentMethod,
    );
  }
}

const PAYMENT_PROVIDER_TO_TYPE_MAP = {
  stripe: PaymentTypes.CARD,
  converge: PaymentTypes.CARD,
  invoice: PaymentTypes.INVOICE,
  trainingToken: PaymentTypes.TRAINING_TOKEN,
};

function getEcommercePayloadFromCart(
  cart: Cart,
  cartItem?: CartLineItem,
  paymentType?: PaymentTypes | null,
) {
  return {
    transaction_id: cart.id ?? undefined,
    payment_type: paymentType,
    currency: cart.currency?.code,
    subtotal: Number(cart.price?.subTotal || 0),
    discount: Number(cart.price?.discountTotal || 0),
    value: Number(cart.price?.grandTotal || 0),
    tax: Number(
      cart.price?.taxes.reduce(
        (runningTotal, cartTax) =>
          runningTotal + Number(cartTax?.totalAmount ?? 0),
        0,
      ) ?? 0,
    ),
    coupon: cart.promotionalCode ?? null,
    items: cartItem
      ? [getEcommerceItemPayloadFromCartItem(cartItem)]
      : cart.items?.map(i =>
          getEcommerceItemPayloadFromCartItem(i as CartLineItem),
        ) ?? [],
  };
}
function getEcommerceItemPayloadFromCartItem(cartItem: CartLineItem) {
  const { productOption } = cartItem;
  const itemCommonFields = {
    item_id: productOption?.id ?? null,
    item_name: productOption?.name ?? null,
    item_variant: null,
    code: productOption?.code ?? null,
    ...mapCategories(productOption?.categories ?? []),
    price_level: cartItem.priceLevel?.name,
    price: Number(cartItem.unitAmount ?? 0),
    tax: Number(cartItem.totalTaxAmount ?? 0),
    quantity: Number(cartItem.quantity ?? 0),
    location_id: null,
    location_name: null,
    start_datetime: null,
    is_featured: false,
    learning_tags: [],
    account_associations: [],
  };

  // TODO @tomdodo - given our current codegen version doesn't let us access .__typename on an interface,
  //   is this really the best way to get the real implementing type?
  const encodedType = getEncodedType(productOption?.id);

  switch (encodedType) {
    case "Course":
      const { location, course, start } = productOption as Event;
      return {
        ...itemCommonFields,
        item_variant: "event",
        location_id: location.id,
        location_name: location.name,
        start_datetime: start,
        is_featured: course?.isFeatured ?? false,
        learning_tags: mapLearningTags(
          extractNodes(course?.learningTags.edges ?? []),
        ),
        account_associations: mapAccountAssociations(
          extractNodes(course?.accountAssociations.edges ?? []),
        ),
      };
    case "LearningPath":
      const { isFeatured, learningTags, accountAssociations } =
        productOption as LearningPath;
      return {
        ...itemCommonFields,
        item_variant: "learningPath",
        is_featured: isFeatured,
        learning_tags: mapLearningTags(extractNodes(learningTags.edges)),
        account_associations: mapAccountAssociations(
          extractNodes(accountAssociations.edges),
        ),
      };
    default:
      return itemCommonFields;
  }
}

function mapAccountAssociations(accountAssociations: AccountAssociation[]) {
  return (
    accountAssociations?.map(({ account, associationType }) => ({
      name: account.name,
      type: associationType.name,
    })) ?? []
  );
}

function mapLearningTags(learningTags: LearningTag[]) {
  return learningTags?.map(({ name }) => name) ?? [];
}

function mapCategories(categories: Category[]) {
  return {
    item_category: categories?.[0]?.name ?? null,
    item_category2: categories?.[1]?.name ?? null,
    item_category3: categories?.[2]?.name ?? null,
    item_category4: categories?.[3]?.name ?? null,
    item_category5: categories?.[4]?.name ?? null,
  };
}
