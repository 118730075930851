import { QueryHookOptions } from "@apollo/react-hooks";
import { Mutation, Query } from "../generated/weblinkTypes";
import { GET_VIEWERS_LAST_CART, UPDATE_FINANCIAL_UNIT } from "../queries/cart";
import { useCartRemovedLineItemsContext } from "../providers/CartRemovedLineItemsProvider";
import { useWebLinkMutation, useWebLinkQuery } from "./weblink";

export const useUpdateCartFinancialUnit = (options?: QueryHookOptions) => {
  const {
    data,
    loading: cartLoading,
    refetch,
    networkStatus,
  } = useWebLinkQuery<Query>(GET_VIEWERS_LAST_CART, options);

  const { setRemovedLineItems } = useCartRemovedLineItemsContext();

  const [updateCartFinancialUnit, { loading: updateCartFinancialUnitLoading }] =
    useWebLinkMutation<Mutation>(UPDATE_FINANCIAL_UNIT, {
      onCompleted: data =>
        setRemovedLineItems(
          data.cart?.updateFinancialUnit.removedLineItems ?? [],
        ),
    });

  const cart = data?.viewer?.lastCart ?? null;

  return {
    cart,
    loading: cartLoading || updateCartFinancialUnitLoading,
    refetch,
    networkStatus,
    updateCartFinancialUnit,
  };
};
