import { Maybe } from "@administrate/piston-ux/lib/types";
import {
  WatchQueryOptions,
  OperationVariables,
  ApolloQueryResult,
  ApolloClient,
} from "apollo-boost";
import { useSetOverPageLoadingBarState } from "@administrate/piston-ux";

export type CancellableQueryResult<T> = {
  result: Promise<ApolloQueryResult<T>>;
  cancel: () => void;
};

export type CancellableClient = {
  query: <T = any, TVariables = OperationVariables>(
    options: WatchQueryOptions<TVariables>,
  ) => CancellableQueryResult<T>;
};

export function useCancellableClient(
  client: ApolloClient<any>,
): CancellableClient {
  // The Apollo Link definied in GraphQLProvider picks up that a network request has started, but doesn't get any
  // notification on cancellation. So, we need to reset the page load bar ourselves.
  const setPageIsLoading = useSetOverPageLoadingBarState();

  function query<T = any, TVariables = OperationVariables>(
    options: WatchQueryOptions<TVariables>,
  ) {
    let subscription: Maybe<ZenObservable.Subscription> = null;

    const observableQuery = client.watchQuery(options);

    const result = new Promise<ApolloQueryResult<T>>((resolve, reject) => {
      subscription = observableQuery.subscribe(
        response => resolve(response),
        error => reject(error),
      );
    });

    return {
      result,
      cancel: () => {
        if (subscription) {
          // This will implicitly cancel the in-flight query
          // See: https://github.com/apollographql/apollo-client/issues/4150#issuecomment-500127694
          subscription.unsubscribe();
          setPageIsLoading(false);
        }
      },
    };
  }

  return { query };
}
