import React, { FunctionComponent } from "react";
import { Typeahead } from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { InputValidator } from "@administrate/piston-ux/lib/withFormWrapper";

import { AccountNode } from "../types/LearnerManagement";
import { getUniqueId } from "../utils/displayHelpers";
import { AccountWithAncestors } from "./Ancestors";

type AccountPickerType = {
  name: string;
  valid?: InputValidator;
  loadOptions(inputValue: string): Promise<any>;
  label?: string;
  onChange?: (newValue: any, oldValue: any) => void;
};

export const AccountPicker: FunctionComponent<AccountPickerType> = ({
  name,
  valid,
  loadOptions,
  label,
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <div className="account-hierarchy">
      <Typeahead
        label={label || t("account")}
        name={name}
        loadOptions={loadOptions}
        formatter={(account: AccountNode) => AccountWithAncestors(account)}
        valid={valid}
        onChange={onChange}
        uniqueId={getUniqueId()}
      />
    </div>
  );
};

export const getAccountVariables = (inputValue: string) => ({
  filters: [
    { field: "name", operation: "like", value: `%${inputValue}%` },
    { field: "name", operation: "ne", value: "" },
  ],
});
