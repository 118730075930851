import { ensureObservableValue } from "@administrate/piston-ux/lib/withFormWrapper";
import { get } from "lodash";

import {
  CartLineItem,
  Contact,
  LearningPath,
  Maybe,
  PointOfSaleLearnerFieldDefinition,
  Event as EventType,
} from "../generated/weblinkTypes";
import { LearnerType } from "../pages/Catalog/LearnerSelection";
import { CheckoutURLS } from "../providers/CheckoutProvider";
import { mapPreExistingContactValues } from "./bookingHelpers";

export const checkHasBookingDetails = (
  items: Maybe<Maybe<CartLineItem>[]> | undefined,
  hasRemovedLineItems: boolean,
): boolean => {
  const itemsUnitAmount = items?.reduce((acc, item) => {
    return acc + parseInt(item?.unitAmount, 10);
  }, 0);
  return !!itemsUnitAmount || hasRemovedLineItems;
};

export const getCheckoutRoute = (
  action: "onCheckoutLoad" | "onBookerInformationSubmit",
  hasBookerInformation: boolean,
  hasLearnerDetails: boolean,
  hasBookingDetails: boolean,
): string | undefined => {
  let route: string | undefined = undefined;

  const orderCompletePage = `/checkout/${CheckoutURLS.OrderComplete}`;
  const bookingDetailsPage = `/checkout/${CheckoutURLS.BookingDetails}`;
  const learnerDetailsPage = `/checkout/${CheckoutURLS.LearnerDetails}`;

  if (action === "onCheckoutLoad") {
    if (!hasBookerInformation && !hasLearnerDetails && !hasBookingDetails) {
      route = orderCompletePage;
    }

    if (!hasBookerInformation && !hasLearnerDetails) {
      route = bookingDetailsPage;
    }

    if (!hasBookerInformation) {
      route = learnerDetailsPage;
    }
    return undefined;
  }

  if (action === "onBookerInformationSubmit") {
    if (!hasLearnerDetails) {
      route = bookingDetailsPage;
    } else {
      route = learnerDetailsPage;
    }
  }

  return route;
};

export const getPoSValues = (
  values: any,
  pointOfSaleFields: PointOfSaleLearnerFieldDefinition[],
  contact: Contact | null | undefined,
) => {
  values.learners.forEach((learner: LearnerType) => {
    pointOfSaleFields.forEach(field => {
      ensureObservableValue(learner.posFieldValues, field.key);
    });
  });

  mapPreExistingContactValues(pointOfSaleFields, values.learners[0], contact);
};

export const getProductOption = (
  item: Maybe<CartLineItem>,
): { event?: EventType; learningPath?: LearningPath } => {
  const productOption = get(item, "productOption") as EventType | LearningPath;

  let event;
  let learningPath;

  if (productOption.__typename === "LearningPath") {
    learningPath = productOption as LearningPath;
  }
  if (productOption.__typename === "Event") {
    event = productOption as EventType;
  }
  return {
    event,
    learningPath,
  };
};
