import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { TrainingPass, TrainingPassType } from "../../generated/lmsTypes";

interface CurrentPassesProps {
  learnerPasses: TrainingPass[];
}

const CurrentPasses: FunctionComponent<CurrentPassesProps> = ({
  learnerPasses,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <span>{t("currentPass")}:</span>
      {learnerPasses
        .filter(pass => pass.lifecycleState === "active")
        .map(pass => (
          <p className="my-1" key={pass.id ?? ""}>
            <strong>{pass.trainingPassTypeName}</strong>
          </p>
        ))}
    </>
  );
};

export interface TooltipContentProps {
  tooltipContext: {
    validPassHeld: boolean;
    anyActivePassHeld: boolean;
    isPassRequired: boolean;
    learnerPasses: TrainingPass[];
    contentPasses: TrainingPassType[];
  };
}

export const TooltipContent: FunctionComponent<TooltipContentProps> = ({
  tooltipContext: {
    validPassHeld,
    anyActivePassHeld,
    isPassRequired,
    learnerPasses,
    contentPasses,
  },
}) => {
  const { t } = useTranslation();

  if (validPassHeld) {
    return (
      <>
        <span>{t("instantAccessWith")}:</span>
        {contentPasses.map(pass => (
          <p className="my-1" key={pass.id ?? ""}>
            <strong>{pass.name}</strong>
          </p>
        ))}
      </>
    );
  }

  if (isPassRequired) {
    return (
      <>
        {anyActivePassHeld && <CurrentPasses learnerPasses={learnerPasses} />}
        <span>{t("requiredPass")}:</span>
        {contentPasses.map(pass => (
          <p className="my-1" key={pass.id ?? ""}>
            <strong>{pass.name}</strong>
          </p>
        ))}
      </>
    );
  }

  return (
    <>
      {anyActivePassHeld && <CurrentPasses learnerPasses={learnerPasses} />}
      <span>{t("qualifyingPass")}:</span>
      {contentPasses.map(pass => (
        <p className="my-1" key={pass.id ?? ""}>
          <strong>{pass.name}</strong>
        </p>
      ))}
    </>
  );
};
