import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { Location } from "../../generated/weblinkTypes";

export interface LearnerManagementBookingFilter {
  search: string;
  location?: Location;
  courseType?: string;
  dates?: { from: string; to?: string };
  fromDate?: string;
  toDate?: string;
}

export interface BookingFilterContextType {
  filters: LearnerManagementBookingFilter;
  setFilters: Dispatch<SetStateAction<LearnerManagementBookingFilter>>;
  setFilterValue: (
    key: keyof LearnerManagementBookingFilter,
    value: unknown,
  ) => void;
}

const BookingFilterContext = createContext({} as BookingFilterContextType);
const useBookingFilterContext = () => useContext(BookingFilterContext);

export function BookingFilterContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const emptyFilters = {
    search: "",
    location: undefined,
    courseType: undefined,
    dates: undefined,
    fromDate: undefined,
    toDate: undefined,
  };
  const [filters, setFilters] =
    useState<LearnerManagementBookingFilter>(emptyFilters);
  const setFilterValue = useCallback(
    (key, value) => {
      setFilters(prevState => ({ ...prevState, [key]: value }));
    },
    [setFilters],
  );

  return (
    <BookingFilterContext.Provider
      value={{
        filters,
        setFilters,
        setFilterValue,
      }}
    >
      {children}
    </BookingFilterContext.Provider>
  );
}

export function useLearnerManagementBookingFilters() {
  const context = useBookingFilterContext();
  if (context === undefined) {
    throw new Error(
      "useLearnerManagementBookingFilters must be used within a Learner Management Route",
    );
  }
  return context;
}
