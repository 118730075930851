import React, { Fragment, useState } from "react";
import { useWebLinkMutation, useWebLinkQuery } from "../../hooks/weblink";
import {
  REGISTER_LEARNERS_ONTO_PRIVATE_BOOKING_MUTATION,
  POINT_OF_SALE_LEARNER_FIELD_QUERY,
} from "../../queries/cart";
import {
  Header,
  Container,
  Card,
  Form,
  useFormValues,
  LoadingScreen,
  Submit,
  Button,
  Alert,
  List,
  ListRow,
  ListCell,
  SectionHeader,
  ListDrawer,
  Row,
  Col,
} from "@administrate/piston-ux";
import { SaveStates } from "@administrate/piston-ux/lib/types/detailTypes";
import { FormConsumer } from "@administrate/piston-ux/lib/Form";
import { Observer } from "mobx-react-lite";
import { ensureObservableValue } from "@administrate/piston-ux/lib/withFormWrapper";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

import {
  Query,
  PointOfSaleFieldEntityType,
  Contact,
} from "../../generated/weblinkTypes";
import { useHistory, useParams } from "../../useHistory";
import { ItemInformation } from "../../components/ItemInformation";
import { formatDateRange } from "../../utils/dateTimeHelpers";
import { useDateFormatter } from "../../hooks/useDateFormatter";
import { WEBLINK_BOOKING_BY_ID_QUERY } from "../../queries/bookings";
import { DEFAULT_EVENT } from "./ExistingEventBooking";
import {
  ContactPicker,
  getContactVariables,
} from "../../components/ContactPicker";
import { useLmsClient } from "../../hooks/lms";
import { COORDINATED_LEARNER_QUERY } from "../../queries/learnerManagement";
import { extractNodes } from "../../utils/extractNodes";
import { PointOfSaleLearnerFields } from "../../components/PointOfSaleLearnerFields";
import {
  mapPreExistingContactValues,
  hasErrors,
} from "../../utils/bookingHelpers";
import { FormLearner } from "./BookingLearnersForm";
import { mapPointOfSaleFieldValues } from "../../utils/customFieldValues";

const UNNAMED_LEARNER_CONTACT = {
  id: "unnamed",
  firstName: "Unnamed",
  lastName: "Learner",
};

export const AddLearnerToEventBooking: React.FunctionComponent<{}> = () => {
  const [saveState, setSaveState] = useState<SaveStates>(null);
  const { t } = useTranslation();
  const { bookingId } = useParams<{bookingId:string}>();
  const history = useHistory();

  const { dateFormat } = useDateFormatter({ showTime: true });

  const [registerLearnersOntoPrivateBooking] = useWebLinkMutation(
    REGISTER_LEARNERS_ONTO_PRIVATE_BOOKING_MUTATION,
  );

  const client = useLmsClient();
  const contacts = (inputValue: string) =>
    client
      .query({
        query: COORDINATED_LEARNER_QUERY,
        variables: getContactVariables(inputValue),
      })
      .then(result => {
        return [
          UNNAMED_LEARNER_CONTACT,
          ...extractNodes(result.data.coordinatorManagedContacts.edges),
        ];
      });
  const learner = useFormValues({
    contact: null,
    posFieldValues: {},
  });

  const { data: pointOfSaleFieldsData, loading: pointOfSaleFieldsLoading } =
    useWebLinkQuery<Query>(POINT_OF_SALE_LEARNER_FIELD_QUERY, {
      variables: {
        entityType: PointOfSaleFieldEntityType.CartLineItemEventLearner,
      },
    });
  const pointOfSaleFields =
    pointOfSaleFieldsData?.pointOfSaleLearnerFields ?? [];

  if (pointOfSaleFields.length > 0) {
    pointOfSaleFields.forEach(field => {
      ensureObservableValue(learner.posFieldValues, field.key);
    });
  }

  const { loading, error, data } = useWebLinkQuery<Query>(
    WEBLINK_BOOKING_BY_ID_QUERY,
    {
      variables: {
        bookingId,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    },
  );
  const booking =
    data?.viewer?.eventBookings?.edges?.[0]?.node || DEFAULT_EVENT;

  const handleSelectContact = (newChoice: Contact, _: Contact) => {
    if (newChoice !== null) {
      mapPreExistingContactValues(pointOfSaleFields, learner, newChoice);
    } else {
      learner.contact = null;
      learner.posFieldValues = {};
    }
  };

  const learnerToPost = (learner: FormLearner) => {
    return {
      contactId: learner.contact.id,
      attributes: mapPointOfSaleFieldValues(
        pointOfSaleFields,
        learner.posFieldValues,
      ),
    };
  };

  const handleSubmit = async () => {
    setSaveState("saving");
    const response = await registerLearnersOntoPrivateBooking({
      variables: {
        input: {
          bookingId,
          quantity: 1,
          learners: {
            existingLearners: learnerToPost(learner),
          },
        },
      },
    });

    if (response.errors && response.errors.length > 0) {
      console.error("error while registering learner", response.errors);
      return;
    }

    const resBookingId = get(
      response,
      "data.booking.registerLearnersOntoPrivateBooking.booking.id",
      null,
    );
    const mutationErrors = get(
      response,
      "data.booking.registerLearnersOntoPrivateBooking.errors",
      null,
    );

    if ((mutationErrors && mutationErrors.length > 0) || !resBookingId) {
      console.error(
        "Encountered an error while registering learner",
        resBookingId,
        bookingId,
        mutationErrors,
      );
      return;
    }

    history.push(`/learner-management/bookings/${booking.id}/event`);
  };

  if (loading || pointOfSaleFieldsLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <Alert type="error" message={t("thereWasAProblemFetchingThisBooking")} />
    );
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Fragment>
      <Header
        title={booking.name}
        usage="portal"
        back={{
          label: t("booking"),
          onClick: () =>
            history.push(`/learner-management/bookings/${bookingId}/event`),
        }}
      />
      <div className="result-page">
        <Container>
          <Row>
            <Col md={4}>
              <ItemInformation
                data={{
                  location: booking.location ? booking.location : undefined,
                  dateAndTime: booking.start
                    ? formatDateRange(
                        booking.start,
                        booking.end || "",
                        dateFormat,
                      )
                    : undefined,
                }}
                loading={loading}
                name={t("course")}
                image={booking.imageUrl}
              />
            </Col>
            <Col md={8}>
              <Card>
                <div className="detail-form">
                  <Form
                    type="raw"
                    values={learner}
                    onSubmit={handleSubmit}
                    disabled={saveState === "saving"}
                  >
                    <FormConsumer>
                      {({ values, isDirty, errors }) => (
                        <Observer>
                          {() => (
                            <Fragment>
                              <SectionHeader title={t("addLearner")}>
                                <Fragment>
                                  {!loading ? (
                                    <Submit
                                      disabled={!isDirty || hasErrors(errors)}
                                    />
                                  ) : (
                                    <Button
                                      type="primary"
                                      label={t("submit")}
                                      disabled
                                      onClick={() => null}
                                    />
                                  )}
                                </Fragment>
                              </SectionHeader>
                              <List>
                                <ListRow>
                                  <ListCell
                                    label={t("learnerInformation")}
                                    extraClass="learners--information"
                                  >
                                    <ContactPicker
                                      name={`contact`}
                                      loadOptions={contacts}
                                      onChange={(newChoice, oldChoice) =>
                                        handleSelectContact(
                                          newChoice,
                                          oldChoice,
                                        )
                                      }
                                      valid={v => !!v}
                                    />
                                  </ListCell>
                                  {pointOfSaleFields && (
                                    <ListDrawer
                                      title={t("learnerInformation")}
                                      open={true}
                                      display="hidden"
                                    >
                                      <PointOfSaleLearnerFields
                                        posFields={pointOfSaleFields}
                                        learnerNumber={1}
                                        disabled={!values.contact}
                                      />
                                    </ListDrawer>
                                  )}
                                </ListRow>
                              </List>
                            </Fragment>
                          )}
                        </Observer>
                      )}
                    </FormConsumer>
                  </Form>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};
