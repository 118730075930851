import moment from "moment";
import { TFunction } from "i18next";
import { formatLocalisedDateTime } from "./dateTimeHelpers";
import { Viewer } from "../generated/lmsTypes";

export const determinePassAccessStatus = (
  viewer: Viewer,
  registrationPassTypeId: string,
  translate: TFunction,
) => {
  const now = moment().tz(viewer.timeZoneName || moment.tz.guess());
  // evaluate when access may have ended, if possible
  const userPasses = viewer.trainingPasses || [];
  const passForRegistration = userPasses.find(
    pass => pass?.trainingPassTypeId === registrationPassTypeId,
  );
  const expiryMoment = moment(passForRegistration?.validUntil || undefined);
  if (passForRegistration && expiryMoment < now) {
    const trainingPassExpiry = formatLocalisedDateTime(
      passForRegistration.validUntil,
    );
    return translate("trainingPassExpiry", {
      expiry: trainingPassExpiry,
    });
  }
  return translate("trainingPassInvalid"); // content has likely been removed from the training pass type
};
