import React, { FunctionComponent } from "react";
import { ExportModal } from "../toPistonUX/ExportModal";
import { EventBooking, Learner } from "../generated/weblinkTypes";
import { Maybe } from "@administrate/piston-ux/lib/types";
import { WEBLINK_COURSE_BOOKINGS_EXPORT_QUERY } from "../queries/bookings";
import moment from "moment";
import { useCancellableClient } from "../hooks/useCancellableClient";
import { useWebLinkClient } from "../hooks/weblink";
import { useTranslation } from "react-i18next";

const EXCEL_COMPATIBLE_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

const formatOptionalDateTime = (dateTime: Maybe<string> | undefined): string =>
  dateTime
    ? moment(dateTime).utc().format(EXCEL_COMPATIBLE_DATETIME_FORMAT)
    : "";

type CsvFormat = {
  Title: string;
  "Start Date": string;
  "End Date": string;
  Location: string;
  Region: string;
  "Learner Name": string;
  "Learner Email": string;
  "Learner Account": string;
  "Registration No.": string;
};

function getLearnerDetails(learner: Learner) {
  const { contact } = learner;
  if (!contact) {
    return {
      learnerName: "Unknown",
      learnerEmail: "Unknown",
      learnerAccountName: "Unknown",
      learnerRegistrationNumber: learner.registration
        ? learner.registration.registrationNumber
        : "Unknown",
    };
  }

  const nameArray = [contact.firstName, contact.lastName].filter(s => s);

  const name = nameArray.join(" ") || "Unknown";
  const email = contact.emailAddress || "Unknown";
  const account = contact.account ? contact.account.name : "Unknown";
  const registrationNumber = learner.registration
    ? learner.registration.registrationNumber
    : "Unknown";

  return {
    learnerName: name,
    learnerEmail: email,
    learnerAccountName: account,
    learnerRegistrationNumber: registrationNumber,
  };
}

export const CourseBookingExport: FunctionComponent<{ id?: string }> = ({
  id,
}) => {
  const { t } = useTranslation();
  const client = useCancellableClient(useWebLinkClient());

  const fetchCourseBookings = (cursor: Maybe<string>) => {
    try {
      const query = client.query({
        query: WEBLINK_COURSE_BOOKINGS_EXPORT_QUERY,
        variables: {
          offset: 0,
          first: 100,
          after: cursor,
        },
        fetchPolicy: "network-only",
      });

      return {
        connection: query.result.then(
          response => response.data.viewer.eventBookings,
        ),
        cancel: query.cancel,
      };
    } catch (e) {
      console.error("error", e);
      throw e;
    }
  };

  const transformData = (eventBookings: EventBooking[]) => {
    const rows: CsvFormat[] = [];
    eventBookings.forEach(eventBooking => {
      eventBooking.learners.edges.forEach(({ node: learner }) => {
        const {
          learnerName,
          learnerEmail,
          learnerAccountName,
          learnerRegistrationNumber,
        } = getLearnerDetails(learner);

        rows.push({
          "Learner Name": learnerName,
          "Learner Email": learnerEmail,
          "Learner Account": learnerAccountName,
          Title: eventBooking.name,
          "Start Date": formatOptionalDateTime(eventBooking.start),
          "End Date": formatOptionalDateTime(eventBooking.end),
          Location: eventBooking.location
            ? eventBooking.location.name
            : "Unknown",
          Region:
            eventBooking.location && eventBooking.location.region
              ? eventBooking.location.region.name
              : "Unknown",
          "Registration No.": learnerRegistrationNumber,
        });
      });
    });

    return rows;
  };

  return (
    <ExportModal
      title={t("exportBookings")}
      filenamePrefix="Course_Booking"
      dataFetcher={fetchCourseBookings}
      transformData={transformData}
      csvKeys={[
        "Title",
        "Start Date",
        "End Date",
        "Location",
        "Region",
        "Learner Name",
        "Learner Email",
        "Learner Account",
        "Registration No.",
      ]}
      id={id}
    />
  );
};
