import React, { FunctionComponent, ReactNode } from "react";

import { OverlayTrigger, Tooltip as BSTooltip } from "react-bootstrap";

const getTooltipOverlay: FunctionComponent<any> = (tooltipID, content) => (
  <BSTooltip id={tooltipID}>{content}</BSTooltip>
);

type TooltipProps = {
  tooltipId: string;
  content: string;
  children: ReactNode;
};

export const Tooltip: FunctionComponent<TooltipProps> = ({
  tooltipId,
  content,
  children,
}) => {
  const tooltiper = getTooltipOverlay(tooltipId, content);
  return (
    <OverlayTrigger placement="top" overlay={tooltiper}>
      {children}
    </OverlayTrigger>
  );
};

type TooltipTextProps = {
  tooltipId: string;
  content: string;
  text: string;
};

export const TooltipText: FunctionComponent<TooltipTextProps> = ({
  tooltipId,
  content,
  text,
}) => {
  return (
    <Tooltip content={content} tooltipId={tooltipId}>
      <span style={{ borderBottom: "1px dashed #484848" }}>{text}</span>
    </Tooltip>
  );
};
