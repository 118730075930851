import {
  Card,
  FormStateStatus,
  FutureArticleHeader,
  Placeholder,
  SaveStates,
  Submit,
  useDetailFormState,
  ValidationErrors,
} from "@administrate/piston-ux";
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import {
  LearningPath,
  Event as EventType,
  PointOfSaleLearnerFieldDefinition,
  Contact,
  CartLineItem,
  Maybe,
  Query,
  PointOfSaleFieldEntityType,
  PointOfSaleFieldFilter,
  PointOfSaleField,
  FilterOperation,
} from "../../generated/weblinkTypes";
import { CheckoutPage, CheckoutStep } from "./CheckoutPage";
import {
  Form,
  FormActions,
  FormArray,
  useFormValues,
} from "@administrate/piston-ux/lib/Form";
import { MessageOptions } from "@administrate/piston-ux/lib/utils/FormStateStatus";
import { LearnerType } from "../Catalog/LearnerSelection";
import { PointOfSaleLearnerFields } from "../../components/PointOfSaleLearnerFields";
import {
  CheckoutURLS,
  useCheckoutContext,
} from "../../providers/CheckoutProvider";
import { getLocationRegionFormat } from "../../utils/displayHelpers";
import { useDateFormatter } from "../../hooks/useDateFormatter";
import { formatDateRange } from "../../utils/dateTimeHelpers";
import { useWebLinkMutation, useWebLinkQuery } from "../../hooks/weblink";
import {
  POINT_OF_SALE_LEARNER_FIELD_QUERY,
  UPDATE_EVENT_CART_LINE_ITEM_MUTATION,
  UPDATE_PATH_CART_LINE_ITEM_MUTATION,
} from "../../queries/cart";
import { useHistory } from "../../useHistory";
import { WonCartAlert } from "../../components/WonCartAlert";
import { getPoSValues, getProductOption } from "../../utils/checkoutHelpers";
import { usePlaceOrder } from "../../hooks/usePlaceOrder";
import { mapPointOfSaleFieldValues } from "../../utils/customFieldValues";
import { useAnalytics } from "../../providers/AnalyticsProvider";
import { CartEvent } from "../../analytics/events";

type LearnerDetailsType = {
  productType?: string | undefined;
  interestId: string;
  learners: LearnerType[];
};

export const LearnerDetails: FunctionComponent = () => {
  const { viewer, hasBookingDetails } = useCheckoutContext();
  const { t } = useTranslation();
  const history = useHistory();
  const { captureEvent } = useAnalytics();

  const cart = viewer?.lastCart;
  const {
    messages,
    saveState,
    setSaveState,
    setMessages,
    reset: resetFormState,
  } = useDetailFormState();

  const [
    updateEventLineItem,
    { loading: mutationEventLoading, error: mutationEventError },
  ] = useWebLinkMutation(UPDATE_EVENT_CART_LINE_ITEM_MUTATION);

  const [
    updatePathLineItem,
    { loading: mutationPathLoading, error: mutationPathError },
  ] = useWebLinkMutation(UPDATE_PATH_CART_LINE_ITEM_MUTATION);

  const {
    loading: placeOrderLoading,
    submit: placeOrder,
    mutationError: placeOrderMutationError,
    validationErrors: placeOrderValidationErrors,
  } = usePlaceOrder(cart?.id);

  const [currentItem, setCurrentItem] =
    useState<Maybe<CartLineItem>[] | undefined>();
  const [itemIndex, setItemIndex] = useState(0);

  let itemNumber = itemIndex + 1;
  let nextItemNumber = itemIndex + 2;
  const totalItems = cart?.items?.length || 0;

  const getItem = useCallback(
    (index: number): Maybe<CartLineItem>[] | undefined => {
      setItemIndex(index);
      return cart?.items?.filter((_, i) => i === index);
    },
    [cart],
  );

  const handleSubmit = async (
    result: LearnerDetailsType,
    pointOfSaleFields: PointOfSaleLearnerFieldDefinition[],
  ) => {
    if (!result) {
      setMessages({ failed: "Failed" });
      return;
    }
    setSaveState("saving");
    if (result) {
      const input = {
        cartId: cart?.id,
        interestId: result.interestId,
        learnerDetails: getLearnerDetails(
          pointOfSaleFields,
          result,
          viewer?.contact,
        ),
        quantity: 1,
      };
      let validationErrors;
      if (result.productType === "Event") {
        const response = await updateEventLineItem({
          variables: {
            input,
          },
        });

        validationErrors = response.data.cart.errors;
      }

      if (result.productType === "LearningPath") {
        const response = await updatePathLineItem({
          variables: {
            input,
          },
        });

        validationErrors = response.data.cart.errors;
      }
      if (!mutationEventLoading || !mutationPathLoading) {
        const mutationError = mutationEventError || mutationPathError;
        if (mutationError) {
          setMessages({ failed: mutationError.message });
        } else if (validationErrors && validationErrors.length > 0) {
          setMessages({
            errors: (
              <ValidationErrors
                title={t("updateError")}
                mutationValidationErrors={validationErrors}
              />
            ),
          });
        } else {
          if (itemIndex + 1 === cart?.items?.length) {
            captureEvent(CartEvent.fromLearnerDetailsSubmitted(cart));
            if (!hasBookingDetails) {
              placeOrder();
            } else {
              history.push({
                pathname: `/checkout/${CheckoutURLS.BookingDetails}`,
              });
            }
          } else {
            resetFormState();
            setCurrentItem(getItem(itemIndex + 1));
          }
        }
      }
    }
  };

  useEffect(() => {
    cart?.items && itemIndex === 0 && setCurrentItem(getItem(itemIndex));
  }, [cart, getItem, setCurrentItem, itemIndex]);

  useEffect(() => {
    if (!placeOrderLoading && placeOrderMutationError) {
      setMessages({ failed: placeOrderMutationError });
    }
  }, [placeOrderLoading, placeOrderMutationError, setMessages]);

  useEffect(() => {
    if (!placeOrderLoading && placeOrderValidationErrors) {
      setMessages({
        errors: (
          <ValidationErrors
            title={t("placeOrderError")}
            mutationValidationErrors={placeOrderValidationErrors}
          />
        ),
      });
    }
  }, [placeOrderLoading, placeOrderValidationErrors, setMessages, t]);

  let submitButtonLabel = t("continue");

  if (totalItems === itemIndex + 2) {
    submitButtonLabel = t("multipleItemsLearnerDetailButton", {
      nextItemNumber,
    });
  } else if (!hasBookingDetails) {
    submitButtonLabel = t("completeRegistration");
  }

  return (
    <CheckoutPage step={CheckoutStep.LearnerDetails}>
      <Card>
        <FutureArticleHeader
          title={
            totalItems > 1
              ? t("multipleItemsLearnerDetails", {
                  itemNumber,
                  totalItems,
                })
              : t("learnerDetails")
          }
        />
        {currentItem && (
          <CartLineItemFormWrapper
            item={currentItem[0]}
            messages={messages}
            saveState={saveState}
            onSubmit={handleSubmit}
            submitButtonLabel={submitButtonLabel}
            contact={viewer?.contact}
          />
        )}
      </Card>
      <WonCartAlert cart={cart} />
    </CheckoutPage>
  );
};

const CartLineItemFormWrapper: FunctionComponent<{
  saveState: SaveStates;
  messages: MessageOptions;
  item: Maybe<CartLineItem>;
  onSubmit: (
    result: LearnerDetailsType,
    pointOfSaleFields: PointOfSaleLearnerFieldDefinition[],
  ) => Promise<void>;
  submitButtonLabel: string;
  contact: Contact | null | undefined;
}> = ({ saveState, messages, item, onSubmit, submitButtonLabel, contact }) => {
  const { event, learningPath } = getProductOption(item);
  let entityType;
  let filters: PointOfSaleFieldFilter[] = [];

  if (learningPath) {
    entityType = PointOfSaleFieldEntityType.CartLineItemPathLearner;
  }
  if (event) {
    entityType = PointOfSaleFieldEntityType.CartLineItemEventLearner;
    filters = [
      {
        value: event.course?.id,
        field: PointOfSaleField.IsValidForCourseTemplate,
        operation: FilterOperation.Eq,
      },
    ];
  }

  const { data: posFieldsResponse, loading: poSLoading } =
    useWebLinkQuery<Query>(POINT_OF_SALE_LEARNER_FIELD_QUERY, {
      variables: {
        entityType,
        filters,
      },
    });

  const pointOfSaleFields =
    posFieldsResponse && posFieldsResponse.pointOfSaleLearnerFields
      ? posFieldsResponse.pointOfSaleLearnerFields
      : [];

  return (
    <CartLineItemForm
      item={item}
      pointOfSaleFields={pointOfSaleFields ? pointOfSaleFields : []}
      messages={messages}
      saveState={saveState}
      onSubmit={result => onSubmit(result, pointOfSaleFields)}
      submitButtonLabel={submitButtonLabel}
      event={event}
      learningPath={learningPath}
      contact={contact}
      posLoading={poSLoading}
    />
  );
};

const CartLineItemForm: FunctionComponent<{
  saveState: SaveStates;
  messages: MessageOptions;
  pointOfSaleFields: PointOfSaleLearnerFieldDefinition[];
  item: Maybe<CartLineItem>;
  onSubmit: (result: LearnerDetailsType) => Promise<void>;
  submitButtonLabel: string;
  event?: EventType;
  learningPath?: LearningPath;
  contact: Contact | null | undefined;
  posLoading: boolean | undefined;
}> = ({
  saveState,
  messages,
  item,
  pointOfSaleFields,
  onSubmit,
  submitButtonLabel,
  event,
  learningPath,
  contact,
  posLoading,
}) => {
  const { dateFormat } = useDateFormatter({ showTime: true });
  let values = useFormValues({
    interestId: item?.id,
    productType: event?.__typename || learningPath?.__typename,
    learners: [
      {
        id: contact?.id,
        learnerNumber: 0,
        contact,
        posFieldValues: {},
      },
    ],
  });

  useEffect(() => {
    if (item && pointOfSaleFields.length > 0) {
      values.learners[0].posFieldValues = {};
      getPoSValues(values, pointOfSaleFields, contact);
    }
  }, [item, values, pointOfSaleFields, contact]);

  if (pointOfSaleFields.length > 0) {
    getPoSValues(values, pointOfSaleFields, contact);
  }

  return (
    <Fragment>
      {saveState !== "saved" && (
        <FormStateStatus messages={messages} saveState={saveState} />
      )}
      <h3>{learningPath?.name || event?.name}</h3>
      {event?.location && <p>{getLocationRegionFormat(event?.location)}</p>}
      {event?.start && (
        <p>{formatDateRange(event?.start, event?.end || "", dateFormat)}</p>
      )}
      {posLoading && (
        <div>
          <div>
            <Placeholder width={350} />
          </div>
          <hr />
          <div className="mb-3">
            <Placeholder width={350} />
          </div>
        </div>
      )}
      {!posLoading && (
        <Form
          values={values}
          onSubmit={onSubmit}
          disabled={saveState === "saving"}
        >
          <FormArray name="learners">
            <PointOfSaleLearnerFields
              posFields={pointOfSaleFields}
              learnerNumber={0}
              disabled={false}
            />
          </FormArray>
          <FormActions>
            <Submit label={submitButtonLabel} />
          </FormActions>
        </Form>
      )}
    </Fragment>
  );
};

const getLearnerDetails = (
  pointOfSaleFields: PointOfSaleLearnerFieldDefinition[],
  result: LearnerDetailsType,
  contact?: Contact | null | undefined,
) => {
  const attributes = mapPointOfSaleFieldValues(
    pointOfSaleFields,
    result.learners[0].posFieldValues,
  );

  return [
    {
      firstName: contact?.firstName ?? "",
      lastName: contact?.lastName,
      email: contact?.emailAddress,
      attributes,
    },
  ];
};
