import gql from "graphql-tag";
import {
  ACCOUNT_ASSOCIATION_CONNECTION,
  LEARNING_TAG_CONNECTION,
  PAGE_INFO_FRAGMENT,
} from "./fragments";

export const WEBLINK_STORE_ATTRIBUTES_QUERY = gql`
  query attributes {
    store {
      hidePricesBeforePricingAgreementApplied
      hidePrices
      cartlessCheckout
      canSelfCheckoutByInvoice
      requireBookerBillingAddress
      terms {
        title
        text
      }
      trainingRequestWorkflow {
        enabled
      }
      paymentMethods {
        id
        __typename
        paymentAttributeDefinitions {
          key
          label
          type
          options {
            label
            value
          }
        }
        ... on Stripe {
          publicKey
        }
        ... on TrainingToken {
          tokenTypes {
            id
            name
          }
        }
      }
      currency {
        code
      }
      region {
        code
        id
      }
    }
  }
`;

export const WEBLINK_COURSE_DETAILS_QUERY = gql`
  query getCourseDetails($id: String) {
    courses(first: 1, filters: { field: id, value: $id, operation: eq }) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          id
          name
          description
          teaserDescription
          imageUrl
          code
          topicsCovered
          faqs
          outcomes
          prerequisites
          priceRange {
            minimumPrice {
              amount
              financialUnit {
                name
                code
              }
            }
            normalPrice {
              amount
              financialUnit {
                name
                code
              }
            }
            maximumPrice {
              amount
              financialUnit {
                name
                code
              }
            }
          }
          includedInPassTypeIds
          isFeatured
          categories {
            id
            name
          }
          learningTags {
            ...LearningTagDetails
          }
          accountAssociations {
            ...AccountAssociationDetails
          }
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
  ${LEARNING_TAG_CONNECTION}
  ${ACCOUNT_ASSOCIATION_CONNECTION}
`;
