import React, { FunctionComponent, useEffect, useState } from "react";
import {
  useFormValues,
  FilterReturn,
  FutureArticleHeader,
  FutureSelectFilter,
} from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";

export type MyCoursesFilters = {
  search: string;
  active?: FilterReturn;
  sort?: FilterReturn;
};

export const MyCoursesArticleHeader: FunctionComponent<{
  onChange: (args: MyCoursesFilters) => void;
  loading: boolean;
}> = ({ loading, onChange }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const [active, setActive] = useState<FilterReturn | undefined>({
    name: "show",
    selected: { name: "active", value: "active" },
  });
  const [sort, setSort] = useState<FilterReturn | undefined>({
    name: "sort",
    selected: { name: "recentlyAccessed", value: "recentlyAccessed" },
  });
  useEffect(() => {
    onChange({
      search,
      sort,
      active,
    });
  }, [onChange, active, search, sort]);

  return (
    <FutureArticleHeader
      loading={loading}
      search={{
        label: t("learning"),
        onChange: (value: string) => setSearch(value),
      }}
      filters={{
        values: useFormValues({
          search: "",
          active: true,
          sort: "recentlyAccessed",
        }),
      }}
      id="learning-search"
    >
      <FutureSelectFilter
        name="active"
        label={t("show")}
        direction="right"
        onChange={setActive}
        options={[
          {
            label: t("active"),
            value: true,
          },
        ]}
      />
      <FutureSelectFilter
        name="sort"
        label={t("sort")}
        direction="right"
        onChange={setSort}
        isClearable={false}
        options={[
          {
            label: t("recentlyAccessed"),
            value: "recentlyAccessed",
          },
          {
            label: t("aToZ"),
            value: "aToZ",
          },
          {
            label: t("zToA"),
            value: "zToA",
          },
        ]}
      />
    </FutureArticleHeader>
  );
};
