import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { Location, Region } from "../../generated/weblinkTypes";

export interface DetailFilter {
  location?: Location;
  region?: Region;
  fromDate?: string;
  toDate?: string;
  minSeats?: string;
}

export interface FilterContextType {
  filters: DetailFilter;
  setFilters: Dispatch<SetStateAction<DetailFilter>>;
  setFilterValue: (key: keyof DetailFilter, value: unknown) => void;
}
export const DetailFilterContext = createContext({} as FilterContextType);
const useDetailFilterContext = () => useContext(DetailFilterContext);

export function DetailFilterContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const emptyFilters = {
    location: undefined,
    region: undefined,
    fromDate: undefined,
    toDate: undefined,
    minSeats: undefined,
  };
  const [filters, setFilters] = useState<DetailFilter>(emptyFilters);
  const setFilterValue = useCallback(
    (key, value) => {
      setFilters(prevState => ({ ...prevState, [key]: value }));
    },
    [setFilters],
  );

  return (
    <DetailFilterContext.Provider
      value={{
        filters,
        setFilters,
        setFilterValue,
      }}
    >
      {children}
    </DetailFilterContext.Provider>
  );
}

export function useDetailFilters() {
  const context = useDetailFilterContext();
  if (context === undefined) {
    throw new Error("useDetailFilters must be used within a Detail Route");
  }
  return context;
}
