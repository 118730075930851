import React, { FunctionComponent } from "react";
import { Route, Switch } from "react-router-dom";

import { ProtectedRoute } from "../../ProtectedRoute";
import { usePortal } from "../../providers/PortalProvider";
import { useViewer } from "../../providers/ViewerProvider";
import { CoordinatedAccounts } from "../Learner/CoordinatedAccounts";
import { LearnerCourses } from "../Learner/LearnerCourses";
import { LearnerLearningPaths } from "../Learner/LearnerLearningPaths";
import { LearnerPage } from "../Learner/LearnerPage";
import { AddLearnerToEventBooking } from "./AddLearnerToEventBooking";
import { BookingFilterContextProvider } from "./BookingFilterContext";
import { Bookings } from "./Bookings";
import { ExistingEventBooking } from "./ExistingEventBooking";
import { ExistingLearningPathBooking } from "./ExistingLearningPathBooking";
import { LearnerFilterContextProvider } from "./LearnerFilterContext";
import { LearnerList } from "./LearnerList";
import { UnnamedLearnerDetails } from "./UnnamedLearnerDetails";

export const LearnerManagementRoute: FunctionComponent<{}> = () => {
  const { isBookingPortal } = usePortal();
  const { viewer } = useViewer();

  const allowCoordinatorFunctionality =
    !!viewer && viewer.isCoordinator && isBookingPortal;

  return (
    <LearnerFilterContextProvider>
      <BookingFilterContextProvider>
        <Switch>
          <ProtectedRoute
            path="/learner-management/learners"
            exact
            component={LearnerList}
            condition={allowCoordinatorFunctionality}
          />
          <ProtectedRoute
            path="/learner-management/bookings"
            exact
            component={Bookings}
            condition={allowCoordinatorFunctionality}
          />
          <ProtectedRoute
            path="/learner-management/bookings/:bookingId/event"
            exact
            component={ExistingEventBooking}
            condition={allowCoordinatorFunctionality}
          />
          <ProtectedRoute
            path="/learner-management/bookings/:bookingId/unnamed-learner/:learnerId"
            exact
            component={UnnamedLearnerDetails}
            condition={allowCoordinatorFunctionality}
          />
          <ProtectedRoute
            path="/learner-management/bookings/:bookingId/learning-path"
            exact
            component={ExistingLearningPathBooking}
            condition={allowCoordinatorFunctionality}
          />
          <ProtectedRoute
            path="/learner-management/bookings/:bookingId/event/addLearner"
            exact
            component={AddLearnerToEventBooking}
            condition={allowCoordinatorFunctionality}
          />
          <ProtectedRoute
            path="/learner-management/learners/:id"
            exact
            component={LearnerCourses}
            condition={allowCoordinatorFunctionality}
          />
          <ProtectedRoute
            path="/learner-management/learners/:id/courses"
            exact
            component={LearnerCourses}
            condition={allowCoordinatorFunctionality}
          />
          <ProtectedRoute
            path="/learner-management/learners/:id/learning-paths"
            exact
            component={LearnerLearningPaths}
            condition={allowCoordinatorFunctionality}
          />
          <ProtectedRoute
            path="/learner-management/learners/:id/coordinated-accounts"
            exact
            component={CoordinatedAccounts}
            condition={allowCoordinatorFunctionality}
          />
          <ProtectedRoute
            path="/learner-management/learners/:id/edit"
            exact
            component={LearnerPage}
            condition={allowCoordinatorFunctionality}
          />
          <Route component={LearnerList} />
        </Switch>
      </BookingFilterContextProvider>
    </LearnerFilterContextProvider>
  );
};
