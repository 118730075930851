import React, { FunctionComponent } from "react";
import { useParams } from "../useHistory";
import { Alert, FutureListPage } from "@administrate/piston-ux";
import { useLmsQuery } from "../hooks/lms";
import { CourseRegistrationResponse } from "../types/Registration";
import { REGISTRATION_FOR_COURSE_QUERY } from "../queries/registrations";
import { CardLoadingPlaceholder } from "./CardLoadingPlaceholder";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

export const QuickRegister: FunctionComponent = () => {
  const { t } = useTranslation();
  const { courseTemplateId } = useParams<{ courseTemplateId: string }>();
  const quickRegister = "quickRegister=1";
  const { data, loading, error } = useLmsQuery<CourseRegistrationResponse>(
    REGISTRATION_FOR_COURSE_QUERY,
    {
      variables: { courseTemplateId },
    },
  );
  const registerableId = data?.registrations.edges[0]?.node.id;
  return (
    <FutureListPage title={t("myCourses")} type="grid">
      {loading && <CardLoadingPlaceholder />}
      {error && (
        <Alert
          type="error"
          message={t("anErrorOccurredWhileLoadingCourses")}
          glyph="removeSign"
          overPage={false}
        />
      )}
      {!loading &&
        !error &&
        (registerableId ? (
          <Redirect to={`/my-courses/course/${registerableId}`} />
        ) : (
          <Redirect
            to={`/catalog/self/course/${courseTemplateId}?${quickRegister}`}
          />
        ))}
    </FutureListPage>
  );
};
