import React from "react";
import {
  useFormValues,
  Checkbox,
  Modal,
  SafeHTMLDisplay,
} from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";

export const TermsForm: React.FunctionComponent<{
  onDone: any;
  show: boolean;
  termsAndConditions: string;
  disabled: boolean;
}> = ({ onDone, show, termsAndConditions, disabled }) => {
  const { t } = useTranslation();

  const initialValues: any = {
    acceptedTerms: false,
  };
  const values = useFormValues(initialValues);

  return (
    <Modal
      show={show}
      title={t("termsAndConditions")}
      onDone={onDone}
      values={values}
      disabled={disabled}
      primaryActionText={t("OK")}
    >
      <div className="termsAndConditions">
        <div
          style={{
            maxHeight: "260px",
            overflowY: "auto",
          }}
        >
          <SafeHTMLDisplay html={termsAndConditions} />
        </div>
        <Checkbox
          name="acceptedTerms"
          description={t("acceptedTerms")}
          valid={v => !!v}
        />
      </div>
    </Modal>
  );
};
