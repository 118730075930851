import React, { useCallback, useEffect, useState } from "react";
import UscAlgorithm from "@administrate/uniform-string-compression";

import { SET_PROGRESS_MUTATION } from "../queries/registrations";
import { useLmsMutation } from "../hooks/lms";
import { useViewer } from "../providers/ViewerProvider";
import { setStateType } from "../types/SetStateType";
import { GraphQLErrorTypes, hasErrorType } from "../utils/errorHelpers";
import { lastRecordedTooLongAgo } from "../utils/videoLastRecordedTooLongAgo";

type VideoPlayerProps = {
  contentId: string;
  registrationId?: string;
  wistiaVideoId: string;
  startAt: number;
  setHasInvalidRegistration: setStateType<boolean>;
};

type WistiaVideo = WistiaPlayer.Player & {
  requestControls(requesterName: string): void;
  releaseControls(requesterName: string): void;
};

declare global {
  interface Window {
    _wq: Array<object> | undefined;
  }
}

export const WistiaVideoPlayer: React.FunctionComponent<VideoPlayerProps> = ({
  contentId,
  wistiaVideoId,
  startAt,
  registrationId,
  setHasInvalidRegistration,
}) => {
  const [updateProgress] = useLmsMutation(SET_PROGRESS_MUTATION, {
    onError: error => {
      if (!hasErrorType(error, GraphQLErrorTypes.invalidRegistration)) {
        throw error;
      }
      setHasInvalidRegistration(true);
    },
  });
  const [video, setVideo] = useState<WistiaVideo | null>(null);
  const [newPercent, setNewPercent] = useState<number>(0);
  const [currentPercent, setCurrentPercent] = useState<number>(0);
  const [lastRecordedPercent, setLastRecordedPercent] = useState<number>(0);
  const { viewer } = useViewer();

  const recordProgress = useCallback(
    (registrationId: string, watchedVector: number[], contentId?: string) => {
      if (!viewer?.isPreviewer) {
        // Normalise values to 1/0 where they may be 0 (not watched) or >= 1 (watched n times)
        const vector = watchedVector.map(timesWatched =>
          timesWatched > 0 ? 1 : 0,
        );
        const compressedVector = UscAlgorithm.compress(vector.join(""));

        // noinspection JSIgnoredPromiseFromCall - we don't want to do anything (for now) if this fails
        updateProgress({
          variables: {
            registrationId,
            contentId,
            stringContentId: contentId,
            data: JSON.stringify({
              compressed_seconds_watched: compressedVector,
            }),
          },
        });
      }
    },
    [updateProgress, viewer],
  );

  useEffect(() => {
    if (!document.getElementById("wistia_script")) {
      const wistiaScript = document.createElement("script");
      wistiaScript.id = "wistia_script";
      wistiaScript.type = "text/javascript";
      wistiaScript.src = "https://fast.wistia.com/assets/external/E-v1.js";
      wistiaScript.async = true;
      document.body.appendChild(wistiaScript);
    }

    let isMounted = true;
    window._wq = window._wq || [];
    window._wq.push({
      id: wistiaVideoId,
      onReady: (video: WistiaVideo) => {
        // onReady will be called even after `video.remove()`
        if (isMounted) {
          // time has to be set both here and in the <div> to work without frame flash and on mobile browsers
          video.time(startAt);
          setVideo(video);
        }
      },
    });

    return () => {
      isMounted = false;
    };

    // this useEffect initializes the player on content change or on mount, so it cannot depend on any other variables
  }, [contentId]); // eslint-disable-line

  useEffect(() => {
    const handlePercentChange = (percent: number) => {
      setNewPercent(percent);
    };
    const requestControls = () => {
      if (video) {
        video.requestControls("administrate");
      }
    };
    const releaseControls = () => {
      if (video) {
        video.releaseControls("administrate");
      }
    };

    if (video) {
      video.bind("pause", requestControls); // Show video controls when paused.
      video.bind("play", releaseControls); // Restore original behavior when playing.
      video.bind("percentwatchedchanged", handlePercentChange);
    }

    return () => {
      if (video) {
        video.unbind("play", releaseControls);
        video.unbind("pause", requestControls);
        video.unbind("percentwatchedchanged", handlePercentChange);
        video.remove();
        window._wq = undefined;
      }
    };
  }, [video]);

  useEffect(() => {
    /**
     * When a start time is sent to a newly loaded Wistia video, the video always goes from "beforeplay" to "playing"
     * and then "paused" state, even if the passed time is 0 seconds. This causes the "percentwatchedchanged" event
     * to be fired with value equal to 1 second of progress.
     * This code catches that situation and sets lastRecordedPercent to the same value, in order to prevent saving
     * incorrect playback data.
     */
    if (currentPercent === 0) {
      setLastRecordedPercent(newPercent);
    }
    setCurrentPercent(newPercent);
  }, [newPercent, currentPercent]);

  useEffect(() => {
    if (
      video &&
      registrationId &&
      lastRecordedTooLongAgo(
        currentPercent,
        lastRecordedPercent,
        video.duration(),
      )
    ) {
      recordProgress(registrationId, video.secondsWatchedVector(), contentId);
      setLastRecordedPercent(currentPercent);
    }
  }, [
    currentPercent,
    video,
    recordProgress,
    lastRecordedPercent,
    contentId,
    registrationId,
  ]);

  return (
    <div
      className={`wistia_embed wistia_async_${wistiaVideoId} videoFoam=true time=${startAt}`}
    />
  );
};
