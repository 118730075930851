import gql from "graphql-tag";
import { CONTENT_FRAGMENT, ERROR_FRAGMENT } from "../queries/fragments";

export const RECORD_DISCUSSION_COMMENT = gql`
  mutation recordDiscussionComment(
    $input: RecordDiscussionCommentInput!
    $stringContentId: String!
  ) {
    registration {
      recordDiscussionComment(input: $input) {
        registration {
          contentResults(
            filters: [
              { field: contentId, operation: eq, value: $stringContentId }
            ]
          ) {
            edges {
              node {
                contentId
                content {
                  id
                  type
                  ... on DiscussionTopic {
                    comments {
                      edges {
                        node {
                          id
                          comment
                          createdAt
                          contact {
                            id
                            firstName
                            lastName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        errors {
          ...Error
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const DELETE_DISCUSSION_COMMENT = gql`
  mutation deleteDiscussionComment(
    $input: DeleteDiscussionCommentInput!
    $stringContentId: String!
  ) {
    registration {
      deleteDiscussionComment(input: $input) {
        registration {
          contentResults(
            filters: [
              { field: contentId, operation: eq, value: $stringContentId }
            ]
          ) {
            edges {
              node {
                contentId
                content {
                  id
                  type
                  ... on DiscussionTopic {
                    comments {
                      edges {
                        node {
                          id
                          comment
                          createdAt
                          contact {
                            id
                            firstName
                            lastName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        errors {
          ...Error
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const RECORD_EXTERNAL_CONTENT_ACCESSED = gql`
  mutation recordExternalContentAccessed(
    $registrationId: ID!
    $contentId: ID!
    $stringContentId: String!
  ) {
    registration {
      recordExternalContentAccessed(
        input: { registrationId: $registrationId, contentId: $contentId }
      ) {
        registration {
          id
          contentResults(
            filters: [
              { field: contentId, operation: eq, value: $stringContentId }
            ]
          ) {
            edges {
              node {
                ...ContentFragment
                content {
                  id
                  ... on ExternalActivity {
                    externalActivityUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CONTENT_FRAGMENT}
`;

export const RECORD_EXTERNAL_PROGRESS_STATUS = gql`
  mutation recordExternalProgressStatus(
    $attemptId: ID!
    $status: ExternalActivityStatus!
  ) {
    attempt {
      recordExternalProgress(
        input: { attemptId: $attemptId, status: $status }
      ) {
        attempt {
          id
          registration {
            id
            contentResults {
              edges {
                node {
                  ...ContentFragment
                }
              }
            }
          }
          content {
            id
          }
        }
        errors {
          ...Error
        }
      }
    }
  }
  ${CONTENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

export const ENSURE_KRYTERION_ACCOUNT = gql`
  mutation ensureKryterionAccount($registrationId: ID!, $contentId: ID!) {
    registration {
      ensureKryterionAccount(
        input: { registrationId: $registrationId, contentId: $contentId }
      ) {
        kryterionAccountExists
        errors {
          ...Error
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const RECORD_DOWNLOADED = gql`
  mutation recordDownloaded(
    $registrationId: ID!
    $contentId: ID!
    $stringContentId: String!
  ) {
    registration {
      recordResourceDownload(
        input: { registrationId: $registrationId, contentId: $contentId }
      ) {
        registration {
          id
          contentResults(
            filters: [
              { field: contentId, operation: eq, value: $stringContentId }
            ]
          ) {
            edges {
              node {
                ...ContentFragment
              }
            }
          }
        }
      }
    }
  }
  ${CONTENT_FRAGMENT}
`;

export const REGISTER_PASSHOLDER_TO_EVENT = gql`
  mutation registerPassholderOnEvent($eventId: ID!, $passId: ID!) {
    registration {
      registerPassholder(
        input: { eventId: $eventId, trainingPassId: $passId }
      ) {
        errors {
          ...Error
        }
        registration {
          id
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const REGISTER_PASSHOLDER_TO_LEARNING_PATH = gql`
  mutation registerPassholderOnLearningPath(
    $learningPathId: ID!
    $passTypeId: String!
    $selectedEvents: [SelectedLearningObjectiveEvent!]!
  ) {
    registration {
      registerLearningPathLearner(
        input: {
          learningPathId: $learningPathId
          trainingPassTypeId: $passTypeId
          selectedEvents: $selectedEvents
        }
      ) {
        errors {
          ...Error
        }
        registration {
          id
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
