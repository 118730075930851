import React from "react";
import { useTranslation } from "react-i18next";
import { FutureListPage } from "@administrate/piston-ux";
import { TrainingRequests } from "./TrainingRequests";
import { CancellationRequests } from "./CancellationRequests";

export const MyRequests: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <FutureListPage title={t("myRequests")}>
      <TrainingRequests />
      <CancellationRequests />
    </FutureListPage>
  );
};
