import React, { Fragment } from "react";
import moment from "moment-timezone";
import { DateCriteria } from "../components/AffectBar/DatePickerFilter";
import {
  DeliveryMethod,
  Event as WebLinkEvent,
} from "../generated/weblinkTypes";
import { Event as LmsEvent } from "../generated/lmsTypes";
import { TFunction } from "i18next";

export const applyTimeZoneToDateTime = (
  dateTime: string,
  timeZoneName: string,
) => moment.tz(dateTime, timeZoneName).format();

export const startOfDayWithTimeZone = (
  dateTime: string,
  timeZoneName: string,
) => moment.tz(dateTime, timeZoneName).startOf("day").format();

export const startOfDayUTC = (dateTime: string, timeZoneName: string) =>
  moment.tz(dateTime, timeZoneName).startOf("day").utc().format();

export const endOfDayWithTimeZone = (dateTime: string, timeZoneName: string) =>
  moment.tz(dateTime, timeZoneName).endOf("day").format();

export const endOfDayUTC = (dateTime: string, timeZoneName: string) =>
  moment.tz(dateTime, timeZoneName).endOf("day").utc().format();

export const isNotInPast = (currentDate: string) =>
  moment(currentDate).isSameOrAfter(new Date(), "day");

export const formatDateRange = (
  start: string,
  end: string,
  dateFormat: Function,
) => {
  start = start ? dateFormat({ date: start }) : "";
  end = end ? dateFormat({ date: end }) : "";
  return displayDateRange(start, end);
};

export const formatFilterDateRange = (
  dates: DateCriteria,
  dateFormat: Function,
) => {
  const fromDate = dates.from ? dateFormat(dates.from) : "any";
  const toDate = dates.to ? dateFormat(dates.to) : "any";
  return `${fromDate} - ${toDate}`;
};

// TODO Make this a React Component and remove its usage from the helper files
export const displayDateRange = (start: string, end: string) => (
  <Fragment>
    {start}
    {end && (
      <span className="pl-1">
        to <br />
        {end}
      </span>
    )}
  </Fragment>
);

export const getEventStartDate = (
  event: WebLinkEvent | LmsEvent,
  t: TFunction,
) => {
  if (event.deliveryMethod === DeliveryMethod.Lms) {
    return t("uponEnrollment");
  }
  return event.start ? moment.utc(event.start).local().format("ll") : "";
};

export const formatLocalisedDateTime = (value: string) => {
  if (!value) return "";
  const formattedDateTime = moment(value).format("lll");
  return formattedDateTime !== "Invalid date" ? formattedDateTime : "";
};

export const formatLocalisedDate = (value: string) => {
  if (!value) return "";
  const formattedDate = moment(value).format("ll");
  return formattedDate !== "Invalid date" ? formattedDate : "";
};

export const getDateTimeForStartWeek = (
  week: string,
  year: string,
  timeZoneName: string,
) => {
  const formattedDate = moment()
    .isoWeekYear(Number(year))
    .isoWeek(Number(week))
    .startOf("isoWeek")
    .format("YYYY-MM-DD");
  return startOfDayWithTimeZone(formattedDate, timeZoneName);
};

export const getDateTimeForEndWeek = (
  week: string,
  year: string,
  timeZoneName: string,
) => {
  const formattedDate = moment()
    .isoWeekYear(Number(year))
    .isoWeek(Number(week))
    .endOf("isoWeek")
    .format("YYYY-MM-DD");
  return endOfDayWithTimeZone(formattedDate, timeZoneName);
};

export const getCurrentYear = () => {
  return moment().year().toString();
};

export const getWeeksInYear = (year: string) => {
  const yearNumber = Number(year);
  return moment().year(yearNumber).isoWeeksInYear();
};

export const formatWeekNumberRange = (fromWeek?: string, toWeek?: string) => {
  if (fromWeek && toWeek) {
    return `Week ${fromWeek} - Week ${toWeek}`;
  }
  if (fromWeek) {
    return `Week ${fromWeek} - any`;
  }
  if (toWeek) {
    return `any - Week ${toWeek}`;
  }
  return "";
};
