export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A decimal refers generally to the notation of a number in the decimal system, which contains a decimal separator (for example 10.00 or 3.14159). */
  Decimal: any;
  /** One of a pair of numbers that show the exact position of a point. It is represented by Decimal with 8 points of precision. */
  Coordinate: any;
  /** A datetime as an ISO 8601 formatted string. */
  DateTime: any;
};


/** An Account which groups Contacts together. */
export type Account = {
  __typename?: 'Account';
  /** The unique identifier of the Account. */
  id: Scalars['ID'];
  /** The name of the Account. */
  name: Scalars['String'];
  /** Whether the Account is an Individual Account representing a single Contact. */
  isIndividual: Scalars['Boolean'];
  /** A list of accounts managed by this Account. */
  ancestors: Array<Ancestor>;
  /** Whether the Account is trusted to pay by Invoice. */
  canSelfCheckoutByInvoice: Scalars['Boolean'];
};

/** An Account Association. */
export type AccountAssociation = {
  __typename?: 'AccountAssociation';
  /** The unique identifier of the Account Association. */
  id: Scalars['ID'];
  /** The Account in this Association. */
  account: Account;
  /** The Account Association Type. */
  associationType: AccountAssociationType;
};

/** An Account Association Connection. */
export type AccountAssociationConnection = {
  __typename?: 'AccountAssociationConnection';
  edges: Array<AccountAssociationEdge>;
  pageInfo: PageInfo;
};

/** An Account Association Edge. */
export type AccountAssociationEdge = {
  __typename?: 'AccountAssociationEdge';
  node: AccountAssociation;
  cursor: Scalars['String'];
};

/** An Account Association Type. */
export type AccountAssociationType = {
  __typename?: 'AccountAssociationType';
  /** The unique identifier of the Account Association Type */
  id: Scalars['ID'];
  /** The name of the Account Association Type */
  name: Scalars['String'];
};

/** An Account Connection. */
export type AccountConnection = {
  __typename?: 'AccountConnection';
  edges: Array<AccountEdge>;
  pageInfo: PageInfo;
};

/** An Account Edge. */
export type AccountEdge = {
  __typename?: 'AccountEdge';
  node: Account;
  cursor: Scalars['String'];
};

/** Fields for filtering and ordering Accounts. */
export enum AccountField {
  /** Whether the Account is trusted to pay by Invoice. */
  CanSelfCheckoutByInvoice = 'canSelfCheckoutByInvoice',
  /** The unique identifier of the Account. */
  Id = 'id',
  /** The name of the Account. */
  Name = 'name'
}

/** A filter applied to Accounts. */
export type AccountFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field: AccountField;
  operation: FilterOperation;
};

/** An ordering applied to Accounts. */
export type AccountFieldOrder = {
  field: AccountField;
  direction: OrderDirection;
};

/** Details used to add a Child Item to a Cart. */
export type AddCartChildLineItemInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
  /** The unique identifier of the Product Option. */
  productOptionId: Scalars['ID'];
  /** The unique identifier of the Parent Item. */
  parentId: Scalars['ID'];
  /** Quantity of the Item. */
  quantity: Scalars['Int'];
  /** Amount of the Item. */
  amount: Scalars['Decimal'];
};

/** Details used to Add a Gift Voucher to a Cart. */
export type AddCartGiftVoucherLineItemInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
  /** The unique identifier of the Product Option. */
  productOptionId: Scalars['ID'];
  /** The Amount of the Gift voucher. */
  amount: Scalars['Decimal'];
};

/** Details used to add an Line Item to a Cart. */
export type AddCartLineItemInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
  /** The Line Item to add. */
  cartLineItem: AddCartLineItemPayloadInput;
};

/** Details used to specify the Line Item to add to the Cart. */
export type AddCartLineItemPayloadInput = {
  /** The unique identifier of the Product Option. */
  productOptionId: Scalars['ID'];
  /** Quantity of the Line Item. */
  quantity: Scalars['Int'];
  /** Learners who should be added to the Line Item. */
  learners?: Maybe<LearnerDetailsInput>;
  /** Any Learning Objectives chosen for the Line Item when the Line Item is a Learning Path. */
  objectives?: Maybe<Array<Maybe<LearningPathObjectivesInput>>>;
  /** The unique identifier of the Price Level. */
  priceLevelId?: Maybe<Scalars['ID']>;
};

/** An Account managed by a Parent Account. */
export type Ancestor = {
  __typename?: 'Ancestor';
  /** The unique identifer of the ancestor Account. */
  id: Scalars['ID'];
  /** The name of the ancestor Account. */
  name: Scalars['String'];
};

/** Details used to apply a Gift Voucher to a Cart. */
export type ApplyGiftVoucherInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
  /** The Code of the Gift Voucher to apply. */
  code: Scalars['String'];
};

/** Details used to apply a Payment Code to a Cart. */
export type ApplyPaymentCodeInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
  /** The Payment Code to apply. */
  code: Scalars['String'];
};

/** Details used to apply a Pricing Agreement to a Cart. */
export type ApplyPricingInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
  /** The unique identifier of the Account. */
  accountId: Scalars['ID'];
};

/** Details used to apply a Promotion Code to a Cart. */
export type ApplyPromotionCodeInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
  /** The Promotion Code to apply. */
  promotionCode: Scalars['String'];
};

/** Details used to specify Custom Learner Fields. */
export type AttributeInputType = {
  /** The unique definition key of the Custom Field. */
  definitionKey: Scalars['String'];
  /** The value of the Custom Field. */
  value?: Maybe<Scalars['String']>;
};

/** DEPRECATED - AuthorizeDotNet is no longed in use */
export type AuthorizeDotNet = PaymentProvider & {
  __typename?: 'AuthorizeDotNet';
  /** The unique identifier of the Payment Provider. */
  id?: Maybe<Scalars['ID']>;
  /** The Attribute Definitions linked to the Payment Provider. */
  paymentAttributeDefinitions?: Maybe<Array<Maybe<PaymentAttributeDefinition>>>;
  /** The ID used to identify the account when submitting transaction requests. */
  apiLoginId?: Maybe<Scalars['String']>;
  /** The key used as an additional layer of authentication when submitting transaction requests. */
  transactionKey?: Maybe<Scalars['String']>;
  /**
   * Whether the Payment Provider is considered to be 'Live' i.e. will process real
   * transactions for a production Authorize.net account.
   */
  isLive: Scalars['Boolean'];
};

/** An Event or Learning Path which can be Booked. */
export type Bookable = Event | LearningPath;

/** A Bookable Connection. */
export type BookableConnection = {
  __typename?: 'BookableConnection';
  pageInfo: PageInfo;
  edges: Array<BookableEdge>;
};

/** A Bookable Edge. */
export type BookableEdge = {
  __typename?: 'BookableEdge';
  cursor: Scalars['String'];
  node: Bookable;
};

/** Fields for filtering and ordering Bookables. */
export enum BookableField {
  /** Whether the Bookable is an Event or Learning Path. */
  Type = 'type',
  /** When the Bookable starts. */
  Start = 'start',
  /** When the Bookable ends. */
  End = 'end',
  /** The Delivery Method of the Bookable, Classroom, LMS, or Both. */
  DeliveryMethod = 'deliveryMethod',
  /** How many places remain until the Bookable is fully booked. */
  RemainingPlaces = 'remainingPlaces',
  /** The unique identifier for the Location of the Bookable. */
  LocationId = 'locationId',
  /** The unique identifier for the Course of the Bookable. */
  CourseId = 'courseId',
  /** The language of the Bookable. */
  Language = 'language'
}

/** A filter applied to Bookables. */
export type BookableFieldFilter = {
  value?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  field?: Maybe<BookableField>;
  operation?: Maybe<FilterOperation>;
};

/** An ordering applied to Bookables. */
export type BookableFieldOrder = {
  field?: Maybe<BookableField>;
  direction?: Maybe<OrderDirection>;
};

/** Fields for filtering the type of Bookable. */
export enum BookableType {
  /** An Event */
  Event = 'event',
  /** A Learning Path */
  Path = 'path'
}

/** Mutations that can be used to modify Bookings. */
export type BookingMutations = {
  __typename?: 'BookingMutations';
  /** Cancels a Learner on an Event. */
  cancelEventLearner: EventLearnerCancelResponseType;
  /** Cancels a Learner on a Path. */
  cancelPathLearner: PathLearnerCancelResponseType;
  /** Registers Learners onto a Private Event. */
  registerLearnersOntoPrivateBooking: RegisterLearnersOntoPrivateBookingResponse;
  /** Transfers a Learner from one Event to another Event. */
  transferLearner: TransferLearnerResponseType;
};


/** Mutations that can be used to modify Bookings. */
export type BookingMutationsCancelEventLearnerArgs = {
  input: EventLearnerCancelInput;
};


/** Mutations that can be used to modify Bookings. */
export type BookingMutationsCancelPathLearnerArgs = {
  input: PathLearnerCancelInput;
};


/** Mutations that can be used to modify Bookings. */
export type BookingMutationsRegisterLearnersOntoPrivateBookingArgs = {
  input: RegisterLearnersOntoPrivateBookingInput;
};


/** Mutations that can be used to modify Bookings. */
export type BookingMutationsTransferLearnerArgs = {
  input: TransferLearnerInput;
};

/** The details of the owner of the Cart. */
export type BuyerDetails = {
  __typename?: 'BuyerDetails';
  /** The first name of the Buyer. */
  firstName?: Maybe<Scalars['String']>;
  /** The last name of the Buyer. */
  lastName?: Maybe<Scalars['String']>;
  /** The e-mail address of the Buyer. */
  email?: Maybe<Scalars['String']>;
  /** The Company of the Buyer. */
  companyName?: Maybe<Scalars['String']>;
  /** The Postal Address of the Buyer. */
  billingAddress?: Maybe<PostalAddress>;
  /** Any Custom Fields associated with the Buyer, and their values. */
  customFieldValues: Array<LearnerAttributes>;
};

/** A Cart which contains Items for Checkout purposes. */
export type Cart = {
  __typename?: 'Cart';
  /** The unique identifier of the Cart. */
  id?: Maybe<Scalars['ID']>;
  /** Any Items in the Cart. */
  items?: Maybe<Array<Maybe<CartLineItem>>>;
  /** The details of the Cart owner. */
  buyerDetails?: Maybe<BuyerDetails>;
  /** The Price of the Cart. */
  price?: Maybe<CartPrice>;
  /** The Invoice ID of the Cart, populated during Checkout. */
  invoiceId?: Maybe<Scalars['String']>;
  /** The state of the Cart. */
  state?: Maybe<CartState>;
  /** Any Promotional Code which has been applied to the Cart. */
  promotionalCode?: Maybe<Scalars['String']>;
  /** Any Gift Vouchers which have been applied to the Cart. */
  giftVoucherApplications: Array<GiftVoucherApplication>;
  /** Any Payment Codes which have been applied to the Cart. */
  paymentCodeApplications: Array<PaymentCodeApplication>;
  /** The Registration for the Cart, populated during Checkout. */
  registration?: Maybe<Registration>;
  /** If the cart has been claimed by a user. */
  isClaimed?: Maybe<Scalars['Boolean']>;
  /** The Region of the Cart. */
  region?: Maybe<Region>;
  /** The Currency of the Cart. */
  currency?: Maybe<Currency>;
  /** The Token Type assigned to the Cart. Overriding and acting as the 'Currency' of the Cart if set. */
  tokenType?: Maybe<TokenType>;
  /** Whether the cart contains any items that require a review from an administrator before purchase. */
  requiresReviewBeforePurchase: Scalars['Boolean'];
  /** When the Items in the Cart are reserved until. After this time elapses, the Items are free to be Booked elsewhere. */
  reservationsValidUntil?: Maybe<Scalars['DateTime']>;
  /** The Price Level of the Cart. */
  priceLevel?: Maybe<PriceLevel>;
  /** Whether the Cart is locked to a specific Region/Currency pair. */
  isLockedToPaymentRegion?: Maybe<Scalars['Boolean']>;
};

/** A Cart Connection. */
export type CartConnection = {
  __typename?: 'CartConnection';
  edges?: Maybe<Array<Maybe<CartEdge>>>;
  pageInfo: PageInfo;
};

/** A Cart Edge. */
export type CartEdge = {
  __typename?: 'CartEdge';
  node?: Maybe<Cart>;
  cursor: Scalars['String'];
};

/** A Line Item in a Cart. */
export type CartLineItem = {
  /** The unique identifier of the Line Item. */
  id?: Maybe<Scalars['ID']>;
  /** The number of Line Items to be sold. */
  quantity: Scalars['Int'];
  /** The price charged for a single Line Item. */
  unitAmount: Scalars['Decimal'];
  /** The tax exclusive price charged for all Items of this Line. */
  subTotalAmount: Scalars['Decimal'];
  /** The total tax amount charged for all Items of this Line. */
  totalTaxAmount: Scalars['Decimal'];
  /** The tax inclusive price charged for all Items of this Line. */
  totalAmount: Scalars['Decimal'];
  /** The Tax Type applicable to this Line Item. */
  tax?: Maybe<Tax>;
  /** The underlying Product (Event) for this Line Item. */
  productOption?: Maybe<ProductOption>;
  /** A list of Learner details for this Line Item. */
  learnerDetails?: Maybe<Array<Maybe<LearnerDetails>>>;
  /** The Pricing Agreement Price applied to this Line Item. */
  pricingAgreementPrice?: Maybe<PricingAgreementPrice>;
  /** The Line Items that are children of this Line Item. */
  childItems?: Maybe<Array<Maybe<CartLineItem>>>;
  /** Whether the Line Item can be reserved. */
  isReservable: Scalars['Boolean'];
  /** Whether the Line Item reservation has expired. */
  isExpired: Scalars['Boolean'];
  /** Whether the Line Item is reserved. */
  reserved?: Maybe<Scalars['Boolean']>;
  /** The Price Level applied to this Line Item. */
  priceLevel?: Maybe<PriceLevel>;
  /** Attribute definitions that can be provided with each Learner */
  pointOfSaleDefinitions: Array<PointOfSaleLearnerFieldDefinition>;
};

/** The result of updating the Financial Unit of a Cart. */
export type CartMutateFinancialUnitResponseType = {
  __typename?: 'CartMutateFinancialUnitResponseType';
  /** The updated Cart. */
  cart?: Maybe<Cart>;
  /** A list of Cart Line Items which were removed from the Cart because they don't support the specified Financial Unit. */
  removedLineItems?: Maybe<Array<CartLineItem>>;
  /** A list of errors encountered while running the mutation. */
  errors: Array<FieldError>;
};

/** The result of modifying a Cart. */
export type CartMutateResponseType = {
  __typename?: 'CartMutateResponseType';
  /** The Cart that was modified. */
  cart?: Maybe<Cart>;
  /** A list of errors encountered while running the mutation. */
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

/** Mutations that can be used to modify a Cart. */
export type CartMutations = {
  __typename?: 'CartMutations';
  /** Adds a Child Item to a Cart. */
  addChildLineItem: CartMutateResponseType;
  /** Adds a Gift Voucher to the Cart. */
  addGiftVoucherLineItem: CartMutateResponseType;
  /** Adds an Line Item to a Cart. */
  addLineItem: CartMutateResponseType;
  /** Applies a Gift Voucher to a Cart. */
  applyGiftVoucher: CartMutateResponseType;
  /** Applies a Payment Code to a Cart. */
  applyPaymentCode: CartMutateResponseType;
  /** Applies any Pricing Agreements tied to a specific Account to the Cart. */
  applyPricing: CartMutateResponseType;
  /** Applies a Promotion Code to a Cart. */
  applyPromotionCode: CartMutateResponseType;
  /** Allows a logged in User to claim an existing Cart if the correct guest Portal Token is provided. */
  claimCart: CartMutateResponseType;
  /** Allows a Guest User to claim a Cart via a Recovery Token in order to operate on it. */
  claimCartWithRecoveryToken: CartMutateResponseType;
  /** Creates a new Cart. */
  createCart?: Maybe<CartMutateResponseType>;
  /** Moves the Cart into a pending state. Used when starting a checkout via Multisafepay or Worldpay. */
  moveCartPending?: Maybe<CartMutateResponseType>;
  /** Moves the Cart to the Entry Step. Useful when you need to modify a Pending Cart if the checkout fails. */
  moveCartToEntryStep: CartMutateResponseType;
  /** Complete the checkout for a Cart and create a Registration and Invoice. */
  placeOrder?: Maybe<CartMutateResponseType>;
  /** Moves the cart to the Review Step if applicable. */
  prepareForCheckout: CartMutateResponseType;
  /** Extends Reservations for Items and removes Items from the Cart where the Reservation has expired. */
  prepareItemsForCheckout: CartMutateResponseType;
  /** Removes a Line Item from a Cart. */
  removeCartLineItem?: Maybe<CartMutateResponseType>;
  /** Removes a previously applied Promotion Code from a Cart. */
  removePromotionCode: CartMutateResponseType;
  /** Submits a Cart to be reviewed and progressed by an Administrator in the TMS. */
  submitCartForReview: CartMutateResponseType;
  /** Switch the Payment Region for a Cart */
  switchPaymentRegion: CartMutateResponseType;
  /** Removes an applied Gift Voucher from a Cart. */
  unapplyGiftVoucher: CartMutateResponseType;
  /** Updates a Cart. */
  updateCart?: Maybe<CartMutateResponseType>;
  /** Updates the Financial Unit of a Cart. */
  updateFinancialUnit: CartMutateFinancialUnitResponseType;
  /** Updates an Event which is present in the Cart. */
  updateEventLineItem?: Maybe<CartMutateResponseType>;
  /** Updates a Gift Voucher which is present in the Cart. */
  updateGiftVoucherLineItem: CartMutateResponseType;
  /** Updates a Learning Path which is present in the Cart. */
  updatePathLineItem?: Maybe<CartMutateResponseType>;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsAddChildLineItemArgs = {
  input: AddCartChildLineItemInput;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsAddGiftVoucherLineItemArgs = {
  input: AddCartGiftVoucherLineItemInput;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsAddLineItemArgs = {
  input: AddCartLineItemInput;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsApplyGiftVoucherArgs = {
  input: ApplyGiftVoucherInput;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsApplyPaymentCodeArgs = {
  input: ApplyPaymentCodeInput;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsApplyPricingArgs = {
  input: ApplyPricingInput;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsApplyPromotionCodeArgs = {
  input: ApplyPromotionCodeInput;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsClaimCartArgs = {
  input: ClaimCartInput;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsClaimCartWithRecoveryTokenArgs = {
  input: ClaimCartWithRecoveryTokenInput;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsCreateCartArgs = {
  input?: Maybe<CreateCartInput>;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsMoveCartPendingArgs = {
  input?: Maybe<MoveCartPendingInput>;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsMoveCartToEntryStepArgs = {
  input: MoveCartToEntryStepInput;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsPlaceOrderArgs = {
  input?: Maybe<PlaceOrderInput>;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsPrepareForCheckoutArgs = {
  input: PrepareCartCheckoutInput;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsPrepareItemsForCheckoutArgs = {
  input: PrepareItemsForCheckoutInput;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsRemoveCartLineItemArgs = {
  input: RemoveCartLineItemInput;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsRemovePromotionCodeArgs = {
  input: RemovePromotionCodeInput;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsSubmitCartForReviewArgs = {
  input: SubmitCartForReviewInput;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsSwitchPaymentRegionArgs = {
  input: SwitchPaymentRegionInput;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsUnapplyGiftVoucherArgs = {
  input: ApplyGiftVoucherInput;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsUpdateCartArgs = {
  input?: Maybe<UpdateCartInput>;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsUpdateFinancialUnitArgs = {
  input: UpdateCartFinancialUnitInput;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsUpdateEventLineItemArgs = {
  input?: Maybe<UpdateEventLineItemInput>;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsUpdateGiftVoucherLineItemArgs = {
  input: UpdateGiftVoucherLineItemInput;
};


/** Mutations that can be used to modify a Cart. */
export type CartMutationsUpdatePathLineItemArgs = {
  input?: Maybe<UpdatePathLineItemInput>;
};

/** The Price of a Cart. */
export type CartPrice = {
  __typename?: 'CartPrice';
  /** The subtotal of the Cart. */
  subTotal: Scalars['Decimal'];
  /** The grand total of the Cart. */
  grandTotal: Scalars['Decimal'];
  /** The total of any the Discounts applied to the Cart. */
  discountTotal: Scalars['Decimal'];
  /** The total of any Gift Vouchers applied to the Cart. */
  giftVoucherTotal: Scalars['Decimal'];
  /** The total amount payable of the Cart. */
  payableTotal: Scalars['Decimal'];
  /** The taxes applied to the Cart. */
  taxes: Array<Maybe<CartTax>>;
};

/** The state of a Cart. */
export enum CartState {
  /** Cart is initialized, not paid yet. */
  Created = 'created',
  /** Cart is already paid. */
  Won = 'won',
  /** Cart payment is pending. */
  Pending = 'pending',
  /** Cart is pre-existing and payment can proceed, without modifying items. */
  OrderRetrieval = 'orderRetrieval',
  /** Cart is awaiting review and cannot be modified. */
  OrderReview = 'orderReview',
  /** Cart cannot be edited or progressed to a different state */
  Locked = 'locked'
}

/** The Tax of a Cart. */
export type CartTax = {
  __typename?: 'CartTax';
  /** The total amount of Tax. */
  totalAmount: Scalars['Decimal'];
  /** The type of Tax. */
  type: Tax;
};

/** An item which appears in the Catalog. A Course or Learning Path. */
export type Catalogue = Course | LearningPath;

/** A Catalog Connection. */
export type CatalogueConnection = {
  __typename?: 'CatalogueConnection';
  pageInfo: PageInfo;
  edges: Array<CatalogueEdge>;
};

/** A Catalog Edge. */
export type CatalogueEdge = {
  __typename?: 'CatalogueEdge';
  cursor: Scalars['String'];
  node: Catalogue;
};

/** Fields for filtering and ordering the Catalog. */
export enum CatalogueField {
  /** The unique identifier of the Catalog Item. */
  Id = 'id',
  /** The name of the Catalog Item. */
  Name = 'name',
  /** The unique identifier of the Category. */
  CategoryId = 'categoryId',
  /** The type of Catalog Item. */
  Type = 'type',
  /** Whether the Catalog Item is associated with a specific Account e.g. Partner/Sponsor. */
  IsAssociatedWithAccount = 'isAssociatedWithAccount',
  /** Whether the Catalog Item is associated with a specific Account indirectly by an Association Type */
  IsAssociatedByAccountAssociationType = 'isAssociatedByAccountAssociationType',
  /** The unique identifier of the Location. */
  LocationId = 'locationId',
  /** The first Session Start Time. */
  SessionStartTime = 'sessionStartTime',
  /** The last Session End Time. */
  SessionEndTime = 'sessionEndTime',
  /** When the Catalog Item starts. */
  Start = 'start',
  /** When the Catalog Item ends. */
  End = 'end',
  /** How many bookable places remain on the Catalog Item. */
  RemainingPlaces = 'remainingPlaces',
  /** Which day of week the Catalog Item runs on. */
  DayOfWeek = 'dayOfWeek',
  /**
   * If the Catalog Item (Learning Path) contains only Event and External
   * Objectives. If 'true' is provided, Course Templates are not returned.
   */
  IsBundle = 'isBundle',
  /** If the Catalog Item has a valid Price in a specific Region. */
  HasValidPriceForRegion = 'hasValidPriceForRegion',
  /** If the Catalog Item is included in a specified Training Pass Type. */
  IncludedInTrainingPass = 'includedInTrainingPass',
  /** The unique identifier of the Learning Tag. */
  LearningTagId = 'learningTagId'
}

/** A filter applied to the Catalog. */
export type CatalogueFieldFilter = {
  value?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  field?: Maybe<CatalogueField>;
  operation?: Maybe<FilterOperation>;
};

/** An ordering applied to the Catalog. */
export type CatalogueFieldOrder = {
  field?: Maybe<CatalogueField>;
  direction?: Maybe<OrderDirection>;
};

/** The type of the Catalog. */
export enum CatalogueType {
  /** A Course. */
  Course = 'course',
  /** A Learning Path. */
  Path = 'path'
}

/** A Learning Category. */
export type Category = {
  __typename?: 'Category';
  /** The unique identifier of this Category. */
  id: Scalars['ID'];
  /** The name of this Category. */
  name?: Maybe<Scalars['String']>;
  /** The description of the Category. */
  shortDescription?: Maybe<Scalars['String']>;
  /** The child categories associated with this Category. */
  categories?: Maybe<CategoryConnection>;
  /** The parent of this Category if it is a child Category. */
  parent?: Maybe<Category>;
  /** Products associated with this Category. */
  products?: Maybe<ProductConnection>;
};


/** A Learning Category. */
export type CategoryProductsArgs = {
  filters?: Maybe<Array<Maybe<ProductFieldFilter>>>;
  order?: Maybe<ProductFieldOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** A Category Connection. */
export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  pageInfo: PageInfo;
  edges: Array<CategoryEdge>;
};

/** A Category Edge. */
export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  cursor: Scalars['String'];
  node: Category;
};

/** Fields for filtering and ordering Categories. */
export enum CategoryField {
  /** The unique identifier of the Category. */
  Id = 'id',
  /** The name of the Category. */
  Name = 'name',
  /** The unique identifier of the Parent Category. */
  ParentId = 'parentId'
}

/** A filter applied to Categories. */
export type CategoryFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field: CategoryField;
  operation: FilterOperation;
};

/** An ordering applied to Categories. */
export type CategoryFieldOrder = {
  field: CategoryField;
  direction: OrderDirection;
};

/** Settings used to take payments with a Check. */
export type Check = PaymentProvider & {
  __typename?: 'Check';
  /** The unique identifier of the Payment Provider. */
  id?: Maybe<Scalars['ID']>;
  /** The address where the Check should be mailed to. */
  mailingAddress: MailingAddress;
  /** The Attribute Definitions linked to the Payment Provider */
  paymentAttributeDefinitions?: Maybe<Array<Maybe<PaymentAttributeDefinition>>>;
};

/** Details used to Claim a Cart. */
export type ClaimCartInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
  /** The guest Portal Token. */
  guestToken: Scalars['String'];
};

/** Details used to Claim a Cart with a Recovery Token. */
export type ClaimCartWithRecoveryTokenInput = {
  /** The Recovery Token. */
  token: Scalars['String'];
};

/** A Contact representing an individual Person. */
export type Contact = {
  __typename?: 'Contact';
  /** The unique identifier of the Contact. */
  id: Scalars['ID'];
  /** The first name of the Contact. */
  firstName?: Maybe<Scalars['String']>;
  /** The last name of the Contact. */
  lastName?: Maybe<Scalars['String']>;
  /** The primary email address of the Contact. */
  emailAddress?: Maybe<Scalars['String']>;
  /** The Account the Contact belongs to. */
  account?: Maybe<Account>;
  /** The custom field values for the Contact. */
  customFieldValues?: Maybe<Array<CustomFieldValue>>;
  /** The Job Role of the Contact. */
  jobRole?: Maybe<Scalars['String']>;
  /** The Department in which the Contact works. */
  department?: Maybe<Scalars['String']>;
  /** The Locale for the Contact. */
  locale: Scalars['String'];
  /** The timezone for the Contact. */
  timeZoneName?: Maybe<Scalars['String']>;
  /** Whether the Contact is a Coordinator for any Account. */
  isCoordinator: Scalars['Boolean'];
  /** The Personal Name of the Contact. */
  personalName?: Maybe<PersonalName>;
  /** The Postal Address of the Contact. */
  postalAddress?: Maybe<PostalAddress>;
  /** The Fax number of the Contact. */
  faxPhoneNumber?: Maybe<Scalars['String']>;
  /** The Home phone number of the Contact. */
  homePhoneNumber?: Maybe<Scalars['String']>;
  /** The Mobile phone number of the Contact. */
  mobilePhoneNumber?: Maybe<Scalars['String']>;
  /** The Office phone number of the Contact. */
  officePhoneNumber?: Maybe<Scalars['String']>;
};

/** A Contact Connection. */
export type ContactConnection = {
  __typename?: 'ContactConnection';
  edges: Array<ContactEdge>;
  pageInfo: PageInfo;
};

/** A Contact Edge. */
export type ContactEdge = {
  __typename?: 'ContactEdge';
  node: Contact;
  cursor: Scalars['String'];
};

/** Fields for filtering and ordering Contacts. */
export enum ContactField {
  /** The unique identifier of the Contact. */
  Id = 'id',
  /** The primary email address of the Contact. */
  EmailAddress = 'emailAddress',
  /** The name of the Contact. */
  Name = 'name'
}

/** A filter applied to Contacts. */
export type ContactFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field: ContactField;
  operation: FilterOperation;
};

/** An ordering applied to Contacts. */
export type ContactFieldOrder = {
  field: ContactField;
  direction: OrderDirection;
};

/** Settings used to take payments with Converge. */
export type Converge = PaymentProvider & {
  __typename?: 'Converge';
  /** The unique identifier of the Payment Provider. */
  id: Scalars['ID'];
  /** The Attribute Definitions linked to the Payment Provider. */
  paymentAttributeDefinitions?: Maybe<Array<PaymentAttributeDefinition>>;
  /**
   * Whether this Payment Provider is considered to be 'Live' i.e. will process
   * real transactions for a production Converge account.
   */
  isLive: Scalars['Boolean'];
};

/** Details used to capture a Converge Payment. */
export type ConvergePaymentDetailsInput = {
  /** The unique identifier for the Transaction received from Converge. */
  pendingTransactionId: Scalars['String'];
};


/** A CoordinatePair is a specific Location on a grid. */
export type CoordinatePair = {
  __typename?: 'CoordinatePair';
  /** The latitude of the Coordinate. */
  latitude: Scalars['Coordinate'];
  /** The longitude of the Coordinate. */
  longitude: Scalars['Coordinate'];
};

/** A Country. */
export type Country = {
  __typename?: 'Country';
  /** The unique identifier of the Country. */
  id: Scalars['ID'];
  /** The name of the Country. */
  name: Scalars['String'];
  /** The ISO designated Country Code. */
  code: Scalars['String'];
  /** Provinces which are associated with this Country. */
  provinces: Array<Province>;
};

/** A Course containing details of a Bookable Item. */
export type Course = Product & {
  __typename?: 'Course';
  /** The unique identifier of the Course. */
  id: Scalars['ID'];
  /** The name of the Course. */
  name: Scalars['String'];
  /** The Code referring to the Course. */
  code: Scalars['String'];
  /** The Learning Category of the Course. */
  category?: Maybe<Scalars['String']>;
  /** The Learning Categories of this Course. */
  categories: Array<Category>;
  /** The Price Range of the Course. */
  priceRange?: Maybe<PriceRange>;
  /** The Events for the Course. */
  options?: Maybe<ProductOptionConnection>;
  /** A short description of what is included in the Course. */
  teaserDescription?: Maybe<Scalars['String']>;
  /** A longer description of what is included in the Course. */
  description?: Maybe<Scalars['String']>;
  /** A description of anything that must be completed before attending the Course. */
  prerequisites?: Maybe<Scalars['String']>;
  /** Which Topics will be covered during the Course. */
  topicsCovered?: Maybe<Scalars['String']>;
  /** Frequently asked questions about the Course. */
  faqs?: Maybe<Scalars['String']>;
  /** A description of the Learning Outcomes associated with the Course. */
  outcomes?: Maybe<Scalars['String']>;
  /** The header image URL for the Course. */
  imageUrl?: Maybe<Scalars['String']>;
  /** The Image Gallery URLs for the Course. */
  imageUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The Delivery Method of the Course. */
  deliveryMethod?: Maybe<Scalars['String']>;
  /** The Custom Field Values associated with the Course. */
  customFieldValues: Array<CustomFieldValue>;
  /** Whether the Course is Featured i.e. sorted to the top of the Catalog. */
  isFeatured: Scalars['Boolean'];
  /** Published Training Pass Type Identifiers that grant access to the Course. */
  includedInPassTypeIds: Array<Scalars['ID']>;
  /** The Learning Tags for this Course. */
  learningTags: LearningTagConnection;
  /** The Accounts associated with this Course. */
  accountAssociations: AccountAssociationConnection;
};


/** A Course containing details of a Bookable Item. */
export type CourseOptionsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


/** A Course containing details of a Bookable Item. */
export type CourseLearningTagsArgs = {
  filters?: Maybe<Array<LearningTagFieldFilter>>;
  order?: Maybe<LearningTagFieldOrder>;
};

/** A Course Connection. */
export type CourseConnection = {
  __typename?: 'CourseConnection';
  pageInfo: PageInfo;
  edges: Array<CourseEdge>;
};

/** Basic detail of the Course for a Course Objective. */
export type CourseDetails = {
  __typename?: 'CourseDetails';
  /** The unique identifier of the Course. */
  id: Scalars['ID'];
  /** The Code of the Course. */
  code: Scalars['String'];
  /** The name of the Course. */
  name: Scalars['String'];
};

/** A Course Edge. */
export type CourseEdge = {
  __typename?: 'CourseEdge';
  cursor: Scalars['String'];
  node: Course;
};

/** Fields for Filtering and ordering Courses. */
export enum CourseField {
  /** The unique identifier of the Course. */
  Id = 'id',
  /** The Code referring to the Course. */
  Code = 'code',
  /** The name of the Course. */
  Name = 'name',
  /** The unique identifier of the Course Category. */
  CategoryId = 'categoryId',
  /** The unique identifier of the Price Level. */
  LevelId = 'levelId'
}

/** A filter applied to Courses. */
export type CourseFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field: CourseField;
  operation: FilterOperation;
};

/** An ordering applied to Courses. */
export type CourseFieldOrder = {
  field: CourseField;
  direction: OrderDirection;
};

/** A Course Objective is a Learning Objective for a specific Course. */
export type CourseObjective = LearningObjective & {
  __typename?: 'CourseObjective';
  /** The unique identifier of the Course Objective. */
  id: Scalars['ID'];
  /** Details about the specific Course. */
  course: CourseDetails;
};

/** A Course Outcome represents a Course that will be completed as part of a Learning Path by a specific Learner. */
export type CourseOutcome = LearningOutcome & {
  __typename?: 'CourseOutcome';
  /** The unique identifier of the Course Outcome. */
  id: Scalars['ID'];
  /** The Event which will be completed. */
  event?: Maybe<Event>;
};

/** Details used to Create a Cart. */
export type CreateCartInput = {
  /** The first name of the Cart owner. */
  firstName?: Maybe<Scalars['String']>;
  /** The last name of the Cart owner. */
  lastName?: Maybe<Scalars['String']>;
  /** The e-mail of the Cart owner. */
  email?: Maybe<Scalars['String']>;
  /** The Region the Cart belongs to. */
  regionId?: Maybe<Scalars['ID']>;
  /** The Currency Code the Cart belongs to. */
  currencyCode?: Maybe<Scalars['String']>;
};

/** Details used to Create a Stripe Payment Intent. */
export type CreateStripePaymentIntentInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
  /** The Stripe Details. */
  stripeDetails: StripeDetailsInput;
};

/** The result of creating a Stripe Payment Intent. */
export type CreateStripePaymentIntentResponse = {
  __typename?: 'CreateStripePaymentIntentResponse';
  /** The Payment Intent that was created. */
  paymentIntent?: Maybe<StripePaymentIntent>;
  /** The Cart the Payment Intent is for. */
  cart?: Maybe<Cart>;
  /** A list of errors encountered while running the mutation. */
  errors: Array<FieldError>;
};

/** A Currency. */
export type Currency = {
  __typename?: 'Currency';
  /** The Code of the Currency. */
  code?: Maybe<Scalars['String']>;
  /** The name of the Currency. */
  name?: Maybe<Scalars['String']>;
  /** The symbol of the Currency. */
  symbol?: Maybe<Scalars['String']>;
};

/** A Custom Field which can be used to create Custom Field Filters. */
export type CustomFieldDefinition = {
  __typename?: 'CustomFieldDefinition';
  /** The Key of the Custom Field */
  key: Scalars['ID'];
  /** The label of the Custom Field */
  label: Scalars['String'];
  /** The type of the Custom Field */
  type: CustomFieldType;
  /** A description explaining the use of the Custom Field */
  description?: Maybe<Scalars['String']>;
  /** The list of options of the choice or multichoice Custom Field */
  options?: Maybe<Array<CustomFieldOption>>;
};

/** A Custom Field Filter. */
export type CustomFieldFilter = {
  __typename?: 'CustomFieldFilter';
  /** The unique identifier of the Custom Field Filter. */
  id: Scalars['ID'];
  /** The label of the Custom Field Filter. */
  label: Scalars['String'];
  /** The type of Custom Field Filter. */
  type: Scalars['String'];
  /** The Custom Fields mapped to the Custom Field Filter. Will use these Custom Fields to apply the Filter to. */
  mappedCustomFields: Array<CustomFieldDefinition>;
};

/** A Custom Field Filter Connection. */
export type CustomFieldFilterConnection = {
  __typename?: 'CustomFieldFilterConnection';
  edges: Array<CustomFieldFilterEdge>;
  pageInfo: PageInfo;
};

/** A Custom Field Filter Edge. */
export type CustomFieldFilterEdge = {
  __typename?: 'CustomFieldFilterEdge';
  node: CustomFieldFilter;
  cursor: Scalars['String'];
};

/** Fields for filtering and ordering Custom Field Filters. */
export enum CustomFieldFilterField {
  /** A unique key for the Custom Field Filter. */
  Key = 'key',
  /** The Label of the Custom Field Filter. */
  Label = 'label',
  /** The type of Custom Field Filter. */
  Type = 'type'
}

/** A filter applied to Custom Field Filters. */
export type CustomFieldFilterFilter = {
  value?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  field: CustomFieldFilterField;
  operation: FilterOperation;
};

/** A filter applied to Custom Fields. */
export type CustomFieldFilterInput = {
  value?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  operation: CustomFieldFilterOperation;
};

/** Operations related to filtering Custom Fields. */
export enum CustomFieldFilterOperation {
  /** Field must be equal to value. */
  Eq = 'eq',
  /** Field must be like value. */
  Like = 'like',
  /** Field must be in provided values. */
  In = 'in',
  /** Field must have all provided values. */
  Contains = 'contains'
}

/** An ordering applied to Custom Field Filters. */
export type CustomFieldFilterOrder = {
  field: CustomFieldFilterField;
  direction: OrderDirection;
};

/** A single Option for a Custom Field. */
export type CustomFieldOption = {
  __typename?: 'CustomFieldOption';
  /** The label of the Custom Field Option */
  label: Scalars['String'];
  /** The value of the Custom Field Option */
  value: Scalars['String'];
};

/** Types of Custom Field. */
export enum CustomFieldType {
  /** A true or false boolean. */
  Checkbox = 'checkbox',
  /** Can pick a single option from a list. */
  Choice = 'choice',
  /** The code of a Country available in the options list. */
  Country = 'country',
  /** A calendar day. */
  Date = 'date',
  /** A local date, and time. */
  Datetime = 'datetime',
  /** A reference to a document stored in the Document Management System (DMS). */
  Document = 'document',
  /** An e-mail address. */
  Email = 'email',
  /** Formatted text stored as HTML. */
  Html = 'html',
  /** A whole number, including sign if negative. */
  Integer = 'integer',
  /** A Language available in the options list. */
  Language = 'language',
  /** Can pick multiple options from a list. When storing a value use a pipe (|) separated list of values. */
  Multichoice = 'multichoice',
  /** A number with optional decimal point. */
  Number = 'number',
  /** Short unformatted text input, suitable for a one-line input field. */
  String = 'string',
  /** Unformatted text input, suitable for a multi-line input field. */
  Text = 'text',
  /** A local time. */
  Time = 'time',
  /** A web address. */
  Url = 'url'
}

/** A Custom Field Value. */
export type CustomFieldValue = {
  __typename?: 'CustomFieldValue';
  /** The unique identifier key for the Custom Field. */
  definitionKey?: Maybe<Scalars['String']>;
  /** The value for the Custom Field. */
  value?: Maybe<Scalars['String']>;
};



/** The Delivery Method of an Event. */
export enum DeliveryMethod {
  /** Online via LMS. */
  Lms = 'lms',
  /** In a Classroom setting. */
  Classroom = 'classroom',
  /** Both Online and in a Classroom setting. */
  Blended = 'blended'
}

/** A Document Objective is a Learning Objective for a specific Document the Learner should read/fill. */
export type DocumentObjective = LearningObjective & {
  __typename?: 'DocumentObjective';
  /** The unique identifier of the Document Objective. */
  id: Scalars['ID'];
};

/** A Document Outcome represents a Document which will be read or completed as part of the Learning Path by a specific Learner. */
export type DocumentOutcome = LearningOutcome & {
  __typename?: 'DocumentOutcome';
  /** The unique identifier of the Document Outcome. */
  id: Scalars['ID'];
};

/** An attendable Event. */
export type Event = ProductOption & {
  __typename?: 'Event';
  /** The unique identifier of the Event. */
  id: Scalars['ID'];
  /** The code of the Event */
  code?: Maybe<Scalars['String']>;
  /** The name of the Event. */
  name: Scalars['String'];
  /** The Price of the Event. */
  price?: Maybe<Price>;
  /** The Tax of the Event. */
  tax?: Maybe<Tax>;
  /** The Delivery Method of the Event. */
  deliveryMethod: DeliveryMethod;
  /** The Location of the Event. */
  location: Location;
  /** The Venue of the Event. */
  venue?: Maybe<Venue>;
  /** The start of the Event. */
  start?: Maybe<Scalars['String']>;
  /** The end of the Event. */
  end?: Maybe<Scalars['String']>;
  /** The Time Zone of the Event. */
  timeZoneName?: Maybe<Scalars['String']>;
  /** How many places remain until the Event is fully booked. */
  remainingPlaces?: Maybe<Scalars['Int']>;
  /** The Course the Event was created from. */
  course?: Maybe<ProductOptionCourse>;
  /** The number of days this Event is accessible for. Only applicable for self-paced Events. */
  accessDuration?: Maybe<Scalars['Int']>;
  /** The starting date and time of the earliest, active classroom Session for the Event. */
  classroomStart?: Maybe<Scalars['String']>;
  /** The ending date and time of the latest, active classroom Session for the Event. */
  classroomEnd?: Maybe<Scalars['String']>;
  /** The starting date and time when the LMS access or self paced learning is scheduled to open. */
  lmsStart?: Maybe<Scalars['String']>;
  /** The ending date and time when the LMS access or self paced learning is scheduled to close. */
  lmsEnd?: Maybe<Scalars['String']>;
  /** The total amount of places available on the Event. */
  maxPlaces?: Maybe<Scalars['Int']>;
  /** The Prices applicable to the Event. */
  prices: Array<Price>;
  /** The Price Levels applicable to the Event. */
  priceLevels?: Maybe<Array<Maybe<PriceLevel>>>;
  /** The session information for the Event. */
  sessions: SessionConnection;
  /** The locale of the Event. */
  locale?: Maybe<Locale>;
  /** When Students can begin to Register to the Event. */
  registrationOpensAt?: Maybe<Scalars['DateTime']>;
  /** Whether the Registration for the Event is open yet. */
  registrationOpen: Scalars['Boolean'];
  /** The Categories for this Event */
  categories: Array<Category>;
};


/** An attendable Event. */
export type EventSessionsArgs = {
  filters?: Maybe<Array<Maybe<SessionFieldFilter>>>;
  order?: Maybe<SessionFieldOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
};

/** A Booking for a single Event. */
export type EventBooking = {
  __typename?: 'EventBooking';
  /** The unique identifier of the Event Booking. */
  id: Scalars['ID'];
  /** The name of the Event. */
  name: Scalars['String'];
  /** The Location of the Event. */
  location?: Maybe<Location>;
  /** The start of the Event. */
  start?: Maybe<Scalars['String']>;
  /** The end of the Event. */
  end?: Maybe<Scalars['String']>;
  /** The header image URL for the Course for the Event. */
  imageUrl?: Maybe<Scalars['String']>;
  /** The total amount of Students who have booked onto the Event. */
  bookedPlaces: Scalars['Int'];
  /** The total amount of places available on the Event. */
  maxPlaces?: Maybe<Scalars['Int']>;
  /** The total amount of places remaining on the Event. */
  remainingPlaces?: Maybe<Scalars['Int']>;
  /** The Course the Event was created from. */
  course?: Maybe<ProductOptionCourse>;
  /** The Price of the Event. */
  price?: Maybe<Price>;
  /** The Type of the Event Booking. */
  type: EventBookingType;
  /** The total amount of Unnamed Learners on this Event. */
  unnamedLearnerQuantity: Scalars['Int'];
  /** The total amount of Named Learners on this Event. */
  namedLearnerQuantity: Scalars['Int'];
  /** The Learners managed by the current Coordinator */
  learners: LearnerConnection;
};


/** A Booking for a single Event. */
export type EventBookingLearnersArgs = {
  filters?: Maybe<Array<LearnerFieldFilter>>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<LearnerFieldOrder>;
};

/** An Event Booking Connection. */
export type EventBookingConnection = {
  __typename?: 'EventBookingConnection';
  edges: Array<EventBookingEdge>;
  pageInfo: PageInfo;
};

/** An Event Booking Edge. */
export type EventBookingEdge = {
  __typename?: 'EventBookingEdge';
  node: EventBooking;
  cursor: Scalars['String'];
};

/** The type of Event Booking. */
export enum EventBookingType {
  /** Open to the Public. */
  Public = 'public',
  /** Private. */
  Private = 'private'
}

/** An Event Connection. */
export type EventConnection = {
  __typename?: 'EventConnection';
  pageInfo: PageInfo;
  edges: Array<EventEdge>;
};

/** Basic detail of the Event for an Event Objective. */
export type EventDetails = {
  __typename?: 'EventDetails';
  /** The unique identifier of the Event. */
  id: Scalars['ID'];
  /** The Code of the Event. */
  code: Scalars['String'];
  /** The name of the Event. */
  name: Scalars['String'];
  /** The start of the Event. */
  start?: Maybe<Scalars['String']>;
  /** The end of the Event. */
  end?: Maybe<Scalars['String']>;
  /** How many places remain until the Event is fully booked. */
  remainingPlaces?: Maybe<Scalars['Int']>;
  /** The Location of the Event. */
  location: Location;
};

/** An Event Edge. */
export type EventEdge = {
  __typename?: 'EventEdge';
  cursor: Scalars['String'];
  node: Event;
};

/** Fields for filtering and ordering Events. */
export enum EventField {
  /** The unique identifier of the Event. */
  Id = 'id',
  /** The name of the Event. */
  Name = 'name',
  /** The name of the Location for the Event. */
  LocationName = 'locationName',
  /** The Delivery Method of the Event. */
  DeliveryMethod = 'deliveryMethod',
  /** The code of the Course the Event was created from. */
  CourseCode = 'courseCode',
  /** The start of the Event in the event's local time (requires a datetime without offset) */
  LocalStart = 'localStart',
  /** The end of the Event in the event's local time (requires a datetime without offset) */
  LocalEnd = 'localEnd',
  /** The start of the Event (requires a time-zone aware datetime). */
  Start = 'start',
  /** The end of the Event (requires a time-zone aware datetime). */
  End = 'end',
  /** The starting date and time of the earliest, active classroom Session for the Event. */
  ClassroomStart = 'classroomStart',
  /** The ending date and time of the latest, active classroom Session for the Event. */
  ClassroomEnd = 'classroomEnd',
  /** The starting date and time when the LMS access or self paced learning is scheduled to open. */
  LmsStart = 'lmsStart',
  /** The ending date and time when the LMS access or self paced learning is scheduled to close. */
  LmsEnd = 'lmsEnd',
  /** A Learning Category associated with the Course for this Event. */
  Category = 'category',
  /** The type of Event. */
  Type = 'type',
  /** How many places remain until the Event is fully booked. */
  RemainingPlaces = 'remainingPlaces',
  /** The code of the Region for the Event. */
  RegionCode = 'regionCode',
  /** The unique identifier for the Location of the Event. */
  LocationId = 'locationId',
  /** The first Session Start Time. */
  SessionStartTime = 'sessionStartTime',
  /** The last Session End Time. */
  SessionEndTime = 'sessionEndTime',
  /** Which day of the week the Event runs on. */
  DayOfWeek = 'dayOfWeek',
  /** The Locale of the Event. */
  LocaleId = 'localeId',
  /** Whether the Event is Active and can be Booked or not. */
  IsActive = 'isActive'
}

/** A filter applied to Events. */
export type EventFieldFilter = {
  value?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['String']>>;
  field: EventField;
  operation: FilterOperation;
};

/** An ordering applied to Events. */
export type EventFieldOrder = {
  field: EventField;
  direction: OrderDirection;
};

/** An Event Learner is a Learner Registered on an Event. */
export type EventLearner = Learner & {
  __typename?: 'EventLearner';
  /** The unique identifier for the Event Learner. */
  id: Scalars['ID'];
  /** Whether the Event Learner is cancelled or not. */
  isCancelled: Scalars['Boolean'];
  /** The Contact record for the Event Learner. */
  contact?: Maybe<Contact>;
  /** The Registration for the Event Learner. */
  registration?: Maybe<Registration>;
  /** The quantity of Event Learners if the Event Learner is unnamed. */
  quantity: Scalars['Int'];
  /** Whether the Event Learner is linked to a Contact or not. */
  isUnnamed: Scalars['Boolean'];
  /** The Event Learner Custom Field Values. */
  customFieldValues: Array<CustomFieldValue>;
  /** The Learning Path of the Event Learner. */
  learningPath?: Maybe<LearningPath>;
};

/** Details used to cancel a Learner on an Event. */
export type EventLearnerCancelInput = {
  /** The unique identifier of the Learner to be cancelled. */
  learnerId: Scalars['ID'];
};

/** The result of cancelling a Learner on an Event. */
export type EventLearnerCancelResponseType = {
  __typename?: 'EventLearnerCancelResponseType';
  /** The Learner that was cancelled. */
  learner?: Maybe<EventLearner>;
  /** A list of errors encountered while running the mutation. */
  errors: Array<FieldError>;
};

/** A Line Item in a Cart representing an Event. */
export type EventLineItem = CartLineItem & {
  __typename?: 'EventLineItem';
  /** The unique identifier of the Line Item. */
  id?: Maybe<Scalars['ID']>;
  /** The number of Line Items to be sold. */
  quantity: Scalars['Int'];
  /** The price charged for a single Line Item. */
  unitAmount: Scalars['Decimal'];
  /** The tax exclusive price charged for all Items of this Line. */
  subTotalAmount: Scalars['Decimal'];
  /** The total tax amount charged for all Items of this Line. */
  totalTaxAmount: Scalars['Decimal'];
  /** The tax inclusive price charged for all Items of this Line. */
  totalAmount: Scalars['Decimal'];
  /** The Tax Type applicable to this Line Item. */
  tax?: Maybe<Tax>;
  /** The underlying Product (Event) for this Line Item. */
  productOption?: Maybe<ProductOption>;
  /** A list of Learner details for this Line Item. */
  learnerDetails?: Maybe<Array<Maybe<LearnerDetails>>>;
  /** The Pricing Agreement Price applied to this Line Item. */
  pricingAgreementPrice?: Maybe<PricingAgreementPrice>;
  /** The Line Items that are children of this Line Item. */
  childItems?: Maybe<Array<Maybe<CartLineItem>>>;
  /** Whether the Line Item can be reserved. */
  isReservable: Scalars['Boolean'];
  /** Whether the Line Item reservation has expired. */
  isExpired: Scalars['Boolean'];
  /** Whether the Line Item is reserved. */
  reserved?: Maybe<Scalars['Boolean']>;
  /** The Price Level applied to this Line Item. */
  priceLevel?: Maybe<PriceLevel>;
  /** Attribute definitions that can be provided with each Learner */
  pointOfSaleDefinitions: Array<PointOfSaleLearnerFieldDefinition>;
};

/** An Event Objective is a Learning Objective for a specific Event. */
export type EventObjective = LearningObjective & {
  __typename?: 'EventObjective';
  /** The unique identifier of the Event Objective. */
  id: Scalars['ID'];
  /** Details about the specific Event. */
  event: EventDetails;
};

/** An Event Request Workflow. */
export type EventRequestWorkflow = {
  __typename?: 'EventRequestWorkflow';
  /** Whether the ability to Request Events or not is enabled. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** When a Request has been sent, this message will be displayed. */
  successMessage?: Maybe<Scalars['String']>;
};

/** An Event Selection Option is a single Option which can be chosen to fulfill a Course Objective on a Learning Path. */
export type EventSelectionOption = {
  __typename?: 'EventSelectionOption';
  /** The unique identifier of the Event Option. */
  eventOptionId: Scalars['ID'];
  /** The Event chosen. */
  event: Event;
};

/** An Event Section Option Connection. */
export type EventSelectionOptionConnection = {
  __typename?: 'EventSelectionOptionConnection';
  pageInfo: PageInfo;
  edges: Array<EventSelectionOptionEdge>;
};

/** An Event Selection Option Edge. */
export type EventSelectionOptionEdge = {
  __typename?: 'EventSelectionOptionEdge';
  cursor: Scalars['String'];
  node: EventSelectionOption;
};

/** Fields for filtering and ordering Event Selection Options. */
export enum EventSelectionOptionField {
  /** The Code of the Course the Event was created from. */
  CourseCode = 'courseCode',
  /** The Code of Region of the Event. */
  RegionCode = 'regionCode',
  /** How many places remain until the Event is fully booked. */
  RemainingPlaces = 'remainingPlaces',
  /** The Location name of the Event. */
  LocationName = 'locationName',
  /** The unique identifier of the Location. */
  LocationId = 'locationId',
  /** The start of the Event. */
  Start = 'start',
  /** The end of the Event. */
  End = 'end'
}

/** A filter applied to Event Selection Options. */
export type EventSelectionOptionFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field: EventSelectionOptionField;
  operation: FilterOperation;
};

/** An ordering applied to Event Selection Options. */
export type EventSelectionOptionFieldOrder = {
  field: EventSelectionOptionField;
  direction: OrderDirection;
};

/** Details used to update an Existing Learner. */
export type ExistingLearnerDetailsInput = {
  /** The unique identifier of the underlying Contact for the Learner. */
  contactId: Scalars['ID'];
  /** The Learner Custom Field Values. */
  attributes?: Maybe<Array<AttributeInputType>>;
};

/** An External Objective is a Learning Objective for a specific External resource. */
export type ExternalObjective = LearningObjective & {
  __typename?: 'ExternalObjective';
  /** The unique identifier of the External Objective. */
  id: Scalars['ID'];
  /** The name of the External Objective. */
  name: Scalars['String'];
};

/** An External Outcome represents something that will be completed Externally to the Learning Path by a specific Learner. */
export type ExternalOutcome = LearningOutcome & {
  __typename?: 'ExternalOutcome';
  /** The unique identifier of the External Outcome. */
  id: Scalars['ID'];
};

/** An error returned specific to a field. */
export type FieldError = {
  __typename?: 'FieldError';
  /** Attribute the error relates to. */
  label?: Maybe<Scalars['String']>;
  /** Value that triggered the error. */
  value?: Maybe<Scalars['String']>;
  /** Error message and resolution hint. */
  message?: Maybe<Scalars['String']>;
};

/** An Operation required for Filtering. */
export enum FilterOperation {
  /** Field must be equal to value. */
  Eq = 'eq',
  /** Field must be greater than or equal to value. */
  Ge = 'ge',
  /** Field must be greater than value. */
  Gt = 'gt',
  /** Field must be null. */
  Isnull = 'isnull',
  /** Field must be less than or equal to value. */
  Le = 'le',
  /** Field must be like value. */
  Like = 'like',
  /** Field must be less than value. */
  Lt = 'lt',
  /** Field must be in provided values. */
  In = 'in',
  /** Field must not equal value. */
  Ne = 'ne',
  /** Field must not be null. */
  Notnull = 'notnull'
}

/** A Financial Unit. */
export type FinancialUnit = {
  __typename?: 'FinancialUnit';
  /** The name of the Financial Unit. */
  name: Scalars['String'];
  /** The symbol of the Financial Unit. */
  symbol: Scalars['String'];
  /** The Code of the Financial Unit. */
  code: Scalars['String'];
};

/** A Gift Voucher which can be applied to a Cart. */
export type GiftVoucher = {
  __typename?: 'GiftVoucher';
  /** The unique identifier of the Gift Voucher. */
  id: Scalars['ID'];
  /** The Gift Voucher code. */
  code: Scalars['String'];
  /** Which Currency the Gift Voucher is for. */
  currencyCode: Scalars['String'];
  /** The balance of the Gift Voucher. */
  balance: Scalars['Decimal'];
  /** When the Gift Voucher is valid until. */
  expiresAt?: Maybe<Scalars['DateTime']>;
};

/** An Application of a Gift Voucher to a Cart. */
export type GiftVoucherApplication = {
  __typename?: 'GiftVoucherApplication';
  /** The amount that the Application represents. */
  amount: Scalars['Decimal'];
  /** The Gift Voucher that was applied. */
  giftVoucher: GiftVoucher;
};

/** How a Gift Voucher is Configured. */
export type GiftVoucherConfiguration = Product & ProductOption & {
  __typename?: 'GiftVoucherConfiguration';
  /** The unique identifier of the Gift Voucher Configuration. */
  id: Scalars['ID'];
  /** The code of the Configuration */
  code?: Maybe<Scalars['String']>;
  /** The name of the Configuration. */
  name: Scalars['String'];
  /** The Code of the Currency for the Configuration. */
  currencyCode: Scalars['String'];
  /** The Price of the Configuration. */
  price?: Maybe<Price>;
  /** A Linked Item for the Configuration. */
  linkedItem?: Maybe<StockItem>;
  /** The description of the Configuration. */
  productDescription: Scalars['String'];
  /** The Category of this Gift Voucher Configuration */
  categories: Array<Category>;
};

/** A Gift Voucher Communication Template. */
export type GiftVoucherEmailTemplate = {
  __typename?: 'GiftVoucherEmailTemplate';
  /** The unique identifier of the Communication Template. */
  id: Scalars['ID'];
  /** The name of the Communication Template. */
  label: Scalars['String'];
};

/** A Gift Voucher Communication Template Connection. */
export type GiftVoucherEmailTemplateConnection = {
  __typename?: 'GiftVoucherEmailTemplateConnection';
  edges: Array<GiftVoucherEmailTemplateEdge>;
  pageInfo: PageInfo;
};

/** A Gift Voucher Communication Template Edge. */
export type GiftVoucherEmailTemplateEdge = {
  __typename?: 'GiftVoucherEmailTemplateEdge';
  cursor: Scalars['String'];
  node: GiftVoucherEmailTemplate;
};

/** Fields for filtering and ordering Gift Voucher Communication Templates. */
export enum GiftVoucherEmailTemplateField {
  /** The unique identifier of the Communication Template. */
  Id = 'id',
  /** The name of the Communication Template. */
  Label = 'label',
  /** The type of Delivery Method the Communication Template is intended to be used with. */
  Type = 'type'
}

/** An ordering applied to Gift Voucher Communication Templates. */
export type GiftVoucherEmailTemplateFieldOrder = {
  field: GiftVoucherEmailTemplateField;
  direction: OrderDirection;
};

/** A filter applied to Gift Voucher Communication Templates. */
export type GiftVoucherEmailTemplateFilter = {
  value?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  field: GiftVoucherEmailTemplateField;
  operation: FilterOperation;
};

/** A Line Item in a Cart representing a Gift Voucher. */
export type GiftVoucherLineItem = CartLineItem & {
  __typename?: 'GiftVoucherLineItem';
  /** The unique identifier of the Line Item. */
  id?: Maybe<Scalars['ID']>;
  /** The number of Line Items to be sold. */
  quantity: Scalars['Int'];
  /** The price charged for a single Line Item. */
  unitAmount: Scalars['Decimal'];
  /** The tax exclusive price charged for all Items of this Line. */
  subTotalAmount: Scalars['Decimal'];
  /** The total tax amount charged for all Items of this Line. */
  totalTaxAmount: Scalars['Decimal'];
  /** The tax inclusive price charged for all Items of this Line. */
  totalAmount: Scalars['Decimal'];
  /** The Tax Type applicable to this Line Item. */
  tax?: Maybe<Tax>;
  /** The underlying Product (Gift Voucher) for this Line Item. */
  productOption?: Maybe<ProductOption>;
  /** A list of Learner details for this Line Item. */
  learnerDetails?: Maybe<Array<Maybe<LearnerDetails>>>;
  /** Details of the recipient of the Gift Voucher for this Line Item. */
  giftVoucherRecipientDetails?: Maybe<GiftVoucherRecipientDetails>;
  /** The Pricing Agreement Price applied to this Line Item. */
  pricingAgreementPrice?: Maybe<PricingAgreementPrice>;
  /** The Line Items that are children of this Line Item. */
  childItems?: Maybe<Array<Maybe<CartLineItem>>>;
  /** Whether the Line Item can be reserved. */
  isReservable: Scalars['Boolean'];
  /** Whether the Line Item reservation has expired. */
  isExpired: Scalars['Boolean'];
  /** Whether the Line Item is reserved. */
  reserved?: Maybe<Scalars['Boolean']>;
  /** The Price Level applied to this Line Item. */
  priceLevel?: Maybe<PriceLevel>;
  /** Attribute definitions that can be provided with each Learner */
  pointOfSaleDefinitions: Array<PointOfSaleLearnerFieldDefinition>;
};

/** A Gift Voucher Recipient. */
export type GiftVoucherRecipientDetails = {
  __typename?: 'GiftVoucherRecipientDetails';
  /** The name of the Recipient. */
  name: Scalars['String'];
  /** The e-mail of the Recipient. */
  email?: Maybe<Scalars['String']>;
  /** The first line of the Recipient Postal Address. */
  postalAddressLineOne?: Maybe<Scalars['String']>;
  /** The second line of the Recipient Postal Address. */
  postalAddressLineTwo?: Maybe<Scalars['String']>;
  /** The Town of the Recipient Postal Address. */
  postalAddressTown?: Maybe<Scalars['String']>;
  /** The postcode of the Recipient Postal Address. */
  postalAddressPostcode?: Maybe<Scalars['String']>;
  /** The Country of the Recipient Postal Address. */
  postalAddressCountry?: Maybe<Scalars['String']>;
};

/** Details used to specify the Recipient of a Gift Voucher. */
export type GiftVoucherRecipientInput = {
  /** The name of the Gift Voucher Recipient. */
  name: Scalars['String'];
  /** The email address of the Gift Voucher Recipient. */
  email?: Maybe<Scalars['String']>;
  /** The Postal Address of the Gift Voucher Recipient. */
  postalAddress?: Maybe<GiftVoucherRecipientPostalAddressInput>;
  /** The personalized message to be sent to the Recipient with the Gift Voucher. */
  message?: Maybe<Scalars['String']>;
  /** The unique identifier of the Email Configuration to send to the Recipient. */
  emailConfigurationId?: Maybe<Scalars['String']>;
};

/** Details used to specify the Postal Address of a Gift Voucher Recipient. */
export type GiftVoucherRecipientPostalAddressInput = {
  /** The first line of the Gift Voucher Recipient's Postal Address. */
  postalAddressLineOne: Scalars['String'];
  /** The second line of the Gift Voucher Recipient's Postal Address. */
  postalAddressLineTwo?: Maybe<Scalars['String']>;
  /** The town of the Gift Voucher Recipient's Postal Address. */
  postalAddressTown: Scalars['String'];
  /** The postcode of the Gift Voucher Recipient's Postal Address. */
  postalAddressPostcode: Scalars['String'];
  /** The unique identifier of the Country of the Gift Voucher Recipient's Postal Address. */
  postalAddressCountryId: Scalars['String'];
};

/** Details used to authenticate with Administrate using the Identity Provider service. */
export type IdpConfiguration = {
  __typename?: 'IdpConfiguration';
  /** The Brand from the Host Lookup Service for your Portal. */
  brand?: Maybe<Scalars['String']>;
  /** The Client ID from the Host Lookup Service for your Portal. */
  clientId?: Maybe<Scalars['String']>;
  /** The Domain from the Host Lookup Service for your Portal. */
  domain?: Maybe<Scalars['String']>;
};

/** Settings used to take payments with an Invoice. */
export type Invoice = PaymentProvider & {
  __typename?: 'Invoice';
  /** The unique identifier of the Invoice Payment Provider. */
  id?: Maybe<Scalars['ID']>;
  /** The Attribute Definitions linked to the Payment Provider. */
  paymentAttributeDefinitions?: Maybe<Array<Maybe<PaymentAttributeDefinition>>>;
};

/** Details used to capture an Invoice Payment. */
export type InvoicePaymentDetailsInput = {
  /** The Billing Account to attribute the Payment to. */
  billingAccountId?: Maybe<Scalars['ID']>;
  /** The Billing e-mail to send the Invoice to. */
  billingEmail?: Maybe<Scalars['String']>;
};

/** A Learner is someone registered to a Bookable Item. */
export type Learner = {
  /** The unique identifier for the Learner. */
  id: Scalars['ID'];
  /** Whether the Learner is cancelled or not. */
  isCancelled: Scalars['Boolean'];
  /** The Contact record for the Learner. */
  contact?: Maybe<Contact>;
  /** The Registration for the Learner. */
  registration?: Maybe<Registration>;
  /** The Learner Custom Field Values. */
  customFieldValues: Array<CustomFieldValue>;
  /** The Learning Path of the Learner. */
  learningPath?: Maybe<LearningPath>;
};

/** Learner Attributes are Learner Custom Fields and their values */
export type LearnerAttributes = {
  __typename?: 'LearnerAttributes';
  /** The unique identifier key for the Learner Custom Field. */
  definitionKey?: Maybe<Scalars['String']>;
  /** The Learner Custom Field value. */
  value?: Maybe<Scalars['String']>;
};

/** A Learner Connection. */
export type LearnerConnection = {
  __typename?: 'LearnerConnection';
  edges: Array<LearnerEdge>;
  pageInfo: PageInfo;
};

/** Learner Details are details about a specific Learner. */
export type LearnerDetails = {
  __typename?: 'LearnerDetails';
  /** The first name of the Learner. */
  firstName?: Maybe<Scalars['String']>;
  /** The last name of the Learner. */
  lastName?: Maybe<Scalars['String']>;
  /** The e-mail of the Learner. */
  email?: Maybe<Scalars['String']>;
  /** The Learner Attributes beloning to the Learner. */
  attributes?: Maybe<Array<Maybe<LearnerAttributes>>>;
};

/** Details used to create/update multiple Learners. */
export type LearnerDetailsInput = {
  /** Learners without an Administrate Contact. */
  newLearners?: Maybe<Array<NewLearnerDetailsInput>>;
  /** Learners with an existing Administrate Contact. */
  existingLearners?: Maybe<Array<ExistingLearnerDetailsInput>>;
};

/** A Learner Edge. */
export type LearnerEdge = {
  __typename?: 'LearnerEdge';
  node: Learner;
  cursor: Scalars['String'];
};

/** Fields for filtering and ordering Learners. */
export enum LearnerField {
  /** The unique identifier of the Learner. */
  Id = 'id',
  /** The underlying Contact Name of the Learner. */
  ContactName = 'contactName',
  /** Whether the Learner is cancelled or not. */
  IsCancelled = 'isCancelled',
  /** Whether the Learner is unnamed or not. */
  IsUnnamed = 'isUnnamed'
}

/** A filter applied to Learners. */
export type LearnerFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field: LearnerField;
  operation: FilterOperation;
};

/** An ordering applied to Learners. */
export type LearnerFieldOrder = {
  field: LearnerField;
  direction: OrderDirection;
};

export type LearnerInputDetails = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  attributes?: Maybe<Array<Maybe<AttributeInputType>>>;
};

/** An Objective that is Part of a Learning Path. This Objective can be completed by a Learner. */
export type LearningObjective = {
  /** The unique identifier of the Learning Objective. */
  id: Scalars['ID'];
};

/** A Learning Objective Connection. */
export type LearningObjectiveConnection = {
  __typename?: 'LearningObjectiveConnection';
  pageInfo: PageInfo;
  edges: Array<LearningObjectiveEdge>;
};

/** A Learning Objective Edge. */
export type LearningObjectiveEdge = {
  __typename?: 'LearningObjectiveEdge';
  cursor: Scalars['String'];
  node: LearningObjective;
};

/** Fields for filtering and ordering Learning Objectives. */
export enum LearningObjectiveField {
  Id = 'id'
}

/** A filter applied to Learning Objectives. */
export type LearningObjectiveFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field: LearningObjectiveField;
  operation: FilterOperation;
};

/** An ordering applied to Learning Objectives. */
export type LearningObjectiveFieldOrder = {
  field: LearningObjectiveField;
  direction: OrderDirection;
};

/** A Learning Outcome is a representation of a Learner assigned to a specific Learning Objective. */
export type LearningOutcome = {
  /** The unique identifier of the Learning Outcome. */
  id: Scalars['ID'];
};

/** A Learning Outcome Connection. */
export type LearningOutcomeConnection = {
  __typename?: 'LearningOutcomeConnection';
  pageInfo: PageInfo;
  edges: Array<LearningOutcomeEdge>;
};

/** A Learning Outcome Edge. */
export type LearningOutcomeEdge = {
  __typename?: 'LearningOutcomeEdge';
  cursor: Scalars['String'];
  node: LearningOutcome;
};

/** Fields for filtering and ordering Learning Outcomes. */
export enum LearningOutcomeField {
  Id = 'id'
}

/** A filter applied to Learning Outcomes. */
export type LearningOutcomeFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field: LearningOutcomeField;
  operation: FilterOperation;
};

/** An ordering applied to Learning Outcomes. */
export type LearningOutcomeFieldOrder = {
  field: LearningOutcomeField;
  direction: OrderDirection;
};

/** Learning Paths are bundles of Events, Courses, other Learning Paths, or other External/Document Objectives. */
export type LearningPath = Product & ProductOption & {
  __typename?: 'LearningPath';
  /** The unique identifier of the Learning Path. */
  id: Scalars['ID'];
  /** The code of the Learning Path */
  code?: Maybe<Scalars['String']>;
  /** The name of the Learning Path. */
  name: Scalars['String'];
  /** The Learning Category of the Learning Path. */
  category?: Maybe<Scalars['String']>;
  /** The Price of the Learning Path. */
  price?: Maybe<Price>;
  /** The start of the Learning Path. */
  start?: Maybe<Scalars['String']>;
  /** The end of the Learning Path. */
  end?: Maybe<Scalars['String']>;
  /** How many places remain until the Learning Path is fully booked. */
  remainingPlaces?: Maybe<Scalars['Int']>;
  /** When Registration closes for the Learning Path and no more Learners can be Registered. */
  registrationDeadline?: Maybe<Scalars['DateTime']>;
  /**
   * Whether a Learner must fulfill all the Course Objectives with concrete Event
   * Objectives to be Registered on this Learning Path.
   */
  requireObjectiveFulfillment?: Maybe<Scalars['Boolean']>;
  /** The header image URL for the Learning Path. */
  imageUrl?: Maybe<Scalars['String']>;
  /** The Image Gallery URLs for the Learning Path. */
  imageUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A description of what is included in the Learning Path. */
  description?: Maybe<Scalars['String']>;
  /** The current state of the Learning Path (active, archived, cancelled, draft). */
  lifecycleState: Scalars['String'];
  /** The Custom Field values associated with the Learning Path. */
  customFieldValues: Array<CustomFieldValue>;
  /** The Prices applicable to the Learning Path. */
  prices: Array<Price>;
  /** The Price Levels applicable to the Learning Path. */
  priceLevels?: Maybe<Array<Maybe<PriceLevel>>>;
  /** The Locale of the Learning Path. */
  locale?: Maybe<Locale>;
  /** Whether the Learning Path contains only Event and External Objectives. */
  isBundle?: Maybe<Scalars['Boolean']>;
  /** Whether the Learning Path is Featured i.e. sorted to the top of the Catalog. */
  isFeatured: Scalars['Boolean'];
  /** The Learning Objectives that make up the Learning Path. These can be completed by the Learner. */
  learningObjectives: LearningObjectiveConnection;
  /** Published Training Pass Type Identifiers that grant access to the Learning Path. */
  includedInPassTypeIds: Array<Scalars['ID']>;
  /** The Categories of this Learning Path */
  categories: Array<Category>;
  /** The Learning Tags for this Learning Path. */
  learningTags: LearningTagConnection;
  /** The Accounts associated with this Learning Path. */
  accountAssociations: AccountAssociationConnection;
};


/** Learning Paths are bundles of Events, Courses, other Learning Paths, or other External/Document Objectives. */
export type LearningPathLearningObjectivesArgs = {
  filters?: Maybe<Array<LearningObjectiveFieldFilter>>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<LearningObjectiveFieldOrder>;
};


/** Learning Paths are bundles of Events, Courses, other Learning Paths, or other External/Document Objectives. */
export type LearningPathLearningTagsArgs = {
  filters?: Maybe<Array<LearningTagFieldFilter>>;
  order?: Maybe<LearningTagFieldOrder>;
};

/** A Registration on a Learning Path with one or more Learners. */
export type LearningPathBooking = {
  __typename?: 'LearningPathBooking';
  /** The unique identifier of the Learning Path Booking. */
  id: Scalars['ID'];
  /** The name of the Learning Path. */
  name: Scalars['String'];
  /** The Learning Category of the Learning Path. */
  category?: Maybe<Scalars['String']>;
  /** The Price of the Learning Path. */
  price?: Maybe<Price>;
  /** The start of the Learning Path. */
  start?: Maybe<Scalars['String']>;
  /** The end of the Learning Path. */
  end?: Maybe<Scalars['String']>;
  /** A description of what is included in the Learning Path. */
  description?: Maybe<Scalars['String']>;
  /** The header image URL for the Learning Path. */
  imageUrl?: Maybe<Scalars['String']>;
  /** The Custom Field values associated with the Learning Path. */
  customFieldValues: Array<CustomFieldValue>;
  /** Any Learners registered to the Learning Path. */
  learners: LearnerConnection;
};


/** A Registration on a Learning Path with one or more Learners. */
export type LearningPathBookingLearnersArgs = {
  filters?: Maybe<Array<LearnerFieldFilter>>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<LearnerFieldOrder>;
};

/** A Learning Path Booking Connection. */
export type LearningPathBookingConnection = {
  __typename?: 'LearningPathBookingConnection';
  edges: Array<LearningPathBookingEdge>;
  pageInfo: PageInfo;
};

/** A Learning Path Booking Edge. */
export type LearningPathBookingEdge = {
  __typename?: 'LearningPathBookingEdge';
  node: LearningPathBooking;
  cursor: Scalars['String'];
};

/** A Learning Path Connection. */
export type LearningPathConnection = {
  __typename?: 'LearningPathConnection';
  pageInfo: PageInfo;
  edges: Array<LearningPathEdge>;
};

/** Basic detail of the Learning Path for a Learning Path Objective. */
export type LearningPathDetails = {
  __typename?: 'LearningPathDetails';
  /** The unique identifier of the Learning Path. */
  id: Scalars['ID'];
  /** The name of the Learning Path. */
  name: Scalars['String'];
  /**
   * Whether a Learner must fulfill all the Course Objectives with concrete Event
   * Objectives to be Registered on this Learning Path.
   */
  requireObjectiveFulfillment: Scalars['Boolean'];
  /** Details about the nested Learning Objectives. */
  learningObjectives: LearningObjectiveConnection;
};

/** A Learning Path Edge. */
export type LearningPathEdge = {
  __typename?: 'LearningPathEdge';
  cursor: Scalars['String'];
  node: LearningPath;
};

/** Fields for filtering and ordering Learning Paths. */
export enum LearningPathField {
  /** The unique identifier of the Learning Path. */
  Id = 'id',
  /** The name of the Learning Path. */
  Name = 'name',
  /** The current state of the Learning Path (active, archived, cancelled, draft). */
  LifecycleState = 'lifecycleState',
  /** The start of the Learning Path. */
  Start = 'start',
  /** The end of the Learning Path. */
  End = 'end',
  /** When Registration closes for the Learning Path and no more Learners can be Registered. */
  IsBeforeRegistrationDeadline = 'isBeforeRegistrationDeadline',
  /** Whether the Learning Path contains only Event and External Objectives. */
  IsBundle = 'isBundle',
  /** The Location of an Event Objective on the Learning Path. */
  EventObjectiveLocationName = 'eventObjectiveLocationName',
  /** The Region Code of an Event Objective on the Learning Path. */
  EventObjectiveRegionCode = 'eventObjectiveRegionCode',
  /** The unique identifier of the Locale of the Learning Path. */
  LocaleId = 'localeId'
}

/** A filter applied to Learning Paths. */
export type LearningPathFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field: LearningPathField;
  operation: FilterOperation;
};

/** An ordering applied to Learning Paths. */
export type LearningPathFieldOrder = {
  field: LearningPathField;
  direction: OrderDirection;
};

/** A Learning Path Objective is a Learning Objective for a specific Learning Path. */
export type LearningPathObjective = LearningObjective & {
  __typename?: 'LearningPathObjective';
  /** The unique identifier of the Learning Path Objective. */
  id: Scalars['ID'];
  /** Details about the specific Learning Path. */
  learningPath: LearningPathDetails;
};

/** The Course for a Learning Path Objective. */
export type LearningPathObjectiveCourse = {
  __typename?: 'LearningPathObjectiveCourse';
  /** The name of the Course. */
  name: Scalars['String'];
};

/** Details about the Learning Path Objective. */
export type LearningPathObjectiveDetail = {
  __typename?: 'LearningPathObjectiveDetail';
  /** The Event Details of the Learning Path. */
  event: LearningPathObjectiveEventDetail;
  /** The Learning Path Objective Detail. */
  learningPathObjective: LearningPathObjectiveObjectiveDetail;
};

/** Details about an Event Objective on a Learning Path. */
export type LearningPathObjectiveEventDetail = {
  __typename?: 'LearningPathObjectiveEventDetail';
  /** The unique identifier of the Event. */
  id: Scalars['ID'];
  /** The start of the Event. */
  start?: Maybe<Scalars['String']>;
  /** The Location of the Event. */
  location?: Maybe<LearningPathObjectiveEventLocation>;
  /** The Course the Event was created from. */
  course?: Maybe<LearningPathObjectiveCourse>;
  /** How many places remain until the Event is fully booked. */
  remainingPlaces?: Maybe<Scalars['Int']>;
};

/** The Location of the underlying Event for an Event Objective on a Learning Path. */
export type LearningPathObjectiveEventLocation = {
  __typename?: 'LearningPathObjectiveEventLocation';
  /** The name of the Location. */
  name: Scalars['String'];
};

/** The Learning Path Objective Detail. */
export type LearningPathObjectiveObjectiveDetail = {
  __typename?: 'LearningPathObjectiveObjectiveDetail';
  /** The unique identifier of the Learning Path Objective Detail. */
  id: Scalars['ID'];
};

/** Details used to specify the Objective for a Learning Path. */
export type LearningPathObjectivesInput = {
  /** The unique identifier of the Objective. */
  objectiveId: Scalars['ID'];
  /** The unique identifier of the Event. */
  eventId: Scalars['ID'];
};

/** A Learning Path Outcome represents a Learning Path that will be completed as part of a Learning Path by a specific Learner. */
export type LearningPathOutcome = LearningOutcome & {
  __typename?: 'LearningPathOutcome';
  /** The unique identifier of the Learning Path Outcome. */
  id: Scalars['ID'];
};

/** A Learning Tag. */
export type LearningTag = {
  __typename?: 'LearningTag';
  /** The unique identifier of the Learning Tag. */
  id: Scalars['ID'];
  /** The name of the Learning Tag. */
  name: Scalars['String'];
};

/** A Learning Tag Connection. */
export type LearningTagConnection = {
  __typename?: 'LearningTagConnection';
  edges: Array<LearningTagEdge>;
  pageInfo: PageInfo;
};

/** A Learning Tag Edge. */
export type LearningTagEdge = {
  __typename?: 'LearningTagEdge';
  node: LearningTag;
  cursor: Scalars['String'];
};

/** Fields for filtering Learning Tags. */
export enum LearningTagField {
  /** The unique identifier of the Learning Tag. */
  Id = 'id',
  /** The name of the Learning Tag. */
  Name = 'name'
}

/** A filter applied to Learning Tags. */
export type LearningTagFieldFilter = {
  value: Scalars['String'];
  field: LearningTagField;
  operation: FilterOperation;
};

/** An ordering applied to Learning Tags. */
export type LearningTagFieldOrder = {
  field: LearningTagField;
  direction: OrderDirection;
};

/** A Locale represents a specific Language. */
export type Locale = {
  __typename?: 'Locale';
  /** The unique identifier of the Locale. */
  id: Scalars['ID'];
  /** The name of the Locale. In the format 'language' when only one system variant or 'language (details)' when multiple. */
  name: Scalars['String'];
  /** The language of the Locale. */
  language: Scalars['String'];
};

/** A Locale Connection. */
export type LocaleConnection = {
  __typename?: 'LocaleConnection';
  pageInfo: PageInfo;
  edges: Array<LocaleEdge>;
};

/** A Locale Edge. */
export type LocaleEdge = {
  __typename?: 'LocaleEdge';
  cursor: Scalars['String'];
  node: Locale;
};

/** Fields for filtering and ordering Locales. */
export enum LocaleField {
  /** The unique identifier of the Locale. */
  Id = 'id',
  /** The name of the Locale. */
  Name = 'name'
}

/** A filter applied to Locales. */
export type LocaleFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field: LocaleField;
  operation: FilterOperation;
};

/** An ordering applied to Locales. */
export type LocaleFieldOrder = {
  field: LocaleField;
  direction: OrderDirection;
};

/** A Location represents where an Event takes place. */
export type Location = {
  __typename?: 'Location';
  /** The unique identifier of the Location. */
  id: Scalars['ID'];
  /** The name of the Location. */
  name: Scalars['String'];
  /** The Region of the Location. */
  region?: Maybe<Region>;
  /** The Coordinates of the Location. */
  coordinates?: Maybe<CoordinatePair>;
};

/** A Location Connection. */
export type LocationConnection = {
  __typename?: 'LocationConnection';
  pageInfo: PageInfo;
  edges: Array<LocationEdge>;
};

/** A Location Edge. */
export type LocationEdge = {
  __typename?: 'LocationEdge';
  cursor: Scalars['String'];
  node: Location;
};

/** Fields for filtering and ordering Locations. */
export enum LocationField {
  /** The unique identifier of the Location. */
  Id = 'id',
  /** The name of the Location. */
  Name = 'name'
}

/** A filter applied to Locations. */
export type LocationFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field: LocationField;
  operation: FilterOperation;
};

/** An ordering applied to Locations. */
export type LocationFieldOrder = {
  field: LocationField;
  direction: OrderDirection;
};

/** A Mailing Address for a Check. */
export type MailingAddress = {
  __typename?: 'MailingAddress';
  /** The combined lines that comprise this Mailing Address. */
  lines: Array<Scalars['String']>;
  /** The Postcode of the Mailing Address. */
  postcode?: Maybe<Scalars['String']>;
  /** The Region of the Mailing Address. */
  region?: Maybe<Scalars['String']>;
  /** The Town of the Mailing Address. */
  town: Scalars['String'];
  /** The Country of the Mailing Address. */
  country: Country;
};

/** The Mapping between a Point of Sale Field and a Custom Field. */
export type MappedFieldType = {
  __typename?: 'MappedFieldType';
  /** The type of Entity. */
  entityType: Scalars['String'];
  /** The Custom Field name. */
  field: Scalars['String'];
};

/** Messages store the messages shown to the User when they modify a Learner's Booking. */
export type Messages = {
  __typename?: 'Messages';
  /** Shown when attempting to cancel a Learner. */
  cancellation?: Maybe<Scalars['String']>;
  /** Shown when attempting to transfer a Learner. */
  transfer?: Maybe<Scalars['String']>;
};

/** Details used to move the Cart into a pending state. */
export type MoveCartPendingInput = {
  /** The unique identifier of the Cart. */
  cartId?: Maybe<Scalars['ID']>;
};

/** Details used to move the Cart to the Entry Step. */
export type MoveCartToEntryStepInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
};

/** Available payment methods for MultiSafepay checkouts. */
export enum MspPaymentMethods {
  /** Credit card payment */
  Creditcard = 'CREDITCARD',
  /** QR code payment */
  Qr = 'QR'
}

/** Settings used to take payments with MultiSafepay. */
export type MultiSafepay = PaymentProvider & {
  __typename?: 'MultiSafepay';
  /** The unique identifier of the Payment Provider. */
  id: Scalars['ID'];
  /** The Attribute Definitions linked to the Payment Provider. */
  paymentAttributeDefinitions: Array<PaymentAttributeDefinition>;
};

/** Mutations that can be used to interact with the MultiSafepay Payment Provider. */
export type MultiSafepayMutations = {
  __typename?: 'MultiSafepayMutations';
  /** Starts a checkout with the MultiSafepay Payment Provider. */
  startCheckout?: Maybe<MultiSafepayResponse>;
};


/** Mutations that can be used to interact with the MultiSafepay Payment Provider. */
export type MultiSafepayMutationsStartCheckoutArgs = {
  input: StartMultisafepayCheckoutInput;
};

/** The result of starting a checkout with the MultiSafepay Payment Provider. */
export type MultiSafepayResponse = {
  __typename?: 'MultiSafepayResponse';
  /** The URL of the hosted payment page generated for the MultiSafepay order. */
  url: Scalars['String'];
  /** A list of errors encountered while running the mutation. */
  errors: Array<FieldError>;
};

/** A GraphQL Operation that allows you to insert new data or modify the existing data. */
export type Mutation = {
  __typename?: 'Mutation';
  /** Mutations that can be used to modify a Cart. */
  cart?: Maybe<CartMutations>;
  /** Creates a Payment Session. Used by Converge to create a valid Token for use with their API. */
  createPaymentSession: PaymentSessionCreationResponseType;
  /** Mutations that can be used to interact with the MultiSafepay Payment Provider. */
  multisafepay?: Maybe<MultiSafepayMutations>;
  /** Mutations that can be used to modify Bookings. */
  booking?: Maybe<BookingMutations>;
  /** Submits a request for training per the Training Request Workflow configured for the Portal. */
  requestTraining: RequestTrainingResponseType;
  /** Mutations that can be used to interact with the Stripe Payment Provider. */
  stripe?: Maybe<StripeMutations>;
  /** Mutations that can be used to interact with the Training Tokens payment method. */
  trainingToken?: Maybe<TrainingTokenMutations>;
  /** Mutations that can be used to interact with the Worldpay Payment Provider. */
  worldpay?: Maybe<WorldpayMutations>;
};


/** A GraphQL Operation that allows you to insert new data or modify the existing data. */
export type MutationCreatePaymentSessionArgs = {
  input: PaymentSessionCreateInput;
};


/** A GraphQL Operation that allows you to insert new data or modify the existing data. */
export type MutationRequestTrainingArgs = {
  input: TrainingRequestInput;
};

/** Details used to Create a New Learner. */
export type NewLearnerDetailsInput = {
  /** The e-mail of the Learner. */
  email?: Maybe<Scalars['String']>;
  /** The first name of the Learner. */
  firstName?: Maybe<Scalars['String']>;
  /** The last name of the Learner. */
  lastName?: Maybe<Scalars['String']>;
  /** The Learner Custom Field Values. */
  attributes?: Maybe<Array<Maybe<AttributeInputType>>>;
};

/** An Order placed by a Viewer. */
export type Order = {
  __typename?: 'Order';
  /** The unique identifier of the Order. */
  id: Scalars['ID'];
};

/** An Order Connection. */
export type OrderConnection = {
  __typename?: 'OrderConnection';
  edges: Array<OrderEdge>;
  pageInfo: PageInfo;
};

/** The Direction of Ordering. */
export enum OrderDirection {
  /** Ascending. */
  Asc = 'asc',
  /** Descending. */
  Desc = 'desc'
}

/** An Order Edge. */
export type OrderEdge = {
  __typename?: 'OrderEdge';
  node: Order;
  cursor: Scalars['String'];
};

/** A collection of URLs to redirect users to when they try to navigate to a specific page. */
export type OverrideUrls = {
  __typename?: 'OverrideUrls';
  /** The URL to redirect users to when they try to navigate to the catalogue page. */
  catalogue?: Maybe<Scalars['String']>;
  /** The URL to redirect users to when they try to navigate to the cart page. */
  cart?: Maybe<Scalars['String']>;
};

/** Relay Pagination Object. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Whether more edges exist prior to the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** startCursor is the cursor corresponding to the first node in edges. */
  startCursor: Scalars['String'];
  /** endCursor is the cursor corresponding to the last node in edges. */
  endCursor: Scalars['String'];
  /** The number of records available. */
  totalRecords: Scalars['Int'];
};

/** A PathLearner is a Learner Registered on a Learning Path. */
export type PathLearner = Learner & {
  __typename?: 'PathLearner';
  /** The unique identifier for the Path Learner. */
  id: Scalars['ID'];
  /** Whether the Path Learner is cancelled or not. */
  isCancelled: Scalars['Boolean'];
  /** The Contact record for the Path Learner. */
  contact?: Maybe<Contact>;
  /** The Registration for the Path Learner. */
  registration?: Maybe<Registration>;
  /** The Path Learner Custom Field Values. */
  customFieldValues: Array<CustomFieldValue>;
  /** The Learning Path of the Path Learner. */
  learningPath?: Maybe<LearningPath>;
  /** The Learning Outcomes associated with the Learning Path the Path Learner is registered to. */
  learningOutcomes: LearningOutcomeConnection;
};


/** A PathLearner is a Learner Registered on a Learning Path. */
export type PathLearnerLearningOutcomesArgs = {
  filters?: Maybe<Array<LearningOutcomeFieldFilter>>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<LearningOutcomeFieldOrder>;
};

/** Details used to cancel a Learner on a Path. */
export type PathLearnerCancelInput = {
  /** The unique identifier of the Learner to be cancelled. */
  learnerId: Scalars['ID'];
};

/** The result of cancelling a Learner on a Path. */
export type PathLearnerCancelResponseType = {
  __typename?: 'PathLearnerCancelResponseType';
  /** The Learner that was cancelled. */
  learner?: Maybe<PathLearner>;
  /** A list of errors encountered while running the mutation. */
  errors: Array<FieldError>;
};

/** A Line Item in a Cart representing a Learning Path. */
export type PathLineItem = CartLineItem & {
  __typename?: 'PathLineItem';
  /** The unique identifier of the Line Item. */
  id?: Maybe<Scalars['ID']>;
  /** The number of Line Items to be sold. */
  quantity: Scalars['Int'];
  /** The price charged for a single Line Item. */
  unitAmount: Scalars['Decimal'];
  /** The tax exclusive price charged for all Items of this Line. */
  subTotalAmount: Scalars['Decimal'];
  /** The total tax amount charged for all Items of this Line. */
  totalTaxAmount: Scalars['Decimal'];
  /** The tax inclusive price charged for all Items of this Line. */
  totalAmount: Scalars['Decimal'];
  /** The Tax Type applicable to this Line Item. */
  tax?: Maybe<Tax>;
  /** The underlying Product (Learning Path) for this Line Item. */
  productOption?: Maybe<ProductOption>;
  /** A list of Learner details for this Line Item. */
  learnerDetails?: Maybe<Array<Maybe<LearnerDetails>>>;
  /** The Pricing Agreement Price applied to this Line Item. */
  pricingAgreementPrice?: Maybe<PricingAgreementPrice>;
  /** The Line Items that are children of this Line Item. */
  childItems?: Maybe<Array<Maybe<CartLineItem>>>;
  /** Whether the Line Item can be reserved. */
  isReservable: Scalars['Boolean'];
  /** Whether the Line Item reservation has expired. */
  isExpired: Scalars['Boolean'];
  /** Whether the Line Item is reserved. */
  reserved?: Maybe<Scalars['Boolean']>;
  /** The Price Level applied to this Line Item. */
  priceLevel?: Maybe<PriceLevel>;
  /** A list of the details of the Objectives that have been selected for this Leanring Path. */
  objectives?: Maybe<Array<LearningPathObjectiveDetail>>;
  /** Attribute definitions that can be provided with each Learner */
  pointOfSaleDefinitions: Array<PointOfSaleLearnerFieldDefinition>;
};

/** Details used to capture any Payment Attribute values. */
export type PaymentAttribute = {
  /** The unique definition key of the Custom Field. */
  definitionKey: Scalars['String'];
  /** The value of the Payment Attribute. */
  value?: Maybe<Scalars['String']>;
};

/** Custom Fields linked to a specific Payment Provider. */
export type PaymentAttributeDefinition = {
  __typename?: 'PaymentAttributeDefinition';
  /** The unique definition key of the Custom Field. */
  key: Scalars['String'];
  /** The label of the Custom Field. */
  label: Scalars['String'];
  /** The type of Custom Field. */
  type: Scalars['String'];
  /** Options applicable to the Custom Field. */
  options?: Maybe<Array<Maybe<PaymentAttributeDefinitionOption>>>;
};

/** A single Option for a Payment Attribute Definition. */
export type PaymentAttributeDefinitionOption = {
  __typename?: 'PaymentAttributeDefinitionOption';
  /** The label of the Option. */
  label: Scalars['String'];
  /** The value of the Option. */
  value: Scalars['String'];
};

/** Details used to capture any Payment Attribute values. */
export type PaymentAttributeInput = {
  /** The unique definition key of the Custom Field. */
  definitionKey: Scalars['String'];
  /** The value of the Payment Attribute. */
  value?: Maybe<Scalars['String']>;
};

/** The result of Applying a Payment Code to a Cart. */
export type PaymentCodeApplication = {
  __typename?: 'PaymentCodeApplication';
  /** The Payment Code applied. */
  paymentCode: Scalars['String'];
  /** The underlying balance of the linked Token Issue. */
  tokenIssueBalance: Scalars['Int'];
};

/** Settings used to take Payments. */
export type PaymentProvider = {
  /** The unique identifier of the Payment Provider. */
  id?: Maybe<Scalars['ID']>;
  /** The Attribute Definitions linked to the Payment Provider. */
  paymentAttributeDefinitions?: Maybe<Array<Maybe<PaymentAttributeDefinition>>>;
};

/** Fields for Filtering and Ordering Payment Providers. */
export enum PaymentProviders {
  /** The Stripe Payment Provider. */
  Stripe = 'stripe',
  /** The MultiSafepay Payment Provider. */
  MultiSafepay = 'multiSafepay',
  /** The Check Payment Provider. */
  Check = 'check',
  /** The Invoice Payment Provider. */
  Invoice = 'invoice',
  /** The Converge Payment Provider. */
  Converge = 'converge',
  /** The Training Token Payment Provider. */
  TrainingToken = 'trainingToken',
  /** The Worldpay Payment Provider. */
  Worldpay = 'worldpay'
}

export type PaymentRegion = {
  __typename?: 'PaymentRegion';
  id: Scalars['ID'];
  paymentProviders: Array<PaymentProvider>;
  region: Region;
  currency: Currency;
};

/** Details used to Create a Payment Session. */
export type PaymentSessionCreateInput = {
  /** The unique identifier of the Payment Provider. */
  paymentProviderId: Scalars['ID'];
  /** The amount the Payment Session aims to capture. */
  amount?: Maybe<Scalars['Float']>;
};

/** The result of creating a Payment Session. */
export type PaymentSessionCreationResponseType = {
  __typename?: 'PaymentSessionCreationResponseType';
  /** A Token which can be used to authenticate with an API. */
  token: Scalars['String'];
  /** When the Token expires. */
  validUntil: Scalars['DateTime'];
};

/** Combined first, middle, and last names of a Person. */
export type PersonalName = {
  __typename?: 'PersonalName';
  /** The first name. */
  firstName?: Maybe<Scalars['String']>;
  /** The middle name. */
  middleName?: Maybe<Scalars['String']>;
  /** The last name. */
  lastName?: Maybe<Scalars['String']>;
};

/** Details used to Place the Order. */
export type PlaceOrderInput = {
  /** The unique identifier of the Cart. */
  cartId?: Maybe<Scalars['ID']>;
  /** The Payment Provider used to pay. */
  paymentProvider?: Maybe<PaymentProviders>;
  /** Stripe Details for the Order, if paying using Stripe. */
  stripeDetails?: Maybe<StripePaymentDetailsInput>;
  /** Invoice Details for the Order, if paying using Invoice. */
  invoiceDetails?: Maybe<InvoicePaymentDetailsInput>;
  /** Converge Details for the Order, if paying using Converge. */
  convergeDetails?: Maybe<ConvergePaymentDetailsInput>;
  /** Any Custom Fields captured as part of the Checkout process. */
  paymentAttributes?: Maybe<Array<PaymentAttributeInput>>;
};

/** Details used to Place the Stripe Order. */
export type PlaceStripeOrderInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
  /** Stripe Details for the Order. */
  stripeDetails?: Maybe<StripePaymentDetails>;
  /** Any Custom Fields captured as part of the Checkout process. */
  paymentAttributes?: Maybe<Array<PaymentAttribute>>;
  /** The Billing Account to attribute the Payment to. */
  billingAccountId?: Maybe<Scalars['ID']>;
};

/** Details used to Place the Training Token Order. */
export type PlaceTrainingTokenOrderInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
};

/** Fields for filtering and ordering Point of Sale Fields. */
export enum PointOfSaleField {
  /** Whether the Event Point of Sale Field is Valid for the Course Template. */
  IsValidForCourseTemplate = 'isValidForCourseTemplate'
}

/** Used to capture additional Custom Order information during the Checkout process. */
export type PointOfSaleFieldDefinition = {
  __typename?: 'PointOfSaleFieldDefinition';
  /** The unique key of the Point of Sale Field. */
  key: Scalars['ID'];
  /** Whether the Point of Sale Field must have a value entered for it. */
  isRequired: Scalars['Boolean'];
  /** The label of the Point of Sale Field. */
  label: Scalars['String'];
  /** The type fo Point of Sale Field. */
  type: PointOfSaleFieldTypeNodeEnum;
  /** Options applicable to the Point of Sale Field. */
  options?: Maybe<Array<PointOfSaleFieldOptions>>;
  /** Which section the Point of Sale Field relates to. */
  section?: Maybe<Scalars['String']>;
};

/** The type of Point of Sale Field, an Event field, or a Learning Path Field. */
export enum PointOfSaleFieldEntityType {
  /** A Point of Sale field for an Event. */
  CartLineItemEventLearner = 'CartLineItemEventLearner',
  /** A Point of Sale field for a Learning Path. */
  CartLineItemPathLearner = 'CartLineItemPathLearner'
}

/** A filter applied to Point of Sale Fields. */
export type PointOfSaleFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field?: Maybe<PointOfSaleField>;
  operation?: Maybe<FilterOperation>;
};

/** A single Option for a Point of Sale Field. */
export type PointOfSaleFieldOptions = {
  __typename?: 'PointOfSaleFieldOptions';
  /** The label of the Option. */
  label: Scalars['String'];
  /** The value of the Option. */
  value: Scalars['String'];
};

/** Types of Point of Sale Field. */
export enum PointOfSaleFieldTypeNodeEnum {
  /** A true or false boolean. */
  Checkbox = 'checkbox',
  /** Can pick a single option from a list. */
  Choice = 'choice',
  /** The code of a Country available in the options list. */
  Country = 'country',
  /** A calendar day. */
  Date = 'date',
  /** A local date, and time */
  Datetime = 'datetime',
  /** A reference to a Document stored in the Document Management System (DMS). */
  Document = 'document',
  /** An e-mail address. */
  Email = 'email',
  /** Formatted text stored as HTML. */
  Html = 'html',
  /** A whole number, including sign if negative. */
  Integer = 'integer',
  /** A Language available in the options list. */
  Language = 'language',
  /** Can pick multiple options from a list. When storing a value use a pipe (|) separated list of values. */
  Multichoice = 'multichoice',
  /** A number with optional decimal point. */
  Number = 'number',
  /** A Province available in the options list. */
  Province = 'province',
  /** Short unformatted text input, suitable for a one-line input field. */
  String = 'string',
  /** Unformatted text input, suitable for a multi-line input field. */
  Text = 'text',
  /** A local time. */
  Time = 'time',
  /** A web address. */
  Url = 'url'
}

/** A single value for a Point of Sale Field. */
export type PointOfSaleFieldValue = {
  __typename?: 'PointOfSaleFieldValue';
  /** The unique key for the Point of Sale Field. */
  pointOfSaleFieldKey: Scalars['ID'];
  /** The value of the Point of Sale Field. */
  value?: Maybe<Scalars['String']>;
};

/** Details used to capture a Point of Sale Field value. */
export type PointOfSaleFieldValueInput = {
  /** The unique key for the Point of Sale Field. */
  pointOfSaleFieldKey: Scalars['ID'];
  /** The value of the Point of Sale Field. */
  value?: Maybe<Scalars['String']>;
};

/** Used to capture additional Custom Learner information during the Checkout process. */
export type PointOfSaleLearnerFieldDefinition = {
  __typename?: 'PointOfSaleLearnerFieldDefinition';
  /** The label of the Point of Sale Field. */
  label: Scalars['String'];
  /** The unique key of the Point of Sale Field. */
  key: Scalars['String'];
  /** The description of the Point of Sale Field. */
  description?: Maybe<Scalars['String']>;
  /** The type of Point of Sale Field. */
  type: Scalars['String'];
  /** Whether the Point of Sale Field must have a value entered for it. */
  isRequired: Scalars['Boolean'];
  /** Which section the Point of Sale Field relates to. */
  section?: Maybe<Scalars['String']>;
  /** Options applicable to the Point of Sale Field. */
  options?: Maybe<Array<PointOfSaleLearnerFieldDefinitionOption>>;
  /** Which Custom Field this Point of Sale Field maps to. */
  mappedField?: Maybe<MappedFieldType>;
};

/** A single Option for a Point of Sale Learner Field. */
export type PointOfSaleLearnerFieldDefinitionOption = {
  __typename?: 'PointOfSaleLearnerFieldDefinitionOption';
  /** The label of the Option. */
  label: Scalars['String'];
  /** The value of the Option. */
  value: Scalars['String'];
};

/** A Custom Field which an be used to store information about a Course or Learning Path. */
export type PortalCustomFieldDefinition = {
  __typename?: 'PortalCustomFieldDefinition';
  /** The unique key of the Custom Field. */
  key: Scalars['String'];
  /** The label of the Custom Field. */
  label: Scalars['String'];
  /** The description of the Custom Field. */
  description?: Maybe<Scalars['String']>;
  /** Options applicable to the Custom Field. */
  options?: Maybe<Array<PortalCustomFieldDefinitionOption>>;
  /** The type of Custom Field. */
  type: Scalars['String'];
};

/** A single Option for a Custom Field. */
export type PortalCustomFieldDefinitionOption = {
  __typename?: 'PortalCustomFieldDefinitionOption';
  /** The label of the Option. */
  label: Scalars['String'];
  /** The value of the Option. */
  value: Scalars['String'];
};

/** A full Postal Address. */
export type PostalAddress = {
  __typename?: 'PostalAddress';
  /** The first line of the address, typically unit, street number, or intended recipient. */
  streetAddress1?: Maybe<Scalars['String']>;
  /** The second line of the address, typically street/building/residence address. */
  streetAddress2?: Maybe<Scalars['String']>;
  /** The third line of the address, typically locality, or further miscellaneous address information. */
  streetAddress3?: Maybe<Scalars['String']>;
  /** The city or town of the Postal Address. */
  town?: Maybe<Scalars['String']>;
  /**
   * The region, county, or state of the Postal Address.
   * @deprecated |||
   * |-|-:|
   * | Reason | Replaced by province |
   * | Removal Date | 2024-04-04 |
   * | Deprecation Date | 2024-01-04 |
   */
  region?: Maybe<Scalars['String']>;
  /** The State/Province of the Postal Address. */
  province?: Maybe<Province>;
  /** The postal code or zipcode of the Postal Address. */
  postcode?: Maybe<Scalars['String']>;
  /** The Country of the Postal Address. */
  country?: Maybe<Country>;
};

/** Details used to specify a Postal Address. */
export type PostalAddressInput = {
  /** The first line of the address, typically unit, street number, or intended recipient. */
  streetAddress1: Scalars['String'];
  /** The second line of the address, typically street/building/residence address. */
  streetAddress2?: Maybe<Scalars['String']>;
  /** The third line of the address, typically locality, or further miscellaneous address information. */
  streetAddress3?: Maybe<Scalars['String']>;
  /** The city or town. */
  town: Scalars['String'];
  /** The State/Province Code of the PostalAddress. */
  provinceCode?: Maybe<Scalars['String']>;
  /** The postal code or zipcode of the PostalAddress. */
  postcode: Scalars['String'];
  /** The unique identifier of the Country of the PostalAddress. */
  countryId: Scalars['ID'];
};

/** Details used to Prepare the Cart for Checkout. */
export type PrepareCartCheckoutInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
};

/** Details used to Prepare Items for Checkout. */
export type PrepareItemsForCheckoutInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
};

/** A Price. */
export type Price = {
  __typename?: 'Price';
  /** The amount of the Price. */
  amount?: Maybe<Scalars['Decimal']>;
  /** The Financial Unit of the Price. */
  financialUnit?: Maybe<FinancialUnit>;
  /** Which Region the Price is for. */
  region?: Maybe<Region>;
};

/** A Price Level. */
export type PriceLevel = {
  __typename?: 'PriceLevel';
  /** The unique identifier of the Price Level. */
  id: Scalars['ID'];
  /** The name of the Price Level. */
  name: Scalars['String'];
  /** The amount of the Price. */
  amount: Scalars['String'];
  /** The symbol of the Price. */
  symbol: Scalars['String'];
};

/** A Price Range. */
export type PriceRange = {
  __typename?: 'PriceRange';
  /** The minimum of the Price Range. */
  minimumPrice?: Maybe<Price>;
  /** The normal Price. */
  normalPrice?: Maybe<Price>;
  /** The maxiumum of the Price Range. */
  maximumPrice?: Maybe<Price>;
};

/** A Pricing Agreement is a Price which is associated with an Account. */
export type PricingAgreement = {
  __typename?: 'PricingAgreement';
  /** The unique identifier of the Pricing Agreement. */
  id: Scalars['ID'];
  /** The name of the Pricing Agreement. */
  name: Scalars['String'];
};

/** The underlying Price for a Pricing Agreement. */
export type PricingAgreementPrice = {
  __typename?: 'PricingAgreementPrice';
  /** The unique identifier for the Pricing Agreement Price. */
  id: Scalars['ID'];
  /** The Pricing Agreement of this Pricing Agreement Price. */
  pricingAgreement: PricingAgreement;
};

/** An entity which is advertised and displayed on this Portal. */
export type Product = {
  /** The unique identifier of the Product. */
  id: Scalars['ID'];
  /** The name of the Product. */
  name: Scalars['String'];
};

/** A Product Connection. */
export type ProductConnection = {
  __typename?: 'ProductConnection';
  edges?: Maybe<Array<Maybe<ProductEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

/** A Product Edge. */
export type ProductEdge = {
  __typename?: 'ProductEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Product>;
};

/** Fields for filtering and ordering Products. */
export enum ProductField {
  /** The unique identifier of the Product. */
  Id = 'id',
  /** The name of the Product. */
  Name = 'name'
}

/** A filter applied to Products. */
export type ProductFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field?: Maybe<ProductField>;
  operation?: Maybe<FilterOperation>;
};

/** An ordering applied to Products. */
export type ProductFieldOrder = {
  field?: Maybe<ProductField>;
  direction?: Maybe<OrderDirection>;
};

/** A Bookable instance of an entity which is advertised and displayed on this Portal. */
export type ProductOption = {
  /** The unique identifier of the Product Option. */
  id: Scalars['ID'];
  /** The code of the Product Option */
  code?: Maybe<Scalars['String']>;
  /** The name of the Product Option. */
  name: Scalars['String'];
  /** The price of the Product Option. */
  price?: Maybe<Price>;
  /** The Category of this Product Option */
  categories: Array<Category>;
};

/** A Product Option Connection. */
export type ProductOptionConnection = {
  __typename?: 'ProductOptionConnection';
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<ProductOptionEdge>>>;
};

/** A Course Product Option. */
export type ProductOptionCourse = {
  __typename?: 'ProductOptionCourse';
  /** The unique identifier of the Course. */
  id: Scalars['String'];
  /** The Code referring to the Course. */
  code: Scalars['String'];
  /** The header image URL for the Course. */
  imageUrl?: Maybe<Scalars['String']>;
  /** Published Training Pass Type Identifiers that grant access to the Course. */
  includedInPassTypeIds: Array<Scalars['ID']>;
  /** Whether the Course is Featured i.e. sorted to the top of the Catalog. */
  isFeatured: Scalars['Boolean'];
  /** The Learning Tags for this Course. */
  learningTags: LearningTagConnection;
  /** The Accounts associated with this Course. */
  accountAssociations: AccountAssociationConnection;
};


/** A Course Product Option. */
export type ProductOptionCourseLearningTagsArgs = {
  filters?: Maybe<Array<LearningTagFieldFilter>>;
  order?: Maybe<LearningTagFieldOrder>;
};

/** A Product Option Edge. */
export type ProductOptionEdge = {
  __typename?: 'ProductOptionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ProductOption>;
};

/** A Province. */
export type Province = {
  __typename?: 'Province';
  /** The name of the Province. */
  name: Scalars['String'];
  /** The ISO designated Province code. */
  code: Scalars['String'];
  /** The Country Code of the where the Province is. */
  countryCode: Scalars['String'];
};

/** A GraphQL Operation to fetch data. */
export type Query = {
  __typename?: 'Query';
  /** Retrieves a list of Events or Learning Paths that are available to book on this Portal. */
  bookables: BookableConnection;
  /** Retrieves a specific Cart. */
  cart?: Maybe<Cart>;
  /** Retrieves a list of Courses or Learning Paths that are available to book on this Portal. */
  catalogue: CatalogueConnection;
  /** Retrieves a list of Categories for this Portal. */
  categories: CategoryConnection;
  checkBrowserCompatibility?: Maybe<Scalars['Boolean']>;
  /** Retrieves a list of Countries for this Portal. */
  countries: Array<Country>;
  /** Retrieves a list of Courses for this Portal. */
  courses: CourseConnection;
  /** Retrieves a list of Events for this Portal. */
  events: EventConnection;
  /** Retrieves a Gift Voucher via the Gift Voucher code. */
  giftVoucher?: Maybe<GiftVoucher>;
  /** A test query to validate a correctly configured connection to the API. */
  hello: Scalars['String'];
  /** The Events that can be selected to fulfill a Course Objective on a Learning Path. */
  learningPathEventSelectionOptions: EventSelectionOptionConnection;
  /** Retrieves a list of Learning Paths for this Portal. */
  learningPaths: LearningPathConnection;
  /** Retrieves a list of Learning Tags. */
  learningTags: LearningTagConnection;
  /** Retrieves a list of Locales for this Portal. */
  locales: LocaleConnection;
  /** Retrieves a list of Locations for this Portal. */
  locations: LocationConnection;
  /** Retrieves a list of Accounts that a Coordinator is responsible for. */
  managedAccounts: AccountConnection;
  /** Retrieves a list of Contacts belonging to the Accounts that a Coordinator is responsible for. */
  managedContacts: ContactConnection;
  paymentRegions: Array<PaymentRegion>;
  /** Retrieves a list of fields, for this Portal, used to capture custom Learner information during the Checkout process. */
  pointOfSaleLearnerFields: Array<PointOfSaleLearnerFieldDefinition>;
  /** Retrieves a list of Point of Sale Order Fields for this Portal. */
  pointOfSaleOrderFields: Array<PointOfSaleFieldDefinition>;
  /** Retrieves a list of Point of Sale Order Field Values for this Portal. */
  pointOfSaleOrderFieldValues: Array<PointOfSaleFieldValue>;
  /** Retrieves a list of Custom Field Definitions for this Portal. */
  customFieldDefinitions?: Maybe<Array<PortalCustomFieldDefinition>>;
  /** Retrieves a list of Regions. */
  regions: RegionConnection;
  /** Retrieves the configuration and details of this Portal. */
  store: Store;
  /** Retrieves the list of Training Pass Types which are configured for this Portal. */
  trainingPassTypes: TrainingPassTypeConnection;
  /** The currently logged-in user. */
  viewer?: Maybe<Viewer>;
};


/** A GraphQL Operation to fetch data. */
export type QueryBookablesArgs = {
  filters?: Maybe<Array<BookableFieldFilter>>;
  order?: Maybe<BookableFieldOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  type?: Maybe<BookableType>;
};


/** A GraphQL Operation to fetch data. */
export type QueryCartArgs = {
  id?: Maybe<Scalars['ID']>;
};


/** A GraphQL Operation to fetch data. */
export type QueryCatalogueArgs = {
  filters?: Maybe<Array<Maybe<CatalogueFieldFilter>>>;
  order?: Maybe<CatalogueFieldOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  type?: Maybe<CatalogueType>;
  search?: Maybe<Scalars['String']>;
  customFieldFilters?: Maybe<Array<CustomFieldFilterInput>>;
};


/** A GraphQL Operation to fetch data. */
export type QueryCategoriesArgs = {
  filters?: Maybe<Array<Maybe<CategoryFieldFilter>>>;
  order?: Maybe<CategoryFieldOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


/** A GraphQL Operation to fetch data. */
export type QueryCoursesArgs = {
  filters?: Maybe<Array<Maybe<CourseFieldFilter>>>;
  order?: Maybe<CourseFieldOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


/** A GraphQL Operation to fetch data. */
export type QueryEventsArgs = {
  filters?: Maybe<Array<Maybe<EventFieldFilter>>>;
  order?: Maybe<EventFieldOrder>;
  orderBy?: Maybe<Array<EventFieldOrder>>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


/** A GraphQL Operation to fetch data. */
export type QueryGiftVoucherArgs = {
  code: Scalars['String'];
};


/** A GraphQL Operation to fetch data. */
export type QueryLearningPathEventSelectionOptionsArgs = {
  learningPathId: Scalars['ID'];
  filters?: Maybe<Array<Maybe<EventSelectionOptionFieldFilter>>>;
  order?: Maybe<EventSelectionOptionFieldOrder>;
  orderBy?: Maybe<Array<EventSelectionOptionFieldOrder>>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
};


/** A GraphQL Operation to fetch data. */
export type QueryLearningPathsArgs = {
  filters?: Maybe<Array<LearningPathFieldFilter>>;
  order?: Maybe<LearningPathFieldOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


/** A GraphQL Operation to fetch data. */
export type QueryLearningTagsArgs = {
  filters?: Maybe<Array<LearningTagFieldFilter>>;
  order?: Maybe<LearningTagFieldOrder>;
};


/** A GraphQL Operation to fetch data. */
export type QueryLocalesArgs = {
  filters?: Maybe<Array<LocaleFieldFilter>>;
  order?: Maybe<LocaleFieldOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


/** A GraphQL Operation to fetch data. */
export type QueryLocationsArgs = {
  filters?: Maybe<Array<Maybe<LocationFieldFilter>>>;
  order?: Maybe<LocationFieldOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


/** A GraphQL Operation to fetch data. */
export type QueryManagedAccountsArgs = {
  filters?: Maybe<Array<Maybe<AccountFieldFilter>>>;
  order?: Maybe<AccountFieldOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
};


/** A GraphQL Operation to fetch data. */
export type QueryManagedContactsArgs = {
  filters?: Maybe<Array<Maybe<ContactFieldFilter>>>;
  order?: Maybe<ContactFieldOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
};


/** A GraphQL Operation to fetch data. */
export type QueryPaymentRegionsArgs = {
  regionId?: Maybe<Scalars['ID']>;
  currencyCode?: Maybe<Scalars['String']>;
};


/** A GraphQL Operation to fetch data. */
export type QueryPointOfSaleLearnerFieldsArgs = {
  entityType?: Maybe<PointOfSaleFieldEntityType>;
  filters?: Maybe<Array<PointOfSaleFieldFilter>>;
};


/** A GraphQL Operation to fetch data. */
export type QueryPointOfSaleOrderFieldValuesArgs = {
  id: Scalars['ID'];
};


/** A GraphQL Operation to fetch data. */
export type QueryCustomFieldDefinitionsArgs = {
  type?: Maybe<SupportsWebLinkPortalCustomFieldEntities>;
};


/** A GraphQL Operation to fetch data. */
export type QueryRegionsArgs = {
  filters?: Maybe<Array<Maybe<RegionFieldFilter>>>;
  order?: Maybe<RegionFieldOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
};


/** A GraphQL Operation to fetch data. */
export type QueryTrainingPassTypesArgs = {
  filters?: Maybe<Array<Maybe<TrainingPassTypeFieldGraphFilter>>>;
  order?: Maybe<TrainingPassTypeFieldGraphOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** A financial zone within which a Company trades. */
export type Region = {
  __typename?: 'Region';
  /** The unique identifier of the Region. */
  id: Scalars['ID'];
  /** The code of the Region. */
  code: Scalars['String'];
  /** The name of the Region. */
  name: Scalars['String'];
};

/** A Region Connection. */
export type RegionConnection = {
  __typename?: 'RegionConnection';
  edges: Array<RegionEdge>;
  pageInfo: PageInfo;
};

/** A Region Edge. */
export type RegionEdge = {
  __typename?: 'RegionEdge';
  node: Region;
  cursor: Scalars['String'];
};

/** Fields for filtering and ordering Regions. */
export enum RegionField {
  /** The unique identifier of the Region. */
  Id = 'id',
  /** The code of the Region. */
  Code = 'code',
  /** The name of the Region. */
  Name = 'name'
}

/** A filter applied to Regions. */
export type RegionFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field: RegionField;
  operation: FilterOperation;
};

/** An ordering applied to Regions. */
export type RegionFieldOrder = {
  field: RegionField;
  direction: OrderDirection;
};

/** A Learner or Learning Path Learner registered to piece of training. */
export type Registerable = {
  __typename?: 'Registerable';
  /** The unique identifier of the Registerable. */
  id?: Maybe<Scalars['ID']>;
  /** The type of the Registerable. */
  type?: Maybe<Scalars['String']>;
};

/** A Registerable Connection. */
export type RegisterableConnection = {
  __typename?: 'RegisterableConnection';
  edges?: Maybe<Array<Maybe<RegisterableEdge>>>;
};

/** A Registerable Edge. */
export type RegisterableEdge = {
  __typename?: 'RegisterableEdge';
  node?: Maybe<Registerable>;
};

/** Details used to register Learners onto a Private Event. */
export type RegisterLearnersOntoPrivateBookingInput = {
  /** The unique identifier of the Event. */
  bookingId: Scalars['ID'];
  /** The quantity of Learners to be registered. */
  quantity: Scalars['Int'];
  /** A list of the details of the Learners to be registered onto the Event. */
  learners?: Maybe<LearnerDetailsInput>;
};

/** The result of registering Learners onto a Private Event. */
export type RegisterLearnersOntoPrivateBookingResponse = {
  __typename?: 'RegisterLearnersOntoPrivateBookingResponse';
  /** The updated Event Booking. */
  booking?: Maybe<EventBooking>;
  /** A list of errors encountered while running the mutation. */
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

/** An order used to purchase training and materials. */
export type Registration = {
  __typename?: 'Registration';
  /** The unique identifier of the Registration. */
  id: Scalars['ID'];
  /** A unique reference number for the Registration. */
  registrationNumber: Scalars['String'];
  /** A list of Learners linked to this Registration. */
  registerables?: Maybe<RegisterableConnection>;
};

/** Details used to remove a Line Item to a Cart. */
export type RemoveCartLineItemInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
  /** The unique identifier of the Line Item to be removed. */
  itemId: Scalars['ID'];
};

/** Details used to remove a Promotion Code from a Cart. */
export type RemovePromotionCodeInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
  /** The Promotion Code to be removed. */
  promotionCode: Scalars['String'];
};

export type RequestFieldInput = {
  /** The unique identifier of the Training Request Field */
  id: Scalars['ID'];
  /** The value of the Training Request Field */
  value?: Maybe<Scalars['String']>;
};

/** The result of submitting a training request. */
export type RequestTrainingResponseType = {
  __typename?: 'RequestTrainingResponseType';
  /** The submitted request for training. */
  trainingRequest?: Maybe<TrainingRequest>;
  /** A list of errors encountered while running the mutation. */
  errors: Array<FieldError>;
};

/** A period of time used for training. */
export type Session = {
  __typename?: 'Session';
  /** The unique identifier of the Session. */
  id: Scalars['ID'];
  /** The name of the Session. */
  name: Scalars['String'];
  /** The date and time that the Session starts. */
  start?: Maybe<Scalars['DateTime']>;
  /** The date and time that the Session ends. */
  end?: Maybe<Scalars['DateTime']>;
  /** The location that the Session takes place in. */
  location?: Maybe<Location>;
};

/** A Session Connection. */
export type SessionConnection = {
  __typename?: 'SessionConnection';
  pageInfo: PageInfo;
  edges: Array<SessionEdge>;
};

/** A Session Edge. */
export type SessionEdge = {
  __typename?: 'SessionEdge';
  cursor: Scalars['String'];
  node: Session;
};

/** Fields for filtering and ordering Sessions */
export enum SessionField {
  /** The unique identifier of the Session. */
  Id = 'id',
  /** The name of the Session. */
  Name = 'name'
}

/** A filter applied to Sessions. */
export type SessionFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field: SessionField;
  operation: FilterOperation;
};

/** An ordering applied to Sessions. */
export type SessionFieldOrder = {
  field: SessionField;
  direction: OrderDirection;
};

/** Details used to start a checkout with the MultiSafepay Payment Provider. */
export type StartMultisafepayCheckoutInput = {
  /** The unique identifier of the Cart that the checkout is for. */
  cartId: Scalars['ID'];
  /** The payment method to use for the checkout. */
  paymentMethod: MspPaymentMethods;
  /** The URL that the user has come from. They will be redirected to this URL if they cancel the payment. */
  originalSite?: Maybe<Scalars['String']>;
  /** The URL that the user should be redirected to after they successfully complete the checkout. */
  postCheckoutRedirectUrl?: Maybe<Scalars['String']>;
  /** The locale of the user. This is used to display the hosted payment page in the correct language. */
  locale?: Maybe<Scalars['String']>;
};

/** Details used to start a checkout with the Worldpay Payment Provider. */
export type StartWorldpayCheckoutInput = {
  /** The unique identifier of the Cart that the checkout is for. */
  cartId: Scalars['ID'];
  /** The URL that the user has come from. They will be redirected to this URL if they cancel the payment. */
  originalSite?: Maybe<Scalars['String']>;
  /** The URL that the user should be redirected to after they successfully complete the checkout. */
  postCheckoutRedirectUrl?: Maybe<Scalars['String']>;
  /** The locale of the user. This is used to display the hosted payment page in the correct language. */
  locale?: Maybe<Scalars['String']>;
};

/** A purchasable item. */
export type StockItem = Product & ProductOption & {
  __typename?: 'StockItem';
  /** The unique identifier of the Stock Item. */
  id: Scalars['ID'];
  /** The code of the Stock Item */
  code?: Maybe<Scalars['String']>;
  /** The name of the Stock Item. */
  name: Scalars['String'];
  /** The Price of the Stock Item. */
  price?: Maybe<Price>;
  /** The full description of the Stock Item. */
  description?: Maybe<Scalars['String']>;
  /** The Categories for this Stock Item */
  categories: Array<Category>;
};

/** A Line Item in a Cart representing a Stock Item. */
export type StockItemLineItem = CartLineItem & {
  __typename?: 'StockItemLineItem';
  /** The unique identifier of the Line Item. */
  id?: Maybe<Scalars['ID']>;
  /** The number of Line Items to be sold. */
  quantity: Scalars['Int'];
  /** The price charged for a single Line Item. */
  unitAmount: Scalars['Decimal'];
  /** The tax exclusive price charged for all Items of this Line. */
  subTotalAmount: Scalars['Decimal'];
  /** The total tax amount charged for all Items of this Line. */
  totalTaxAmount: Scalars['Decimal'];
  /** The tax inclusive price charged for all Items of this Line. */
  totalAmount: Scalars['Decimal'];
  /** The Tax Type applicable to this Line Item. */
  tax?: Maybe<Tax>;
  /** The underlying Product (Stock Item) for this Line Item. */
  productOption?: Maybe<ProductOption>;
  /** A list of Learner details for this Line Item. */
  learnerDetails?: Maybe<Array<Maybe<LearnerDetails>>>;
  /** The Pricing Agreement Price applied to this Line Item. */
  pricingAgreementPrice?: Maybe<PricingAgreementPrice>;
  /** The Line Items that are children of this Line Item. */
  childItems?: Maybe<Array<Maybe<CartLineItem>>>;
  /** Whether the Line Item can be reserved. */
  isReservable: Scalars['Boolean'];
  /** Whether the Line Item reservation has expired. */
  isExpired: Scalars['Boolean'];
  /** Whether the Line Item is reserved. */
  reserved?: Maybe<Scalars['Boolean']>;
  /** The Price Level applied to this Line Item. */
  priceLevel?: Maybe<PriceLevel>;
  /** Attribute definitions that can be provided with each Learner */
  pointOfSaleDefinitions: Array<PointOfSaleLearnerFieldDefinition>;
};

/** The configuration of the Portal. */
export type Store = {
  __typename?: 'Store';
  /** Retrieves a list of Custom Field Filters for this Portal. */
  customFieldFilters: CustomFieldFilterConnection;
  /** Retrieves a list of Gift Voucher Communication Templates for this Portal. */
  giftVoucherEmailTemplates: GiftVoucherEmailTemplateConnection;
  /** The Region that the Portal is locked to, if applicable. */
  region?: Maybe<Region>;
  /** The Currency that the Portal is locked to, if applicable. */
  currency?: Maybe<Currency>;
  /** The payment methods available for the Portal. */
  paymentMethods: Array<PaymentProvider>;
  /** Whether the WebLink Portal is indexable by search engines. */
  indexable?: Maybe<Scalars['Boolean']>;
  /**
   * An error message to display to customers when there is an error. This could
   * include contact details for your customer support.
   */
  defaultErrorMessage?: Maybe<Scalars['String']>;
  /** The name of the WebLink store. */
  name?: Maybe<Scalars['String']>;
  /** The Administrate instance that the Portal is connected to. */
  instanceCode?: Maybe<Scalars['String']>;
  /** A list of agreements that customers must accept before making a booking. */
  terms?: Maybe<Array<Maybe<Term>>>;
  /** Whether Prices for products in the Portal include tax. */
  taxInclusive?: Maybe<Scalars['Boolean']>;
  /** Whether Prices set in the TMS for products in the Portal are already tax inclusive. */
  isTaxInclusiveOnly: Scalars['Boolean'];
  /** Whether customers have to confirm their email address while checking out. */
  confirmEmails?: Maybe<Scalars['Boolean']>;
  /** Whether Prices for products in the Portal should be hidden. */
  hidePrices?: Maybe<Scalars['Boolean']>;
  /** Whether Prices for products in the Portal should be hidden until a Pricing Agreement has been applied. */
  hidePricesBeforePricingAgreementApplied: Scalars['Boolean'];
  /** Whether the Portal should direct users straight to checkout for a product instead of allowing them to add to a Cart. */
  cartlessCheckout: Scalars['Boolean'];
  /** Whether customers are required to fill in information about the Learners for the products they are purchasing. */
  requireLearnerDetails: Scalars['Boolean'];
  /** Whether customers are required to login in order to complete a purchase. */
  requireLoginOnCheckout: Scalars['Boolean'];
  /** Whether products added to a Cart should be marked as reserved. */
  reserveOnItemAdd: Scalars['Boolean'];
  /** Whether the Company field should show during checkout. */
  hideCompanyFieldForBooker: Scalars['Boolean'];
  /** Whether customers are required to fill in their billing address during checkout. */
  requireBookerBillingAddress: Scalars['Boolean'];
  /** The settings for Gift Vouchers on the Portal. */
  giftVoucherConfiguration?: Maybe<GiftVoucherConfiguration>;
  /** The Price Levels configured for the Portal. */
  priceLevels: Array<Maybe<PriceLevel>>;
  /** Redirects configured for when a user tries to navigate to a specific Portal page. */
  overrideUrls?: Maybe<OverrideUrls>;
  /** A list of Countries for which shipping/delivery is supported. */
  postalCountries: Array<Country>;
  /** Details used to authenticate with Administrate using the identity provider service. */
  idpConfiguration: IdpConfiguration;
  multiRegion: Scalars['Boolean'];
  /**
   * Custom messages shown to the user in a specific situation. These can include
   * warnings or extra information that may be relevant.
   */
  messages?: Maybe<Messages>;
  /**
   * Whether customers are allowed to checkout via the Invoice option. Only
   * relevant when the Portal is linked with an LMS as part of a Booking Portal.
   */
  canSelfCheckoutByInvoice: Scalars['Boolean'];
  /** Whether Administrate's internal product analytics are enabled for the Store. */
  analyticsEnabled: Scalars['Boolean'];
  /** The training request workflow configured for this Portal. */
  trainingRequestWorkflow?: Maybe<TrainingRequestWorkflow>;
};

/** Settings used to take payments with Stripe. */
export type Stripe = PaymentProvider & {
  __typename?: 'Stripe';
  /** The unique identifier of the Payment Provider. */
  id?: Maybe<Scalars['ID']>;
  /** The public key provided by Stripe and used to create payments. */
  publicKey?: Maybe<Scalars['String']>;
  /** The Attribute Definitions linked to the Payment Provider. */
  paymentAttributeDefinitions?: Maybe<Array<Maybe<PaymentAttributeDefinition>>>;
};

/** Details used to Create the Stripe Payload */
export type StripeDetailsInput = {
  /** The result of a 'create_payment_method' call in Stripe e.g. pm_1Mqd4w2eZvKYlo2CgmxZMhWp. */
  paymentMethod: Scalars['String'];
};

/** Mutations that can be used to interact with the Stripe Payment Provider. */
export type StripeMutations = {
  __typename?: 'StripeMutations';
  /** Creates a Stripe Payment Intent. */
  createPaymentIntent: CreateStripePaymentIntentResponse;
  /** Complete the checkout for a Cart using Stripe and create a Registration and Invoice. */
  placeOrder?: Maybe<CartMutateResponseType>;
};


/** Mutations that can be used to interact with the Stripe Payment Provider. */
export type StripeMutationsCreatePaymentIntentArgs = {
  input: CreateStripePaymentIntentInput;
};


/** Mutations that can be used to interact with the Stripe Payment Provider. */
export type StripeMutationsPlaceOrderArgs = {
  input?: Maybe<PlaceStripeOrderInput>;
};

/** Details used to capture a Stripe Payment. */
export type StripePaymentDetails = {
  /** The unique identifier of the Stripe Payment Intent. */
  paymentIntentId: Scalars['String'];
};

/** Details used to capture a Stripe Payment. */
export type StripePaymentDetailsInput = {
  /** The result of a 'create_payment_method' call in Stripe e.g. pm_1Mqd4w2eZvKYlo2CgmxZMhWp. */
  paymentMethod: Scalars['String'];
  /** The Billing Account to attribute the Payment to. */
  billingAccountId?: Maybe<Scalars['ID']>;
};

/** An Intent to Pay with Stripe. */
export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  /** The unique identifier of the Payment Intent. */
  id: Scalars['String'];
  /** The Stripe Client Secret. Used for client-side retrieval using a publishable key. */
  clientSecret: Scalars['String'];
  /** If the payment requires additional actions, such as authenticating with 3D Secure, this will be true. */
  requiresAction: Scalars['Boolean'];
};

/** Details used to submit a Cart be reviewed. */
export type SubmitCartForReviewInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
};

/** Which types support Custom Fields. */
export enum SupportsWebLinkPortalCustomFieldEntities {
  /** Courses. */
  CourseCard = 'CourseCard',
  /** Learning Paths. */
  LearningPathCard = 'LearningPathCard'
}

export type SwitchPaymentRegionInput = {
  /** ID of the Cart. */
  cartId: Scalars['ID'];
  /** Currency Code to switch the Cart to. */
  currencyCode: Scalars['String'];
  /** Region ID to switch the Cart to. */
  regionId: Scalars['ID'];
};

/** A tax rate which can be applied to a financial amount to calculate the taxable amount. */
export type Tax = {
  __typename?: 'Tax';
  /** The unique identifier of the Tax. */
  id: Scalars['ID'];
  /** The tax rate that is applied for this Tax. */
  effectiveRate: Scalars['Decimal'];
  /** The name of the Tax. */
  name?: Maybe<Scalars['String']>;
};

/** A statement of agreement that customers must accept before completing checkout. */
export type Term = {
  __typename?: 'Term';
  /** The title of the Term. */
  title?: Maybe<Scalars['String']>;
  /** The main text of the Term. */
  text?: Maybe<Scalars['String']>;
};

/** A Training Token which can be used in-place of Currency when completing a Checkout. */
export type TokenType = {
  __typename?: 'TokenType';
  /** The unique identifier of the Training Token Type. */
  id: Scalars['String'];
  /** The name of the Training Token Type. */
  name: Scalars['String'];
};

/** A type of Training Pass */
export type TrainingPassType = {
  __typename?: 'TrainingPassType';
  /** The unique identifier of the Training Pass Type. */
  id: Scalars['ID'];
  /** The code of the Training Pass Type. */
  code?: Maybe<Scalars['String']>;
  /** The name of the Training Pass Type. */
  name?: Maybe<Scalars['String']>;
  /** The full description of the Training Pass Type. Supports HTML. */
  description?: Maybe<Scalars['String']>;
};

/** A Training Pass Type Connection. */
export type TrainingPassTypeConnection = {
  __typename?: 'TrainingPassTypeConnection';
  pageInfo: PageInfo;
  edges: Array<TrainingPassTypeEdge>;
};

/** A Training Pass Type Edge. */
export type TrainingPassTypeEdge = {
  __typename?: 'TrainingPassTypeEdge';
  cursor: Scalars['String'];
  node: TrainingPassType;
};

/** Fields for filtering and ordering Training Pass Types. */
export enum TrainingPassTypeField {
  /** The unique identifier of the Training Pass Type. */
  Id = 'id',
  /** The code of the Training Pass Type. */
  Code = 'code',
  /** The name of the Training Pass Type. */
  Name = 'name'
}

/** A filter applied to Training Pass Types. */
export type TrainingPassTypeFieldGraphFilter = {
  value?: Maybe<Scalars['String']>;
  field: TrainingPassTypeField;
  operation: FilterOperation;
};

/** An ordering applied to Training Pass Types. */
export type TrainingPassTypeFieldGraphOrder = {
  field: TrainingPassTypeField;
  direction: OrderDirection;
};

/** A request for training that has been submitted by a customer. */
export type TrainingRequest = {
  __typename?: 'TrainingRequest';
  /** The unique identifier of the training request. */
  id: Scalars['ID'];
};

/** Details used to submit a training request. */
export type TrainingRequestInput = {
  /** The unique identifier of the product (Course / Learning Path) this training request is for. */
  interestId?: Maybe<Scalars['ID']>;
  /** The unique identifier of the Region that the training request should be created in. */
  regionId: Scalars['ID'];
  /** Whether the training request is for Private or Public training. */
  isPrivate: Scalars['Boolean'];
  /** The list of values for the fields in the training request form. */
  requestFields: Array<RequestFieldInput>;
  /** The name of the training request. */
  name?: Maybe<Scalars['String']>;
};

export type TrainingRequestWorkflow = {
  __typename?: 'TrainingRequestWorkflow';
  /** The unique identifier of the Training Request Workflow. */
  id: Scalars['ID'];
  /** Whether customers will be able to request training using this workflow. */
  enabled: Scalars['Boolean'];
  /** A message shown to customers upon successfully submitting the training request form. */
  successMessage?: Maybe<Scalars['String']>;
  /** The Training Request Workflow Fields that make up the training request form for this workflow. */
  workflowFields: Array<TrainingRequestWorkflowField>;
};

export type TrainingRequestWorkflowField = {
  __typename?: 'TrainingRequestWorkflowField';
  /** The unique identifier of the Training Request Workflow Field. */
  id: Scalars['ID'];
  /** The label to be shown to the customer when displaying the Training Request Workflow Field. */
  label: Scalars['String'];
  /** The display position of the Training Request Workflow Field on the training request form. */
  position: Scalars['Int'];
  /** Whether this Training Request Workflow Field must be filled out in order to submit the training request form. */
  required: Scalars['Boolean'];
  /** The type of data that the Training Request Workflow Field captures. */
  type: CustomFieldType;
  /** The list of options available for the Training Request Workflow Field. Only relevant for `choice` or `multichoice` types. */
  options: Array<CustomFieldOption>;
};

/** Settings used to take payments with Training Tokens. */
export type TrainingToken = PaymentProvider & {
  __typename?: 'TrainingToken';
  /** The unique identifier of the Payment Provider. */
  id: Scalars['ID'];
  /** The Attribute Definitions linked to the Payment Provider. */
  paymentAttributeDefinitions: Array<PaymentAttributeDefinition>;
  /** The Training Token Types available on this Portal. */
  tokenTypes: Array<TokenType>;
};

/** Mutations that can be used to interact with Training Tokens. */
export type TrainingTokenMutations = {
  __typename?: 'TrainingTokenMutations';
  /** Complete the checkout for a Cart using Training Tokens and create a Registration and Invoice. */
  placeOrder?: Maybe<CartMutateResponseType>;
};


/** Mutations that can be used to interact with Training Tokens. */
export type TrainingTokenMutationsPlaceOrderArgs = {
  input?: Maybe<PlaceTrainingTokenOrderInput>;
};

/** Details used to transfer a Learner from one Event to another Event. */
export type TransferLearnerInput = {
  /** The unique identifier of the Learner to be transferred. */
  learnerId: Scalars['ID'];
  /** The unique identifier of the Event the Learner is to be transferred to. */
  eventId: Scalars['ID'];
};

/** The result of transferring a Learner. */
export type TransferLearnerResponseType = {
  __typename?: 'TransferLearnerResponseType';
  /** The Learner that was transferred. */
  learner?: Maybe<EventLearner>;
  /** A list of errors encountered while running the mutation. */
  errors: Array<FieldError>;
};

/** Details used to update the Financial Unit of a Cart. */
export type UpdateCartFinancialUnitInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
  /** The unique identifier of the Financial Unit. */
  financialUnitId: Scalars['ID'];
};

/** Details used to update a Cart. */
export type UpdateCartInput = {
  /** The unique identifier of the Cart. */
  id: Scalars['ID'];
  /** The first name of the person who is making the booking. */
  firstName: Scalars['String'];
  /** The last name of the person who is making the booking. */
  lastName: Scalars['String'];
  /** The email address of the person who is making the booking. */
  email: Scalars['String'];
  /** The company that the person who is making the booking is from. */
  company?: Maybe<Scalars['String']>;
  /** The billing address of the person who will be paying for the booking. */
  billingAddress?: Maybe<PostalAddressInput>;
  /** A list of values for the Point of Sale Fields for the booking. */
  pointOfSaleFields?: Maybe<Array<PointOfSaleFieldValueInput>>;
  /** The locale of the user. */
  locale?: Maybe<Scalars['String']>;
};

/** Details used to update an Event in a Cart. */
export type UpdateEventLineItemInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
  /** The unique identifier of the Event Interest. */
  interestId: Scalars['ID'];
  /** An updated list of details of the Event Learners. */
  learnerDetails: Array<Maybe<LearnerInputDetails>>;
  /** The updated quantity of this interest in the Cart. */
  quantity: Scalars['Int'];
};

/** Details used to update a Gift Voucher in a Cart. */
export type UpdateGiftVoucherLineItemInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
  /** The unique identifier of the Gift Voucher Interest. */
  interestId: Scalars['ID'];
  /** The details of the Recipient of the Gift Voucher. */
  giftVoucherRecipientDetails: GiftVoucherRecipientInput;
};

/** Details used to update a Learning Path in a Cart. */
export type UpdatePathLineItemInput = {
  /** The unique identifier of the Cart. */
  cartId: Scalars['ID'];
  /** The unique identifier of the Learning Path Interest. */
  interestId: Scalars['ID'];
  /** An updated list of details of the Learning Path Learners. */
  learnerDetails: Array<Maybe<LearnerInputDetails>>;
  /** The updated quantity of this interest in the Cart. */
  quantity: Scalars['Int'];
};

/** A URL Objective is a Learning Objective for a specific URL that the Learner should visit. */
export type UrlObjective = LearningObjective & {
  __typename?: 'URLObjective';
  /** The unique identifier of the URL Objective. */
  id: Scalars['ID'];
};

/** A URL Outcome represents a URL that will be visited as part of the Learning Path by a specific Learner. */
export type UrlOutcome = LearningOutcome & {
  __typename?: 'URLOutcome';
  /** The unique identifier of the URL Outcome. */
  id: Scalars['ID'];
};

/** A building that training takes place in. */
export type Venue = {
  __typename?: 'Venue';
  /** The unique identifier of the Venue. */
  id?: Maybe<Scalars['ID']>;
  /** The name of the Venue. */
  name?: Maybe<Scalars['String']>;
};

/** A logged-in user. */
export type Viewer = {
  __typename?: 'Viewer';
  /** Retrieves a list of Carts associated with the Viewer. */
  carts: CartConnection;
  /** All Event Bookings for managed accounts if viewer is a Coordinator. */
  eventBookings: EventBookingConnection;
  /** Most recently updated, open Cart, claimed by the current viewer. */
  lastCart?: Maybe<Cart>;
  /** All Learning Path Bookings for Managed Accounts if Viewer is a Coordinator. */
  learningPathBookings: LearningPathBookingConnection;
  /** Retrieves a list of Orders placed by a Viewer. */
  orders: OrderConnection;
  /** The Contact associated with the user. */
  contact: Contact;
};


/** A logged-in user. */
export type ViewerCartsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


/** A logged-in user. */
export type ViewerEventBookingsArgs = {
  filters?: Maybe<Array<EventFieldFilter>>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<EventFieldOrder>;
};


/** A logged-in user. */
export type ViewerLearningPathBookingsArgs = {
  filters?: Maybe<Array<LearningPathFieldFilter>>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<LearningPathFieldOrder>;
};


/** A logged-in user. */
export type ViewerOrdersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};

/** Settings used to take payments with Worldpay. */
export type Worldpay = PaymentProvider & {
  __typename?: 'Worldpay';
  /** The unique identifier of the Payment Provider. */
  id: Scalars['ID'];
  /** The Attribute Definitions linked to the Payment Provider. */
  paymentAttributeDefinitions: Array<PaymentAttributeDefinition>;
};

/** Mutations that can be used to interact with the Worldpay Payment Provider. */
export type WorldpayMutations = {
  __typename?: 'WorldpayMutations';
  /** Starts a checkout with the Worldpay Payment Provider. */
  startCheckout?: Maybe<WorldpayUrlResponse>;
};


/** Mutations that can be used to interact with the Worldpay Payment Provider. */
export type WorldpayMutationsStartCheckoutArgs = {
  input: StartWorldpayCheckoutInput;
};

/** The result of starting a checkout with the Worldpay Payment Provider. */
export type WorldpayUrlResponse = {
  __typename?: 'WorldpayUrlResponse';
  /** The URL of the hosted payment page generated for the Worldpay order. */
  url: Scalars['String'];
  /** A list of errors encountered while running the mutation. */
  errors: Array<FieldError>;
};
