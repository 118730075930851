import { useParams } from "../../useHistory";
import { useGlobalStore } from "../../providers/GlobalStore";

export const IdentityProviderResetRedirect: React.FunctionComponent = () => {
  const { passwordResetToken } = useParams<{passwordResetToken:string}>();
  const globalStore = useGlobalStore();

  window.location.href = `https://${globalStore.config.idp.domain}/reset/${passwordResetToken}?brand=${globalStore.config.brand}`;

  return null;
};
