import {
  Button,
  Col,
  Input,
  LoadingBar,
  Prompt,
  Row,
  StaticFormGroup,
  UtilityStyle,
} from "@administrate/piston-ux";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWebLinkMutation } from "../../hooks/weblink";
import { APPLY_PAYMENT_CODE } from "../../queries/trainingTokens";
import {
  Cart,
  CartLineItem,
  FieldError,
  Mutation,
  TrainingToken,
} from "../../generated/weblinkTypes";
import { Maybe } from "../../types/Maybe";
import { useFormContext } from "@administrate/piston-ux/lib/Form";
import { useCartRemovedLineItemsContext } from "../../providers/CartRemovedLineItemsProvider";
import {
  FeedbackTooltip,
  FeedbackType,
} from "@administrate/piston-ux/lib/Tooltip";
import { ApolloQueryResult } from "apollo-boost";
import { TFunction } from "i18next";
import {
  InvalidLineItemInfo,
  INVALID_API_ERROR_TO_DISPLAY_ERROR,
  useCartInvalidLineItemsContext,
} from "../../providers/CartInvalidLineItemsProvider";

const apiErrorToFrontendErrorMap = (t: TFunction): Record<string, string> => ({
  "Payment Code Not Found": t("paymentCodeNotRecognized"),
  "This Payment Code is for an Expired Token Issue": t("paymentCodeExpired"),
  "This Payment Code is for an Archived Token Type": t(
    "paymentCodeTokenTypeArchived",
  ),
  "An Interest on the Opportunity has a start date after the Token Issue expiry date":
    t("itemsNotAvailableInTokenPrice"),
});

function getFrontendErrorFromApiError(
  error: Maybe<FieldError>,
  t: TFunction,
): string {
  // TODO: Make this better, how should we handle the variety of other possible errors?
  const errorMap = apiErrorToFrontendErrorMap(t);

  if (!error || !error.message || !(error.message in errorMap))
    return t("updateError");

  return errorMap[error.message];
}

function getInvalidCartItemInformation(
  errors: Maybe<FieldError>[],
  cartItems: Maybe<CartLineItem>[],
  t: TFunction,
) {
  const invalidIds: Record<string, string> = {};
  errors.forEach(error => {
    const { message, value } = error as FieldError;
    if (message && value) {
      invalidIds[value] = message;
    }
  });

  const invalidCartItemInfo: InvalidLineItemInfo = {};
  cartItems.forEach(item => {
    if (item && item.id) {
      const reason = invalidIds[item.id];
      if (reason) {
        invalidCartItemInfo[item.id] =
          INVALID_API_ERROR_TO_DISPLAY_ERROR(t)[reason];
      }
    }
  });

  return invalidCartItemInfo;
}

export const TrainingTokensPaymentInfo: FunctionComponent<{
  paymentProvider: TrainingToken;
  cart?: Cart;
  refetchViewer: () => Promise<ApolloQueryResult<any>>;
}> = ({ paymentProvider, cart, refetchViewer }) => {
  const tokenType = paymentProvider.tokenTypes[0];
  const [hasInsufficientFunds, setHasInsufficientFunds] = useState(false);

  const PAYMENT_CODE_MAX_LENGTH = 23;

  const { t } = useTranslation();

  const { hasRemovedLineItems } = useCartRemovedLineItemsContext();

  const { setInvalidLineItemInfo } = useCartInvalidLineItemsContext();

  const paymentCodeAlreadyAppliedError =
    "This Payment Code has already been applied to this Opportunity";

  const { values } = useFormContext();

  const [
    applyPaymentCode,
    {
      data: applyPaymentCodeData,
      loading: applyPaymentCodeLoading,
      error: applyPaymentCodeNetworkError,
    },
  ] = useWebLinkMutation<Mutation>(APPLY_PAYMENT_CODE, {
    onCompleted: data => {
      const errors = data.cart?.applyPaymentCode.errors;
      if (!!errors && cart?.items) {
        setInvalidLineItemInfo(
          getInvalidCartItemInformation(errors, cart?.items, t),
        );
      }
    },
  });

  const applyPaymentCodeResolverErrors =
    applyPaymentCodeData?.cart?.applyPaymentCode?.errors || [];

  const { totalBalance, paymentCodeApplications } = useTotalTokenBalance(cart);

  function getTooltipInfo(): Maybe<{ content: string; type: FeedbackType }> {
    if (applyPaymentCodeLoading) return null;
    if (applyPaymentCodeNetworkError) {
      return { content: t("updateError"), type: "error" };
    }

    let resolverError = applyPaymentCodeResolverErrors[0];

    if (resolverError?.message === paymentCodeAlreadyAppliedError) {
      applyPaymentCodeResolverErrors.shift();
    }

    if (applyPaymentCodeResolverErrors.length) {
      return {
        content: getFrontendErrorFromApiError(resolverError, t),
        type: "error",
      };
    }

    // TODO: This will be handled by PLAT-20787 eventually, we need it now to prevent submission
    if (paymentCodeApplications.length === 0) {
      return { content: t("applyToValidate"), type: "error" };
    }

    if (hasRemovedLineItems) {
      return { content: t("itemsNotAvailableInTokenPrice"), type: "warning" };
    }

    return null;
  }

  const tooltipInfo = getTooltipInfo();

  useEffect(() => {
    if (cart?.tokenType !== null) {
      setHasInsufficientFunds(
        paymentCodeApplications &&
          paymentCodeApplications.length !== 0 &&
          totalBalance < Number(cart?.price?.grandTotal),
      );
    }
  }, [
    refetchViewer,
    setHasInsufficientFunds,
    cart?.price?.grandTotal,
    cart?.tokenType,
    paymentCodeApplications,
    totalBalance,
  ]);

  return (
    <>
      {applyPaymentCodeLoading && <LoadingBar isLoading />}
      <Row>
        <Col xs={6}>
          <StaticFormGroup value={tokenType.name} label={t("trainingToken")} />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          {tooltipInfo ? (
            <FeedbackTooltip
              content={tooltipInfo.content}
              placement="top"
              tooltipId="PAYMENT_CODE_TOOLTIP"
              type={tooltipInfo.type}
            >
              <Input
                label={t("paymentCode")}
                name="tokenIssuePaymentCode"
                maxLength={PAYMENT_CODE_MAX_LENGTH}
                // TODO: This will disappear when we do PLAT-20787
                valid={() =>
                  tooltipInfo ? tooltipInfo.type !== "error" : true
                }
              />
            </FeedbackTooltip>
          ) : (
            <Input
              label={t("paymentCode")}
              name="tokenIssuePaymentCode"
              maxLength={PAYMENT_CODE_MAX_LENGTH}
            />
          )}
        </Col>
        {/* TODO: Fix this. Using a separate column with padding to line it up with the input is a horrible way to have the button to the right of the input. The issue is that the input gets wrapped in loads of divs by the form and there's no easy way to override them to allow the button to be displayed inline. */}
        <Col xs={2}>
          <div style={{ paddingTop: "22px" }}>
            <Button
              label={t("apply")}
              type="default"
              onClick={async () => {
                await applyPaymentCode({
                  variables: {
                    input: {
                      cartId: cart?.id || "",
                      code: values.tokenIssuePaymentCode,
                    },
                  },
                });
                await refetchViewer();
              }}
              disabled={!values.tokenIssuePaymentCode}
            />
          </div>
        </Col>
        <Col xs={3}>
          <StaticFormGroup
            value={totalBalance || "-"}
            label={t("currentBalance")}
          />
        </Col>
      </Row>
      {hasInsufficientFunds && (
        <Row>
          <Col xs={12}>
            <Prompt
              type="warning"
              message={t("insufficientTokens")}
              styleOptions={[UtilityStyle.FontBold, UtilityStyle.BottomSpace]}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export function useTotalTokenBalance(cart?: Cart) {
  const existingPaymentCodeApplications = cart?.paymentCodeApplications || [];
  // TODO: Could replace this with the cart from above.
  const paymentCodeApplications =
    cart?.paymentCodeApplications || existingPaymentCodeApplications;

  const totalBalance = useMemo(
    () =>
      paymentCodeApplications.reduce(
        (previousValue, currentValue) =>
          previousValue + currentValue.tokenIssueBalance,
        0,
      ),
    [paymentCodeApplications],
  );
  return { totalBalance, paymentCodeApplications };
}
