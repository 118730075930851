import React, { Fragment, FunctionComponent, useMemo, useState } from "react";
import {
  Container,
  Alert,
  LoadingScreen,
  AlertModal,
} from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import { useHistory } from "../../useHistory";
import { WistiaVideoPlayer } from "../../components/WistiaVideoPlayer";

import { MainWrapper, MenuBar } from "../../MenuBar";
import { DiscussionTopicPage } from "../../components/OnlineContent/DiscussionTopicPage";
import { BaseExternalObjective } from "../../components/OnlineContent/external/BaseExternalObjective";
import { DocumentPlayer } from "../../components/OnlineContent/DocumentPlayer";
import { ScormPlayer } from "../../components/OnlineContent/SCORMPlayer";
import { ContentMenu } from "../../components/OnlineContent/ContentMenu";
import {
  DiscussionTopic,
  Document,
  ExternalActivity,
  ExternalContentResult,
  Registration,
  ResourceContentResult,
  Scorm,
  ScormContentResult,
  Separator,
  Video,
  VideoContentResult,
  KryterionActivity,
  Content,
} from "../../generated/lmsTypes";
import { useGlobalStore } from "../../providers/GlobalStore";
import { FetchMoreCallback } from "../../types/ApolloCallbacks";
import { setStateType } from "../../types/SetStateType";
import { isLmsContentAccessible } from "../../utils/eventHelpers";
import { useViewer } from "../../providers/ViewerProvider";
import { checkHasLockedContent } from "../../components/TrainingPass/ContentStatusLabel";
import { isMobileDevice } from "../../utils/previewHelpers";

type ContentUnion =
  | DiscussionTopic
  | Video
  | Scorm
  | Document
  | ExternalActivity
  | KryterionActivity
  | Separator;

export type ContentResultUnion =
  | VideoContentResult
  | ScormContentResult
  | ResourceContentResult
  | ExternalContentResult
  | null
  | undefined;

export const ContentPlayerInfo: FunctionComponent<{
  registration?: Registration;
  contentId: string;
  parentPage: string;
  nextUrl: string;
  listIsLoading: boolean;
  contentIsLoading: boolean;
  previewMode?: boolean;
  contentResult?: ContentResultUnion;
  learningPathId?: string;
  fetchMoreCourseDetails?: FetchMoreCallback;
}> = ({
  registration,
  contentId,
  parentPage,
  nextUrl,
  listIsLoading,
  contentIsLoading,
  previewMode = false,
  contentResult,
  learningPathId,
  fetchMoreCourseDetails,
}) => {
  const { viewer } = useViewer();
  const [hasInvalidRegistration, setHasInvalidRegistration] =
    useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation();
  const globalStore = useGlobalStore();
  const registrationId = registration?.id;

  const allCourseContent: (ContentUnion | null | undefined)[] | undefined =
    registration?.course?.content?.edges
      ?.map(edge => edge?.node)
      .filter(node => {
        const hasLockedPremiumContent =
          node && checkHasLockedContent(node as Content, viewer);
        return !hasLockedPremiumContent && node?.type !== "separator";
      });

  const contentIndex = allCourseContent?.findIndex(
    node => node && node.id === contentId,
  );
  const nextContent = useMemo(() => {
    if (contentIndex !== undefined && allCourseContent) {
      if (contentIndex + 1 === allCourseContent?.length) {
        return {
          buttonLabel: t("complete"),
          onClick: () => {
            history.push(parentPage);
          },
        };
      }
      const next = allCourseContent[contentIndex + 1];
      return {
        label: (
          <div className="next-wrapper">
            <span className="next-label">{t("nextModule")}</span>
            <span className="next-wrapper--name">{next?.displayName}</span>
          </div>
        ),
        buttonLabel: t("continue"),
        onClick: () => {
          history.push(`${nextUrl}/${next?.id}`);
        },
      };
    }
  }, [contentIndex, parentPage, nextUrl, allCourseContent, history, t]);

  const lmsContentAccessible = isLmsContentAccessible(
    previewMode,
    registration?.course?.lmsStart,
    registration?.course?.lmsEnd,
  );

  if (
    hasInvalidRegistration ||
    registration?.passAccessExpired ||
    !lmsContentAccessible
  ) {
    return (
      <AlertModal
        title={t("registrationInvalidRedirectAlert")}
        show={true}
        type="warningWithoutCancel"
        onDone={() => {
          history.push(`/`);
        }}
      />
    );
  }

  if (listIsLoading) {
    return <LoadingScreen />;
  }

  if (contentIndex === undefined || contentIndex === null) {
    return <Alert message={t("contentNotFound")} />;
  }

  const content = allCourseContent && allCourseContent[contentIndex];

  if (!content || (!previewMode && !contentResult && !contentIsLoading)) {
    return <Alert message={t("contentNotFound")} />;
  }

  if (isMobileDevice) {
    globalStore.contentMenuOpen = false;
  }

  return (
    <>
      <MenuBar
        type="player"
        back={{
          label: t("course"),
          onClick: () => history.push(parentPage),
        }}
        next={lmsContentAccessible ? nextContent : undefined}
        playerContent={{
          type: content?.type ?? undefined,
          name: content?.displayName,
        }}
      />
      <ContentMenu
        registration={registration}
        activeContentId={content?.id ?? undefined}
        learningPathId={learningPathId}
        fetchMoreCourseDetails={fetchMoreCourseDetails}
        isAccessible={lmsContentAccessible}
      />
      <MainWrapper
        hasIframe={
          content.type === "resource" ||
          (content.type === "scorm" &&
            !globalStore.config.open_scorm_new_window)
        }
      >
        {contentIsLoading ? (
          <LoadingScreen />
        ) : !lmsContentAccessible ? (
          <Alert message={t("contentLocked")} />
        ) : (
          <ContentContainer
            content={content}
            contentResult={contentResult}
            registrationId={registrationId}
            setHasInvalidRegistration={setHasInvalidRegistration}
          />
        )}
      </MainWrapper>
    </>
  );
};

const ContentContainer: FunctionComponent<{
  content: ContentUnion;
  contentResult: ContentResultUnion;
  registrationId: string | undefined;
  setHasInvalidRegistration: setStateType<boolean>;
}> = ({
  content,
  contentResult,
  registrationId,
  setHasInvalidRegistration,
}) => {
  return (
    <Fragment>
      {content.__typename === "Video" && content.wistiaVideoId && (
        <Container>
          <WistiaVideoPlayer
            contentId={content.id!}
            wistiaVideoId={content.wistiaVideoId}
            startAt={
              contentResult?.__typename === "VideoContentResult"
                ? contentResult?.startAt ?? 0
                : 0
            }
            registrationId={registrationId}
            key={content.wistiaVideoId ?? undefined}
            setHasInvalidRegistration={setHasInvalidRegistration}
          />
        </Container>
      )}
      {content.__typename === "Scorm" && (
        <ScormPlayer
          registrationId={registrationId}
          contentId={content.id!}
          entrypoint={content.entrypoint ?? undefined}
          version={content.version ?? undefined}
          setHasInvalidRegistration={setHasInvalidRegistration}
        />
      )}
      {content.__typename === "Document" && (
        <DocumentPlayer
          registrationId={registrationId}
          contentId={content.id!}
          displayName={content.displayName ?? undefined}
          originalExtension={content.originalExtension ?? undefined}
        />
      )}
      {content.__typename === "DiscussionTopic" && (
        <DiscussionTopicPage
          registrationId={registrationId}
          content={content}
          setHasInvalidRegistration={setHasInvalidRegistration}
        />
      )}
      {content.__typename === "ExternalActivity" && (
        <BaseExternalObjective
          registrationId={registrationId}
          contentId={content.id!}
          status={contentResult?.status ?? undefined}
          shouldAutoComplete={content.autoComplete ?? false}
          extraArgs={{
            kind: "External",
            description: content.htmlDescription ?? undefined,
            url: content.externalActivityUrl ?? undefined,
          }}
        />
      )}
      {content.__typename === "KryterionActivity" && (
        <BaseExternalObjective
          registrationId={registrationId}
          contentId={content.id!}
          status={contentResult?.status ?? undefined}
          shouldAutoComplete={false}
          extraArgs={{
            kind: "Kryterion",
            url: content.kryterionUrl ?? "",
            externalCourseId: content.kryterionCourseId ?? "",
            title: content.displayName ?? undefined,
            instructions: content.htmlDescription ?? undefined,
            accessedPreviously: !isEmpty(contentResult?.lastAccessed),
          }}
        />
      )}
    </Fragment>
  );
};
