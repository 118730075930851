import React from "react";
import { Nav, NavItem } from "react-bootstrap";

interface CardNavigationProps {
  menu: {
    name: string;
    label: string;
    disabled?: boolean;
    icon?: string;
  }[];
}

export const CardNavigation: React.FunctionComponent<CardNavigationProps> = ({
  menu,
}) => (
  <Nav bsStyle="pills" stacked className="card-navigation">
    {menu.map((item, i) => (
      <NavItem key={i} eventKey={item.name} disabled={item.disabled}>
        {item.icon && (
          <span
            className={`glyphicon-pro glyphicon-pro-${item.icon} pr-3`}
          ></span>
        )}
        {item.label}
      </NavItem>
    ))}
  </Nav>
);
