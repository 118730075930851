import React, { useCallback, useState } from "react";
import { Alert, Col, FutureListPage, GridRow } from "@administrate/piston-ux";
import { NetworkStatus } from "apollo-client";
import { useTranslation } from "react-i18next";

import { RegisterablesResponse } from "../../types/Registerables";
import { ScrollTrigger } from "../../ScrollTrigger";
import { REGISTERABLES_QUERY } from "../../queries/registerables";
import { RegistrableCard } from "./RegistrableCard";
import {
  RegisterableField,
  OrderDirection,
  FilterOperation,
} from "../../generated/lmsTypes";
import { CardLoadingPlaceholder } from "../../components/CardLoadingPlaceholder";
import { useLmsQuery } from "../../hooks/lms";
import {
  MyCoursesArticleHeader,
  MyCoursesFilters,
} from "./MyCoursesArticleHeader";
import { WelcomeMessage } from "../../components/WelcomeMessage";

export const MyCourses = () => {
  const [affectBarFilters, setAffectBarFilters] = useState<MyCoursesFilters>({
    search: "",
    active: undefined,
  });

  const { t } = useTranslation();

  const affectBarChange = useCallback(
    filters => setAffectBarFilters(filters),
    [setAffectBarFilters],
  );

  const filters = getFilters(affectBarFilters);
  const order = getOrder(affectBarFilters);

  const queryVariables = {
    firstCount: 8,
    offset: 0,
    filters,
    order,
  };

  const {
    loading: registerablesIsLoading,
    data: registerablesResponse,
    fetchMore: fetchMoreRegisterables,
    error,
    networkStatus,
  } = useLmsQuery<RegisterablesResponse>(REGISTERABLES_QUERY, {
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const registerables = registerablesResponse?.registerables?.edges || [];

  const loadNextPage = useCallback(() => {
    fetchMoreRegisterables({
      variables: {
        offset: registerablesResponse?.registerables?.edges?.length || 0,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return fetchMoreResult
          ? {
              ...prev,
              registerables: {
                ...prev.registerables,
                pageInfo: fetchMoreResult.registerables.pageInfo,
                edges: [
                  ...prev.registerables.edges,
                  ...fetchMoreResult.registerables.edges,
                ],
              },
            }
          : prev;
      },
    }).catch(error => {
      // apollo error: update query doesn't get cancelled after unmount
      if (error.name !== "Invariant Violation") {
        throw error;
      }
    });
  }, [fetchMoreRegisterables, registerablesResponse]);

  const hasNextPage =
    registerablesResponse?.registerables?.pageInfo?.hasNextPage ?? false;

  return (
    <FutureListPage title={t("myCourses")} type="grid">
      <WelcomeMessage />
      <article>
        <MyCoursesArticleHeader
          onChange={affectBarChange}
          loading={registerablesIsLoading}
        />
        {error && (
          <Alert
            type="error"
            message={t("anErrorOccurredWhileLoadingCourses")}
            glyph="removeSign"
            overPage={false}
          />
        )}
        {(networkStatus === NetworkStatus.fetchMore ||
          networkStatus === NetworkStatus.ready) && (
          <GridRow>
            {registerables.map(({ node }) => (
              <Col sm={6} md={4} lg={3} key={node.id}>
                <RegistrableCard
                  registerable={node}
                  section={t("allCourses")}
                />
              </Col>
            ))}
          </GridRow>
        )}
        {registerablesIsLoading ? (
          <CardLoadingPlaceholder />
        ) : (
          <>
            {hasNextPage ? (
              <ScrollTrigger onEnter={loadNextPage} />
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p className="text-muted">{t("youveReachedTheEnd")}</p>
              </div>
            )}
          </>
        )}
      </article>
    </FutureListPage>
  );
};

const getFilters = (affectBarFilters: MyCoursesFilters) => {
  const filters = [];
  if (affectBarFilters.active && affectBarFilters.active.selected) {
    filters.push({
      field: RegisterableField.IsActive,
      operation: FilterOperation.Eq,
      value: "true",
    });
    filters.push({
      field: RegisterableField.PassAccessExpired,
      operation: FilterOperation.Eq,
      value: "false",
    });
  }
  if (affectBarFilters.search) {
    filters.push({
      field: RegisterableField.Title,
      operation: FilterOperation.Like,
      value: `%${affectBarFilters.search}%`,
    });
  }
  return filters;
};

const getOrder = (affectBarFilters: MyCoursesFilters) => {
  let order = {
    field: RegisterableField.LastAccessed,
    direction: OrderDirection.Desc,
  };

  if (affectBarFilters.sort?.selected?.value === "aToZ") {
    order = {
      field: RegisterableField.Title,
      direction: OrderDirection.Asc,
    };
  }

  if (affectBarFilters.sort?.selected?.value === "zToA") {
    order = {
      field: RegisterableField.Title,
      direction: OrderDirection.Desc,
    };
  }

  return order;
};
