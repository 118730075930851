import { ListRow, ListCell, List } from "@administrate/piston-ux";
import React, { FunctionComponent } from "react";

export const ContentLoadingSkeleton: FunctionComponent = () => (
  <List extraClass="my-course">
    <ListRow>
      <ListCell loading extraClass="d-none d-lg-block text-center" />
      <ListCell loading />
      <ListCell loading />
    </ListRow>
    <ListRow>
      <ListCell loading extraClass="d-none d-lg-block text-center" />
      <ListCell loading />
      <ListCell loading />
    </ListRow>
    <ListRow>
      <ListCell loading extraClass="d-none d-lg-block text-center" />
      <ListCell loading />
      <ListCell loading />
    </ListRow>
  </List>
);
