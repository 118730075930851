import { SaveStates } from "@administrate/piston-ux/lib/types/detailTypes";
import { MessageOptions } from "@administrate/piston-ux/lib/utils/FormStateStatus";
import { MutationHookOptions } from "@apollo/react-hooks";
import { CardElement } from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { ExecutionResult } from "apollo-boost";
import { TFunction } from "i18next";
import { PostalAddress } from "../../generated/lmsTypes";
import parsePaymentError from "../../utils/parsePaymentError";

export const checkoutWithStripe = async ({
  stripe,
  elements,
  cartId,
  billingAccountId,
  buyerBillingAddress,
  setMessages,
  setSaveState,
  createStripePaymentIntent,
  placeStripeOrder,
  completeCheckout,
  t,
}: {
  stripe: Stripe;
  elements: StripeElements;
  cartId: string;
  billingAccountId: string;
  buyerBillingAddress?: PostalAddress;
  setMessages: React.Dispatch<React.SetStateAction<MessageOptions>>;
  setSaveState: React.Dispatch<React.SetStateAction<SaveStates>>;
  createStripePaymentIntent: (
    options: MutationHookOptions,
  ) => Promise<ExecutionResult>;
  placeStripeOrder: (options: MutationHookOptions) => Promise<ExecutionResult>;
  completeCheckout: () => void;
  t: TFunction;
}) => {
  const cardElement = elements.getElement(CardElement);

  if (!cardElement) {
    console.error("Stripe cardElement not found");
    setMessages({
      failed: t("stripeNotFound"),
    });
    setSaveState("failed");
    return;
  }

  const customerBillingDetails = {
    address: {
      line1: buyerBillingAddress?.streetAddress1 ?? undefined,
      line2: buyerBillingAddress?.streetAddress2 ?? undefined,
      city: buyerBillingAddress?.town ?? undefined,
      postal_code: buyerBillingAddress?.postcode ?? undefined,
      country: buyerBillingAddress?.country?.code ?? undefined,
    },
  };

  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: "card",
    card: cardElement,
    billing_details: buyerBillingAddress ? customerBillingDetails : undefined,
  });

  if (error) {
    const errorCode = error.decline_code || error.code || "";
    setMessages({
      errors: [parsePaymentError(errorCode, t)],
    });
    setSaveState("errors");
    return;
  }

  if (paymentMethod) {
    const response = await createStripePaymentIntent({
      variables: {
        input: {
          cartId,
          stripeDetails: {
            paymentMethod: paymentMethod.id,
          },
        },
      },
    });

    const {
      cart,
      paymentIntent,
      errors: createPaymentIntentErrors,
    } = response.data?.stripe.createPaymentIntent;

    if (createPaymentIntentErrors.length) {
      const errorCode = createPaymentIntentErrors[0].value || "";
      setMessages({
        errors: [parsePaymentError(errorCode, t)],
      });
      setSaveState("errors");
      return;
    }

    if (paymentIntent.requiresAction) {
      const { error } = await stripe.handleCardAction(
        paymentIntent.clientSecret,
      );

      if (error) {
        const errorCode = error.decline_code || error.code || "";
        setMessages({
          errors: [parsePaymentError(errorCode, t)],
        });
        setSaveState("errors");
        return;
      }
    }

    const input = {
      cartId: cart.id,
      stripeDetails: {
        paymentIntentId: paymentIntent.id,
      },
      ...(billingAccountId && { billingAccountId }),
    };

    const placeOrderResponse = await placeStripeOrder({
      variables: {
        input,
      },
    });

    const errors = placeOrderResponse.data?.stripe.placeOrder.errors || [];

    if (errors.length) {
      setMessages({
        failed: t("placeOrderErrorRestartBooking"),
      });
      setSaveState("errors");
    } else {
      completeCheckout();
    }
  }
};
