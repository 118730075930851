import React, { createContext, useContext, useMemo } from "react";
import { CartLineItem } from "../generated/weblinkTypes";
import { useLocalStorage } from "../hooks/useLocalStorage";

export type CartRemovedLineItemsContextType = {
  removedLineItems: CartLineItem[];
  setRemovedLineItems: React.Dispatch<React.SetStateAction<CartLineItem[]>>;
  hasRemovedLineItems: boolean;
};

export const CartRemovedLineItemsContext =
  createContext<CartRemovedLineItemsContextType | undefined>(undefined);

export const useCartRemovedLineItemsContext = () => {
  const context = useContext(CartRemovedLineItemsContext);
  if (context === undefined) {
    throw new Error(
      "`useCartRemovedLineItemsContext` has been used outside of the CartRemovedLineItems Provider component",
    );
  }
  return context;
};

export const CartRemovedLineItemsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const key = "CART_REMOVED_LINE_ITEMS";

  const [removedLineItems, setRemovedLineItems] = useLocalStorage<
    CartLineItem[]
  >(key, []);

  const hasRemovedLineItems = useMemo(
    () => removedLineItems && removedLineItems.length !== 0,
    [removedLineItems],
  );

  return (
    <CartRemovedLineItemsContext.Provider
      value={{
        removedLineItems,
        setRemovedLineItems,
        hasRemovedLineItems,
      }}
    >
      {children}
    </CartRemovedLineItemsContext.Provider>
  );
};
