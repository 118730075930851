import React, { useContext, createContext, FunctionComponent } from "react";
import { LoadingScreen } from "@administrate/piston-ux";

import {
  CartLineItem,
  LearningPath,
  Maybe,
  Query,
  Viewer,
  Event as EventType,
  Term,
} from "../generated/weblinkTypes";
import { CHECKOUT_QUERY } from "../queries/cart";
import { useWebLinkQuery } from "../hooks/weblink";
import { checkHasBookingDetails } from "../utils/checkoutHelpers";
import { ApolloQueryResult, NetworkStatus } from "apollo-boost";
import { useCartRemovedLineItemsContext } from "./CartRemovedLineItemsProvider";

export enum RefetchStatus {
  StartRefetch,
  Cancel,
  Done,
}

export type CheckoutContent = {
  viewer: Viewer | null | undefined;
  hasLearnerDetails: boolean;
  hasBookingDetails: boolean;
  hasBookerInformation: boolean;
  hidePricesBeforePricingAgreementApplied: boolean;
  terms?: Maybe<Array<Maybe<Term>>>;
  refetch: () => Promise<ApolloQueryResult<any>>;
  networkStatus: NetworkStatus;
};

export enum CheckoutURLS {
  BookerInformation = "",
  LearnerDetails = "learner-details",
  BookingDetails = "booking-details",
  OrderComplete = "order-complete",
}

export const useCheckoutContext = () => {
  const context = useContext(CheckoutContext);
  if (context === undefined) {
    throw new Error(
      "A component has been used outside of the Checkout Provider components",
    );
  }
  return context;
};

export const CheckoutContext =
  createContext<CheckoutContent | undefined>(undefined);

export const CheckoutProvider: FunctionComponent = ({ children }) => {
  const {
    data: checkoutQueryResponse,
    loading: checkoutQueryLoading,
    refetch,
    networkStatus,
  } = useWebLinkQuery<Query>(CHECKOUT_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const { hasRemovedLineItems } = useCartRemovedLineItemsContext();

  const hasBookingDetails =
    !checkoutQueryLoading &&
    (checkoutQueryResponse?.viewer?.lastCart?.items || hasRemovedLineItems)
      ? checkHasBookingDetails(
          checkoutQueryResponse?.viewer?.lastCart?.items,
          hasRemovedLineItems,
        )
      : false;

  const storeResponse = checkoutQueryResponse?.store;
  const terms = storeResponse?.terms;
  const hasBookerInformation = (terms && terms.length > 0) ?? false;
  const hidePricesBeforePricingAgreementApplied =
    storeResponse?.hidePricesBeforePricingAgreementApplied ?? false;

  const eventPoSFields = (checkoutQueryResponse as any)?.eventLearnerPoSFields;
  const pathPoSFields = (checkoutQueryResponse as any)?.pathLearnerPoSFields;

  const cartItems = getCartItems(
    checkoutQueryResponse?.viewer?.lastCart?.items,
  );

  const hasLearnerDetails = !checkoutQueryLoading
    ? (eventPoSFields && cartItems.events > 0) ||
      (pathPoSFields && cartItems.paths > 0) ||
      hasRemovedLineItems
    : false;

  if (checkoutQueryLoading && networkStatus !== NetworkStatus.refetch)
    return <LoadingScreen />;

  return (
    <CheckoutContext.Provider
      value={{
        viewer: checkoutQueryResponse?.viewer,
        hasLearnerDetails,
        hasBookingDetails,
        hasBookerInformation,
        hidePricesBeforePricingAgreementApplied,
        terms,
        refetch,
        networkStatus,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

const getCartItems = (items: Maybe<CartLineItem>[] | null | undefined) => ({
  paths:
    items?.filter(
      item =>
        (item?.productOption as LearningPath).__typename === "LearningPath",
    ).length ?? 0,
  events:
    items?.filter(
      item => (item?.productOption as EventType).__typename === "Event",
    ).length ?? 0,
});
