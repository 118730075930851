import React from "react";
import useScormLauncherPopup from "../../hooks/useScormLauncherPopup";
import useScormSetup from "../../hooks/useScormSetup";
import { LoadingScreen, Button } from "@administrate/piston-ux";
import { Trans, useTranslation } from "react-i18next";
import { setStateType } from "../../types/SetStateType";
import { isMobileDevice } from "../../utils/previewHelpers";

export enum PlayerStatus {
  Init = "init",
  Open = "open",
  Blocked = "blocked",
  Closed = "closed",
}

export const ScormLauncherPopup: React.FunctionComponent<{
  registrationId?: string;
  contentId: string;
  entrypoint: string;
  version: string;
  setApolloError: setStateType<any>;
}> = ({ registrationId, contentId, entrypoint, version, setApolloError }) => {
  const { childWindow, playerState, openContent } = useScormLauncherPopup();
  const { scormApiReady, frameSrc, scormDataModel } = useScormSetup(
    contentId,
    entrypoint,
    version,
    setApolloError,
    registrationId,
    childWindow || undefined,
  );

  if (isMobileDevice) {
    setInterval(function () {
      scormDataModel?.commit();
    }, 30000);
  } else {
    window.addEventListener("beforeunload", function handleBeforeUnload() {
      scormDataModel?.commit();
    });
  }

  const { t } = useTranslation();
  if (!scormApiReady || !frameSrc) return <LoadingScreen />;
  return (
    <div className="scorm-content-status">
      <h3>
        <Trans i18nKey={"popupStatusMessage_" + playerState}>
          <br />
          <strong />
        </Trans>
      </h3>
      {playerState === PlayerStatus.Blocked && (
        <Button
          type="primary"
          onClick={() => openContent(frameSrc)}
          label={t("Open content")}
        />
      )}
    </div>
  );
};
