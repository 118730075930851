import React, { Fragment, FunctionComponent, useState } from "react";
import {
  Card,
  Form,
  useFormValues,
  Submit,
  Input,
  StatusLabel,
  AlertModal,
  List,
  ListCell,
  ListRow,
  Alert,
  FutureArticleHeader,
  ListDrawer,
  Row,
  Col,
  Placeholder,
  Button,
} from "@administrate/piston-ux";
import { ColorOptions } from "@administrate/piston-ux/lib/types";
import { observer, Observer } from "mobx-react-lite";
import NavigationPrompt from "react-router-navigation-prompt";
import { get } from "lodash";
import { extractNodes } from "../../utils/extractNodes";
import { COORDINATED_LEARNER_QUERY } from "../../queries/learnerManagement";
import {
  ContactPicker,
  getContactVariables,
} from "../../components/ContactPicker";
import { useHistory } from "../../useHistory";
import { useWebLinkQuery } from "../../hooks/weblink";
import {
  ADD_CART_LINE_ITEM_MUTATION,
  POINT_OF_SALE_LEARNER_FIELD_QUERY,
} from "../../queries/cart";
import { useWebLinkMutation } from "../../hooks/weblink";
import {
  Query,
  PointOfSaleLearnerFieldDefinition,
  Maybe,
  LearningPath,
  Event as EventType,
  PointOfSaleFieldEntityType,
  Contact,
  PointOfSaleFieldFilter,
} from "../../generated/weblinkTypes";
import { ensureObservableValue } from "@administrate/piston-ux/lib/withFormWrapper";
import { PointOfSaleLearnerFields } from "../../components/PointOfSaleLearnerFields";
import {
  FormArray,
  FormConsumer,
  FormActions,
} from "@administrate/piston-ux/lib/Form";
import { mapPreExistingContactValues } from "../../utils/bookingHelpers";
import { getUniqueId } from "../../utils/displayHelpers";
import { useLmsClient } from "../../hooks/lms";
import { useTranslation } from "react-i18next";
import { hasErrors } from "@administrate/piston-ux/lib/Submit";
import { RawStaticInput } from "@administrate/piston-ux/lib/StaticInput";
import { mapPointOfSaleFieldValues } from "../../utils/customFieldValues";

export const UNNAMED_LEARNER_CONTACT = {
  id: "unnamed",
  firstName: "Unnamed",
  lastName: "Learner",
};

export const GLOBAL_LEARNER_LIMIT = 100;

export type LearnerType = {
  id: string;
  learnerNumber: number;
  contact: typeof UNNAMED_LEARNER_CONTACT;
  posFieldValues: Record<string, unknown>;
};

type LearnerSelectionProps = {
  product: EventType | LearningPath | undefined;
  cartId: string | null | undefined;
  loading: boolean;
  pointOfSaleFieldVariables: {
    entityType: PointOfSaleFieldEntityType;
    filters?: PointOfSaleFieldFilter[];
  };
  learningPathObjectivesInput?: { eventId: string; objectiveId: string }[];
  maxLearners: number | null | undefined;
  getMaxLearnersReachedMessages: (learnerAmount: number) => string[];
  invalidStateAlertVisible: boolean;
};

export const LearnerSelection = observer<LearnerSelectionProps>(
  ({
    cartId,
    product,
    pointOfSaleFieldVariables,
    learningPathObjectivesInput,
    maxLearners,
    getMaxLearnersReachedMessages,
    invalidStateAlertVisible,
  }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [apiError, setApiError] = useState(false);
    const [
      addCartLineItem,
      { loading: mutationLoading, error: mutationError },
    ] = useWebLinkMutation(ADD_CART_LINE_ITEM_MUTATION);

    const { data: pointOfSaleFieldsData, loading: pointOfSaleFieldsLoading } =
      useWebLinkQuery<Query>(POINT_OF_SALE_LEARNER_FIELD_QUERY, {
        variables: pointOfSaleFieldVariables,
      });
    const pointOfSaleFields =
      pointOfSaleFieldsData && pointOfSaleFieldsData.pointOfSaleLearnerFields
        ? pointOfSaleFieldsData.pointOfSaleLearnerFields
        : [];

    const values = useFormValues({
      learnerNumber: 1,
      learners: [
        {
          id: "unnamed",
          learnerNumber: 0,
          contact: UNNAMED_LEARNER_CONTACT,
          posFieldValues: {},
        },
      ],
      learnerLength: 1,
    });

    if (pointOfSaleFields.length > 0) {
      values.learners.forEach((learner: LearnerType) => {
        pointOfSaleFields.forEach(field => {
          ensureObservableValue(learner.posFieldValues, field.key);
        });
      });
    }

    const client = useLmsClient();
    const contacts = (inputValue: string) =>
      client
        .query({
          query: COORDINATED_LEARNER_QUERY,
          variables: getContactVariables(inputValue),
        })
        .then(result => {
          return [
            UNNAMED_LEARNER_CONTACT,
            ...extractNodes(result.data.coordinatorManagedContacts.edges),
          ];
        });

    const handleChangeLearnerNumber = (value: string) => {
      const newLearnerLength = parseInt(value);
      if (
        (maxLearners && newLearnerLength > maxLearners) ||
        newLearnerLength > GLOBAL_LEARNER_LIMIT
      ) {
        return;
      }
      if (newLearnerLength >= 0) {
        values.learnerLength = newLearnerLength;
        if (newLearnerLength < values.learners.length) {
          values.learners = values.learners.slice(0, newLearnerLength);
        } else if (newLearnerLength > values.learners.length) {
          const unnamedLearners = [];
          for (let i = values.learners.length; i < newLearnerLength; i++) {
            let unnamedLearner: LearnerType = {
              id: "unnamed",
              learnerNumber: i,
              contact: UNNAMED_LEARNER_CONTACT,
              posFieldValues: {},
            };

            if (pointOfSaleFields) {
              pointOfSaleFields.forEach(field => {
                unnamedLearner.posFieldValues[field.key] = null;
              });
            }
            unnamedLearners.push(unnamedLearner);
          }
          values.learners = [...values.learners, ...unnamedLearners];
        }
      }
    };

    const handleSelectContact = (
      newChoice: Contact,
      _: Contact,
      index: number,
    ) => {
      if (newChoice !== null) {
        const currentLearner = values.learners[index];
        if (!currentLearner) {
          return;
        }

        currentLearner.id = newChoice.id;
        currentLearner.learnerNumber = index;

        mapPreExistingContactValues(
          pointOfSaleFields,
          currentLearner,
          newChoice,
        );
      } else {
        values.learners[index] = {
          id: UNNAMED_LEARNER_CONTACT.id,
          learnerNumber: index,
          contact: UNNAMED_LEARNER_CONTACT,
          posFieldValues: {},
        };
      }
    };

    const handleSubmit = async () => {
      const response = await addCartLineItem({
        variables: {
          input: {
            cartId: cartId,
            cartLineItem: getCartLineItem(),
          },
        },
      });
      if (response.errors && response.errors.length > 0) {
        console.error(
          "error while adding opportunity line item",
          response.errors,
        );
        return;
      }

      const responseCartId = get(
        response,
        "data.cart.addLineItem.cart.id",
        null,
      );
      const apiErrors = get(response, "data.cart.addLineItem.errors", null);

      if ((apiErrors && apiErrors.length > 0) || !responseCartId) {
        console.error(
          "Encountered an error while creating Cart",
          cartId,
          apiErrors,
        );
        setApiError(true);
        return;
      }

      history.push(`/catalog/coordinating/booking/${responseCartId}`);
    };

    const getCartLineItem = () => {
      const existingLearnerDetailsInput = values.learners
        .filter((learner: LearnerType) => learner.id !== "unnamed")
        .map((learner: LearnerType) => {
          const posFieldValues = mapPointOfSaleFieldValues(
            pointOfSaleFields,
            learner.posFieldValues,
          );
          return {
            contactId: learner.id,
            attributes: posFieldValues,
          };
        });

      return {
        productOptionId: product && product.id,
        quantity: values.learners.length,
        learners: {
          newLearners: [],
          existingLearners: existingLearnerDetailsInput,
        },
        objectives: learningPathObjectivesInput
          ? learningPathObjectivesInput
          : [],
      };
    };

    const maxLearnersExceeded = (v: string) => {
      if (maxLearners === null || maxLearners === undefined) {
        return false;
      }
      return parseInt(v) > maxLearners;
    };

    return (
      <Fragment>
        {pointOfSaleFieldsLoading ? (
          <LearnerSelectionSkeleton />
        ) : (
          <Form
            type="raw"
            values={values}
            onSubmit={handleSubmit}
            disabled={mutationLoading}
          >
            <Card>
              <FutureArticleHeader title={t("learners")} />
              <NavigationPrompt
                when={(_, nextLocation) => {
                  return (
                    !invalidStateAlertVisible &&
                    values.learners.length > 0 &&
                    !get(nextLocation, "pathname", "").includes("booking")
                  );
                }}
              >
                {({ onConfirm, onCancel }) => (
                  <AlertModal
                    title={`${t(
                      "Are you sure you want to leave this page? Your current booking will be lost",
                    )}.`}
                    show
                    onDone={(confirmed: boolean) => {
                      return confirmed ? onConfirm() : onCancel();
                    }}
                    type="warning"
                  />
                )}
              </NavigationPrompt>
              {(mutationError || apiError) && (
                <Alert
                  type="error"
                  message={`${t(
                    "An error occurred while adding learners to your cart",
                  )}.`}
                  glyph="removeSign"
                  overPage={false}
                />
              )}
              <Row>
                <Col xs={6} sm={4}>
                  <Input
                    type="number"
                    label={t("numberOfLearners")}
                    name="learnerNumber"
                    autoFocus
                    onChange={handleChangeLearnerNumber}
                    valid={v =>
                      !!v &&
                      parseInt(v) > 0 &&
                      !maxLearnersExceeded(v) &&
                      parseInt(v) <= GLOBAL_LEARNER_LIMIT
                    }
                    min={1}
                    max={maxLearners ? maxLearners : undefined}
                    uniqueId={getUniqueId()}
                  />
                </Col>
              </Row>
              {maxLearners &&
                maxLearnersExceeded(values.learnerNumber) &&
                getMaxLearnersReachedMessages(values.learnerNumber).map(
                  (message: string) => {
                    return (
                      <Row>
                        <Col xs={12}>{message}</Col>
                      </Row>
                    );
                  },
                )}
              {values.learnerNumber > GLOBAL_LEARNER_LIMIT && (
                <Row>
                  <Col xs={12}>
                    {t("youCannotBookOver")} {GLOBAL_LEARNER_LIMIT}{" "}
                    {t("learnersUsingThisTool")}
                  </Col>
                </Row>
              )}
              <hr />
              <List extraClass="learners">
                <FormArray name="learners">
                  <LearnerRow
                    contacts={contacts}
                    onChange={handleSelectContact}
                    posFields={pointOfSaleFields}
                    disabled={maxLearnersExceeded(values.learnerNumber)}
                  />
                </FormArray>
              </List>
              <FormActions>
                <Submit label={t("continue")} />
              </FormActions>
            </Card>
          </Form>
        )}
      </Fragment>
    );
  },
);

type LearnerRowType = {
  contacts(inputValue: string): Promise<any>;
  onChange(newValue: Contact, oldValue: Maybe<Contact>, index: number): void;
  posFields?: PointOfSaleLearnerFieldDefinition[];
  disabled: boolean;
};

export const LearnerRow: FunctionComponent<LearnerRowType> = ({
  contacts,
  onChange,
  posFields,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <FormConsumer>
      {({ values, arrayIndex }) => {
        return (
          <Observer>
            {() => (
              <ListRow gridColumns="140px 1fr">
                <ListCell label={t("learnerNumber")}>
                  <FormConsumer>
                    {({ errors }) => (
                      <LearnerStatusLabel
                        errors={errors}
                        hasLearner={!(values.id === UNNAMED_LEARNER_CONTACT.id)}
                        arrayIndex={arrayIndex}
                      />
                    )}
                  </FormConsumer>
                </ListCell>
                <ListCell
                  label={t("learnerInformation")}
                  extraClass="learners--information"
                >
                  <ContactPicker
                    name={`contact`}
                    loadOptions={contacts}
                    onChange={(newValue, oldValue) =>
                      onChange(newValue, oldValue, arrayIndex)
                    }
                    valid={v => !!v}
                    disabled={disabled}
                  />
                </ListCell>
                {posFields && !(values.id === UNNAMED_LEARNER_CONTACT.id) && (
                  <ListDrawer
                    title={t("learnerInformation")}
                    open={true}
                    display="hidden"
                  >
                    <PointOfSaleLearnerFields
                      posFields={posFields}
                      learnerNumber={arrayIndex}
                      disabled={disabled}
                    />
                  </ListDrawer>
                )}
              </ListRow>
            )}
          </Observer>
        );
      }}
    </FormConsumer>
  );
};

const LearnerStatusLabel: FunctionComponent<{
  errors: any;
  hasLearner?: boolean;
  arrayIndex: number;
}> = observer(({ errors, hasLearner, arrayIndex }) => {
  const { t } = useTranslation();
  return (
    <StatusLabel
      color={
        hasErrors(errors)
          ? ColorOptions.Red
          : hasLearner
          ? ColorOptions.Green
          : ColorOptions.LightGrey
      }
      text={`${t("learner")} ${arrayIndex + 1}`}
    />
  );
});

export const LearnerSelectionSkeleton: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Card>
      <FutureArticleHeader title={t("learners")} />
      <Row className="mb-2">
        <Col xs={12}>
          <RawStaticInput value="" label={t("numberOfLearners")} />
          <Placeholder width={200} />
        </Col>
      </Row>
      <hr />
      <List extraClass="learners">
        <ListRow gridColumns="140px 1fr">
          <ListCell label={t("learnerNumber")}>
            <Placeholder />
          </ListCell>
          <ListCell
            label={t("learnerInformation")}
            extraClass="learners--information"
          >
            <Placeholder />
          </ListCell>
        </ListRow>
      </List>
      <div className="my-3"></div>
      <FormActions>
        <Button type="primary" disabled label={t("continue")} />
      </FormActions>
    </Card>
  );
};
