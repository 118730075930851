import React, { FunctionComponent, useState } from "react";
import { Card, LoadingBar } from "@administrate/piston-ux";

import { EventList } from "../../components/EventList";
import {
  EventEdge,
  FilterOperation,
  LocationField,
  RegionField,
} from "../../generated/weblinkTypes";
import { TrainingPassType } from "../../generated/lmsTypes";
import { DetailEventListHeader } from "./DetailEventListHeader";
import {
  WEBLINK_BASIC_LOCATIONS_QUERY,
  WEBLINK_REGIONS_QUERY,
} from "../../queries/filters";
import { extractNodes } from "../../utils/extractNodes";
import { useWebLinkClient } from "../../hooks/weblink";
import { useWeblinkSettings } from "../../hooks/useWeblinkSettings";
import { LoadingCard } from "../../components/LoadingCard";
import { useTranslation } from "react-i18next";
import { EVENT_TABLE_PAGINATION } from "../../utils/eventHelpers";

type DetailEventListProps = {
  courseCode: string;
  loading: boolean;
  events: EventEdge[];
  totalEventRecords: number;
  refetchEvents: any;
  contentPasses: TrainingPassType[];
};

export const DetailEventList: FunctionComponent<DetailEventListProps> = ({
  courseCode,
  loading,
  events,
  totalEventRecords,
  refetchEvents,
  contentPasses,
}) => {
  const { t } = useTranslation();
  const [eventsOffset, setEventsOffset] = useState(0);
  const [mutationRunning, setMutationRunning] = useState(false);

  const {
    hidePricesBeforePricingAgreementApplied,
    hidePrices,
    cartlessCheckout,
    loading: weblinkLoading,
  } = useWeblinkSettings();

  const client = useWebLinkClient();

  const locationOptions = (inputValue: string) =>
    client
      .query({
        query: WEBLINK_BASIC_LOCATIONS_QUERY,
        variables: {
          filters: [
            {
              field: LocationField.Name,
              operation: FilterOperation.Like,
              value: `%${inputValue}%`,
            },
          ],
        },
      })
      .then(result => extractNodes(result.data.locations.edges));

  const regionOptions = (inputValue: string) =>
    client
      .query({
        query: WEBLINK_REGIONS_QUERY,
        variables: {
          filters: [
            {
              field: RegionField.Code,
              operation: FilterOperation.Like,
              value: `%${inputValue}%`,
            },
          ],
        },
      })
      .then(result => extractNodes(result.data.regions.edges));

  if (weblinkLoading) {
    return <LoadingCard title={t("dates")} />;
  }

  return (
    <Card>
      <DetailEventListHeader
        loading={loading}
        locations={locationOptions}
        regions={regionOptions}
      />
      {mutationRunning && <LoadingBar isLoading />}
      <EventList
        events={events}
        loading={loading}
        offset={eventsOffset}
        totalRecords={totalEventRecords}
        hidePricesBeforePricingAgreementApplied={
          hidePricesBeforePricingAgreementApplied
        }
        hidePrices={hidePrices}
        cartlessCheckout={cartlessCheckout}
        setOffset={(newOffset: React.SetStateAction<number>) => {
          refetchEvents({
            courseCode,
            first: EVENT_TABLE_PAGINATION,
            offset: newOffset,
          });
          setEventsOffset(newOffset);
        }}
        mutationRunning={mutationRunning}
        setMutationRunning={(value = true) => setMutationRunning(value)}
        contentPasses={contentPasses}
      />
    </Card>
  );
};
