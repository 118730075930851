import React, { FunctionComponent } from "react";
import { LearningPath, EventObjective } from "../../generated/weblinkTypes";
import { LearningObjective } from "../../generated/lmsTypes";

export const LearningPathLocation: FunctionComponent<{
  path: LearningPath;
  hideRegion?: boolean;
}> = ({ path, hideRegion = false }) => {
  const eventObjectives = path.learningObjectives.edges
    .filter(
      ({ node }) =>
        (node as LearningObjective & { __typename: string }).__typename ===
        "EventObjective",
    )
    .map(({ node }) => node as EventObjective);

  if (eventObjectives.length === 0) {
    return <span>-</span>;
  }

  const locationMap = eventObjectives.map(
    ({ event: { location } }) =>
      `${location.name}${
        location.region && !hideRegion ? `, ${location.region.name}` : ""
      }`,
  );
  const locations = Array.from(new Set(locationMap)).join(". ");

  return <span>{locations}</span>;
};
