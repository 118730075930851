import { Prompt } from "@administrate/piston-ux";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

export const RemainingAttempts: FunctionComponent<{
  remainingAttempts: number;
  contentLocked?: boolean;
}> = ({ remainingAttempts, contentLocked }) => {
  const { t } = useTranslation();
  return (
    <div className="completedAttempts">
      {remainingAttempts > 0 && (
        <div className="prompt">
          <div className="completedAttempts">
            <span className="glyphicon glyphicon-repeat"></span>
            <div className="completedAttempts-number">{remainingAttempts}</div>
          </div>
          <span className="prompt--label">{t("remainingAttempts")}</span>
        </div>
      )}
      {contentLocked && (
        <Prompt
          type="locked"
          message={t("remainingAttempts", {
            remainingAttempts,
          })}
        />
      )}
    </div>
  );
};
