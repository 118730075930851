import gql from "graphql-tag";
import {
  PAGE_INFO_FRAGMENT,
  ERROR_FRAGMENT,
  REGISTRATION_FRAGMENT,
  CONTENT_FRAGMENT,
} from "./fragments";

export const GET_PREVIEW_COURSE_QUERY = gql`
  query coursePreview($jwt: String!, $offset: Int, $id: String) {
    coursePreview(jwt: $jwt) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          ...Registration
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
  ${REGISTRATION_FRAGMENT(false)}
`;

export const COURSE_REGISTRATION_DETAILS_QUERY = gql`
  query courseRegistrationDetails($id: String!, $offset: Int) {
    registrations: registrationsWithProgress(
      filters: [{ field: id, operation: eq, value: $id }]
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          ...Registration
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
  ${REGISTRATION_FRAGMENT(true)}
`;

export const CONTENT_PROGRESS_DETAILS_QUERY = (forNewContent: boolean) => gql`
  ${
    forNewContent
      ? "query newContentProgressDetails"
      : "query oldContentProgressDetails"
  } ($id: String!, $contentId: String!) {
    registrations(filters: [{ field: id, operation: eq, value: $id }]) {
      edges {
        node {
          id
          completedPiecesOfContent
          contentResults(
            filters: [{ field: contentId, operation: eq, value: $contentId }]
          ) {
            edges {
              node {
                ...ContentFragment
              }
            }
          }
        }
      }
    }
  }
  ${CONTENT_FRAGMENT}
`;

export const SET_PROGRESS_MUTATION = gql`
  mutation setProgress(
    $registrationId: ID!
    $contentId: ID!
    $stringContentId: String!
    $data: String!
  ) {
    registration {
      recordVideoProgress(
        input: {
          registrationId: $registrationId
          contentId: $contentId
          data: $data
        }
      ) {
        errors {
          ...Error
        }
        registration {
          id
          contentResults(
            filters: [
              { field: contentId, operation: eq, value: $stringContentId }
            ]
          ) {
            edges {
              node {
                ...ContentFragment
              }
            }
          }
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${CONTENT_FRAGMENT}
`;

export const MANAGED_REGISTRATIONS_FOR_CONTACT_QUERY = gql`
  query managedRegistrations(
    $contactId: String!
    $registrationFilters: [RegistrationFieldFilter]!
    $first: Int!
    $order: RegistrationFieldOrder
    $offset: Int!
  ) {
    coordinatorManagedContacts(
      filters: { field: id, operation: eq, value: $contactId }
      first: 1
    ) {
      edges {
        node {
          registrations(
            first: $first
            order: $order
            offset: $offset
            filters: $registrationFilters
          ) {
            pageInfo {
              ...PageInfo
            }
            edges {
              node {
                id
                learningPathRegistration {
                  id
                  learningPath {
                    id
                    name
                  }
                  results {
                    edges {
                      node {
                        id
                        completed
                        order
                        __typename
                        ... on LearningPathOutcome {
                          id
                          learningPath {
                            id
                            name
                            start
                            end
                          }
                        }
                        ... on CourseOutcome {
                          id
                          order
                          course {
                            id
                            title
                            start
                            end
                            startDate
                            endDate
                            location {
                              id
                              name
                            }
                            learningMode
                          }
                        }
                        ... on ExternalOutcome {
                          external
                        }
                        ... on URLOutcome {
                          url
                        }
                        ... on DocumentOutcome {
                          document {
                            id
                            displayName
                          }
                        }
                      }
                    }
                  }
                }
                lastAccessed
                title
                expiry
                totalPiecesOfContent
                completedPiecesOfContent
                hasPassed
                course {
                  id
                  title
                  learningMode
                  location {
                    id
                    name
                  }
                  start
                  end
                  imageUrl
                  categories {
                    edges {
                      node {
                        id
                        name
                        __typename
                      }
                      __typename
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
            }
          }
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

export const MANAGED_PATH_REGISTRATIONS_FOR_CONTACT_QUERY = gql`
  query managedRegistrations(
    $contactId: String!
    $registrationFilters: [LearningPathRegistrationFieldFilter]!
    $first: Int!
    $order: LearningPathRegistrationFieldOrder
    $offset: Int!
  ) {
    coordinatorManagedContacts(
      filters: { field: id, operation: eq, value: $contactId }
      first: 1
    ) {
      edges {
        node {
          learningPathRegistrations(
            first: $first
            order: $order
            offset: $offset
            filters: $registrationFilters
          ) {
            pageInfo {
              ...PageInfo
            }
            edges {
              node {
                id
                lifecycleState
                results {
                  edges {
                    node {
                      id
                      completed
                      order
                      __typename
                      ... on LearningPathOutcome {
                        id
                        learningPath {
                          id
                          name
                          start
                          end
                        }
                      }
                      ... on CourseOutcome {
                        id
                        order
                        course {
                          id
                          title
                          start
                          end
                          startDate
                          endDate
                          location {
                            id
                            name
                          }
                          learningMode
                        }
                      }
                      ... on ExternalOutcome {
                        external
                      }
                      ... on URLOutcome {
                        url
                      }
                      ... on DocumentOutcome {
                        document {
                          id
                          displayName
                        }
                      }
                    }
                  }
                }
                lastAccessed
                title
                isActive
                __typename
              }
            }
          }
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

export const REGISTRATION_FOR_COURSE_QUERY = gql`
  query reg($courseTemplateId: String!) {
    registrations(
      filters: [
        { field: courseTemplateId, operation: eq, value: $courseTemplateId }
      ]
      order: { field: id, direction: desc }
      first: 1
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`;
