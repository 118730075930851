import gql from "graphql-tag";
import { REGISTERABLE_FRAGMENT, PAGE_INFO_FRAGMENT } from "./fragments";

export const RECENT_REGISTERABLES_QUERY = gql`
  query recentActiveRegisterables(
    $firstCount: Int
    $filters: [RegisterableFieldFilter]
  ) {
    registerables(
      first: $firstCount
      filters: $filters
      order: { field: lastAccessed, direction: desc }
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          ...Registerable
          __typename
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
  ${REGISTERABLE_FRAGMENT}
`;

export const REGISTERABLES_QUERY = gql`
  query registerables(
    $offset: Int!
    $firstCount: Int
    $filters: [RegisterableFieldFilter]
    $order: RegisterableFieldOrder
  ) {
    registerables(
      first: $firstCount
      offset: $offset
      filters: $filters
      order: $order
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          ...Registerable
          __typename
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
  ${REGISTERABLE_FRAGMENT}
`;

export const REGISTERABLES_COUNT_QUERY = gql`
  query registerablesCount {
    registerablesCount(
      filters: [{ field: isActive, operation: eq, value: "true" }]
    ) {
      count
    }
  }
`;
