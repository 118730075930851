import React, { FunctionComponent, Fragment, useState, useEffect } from "react";
import {
  Container,
  Steps,
  Step,
  Row,
  Col,
  Card,
  FutureArticleHeader,
  OpacityOverlay,
  LoadingBar,
} from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { MenuBar } from "../../MenuBar";
import {
  getFinancialFormat,
  getLocationRegionFormat,
} from "../../utils/displayHelpers";
import { CartSummary, CartSummaryUsage } from "../../components/CartSummary";
import {
  RefetchStatus,
  useCheckoutContext,
} from "../../providers/CheckoutProvider";
import { useBookerIntention } from "../../hooks/useBookerIntention";
import { useHistory } from "../../useHistory";
import { getProductOption } from "../../utils/checkoutHelpers";
import { ItemSummary } from "../../components/ItemSummary";
import { PromoCode } from "../../components/PromoCode";
import { NetworkStatus } from "apollo-boost";
import { EventDateRange } from "../../components/EventDateRange";
import { useCartRemovedLineItemsContext } from "../../providers/CartRemovedLineItemsProvider";
import { useCartInvalidLineItemsContext } from "../../providers/CartInvalidLineItemsProvider";
import { CartLineItem, Maybe } from "../../generated/weblinkTypes";

export enum BookerIntention {
  Coordinating = "coordinating",
  Self = "self",
}

export enum CheckoutStep {
  BookingDetails = 3,
  LearnerDetails = 2,
  BookerInformation = 1,
  OrderComplete = 4,
}

export const CheckoutPage: FunctionComponent<{
  step: CheckoutStep;
  canShowPrice?: boolean;
}> = ({ step, children }) => {
  const [cartRefetching, setCartRefecting] = useState<RefetchStatus>();
  const { t } = useTranslation();

  const [bookerIntention] = useBookerIntention();
  const history = useHistory();

  const {
    hasBookingDetails,
    hasLearnerDetails,
    hasBookerInformation,
    hidePricesBeforePricingAgreementApplied,
    networkStatus,
    refetch: refetchCartResponse,
    viewer,
  } = useCheckoutContext();

  const refetch = (refetch: RefetchStatus) => {
    if (refetch === RefetchStatus.Cancel) {
      setCartRefecting(undefined);
    }
    if (refetch === RefetchStatus.StartRefetch) {
      setCartRefecting(RefetchStatus.StartRefetch);
      refetchCartResponse();
    }
  };

  useEffect(() => {
    if (
      cartRefetching === RefetchStatus.StartRefetch &&
      networkStatus === NetworkStatus.refetch
    ) {
      setTimeout(() => setCartRefecting(RefetchStatus.Done), 300);
    }
  }, [cartRefetching, networkStatus]);

  if (!viewer?.lastCart) {
    history.push({
      pathname: `/`,
    });
  }

  const getMdColumn = () => {
    let md = 8;
    if (!hasBookingDetails) {
      md = 6;
    }
    if (!hasBookingDetails && !hasLearnerDetails) {
      md = 4;
    }

    return md;
  };

  const getMdOffsetColumn = () => {
    let mdOffset = 2;
    if (!hasBookingDetails) {
      mdOffset = 3;
    }
    if (!hasBookingDetails && !hasLearnerDetails) {
      mdOffset = 4;
    }
    return mdOffset;
  };

  return (
    <Fragment>
      <MenuBar
        type="basic"
        back={{
          label: t("catalog"),
          onClick: () => history.push(`/catalog/${bookerIntention}`),
        }}
      />
      <main>
        <div className="header pb-3">
          <h1 className="sr-only">{t("booking")}</h1>
          {(hasBookingDetails || hasBookerInformation || hasLearnerDetails) && (
            <Container>
              <Row>
                <Col md={getMdColumn()} mdOffset={getMdOffsetColumn()}>
                  <Steps>
                    {hasBookerInformation && (
                      <Step
                        title={t("bookerInformation")}
                        complete={step >= 2}
                        glyph={step >= 2 ? "check" : undefined}
                        active={step === 1}
                      />
                    )}
                    {hasLearnerDetails && (
                      <Step
                        title={t("learnerDetails")}
                        complete={step >= 3}
                        glyph={step >= 3 ? "check" : undefined}
                        active={step === 2}
                      />
                    )}
                    {hasBookingDetails && (
                      <Step
                        title={t("bookingDetails")}
                        active={step === 3}
                        complete={step >= 4}
                        glyph={step >= 4 ? "check" : undefined}
                      />
                    )}
                    <Step
                      title={t("orderComplete")}
                      complete={step === 4}
                      glyph={step === 4 ? "check" : undefined}
                    />
                  </Steps>
                </Col>
              </Row>
            </Container>
          )}
        </div>
        {cartRefetching === RefetchStatus.StartRefetch && (
          <div style={{ marginTop: -20, marginBottom: 15 }}>
            <LoadingBar isLoading />
          </div>
        )}
        <Container>
          <Row>
            <Col
              lg={3}
              lgOffset={1}
              lgPush={7}
              md={4}
              mdPush={8}
              data-label="Order Summary"
            >
              <OpacityOverlay
                on={cartRefetching === RefetchStatus.StartRefetch}
              >
                <OrderSummary
                  refetchCart={refetch}
                  hidePrices={hidePricesBeforePricingAgreementApplied}
                />
                {viewer?.lastCart && step !== CheckoutStep.OrderComplete && (
                  <PromoCode cart={viewer?.lastCart} refetchCart={refetch} />
                )}
              </OpacityOverlay>
            </Col>
            <Col lg={7} lgPull={3} md={8} mdPull={4}>
              {children}
            </Col>
          </Row>
        </Container>
      </main>
    </Fragment>
  );
};

const OrderSummary: FunctionComponent<{
  refetchCart: (refetch: RefetchStatus) => void;
  hidePrices: boolean;
}> = ({ hidePrices, refetchCart }) => {
  const [mutationRunning, setMutationRunning] = useState(false);
  const { t } = useTranslation();

  const { viewer, hasBookingDetails } = useCheckoutContext();
  const cart = viewer?.lastCart;

  const { removedLineItems } = useCartRemovedLineItemsContext();
  const { invalidLineItemInfo } = useCartInvalidLineItemsContext();

  const cartItems = [...(cart?.items || []), ...removedLineItems];

  const itemWasRemovedFromCart = (item: Maybe<CartLineItem>) =>
    item ? removedLineItems.includes(item) : false;
  const getInvalidReason = (item: Maybe<CartLineItem>) => {
    if (itemWasRemovedFromCart(item)) {
      return t("itemNoValidTokenPrice");
    }
    return item && item.id ? invalidLineItemInfo[item.id] : undefined;
  };

  return (
    <Card>
      <FutureArticleHeader title={t("summary")} />
      {mutationRunning && <LoadingBar isLoading />}
      <OpacityOverlay on={mutationRunning}>
        {cartItems.map((item, i) => {
          const { event, learningPath } = getProductOption(item);
          const unitAmount = parseInt(item?.unitAmount, 10);
          return (
            <Fragment key={i}>
              <ItemSummary
                name={event?.name || learningPath?.name || ""}
                location={
                  event?.location && getLocationRegionFormat(event.location)
                }
                date={event ? <EventDateRange event={event} /> : undefined}
                price={
                  <>
                    {getFinancialFormat(
                      item?.unitAmount,
                      cart?.currency,
                      viewer?.contact.locale,
                    )}
                  </>
                }
                hidePrices={hidePrices}
                isZeroPriced={unitAmount === 0}
                wasRemovedFromCart={itemWasRemovedFromCart(item)}
                invalidReason={getInvalidReason(item)}
              />
              <hr />
            </Fragment>
          );
        })}
        {!hidePrices && hasBookingDetails && cart && (
          <CartSummary
            cart={cart}
            usage={CartSummaryUsage.Checkout}
            refetchCart={refetchCart}
            setIsRemovingPromoCode={(value: boolean) =>
              setMutationRunning(value)
            }
          />
        )}
      </OpacityOverlay>
    </Card>
  );
};
