import React, { FunctionComponent, Fragment } from "react";
import { Container, Steps, Step, Row, Col } from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { MenuBar } from "../../MenuBar";
import { useHistory } from "../../useHistory";

export enum BookerIntention {
  Coordinating = "coordinating",
  Self = "self",
}

export enum BookingStep {
  bookingComplete,
  bookingDetails,
  learnerDetails,
}

export const BookingPage: FunctionComponent<{
  step: number;
  bookerIntention?: BookerIntention | null;
  isZeroPriced?: boolean;
}> = ({ step, children, isZeroPriced }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Fragment>
      <MenuBar
        type="basic"
        back={{
          label: t("catalog"),
          onClick: () =>
            history.push(`/catalog/${BookerIntention.Coordinating}`),
        }}
      />
      <main>
        <div className="header pb-3">
          <h1 className="sr-only">{t("booking")}</h1>
          <Container>
            <Row>
              <Col md={isZeroPriced ? 6 : 8} mdOffset={isZeroPriced ? 3 : 2}>
                <Steps>
                  <Step
                    title={t("learnerDetails")}
                    complete={step >= 2}
                    glyph={step >= 2 ? "check" : undefined}
                    active={step === 1}
                  />
                  {!isZeroPriced && (
                    <Step
                      title={t("bookingDetails")}
                      active={step === 2}
                      complete={step >= 3}
                      glyph={step >= 3 ? "check" : undefined}
                    />
                  )}
                  <Step
                    title={t("bookingComplete")}
                    complete={step === 3}
                    glyph={step === 3 ? "check" : undefined}
                  />
                </Steps>
              </Col>
            </Row>
          </Container>
        </div>
        {children}
      </main>
    </Fragment>
  );
};
