import React, { useEffect, useReducer, useState, useCallback } from "react";
import { BrowserRouter } from "react-router-dom";
import {
  OverPageLoadingBarProvider as PageHeaderLoadingBarProvider,
  Card,
  StatusLabel,
  Button,
  Flex,
  View,
} from "@administrate/piston-ux";
import { ColorOptions } from "@administrate/piston-ux/lib/types";

import { Routes, UnauthorizedRoutes } from "./Routes";
import { GraphQLProvider } from "./providers/GraphQLProvider";
import { AuthProvider } from "./providers/AuthProvider";
import { PortalProvider } from "./providers/PortalProvider";
import { ViewerProvider } from "./providers/ViewerProvider";
import { ConfigLoader } from "./providers/ConfigLoader";
import { PistonProvider } from "./providers/PistonProvider";
import { ErrorDescription, useOnDiagnosticErrors } from "./utils/errorHelpers";
import { reload } from "./utils/windowHelpers";
import { AnalyticsProvider } from "./providers/AnalyticsProvider";

// we will need to upgrade to TS 4.7 before the compiler knows about structuredClone
declare function structuredClone<T>(value: T, options?: any): T;

/** separate function just for diagnostics */
function getAuthToken() {
  const key = Object.keys(window.localStorage).filter(k =>
    k.includes("auth0"),
  )[0];
  if (!key) {
    return null;
  }

  const auth0DataJsonString = window.localStorage[key];
  const auth0Data = JSON.parse(auth0DataJsonString);

  return auth0Data.body.access_token;
}

type STATUS = "in_progress" | "pass" | "fail";
type DIAGNOSTIC_NAME = "LMS Config" | "IDP" | "LMS API" | "LMS API GQL";

interface Diagnostic {
  name: DIAGNOSTIC_NAME;
  url: string;
  status: STATUS;
}

type DiagnosticRunState = "hold" | "start" | "in_progress" | "complete";

interface DiagnosticUrls {
  lmsApiUrl: string;
  idpUrl: string;
  lmsGqlUrl: string;
}

interface DiagnosticState {
  diagnostics: Diagnostic[];
  run: DiagnosticRunState;
  fetchingApi: DiagnosticRunState;
  fetchingIdp: DiagnosticRunState;
  fetchingGql: DiagnosticRunState;
  urls: Partial<DiagnosticUrls>;
}

const DEFAULT_DIAGNOSTIC: DiagnosticState = {
  diagnostics: [],
  run: "hold",
  fetchingApi: "hold",
  fetchingIdp: "hold",
  fetchingGql: "hold",
  urls: {},
};

type Action<T extends string> = { type: T };

type DiagnosticAction =
  | Action<"start">
  | (Action<"diagnostic:push"> & { diagnostic: Diagnostic })
  | (Action<"diagnostic:replace"> & {
      diagnostic: Partial<Diagnostic> & Pick<Diagnostic, "name">;
    })
  | Action<"config:start">
  | (Action<"config:pass"> & { apiUrl: string; idpUrl: string; gqlUrl: string })
  | Action<"idp:start">
  | Action<"api:start">
  | Action<"gql:start">
  | Action<"complete">;

function diagnosticReducer(state: DiagnosticState, action: DiagnosticAction) {
  let freshState = structuredClone(state);

  switch (action.type) {
    case "start":
      freshState = structuredClone(DEFAULT_DIAGNOSTIC);
      freshState.run = "start";
      return freshState;
    case "diagnostic:push":
      freshState.diagnostics.push(action.diagnostic);
      return freshState;
    case "diagnostic:replace":
      freshState.diagnostics = freshState.diagnostics.map(diagnostic => {
        if (diagnostic.name === action.diagnostic.name) {
          return { ...diagnostic, ...action.diagnostic };
        }

        return diagnostic;
      });
      return freshState;
    case "config:start":
      freshState.run = "in_progress";
      return freshState;
    case "idp:start":
      freshState.fetchingIdp = "in_progress";
      return freshState;
    case "api:start":
      freshState.fetchingApi = "in_progress";
      return freshState;
    case "gql:start":
      freshState.fetchingGql = "in_progress";
      return freshState;
    case "config:pass":
      freshState.urls = {
        idpUrl: action.idpUrl,
        lmsApiUrl: action.apiUrl,
        lmsGqlUrl: action.gqlUrl,
      };
      freshState.fetchingIdp = "start";
      freshState.fetchingApi = "start";
      freshState.fetchingGql = "start";
      freshState.run = "in_progress";
      return freshState;
    case "complete":
      freshState.run = "complete";
      return freshState;
  }
}

/** Runs a full diagnostic */
function useFullDiagnostic() {
  const [state, dispatch] = useReducer(diagnosticReducer, DEFAULT_DIAGNOSTIC);

  const runState = state.run;

  const startDiagnostic = useCallback(() => {
    dispatch({ type: "start" });
  }, [dispatch]);

  const pushDiagnostic = useCallback(
    (diagnostic: Diagnostic) => {
      dispatch({ type: "diagnostic:push", diagnostic });
    },
    [dispatch],
  );

  const replaceDiagnostic = useCallback(
    (replacement: Partial<Diagnostic> & Pick<Diagnostic, "name">) => {
      dispatch({ type: "diagnostic:replace", diagnostic: replacement });
    },
    [dispatch],
  );

  const passByName = useCallback(
    (name: DIAGNOSTIC_NAME) => {
      replaceDiagnostic({ name, status: "pass" });
    },
    [replaceDiagnostic],
  );

  const failByName = useCallback(
    (name: DIAGNOSTIC_NAME) => {
      replaceDiagnostic({ name, status: "fail" });
      dispatch({ type: "complete" });
    },
    [dispatch, replaceDiagnostic],
  );

  useEffect(() => {
    if (runState !== "start") {
      return;
    }

    dispatch({ type: "config:start" });
    pushDiagnostic({
      name: "LMS Config",
      url: `${window.location.origin}/config`,
      status: "in_progress",
    });

    fetch("/config")
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status + " " + response.statusText);
        }

        return response.json();
      })
      .then(configJson => {
        if (
          configJson &&
          configJson.brand &&
          configJson.brand_details.issuer &&
          configJson.idp &&
          configJson.idp.domain &&
          configJson.image_uri_prefix &&
          configJson.graphql_uri
        ) {
          passByName("LMS Config");

          dispatch({
            type: "config:pass",
            idpUrl: "https://" + configJson.idp.domain,
            apiUrl: configJson.image_uri_prefix,
            gqlUrl: configJson.graphql_uri,
          });
          return;
        }

        throw new Error("Invalid config");
      })
      .catch(error => {
        console.error(error);
        failByName("LMS Config");
      });
  }, [
    state,
    runState,
    passByName,
    failByName,
    pushDiagnostic,
    replaceDiagnostic,
  ]);

  useEffect(() => {
    if (
      runState !== "in_progress" ||
      (state.urls.idpUrl && state.urls.idpUrl.length === 0) ||
      state.fetchingIdp !== "start"
    ) {
      return;
    }

    dispatch({ type: "idp:start" });
    pushDiagnostic({
      name: "IDP",
      status: "in_progress",
      url: state.urls.idpUrl + "/status",
    });

    fetch(state.urls.idpUrl + "/status")
      .then(response => {
        if (!response.ok) {
          failByName("IDP");
        }

        passByName("IDP");
      })
      .catch(() => {
        failByName("IDP");
      });
  }, [
    state,
    runState,
    passByName,
    failByName,
    pushDiagnostic,
    replaceDiagnostic,
  ]);

  useEffect(() => {
    if (
      runState !== "in_progress" ||
      (state.urls.lmsApiUrl && state.urls.lmsApiUrl.length === 0) ||
      state.fetchingApi !== "start"
    ) {
      return;
    }

    let lmsApiUrl: string = state.urls.lmsApiUrl!;

    dispatch({ type: "api:start" });
    pushDiagnostic({
      name: "LMS API",
      status: "in_progress",
      url: lmsApiUrl + "/status",
    });

    fetch(lmsApiUrl + "/status")
      .then(response => {
        if (!response.ok) {
          failByName("LMS API");
        }

        passByName("LMS API");
      })
      .catch(() => {
        failByName("LMS API");
      });
  }, [
    state,
    runState,
    pushDiagnostic,
    passByName,
    failByName,
    replaceDiagnostic,
  ]);

  useEffect(() => {
    if (
      runState !== "in_progress" ||
      (state.urls.lmsGqlUrl && state.urls.lmsGqlUrl.length === 0) ||
      state.fetchingGql !== "start"
    ) {
      return;
    }

    let lmsGqlUrl: string = state.urls.lmsGqlUrl!;

    dispatch({ type: "gql:start" });

    pushDiagnostic({
      name: "LMS API GQL",
      url: lmsGqlUrl,
      status: "in_progress",
    });

    fetch(lmsGqlUrl, {
      credentials: "same-origin",
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAuthToken()}`,
      },
      body: JSON.stringify({ query: "{ viewer { id } }" }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Failed to connect to LMS GQL API");
        }

        return response.json();
      })
      .then(jsonData => {
        if (!jsonData.data.viewer.id) {
          throw new Error("Failed to fetch viewer");
        }

        passByName("LMS API GQL");
      })
      .catch(error => {
        console.error(error);
        failByName("LMS API GQL");
      })
      .finally(() => {
        dispatch({ type: "complete" });
      });
  }, [state, runState, failByName, passByName, pushDiagnostic]);

  return {
    diagnostics: state.diagnostics,
    startDiagnostic,
    isRunning: runState === "in_progress",
  };
}

const DiagnosticStatus: React.FC<{ status: STATUS }> = ({ status }) => {
  let glyph: React.ComponentProps<typeof StatusLabel>["glyph"] = "cog";
  let text = "checking";
  let color: ColorOptions = ColorOptions.Yellow;

  if (status === "fail") {
    glyph = "circleAlert";
    text = "failed";
    color = ColorOptions.Red;
  }

  if (status === "pass") {
    glyph = "check";
    text = "passed";
    color = ColorOptions.Green;
  }

  return <StatusLabel glyph={glyph} color={color} text={text} />;
};

const buttonViewWidth = {
  large: "15%",
  medium: "20%",
  small: "35%",
  extraSmall: "50%",
};

const ErrorHandler: React.FunctionComponent = ({ children }) => {
  const [diagnosticErrors, setDiagnosticErrors] = useState<ErrorDescription[]>(
    [],
  );
  const hasRequiredErrors =
    diagnosticErrors.filter(error => error.required).length > 0;
  const plural = diagnosticErrors.length > 1;

  const addError = useCallback(
    (err: ErrorDescription) => {
      const newArr = structuredClone(diagnosticErrors);
      newArr.push(err);
      setDiagnosticErrors(newArr);
    },
    [diagnosticErrors, setDiagnosticErrors],
  );

  useOnDiagnosticErrors(addError);

  const { diagnostics, startDiagnostic, isRunning } = useFullDiagnostic();

  if (hasRequiredErrors) {
    return (
      <div>
        <Card title="A critical network failure prevented the LMS from loading.">
          <StatusLabel text="Network Failure" color={ColorOptions.Red} />
          <h4>Error{plural && "s"}:</h4>
          <Card>
            {diagnosticErrors.map(diagnostic => (
              <div key={diagnostic.qualifier}>
                <span>
                  - {diagnostic.name} at {diagnostic.qualifier} has{" "}
                  {diagnostic.status}
                </span>
                {diagnostic.fullError && (
                  <pre className="ml-4">{diagnostic.fullError.toString()}</pre>
                )}
              </div>
            ))}
          </Card>

          <p>
            Please share the above error{plural && "s"} with your network
            administrator or learning admin for additional troubleshooting.
          </p>
          <p>
            Press the "Run Full Diagnostic" button below to run a network test
            against for the required services from this browser.
          </p>
          <p>
            You may attempt to reload this page to see if the root cause was a
            temporary network outage. The LMS will be able to load if
            diagnostics are all green.
          </p>

          <br />

          <Flex gap="default">
            <View
              width={buttonViewWidth}
              paddingLeft="large"
              paddingRight="large"
            >
              <Button
                label="Run Full Diagnostic"
                type="default"
                onClick={startDiagnostic}
                id="diagnostic"
                disabled={isRunning}
              />
            </View>
            <View
              width={buttonViewWidth}
              paddingLeft="large"
              paddingRight="large"
            >
              <Button
                label="Reload LMS"
                type="primary"
                onClick={reload}
                id="reload"
              />
            </View>
          </Flex>

          <br />

          {diagnostics.length > 0 && (
            <>
              <h4>Diagnostics</h4>
              <ul className="ml-2">
                {diagnostics.map(diagnostic => (
                  <li className="my-4" key={diagnostic.name}>
                    <DiagnosticStatus status={diagnostic.status} />{" "}
                    {diagnostic.name} at <code>{diagnostic.url}</code>
                  </li>
                ))}
              </ul>
            </>
          )}
        </Card>
      </div>
    );
  }
  return <>{children}</>;
};

const App: React.FunctionComponent = () => {
  return (
    <ErrorHandler>
      <ConfigLoader>
        <BrowserRouter>
          <UnauthorizedRoutes>
            <AuthProvider>
              <PageHeaderLoadingBarProvider>
                <GraphQLProvider>
                  <PortalProvider>
                    <ViewerProvider>
                      <PistonProvider>
                        <AnalyticsProvider>
                          <Routes />
                        </AnalyticsProvider>
                      </PistonProvider>
                    </ViewerProvider>
                  </PortalProvider>
                </GraphQLProvider>
              </PageHeaderLoadingBarProvider>
            </AuthProvider>
          </UnauthorizedRoutes>
        </BrowserRouter>
      </ConfigLoader>
    </ErrorHandler>
  );
};

export default App;
