import {
  useQuery, // eslint-disable-line no-restricted-imports
  useMutation, // eslint-disable-line no-restricted-imports
  useLazyQuery, // eslint-disable-line no-restricted-imports
  QueryHookOptions,
  MutationHookOptions,
  LazyQueryHookOptions,
  QueryTuple,
  MutationTuple,
} from "@apollo/react-hooks";
import { FetchPolicy, DocumentNode } from "apollo-boost";
import { OperationVariables, QueryResult } from "@apollo/react-common";
import {
  GraphQLContextValue,
  useGraphQLContext,
} from "../providers/GraphQLProvider";
import { BookerIntention } from "../pages/Order/BookingPage";
import { useBookerIntention } from "./useBookerIntention";

export const NO_WEBLINK_CLIENTS_ERROR =
  "No WebLink client set up - possibly there are no linked Booking and Self Registration WebLink portals?";

export const NO_BOOKING_WEBLINK_CLIENT_ERROR =
  "No Booking WebLink client set up - possibly there are no linked Booking WebLink portals?";

export const NO_SELF_REGISTRATION_WEBLINK_CLIENT_ERROR =
  "No Self Registration WebLink client set up - possibly there are no linked Self Registration WebLink portals?";

export const useWebLinkClient = () => {
  const context = useGraphQLContext();
  const [bookerIntention] = useBookerIntention();

  return findWeblinkClient(context, bookerIntention);
};

export const findWeblinkClient = (
  context: GraphQLContextValue,
  bookerIntention: BookerIntention,
) => {
  const { selfRegistrationWebLinkClient, bookingWebLinkClient } = context;

  if (!bookingWebLinkClient && !selfRegistrationWebLinkClient) {
    throw new Error(NO_WEBLINK_CLIENTS_ERROR);
  }

  if (
    bookerIntention === BookerIntention.Coordinating &&
    !bookingWebLinkClient
  ) {
    throw new Error(NO_BOOKING_WEBLINK_CLIENT_ERROR);
  }
  if (
    bookerIntention === BookerIntention.Self &&
    !selfRegistrationWebLinkClient
  ) {
    throw new Error(NO_SELF_REGISTRATION_WEBLINK_CLIENT_ERROR);
  }

  if (
    bookerIntention === BookerIntention.Coordinating &&
    bookingWebLinkClient
  ) {
    return bookingWebLinkClient;
  }

  if (
    bookerIntention === BookerIntention.Self &&
    selfRegistrationWebLinkClient
  ) {
    return selfRegistrationWebLinkClient;
  }

  // If there's no intention return the currently available client
  return bookingWebLinkClient! ?? selfRegistrationWebLinkClient!;
};

export function useWebLinkQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> {
  const client = useWebLinkClient();

  const queryOptions = {
    fetchPolicy: "no-cache" as FetchPolicy,
    ...options,
    client,
  };

  return useQuery(query, queryOptions);
}

export function useWebLinkMutation<
  TData = any,
  TVariables = Record<string, any>,
>(
  mutation: any,
  options?: MutationHookOptions<TData, TVariables> | undefined,
): MutationTuple<TData, TVariables> {
  const client = useWebLinkClient();

  const mutationOptions = {
    fetchPolicy: "no-cache" as FetchPolicy,
    ...options,
    client,
  };

  return useMutation(mutation, mutationOptions);
}

export function useLazyWebLinkQuery<
  TData = any,
  TVariables = OperationVariables,
>(
  query: DocumentNode,
  options?: LazyQueryHookOptions<TData, TVariables>,
): QueryTuple<TData, TVariables> {
  const client = useWebLinkClient();

  return useLazyQuery(query, {
    ...options,
    client,
  });
}
