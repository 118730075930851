const SHORT_VIDEO_RECORDING_INTERVAL_PERCENTAGE = 0.01;
const MAX_SHORT_VIDEO_LENGTH_SECONDS = 1000;
const LONG_VIDEO_RECORDING_INTERVAL_SECONDS = 10;

export function lastRecordedTooLongAgo(
  current: number,
  previous: number,
  durationInSecs: number,
) {
  if (previous === 0 || current === 0) {
    return true;
  }
  const recordInterval = isShortVideo(durationInSecs)
    ? SHORT_VIDEO_RECORDING_INTERVAL_PERCENTAGE
    : secondsAsPercentage(durationInSecs);
  return previous + recordInterval <= current;
}

function isShortVideo(durationInSecs: number) {
  return durationInSecs <= MAX_SHORT_VIDEO_LENGTH_SECONDS;
}

function secondsAsPercentage(durationInSecs: number) {
  return LONG_VIDEO_RECORDING_INTERVAL_SECONDS / durationInSecs;
}
