import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useHistory } from "../useHistory";
import { useViewer } from "./ViewerProvider";
import { BaseEvent, PageViewEvent } from "../analytics/events";

declare global {
  interface Window {
    dataLayer: Array<object> | undefined;
  }
}

type AnalyticsContextType = {
  captureEvent: (event: BaseEvent | null) => void;
};

const AnalyticsContext = createContext<AnalyticsContextType>({
  captureEvent: () => {},
});

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  return context;
};

export const AnalyticsProvider: React.FunctionComponent = ({ children }) => {
  const { listen } = useHistory();
  const { viewer } = useViewer();

  const additionalData = useMemo(
    () => ({
      user_id: viewer?.id ?? null,
      language: viewer?.locale ?? null,
    }),
    [viewer],
  );

  const captureEvent = useCallback(
    (event: BaseEvent | null) => {
      if (!event) return;

      event.user_id = additionalData.user_id;
      event.page_location = window.location.href;
      event.page_title = window.document.title;
      event.language = additionalData.language;

      if (!!window.dataLayer) {
        console.debug("Analytics event emitted: ", event);
        window.dataLayer.push(event);
      }
    },
    [additionalData],
  );

  useEffect(() => {
    // emits the 1st Page View when LMS is 1st loaded
    if (!viewer) return;
    captureEvent(new PageViewEvent());
  }, [viewer, captureEvent]);

  useEffect(() => {
    // emits subsequent Page View events when navigating
    const unregisterListener = listen((location, action) => {
      captureEvent(new PageViewEvent());
    });
    return unregisterListener;
  }, [listen, captureEvent]);

  return (
    <AnalyticsContext.Provider value={{ captureEvent }}>
      {children}
    </AnalyticsContext.Provider>
  );
};
