import React, { useEffect, Fragment, FunctionComponent } from "react";
import {
  Button,
  Card,
  StatusLabel,
  List,
  ListRow,
  ListCell,
  FutureArticleHeader,
  Col,
  Row,
  FutureDetailPage,
  Progress,
  DetailPageContent,
  Placeholder,
  DetailPageProps,
  BodyText,
  Alert,
} from "@administrate/piston-ux";
import { DetailPageOverview } from "@administrate/piston-ux/lib/layouts/DetailPage/DetailPageOverview";
import { BackButtonConfig } from "@administrate/piston-ux/lib/Header/types";
import { ColorOptions, Maybe } from "@administrate/piston-ux/lib/types";
import { ListEmpty } from "@administrate/piston-ux/lib/List";
import { Trans, useTranslation } from "react-i18next";

import { useHistory, useParams } from "../../useHistory";
import { CANCELLATION_REQUEST_MUTATION } from "../../queries/cancellation";
import {
  COURSE_REGISTRATION_DETAILS_QUERY,
  GET_PREVIEW_COURSE_QUERY,
} from "../../queries/registrations";
import { CancellationRequestCreateResponse } from "../../types/Cancellation";
import {
  CourseRegistrationResponse,
  Registration,
} from "../../types/Registration";
import { CancelRegistrationModal } from "../../components/CancelRegistrationModal";
import { useDateFormatter } from "../../hooks/useDateFormatter";

import coursePlaceholder from "../../images/course-placeholder.png";
import { useLmsQuery, useLmsMutation } from "../../hooks/lms";
import { usePortal } from "../../providers/PortalProvider";
import { AchievementCard } from "../../components/AchievementCard";
import { formatLocalisedDateTime } from "../../utils/dateTimeHelpers";
import {
  completedSteps,
  getProgress,
  getRequiredSessions,
  totalSteps,
} from "../../utils/event";
import { NextContentButton } from "../../components/NextContentButton";
import { useGlobalStore } from "../../providers/GlobalStore";
import { ContentList } from "../../components/OnlineContent/ContentList";
import { ContentLoadingSkeleton } from "../../components/OnlineContent/ContentLoadingSkeleton";
import { Achievement, LearningMode, Scalars } from "../../generated/lmsTypes";
import moment from "moment";
import { useViewer } from "../../providers/ViewerProvider";
import { CourseResources } from "./CourseResources";
import { FetchMoreCallback } from "../../types/ApolloCallbacks";
import {
  isLmsContentAccessible,
  isLmsContentInThePast,
} from "../../utils/eventHelpers";
import { determinePassAccessStatus } from "../../utils/registrationHelpers";
import {
  CancelLearnerModal,
  LearnerCategory,
} from "../../pages/LearnerManagement/CancelLearnerModal";

const MAX_SCORE = 100;

export const CourseDetail: FunctionComponent = () => {
  const { id: learnerId, learningPathId, status } = useParams<{id:string, learningPathId:string, status:string}>();
  const { t } = useTranslation();

  const {
    loading: courseDetailsIsLoading,
    data: courseDetailsResponse,
    fetchMore: fetchMoreCourseDetails,
  } = useLmsQuery<CourseRegistrationResponse>(
    COURSE_REGISTRATION_DETAILS_QUERY,
    {
      variables: { id: learnerId },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    },
  );

  const registration = courseDetailsResponse?.registrations?.edges?.[0]?.node;

  return (
    <Fragment>
      {status === "registrationSuccess" && (
        <Alert message={t("registrationSuccess")} type="success" overPage />
      )}
      <CourseDetailInfo
        registration={registration}
        learningPathId={learningPathId}
        learnerId={learnerId}
        courseDetailsIsLoading={courseDetailsIsLoading}
        fetchMoreCourseDetails={fetchMoreCourseDetails}
      />
    </Fragment>
  );
};

export const PreviewCourseDetail: FunctionComponent = () => {
  const { previewJWT } = useGlobalStore();

  const {
    loading: courseDetailsIsLoading,
    data: courseDetailsResponse,
    fetchMore: fetchMoreCourseDetails,
  } = useLmsQuery<CourseRegistrationResponse>(GET_PREVIEW_COURSE_QUERY, {
    variables: { jwt: previewJWT },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const registration = courseDetailsResponse?.coursePreview?.edges?.[0]?.node;

  return (
    <CourseDetailInfo
      registration={registration}
      courseDetailsIsLoading={courseDetailsIsLoading}
      fetchMoreCourseDetails={fetchMoreCourseDetails}
    />
  );
};

const CourseDetailInfo: FunctionComponent<{
  registration: Registration | undefined;
  learnerId?: string;
  learningPathId?: string;
  courseDetailsIsLoading: boolean;
  fetchMoreCourseDetails: FetchMoreCallback;
}> = ({
  learnerId,
  registration,
  learningPathId,
  courseDetailsIsLoading,
  fetchMoreCourseDetails,
}) => {
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const portal = usePortal();
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const { isApprovalPortal } = portal;
  const history = useHistory();
  const { dateToDateFormat } = useDateFormatter({ showTime: true });
  const { dateFormat } = useDateFormatter({ showTime: false });
  const { convertLMSImageUrl } = useGlobalStore();

  const attendanceMarkToAttendanceLabel = (
    attendanceMark: string,
    startDate: Maybe<Scalars["Datetime"]>,
    endDate: Maybe<Scalars["Datetime"]>,
  ): {
    attendanceMessage: string;
    attendanceType:
      | ColorOptions.Green
      | ColorOptions.LightGrey
      | ColorOptions.Red;
  } => {
    if (attendanceMark === "Present") {
      return {
        attendanceMessage: t("attended"),
        attendanceType: ColorOptions.Green,
      };
    } else if (attendanceMark === "Absent") {
      return {
        attendanceMessage: t("notAttended"),
        attendanceType: ColorOptions.Red,
      };
    }

    let message = t("upcoming");
    const now = moment().tz(viewer?.timeZoneName || moment.tz.guess());
    const start = moment(startDate);
    const end = moment(endDate);
    if (endDate && end.isBefore(now)) {
      message = t("past");
    } else if (startDate && start.isBefore(now) && end.isAfter(now)) {
      message = t("ongoing");
    }
    return {
      attendanceMessage: message,
      attendanceType: ColorOptions.LightGrey,
    };
  };

  // Modal
  const [
    cancellationRequest,
    {
      loading: cancellationRequestIsLoading,
      called: cancellationRequestIsCalled,
      data: cancellationRequestData,
    },
  ] = useLmsMutation<CancellationRequestCreateResponse>(
    CANCELLATION_REQUEST_MUTATION,
  );

  useEffect(() => {
    if (cancellationRequestIsCalled && !cancellationRequestIsLoading) {
      const hasErrors =
        cancellationRequestData?.cancellationRequest?.create?.errors?.length;
      if (!hasErrors) {
        history.push("/my-requests");
      } else {
        // TODO: show some sort of error.
      }
    }
  }, [
    cancellationRequestData,
    cancellationRequestIsCalled,
    cancellationRequestIsLoading,
    history,
  ]);

  const onCancellationModalDone = ({
    postMutation,
    reasonId,
    reasonNotes,
  }: {
    postMutation: boolean;
    reasonId?: string;
    reasonNotes?: string;
  }) => {
    setShowCancelModal(false);
    if (postMutation) {
      cancellationRequest({
        variables: {
          learnerId,
          reasonId,
          reasonNotes,
        },
      });
    }
  };

  const classRooms = registration?.course?.sessions?.edges || [];
  const attendances = registration?.attendance?.sessionDetail?.edges || [];
  const online = registration?.course?.content?.edges || [];

  const lmsContentAccessible = isLmsContentAccessible(
    viewer?.isPreviewer || false,
    registration?.course?.lmsStart,
    registration?.course?.lmsEnd,
  );
  const lmsContentInThePast = isLmsContentInThePast(
    registration?.course?.lmsEnd,
  );
  const lmsCutoffDate = lmsContentInThePast
    ? registration?.course?.lmsEnd
    : registration?.course?.lmsStart;

  const totalPiecesOfContent = registration?.totalPiecesOfContent || 0;
  const completedPiecesOfContent = registration?.completedPiecesOfContent || 0;

  const { requiredSessions, attendedRequiredSessions } =
    getRequiredSessions(registration);

  const totalContentAndSessions = totalSteps(
    totalPiecesOfContent + requiredSessions,
  );
  const totalCompletedAndAttended = completedSteps(
    completedPiecesOfContent + attendedRequiredSessions,
  );

  const currentProgress = getProgress(
    totalContentAndSessions,
    totalCompletedAndAttended,
  );

  const hasPassed = registration?.hasPassed || null;

  const totalContentSteps = totalSteps(totalPiecesOfContent);
  const currentContentProgress = getProgress(
    totalPiecesOfContent,
    completedPiecesOfContent,
  );
  const hasNextContentButton =
    lmsContentAccessible &&
    totalContentSteps !== 0 &&
    currentContentProgress !== 100;

  const course = registration?.course;
  const courseDescription = course?.message || "";
  const lmsAnnouncements = course?.lmsAnnouncements || "";
  const passAccessExpired = registration?.passAccessExpired || false;

  const isTrainingPassRegistration = !!registration?.trainingPassTypeId;
  const showCancelButton =
    isApprovalPortal ||
    (isTrainingPassRegistration &&
      course?.learningMode === LearningMode.SelfPaced);

  let back: BackButtonConfig = {
    label: t("myCourses"),
    onClick: () => history.push("/my-courses"),
    disabled: viewer?.isPreviewer,
  };

  if (learningPathId) {
    back = {
      label: t("learningPath"),
      onClick: () =>
        history.push(`/my-courses/learning-path/${learningPathId}`),
    };
  }
  const expiry = formatLocalisedDateTime(registration?.expiry);
  let passStatusMessage = "";
  if (
    !expiry &&
    registration?.passAccessExpired &&
    viewer &&
    registration?.trainingPassTypeId
  ) {
    passStatusMessage = determinePassAccessStatus(
      viewer,
      registration?.trainingPassTypeId,
      t,
    );
  }

  let expiryMessageNode: undefined | JSX.Element;
  if (expiry) {
    expiryMessageNode = (
      <p style={{ fontSize: 14, fontWeight: 300 }} className="my-3">
        {t("registrationExpiry", {
          expiry,
        })}
      </p>
    );
  } else if (passStatusMessage.length) {
    expiryMessageNode = (
      <p style={{ fontSize: 14, fontWeight: 300 }} className="my-3">
        {passStatusMessage}
      </p>
    );
  }

  const getHeader = (): DetailPageProps["header"] => {
    return {
      extra: !courseDetailsIsLoading &&
        (hasNextContentButton || showCancelButton) && (
          <Fragment>
            {!passAccessExpired &&
              hasNextContentButton &&
              course &&
              registration?.contentResults && (
                <NextContentButton
                  type="link"
                  contentResults={registration?.contentResults}
                  courseContent={course?.content}
                  viewer={viewer}
                  registrationId={registration?.id}
                />
              )}
            {showCancelButton && (
              <Fragment>
                <Button
                  label={t("cancelRegistration")}
                  onClick={() => setShowCancelModal(true)}
                  disabled={
                    courseDetailsIsLoading || cancellationRequestIsLoading
                  }
                  type="default"
                />
                {isApprovalPortal ? (
                  <CancelRegistrationModal
                    show={showCancelModal}
                    onDone={onCancellationModalDone}
                  />
                ) : (
                  showCancelModal && (
                    <CancelLearnerModal
                      hideGenericMessage
                      learnerId={learnerId!}
                      onModalClose={submitted => {
                        setShowCancelModal(false);
                        if (submitted) {
                          history.push("/my-courses");
                        }
                      }}
                      learnerCategory={LearnerCategory.Event}
                    />
                  )
                )}
              </Fragment>
            )}
          </Fragment>
        ),
      children: expiryMessageNode,
    };
  };

  const achievementTypes = course?.achievementTypes || [];
  const achievementsByType:
    | { [achievementTypeId: string]: Achievement }
    | undefined = registration?.achievements?.reduce(
    (acc, a) => ({ ...acc, [a.achievementType?.id || ""]: a }),
    {},
  );
  const achievementsToRender = achievementTypes.map(at => ({
    achievementType: at,
    achievement:
      achievementsByType && at.id && achievementsByType[at.id]
        ? achievementsByType[at.id]
        : null,
  }));

  return (
    <FutureDetailPage
      title={course?.title || ""}
      layout="adjacent"
      detailType="Event"
      legacyId={0}
      page="overview"
      form={{
        saveState: null,
      }}
      back={back}
      header={getHeader()}
    >
      <DetailPageOverview>
        {courseDetailsIsLoading && (
          <Card
            imageSource={coursePlaceholder}
            label={{
              text: t("course"),
              color: ColorOptions.Blue,
            }}
            extraClass="card--type-event"
          >
            <Placeholder />
          </Card>
        )}
        {!courseDetailsIsLoading && (
          <Card
            imageSource={
              registration?.course?.imageUrl
                ? convertLMSImageUrl(registration?.course?.imageUrl)
                : coursePlaceholder
            }
            label={{
              text: t("course"),
              color: ColorOptions.Blue,
            }}
            progress={
              !!online.length && !courseDetailsIsLoading ? (
                <Progress
                  value={hasPassed ? 1 : currentProgress}
                  maxValue={hasPassed ? 1 : MAX_SCORE}
                  title={
                    <Trans i18nKey="stepsComplete">
                      <strong>{{ totalCompletedAndAttended }}</strong>
                      <strong>{{ totalContentAndSessions }}</strong>
                    </Trans>
                  }
                />
              ) : undefined
            }
            extraClass="card--type-event"
          >
            <Fragment>
              {achievementsToRender.map((a, i) => (
                <Fragment key={i}>
                  {i > 0 && (
                    <Fragment>
                      <br />
                      <hr />
                    </Fragment>
                  )}
                  <AchievementCard
                    key={i}
                    achievementTypeName={a.achievementType.name}
                    achievement={a.achievement}
                  />
                </Fragment>
              ))}
            </Fragment>
          </Card>
        )}
        <CourseResources
          documents={course?.documents ?? []}
          resources={course?.resources ?? []}
        />
      </DetailPageOverview>
      <DetailPageContent>
        <Fragment>
          {!!courseDescription.length && (
            <Card>
              <FutureArticleHeader title={t("about")} />
              <Row>
                <Col xs={12} lg={10}>
                  <div
                    dangerouslySetInnerHTML={{ __html: courseDescription }}
                  />
                </Col>
              </Row>
            </Card>
          )}
          {!!lmsAnnouncements.length && (
            <Card>
              <Row>
                <Col xs={12} lg={10}>
                  <div dangerouslySetInnerHTML={{ __html: lmsAnnouncements }} />
                </Col>
              </Row>
            </Card>
          )}
          {!!online.length && registration && (
            <Card>
              <FutureArticleHeader
                title={t("content")}
                extra={[
                  !lmsContentAccessible && (
                    <div style={{ paddingTop: "6px" }} key={1}>
                      {lmsContentInThePast ? t("finishedOn") : t("opensOn")}{" "}
                      {dateFormat({ date: lmsCutoffDate })}
                    </div>
                  ),
                ]}
              />
              <List extraClass="my-course">
                <ContentList
                  usage="courseDetail"
                  registration={registration}
                  learningPathId={learningPathId}
                  fetchMoreCourseDetails={fetchMoreCourseDetails}
                  courseDetailsIsLoading={courseDetailsIsLoading}
                  isAccessible={lmsContentAccessible && !passAccessExpired}
                />
              </List>
            </Card>
          )}
          {!!classRooms.length && (
            <Card>
              <FutureArticleHeader title={t("classroom")} />
              <List extraClass="my-course">
                {classRooms.map(({ node: classroomContent }, index) => {
                  const attendanceMark =
                    attendances.find(
                      ({ node: attendanceNode }) =>
                        attendanceNode.session.id === classroomContent.id,
                    )?.node.attendanceMark || "";
                  const { attendanceMessage, attendanceType } =
                    attendanceMarkToAttendanceLabel(
                      attendanceMark,
                      classroomContent.timeZonedStart,
                      classroomContent.timeZonedEnd,
                    );

                  return (
                    <Fragment key={index}>
                      <ListRow
                        extraClass="content-row classroom"
                        dataLabel={classroomContent?.title || undefined}
                      >
                        <ListCell label={t("type")} extraClass="content-type">
                          <span
                            className="glyphicon-pro glyphicon-pro-user-group glyphicon-lg"
                            style={{
                              top: "4px",
                            }}
                          />
                        </ListCell>
                        <ListCell
                          label={t("content")}
                          extraClass="content-name"
                        >
                          <p className="font-weight-bold">
                            {classroomContent?.title}
                          </p>
                          <Row>
                            {classroomContent?.location?.name && (
                              <Col
                                lg={classroomContent?.remoteMeetingUrl ? 3 : 4}
                                className="mb-2 mb-xl-0"
                              >
                                <BodyText
                                  glyph="map"
                                  message={classroomContent?.location?.name}
                                />
                              </Col>
                            )}
                            {(classroomContent?.timeZonedStart ||
                              classroomContent?.timeZonedEnd) && (
                              <Col
                                lg={classroomContent?.remoteMeetingUrl ? 6 : 8}
                                className="mb-2 mb-xl-0"
                              >
                                <BodyText
                                  glyph="calendar"
                                  message={dateToDateFormat({
                                    start: classroomContent.timeZonedStart,
                                    end: classroomContent.timeZonedEnd,
                                    customTimeZoneName:
                                      viewer?.timeZoneName || moment.tz.guess(),
                                  })}
                                />
                              </Col>
                            )}
                            {classroomContent?.remoteMeetingUrl && (
                              <Col
                                lg={3}
                                className="mb-2 mb-xl-0"
                                style={{
                                  marginTop: -1,
                                }}
                              >
                                <BodyText
                                  glyph="webcam"
                                  message={
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                    <a
                                      className="btn btn-link py-0"
                                      href={classroomContent?.remoteMeetingUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {t("joinOnlineTraining")}
                                    </a>
                                  }
                                />
                              </Col>
                            )}
                          </Row>
                        </ListCell>
                        <ListCell label={t("status")} extraClass="content-meta">
                          <div className="float-md-right">
                            <StatusLabel
                              color={attendanceType}
                              text={attendanceMessage}
                            />
                          </div>
                        </ListCell>
                      </ListRow>
                    </Fragment>
                  );
                })}
              </List>
            </Card>
          )}
          {!classRooms.length && !online.length && !courseDetailsIsLoading && (
            <List>
              <ListEmpty text={t("noResults")} />
            </List>
          )}
          {courseDetailsIsLoading && (
            <Card>
              <ContentLoadingSkeleton />
            </Card>
          )}
        </Fragment>
      </DetailPageContent>
    </FutureDetailPage>
  );
};
