import React from "react";
import { Button, Col, Container } from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { ContentResultStatus } from "../../../generated/lmsTypes";

export const ExternalActivityResult: React.FunctionComponent<{
  status?: ContentResultStatus;
  onClickTryAgain: () => void;
}> = ({ status, onClickTryAgain }) => {
  const { t } = useTranslation();
  const resultIcon =
    status === ContentResultStatus.Passed ? (
      <span className="icon glyphicon glyphicon-pro-check external-activity-result-icon external-activity-result-icon--pass" />
    ) : (
      <span className="icon glyphicon glyphicon-pro-times external-activity-result-icon external-activity-result-icon--fail" />
    );
  const resultText =
    status === ContentResultStatus.Passed
      ? t("externalActivityPassedText")
      : t("externalActivityFailedText");

  return (
    <Container>
      <Col md={2} />
      <Col md={8}>
        <div className="external-activity-result">
          <div
            className={`external-activity-result-icon ${
              status === ContentResultStatus.Failed ? "failed" : ""
            }`}
          >
            {resultIcon}
          </div>
          <div className="external-activity-result-header">
            {status === ContentResultStatus.Passed ? (
              <h1>{t("externalActivityPassedHeader")}</h1>
            ) : (
              <h1>{t("externalActivityFailedHeader")}</h1>
            )}
          </div>
          <div className="external-activity-result-text">{resultText}</div>
          {status === ContentResultStatus.Failed && (
            <div className="external-activity-result-button">
              <Button
                label={t("externalActivityTryAgain")}
                type="primary"
                onClick={onClickTryAgain}
              />
            </div>
          )}
        </div>
      </Col>
      <Col md={2} />
    </Container>
  );
};
