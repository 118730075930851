import React, {
  FunctionComponent,
  Fragment,
  useEffect,
  useState,
  useMemo,
} from "react";
import {
  Container,
  Card,
  Header,
  Row,
  Col,
  SafeHTMLDisplay,
  ListCell,
  LoadingBar,
} from "@administrate/piston-ux";
import { get, includes } from "lodash";
import { Well } from "react-bootstrap";
import { ColorOptions } from "@administrate/piston-ux/lib/types";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "../../useHistory";
import { DetailEventList } from "./DetailEventList";
import { WEBLINK_COURSE_DETAILS_QUERY } from "../../queries/courses";
import { useGlobalStore } from "../../providers/GlobalStore";
import coursePlaceholder from "../../images/course-placeholder.png";
import { useLazyWebLinkQuery, useWebLinkQuery } from "../../hooks/weblink";
import { RequestTraining } from "../../components/RequestTraining";
import { useBookerIntention } from "../../hooks/useBookerIntention";
import { CartWidget } from "../Cart/CartWidget";
import { BookerIntention } from "../Order/BookingPage";
import { WEBLINK_EVENTS_FOR_COURSE_QUERY } from "../../queries/events";
import {
  EVENT_TABLE_PAGINATION,
  getEventFilters,
  isCourseStartable,
} from "../../utils/eventHelpers";
import { Query } from "../../generated/weblinkTypes";
import { useViewer } from "../../providers/ViewerProvider";
import moment from "moment";
import AutoRegister from "../../components/AutoRegister";
import { useWeblinkSettings } from "../../hooks/useWeblinkSettings";
import { useDetailFilters } from "./DetailFilterContext";
import { TrainingPassContentStatusLabel } from "../../components/TrainingPass";
import { useTrainingPassTypes } from "../../hooks/useTrainingPassTypes";
import { useAnalytics } from "../../providers/AnalyticsProvider";
import { ProductViewEvent, SearchEvent } from "../../analytics/events";

export const Detail: FunctionComponent = () => {
  const { convertLMSImageUrl } = useGlobalStore();
  const { cartlessCheckout, loading: weblinkLoading } = useWeblinkSettings();
  const trainingPassTypes = useTrainingPassTypes();
  const { viewer } = useViewer();
  const history = useHistory();
  const { captureEvent } = useAnalytics();
  const { t } = useTranslation();

  const { id, catalogType } = useParams<{id:string, catalogType:BookerIntention}>();
  const { filters } = useDetailFilters();
  const [bookerIntention, setBookerIntention] = useBookerIntention();

  const [isPageLoading, setIsPageLoading] = useState(false);

  const viewerTimeZone = viewer?.timeZoneName ?? moment.tz.guess();

  if (bookerIntention !== catalogType) {
    setBookerIntention(catalogType);
  }

  const { data: courseDetailsResponse, loading } = useWebLinkQuery<Query>(
    WEBLINK_COURSE_DETAILS_QUERY,
    {
      variables: { id },
    },
  );

  const course = courseDetailsResponse?.courses?.edges?.[0]?.node;
  const courseCode = course?.code || "";
  const courseName = course?.name || "";
  const outcomes = course?.outcomes || "";
  const description = course?.description || "";
  const topicsCovered = course?.topicsCovered || "";
  const preRequisites = course?.prerequisites || "";
  const FAQs = course?.faqs || "";
  const contentPasses = trainingPassTypes.filter(tpt =>
    includes(course?.includedInPassTypeIds ?? [], tpt.id),
  );

  const eventFilters = useMemo(
    () => getEventFilters(courseCode, filters, viewerTimeZone),
    [courseCode, filters, viewerTimeZone],
  );

  const [
    loadEvents,
    { data: eventsResponse, refetch: refetchEvents, loading: eventLoading },
  ] = useLazyWebLinkQuery<Query>(WEBLINK_EVENTS_FOR_COURSE_QUERY, {
    variables: {
      first: EVENT_TABLE_PAGINATION,
      offset: 0,
      filters: eventFilters,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (courseCode) {
      loadEvents();
    }
  }, [courseCode, loadEvents]);

  useEffect(() => {
    if (loading || !course) return;
    captureEvent(ProductViewEvent.fromCourseView(course));
  }, [course, loading, captureEvent]);

  useEffect(() => {
    if (eventLoading || !eventsResponse) return;
    captureEvent(
      SearchEvent.fromEventsSearch(
        eventsResponse.events.pageInfo.totalRecords,
        eventFilters,
      ),
    );
  }, [eventLoading, eventsResponse, captureEvent, eventFilters]);

  const events = eventsResponse?.events?.edges || [];
  const totalEventRecords = get(eventsResponse, "events.pageInfo.totalRecords");
  const startableCourse =
    bookerIntention === BookerIntention.Self &&
    cartlessCheckout &&
    isCourseStartable(events);

  const prices = [
    ...events.map(e => e.node.prices).flat(),
    course?.priceRange?.minimumPrice,
    course?.priceRange?.normalPrice,
    course?.priceRange?.maximumPrice,
  ];
  const hasPricing = prices.some(price => {
    return (price?.amount ?? null) !== null;
  });

  return (
    <div className="page">
      {isPageLoading && <LoadingBar isLoading />}
      <Header
        title={courseName}
        back={{
          label: t("catalog"),
          onClick: () => history.push(`/catalog/${bookerIntention}`),
        }}
        extra={
          <Fragment>
            {bookerIntention === BookerIntention.Self && <CartWidget />}
            <RequestTraining
              course={{
                id,
                name: courseName,
              }}
            />
          </Fragment>
        }
        usage="portal"
      >
        <div style={{ maxWidth: 768 }}>
          <small>
            <SafeHTMLDisplay html={course?.teaserDescription || ""} />
          </small>
        </div>
      </Header>
      <div className="result-page">
        <Container>
          <Row>
            <Col md={8}>
              {outcomes.length > 0 && (
                <Well className="clearfix">
                  <h2 className="mt-0">{t("whatYoullLearn")}</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: outcomes,
                    }}
                  ></div>
                </Well>
              )}
              <h2>{t("description")}</h2>
              {description.length > 0 || loading ? (
                <Fragment>
                  {loading ? (
                    <span
                      className="placeholder animated"
                      style={{ maxWidth: "500px" }}
                    />
                  ) : (
                    <SafeHTMLDisplay html={description} />
                  )}
                </Fragment>
              ) : (
                "-"
              )}

              {topicsCovered.length > 0 && (
                <Fragment>
                  <h2>{t("topicsCovered")}</h2>
                  <SafeHTMLDisplay html={topicsCovered} />
                </Fragment>
              )}

              {preRequisites.length > 0 && (
                <Fragment>
                  <h2>{t("prerequisites")}</h2>
                  <SafeHTMLDisplay html={preRequisites} />
                </Fragment>
              )}
              {FAQs.length > 0 && (
                <Fragment>
                  <h2>{t("frequentlyAskedQuestions")}</h2>
                  <SafeHTMLDisplay html={FAQs} />
                </Fragment>
              )}
            </Col>
            <Col md={4}>
              <Card
                label={{
                  color: ColorOptions.Green,
                  text: t("course"),
                  extraClass: "course-color",
                }}
                imageSource={convertLMSImageUrl(
                  course?.imageUrl || coursePlaceholder,
                )}
              >
                {loading || eventLoading || weblinkLoading ? (
                  <ListCell loading />
                ) : (
                  startableCourse && (
                    <AutoRegister
                      events={events}
                      startableCourse={startableCourse}
                      setIsPageLoading={setIsPageLoading}
                    />
                  )
                )}
              </Card>
              <div className="floating-pass-status">
                <TrainingPassContentStatusLabel
                  courseCardId={course?.id ?? ""}
                  learnerPasses={viewer?.trainingPasses ?? []}
                  contentPasses={contentPasses}
                  hasPricing={hasPricing}
                />
              </div>
            </Col>
          </Row>
          {!startableCourse && (
            <Row>
              <Col xs={12} className="mt-4">
                <DetailEventList
                  courseCode={courseCode}
                  loading={loading || eventLoading || weblinkLoading}
                  events={events}
                  totalEventRecords={totalEventRecords}
                  refetchEvents={refetchEvents}
                  contentPasses={contentPasses}
                />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </div>
  );
};
