import gql from "graphql-tag";

export const COUNTRY_QUERY = gql`
  query getCountries {
    countries {
      code
      name
      id
      provinces {
        code
        name
      }
    }
  }
`;
