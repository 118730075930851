import React from "react";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { StatusLabel } from "@administrate/piston-ux";
import { ColorOptions } from "@administrate/piston-ux/lib/types";

import { Event } from "../types/WeblinkEvent";
import { Event as generatedEvent } from "../generated/weblinkTypes";
import { DeliveryMethod } from "../generated/weblinkTypes";

interface LearningModeProps {
  event: Event | generatedEvent;
}

const getLabelText = (
  t: TFunction,
  deliveryMethod: DeliveryMethod | undefined,
) => {
  switch (deliveryMethod) {
    case DeliveryMethod.Classroom:
      return t("classroom");

    case DeliveryMethod.Lms:
      return t("elearning");

    default:
      return null;
  }
};

export const LearningMode = ({ event }: LearningModeProps) => {
  const { t } = useTranslation();

  const text = getLabelText(t, event.deliveryMethod);

  if (!text) {
    return null;
  }

  return <StatusLabel color={ColorOptions.LightGrey} text={text} />;
};
