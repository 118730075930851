import { Modal, V2Table } from "@administrate/piston-ux";
import { TABLE_LIMIT } from "@administrate/piston-ux/lib/layouts/ArticleTable/ArticleTable";
import { GridColumnWidth } from "@administrate/piston-ux/lib/types/table";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useDateFormatter } from "../../hooks/useDateFormatter";
import {
  TokenTransactionLog,
  useTrainingTokenIssueLog,
} from "../../hooks/useTrainingTokenIssues";

export const TokenIssueLog: FunctionComponent<{
  onDone: (value: boolean) => void;
  show: boolean;
  issueId?: string;
}> = props => {
  const { loading, tokenIssueLog, offset, setOffset, totalRecords } =
    useTrainingTokenIssueLog(props?.issueId);
  return (
    <TokenIssueLogModal
      {...props}
      loading={loading}
      tokenIssueLog={tokenIssueLog}
      offset={offset}
      setOffset={setOffset}
      totalRecords={totalRecords}
    />
  );
};

export const TokenIssueLogModal: FunctionComponent<{
  onDone: (value: boolean) => void;
  show: boolean;
  loading: boolean;
  tokenIssueLog: TokenTransactionLog[];
  offset: number;
  totalRecords?: number;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
}> = props => {
  const { t } = useTranslation();
  const { dateFormat } = useDateFormatter({ showTime: true });
  return (
    <Modal
      title={t("log")}
      show={props?.show}
      onDone={props?.onDone}
      primaryActionText={t("dismiss")}
      showCancelButton={false}
      loading={props?.loading}
      size="large"
    >
      <V2Table<TokenTransactionLog, {}>
        headings={[
          {
            title: t("date"),
            key: "occurredAt",
            formatter: (date: string | null) => date && dateFormat({ date }),
            width: GridColumnWidth.LG,
          },
          {
            title: t("type"),
            key: "type",
            width: GridColumnWidth.SM,
            formatter: (value: string) => (
              <span style={{ textTransform: "capitalize" }}>{value}</span>
            ),
          },
          {
            title: t("change"),
            key: "change",
            width: GridColumnWidth.XL,
          },
          { title: t("notes"), key: "notes", width: GridColumnWidth.AUTO },
        ]}
        data={props?.tokenIssueLog}
        dataTestId="token-issue-log"
        styling="branded"
        loading={props?.loading}
        pagination={{
          offset: props?.offset,
          setOffset: props?.setOffset,
          totalRecords: props?.totalRecords ?? 0,
          limit: TABLE_LIMIT,
        }}
      />
    </Modal>
  );
};
