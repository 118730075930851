export function classNames(
  ...args: (
    | string
    | {
        [key: string]: boolean;
      }
  )[]
) {
  const classes: string[] = [];
  args.forEach((arg: any) => {
    switch (typeof arg) {
      case "string":
        classes.push(arg);
        break;
      case "object":
        Object.keys(arg).forEach((key: string) => {
          if (arg[key]) classes.push(key);
        });
        break;
    }
  });
  return classes.join(" ");
}
