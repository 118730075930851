import {
  CourseObjective,
  DocumentObjective,
  UrlObjective,
  LearningPathObjective,
  ExternalObjective,
  Course,
  CourseOutcome,
  DocumentOutcome,
  UrlOutcome,
  LearningPathOutcome,
  ExternalOutcome,
  Registration,
  LearningPathRegistration,
  LearningPathResult,
} from "../generated/lmsTypes";
import { Maybe } from "@administrate/piston-ux/lib/types";

import { LearningObjectiveProps } from "@administrate/piston-ux/lib/LearningObjective";

export type LearningObjectiveUnion =
  | CourseObjective
  | DocumentObjective
  | UrlObjective
  | LearningPathObjective
  | ExternalObjective
  | null
  | undefined;

export type LearningOutcomeUnion =
  | CourseOutcome
  | DocumentOutcome
  | UrlOutcome
  | LearningPathOutcome
  | ExternalOutcome;

export type RegisterableUnion =
  | LearningPathRegistration
  | Registration
  | null
  | undefined;

export type OutcomeInfo = {
  type: string;
  id: Maybe<string>;
  course: Maybe<Course>;
  title: Maybe<string> | undefined;
  start: string;
  end: string;
};

export const getOutcomeInfo = (outcome: LearningOutcomeUnion) => {
  const info: OutcomeInfo = {
    type: extractOutcomeType(outcome),
    id: outcome.id ? outcome.id : null,
    title: getOutcomeTitle(outcome),
    start: getOutcomeStart(outcome),
    end: getOutcomeEnd(outcome),
    course: null,
  };

  if (outcome.__typename === "CourseOutcome") {
    info.course = outcome.course ? outcome.course : null;
  }
  return info;
};

const getOutcomeTitle = (outcome: LearningOutcomeUnion) => {
  if (outcome.__typename === "CourseOutcome") {
    return outcome.course ? outcome.course.title : "";
  }
  if (outcome.__typename === "LearningPathOutcome") {
    return outcome.learningPath ? outcome.learningPath.name : "";
  }
  if (outcome.__typename === "DocumentOutcome") {
    return outcome.document ? outcome.document.displayName : "";
  }
  if (outcome.__typename === "URLOutcome") {
    return outcome.url;
  }
  if (outcome.__typename === "ExternalOutcome") {
    return outcome.external;
  }
  return "";
};

const getOutcomeStart = (outcome: LearningOutcomeUnion) => {
  if (outcome.__typename === "CourseOutcome") {
    return outcome.course ? outcome.course.start : "";
  }
  if (outcome.__typename === "LearningPathOutcome") {
    return outcome.learningPath ? outcome.learningPath.start : "";
  }
  return "";
};

const getOutcomeEnd = (outcome: LearningOutcomeUnion) => {
  if (outcome.__typename === "CourseOutcome") {
    return outcome.course ? outcome.course.end : "";
  }
  if (outcome.__typename === "LearningPathOutcome") {
    return outcome.learningPath ? outcome.learningPath.end : "";
  }
  return "";
};

const extractOutcomeType = (outcome: LearningOutcomeUnion) =>
  outcome.__typename ? outcome.__typename.replace("Outcome", "") : "";

export const mapObjective = (
  learningObjectiveData: LearningObjectiveUnion,
): LearningObjectiveProps => {
  let objective: LearningObjectiveProps;
  switch (learningObjectiveData?.__typename) {
    case "CourseObjective":
      objective = {
        type: "Course",
        title: learningObjectiveData?.course?.title || "",
        description: learningObjectiveData?.course?.message || undefined,
      };
      break;
    case "DocumentObjective":
      objective = {
        type: "Document",
        title: learningObjectiveData?.document?.displayName || "",
      };
      break;
    case "URLObjective":
      objective = {
        type: "External",
        title: learningObjectiveData.url || "",
        onClick: () =>
          learningObjectiveData.url && window.open(learningObjectiveData.url),
      };
      break;
    case "ExternalObjective":
      objective = {
        type: "External",
        title: learningObjectiveData.name || "",
        description: learningObjectiveData.external || "",
      };
      break;
    case "LearningPathObjective":
      if (!learningObjectiveData.learningPath) {
        console.warn("mapObjective: double nested path without data.");
        objective = {
          type: "Double Nested Path",
          title: "Double Nested Path",
        };
      } else {
        objective = {
          type: "Learning Path",
          title: learningObjectiveData.learningPath.name,
          description:
            learningObjectiveData.learningPath.longDescription || undefined,
        };
      }
      break;
    default:
      objective = {
        title: "",
        type: undefined,
      };
  }
  return objective;
};

export type ObjectiveAvailability = {
  isAvailable: boolean;
  messageOnDisabled: string | null;
};

export const getObjectiveAvailability = (
  objectiveId: Maybe<string> | undefined,
  objectiveAvailabilityMap: Map<string, ObjectiveAvailability>,
  enforceObjectiveOrder: boolean,
  everythingIsDisabled: boolean,
): ObjectiveAvailability => {
  if (everythingIsDisabled) {
    return {
      isAvailable: false,
      messageOnDisabled: null,
    };
  }
  const availabilityFromMap = objectiveId
    ? objectiveAvailabilityMap.get(objectiveId)
    : undefined;
  return (
    availabilityFromMap || {
      isAvailable: !enforceObjectiveOrder,
      messageOnDisabled: null,
    }
  );
};

export const getObjectiveAvailabilityMap = (
  outcomes: (LearningPathResult | CourseOutcome)[],
  objectives: LearningObjectiveUnion[],
  enforceObjectiveOrder: Boolean,
): Map<string, ObjectiveAvailability> => {
  const availabilityMap = new Map();
  let setNextToUnavailable = false;
  let prevResultCompleted = true;

  objectives.forEach(objective => {
    if (!objective || !objective.id) {
      return;
    }

    const result = findResultForObjective(outcomes, objective.id);
    let isAvailable = true;
    let messageOnDisabled = null;

    if (enforceObjectiveOrder) {
      if (!prevResultCompleted) {
        // the previous objective is not complete, so the rest of the objectives are not available
        isAvailable = false;
      } else if (setNextToUnavailable) {
        // this means we have already found an incomplete objective, and all further objectives are unavailable
        isAvailable = false;
      } else if (result === undefined) {
        // some objectives do not have a result. in this case we set the rest of the objectives to unavailable
        setNextToUnavailable = true;
      } else {
        // save the completeness status for the next objective to check
        prevResultCompleted = !!result?.completed;
      }

      if (!isAvailable) {
        // when enforcing objective order any objective that is not available makes all subsequent not available
        setNextToUnavailable = true;
      }
    }

    const availability: ObjectiveAvailability = {
      isAvailable: isAvailable,
      messageOnDisabled: messageOnDisabled,
    };
    availabilityMap.set(objective.id, availability);
  });

  return availabilityMap;
};

function findResultForObjective(
  outcomes: (LearningPathResult | CourseOutcome)[],
  objectiveId: string,
) {
  return outcomes.find(outcome => outcome.objectiveId === objectiveId);
}
