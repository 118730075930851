import { CustomFieldInput, Fieldset } from "@administrate/piston-ux";
import { useFormContext } from "@administrate/piston-ux/lib/Form";
import { pick as _pick } from "lodash";
import { isObservableArray } from "mobx";
import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  PointOfSaleFieldDefinition,
  PointOfSaleFieldValue,
} from "../generated/weblinkTypes";
import { Maybe } from "../types/Maybe";

const CUSTOM_FIELD_CHOICE_JOINER = "|";
const CUSTOM_FIELD_MULTI_CHOICES = ["multichoice"];
const CUSTOM_FIELD_BOOLEAN_CHOICES = ["boolean", "checkbox"];

const isValid = (isRequired: Maybe<Boolean>) => (value: string | string[]) => {
  if (!isRequired) {
    return true;
  }

  return Array.isArray(value) || isObservableArray(value)
    ? !!value.length
    : !!value;
};

const parseValueFromPoSQuery = (
  pointOfSaleField: PointOfSaleFieldDefinition,
  value?: Maybe<string>,
) => {
  if (!value || !pointOfSaleField) {
    return value;
  }
  const { type } = pointOfSaleField;
  if (CUSTOM_FIELD_MULTI_CHOICES.includes(type)) {
    return value.split(CUSTOM_FIELD_CHOICE_JOINER);
  }
  if (CUSTOM_FIELD_BOOLEAN_CHOICES.includes(type)) {
    return typeof value === "string" ? value.toLowerCase() === "true" : value;
  }
  return value;
};

const serialisePosValueForMutation = (
  value?: Maybe<string | boolean | Array<string>>,
) => {
  if (Array.isArray(value)) {
    return value.join(CUSTOM_FIELD_CHOICE_JOINER);
  }
  if (typeof value === "boolean") {
    return value.toString();
  }
  return value ?? null;
};

export const serialisePoSValuesForMutation = (
  objectifiedFormValue: any,
  pointOfSaleFields: PointOfSaleFieldDefinition[],
) => {
  const pointOfSaleFieldKeys = pointOfSaleFields.map(({ key }) => key);
  const pointOfSaleFormValues = _pick(
    objectifiedFormValue,
    pointOfSaleFieldKeys,
  );
  return Object.entries(pointOfSaleFormValues).map(
    ([pointOfSaleFieldKey, value]) => ({
      pointOfSaleFieldKey,
      value: serialisePosValueForMutation(value),
    }),
  );
};

export const PointOfSaleCustomFieldInput: FunctionComponent<{
  pointOfSaleFields: PointOfSaleFieldDefinition[];
  pointOfSaleValues: PointOfSaleFieldValue[];
}> = ({ pointOfSaleFields, pointOfSaleValues }) => {
  const { t } = useTranslation();
  const { values } = useFormContext();

  useEffect(() => {
    if (pointOfSaleFields && pointOfSaleValues && pointOfSaleValues.length) {
      const pointOfSalesByKey: any = pointOfSaleFields.reduce(
        (obj, item) => ({ ...obj, [item.key]: item }),
        {},
      );
      pointOfSaleValues.forEach(({ pointOfSaleFieldKey, value }) => {
        values.set(
          pointOfSaleFieldKey,
          parseValueFromPoSQuery(pointOfSalesByKey[pointOfSaleFieldKey], value),
        );
      });
    }
  }, [pointOfSaleValues, pointOfSaleFields, values]);

  return (
    <>
      {pointOfSaleFields.length > 0 && (
        <Fieldset legend={t("additionalInformation")}>
          {pointOfSaleFields.map(
            ({ key, label, options, isRequired, type }) => (
              <CustomFieldInput
                key={key}
                uniqueId={key}
                name={key}
                label={label}
                valid={isValid(isRequired)}
                type={type}
                options={options?.map(({ label, value }) => ({ label, value }))}
              />
            ),
          )}
        </Fieldset>
      )}
    </>
  );
};
