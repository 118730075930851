import React, { useState, useEffect, useRef, useCallback } from "react";

import { CATEGORY_QUERY } from "../../queries/category";
import { Category, CategoryChange } from "./types";
import { useDebounce } from "../../hooks/useDebounce";
import { useLmsQuery } from "../../hooks/lms";
import { useTranslation } from "react-i18next";

type CategoryPickerProps = {
  onChange: (c: CategoryChange | null) => void;
};

export const CategoryPicker: React.FunctionComponent<CategoryPickerProps> = ({
  onChange,
}) => {
  const { t } = useTranslation();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [selected, setSelected] = useState<CategoryChange | null>(null);
  const [hoverId, setHoverId] = useState<string | null>(null);

  const { data, loading } = useLmsQuery(CATEGORY_QUERY);
  const elementRef = useRef<HTMLDivElement>(null);
  const mouseDownHandler = useCallback((ev: Event) => {
    if (elementRef.current) {
      if (!elementRef.current.contains(ev.target as Node)) {
        setIsMenuVisible(false);
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", mouseDownHandler);
    return () => {
      document.removeEventListener("mousedown", mouseDownHandler);
    };
  }, [mouseDownHandler]);

  useEffect(() => {
    onChange(selected);
  }, [selected, onChange]);

  const debouncedHoverId = useDebounce(hoverId, 100);

  return (
    <div ref={elementRef} className="actionsBar-quickFilter">
      <div
        className={`actionsBar-button ${
          selected ? "small-label" : "large-label"
        }`}
        onClick={() => setIsMenuVisible(true)}
        onKeyPress={() => setIsMenuVisible(true)}
        role="button"
        tabIndex={0}
      >
        <div className="d-inline-block">
          <span className="actionsBar-label">{t("categories")}</span>
          {selected && (
            <span className="actionsBar-value">{selected.name}</span>
          )}
        </div>
        <div className="actionsBar-quickFilter-carat">
          <span className="caret"></span>
        </div>
      </div>
      {!loading && isMenuVisible && (
        <ul className="actionsBar-menu options">
          <li>
            <span
              onKeyPress={() => setSelected(null)}
              onClick={() => setSelected(null)}
              tabIndex={0}
              role="button"
            >
              {t("all")}
            </span>
          </li>
          {data.categories.edges.map(
            ({ node: category }: { node: Category }) => {
              const subCategories = category.subcategories?.edges;
              const hasSubCategories =
                subCategories !== undefined && subCategories.length > 0;

              return (
                <li
                  key={category.id}
                  onMouseEnter={() => {
                    setHoverId(category.id);
                  }}
                  onMouseLeave={() => {
                    setHoverId(null);
                  }}
                >
                  {(category.id === hoverId ||
                    category.id === debouncedHoverId) &&
                    hasSubCategories && (
                      <ul
                        className={`actionsBar-menu options submenu ${
                          category.id !== hoverId ? "submenu-closing" : ""
                        }`}
                      >
                        {subCategories.map(edge => (
                          <li key={edge.node.id}>
                            <span
                              onClick={() => {
                                setSelected({
                                  name: edge.node.name,
                                  id: edge.node.id,
                                });
                                setIsMenuVisible(false);
                              }}
                            >
                              {edge.node.name}
                            </span>
                          </li>
                        ))}
                      </ul>
                    )}
                  <span
                    onClick={() => {
                      setSelected({
                        name: category.name,
                        id: category.id,
                      });
                      setIsMenuVisible(false);
                    }}
                    tabIndex={0}
                    role="button"
                  >
                    {category.name}
                  </span>
                </li>
              );
            },
          )}
        </ul>
      )}
    </div>
  );
};
