import {
  Col,
  FormStateStatus,
  Row,
  useDetailFormState,
  ValidationErrors,
} from "@administrate/piston-ux";
import React, { Fragment, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import {
  Cart,
  CartTax,
  RemovePromotionCodeInput,
} from "../generated/weblinkTypes";
import { useWebLinkMutation } from "../hooks/weblink";
import { RefetchStatus } from "../providers/CheckoutProvider";
import { useViewer } from "../providers/ViewerProvider";
import { REMOVE_PROMO_CODE_MUTATION } from "../queries/cart";
import { getFinancialFormat } from "../utils/displayHelpers";
import { ActivePromoCode } from "./PromoCode";

export enum CartSummaryUsage {
  Cart = "cart",
  Checkout = "checkout",
}

export const CartSummary: FunctionComponent<{
  cart: Cart;
  usage: CartSummaryUsage;
  refetchCart: (refetch: RefetchStatus) => void;
  setIsRemovingPromoCode: (value: boolean) => void;
}> = ({ cart, refetchCart, setIsRemovingPromoCode }) => {
  const { viewer } = useViewer();
  const {
    messages,
    saveState,
    setSaveState,
    setMessages,
    reset: resetFormState,
  } = useDetailFormState();

  const [removePromoCode, { loading: mutationLoading, error: mutationError }] =
    useWebLinkMutation(REMOVE_PROMO_CODE_MUTATION);

  const handleRemovePromoCode = async () => {
    setIsRemovingPromoCode(true);
    setSaveState("saving");
    const input: RemovePromotionCodeInput = {
      cartId: cart?.id || "",
      promotionCode: cart?.promotionalCode || "",
    };
    const response = await removePromoCode({
      variables: {
        input,
      },
    });
    setIsRemovingPromoCode(false);
    const validationErrors = response.data.cart.removePromotionCode.errors;

    if (!mutationLoading) {
      if (mutationError) {
        refetchCart(RefetchStatus.Cancel);
        setMessages({ failed: mutationError.message });
      } else if (validationErrors && validationErrors.length > 0) {
        refetchCart(RefetchStatus.Cancel);
        setMessages({
          errors: (
            <ValidationErrors
              title={t("errorRemovingPromoCode")}
              mutationValidationErrors={validationErrors}
            />
          ),
        });
      } else {
        resetFormState();
        refetchCart(RefetchStatus.StartRefetch);
      }
    }
  };

  const { t } = useTranslation();

  return (
    <Fragment>
      {saveState !== "saving" && (
        <FormStateStatus messages={messages} saveState={saveState} />
      )}
      <Row className="mb-2">
        <Col xs={6} className="font-weight-bold">
          {t("subtotal")}
        </Col>
        <Col xs={6} className="text-right">
          {getFinancialFormat(
            cart.price?.subTotal,
            cart?.currency,
            viewer?.locale,
          )}
        </Col>
      </Row>
      {cart.promotionalCode && (
        <ActivePromoCode
          cart={cart}
          onRemovePromoCode={handleRemovePromoCode}
        />
      )}
      {(cart.price?.taxes as CartTax[]).map(({ totalAmount, type }) => (
        <Row className="my-2" key={`tax-summary-${type.id}`}>
          <Col xs={6} className="font-weight-bold">
            {type.name}
          </Col>
          <Col xs={6} className="text-right ">
            {getFinancialFormat(totalAmount, cart?.currency, viewer?.locale)}
          </Col>
        </Row>
      ))}
      <Row className="mt-4 mb-4">
        <Col xs={6} className="font-weight-bold">
          {t("total")}
        </Col>
        <Col xs={6} className="text-right font-weight-bold">
          {getFinancialFormat(
            cart.price?.grandTotal,
            cart?.currency,
            viewer?.locale,
          )}
        </Col>
      </Row>
    </Fragment>
  );
};
