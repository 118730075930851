import gql from "graphql-tag";

export const ERROR_FRAGMENT = gql`
  fragment Error on FieldError {
    label
    value
    message
  }
`;

export const TRAINING_REQUEST_FRAGMENT = gql`
  fragment TrainingRequest on TrainingRequest {
    id
    updatedAt
    createdAt
    stepName
    stageName
    state
    registerableId
    training {
      id
      title
      start
      end
      imageUrl
      location
      type
    }
  }
`;

export const PAGE_INFO_FRAGMENT = gql`
  fragment PageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
    totalRecords
  }
`;

export const REGISTERABLE_FRAGMENT = gql`
  fragment Registerable on Registerable {
    id
    title
    lastAccessed
    isActive
    ... on Registration {
      expiry
      totalPiecesOfContent
      completedPiecesOfContent
      hasPassed
      attendance {
        sessionsAttended
        sessionsMissed
        totalSessions
        __typename
      }
      course {
        id
        title
        learningMode
        location {
          id
          name
        }
        start
        end
        lmsStart
        lmsEnd
        imageUrl
        categories {
          edges {
            node {
              id
              name
              __typename
            }
            __typename
          }
          __typename
        }
        allowedMissedSessions
        __typename
      }
      __typename
    }
    ... on LearningPathRegistration {
      progressMade
      expectedProgress
      trainingPassTypeId
      startDate
      lifecycleState
      learningPath {
        id
        name
        longDescription
        imageUrl
        start
        end
        __typename
      }
      __typename
    }
    __typename
  }
`;

export const EVENT_FRAGMENT = gql`
  fragment Event on Event {
    id
    name
    location {
      id
      name
    }
    start
    end
    timeZoneName
    remainingPlaces
    accessDuration
    classroomStart
    classroomEnd
    lmsStart
    lmsEnd
    deliveryMethod
    imageHash
  }
`;

export const WEBLINK_EVENT_FRAGMENT = gql`
  fragment WeblinkEvent on Event {
    id
    name
    location {
      id
      name
      region {
        id
        name
      }
    }
    venue {
      id
      name
    }
    start
    end
    timeZoneName
    remainingPlaces
    accessDuration
    classroomStart
    classroomEnd
    lmsStart
    lmsEnd
    deliveryMethod
    prices {
      amount
      financialUnit {
        name
        code
        symbol
      }
      region {
        id
        name
      }
    }
    course {
      id
      imageUrl
    }
    locale {
      id
      language
    }
    registrationOpen
    registrationOpensAt
  }
`;

const learnerSharedFields = `
  id
  isCancelled
  contact {
    id
    firstName
    lastName
    emailAddress
    jobRole
    department
    personalName {
      firstName
      middleName
      lastName
    }
    postalAddress {
      town
      postcode
      province {
        code
      }
      country {
        code
      }
    }
    account {
      id
      name
    }
    customFieldValues {
      definitionKey
      value
    }
  }
  registration {
    id
    registrationNumber
  }
  customFieldValues {
    definitionKey
    value
  }
  learningPath {
    id
    name
  }
`;

export const WEBLINK_EVENT_LEARNER_FRAGMENT = gql`
  fragment EventLearner on EventLearner {
    ${learnerSharedFields}
    isUnnamed
    quantity
  }
`;

export const WEBLINK_PATH_LEARNER_FRAGMENT = gql`
  fragment PathLearner on PathLearner {
    ${learnerSharedFields}
    learningOutcomes {
      edges {
        node {
          id
          ... on CourseOutcome {
            event {
              id
              name
              start
              end
              location {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const CANCELLATION_REQUEST_FRAGMENT = gql`
  fragment CancellationRequest on CancellationRequest {
    id
    reason
    reasonNotes
    approvalState
    createdAt
    updatedAt
    learner
    registerableId
  }
`;

export const VIEWER_FRAGMENT = gql`
  fragment Viewer on Viewer {
    id
    firstName
    lastName
    emailAddress
    jobRole
    locale
    timeZoneName
    learnerWelcomed
    username
    company
    isCoordinator
    customFieldValues {
      definitionKey: key
      value
    }
    externalIdNames
    trainingPasses {
      id
      trainingPassTypeId
      trainingPassTypeName
      lifecycleState
      validFrom
      validUntil
    }
  }
`;

export const POINT_OF_SALE_LEARNER_FIELD_DEFINITION_FRAGMENT = gql`
  fragment PointOfSaleLearnerFieldDefinition on PointOfSaleLearnerFieldDefinition {
    __typename
    label
    key
    type
    isRequired
    section
    options {
      label
      value
    }
    mappedField {
      entityType
      field
    }
  }
`;

export const REGISTRABLE_COURSE_CONTENT_FRAGMENT = gql`
  fragment RegistrableCourseContent on Content {
    id
    isRequired
    order
    type
    autoComplete
    displayName
    htmlDescription
    isPremium
    includedInPassTypeIds
    ... on ExternalActivity {
      externalActivityUrl
    }
    ... on KryterionActivity {
      kryterionUrl
      kryterionCourseId
    }
    ... on Document {
      identifyingHash
      fileSizeStr
      originalExtension
    }
    ... on Video {
      wistiaVideoId
      fileSizeStr
    }
    ... on Scorm {
      identifyingHash
      entrypoint
      version
      fileSizeStr
      maxAttempts
    }
    ... on DiscussionTopic {
      comments {
        edges {
          node {
            id
            comment
            createdAt
            contact {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export const CONTENT_FRAGMENT = gql`
  fragment ContentFragment on ContentResult {
    id
    contentId
    progress
    score
    scorePercent
    attempts
    completedAttempts
    lastAccessed
    status
    ... on VideoContentResult {
      startAt
      lastAccessed
      progress
    }
    ... on ExternalContentResult {
      latestAttempt {
        id
      }
    }
  }
`;

export const REGISTRATION_FRAGMENT = (withProgress: boolean) => gql`
  fragment Registration on ${
    withProgress ? "RegistrationWithProgress" : "Registration"
  } {
    id
    expiry
    totalPiecesOfContent
    completedPiecesOfContent
    hasPassed
    quantity
    contentResults${withProgress ? "" : "(offset: $offset)"} {
      edges {
        node {
          ...ContentFragment
        }
      }
    }
    achievement {
      id
      identifier
      expiresAt
      achievementType {
        id
        name
      }
      certificateUrl
      documentUrls
    }
    passAccessExpired
    trainingPassTypeId
    achievements {
      id
      identifier
      expiresAt
      achievementType {
        id
        name
      }
      certificateUrl
      documentUrls
      documentIds
    }
    attendance {
      sessionsAttended
      sessionsMissed
      totalSessions
      sessionDetail {
        edges {
          node {
            session {
              id
              title
              timeZonedStart
              timeZonedEnd
              location {
                id
                name
              }
            }
            attendanceMark
          }
        }
      }
    }
    course {
      id
      title
      message
      lmsAnnouncements
      resources {
        label
        link
      }
      learningMode
      imageUrl
      achievementType {
        id
        name
      }
      achievementTypes {
        id
        name
      }
      categories {
        edges {
          node {
            id
            name
          }
        }
      }
      sessions {
        edges {
          node {
            id
            title
            timeZonedStart
            timeZonedEnd
            remoteMeetingUrl
            location {
              name
              id
            }
          }
        }
      }
      lmsStart
      lmsEnd
      content(offset: $offset, learnerId: $id) {
        pageInfo {
          ...PageInfo
        }
        edges {
          node {
            ...RegistrableCourseContent
          }
        }
      }
      documents {
        id
        name
        description
        fileSizeStr
      }
      allowedMissedSessions
    }
  }

  ${CONTENT_FRAGMENT}
  ${REGISTRABLE_COURSE_CONTENT_FRAGMENT}
`;

export const CART_FRAGMENT_QUERY = gql`
  fragment Cart on Cart {
    id
    invoiceId
    state
    promotionalCode
    buyerDetails {
      firstName
      lastName
      email
      billingAddress {
        streetAddress1
        streetAddress2
        streetAddress3
        town
        province {
          name
          code
        }
        postcode
        country {
          id
          name
          code
        }
      }
    }
    items {
      id
      learnerDetails {
        firstName
        lastName
        email
      }
      productOption {
        id
        name
        price {
          amount
        }
        ... on Event {
          id
          name
          start
          end
          location {
            id
            name
          }
          course {
            id
            imageUrl
          }
        }
        ... on LearningPath {
          id
          name
          imageUrl
        }
      }
      totalAmount
      unitAmount
      quantity
      pricingAgreementPrice {
        id
        pricingAgreement {
          id
          name
        }
      }
    }
    price {
      discountTotal
      subTotal
      grandTotal
      taxes {
        totalAmount
        type {
          id
          name
          effectiveRate
        }
      }
    }
    currency {
      name
      symbol
      code
    }
    paymentCodeApplications {
      paymentCode
      tokenIssueBalance
    }
    tokenType {
      id
      name
    }
  }
`;

export const LEARNING_TAG_CONNECTION = gql`
  fragment LearningTagDetails on LearningTagConnection {
    edges {
      node {
        id
        name
      }
    }
  }
`;

export const ACCOUNT_ASSOCIATION_CONNECTION = gql`
  fragment AccountAssociationDetails on AccountAssociationConnection {
    edges {
      node {
        id
        account {
          id
          name
        }
        associationType {
          id
          name
        }
      }
    }
  }
`;

export const CART_ANALYTICS_DETAILS = gql`
  fragment CartAnalyticsDetails on Cart {
    currency {
      code
    }
    price {
      subTotal
      discountTotal
      grandTotal
      taxes {
        totalAmount
      }
    }
    promotionalCode
  }
`;

export const CART_ITEM_ANALYTICS_DETAILS = gql`
  fragment CartItemAnalyticsDetails on CartLineItem {
    quantity
    unitAmount
    totalTaxAmount
    priceLevel {
      id
      name
    }
    productOption {
      __typename
      code
      categories {
        id
        name
      }
      ... on Event {
        start
        location {
          id
          name
        }
        course {
          isFeatured
          learningTags {
            ...LearningTagDetails
          }
          accountAssociations {
            ...AccountAssociationDetails
          }
        }
      }
      ... on LearningPath {
        isFeatured
        learningTags {
          ...LearningTagDetails
        }
        accountAssociations {
          ...AccountAssociationDetails
        }
      }
    }
  }
  ${ACCOUNT_ASSOCIATION_CONNECTION}
  ${LEARNING_TAG_CONNECTION}
`;
