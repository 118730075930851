import {
  List,
  ListCell,
  ListEmpty,
  ListHeader,
  ListRow,
  Modal,
  Pagination,
} from "@administrate/piston-ux";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Query } from "../../generated/weblinkTypes";
import { useWebLinkQuery } from "../../hooks/weblink";
import { WEBLINK_EVENT_SESSIONS_QUERY } from "../../queries/events";
import { WEBLINK_LEARNING_PATH_OBJECTIVES_QUERY } from "../../queries/learningPaths";
import { getLearningObjectiveOptions } from "../../utils/weblinkLearningPathHelpers";
import { DateRange } from "../EventDateRange";

export const BookableDetails: FunctionComponent<{
  onDone: () => void;
  bookableId: string;
  type: "Event" | "LearningPath";
}> = ({ onDone, bookableId, type }) => {
  const { t } = useTranslation();

  const details =
    type === "Event" ? (
      <EventSessions eventId={bookableId} />
    ) : (
      <LearningPathObjectives learningPathId={bookableId} />
    );

  return (
    // TODO: Update PistonUX so we can show a modal with no Cancel button
    <Modal
      onDone={onDone}
      title={t("Details")}
      show={true}
      size="large"
      primaryActionText={t("OK")}
    >
      {details}
    </Modal>
  );
};

// TODO: Lots of shared code with EventSessions below, refactor out the common parts
const LearningPathObjectives: FunctionComponent<{
  learningPathId: string;
}> = ({ learningPathId }) => {
  const gridColumns = "1fr 1fr 1fr";
  const OBJECTIVE_PAGINATION_LIMIT = 5;

  const { t } = useTranslation();

  const [offset, setOffset] = useState(0);

  const { loading, data } = useWebLinkQuery<Query>(
    WEBLINK_LEARNING_PATH_OBJECTIVES_QUERY,
    {
      variables: {
        learningPathId,
        offset: offset,
        first: OBJECTIVE_PAGINATION_LIMIT,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const learningPath = data?.learningPaths?.edges?.[0]?.node;
  const objectives = getLearningObjectiveOptions(
    learningPath?.learningObjectives?.edges ?? [],
  );
  const totalRecords = learningPath?.learningObjectives?.pageInfo?.totalRecords;
  const hasNextPage = learningPath?.learningObjectives?.pageInfo?.hasNextPage;
  const hasPreviousPage =
    learningPath?.learningObjectives?.pageInfo?.hasPreviousPage;

  return (
    <>
      <List>
        <ListHeader
          gridColumns={gridColumns}
          headings={[
            {
              title: t("name"),
            },

            {
              title: t("dates"),
            },
            {
              title: t("location"),
            },
          ]}
        />

        {loading && <SessionLoading />}
        {!loading && objectives.length < 1 && (
          <ListEmpty text={t("thereAreNoObjectivesForThisLearningPath")} />
        )}
        {!loading &&
          objectives.length >= 1 &&
          objectives.map(({ id, objective }) => {
            if (!objective.event) {
              return null;
            }

            return (
              <ListRow
                key={id}
                gridColumns={gridColumns}
                dataLabel={objective.name}
              >
                <ListCell label={t("name")}>{objective.name}</ListCell>
                <ListCell label={t("dates")}>
                  <DateRange
                    start={objective.event.start}
                    end={objective.event.end}
                  />
                </ListCell>
                <ListCell label={t("location")}>
                  {objective.event.location ?? null}
                </ListCell>
              </ListRow>
            );
          })}
      </List>
      {objectives.length >= 1 &&
        totalRecords &&
        (hasNextPage || hasPreviousPage) && (
          <Pagination
            totalRecords={totalRecords}
            limit={OBJECTIVE_PAGINATION_LIMIT}
            offset={offset}
            setOffset={setOffset}
          />
        )}
    </>
  );
};

const EventSessions: FunctionComponent<{
  eventId: string;
}> = ({ eventId }) => {
  const gridColumns = "1fr 1fr 1fr";
  const SESSION_PAGINATION_LIMIT = 5;

  const { t } = useTranslation();

  const [offset, setOffset] = useState(0);

  const { loading, data } = useWebLinkQuery<Query>(
    WEBLINK_EVENT_SESSIONS_QUERY,
    {
      variables: {
        eventId,
        sessionsOffset: offset,
        sessionsFirst: SESSION_PAGINATION_LIMIT,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const event = data?.events?.edges?.[0]?.node;
  const timeZone = event?.timeZoneName;
  const sessions = event?.sessions?.edges ?? [];
  const totalRecords = event?.sessions?.pageInfo?.totalRecords;
  const hasNextPage = event?.sessions?.pageInfo?.hasNextPage;
  const hasPreviousPage = event?.sessions?.pageInfo?.hasPreviousPage;

  return (
    <>
      <List>
        <ListHeader
          gridColumns={gridColumns}
          headings={[
            {
              title: t("name"),
            },

            {
              title: t("dates"),
            },
            {
              title: t("location"),
            },
          ]}
        />

        {loading && <SessionLoading />}
        {!loading && sessions.length < 1 && (
          <ListEmpty text={t("thereAreNoSessionsForThisEvent")} />
        )}
        {!loading &&
          sessions.length >= 1 &&
          sessions.map(({ node: session }) => {
            return (
              <ListRow
                key={session.id}
                gridColumns={gridColumns}
                dataLabel={session.name}
              >
                <ListCell label={t("name")}>{session.name}</ListCell>
                <ListCell label={t("dates")}>
                  <DateRange
                    start={session.start}
                    end={session.end}
                    timezone={timeZone}
                  />
                </ListCell>
                <ListCell label={t("location")}>
                  {session.location?.name ?? null}
                </ListCell>
              </ListRow>
            );
          })}
      </List>
      {sessions.length >= 1 &&
        totalRecords &&
        (hasNextPage || hasPreviousPage) && (
          <Pagination
            totalRecords={totalRecords}
            limit={SESSION_PAGINATION_LIMIT}
            offset={offset}
            setOffset={setOffset}
          />
        )}
    </>
  );
};

const SessionLoading: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      {[...Array(3)].map((_, i) => (
        <ListRow key={i} gridColumns="1fr 1fr 1fr">
          <ListCell loading label={t("name")} />
          <ListCell loading label={t("dateAndTime")} />
          <ListCell loading label={t("location")} />
        </ListRow>
      ))}
    </>
  );
};
