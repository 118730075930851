import React from "react";
import { Card, Col, GridRow } from "@administrate/piston-ux";
import { range } from "lodash";
import catalogPlaceholderSquare from "../images/course-placeholder_square.png";
import { useGlobalStore } from "../providers/GlobalStore";
import { ColorOptions } from "@administrate/piston-ux/lib/types";

export const CardLoadingPlaceholder = () => {
  const { convertLMSImageUrl } = useGlobalStore();
  return (
    <div className="loading">
      <GridRow>
        {range(4).map(i => (
          <Col sm={6} md={4} lg={3} key={`loading-${i}`}>
            <Card
              imageSource={convertLMSImageUrl(catalogPlaceholderSquare)}
              label={{ color: ColorOptions.LightGrey, text: "" }}
            >
              <span className="font-weight-bold placeholder animated placeholder-text-value" />
              <span className="placeholder animated placeholder-h4" />
            </Card>
          </Col>
        ))}
      </GridRow>
    </div>
  );
};
