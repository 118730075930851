import React, { useCallback, useEffect, useMemo } from "react";
import {
  Col,
  DateTimeInput,
  Modal,
  Row,
  StaticInput,
  Typeahead,
  useDetailFormState,
  useTypedFormValues,
  ValidationErrors,
} from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { WEBLINK_EVENTS_QUERY } from "../../queries/events";
import {
  useWebLinkClient,
  useWebLinkMutation,
  useWebLinkQuery,
} from "../../hooks/weblink";
import {
  Event,
  EventBooking,
  EventField,
  EventLearner,
  FilterOperation,
  Location,
  LocationField,
  Mutation,
  Query,
} from "../../generated/weblinkTypes";
import { TRANSFER_LEARNER_MUTATION } from "../../queries/learnerManagement";
import { extractNodes } from "../../utils/extractNodes";
import { WEBLINK_BASIC_LOCATIONS_QUERY } from "../../queries/filters";
import { observer } from "mobx-react-lite";
import { getEventStartDate, isNotInPast } from "../../utils/dateTimeHelpers";
import moment from "moment";
import { PORTAL_MESSAGES_QUERY } from "../../queries/portal";
import { getLearnerName } from "../../utils/displayHelpers";
import gql from "graphql-tag";
import { toJS } from "mobx";

const WEBLINK_TRANSFER_BOOKING_MODAL_QUERY = gql`
  query getBooking($bookingId: String!, $learnerId: String!) {
    viewer {
      eventBookings(
        filters: [{ field: id, operation: eq, value: $bookingId }]
      ) {
        edges {
          node {
            id
            course {
              id
              code
            }
            location {
              id
              name
            }
            learners(
              first: 1
              filters: [{ field: id, operation: eq, value: $learnerId }]
            ) {
              edges {
                node {
                  id
                  contact {
                    id
                    personalName {
                      firstName
                      middleName
                      lastName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

type TransferLearnerModalProps = {
  learnerId: string;
  bookingId: string;
  onModalClose: (submitted: boolean) => void;
};

export const TransferLearnerModal = ({
  bookingId,
  learnerId,
  onModalClose,
}: TransferLearnerModalProps) => {
  const { data, loading } = useWebLinkQuery<Query>(
    WEBLINK_TRANSFER_BOOKING_MODAL_QUERY,
    {
      variables: {
        bookingId,
        learnerId,
      },
    },
  );

  const booking = data?.viewer?.eventBookings.edges[0].node ?? null;

  const learnerName = booking
    ? getLearnerName(booking?.learners.edges[0].node as EventLearner)
    : "";

  return (
    <TransferLearner
      loading={loading}
      learnerId={learnerId}
      learnerName={learnerName}
      booking={booking!}
      onModalClose={onModalClose}
    />
  );
};

type TransferLearnerProps = {
  loading: boolean;
  learnerId: string;
  learnerName: string;
  booking: EventBooking | null;
  onModalClose: (submitted: boolean) => void;
};

type TransferLearnerFormValues = {
  learnerName: string;
  location: Location | null | undefined;
  dateFrom: string | null;
  dateTo: string | null;
  event: Event | null;
};

const TransferLearner = observer(
  ({
    learnerId,
    learnerName,
    loading,
    booking,
    onModalClose,
  }: TransferLearnerProps) => {
    const { t } = useTranslation();
    const [transferLearner] = useWebLinkMutation<Mutation>(
      TRANSFER_LEARNER_MUTATION,
    );

    const { data: messagesResponse, loading: messagesLoading } =
      useWebLinkQuery<Query>(PORTAL_MESSAGES_QUERY);

    const values = useTypedFormValues<TransferLearnerFormValues>({
      learnerName,
      location: undefined,
      dateFrom: null,
      dateTo: null,
      event: null,
    });

    const {
      messages,
      saveState,
      setSaveState,
      setMessages,
      reset: resetFormState,
    } = useDetailFormState();

    useEffect(() => {
      if (!booking) return;

      values.location = booking.location ?? null;
      values.learnerName = learnerName;
    }, [booking, learnerName, values]);

    const onDone = async (submitted: boolean) => {
      if (submitted && values.event) {
        const { data } = await transferLearner({
          variables: {
            input: {
              learnerId,
              eventId: values.event.id,
            },
          },
        });

        const errors = data?.booking?.transferLearner.errors;

        if (errors && errors.length) {
          setMessages({
            errors: (
              <ValidationErrors
                title={t("errorTransferringLearner")}
                mutationValidationErrors={errors.map(e => ({
                  message: e.message ?? t("unknown"),
                }))}
              />
            ),
          });
          setSaveState("saved");
        } else {
          resetFormState();
          onModalClose(submitted);
        }
      } else {
        onModalClose(submitted);
      }
    };

    const transferMessage = messagesResponse?.store.messages?.transfer;

    return (
      <Modal
        title={t("transferLearner")}
        show={true}
        onDone={onDone}
        values={values}
        disabled={messagesLoading || loading}
        messages={messages}
        saveState={saveState}
      >
        {loading || !booking ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 200,
            }}
          >
            <span className="glyphicon glyphicon-refresh glyphicon-spinner" />
          </div>
        ) : (
          <>
            <p>{t("transferLearnerWarning")}</p>
            {transferMessage ? <p>{transferMessage}</p> : null}
            <hr />
            <StaticInput name="learnerName" label={t("learnerName")} />
            <LocationsFilter />
            <DateFilter values={values} />
            <NewEventSelect values={values} booking={booking} />
          </>
        )}
      </Modal>
    );
  },
);

const LocationsFilter = observer(() => {
  const { t } = useTranslation();
  const client = useWebLinkClient();

  const locationOptions = useCallback(
    async (inputValue: string) => {
      const { data } = await client.query({
        query: WEBLINK_BASIC_LOCATIONS_QUERY,
        variables: {
          filters: [
            {
              field: LocationField.Name,
              operation: FilterOperation.Like,
              value: `%${inputValue}%`,
            },
          ],
        },
      });
      return extractNodes(data.locations.edges);
    },
    [client],
  );

  return (
    <Typeahead
      loadOptions={locationOptions}
      label={t("location")}
      name="location"
      formatter={(location: Location) => location.name}
      placeholder={`${t("selectLocation")}...`}
    />
  );
});

const DateFilter = observer(
  ({ values }: { values: TransferLearnerFormValues }) => {
    const { t } = useTranslation();

    const currentDate = useMemo(() => new Date(), []); // Stops constant re-rendering
    const dateFromFilterValue = values.dateFrom || currentDate;

    return (
      <Row>
        <Col md={6}>
          <DateTimeInput
            label={t("from")}
            type="date"
            name="dateFrom"
            placeholder={t("today")}
            isValidDate={isNotInPast}
          />
        </Col>
        <Col md={6}>
          <DateTimeInput
            label={t("to")}
            type="date"
            name="dateTo"
            placeholder={t("future")}
            isValidDate={date =>
              moment(date).isSameOrAfter(dateFromFilterValue, "day")
            }
          />
        </Col>
      </Row>
    );
  },
);

const NewEventSelect = observer(
  ({
    values,
    booking,
  }: {
    values: TransferLearnerFormValues;
    booking: EventBooking;
  }) => {
    const { t } = useTranslation();
    const client = useWebLinkClient();

    // In order to use form values in a useCallback, we need to convert
    // them into regular JS values instead of MobX observables.
    const { location, dateFrom, dateTo } = toJS(values);
    const locationId =
      location === undefined ? booking.location?.id : location?.id;
    const bookingId = booking?.id;
    const courseCode = booking?.course?.code;
    const eventOptions = useCallback(
      async (inputValue: string) => {
        if (!bookingId) return [];

        const { data } = await client.query({
          query: WEBLINK_EVENTS_QUERY,
          variables: {
            filters: [
              ...getEventFilters(
                locationId,
                dateFrom,
                dateTo,
                bookingId,
                courseCode,
              ),
              {
                field: EventField.Name,
                operation: FilterOperation.Like,
                value: `%${inputValue}%`,
              },
            ],
          },
        });
        return extractNodes(data.events.edges);
      },
      [client, locationId, dateFrom, dateTo, bookingId, courseCode],
    );

    return (
      <Typeahead
        loadOptions={eventOptions}
        label={t("newEvent")}
        name="event"
        formatter={(event: Event) =>
          `${getEventStartDate(event, t)} - ${event.location.name}${
            event.venue?.name ? ` (${event.venue.name})` : ""
          }`
        }
        placeholder={`${t("selectEvent")}...`}
        valid={v => !!v}
      />
    );
  },
);

const getEventFilters = (
  locationId: string | null | undefined,
  dateFrom: string | null,
  dateTo: string | null,
  bookingId: string | null | undefined,
  courseCode: string | null | undefined,
) => {
  const eventFilters = [
    {
      field: EventField.Start,
      operation: FilterOperation.Gt,
      value: dateFrom || new Date(),
    },
  ];

  if (bookingId) {
    eventFilters.push({
      field: EventField.Id,
      operation: FilterOperation.Ne,
      value: bookingId,
    });
  }

  if (courseCode) {
    eventFilters.push({
      field: EventField.CourseCode,
      operation: FilterOperation.Eq,
      value: courseCode || "",
    });
  }

  if (dateTo) {
    eventFilters.push({
      field: EventField.End,
      operation: FilterOperation.Lt,
      value: dateTo,
    });
  }

  if (locationId) {
    eventFilters.push({
      field: EventField.LocationId,
      operation: FilterOperation.Eq,
      value: locationId,
    });
  }

  return eventFilters;
};
