import React, { Fragment, FunctionComponent } from "react";
import {
  Card,
  Col,
  FutureArticleHeader,
  Placeholder,
  Row,
  SubSection,
} from "@administrate/piston-ux";
import { Maybe } from "@administrate/piston-ux/lib/types";
import {
  CartLineItem,
  LearnerDetails,
  ProductOption,
} from "../../generated/weblinkTypes";
import { PersonRow, UnnamedLearners } from "../Order/BookingConfirmation";
import { CheckoutPage, CheckoutStep } from "./CheckoutPage";
import { useTranslation } from "react-i18next";
import { useCheckoutContext } from "../../providers/CheckoutProvider";
import { useBookerIntention } from "../../hooks/useBookerIntention";
import { BookerIntention } from "../Order/BookingPage";
import { getProductOption } from "../../utils/checkoutHelpers";
import { RECENT_REGISTERABLES_QUERY } from "../../queries/registerables";
import { useLmsQuery } from "../../hooks/lms";
import {
  FilterOperation,
  RegisterableField,
  Query,
} from "../../generated/lmsTypes";
import { Link } from "react-router-dom";
import { RegisterableUnion } from "../../utils/lmsLearningPathHelpers";
import { getRegisterableForCartItem } from "../../utils/cart";

export const OrderComplete: FunctionComponent = () => {
  const { t } = useTranslation();
  const { viewer } = useCheckoutContext();
  const cart = viewer?.lastCart;
  const postalAddress = viewer?.contact.postalAddress;
  const [bookerIntention] = useBookerIntention();

  const { data: recentRegisterablesData, loading: registerablesLoading } =
    useLmsQuery<Query>(RECENT_REGISTERABLES_QUERY, {
      variables: {
        filters: [
          {
            field: RegisterableField.ContactId,
            operation: FilterOperation.Eq,
            value: viewer?.contact.id,
          },
        ],
      },
      skip: bookerIntention === BookerIntention.Coordinating,
    });

  const recentRegisterables: RegisterableUnion[] | undefined =
    recentRegisterablesData?.registerables?.edges.map(
      edge => edge?.node as RegisterableUnion,
    );

  return (
    <CheckoutPage step={CheckoutStep.OrderComplete}>
      {cart && (
        <Card>
          <FutureArticleHeader title={t("orderComplete")} />
          <p id="successful-order-message">{t("successfulOrderMessage")}</p>
          <div>
            <span className="form-control-static-label">
              {t("orderNumber")}
            </span>
            {cart?.id && <p>{atob(cart?.id).split(":")[1]}</p>}
          </div>
          <SubSection titleLevel="h3" title={t("bookerInformation")}>
            <PersonRow
              firstName={
                cart && cart.buyerDetails && cart.buyerDetails.firstName
              }
              lastName={cart && cart.buyerDetails && cart.buyerDetails.lastName}
              email={cart && cart.buyerDetails && cart.buyerDetails.email}
              loading={false}
            />
            {postalAddress && (
              <BillingAddressRow billingAddress={postalAddress} />
            )}
          </SubSection>
          <SubSection
            titleLevel="h3"
            title={
              bookerIntention === BookerIntention.Self
                ? t("information")
                : t("learnerDetails")
            }
          >
            {cart.items &&
              cart.items.map((item: Maybe<CartLineItem>, i: number) => {
                let label = t("item");
                const { event, learningPath } = getProductOption(item);

                if (learningPath) {
                  label = t("learningPath");
                }
                if (event) {
                  label = t("course");
                }
                if (!item) return undefined;
                return (
                  <Fragment key={i}>
                    <ItemInformation
                      label={label}
                      productOption={item.productOption}
                      recentRegisterables={recentRegisterables}
                      registerablesLoading={registerablesLoading}
                      selfRegistration={
                        bookerIntention === BookerIntention.Self
                      }
                    />
                    {bookerIntention === BookerIntention.Coordinating &&
                      item.learnerDetails &&
                      item.learnerDetails.map(
                        (learner: Maybe<LearnerDetails>, i: number) => {
                          if (learner) {
                            return (
                              <Fragment key={i}>
                                <PersonRow
                                  loading={false}
                                  firstName={learner.firstName}
                                  lastName={learner.lastName}
                                  email={learner.email}
                                />
                              </Fragment>
                            );
                          }
                          return undefined;
                        },
                      )}
                    {bookerIntention === BookerIntention.Coordinating && (
                      <UnnamedLearners item={item} cartLoading={false} />
                    )}
                  </Fragment>
                );
              })}
          </SubSection>
        </Card>
      )}
    </CheckoutPage>
  );
};

const ItemInformation: FunctionComponent<{
  label: string;
  productOption?: Maybe<ProductOption>;
  recentRegisterables?: RegisterableUnion[];
  registerablesLoading: boolean;
  selfRegistration: boolean;
}> = ({
  label,
  productOption,
  recentRegisterables,
  registerablesLoading,
  selfRegistration,
}) => {
  const getCartItemLink = (productOption: ProductOption) => {
    const registerable = getRegisterableForCartItem(
      productOption.id,
      recentRegisterables,
    );
    if (!registerable) {
      return "";
    }
    const baseUrl =
      registerable.__typename === "LearningPathRegistration"
        ? "learning-path"
        : "course";
    return `/my-courses/${baseUrl}/${registerable.id}`;
  };

  if (!productOption) {
    return <p className="font-weight-bold">{label}</p>;
  }
  if (selfRegistration) {
    if (registerablesLoading) {
      return <Placeholder />;
    }
    return (
      <p>
        <span className="font-weight-bold d-block">{label}</span>
        <Link to={getCartItemLink(productOption)}>{productOption?.name}</Link>
      </p>
    );
  }
  return (
    <p className="font-weight-bold">
      {label}: {productOption?.name}
    </p>
  );
};

const BillingAddressRow: FunctionComponent<{
  billingAddress: any;
}> = ({ billingAddress }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col sm={6}>
        <div>
          <span className="form-control-static-label">
            {t("billingAddress")}
          </span>
          <p>{billingAddress.streetAddress1}</p>
          <p>{billingAddress.streetAddress2}</p>
          <p>{billingAddress.streetAddress3}</p>
          <p>{billingAddress.town}</p>
          <p>{billingAddress.postcode}</p>
          {billingAddress.province && <p>{billingAddress.province.name}</p>}
          {billingAddress.country && <p>{billingAddress.country.name}</p>}
        </div>
      </Col>
    </Row>
  );
};
