import {
  AlertModal,
  Button,
  Col,
  Container,
  Form,
  List,
  ListCell,
  ListEmpty,
  ListRow,
  Row,
  Submit,
  SubSection,
  TextArea,
  useFormValues,
  useDetailFormState,
  FormStateStatus,
  ValidationErrors,
  Card,
} from "@administrate/piston-ux";
import React, { Fragment, FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import jwtDecode from "jwt-decode";
import { useLmsMutation } from "../../hooks/lms";
import {
  DELETE_DISCUSSION_COMMENT,
  RECORD_DISCUSSION_COMMENT,
} from "../../mutations/registrations";
import { resetForm } from "../../utils/formHelpers";
import { DiscussionTopic as DiscussionTopicType } from "../../generated/lmsTypes";
import { useViewer } from "../../providers/ViewerProvider";
import { useGlobalStore } from "../../providers/GlobalStore";
import { setStateType } from "../../types/SetStateType";
import { GraphQLErrorTypes, hasErrorType } from "../../utils/errorHelpers";

type CommentResult = {
  comment: string;
};

const COMMENT_MIN_LENGTH = 5;
const COMMENT_MAX_LENGTH = 1500;

export const DiscussionTopicPage: FunctionComponent<{
  registrationId?: string;
  content: DiscussionTopicType;
  setHasInvalidRegistration: setStateType<boolean>;
}> = ({ registrationId, content, setHasInvalidRegistration }) => {
  const contentId = content.id;
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const { previewJWT } = useGlobalStore();

  const [showAlertModal, setShowAlertModal] = useState(false);
  const [commentToDeleteId, setCommentToDeleteId] = useState("");

  const comments = content.comments.edges;

  const [addComment, { loading: mutationLoading, error: mutationError }] =
    useLmsMutation(RECORD_DISCUSSION_COMMENT, {
      onError: error => {
        if (!hasErrorType(error, GraphQLErrorTypes.invalidRegistration)) {
          throw error;
        }
        setHasInvalidRegistration(true);
      },
    });

  const {
    messages,
    saveState,
    setSaveState,
    setMessages,
    reset: resetFormState,
  } = useDetailFormState();

  let canDeleteDiscussionComments = false;
  if (previewJWT) {
    const decodedJwtToken = jwtDecode(previewJWT);
    const { permissions } = decodedJwtToken as any;
    ({ canDeleteDiscussionComments } = permissions);
  }

  const initialValues = {
    comment: "",
  };

  const formValues = useFormValues(initialValues);

  const handleSubmit = async (result: CommentResult) => {
    if (viewer?.isPreviewer) {
      return;
    }
    if (!result) {
      setSaveState("failed");
      return;
    }

    setSaveState("saving");
    const response = await addComment({
      variables: {
        input: {
          registrationId,
          contentId,
          comment: result.comment,
        },
        stringContentId: contentId,
      },
    });

    const validationErrors =
      response?.data.registration.recordDiscussionComment.errors;

    if (!mutationLoading) {
      if (mutationError) {
        setMessages({ failed: mutationError.message });
      } else if (validationErrors && validationErrors.length > 0) {
        setMessages({
          errors: (
            <ValidationErrors
              title={t("commentError")}
              mutationValidationErrors={validationErrors}
            />
          ),
        });
      } else {
        resetFormState();
        resetForm(formValues, initialValues);
      }
    }
  };

  const displayTimePosted = (createdAt: string) => {
    let createdAtLocalTime = moment(createdAt).add(
      moment(createdAt).utcOffset(),
      "minutes",
    );
    const isPostedInFuture = createdAtLocalTime.isAfter();

    if (isPostedInFuture) {
      createdAtLocalTime = moment();
    }

    return createdAtLocalTime.fromNow();
  };

  const onModalsDone = () => {
    setCommentToDeleteId("");
    setShowAlertModal(false);
  };

  const textareaValidation = t("commentLengthValidation", {
    minLength: COMMENT_MIN_LENGTH,
    maxLength: COMMENT_MAX_LENGTH,
  });

  return (
    <Fragment>
      <Container>
        <Row>
          <Col mdOffset={2} md={8}>
            <h1 className="sr-only">{(content as any).displayName || ""}</h1>
            <SubSection
              titleLevel="span"
              title={(content as any).displayName || ""}
            >
              {content.htmlDescription && (
                <div
                  dangerouslySetInnerHTML={{ __html: content.htmlDescription }}
                />
              )}
            </SubSection>
            <FormStateStatus messages={messages} saveState={saveState} />
            <h2 className="sr-only">{t("discussion")}</h2>
            {comments.length === 0 ? (
              <List extraClass="mb-5">
                <ListEmpty text={t("noComments")} />
              </List>
            ) : (
              <Card>
                <List>
                  {comments.map(({ node: comment }) => {
                    return (
                      <ListRow
                        key={comment.id}
                        gridColumns="13.5fr 0.5fr"
                        extra={
                          canDeleteDiscussionComments && (
                            <Button
                              type="suppressed"
                              label={t("delete")}
                              onClick={() => {
                                setShowAlertModal(true);
                                setCommentToDeleteId(comment.id);
                              }}
                            />
                          )
                        }
                      >
                        <ListCell
                          label={t("delete")}
                          extraClass="discussion-topic-comment"
                        >
                          <div className="mb-4">{comment.comment}</div>
                          <div>
                            <span>
                              {t("postedBy")}{" "}
                              <strong>
                                {comment.contact.firstName}{" "}
                                {comment.contact.lastName &&
                                comment.contact.lastName.length > 0
                                  ? `${comment.contact.lastName[0]}.`
                                  : null}
                              </strong>
                              <span className="ml-4">
                                {displayTimePosted(comment.createdAt)}
                              </span>
                            </span>
                          </div>
                        </ListCell>
                      </ListRow>
                    );
                  })}
                </List>
              </Card>
            )}
            {!viewer?.isPreviewer && (
              <Form values={formValues} onSubmit={handleSubmit}>
                <TextArea
                  label={t("comment")}
                  name="comment"
                  maxLength={COMMENT_MAX_LENGTH}
                  valid={v =>
                    v.length >= COMMENT_MIN_LENGTH ? true : textareaValidation
                  }
                />
                <Submit
                  label={t("comment")}
                  disabled={saveState === "saving"}
                />
              </Form>
            )}
          </Col>
        </Row>
      </Container>
      {canDeleteDiscussionComments && (
        <DeleteCommentModal
          showModal={showAlertModal}
          onClose={onModalsDone}
          commentToDeleteId={commentToDeleteId}
          contentId={contentId}
        />
      )}
    </Fragment>
  );
};

const DeleteCommentModal: FunctionComponent<{
  showModal: boolean;
  onClose: (refetch: boolean) => void;
  commentToDeleteId: string;
  contentId: string;
}> = ({ showModal, onClose, commentToDeleteId, contentId }) => {
  const { t } = useTranslation();

  const [deleteComment, { loading: mutationLoading, error: mutationError }] =
    useLmsMutation(DELETE_DISCUSSION_COMMENT);

  const { messages, saveState, setSaveState, setMessages, reset } =
    useDetailFormState();

  if (showModal && saveState === "saved") reset();

  const handleCloseModal = (submitted: boolean) => {
    if (submitted) {
      setSaveState("saving");
      handleDeleteComment();
    } else {
      reset();
      onClose(false);
    }
  };

  const handleDeleteComment = async () => {
    const response = await deleteComment({
      variables: {
        input: {
          commentId: commentToDeleteId,
        },
        stringContentId: contentId,
      },
    });

    const validationErrors =
      response.data.registration.deleteDiscussionComment.errors;

    if (!mutationLoading) {
      if (mutationError) {
        setMessages({ failed: mutationError.message });
      } else if (validationErrors && validationErrors.length > 0) {
        setMessages({
          errors: (
            <ValidationErrors
              title={t("commentDeleteError")}
              mutationValidationErrors={validationErrors}
            />
          ),
        });
      } else {
        onClose(true);
        setMessages({
          success: t("commentDeleteSuccess"),
        });
      }
    }
  };

  return (
    <Fragment>
      {saveState && (
        <FormStateStatus messages={messages} saveState={saveState} />
      )}
      {showModal && (
        <AlertModal
          title={t("areYouSure")}
          type="warning"
          show
          onDone={handleCloseModal}
        />
      )}
    </Fragment>
  );
};
