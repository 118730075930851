import React, { useContext } from "react";
import { useLocalStore } from "mobx-react-lite";

export type GlobalStoreType =
  | undefined
  | {
      config: any;
      sideBarOpen: boolean;
      contentMenuOpen?: boolean;
      token?: string;
      previewJWT?: string;
      convertLMSImageUrl: (src: string) => string;
    };

const GlobalStoreContext = React.createContext<GlobalStoreType>(undefined);

const GLOBAL_STORE_INITIAL_VALUES = {
  config: null,
  sideBarOpen: false,
  contentMenuOpen: true,
  convertLMSImageUrl: (src: string) => src,
};

export const useGlobalStore = () => {
  const context = useContext(GlobalStoreContext);
  if (context === undefined) {
    throw new Error("GlobalStore provider not found");
  }
  return context;
};

export const GlobalStoreProvider: React.FunctionComponent<{
  config: any;
  initialValues?: GlobalStoreType;
}> = ({ config, initialValues = GLOBAL_STORE_INITIAL_VALUES, children }) => {
  initialValues.config = config;
  return (
    <GlobalStoreContext.Provider value={useLocalStore(() => initialValues)}>
      <SetContextAwareFunctions imageUriPrefix={config.image_uri_prefix}>
        {children}
      </SetContextAwareFunctions>
    </GlobalStoreContext.Provider>
  );
};

/**
 * We want to initialize the convertLMSImageUrl in here, in context of globalStore, because we need for the 'token'
 * variable to be dynamically filled in when it's available. However, globalStore is a hook, so it cannot be
 * initialized conditionally. So if we were to make convertLMSImageUrl a normal function, we'd always have to call it
 * with 'token' as a parameter, and use globalStore wherever we call convertLMSImageUrl anyway. So, it's more compact
 * to just have this function as part of the globalStore and request it directly.
 */
const SetContextAwareFunctions: React.FunctionComponent<{
  imageUriPrefix: string;
}> = ({ imageUriPrefix, children }) => {
  const globalStore = useGlobalStore();

  globalStore.convertLMSImageUrl = (src: string) => {
    if (/^\/images(.*)/.test(src)) {
      src = `${imageUriPrefix}${src}?access_token=${globalStore.token}`;
    }
    return src;
  };

  return <>{children}</>;
};
