import { Query } from "../generated/lmsTypes";
import { RegisterableUnion } from "./lmsLearningPathHelpers";
import { Event, LearningPath } from "../generated/weblinkTypes";

export const getRegisterableForCartItem = (
  eventId: string,
  recentRegisterables: RegisterableUnion[] | undefined,
) => {
  if (recentRegisterables) {
    const registerable = recentRegisterables?.find(node => {
      if (node?.__typename === "LearningPathRegistration")
        return node?.learningPath?.id === eventId;
      if (node?.__typename === "Registration")
        return node?.course?.id === eventId;
      return false;
    });
    return registerable;
  }
};

export const checkRegistrationAndNavigate = (
  registrationData: Query | undefined,
  event: Event | LearningPath,
) => {
  if (!registrationData) {
    return false;
  }
  const recentRegisterables: RegisterableUnion[] | undefined =
    registrationData.registerables?.edges.map(
      edge => edge?.node as RegisterableUnion,
    );

  const registerable = getRegisterableForCartItem(
    event.id,
    recentRegisterables,
  );
  if (registerable) {
    const baseUrl =
      registerable.__typename === "LearningPathRegistration"
        ? "learning-path"
        : "course";
    return `/my-courses/${baseUrl}/${registerable.id}`;
  }
  return false;
};
