import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormStateStatus,
  FutureArticleHeader,
  Input,
  Row,
  SaveStates,
  Submit,
  Tooltip,
  useDetailFormState,
  useFormValues,
  ValidationErrors,
} from "@administrate/piston-ux";
import { MessageOptions } from "@administrate/piston-ux/lib/utils/FormStateStatus";
import { useTranslation } from "react-i18next";
import { ApplyPromotionCodeInput, Cart } from "../generated/weblinkTypes";
import { useWebLinkMutation } from "../hooks/weblink";
import { APPLY_PROMO_CODE_MUTATION } from "../queries/cart";
import { getFinancialFormat } from "../utils/displayHelpers";
import { RefetchStatus } from "../providers/CheckoutProvider";
import { useViewer } from "../providers/ViewerProvider";

export type PromoCodeType = {
  promotionCode: string;
};

export const PromoCode: FunctionComponent<{
  cart?: Cart;
  refetchCart: (refetch: RefetchStatus) => void;
}> = ({ cart, refetchCart }) => {
  const { t } = useTranslation();

  const {
    messages,
    saveState,
    setSaveState,
    setMessages,
    reset: resetFormState,
  } = useDetailFormState();

  const [addPromoCode, { loading: mutationLoading, error: mutationError }] =
    useWebLinkMutation(APPLY_PROMO_CODE_MUTATION);

  const handlePromoCodeSubmit = async (result: PromoCodeType) => {
    if (!result) {
      setSaveState("failed");
    }
    if (result && cart?.id) {
      setSaveState("saving");
      const input: ApplyPromotionCodeInput = {
        cartId: cart?.id,
        promotionCode: result.promotionCode,
      };
      const response = await addPromoCode({
        variables: {
          input,
        },
      });
      const validationErrors = response.data.cart.applyPromotionCode.errors;

      if (!mutationLoading) {
        if (mutationError) {
          setMessages({ failed: mutationError.message });
          refetchCart(RefetchStatus.Cancel);
        } else if (validationErrors && validationErrors.length > 0) {
          setMessages({
            errors: (
              <ValidationErrors
                title={t("errorAddingPromoCode")}
                mutationValidationErrors={validationErrors}
              />
            ),
          });
          setSaveState("errors");
          refetchCart(RefetchStatus.Cancel);
        } else {
          resetFormState();
          refetchCart(RefetchStatus.StartRefetch);
        }
      }
    }
  };

  return (
    <Card>
      <FutureArticleHeader title={t("promoCode")} />
      <PromoCodeForm
        handleSubmit={handlePromoCodeSubmit}
        messages={messages}
        saveState={saveState}
        cart={cart}
      />
    </Card>
  );
};

const PromoCodeForm: FunctionComponent<{
  cart?: Cart;
  messages: MessageOptions;
  saveState: SaveStates;
  handleSubmit: (result: PromoCodeType) => Promise<void>;
}> = ({ handleSubmit, saveState, messages, cart }) => {
  const [hasActivePromoCode, setHasActivePromoCode] = useState(false);
  const [hasInputtedPromoCode, setHasInputtedPromoCode] = useState(false);

  const { t } = useTranslation();

  const values = useFormValues({
    promotionCode: undefined,
  });

  useEffect(() => {
    if (cart?.promotionalCode) {
      values.promotionCode = cart?.promotionalCode;
      setHasActivePromoCode(true);
    }
    if (!cart?.promotionalCode && hasActivePromoCode) {
      values.promotionCode = undefined;
      setHasActivePromoCode(false);
    }
  }, [cart, values.promotionCode, hasActivePromoCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (result: PromoCodeType) => {
    handleSubmit(result);
    setHasActivePromoCode(true);
  };

  return (
    <Fragment>
      <FormStateStatus messages={messages} saveState={saveState} />
      <Form
        values={values}
        onSubmit={onSubmit}
        disabled={saveState === "saving"}
      >
        <div style={{ display: "flex" }} className="promo-codes">
          <div style={{ flex: 1 }} className="mr-2">
            <Tooltip
              content={t("onePromoCodeAllowed")}
              tooltipId="promoCodeTooltip"
            >
              <Input
                name="promotionCode"
                label={t("enterPromoCode")}
                disabled={hasActivePromoCode}
                onChange={value => setHasInputtedPromoCode(value.length !== 0)}
              />
            </Tooltip>
          </div>
          <div
            style={{
              marginTop: 22,
            }}
          >
            <Submit
              label={t("addCode")}
              disabled={hasActivePromoCode || !hasInputtedPromoCode}
            />
          </div>
        </div>
        {hasActivePromoCode && saveState !== "saving" && (
          <div>
            <span
              className="glyphicon-pro glyphicon-pro-check mr-1"
              style={{ fontSize: 16 }}
            ></span>
            {t("You successfully redeemed your Promo Code")}.
          </div>
        )}
      </Form>
    </Fragment>
  );
};

export const ActivePromoCode: FunctionComponent<{
  cart: Cart;
  onRemovePromoCode: () => Promise<void>;
}> = ({ cart, onRemovePromoCode }) => {
  const { viewer } = useViewer();
  const { t } = useTranslation();
  return (
    <Fragment>
      <Row className="mt-2 mb-0">
        <Col xs={6} className="font-weight-bold">
          {t("promoCode")}:
        </Col>
      </Row>
      <Row className="mb-2" data-label="Applied Promo Code">
        <Col xs={8} className="d-flex">
          <div
            className="mr-2"
            style={{
              height: 30,
              lineHeight: "30px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {cart?.promotionalCode}
          </div>
          <div style={{ width: 30 }}>
            <Button
              label=""
              ariaLabel={t("removePromoCode")}
              icon="remove-circle"
              type="suppressed"
              onClick={() => onRemovePromoCode()}
            />
          </div>
        </Col>
        <Col
          xs={4}
          className="text-right "
          style={{ height: 30, lineHeight: "30px" }}
        >
          <span style={{ whiteSpace: "nowrap" }}>
            -
            {getFinancialFormat(
              cart?.price?.discountTotal,
              cart?.currency,
              viewer?.locale,
            )}
          </span>
        </Col>
      </Row>
    </Fragment>
  );
};
