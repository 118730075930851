import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FutureListPage } from "@administrate/piston-ux";

export const LearnerManagementPage: FunctionComponent<{
  tab: "learners" | "bookings";
}> = ({ tab, children }) => {
  const { t } = useTranslation();
  return (
    <FutureListPage
      title={t("learnerManagement")}
      navigation={[
        {
          label: t("browseCatalog"),
          link: <Link to="/learner-management/learners">{t("learners")}</Link>,
          active: tab === "learners",
        },
        {
          label: t("allDates"),
          link: <Link to="/learner-management/bookings">{t("bookings")}</Link>,
          active: tab === "bookings",
        },
      ]}
    >
      {children}
    </FutureListPage>
  );
};
