import React, { useState, useEffect, FunctionComponent } from "react";

import { CategoryPicker } from "../../toPistonUX/ActionsBar/CategoryPicker";
import { SearchBar } from "../../toPistonUX/ActionsBar/SearchBar";
import { CategoryChange } from "../../toPistonUX/ActionsBar/types";
import { ActionsBar } from "../../toPistonUX/ActionsBar/ActionsBar";
import { useTranslation } from "react-i18next";

export type CourseCatalogActionBarFilters = {
  category: CategoryChange | null;
  searchBar: string;
};

export type CourseCatalogActionsBarProps = {
  onChange: (args: CourseCatalogActionBarFilters) => void;
};

export const CourseCatalogActionsBar: FunctionComponent<CourseCatalogActionsBarProps> =
  ({ onChange }) => {
    const { t } = useTranslation();
    const [searchBarValue, setSearchBarValue] = useState("");
    const [categoryPickerValue, setCategoryPickerValue] =
      useState<CategoryChange | null>(null);

    useEffect(() => {
      onChange({
        searchBar: searchBarValue,
        category: categoryPickerValue,
      });
    }, [searchBarValue, categoryPickerValue, onChange]);

    return (
      <ActionsBar>
        <SearchBar onChange={setSearchBarValue} label={t("courses")} />
        <CategoryPicker onChange={setCategoryPickerValue} />
      </ActionsBar>
    );
  };
