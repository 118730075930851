import { FeedbackTooltip } from "@administrate/piston-ux/lib/Tooltip";
import React, { FunctionComponent, ReactElement } from "react";
import { useTranslation } from "react-i18next";

export const ItemSummary: FunctionComponent<{
  name: string;
  quantity?: number | null;
  location?: string | ReactElement;
  date?: ReactElement;
  price?: ReactElement;
  hidePrices: boolean;
  isZeroPriced: boolean;
  wasRemovedFromCart?: boolean;
  invalidReason?: string;
}> = ({
  name,
  quantity,
  location,
  date,
  price,
  hidePrices,
  isZeroPriced,
  wasRemovedFromCart,
  invalidReason,
}) => {
  const { t } = useTranslation();

  const invalidOrRemovedItem = wasRemovedFromCart || invalidReason;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div
        data-label={name}
        style={
          invalidOrRemovedItem ? { textDecoration: "line-through" } : undefined
        }
      >
        <p>
          <span className="font-weight-bold">{name}</span>
        </p>
        {location && <p>{location}</p>}
        {date && <p>{date}</p>}
        {quantity && (
          <p>
            <span className="font-weight-bold">{t("quantity")}: </span>
            {quantity}
          </p>
        )}
        {!(isZeroPriced || hidePrices) && price && !wasRemovedFromCart && (
          <p>
            {price} {t("perSeat")}
          </p>
        )}
      </div>
      {/* We need to pass a child for this to work */}
      {invalidOrRemovedItem && (
        <FeedbackTooltip
          tooltipId={`TOOLTIP_FOR_ITEM_${name}`}
          content={invalidReason}
          type="warning"
        >
          {null}
        </FeedbackTooltip>
      )}
    </div>
  );
};
