import React, { Fragment, FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FutureListPage } from "@administrate/piston-ux";

import { RequestTraining } from "../../components/RequestTraining";
import { useBookerIntention } from "../../hooks/useBookerIntention";
import { CartWidget } from "../Cart/CartWidget";
import { BookerIntention } from "../Order/BookingPage";

type CatalogPageProps = {
  tab: "find" | "browse";
  title: string;
};

export const CatalogPage: FunctionComponent<CatalogPageProps> = ({
  tab,
  title,
  children,
}) => {
  const { t } = useTranslation();
  const [bookerIntention] = useBookerIntention();
  return (
    <FutureListPage
      title={title}
      navigation={[
        {
          label: t("browseCatalog"),
          link: (
            <Link to={`/catalog/${bookerIntention}`}>{t("browseCatalog")}</Link>
          ),
          active: tab === "browse",
        },
        {
          label: t("allDates"),
          link: (
            <Link to={`/catalog/${bookerIntention}/find`}>{t("allDates")}</Link>
          ),
          active: tab === "find",
        },
      ]}
      extra={
        <Fragment>
          {bookerIntention === BookerIntention.Self && <CartWidget />}
          <RequestTraining />
        </Fragment>
      }
      type={tab === "browse" ? "grid" : "table"}
    >
      {children}
    </FutureListPage>
  );
};
