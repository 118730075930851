import gql from "graphql-tag";

export const TRAINING_PASS_TYPES = gql`
  query passTypes {
    trainingPassTypes {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
