import { TFunction } from "i18next";
import { Cart, PlaceOrderInput } from "../../generated/weblinkTypes";
import { WeblinkPaymentMethodUnion } from "../../hooks/useWeblinkSettings";
import { code as iso4217Code } from "currency-codes";
import { SaveStates } from "@administrate/piston-ux";
import { MessageOptions } from "@administrate/piston-ux/lib/utils/FormStateStatus";
import { ExecutionResult } from "apollo-boost";
import { MutationFunctionOptions } from "@apollo/react-common";

type ThreeDSecureResponse = {
  ssl_3ds2_token: string;
  ssl_3ds2_exp: string;
  ssl_3ds2_error?: string;
};
type ThreeDSFlowResponse = {
  transStatus: string;
  messageType: string;
  messageVersion: string;
  threeDSServerTransID: string;
  dsTransID: string;
  acsTransID: string;
  acsReferenceNumber: string;
  acsOperatorID: string;
  dsReferenceNumber: string;
  authenticationType: string;
  acsChallengeMandated: string;
  acsURL: string;
  challengeCancel: string;
  eci: string;
  interactionCounter: string;
  messageCategory: string;
  transStatusReason: string;
  authenticated: boolean;
  message: string;
  error?: {
    errorCode: string;
    errorComponent: string;
    errorDescription: string;
    errorDetail: string;
    errorMessageType: string;
    messageType: string;
    messageVersion: string;
    threeDSServerTransID: string;
  };
};
type ConvergeCardDetails = {
  cardNumber: string;
  expiration: string;
  cvv2: string;
  address1: string;
  zip: string;
};

const parseErrorResponse = (
  response: ThreeDSFlowResponse,
  setMessages: React.Dispatch<React.SetStateAction<MessageOptions>>,
  setSaveState: React.Dispatch<React.SetStateAction<SaveStates>>,
  t: TFunction,
) => {
  const { error } = response;
  const code = error ? Number(error.errorCode) : 500;

  console.error("3DS Error:", error);
  switch (code) {
    case 203:
      setSaveState("errors");
      setMessages({ errors: t("3dsCheckYourDetails") });
      break;
    case 305:
      setSaveState("errors");
      setMessages({ errors: t("3dsTryAnotherCard") });
      break;
    case 402:
    case 403:
    case 404:
    case 405:
      setSaveState("errors");
      setMessages({ errors: t("3dSecureServerUnavailable") });
      break;
    default:
      setSaveState("errors");
      setMessages({ errors: t("3dsCheckYourDetails") });
  }
};

const onThreeDSecure = async ({
  response,
  token,
  values,
  cart,
  executePlaceOrderInput,
  executePlaceOrder,
  convergeEFSUrl,
  setMessages,
  setSaveState,
  t,
}: {
  response: ThreeDSecureResponse;
  token: string;
  values: ConvergeCardDetails;
  cart: Cart;
  executePlaceOrderInput: PlaceOrderInput;
  executePlaceOrder: (input: PlaceOrderInput) => Promise<void>;
  convergeEFSUrl?: string;
  setMessages: React.Dispatch<React.SetStateAction<MessageOptions>>;
  setSaveState: React.Dispatch<React.SetStateAction<SaveStates>>;
  t: TFunction;
}) => {
  if (!response.ssl_3ds2_token) {
    // Required to handle transitionary period where customers don't have 3DS2 enabled.
    if (
      response.ssl_3ds2_error &&
      response.ssl_3ds2_error === "Terminal not setup for 3ds2"
    ) {
      completeConvergeCheckout(
        values,
        token,
        executePlaceOrderInput,
        executePlaceOrder,
        setMessages,
        setSaveState,
        t,
      );
      return;
    }

    console.error("3DS Response:", response);
    setSaveState("errors");
    setMessages({ errors: t("3dsFailedToInitialize") });
    return;
  }

  const sdk = new (window as any).Elavon3DSWebSDK({
    baseUrl: convergeEFSUrl,
    token: response.ssl_3ds2_token,
  });

  const cartPrice = cart.price;
  const currencyCode = cart.currency!.code as string;
  const [mm, yy] = values.expiration.split("/");

  const threeDsRequest = {
    messageId: `Cart:${cart?.id}`,
    purchaseAmount: (parseFloat(cartPrice?.grandTotal) * 100).toString(),
    purchaseCurrency: iso4217Code(currencyCode)?.number,
    purchaseExponent: "2",
    acctNumber: values.cardNumber,
    cardExpiryDate: `${yy}${mm}`,
    messageCategory: "01",
    transType: "01",
    threeDSRequestorAuthenticationInd: "01",
    challengeWindowSize: "03",
    displayMode: "lightbox",
    // TODO: These don't seem to work for 3DS1 fallback atm...
    clientStartProtocolVersion: "1.0.2",
    clientEndProtocolVersion: "2.1.0",
  };

  // Works around https://bugs.chromium.org/p/chromium/issues/detail?id=1095999 for now
  const { height, width, colorDepth } = window.screen;
  (window as any).screen = {
    colorDepth: colorDepth === 30 ? 24 : colorDepth,
    height,
    width,
  };

  sdk.web3dsFlow(threeDsRequest).then(
    (res: ThreeDSFlowResponse) => {
      if (!res.authenticated || res.transStatus === "N") {
        setSaveState("errors");
        setMessages({ errors: t("3dsFailedToAuthenticate") });
        console.error("3DS Error:", res);
        return;
      }

      completeConvergeCheckout(
        values,
        token,
        executePlaceOrderInput,
        executePlaceOrder,
        setMessages,
        setSaveState,
        t,
      );
    },
    (res: ThreeDSFlowResponse) => {
      parseErrorResponse(res, setMessages, setSaveState, t);
    },
  );
};

const completeConvergeCheckout = (
  cardDetails: ConvergeCardDetails,
  authToken: string,
  placeOrderInput: PlaceOrderInput,
  executePlaceOrder: (input: PlaceOrderInput) => Promise<void>,
  setMessages: React.Dispatch<React.SetStateAction<MessageOptions>>,
  setSaveState: React.Dispatch<React.SetStateAction<SaveStates>>,
  t: TFunction,
) => {
  const onApproval = async (response: any) => {
    placeOrderInput.convergeDetails = {
      pendingTransactionId: response.ssl_txn_id,
    };
    executePlaceOrder(placeOrderInput);
  };

  const onError = (response: any) => {
    setSaveState("errors");
    setMessages({
      errors: `${t("anErrorOccurredWhilePlacingYourOrder")}.`,
    });
  };
  const onDeclined = (response: any) => {
    console.error("Declined", JSON.stringify(response));
    setSaveState("errors");
    setMessages({
      errors: `${t("paymentWasDeclined")}`,
    });
  };

  const onDCCDecision = (responseFields: any) => {
    console.log("dcc decision required");
    (window as any).ConvergeEmbeddedPayment.dccDecision(false, {
      onError,
      onDeclined,
      onApproval,
    });
  };

  const [month, year] = cardDetails.expiration.split("/");

  (window as any).ConvergeEmbeddedPayment.pay(
    {
      ssl_txn_auth_token: authToken,
      ssl_card_number: cardDetails.cardNumber,
      ssl_exp_date: `${month}${year}`,
      ssl_cvv2cvc2: cardDetails.cvv2,
      ssl_avs_address: cardDetails.address1,
      ssl_avs_zip: cardDetails.zip,
    },
    { onError, onDeclined, onApproval, onDCCDecision },
  );
};

export const checkoutWithConverge = async (
  values: ConvergeCardDetails,
  convergePaymentMethod: WeblinkPaymentMethodUnion,
  cart: Cart,
  createPaymentSession: (
    options?: MutationFunctionOptions<any, Record<string, any>>,
  ) => Promise<ExecutionResult<any>>,
  executePlaceOrder: (input: PlaceOrderInput) => Promise<void>,
  executePlaceOrderInput: PlaceOrderInput,
  t: TFunction,
  setMessages: React.Dispatch<React.SetStateAction<MessageOptions>>,
  setSaveState: React.Dispatch<React.SetStateAction<SaveStates>>,
  convergeEFSUrl?: string,
) => {
  try {
    const { cardNumber, expiration, cvv2, address1, zip } = values;
    if (!cardNumber || !expiration || !cvv2 || !address1 || !zip) {
      setMessages({
        errors: `${t("someCardDetailsAreMissing")}`,
      });
      return;
    }

    if (!convergePaymentMethod) {
      throw new Error(
        "Attempting to checkout with Converge but not configured for this Portal",
      );
    }

    const {
      data: {
        createPaymentSession: { token },
      },
    } = await createPaymentSession({
      variables: {
        input: {
          paymentProviderId: convergePaymentMethod.id,
          amount: parseFloat(cart.price?.grandTotal),
        },
      },
    });

    const threeDsCallback: {
      onThreeDSecure2: (response: ThreeDSecureResponse) => Promise<void>;
    } = {
      onThreeDSecure2: async response =>
        onThreeDSecure({
          response,
          token,
          values,
          cart,
          executePlaceOrderInput,
          executePlaceOrder,
          convergeEFSUrl,
          setMessages,
          setSaveState,
          t,
        }),
    };

    (window as any).ConvergeEmbeddedPayment.getEFSToken(
      { ssl_txn_auth_token: token },
      threeDsCallback,
    );
  } catch (ex) {
    setSaveState("failed");
    setMessages({
      failed: `${t("anErrorOccurredWhilePlacingYourOrder")}.`,
    });
  }
};
