import React, { Fragment, FunctionComponent } from "react";
import { Elements as StripeElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useHistory } from "../../useHistory";
import { MANAGED_ACCOUNTS_QUERY } from "../../queries/learnerManagement";
import {
  Account,
  AccountConnection,
  Cart,
  Stripe,
  Viewer,
} from "../../generated/weblinkTypes";
import { useWebLinkQuery } from "../../hooks/weblink";
import {
  AlertModal,
  Card,
  Col,
  Container,
  FutureArticleHeader,
  LoadingBar,
  Placeholder,
  Row,
} from "@administrate/piston-ux";
import { MutationHookOptions } from "@apollo/react-hooks";
import { ExecutionResult } from "apollo-boost";
import { BookerIntention, BookingPage } from "./BookingPage";
import { VIEWER_CONTACT_DETAILS_QUERY } from "../../queries/cart";
import { BookingDetailForm } from "./BookingDetailForm";
import { useTranslation } from "react-i18next";
import { OrderSummary } from "./OrderSummary";
import { useBookerIntention } from "../../hooks/useBookerIntention";
import {
  useWeblinkSettings,
  WeblinkSettingsType,
} from "../../hooks/useWeblinkSettings";
import { useScript } from "../../hooks/useScript";
import { useUpdateCartFinancialUnit } from "../../hooks/useUpdateCartFinancialUnit";

type ChildBookingDetailType = {
  weblinkSettings?: WeblinkSettingsType;
  managedAccount?: Account;
  cart?: Cart;
  wonCart?: boolean;
  refetchViewer: any;
  updateCartFinancialUnit: (
    options?: MutationHookOptions,
  ) => Promise<ExecutionResult>;
};

export const BookingDetailPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [bookerIntention] = useBookerIntention();
  const weblinkSettings = useWeblinkSettings();

  const stripePaymentProvider = weblinkSettings.stripePaymentMethod;
  const stripePublicKey = stripePaymentProvider
    ? (stripePaymentProvider as Stripe).publicKey
    : null;
  const stripePromise = stripePublicKey ? loadStripe(stripePublicKey) : null;

  useScript(weblinkSettings.convergeJSUrl);
  useScript(weblinkSettings.converge3DSUrl);

  const {
    cart,
    loading: viewerQueryLoading,
    refetch: refetchViewer,
    updateCartFinancialUnit,
  } = useUpdateCartFinancialUnit();

  if (viewerQueryLoading) {
    return <LoadingBar isLoading />;
  }

  if (!cart) throw new Error("No cart found");

  const wonCart = viewerQueryLoading && cart !== null && cart.state === "won";

  return (
    <BookingPage step={2} bookerIntention={bookerIntention}>
      <StripeElements stripe={stripePromise}>
        <Container>
          {viewerQueryLoading ? (
            <BookingDetailFormSkeleton bookerIntention={bookerIntention} />
          ) : (
            <Fragment>
              {bookerIntention === BookerIntention.Coordinating && (
                <ManagedAccountBookingDetailForm
                  cart={cart}
                  wonCart={wonCart}
                  refetchViewer={refetchViewer}
                  updateCartFinancialUnit={updateCartFinancialUnit}
                />
              )}
              {bookerIntention === BookerIntention.Self && (
                <SelfRegistrationBookingDetailForm
                  cart={cart}
                  wonCart={wonCart}
                  refetchViewer={refetchViewer}
                  updateCartFinancialUnit={updateCartFinancialUnit}
                />
              )}
            </Fragment>
          )}
        </Container>
      </StripeElements>
      <AlertModal
        title={t("This booking is already confirmed")}
        show={wonCart}
        onDone={() =>
          history.push(
            `/catalog/${bookerIntention}/booking/${cart?.id}/confirmation`,
          )
        }
        type="warningWithoutCancel"
      />
    </BookingPage>
  );
};

export const ManagedAccountBookingDetailForm: FunctionComponent<ChildBookingDetailType> =
  ({ cart, wonCart, refetchViewer, updateCartFinancialUnit }) => {
    const { data, loading } = useWebLinkQuery<{
      managedAccounts: AccountConnection;
    }>(MANAGED_ACCOUNTS_QUERY, {
      fetchPolicy: "cache-and-network",
    });
    const managedAccount =
      data?.managedAccounts.pageInfo.totalRecords === 1
        ? data.managedAccounts.edges[0].node
        : undefined;

    return (
      <Fragment>
        {loading && !managedAccount ? (
          <BookingDetailFormSkeleton
            bookerIntention={BookerIntention.Coordinating}
          />
        ) : (
          <BookingDetailForm
            account={managedAccount}
            cart={cart}
            wonCart={wonCart}
            bookerIntention={BookerIntention.Coordinating}
            refetchViewer={refetchViewer}
          />
        )}
      </Fragment>
    );
  };

export const SelfRegistrationBookingDetailForm: FunctionComponent<ChildBookingDetailType> =
  ({ cart, wonCart, refetchViewer, updateCartFinancialUnit }) => {
    const { data, loading } = useWebLinkQuery<{
      viewer: Viewer;
    }>(VIEWER_CONTACT_DETAILS_QUERY);

    const account = data?.viewer?.contact?.account;

    return (
      <Fragment>
        {loading && !account && (
          <BookingDetailFormSkeleton bookerIntention={BookerIntention.Self} />
        )}
        {account && (
          <BookingDetailForm
            account={account}
            cart={cart}
            wonCart={wonCart}
            bookerIntention={BookerIntention.Self}
            refetchViewer={refetchViewer}
          />
        )}
      </Fragment>
    );
  };

const BookingDetailFormSkeleton: FunctionComponent<{
  bookerIntention: BookerIntention;
}> = ({ bookerIntention }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col lg={3} lgOffset={1} lgPush={7} md={4} mdPush={8}>
        <OrderSummary loading step={2} hidePrices isZeroPriced={false} />
      </Col>
      <Col lg={7} lgPull={3} md={8} mdPull={4} className="booking-page">
        <Card>
          <FutureArticleHeader
            title={
              bookerIntention === BookerIntention.Self
                ? t("registrationDetails")
                : t("bookingDetails")
            }
          />
          <Placeholder />
        </Card>
      </Col>
    </Row>
  );
};
