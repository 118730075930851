import React, { useMemo, useState } from "react";
import { useFormValues, Input, Select, Modal } from "@administrate/piston-ux";

import { CANCELLATION_REASONS_QUERY } from "../queries/cancellation";
import { CancellationReasonsResponse } from "../types/CancellationReasons";
import { SelectOption } from "../types/SelectOption";
import { useLmsQuery } from "../hooks/lms";
import { useTranslation } from "react-i18next";

export const CancelRegistrationModal: React.FunctionComponent<{
  show: boolean;
  onDone: any;
}> = ({ show, onDone }) => {
  const { t } = useTranslation();
  const initialValues: any = {
    reason: "",
    notes: "",
  };

  const [reasonId, setReasonId] = useState<string>();
  const [reasonNotes, setReasonNotes] = useState<string>();

  const {
    loading: cancellationReasonsIsLoading,
    data: cancellationReasonsResponse,
  } = useLmsQuery<CancellationReasonsResponse>(CANCELLATION_REASONS_QUERY);

  const cancellationReasons = useMemo<SelectOption[]>(
    () =>
      cancellationReasonsResponse?.cancellationReasons?.edges?.map(
        ({ node: { id, text } }) => ({ label: text, value: id }),
      ) || [],
    [cancellationReasonsResponse],
  );

  const formValues = useFormValues(initialValues);
  return (
    <Modal
      show={show}
      title={t("areYouSure")}
      onDone={(postMutation: boolean) =>
        onDone({ postMutation, reasonId, reasonNotes })
      }
      values={formValues}
      primaryActionText={t("OK")}
    >
      <div style={{ textAlign: "center" }}>
        <span className="glyphicon glyphicon-info-sign glyphicon-xl color-warning"></span>
        <p>{t("youAreAboutToCancelYourRegistration")}</p>
      </div>
      {cancellationReasonsIsLoading ? (
        <span className="placeholder animated" style={{ fontSize: "18px" }} />
      ) : (
        <>
          <Select
            label={t("cancellationReason")}
            options={cancellationReasons}
            name="reason"
            valid={v => !!v}
            onChange={(newValue: string) => setReasonId(newValue)}
          />
          <Input
            label={t("note")}
            type="text"
            name="notes"
            onChange={(newValue: string) => setReasonNotes(newValue)}
          />
        </>
      )}
    </Modal>
  );
};
