/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useState } from "react";
import { Alert, Button, ListCell, LoadingBar } from "@administrate/piston-ux";
import { get } from "lodash";
import { Query } from "../generated/lmsTypes";
import { Event, EventEdge } from "../generated/weblinkTypes";
import { useLmsQuery } from "../hooks/lms";
import { useWebLinkMutation } from "../hooks/weblink";
import {
  ADD_CART_LINE_ITEM_MUTATION,
  CREATE_CART_MUTATION,
  PLACE_ORDER_MUTATION,
} from "../queries/cart";
import { RECENT_REGISTERABLES_QUERY } from "../queries/registerables";
import { useHistory } from "../useHistory";
import { checkRegistrationAndNavigate } from "../utils/cart";
import { useViewer } from "../providers/ViewerProvider";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

type AutoRegisterType = {
  events: EventEdge[];
  startableCourse: boolean;
  setIsPageLoading: (pageLoading: boolean) => void;
};

const AutoRegister: FunctionComponent<AutoRegisterType> = ({
  events,
  startableCourse,
  setIsPageLoading,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { viewer } = useViewer();
  const [registering, setRegistering] = useState(false);
  const [apiError, setApiError] = useState(false);

  const [createCart, { error: createCartMutationError }] =
    useWebLinkMutation(CREATE_CART_MUTATION);

  const [placeOrder, { error: mutationPlaceOrderError }] =
    useWebLinkMutation(PLACE_ORDER_MUTATION);

  const [addCartLineItem, { error: addCartLineItemMutationError }] =
    useWebLinkMutation(ADD_CART_LINE_ITEM_MUTATION);

  const {
    data: registerableData,
    loading: registerablesLoading,
    error: registerablesError,
    refetch: refetchRegistrations,
  } = useLmsQuery<Query>(RECENT_REGISTERABLES_QUERY);

  useEffect(() => {
    if (registering) {
      setIsPageLoading(true);
    } else {
      setIsPageLoading(false);
    }
  }, [registering, setIsPageLoading]);

  if (!startableCourse) {
    return null;
  }

  const event = events[0].node;

  const selectedPrice = event.price || event.prices[0];

  const cartInput = {
    currencyCode: selectedPrice.financialUnit?.code,
    regionId: selectedPrice.region?.id,
  };

  const handleAutoRegisterToCourse = async (
    input: {
      currencyCode?: string;
      regionId?: string;
    },
    event: Event,
  ) => {
    setRegistering(true);
    const toRegistrationRoute = checkRegistrationAndNavigate(
      registerableData,
      event,
    );
    if (toRegistrationRoute) {
      history.push(toRegistrationRoute);
      return;
    }

    const cartResponse = await createCart({
      variables: { input },
    });
    const cartId = get(cartResponse, "data.cart.createCart.cart.id", "");
    if (
      createCartMutationError ||
      (cartResponse.errors && cartResponse.errors.length > 0)
    ) {
      logError(cartResponse.errors, "creating cart");
      return;
    }

    const addLineItemResponse = await addCartLineItem({
      variables: {
        input: {
          cartId,
          cartLineItem: {
            productOptionId: event && event.id,
            learners: { existingLearners: [{ contactId: viewer?.id }] },
            quantity: 1,
          },
        },
      },
    });
    if (addLineItemResponse.errors && addLineItemResponse.errors.length > 0) {
      logError(addLineItemResponse.errors, "adding line item");
      return;
    }

    const placeOrderResponse = await placeOrder({
      variables: { input: { cartId } },
    });
    if (placeOrderResponse.errors && placeOrderResponse.errors.length > 0) {
      logError(placeOrderResponse.errors, "placing order");
      return;
    }

    const loadRegistrationResponse = await refetchRegistrations();

    const toNewRegistrationRoute = checkRegistrationAndNavigate(
      loadRegistrationResponse.data,
      event,
    );
    if (toNewRegistrationRoute) {
      history.push(toNewRegistrationRoute);
    } else {
      console.error("event", event);
      logError(loadRegistrationResponse.data, "redirecting to registration");
    }
  };

  const logError = (error: any, message: string) => {
    console.error(`encountered an error while ${message}`);
    console.error(error);
    setApiError(true);
    setRegistering(false);
  };

  return (
    <Fragment>
      {(createCartMutationError ||
        mutationPlaceOrderError ||
        apiError ||
        addCartLineItemMutationError ||
        registerablesError) && (
        <Alert
          type="error"
          message={t("anErrorOccurredWhileCreatingYourCart")}
          glyph="removeSign"
          overPage={false}
        />
      )}
      {registerablesLoading ? (
        <ListCell loading />
      ) : (
        <Button
          id="auto-register-start"
          label={t("startCourse")}
          type="primary"
          onClick={() => handleAutoRegisterToCourse(cartInput, event)}
          disabled={registering}
        ></Button>
      )}
    </Fragment>
  );
};

export default AutoRegister;
