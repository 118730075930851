import React, { FunctionComponent } from "react";
import { ListRow, ListCell, SafeHTMLDisplay } from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { ContentListProps } from "./ContentList";
import { OptionalContentPrompt } from "./OptionalContentPrompt";
import { ObjectiveStatus } from "@administrate/piston-ux";
import { RemainingAttempts } from "./RemainingAttempts";
import {
  TrainingPassContentStatusLabel,
  getTrainingPassTypesForContent,
} from "../TrainingPass/ContentStatusLabel";

export const CourseDetailList: FunctionComponent<ContentListProps> = ({
  passTypes,
  hasLockedPremiumContent,
  includedInPassTypeIds,
  contentId,
  learnerPasses,
  contentLocked,
  typeLabel,
  type,
  name,
  description,
  isRequired,
  lastAccessed,
  results,
  remainingAttempts,
  instructions,
  externalActivityInstructions,
  hasUrl,
  dataLabel,
}) => {
  const { t } = useTranslation();
  const desc = courseDescriptionForType(
    type,
    description,
    instructions,
    externalActivityInstructions,
  );
  const ariaLabel = `${
    hasUrl && (!contentLocked || hasLockedPremiumContent) ? "link," : ""
  } ${typeLabel}, `;
  return (
    <ListRow
      extraClass="content-row"
      disabled={contentLocked || hasLockedPremiumContent}
      role={hasUrl ? "" : "listitem"}
      dataLabel={dataLabel}
      extra={
        hasLockedPremiumContent &&
        learnerPasses && (
          <TrainingPassContentStatusLabel
            courseCardId={contentId}
            learnerPasses={learnerPasses}
            contentPasses={getTrainingPassTypesForContent(
              passTypes,
              includedInPassTypeIds,
            )}
            hasPricing={false}
          />
        )
      }
    >
      <ListCell extraClass="content-type">
        <span
          className={`glyphicon glyphicon-${onlineTypeToGlyph(
            type,
          )} glyphicon-lg`}
        ></span>
      </ListCell>
      <ListCell extraClass="content-name">
        {name && (
          <p
            className="mt-0 course-name font-weight-bold"
            aria-label={`${ariaLabel} ${name}`}
          >
            {name}
          </p>
        )}
        {!name && description && (
          <div
            className="mb-3"
            aria-label={!name ? `${ariaLabel} ${description}` : undefined}
          >
            {description}
          </div>
        )}
        {(lastAccessed || !isRequired) && (
          <p style={{ fontSize: 11.5 }}>
            {!isRequired && <OptionalContentPrompt />}
            {lastAccessed && !isRequired && <span className="mx-2">•</span>}
            {lastAccessed && (
              <span>
                {t("lastAccessed")}
                {": "}
                {lastAccessed}
              </span>
            )}
          </p>
        )}
        {desc && (
          <div className="mb-2">
            <SafeHTMLDisplay html={desc} />
          </div>
        )}
      </ListCell>
      {results && (
        <ListCell extraClass="content-meta">
          <div className="text-left text-lg-right">
            {!hasLockedPremiumContent && (
              <div>
                <ObjectiveStatus results={results} type={type} />
              </div>
            )}
            {remainingAttempts !== undefined && (
              <div className="mb-0 mt-3 mb-md-3">
                <RemainingAttempts
                  remainingAttempts={remainingAttempts}
                  contentLocked={contentLocked}
                />
              </div>
            )}
          </div>
        </ListCell>
      )}
    </ListRow>
  );
};

export const onlineTypeToGlyph = (type: string) => {
  switch (type) {
    case "video":
      return "play";
    case "document":
    case "resource":
      return "file";
    case "scorm":
      return "blackboard";
    case "presentation":
      return "blackboard";
    case "classroom":
      return "user";
    case "quiz":
      return "ok";
    case "kryterion":
    case "external":
      return "new-window";
    case "discussion":
      return "comment";
    default:
      return null;
  }
};

export const courseDescriptionForType = (
  type: string,
  description?: string,
  instructions?: string,
  externalActivityInstructions?: string,
) => {
  switch (type) {
    case "kryterion":
    case "discussion":
      return instructions;
    case "external":
      return externalActivityInstructions;
    default:
      return description;
  }
};
