import React from "react";
import { useGlobalStore } from "./GlobalStore";
import { Auth0Provider, useAuth0 } from "../react-auth0-wrapper";
import { LoadingScreen } from "@administrate/piston-ux";
import { handlePreview } from "../utils/previewHelpers";

const onRedirectCallback = (appState: any) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
};

export const AuthProvider: React.FunctionComponent = ({ children }) => {
  const globalStore = useGlobalStore();
  return (
    <Auth0Provider
      domain={globalStore.config.idp.domain}
      issuer={globalStore.config.brand_details.issuer}
      client_id={globalStore.config.idp.client_id}
      redirect_uri={window.location.href}
      onRedirectCallback={onRedirectCallback}
      returnTo={window.location.href}
      brand={globalStore.config.brand}
      leeway={300} // 5 minutes
      useRefreshTokens={true}
      cacheLocation={"localstorage"}
    >
      <LoginCheck>{children}</LoginCheck>
    </Auth0Provider>
  );
};

const LoginCheck: React.FunctionComponent = ({ children }) => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const globalStore = useGlobalStore();

  const { previewJWT, isPreviewer } = handlePreview(user);

  if (isAuthenticated && previewJWT && !isPreviewer) {
    logout({
      redirect_uri: window.location.href,
      brand: globalStore.config.brand,
    });
  }

  if (!isAuthenticated) {
    if (previewJWT) {
      loginWithRedirect({ preview_jwt: previewJWT });
    } else {
      loginWithRedirect();
    }
    return <LoadingScreen />;
  }
  return <>{children}</>;
};
