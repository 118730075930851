import React from "react";
import { AlertModal } from "@administrate/piston-ux";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { LearnerSelection } from "./../Catalog/LearnerSelection";
import { useParams, useHistory } from "../../useHistory";
import { useWebLinkQuery } from "../../hooks/weblink";
import { WEBLINK_LEARNING_PATH_DETAILS_QUERY } from "../../queries/learningPaths";
import {
  Query,
  PointOfSaleFieldEntityType,
} from "../../generated/weblinkTypes";
import { GET_CART } from "../../queries/cart";
import { CourseObjectiveOption } from "../../types/LearningPaths";
import { LearnerDetailsContainer } from "../Order/LearnerDetailsContainer";
import { BookerIntention } from "../Order/BookingPage";
import { useBookerIntention } from "../../hooks/useBookerIntention";

type RemainingPlacesCountType = {
  courseName: string;
  remainingPlaces: number | null;
};
export const LearningPathLearnerSelection = observer(() => {
  const { t } = useTranslation();
  const { learningPathId, cartId } = useParams<{learningPathId:string, cartId:string}>();

  const [bookerIntention, setBookerIntention] = useBookerIntention();
  if (bookerIntention !== BookerIntention.Coordinating) {
    setBookerIntention(BookerIntention.Coordinating);
  }

  const history = useHistory();

  let maxLearners: number | null = null;

  let objectiveOptions: CourseObjectiveOption[] = [];

  const pathObjectivesJSON = localStorage.getItem(cartId);
  let invalidObjectives = pathObjectivesJSON === null;

  if (pathObjectivesJSON) {
    objectiveOptions = JSON.parse(pathObjectivesJSON);
  }

  let courseObjectives: CourseObjectiveOption[] = [];
  if (!objectiveOptions) {
    invalidObjectives = true;
  }
  courseObjectives = objectiveOptions.filter(
    (objective: CourseObjectiveOption) => objective.objective.type === "Course",
  );
  const remainingPlacesCounts = courseObjectives.map(
    (objective: CourseObjectiveOption) => ({
      courseName: objective.objective.name,
      remainingPlaces: objective.objective.event.remainingPlaces,
    }),
  );
  remainingPlacesCounts.forEach((count: RemainingPlacesCountType) => {
    if (count.remainingPlaces !== null) {
      if (maxLearners === null || count.remainingPlaces < maxLearners) {
        maxLearners = count.remainingPlaces;
      }
    }
  });

  const getMaxLearnersMessages = (learnerAmount: number) => {
    const messages: string[] = [];
    remainingPlacesCounts.forEach((count: RemainingPlacesCountType) => {
      if (count.remainingPlaces && count.remainingPlaces < learnerAmount) {
        messages.push(
          `The selected event for the course ${
            count.courseName
          } only has space for ${count.remainingPlaces} more learner${
            count.remainingPlaces > 1 ? "s" : ""
          }.`,
        );
      }
    });
    return messages;
  };

  const { data: pathsResponse, loading: pathLoading } = useWebLinkQuery<Query>(
    WEBLINK_LEARNING_PATH_DETAILS_QUERY,
    {
      variables: { id: learningPathId },
    },
  );

  const path = pathsResponse?.learningPaths?.edges?.[0]?.node;

  const { data: cartResponse, loading: cartLoading } = useWebLinkQuery<Query>(
    GET_CART,
    {
      variables: { id: cartId },
    },
  );

  const cart = cartResponse && cartResponse.cart;

  const fullCart = !cartLoading && cart && cart.items && cart.items.length > 0;
  const wonCart =
    !cartLoading && cart && (cart.state === "won" || cart.invoiceId !== null);
  const showAlert = !cartLoading && (invalidObjectives || fullCart || wonCart);

  const getAlertTitle = () => {
    if (wonCart) {
      return t("This booking is already confirmed");
    }
    if (fullCart) {
      return t("This booking already has learners assigned to it");
    }
    return `${t(
      "The objectives for this booking are not configured properly or you have reached this page in an unexpected way",
    )}. ${t("Please restart your booking")}.`;
  };

  const getRedirectUrl = () => {
    if (wonCart) {
      return `/catalog/${BookerIntention.Coordinating}/booking/${
        cart && cart.id
      }/confirmation`;
    }
    if (fullCart) {
      return `/catalog/${BookerIntention.Coordinating}/booking/${
        cart && cart.id
      }`;
    }
    return `/catalog/${BookerIntention.Coordinating}/learning-path/${
      path && path.id
    }`;
  };

  return (
    <LearnerDetailsContainer loading={pathLoading || cartLoading} path={path}>
      <AlertModal
        title={getAlertTitle()}
        show={!!showAlert}
        onDone={() => history.push(getRedirectUrl())}
        type="warningWithoutCancel"
      />
      <LearnerSelection
        cartId={cart && cart.id}
        product={path}
        loading={pathLoading || cartLoading}
        pointOfSaleFieldVariables={{
          entityType: PointOfSaleFieldEntityType.CartLineItemPathLearner,
        }}
        learningPathObjectivesInput={courseObjectives.map(
          (objective: CourseObjectiveOption) => ({
            eventId: objective.objective.event.eventId,
            objectiveId: objective.id,
          }),
        )}
        maxLearners={maxLearners}
        getMaxLearnersReachedMessages={getMaxLearnersMessages}
        invalidStateAlertVisible={!!showAlert}
      />
    </LearnerDetailsContainer>
  );
});
