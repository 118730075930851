import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Maybe } from "../generated/lmsTypes";
import { useViewer } from "../providers/ViewerProvider";
import { initAnalytics, sendPageView } from "./../analytics";

export function useGoogleAnalytics(
  userId: Maybe<string> | undefined,
  instance: string,
  enabled: boolean,
) {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const { viewer } = useViewer();

  React.useEffect(() => {
    if (
      !initialized &&
      (userId || viewer?.isPreviewer) &&
      instance &&
      enabled
    ) {
      initAnalytics(userId, instance);
      setInitialized(true);
    }
  }, [initialized, userId, viewer, instance, enabled]);

  React.useEffect(() => {
    if (initialized) {
      const currentPath = location.pathname + location.search;
      sendPageView(currentPath);
    }
  }, [location, initialized]);
}
