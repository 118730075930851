import React, { FunctionComponent } from "react";
import { Typeahead } from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { ContactNode } from "../types/LearnerManagement";
import { getUniqueId } from "../utils/displayHelpers";

type ContactPickerType = {
  name: string;
  loadOptions(inputValue: string): Promise<any>;
  onChange(newValue: any, oldValue: any): void;
  valid?(value: string): boolean | string;
  disabled?: boolean;
};

export const ContactPicker: FunctionComponent<ContactPickerType> = ({
  name,
  loadOptions,
  onChange,
  valid = () => true,
  disabled = false,
}) => {
  const { t } = useTranslation();
  return (
    <Typeahead
      label={t("learner")}
      name={name}
      loadOptions={loadOptions}
      formatter={(contact: ContactNode) =>
        contact.id === "unnamed"
          ? t("unnamedLearner")
          : `${contact.firstName} ${contact.lastName} (${contact.emailAddress})`
      }
      onChange={onChange}
      valid={valid}
      disabled={disabled}
      uniqueId={getUniqueId()}
    />
  );
};

export const getContactVariables = (inputValue: string) => ({
  filters: [
    {
      field: "name",
      operation: "like",
      value: `%${inputValue}%`,
    },
    {
      field: "name",
      operation: "ne",
      value: "",
    },
  ],
});
