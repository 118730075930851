import React, {
  useState,
  useCallback,
  useMemo,
  FunctionComponent,
  Fragment,
} from "react";
import { Card, Col, FutureListPage, GridRow } from "@administrate/piston-ux";
import { Link } from "react-router-dom";
import { range } from "lodash";
import { useTranslation } from "react-i18next";
import { ColorOptions } from "@administrate/piston-ux/lib/types";

import { CATALOGUE_QUERY } from "../../queries/catalogue";
import {
  CourseCatalogActionsBar,
  CourseCatalogActionBarFilters,
} from "./CourseCatalogActionsBar";
import { Category } from "../../toPistonUX/ActionsBar/types";
import { PageInfo } from "../../types/PageInfo";
import { ScrollTrigger } from "../../ScrollTrigger";
import { useGlobalStore } from "../../providers/GlobalStore";
import coursePlaceholderSquare from "../../images/course-placeholder_square.png";
import { useLmsQuery } from "../../hooks/lms";

type CatalogueResponse = {
  catalogue: {
    pageInfo: PageInfo;
    edges: Array<CatalogueEdge>;
  };
};

type CatalogueEdge = {
  node: CatalogueNode;
};

type CatalogueNode = {
  id: string;
  imageUrl: string;
  name: string;
  category: Category;
};

export const CourseCatalog: React.FunctionComponent = () => {
  const { t } = useTranslation();
  // const [isActive, setIsActive] = useState(false);
  const [actionBarFilters, setActionBarFilters] =
    useState<CourseCatalogActionBarFilters>({
      searchBar: "",
      category: null,
    });
  const filters = useMemo(() => {
    const value = [];
    if (actionBarFilters.searchBar) {
      value.push({
        value: `%${actionBarFilters.searchBar}%`,
        operation: "like",
        field: "name",
      });
    }

    if (actionBarFilters.category) {
      value.push({
        value: actionBarFilters.category.id,
        operation: "eq",
        field: "learningCategoryId",
      });
    }
    return value;
  }, [actionBarFilters]);
  const {
    loading: isCatalogueLoading,
    data: catalogueResponse,
    fetchMore: fetchMoreCatalogue,
  } = useLmsQuery<CatalogueResponse>(CATALOGUE_QUERY, {
    variables: {
      offset: 0,
      filters,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });
  const hasNextPage =
    catalogueResponse?.catalogue?.pageInfo?.hasNextPage ?? false;
  const actionsBarChange = useCallback(
    filters => setActionBarFilters(filters),
    [setActionBarFilters],
  );

  const loadNextPage = useCallback(() => {
    fetchMoreCatalogue({
      variables: { offset: catalogueResponse?.catalogue?.edges?.length || 0 },
      updateQuery: (
        prev: {
          catalogue: {
            pageInfo: PageInfo;
            edges: Array<CatalogueEdge>;
          };
        },
        { fetchMoreResult },
      ) => {
        return fetchMoreResult
          ? {
              ...prev,
              catalogue: {
                ...prev.catalogue,
                pageInfo: fetchMoreResult.catalogue.pageInfo,
                edges: [
                  ...prev.catalogue.edges,
                  ...fetchMoreResult.catalogue.edges,
                ],
              },
            }
          : prev;
      },
    });
  }, [fetchMoreCatalogue, catalogueResponse]);

  const { convertLMSImageUrl } = useGlobalStore();

  const edges = catalogueResponse?.catalogue?.edges || [];

  return (
    <FutureListPage title={t("catalog")} type="grid">
      <CourseCatalogActionsBar onChange={actionsBarChange} />
      <h2 className="sr-only">{t("results")}</h2>
      <GridRow>
        {edges.map((edge, key) => (
          <Col sm={6} md={4} lg={3} key={key}>
            <Link
              to={`/course-catalog/${edge.node.id}`}
              className="no-underline"
            >
              <Card
                imageSource={convertLMSImageUrl(
                  edge.node?.imageUrl || coursePlaceholderSquare,
                )}
                label={{
                  color: ColorOptions.Green,
                  text: t("course"),
                  extraClass: "course-color",
                }}
              >
                {edge.node.category && (
                  <span className="font-weight-bold">
                    {edge.node?.category?.name}
                  </span>
                )}
                <h4 style={{ marginTop: 4 }}>{edge.node?.name}</h4>
              </Card>
            </Link>
          </Col>
        ))}
        {isCatalogueLoading && <LoadingPlaceholder />}
      </GridRow>
      {!isCatalogueLoading && hasNextPage && (
        <ScrollTrigger onEnter={loadNextPage} />
      )}
      {!hasNextPage && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p className="text-muted">{t("youveReachedTheEnd")}</p>
        </div>
      )}
    </FutureListPage>
  );
};

const LoadingPlaceholder: FunctionComponent = () => {
  const { t } = useTranslation();
  const { convertLMSImageUrl } = useGlobalStore();
  return (
    <Fragment>
      {range(4).map(i => (
        <Col sm={6} md={4} lg={3} key={`loading-${i}`}>
          <Card
            imageSource={convertLMSImageUrl(coursePlaceholderSquare)}
            label={{
              color: ColorOptions.Green,
              text: t("course"),
              extraClass: "course-color",
            }}
          >
            <span className="font-weight-bold placeholder animated placeholder-text-value" />
            <span className="placeholder animated placeholder-h4" />
          </Card>
        </Col>
      ))}
    </Fragment>
  );
};
