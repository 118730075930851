/* eslint-disable no-restricted-globals */
import React, { FunctionComponent, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useLmsMutation } from "../../hooks/lms";
import { RECORD_EXTERNAL_PROGRESS_STATUS } from "../../mutations/registrations";
import { useHistory, useParams } from "../../useHistory";
import { useGlobalStore } from "../../providers/GlobalStore";
import { useViewer } from "../../providers/ViewerProvider";
import { NoMatch } from "../NoMatch";
import { ExternalActivityStatus } from "../../generated/lmsTypes";

export const ExternalAttempt: FunctionComponent = () => {
  const { attemptId: urlAttemptId } = useParams<{attemptId:string}>();
  const history = useHistory();
  const globalStore = useGlobalStore();
  const { viewer } = useViewer();

  const [error, setError] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");
  const [redirectPath, setRedirectPath] = useState("");

  const [recordExternalProgressStatus] = useLmsMutation(
    RECORD_EXTERNAL_PROGRESS_STATUS,
  );

  useEffect(() => {
    if (top !== self || error || !viewer) {
      return;
    }

    const searchParams = new URLSearchParams(history.location.search);
    const encodedAttemptId =
      urlAttemptId ||
      searchParams.get("adm_attempt_id") ||
      searchParams.get("attempt_id");
    const attemptId = decodeURIComponent(encodedAttemptId!);

    const passed = searchParams.get("passed");
    let status: ExternalActivityStatus;
    if (passed === "true") {
      status = ExternalActivityStatus.Passed;
    } else if (passed === "false") {
      status = ExternalActivityStatus.Failed;
    } else {
      status = ExternalActivityStatus.Completed;
    }

    recordExternalProgressStatus({
      variables: {
        attemptId,
        status,
      },
    })
      .then(({ data }) => {
        const response = data.attempt.recordExternalProgress;
        if (isEmpty(response.errors)) {
          const {
            attempt: { registration, content },
          } = response;
          history.push(
            `/my-courses/course/${registration.id}/content/${content.id}`,
          );
        } else {
          setErrorTitle("errorRequestFailed");
          if (!globalStore.token) {
            setButtonLabel("errorLoginButton");
            setRedirectPath("/");
          } else if (viewer?.isPreviewer) {
            setButtonLabel("errorPreviewButton");
            setRedirectPath("/preview");
          } else {
            setButtonLabel("errorTitleButton");
            setRedirectPath("/my-courses");
          }
          setError(true);
        }
      })
      .catch(console.error);
  }, [
    error,
    history,
    recordExternalProgressStatus,
    urlAttemptId,
    globalStore.token,
    viewer,
  ]);

  // Bust out of any nested iFrames from the original content page
  if (top !== self && top) {
    top.location = window.location;
    return null;
  }

  if (error) {
    return (
      <NoMatch
        title={errorTitle}
        description=" "
        buttonLabel={buttonLabel}
        buttonPath={redirectPath}
      />
    );
  }

  return null;
};
