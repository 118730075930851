import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

export const OptionalContentPrompt: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <span className="optional-content">
      <span className="glyphicon glyphicon-info-sign pr-2"></span>
      {t("optional")}
    </span>
  );
};
