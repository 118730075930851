import React, { useState, FunctionComponent, useRef, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { NavLink, useLocation } from "react-router-dom";

import { classNames } from "./toPistonUX/classNames";
import { useGlobalStore } from "./providers/GlobalStore";
import { usePortal } from "./providers/PortalProvider";
import { useViewer } from "./providers/ViewerProvider";
import { useTranslation } from "react-i18next";
import { BookerIntention } from "./pages/Order/BookingPage";
import { ExternalLink } from "./generated/lmsTypes";
import { useOnClickOutside } from "./hooks/useOnClickOutside";
import { useOnKeyHit } from "./hooks/useOnKeyHit";
import { useOnTabOut } from "./hooks/useOnTabOut";

export const SideBar: FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const globalState = useGlobalStore();
  const { viewer } = useViewer();
  const [showBackdrop, setShowBackdrop] = useState(globalState.sideBarOpen);
  const [showSideBar, setShowSideBar] = useState(globalState.sideBarOpen);

  const {
    externalLinks,
    isPortalLoading,
    isApprovalPortal,
    isBookingPortal,
    isSelfRegistrationPortal,
  } = usePortal();

  const allowCoordinatorFunctionality =
    !!viewer && viewer.isCoordinator && isBookingPortal;
  const allowRegistrationFunctionality =
    allowCoordinatorFunctionality || isSelfRegistrationPortal;

  if (showBackdrop !== globalState.sideBarOpen) {
    if (globalState.sideBarOpen === true) {
      setShowBackdrop(globalState.sideBarOpen);
    } else {
      setTimeout(() => {
        setShowBackdrop(globalState.sideBarOpen);
      }, 150);
    }
  }
  if (showSideBar !== globalState.sideBarOpen) {
    setTimeout(() => {
      setShowSideBar(globalState.sideBarOpen);
    });
  }

  const handleClick = () => {
    globalState.sideBarOpen = false;
  };

  return (
    <nav aria-label={t("mainSiteNavigation")}>
      <div
        className={classNames("modal-backdrop", "fade", { in: showSideBar })}
        style={{
          zIndex: 1020,
          display: showBackdrop ? undefined : "none",
        }}
        onClick={handleClick}
      />
      <ul className={classNames("navbar-fixed-left", { open: showSideBar })}>
        <MenuOption
          to="/my-courses"
          icon="glyphicon-pro glyphicon-pro-education"
          label={t("myCourses")}
          onChange={handleClick}
          open={globalState.sideBarOpen}
          id="my-courses"
        />
        <MenuOption
          to="/my-achievements"
          icon="glyphicon-pro glyphicon-pro-certificate"
          label={t("myAchievements")}
          onChange={handleClick}
          open={globalState.sideBarOpen}
          id="my-achievements"
        />
        {isApprovalPortal && (
          <MenuOption
            to="/course-catalog"
            icon="glyphicon-pro glyphicon-pro-book"
            label={t("catalog")}
            onChange={handleClick}
            open={globalState.sideBarOpen}
            id="course-catalog"
          />
        )}
        {isApprovalPortal && (
          <MenuOption
            to="/my-requests"
            icon="glyphicon-pro glyphicon-pro-check"
            label={t("myRequests")}
            onChange={handleClick}
            open={globalState.sideBarOpen}
            id="my-requests"
          />
        )}
        {allowCoordinatorFunctionality && (
          <MenuOption
            to="/learner-management"
            icon="glyphicon-pro glyphicon-pro-user-group"
            label={t("learnerManagement")}
            onChange={handleClick}
            open={globalState.sideBarOpen}
            id="learner-management"
          />
        )}
        {allowRegistrationFunctionality && (
          <CatalogMenuOption
            allowCoordinatorFunctionality={allowCoordinatorFunctionality}
            isSelfRegistrationPortal={isSelfRegistrationPortal}
            onChange={handleClick}
          />
        )}
        {!isPortalLoading &&
          (externalLinks as ExternalLink[])?.map(
            ({ displayText, href, iconName, position }: ExternalLink) => (
              <li key={`externalLink-${position}`}>
                <a href={href ?? undefined}>
                  <span
                    className={`glyphicon-pro glyphicon-pro-${iconName?.replace(
                      "glyphicon-",
                      "",
                    )}`}
                  />
                  <span
                    className={`navbar-fixed-left-label ${
                      globalState.sideBarOpen ? "display-block" : "sr-only"
                    }`}
                  >
                    {displayText}
                  </span>
                </a>
              </li>
            ),
          )}
      </ul>
    </nav>
  );
});

const MenuOption: FunctionComponent<{
  onChange(): void;
  open: boolean;
  to: string;
  label: string;
  id: string;
  icon?: string;
  className?: string;
}> = ({ onChange, to, label, icon, open, id }) => {
  return (
    <li onClick={onChange} onKeyPress={onChange}>
      <NavLink to={to} id={id}>
        {icon && <span className={icon} />}
        <span
          className={`navbar-fixed-left-label ${
            open ? "display-block" : "sr-only"
          }`}
        >
          {label}
        </span>
      </NavLink>
    </li>
  );
};

type DropDownOptionDetailType = {
  label: string;
  to: string;
  onChange(): void;
  id: string;
};

const MenuOptionWithDropDown: FunctionComponent<{
  onChange(): void;
  open: boolean;
  dropDownOptionDetails: DropDownOptionDetailType[];
  label: string;
  icon: string;
  active: boolean;
  id: string;
}> = ({ onChange, dropDownOptionDetails, label, icon, open, active, id }) => {
  const { sideBarOpen } = useGlobalStore();
  const [expanded, setExpanded] = useState<boolean>(false);

  const ref = useRef<HTMLLIElement>(null);
  const closeDropDown = useCallback(() => setExpanded(false), []);
  useOnClickOutside(ref, closeDropDown);
  useOnTabOut(ref, closeDropDown);
  useOnKeyHit(ref, closeDropDown, "Escape");

  const componentId = `${id}-menu`;
  const submenuId = `${componentId}__submenu`;

  const dropDownOptions = dropDownOptionDetails.map(
    (optionDetail: DropDownOptionDetailType) => (
      <MenuSubOption
        key={optionDetail.label}
        to={optionDetail.to}
        label={optionDetail.label}
        onClick={optionDetail.onChange}
        id={`${submenuId}-${optionDetail.id}`}
      />
    ),
  );

  const handleClick = () => {
    setExpanded(!expanded);
    !sideBarOpen && onChange();
  };

  return (
    <li
      id={componentId}
      onClick={handleClick}
      onKeyPress={handleClick}
      tabIndex={0}
      role="button"
      ref={ref}
      aria-expanded={expanded}
      aria-controls={submenuId}
      aria-haspopup="true"
    >
      <div
        className={`navbar-fixed-left__dropdown-toggle ${
          active ? "active" : ""
        }`}
      >
        <span className={icon} />
        <span
          className={`navbar-fixed-left-label ${
            open ? "display-block" : "sr-only"
          }`}
        >
          {label}
        </span>
        <span
          className={`navbar-fixed-left__dropdown-caret glyphicon-pro glyphicon-pro-chevron-${
            expanded ? "up" : "down"
          }`}
        />
      </div>
      {expanded && (
        <ul
          className={`navbar-fixed-left__dropdown-content ${
            open ? "navbar-fixed-left__dropdown-content--open" : ""
          }`}
          id={submenuId}
        >
          {dropDownOptions}
        </ul>
      )}
    </li>
  );
};

const MenuSubOption: FunctionComponent<{
  onClick(): void;
  to: string;
  label: string;
  id: string;
  className?: string;
}> = ({ onClick, to, label, id }) => {
  return (
    <li onClick={onClick} onKeyPress={onClick}>
      <NavLink to={to} id={id}>
        <span className="navbar-fixed-left-label">{label}</span>
      </NavLink>
    </li>
  );
};

const CatalogMenuOption: FunctionComponent<{
  onChange(): void;
  allowCoordinatorFunctionality: boolean;
  isSelfRegistrationPortal: boolean;
}> = ({
  onChange,
  allowCoordinatorFunctionality,
  isSelfRegistrationPortal,
}) => {
  const { t } = useTranslation();
  const globalState = useGlobalStore();
  const location = useLocation();
  if (allowCoordinatorFunctionality && isSelfRegistrationPortal) {
    const options: DropDownOptionDetailType[] = [
      {
        label: t("coordinatorCatalog"),
        to: `/catalog/${BookerIntention.Coordinating}`,
        onChange: onChange,
        id: BookerIntention.Coordinating,
      },
      {
        label: t("myCatalog"),
        to: `/catalog/${BookerIntention.Self}`,
        onChange: onChange,
        id: BookerIntention.Self,
      },
    ];

    return (
      <MenuOptionWithDropDown
        dropDownOptionDetails={options}
        icon="glyphicon-pro glyphicon-pro-book"
        label={t("catalog")}
        onChange={onChange}
        open={globalState.sideBarOpen}
        active={location.pathname.indexOf("/catalog") > -1}
        id="catalog"
      />
    );
  }

  return (
    <MenuOption
      to="/catalog"
      icon="glyphicon-pro glyphicon-pro-book"
      label={t("catalog")}
      onChange={() => (globalState.sideBarOpen = false)}
      open={globalState.sideBarOpen}
      id="catalog"
    />
  );
};
