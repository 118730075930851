import React, { useState, useCallback, useMemo } from "react";
import {
  Container,
  Card,
  Alert,
  Header,
  Col,
  Row,
  Button,
  SafeHTMLDisplay,
} from "@administrate/piston-ux";
import { Well } from "react-bootstrap";

import { useHistory, useParams } from "../../useHistory";
import { TRAINING_REQUESTS_QUERY } from "../../queries/trainingRequests";
import { COURSE_DETAILS_QUERY } from "../../queries/catalogue";
import { BookTrainingModal } from "../../components/BookTrainingModal";
import { EventsTable } from "../../components/EventsTable";
import { CatalogueResponse } from "../../types/Catalogue";
import { TrainingRequestsResponse } from "../../types/TrainingRequest";
import { useGlobalStore } from "../../providers/GlobalStore";
import coursePlaceholder from "../../images/course-placeholder.png";
import { useDateFormatter } from "../../hooks/useDateFormatter";
import { useLmsQuery } from "../../hooks/lms";
import { Maybe } from "../../toPistonUX/ActionsBar/types";
import { useTranslation } from "react-i18next";
import { ColorOptions } from "@administrate/piston-ux/lib/types";

export const CourseCatalogDetail: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { id } = useParams<{id:string}>();

  const history = useHistory();

  // Modal
  const [showModal, setShowModal] = useState(false);
  const [preSelectedEvent, setPreSelectedEvent] =
    useState<
      Maybe<{
        id: string;
        location: string;
      }>
    >(null);
  const onModalDone = useCallback(
    ({
      success,
      selectedEventId,
    }: {
      success: boolean;
      selectedEventId?: string;
    }) => {
      setShowModal(false);
      if (success) {
        history.push(`/my-requests/${selectedEventId}/training-request`);
      }
    },
    [history],
  );

  const onModalShow = useCallback(
    ({
      preSelectedEvent,
    }: { preSelectedEvent?: { id: string; location: string } } = {}) => {
      setPreSelectedEvent(preSelectedEvent || null);
      setShowModal(true);
    },
    [],
  );

  // Course details
  const { loading: courseDetailsIsLoading, data: courseDetailsResponse } =
    useLmsQuery<CatalogueResponse>(COURSE_DETAILS_QUERY, {
      variables: { id },
      fetchPolicy: "cache-and-network",
    });

  const courseDetails = courseDetailsResponse?.catalogue?.edges?.[0]?.node;
  const courseCode = courseDetails?.code || "";
  const rawOutcomes = courseDetails?.outcomes || "";
  const outcomes = useMemo(
    () =>
      rawOutcomes && rawOutcomes.match(/<p>|<\/p>|<ul>|<\/ul>|<li>|<\/li>|\n/)
        ? rawOutcomes
            .split(/<p>|<\/p>|<ul>|<\/ul>|<li>|<\/li>|\n/)
            .filter(item => item !== "")
        : [],
    [rawOutcomes],
  );
  const description = courseDetails?.description || "";
  const topicsCovered = courseDetails?.topicsCovered || "";
  const prerequisites = courseDetails?.prerequisites || "";

  const { convertLMSImageUrl } = useGlobalStore();

  // Related Training Request
  const { dateFormat } = useDateFormatter();
  const { data: trainingRequestsResponse } =
    useLmsQuery<TrainingRequestsResponse>(TRAINING_REQUESTS_QUERY, {
      variables: {
        filters: { field: "courseTemplateId", operation: "eq", value: id },
        order: { field: "updatedAt", direction: "asc" },
      },
    });

  const lastTrainingRequest =
    trainingRequestsResponse?.trainingRequests?.edges?.[0]?.node;
  const lastTrainingRequestDate = lastTrainingRequest?.createdAt;

  const approvalTagFromState = (
    state?: string,
  ): { text: string; color: ColorOptions } => {
    switch (state) {
      case "Won":
        return { text: t("approved"), color: ColorOptions.Green };
      case "Lost":
        return { text: t("rejected"), color: ColorOptions.LightGrey };
      case "Archived":
        return { text: t("waitList"), color: ColorOptions.Blue };
      default:
        return { text: t("awaitingApproval"), color: ColorOptions.Yellow };
    }
  };

  const lastTrainingRequestStatus = approvalTagFromState(
    lastTrainingRequest?.state,
  ).text;

  return (
    <div className="page">
      <Header
        usage="portal"
        title={
          courseDetailsIsLoading
            ? ((
                <span
                  className="placeholder animated"
                  style={{ maxWidth: "300px" }}
                />
              ) as any)
            : courseDetails?.name || ""
        }
      >
        <Row>
          <Col md={8}>
            {courseDetailsIsLoading ? (
              <p>
                <span
                  className="placeholder animated"
                  style={{ maxWidth: "500px" }}
                />
              </p>
            ) : (
              <SafeHTMLDisplay html={courseDetails?.teaserDescription || ""} />
            )}
          </Col>
        </Row>
      </Header>
      <Container>
        <Row>
          <Col md={4} mdPush={8}>
            <Card
              imageSource={convertLMSImageUrl(
                courseDetails?.imageUrl || coursePlaceholder,
              )}
              label={{
                text: t("course"),
                color: ColorOptions.Green,
                extraClass: "course-color",
              }}
            >
              <Button
                block
                type="primary"
                onClick={() => onModalShow()}
                disabled={courseDetailsIsLoading || !courseCode}
                label={t("requestTraining")}
              />
              {/* COMMENT(Roland):
                Had to unmount BookTrainingModal when showModal is false
                Because if I only switch a show prop it will not use the new preSelectedEvent
                (Piston does not provide a way to update the Forms Context values, you can only initialize one)
                Consequence: the modal will fetch the events every time you open it.
                - This is not a big issues since the user should probably open the modal only once.
              */}
              {showModal && (
                <BookTrainingModal
                  onDone={onModalDone}
                  title={courseDetails?.name || ""}
                  courseCode={courseCode}
                  preSelectedEvent={preSelectedEvent}
                />
              )}
            </Card>
          </Col>
          <Col md={8} mdPull={4}>
            {lastTrainingRequest && (
              <Alert
                glyph="infoSign"
                type="warning"
                message={`${t("youAppliedForThisCourseOn")} ${
                  lastTrainingRequestDate
                    ? dateFormat({ date: lastTrainingRequestDate })
                    : t("unknownDate")
                }. ${t("itIsCurrently")} ${lastTrainingRequestStatus}.`}
              />
            )}
            {(outcomes.length || courseDetailsIsLoading) && (
              <Well className="clearfix">
                <h2>{t("whatYoullLearn")}</h2>
                <ul className="two-up">
                  {courseDetailsIsLoading ? (
                    <span className="placeholder animated" />
                  ) : (
                    outcomes.map((item, index) => (
                      <li className="half-item" key={index}>
                        <SafeHTMLDisplay html={item} />
                      </li>
                    ))
                  )}
                </ul>
              </Well>
            )}
            {(courseDetailsIsLoading || description) && (
              <>
                <h2>{t("description")}</h2>
                {courseDetailsIsLoading ? (
                  <span
                    className="placeholder animated"
                    style={{ maxWidth: "500px" }}
                  />
                ) : (
                  <SafeHTMLDisplay html={description} />
                )}
              </>
            )}
            {(courseDetailsIsLoading || topicsCovered) && (
              <>
                <h2>{t("topicsCovered")}</h2>
                {courseDetailsIsLoading ? (
                  <span
                    className="placeholder animated"
                    style={{ maxWidth: "500px" }}
                  />
                ) : (
                  <SafeHTMLDisplay html={topicsCovered} />
                )}
              </>
            )}
            {(courseDetailsIsLoading || prerequisites) && (
              <>
                <h2>{t("prerequisites")}</h2>
                {courseDetailsIsLoading ? (
                  <span
                    className="placeholder animated"
                    style={{ maxWidth: "500px" }}
                  />
                ) : (
                  <SafeHTMLDisplay html={prerequisites} />
                )}
              </>
            )}
            <h2>{t("dates")}</h2>
            <EventsTable courseCode={courseCode} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
