import React, { FunctionComponent, useEffect, useState } from "react";
import { Modal } from "@administrate/piston-ux";
import { useViewer } from "../providers/ViewerProvider";
import { usePortal } from "../providers/PortalProvider";
import { useGlobalStore } from "../providers/GlobalStore";

export const WelcomeMessage: FunctionComponent = () => {
  const { viewer, markLearnerWelcomed } = useViewer();
  const { welcomeMessage } = usePortal();
  const { convertLMSImageUrl } = useGlobalStore();
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const [welcomeMessageShown, setWelcomeMessageShown] = useState(false);
  const markAsShown = () => {
    setShowWelcomeMessage(false);
    markLearnerWelcomed();
  };

  useEffect(() => {
    if (
      !welcomeMessageShown &&
      viewer &&
      typeof viewer.learnerWelcomed == "boolean" &&
      !viewer.learnerWelcomed &&
      welcomeMessage
    ) {
      setShowWelcomeMessage(true);
      setWelcomeMessageShown(true);
    }
  }, [viewer, welcomeMessage, welcomeMessageShown]);

  return (
    <Modal
      size="large"
      title="welcome message"
      show={showWelcomeMessage}
      onDone={markAsShown}
      primaryActionText={
        welcomeMessage?.buttonText ? welcomeMessage?.buttonText : undefined
      }
      showCancelButton={false}
      showTitle={false}
      imageSource={
        welcomeMessage?.imageUrl
          ? convertLMSImageUrl(welcomeMessage?.imageUrl)
          : undefined
      }
      className="welcome-message"
    >
      <div dangerouslySetInnerHTML={{ __html: welcomeMessage?.body || "" }} />
    </Modal>
  );
};
