import React, { FunctionComponent } from "react";
import { Route, Switch } from "react-router-dom";
import { BookerIntention } from "../Order/BookingPage";
import { ProtectedRoute } from "../../ProtectedRoute";
import { BrowseCatalog } from "./BrowseCatalog";
import { SelfRegistrationCatalog } from "./SelfRegistrationCatalog";
import { CoordinatorCatalog } from "./CoordinatorCatalog";
import { FindBookable } from "./FindBookable";
import { Detail } from "./Detail";
import { usePortal } from "../../providers/PortalProvider";
import { useViewer } from "../../providers/ViewerProvider";
import { EventLearnerSelection } from "./EventLearnerSelection";
import { LearningPathLearnerSelection } from "../LearningPaths/LearningPathLearnerSelection";
import { BookingDetailPage } from "../Order/BookingDetailPage";
import { LearningPath } from "../LearningPaths/LearningPath";
import { CatalogFilterContextProvider } from "./CatalogFilterContext";
import { DetailFilterContextProvider } from "./DetailFilterContext";
import { SideBar } from "../../SideBar";
import { MenuBar } from "../../MenuBar";
import { ProtectedCheckoutRoute } from "../../Routes";

export const CatalogRoute: FunctionComponent<{}> = () => {
  const { isBookingPortal, isSelfRegistrationPortal } = usePortal();
  const { viewer } = useViewer();

  const allowCoordinatorFunctionality =
    !!viewer && viewer.isCoordinator && isBookingPortal;
  const allowRegistrationFunctionality =
    allowCoordinatorFunctionality || isSelfRegistrationPortal;

  return (
    <CatalogFilterContextProvider>
      <DetailFilterContextProvider>
        <Switch>
          <ProtectedRoute
            path="/catalog/:catalogType/event/:eventId/booking/:cartId"
            exact
            component={EventLearnerSelection}
            condition={allowRegistrationFunctionality}
          />
          <ProtectedRoute
            path="/catalog/:catalogType/learning-path/:learningPathId/booking/:cartId"
            exact
            component={LearningPathLearnerSelection}
            condition={allowRegistrationFunctionality}
          />
          <ProtectedCheckoutRoute
            path="/catalog/:catalogType/booking/:bookingId"
            exact
            component={BookingDetailPage}
            condition={allowRegistrationFunctionality}
          />
          <CatalogRoutesWithNavigation />
        </Switch>
      </DetailFilterContextProvider>
    </CatalogFilterContextProvider>
  );
};

export const CatalogRoutesWithNavigation: FunctionComponent<{}> = () => {
  const { isBookingPortal, isSelfRegistrationPortal } = usePortal();
  const { viewer } = useViewer();

  const allowCoordinatorFunctionality =
    !!viewer && viewer.isCoordinator && isBookingPortal;
  const allowRegistrationFunctionality =
    allowCoordinatorFunctionality || isSelfRegistrationPortal;

  return (
    <CatalogFilterContextProvider>
      <DetailFilterContextProvider>
        <MenuBar />
        {!viewer?.isPreviewer && <SideBar />}
        <main className={viewer?.isPreviewer ? "preview" : "general"}>
          <Switch>
            <ProtectedRoute
              path={`/catalog/${BookerIntention.Self}`}
              exact
              component={SelfRegistrationCatalog}
              condition={allowRegistrationFunctionality}
            />
            <ProtectedRoute
              path={`/catalog/${BookerIntention.Coordinating}`}
              exact
              component={CoordinatorCatalog}
              condition={allowRegistrationFunctionality}
            />
            <ProtectedRoute
              path="/catalog/:catalogType/find"
              exact
              component={FindBookable}
              condition={allowRegistrationFunctionality}
            />
            <ProtectedRoute
              path="/catalog/:catalogType/course/:id"
              exact
              component={Detail}
              condition={allowRegistrationFunctionality}
            />
            <ProtectedRoute
              path="/catalog/:catalogType/learning-path/:learningPathId"
              exact
              component={LearningPath}
              condition={allowRegistrationFunctionality}
            />
            <Route component={BrowseCatalog} />
          </Switch>
        </main>
      </DetailFilterContextProvider>
    </CatalogFilterContextProvider>
  );
};
