import gql from "graphql-tag";
import {
  PAGE_INFO_FRAGMENT,
  EVENT_FRAGMENT,
  WEBLINK_EVENT_FRAGMENT,
} from "./fragments";

export const EVENTS_FOR_APPROVAL_PORTAL_COURSE_QUERY = gql`
  query getEventsForCourse(
    $courseCode: String
    $first: Int
    $offset: Int
    $minDate: String
  ) {
    events(
      filters: [
        { field: courseCode, operation: eq, value: $courseCode }
        { field: registerBy, operation: gt, value: $minDate }
      ]
      first: $first
      offset: $offset
      order: { field: start, direction: asc }
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          ...Event
        }
      }
    }
  }
  ${EVENT_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;

export const LMS_EVENTS_FOR_COURSE_QUERY = gql`
  query getEventsForCourse($courseCode: String, $first: Int, $offset: Int) {
    events(
      filters: [{ field: courseCode, operation: eq, value: $courseCode }]
      first: $first
      offset: $offset
      order: { field: start, direction: asc }
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          ...Event
        }
      }
    }
  }
  ${EVENT_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;

export const WEBLINK_EVENTS_FOR_COURSE_QUERY = gql`
  query getEventsForCourse(
    $first: Int
    $offset: Int
    $filters: [EventFieldFilter!]
  ) {
    events(
      filters: $filters
      first: $first
      offset: $offset
      order: { field: start, direction: asc }
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          ...WeblinkEvent
        }
      }
    }
  }
  ${WEBLINK_EVENT_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;

export const WEBLINK_EVENTS_QUERY = gql`
  query getEvents($filters: [EventFieldFilter!], $first: Int, $offset: Int) {
    events(
      first: $first
      offset: $offset
      order: { field: start, direction: asc }
      filters: $filters
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          ...WeblinkEvent
        }
      }
    }
  }
  ${WEBLINK_EVENT_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;

export const EVENT_BY_ID_QUERY = gql`
  query getEventById($id: String) {
    events(filters: [{ field: id, operation: eq, value: $id }], first: 1) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          ...Event
        }
      }
    }
  }
  ${EVENT_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;

export const WEBLINK_EVENT_BY_ID_QUERY = gql`
  query getEventById($id: String) {
    events(filters: [{ field: id, operation: eq, value: $id }], first: 1) {
      edges {
        node {
          ...WeblinkEvent
        }
      }
    }
  }
  ${WEBLINK_EVENT_FRAGMENT}
`;

export const WEBLINK_EVENT_SESSIONS_QUERY = gql`
  query getEventSessions(
    $eventId: String
    $sessionsFirst: Int
    $sessionsOffset: Int
  ) {
    events(filters: [{ field: id, operation: eq, value: $eventId }], first: 1) {
      edges {
        node {
          id
          timeZoneName
          sessions(first: $sessionsFirst, offset: $sessionsOffset) {
            pageInfo {
              ...PageInfo
            }
            edges {
              cursor
              node {
                id
                name
                start
                end
                location {
                  id
                  name
                  region {
                    id
                    name
                    code
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ${PAGE_INFO_FRAGMENT}
`;

export const ASSOCIATE_UNNAMED_LEARNER_WITH_CONTACTS = gql`
  mutation associateLearnerWithContacts(
    $input: AssociateUnnamedLearnerWithContactsInput!
  ) {
    registration {
      associateUnnamedEventLearnerWithContacts(input: $input) {
        registrations {
          id
          contactId
        }
        errors {
          label
          message
          value
        }
      }
    }
  }
`;
