import React, { FunctionComponent, useState, Fragment, useEffect } from "react";
import {
  List,
  ListRow,
  ListCell,
  Button,
  Pagination,
  ListHeader,
  Input,
  Select,
  DateTimeInput,
  OptionType,
  Prompt,
  Row,
  Col,
  ListEmpty,
} from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { useViewer } from "../../providers/ViewerProvider";
import { getUniqueId } from "../../utils/displayHelpers";
import { hasUnlimitedPlaces } from "../../utils/eventHelpers";
import { WEBLINK_LEARNING_PATH_EVENT_SELECTION_OPTIONS } from "../../queries/learningPaths";
import { useWebLinkQuery } from "../../hooks/weblink";
import {
  Query,
  EventField,
  EventFieldFilter,
  FilterOperation,
  Event as WeblinkEvent,
} from "../../generated/weblinkTypes";
import { startOfDayWithTimeZone } from "../../utils/dateTimeHelpers";
import { FormConsumer } from "@administrate/piston-ux/lib/Form";
import { DateCriteria } from "../../components/AffectBar/DatePickerFilter";
import moment from "moment";
import { EventDateRange } from "../../components/EventDateRange";
import { useDateFormatter } from "../../hooks/useDateFormatter";
import { useAnalytics } from "../../providers/AnalyticsProvider";
import { SearchEvent } from "../../analytics/events";

const EXTRA_TABLE_PADDING = 160;

export const LearningPathDateAndLocations: FunctionComponent<{
  courseCode: string;
  learningPathId: string;
  regionCode: string;
  onEventSelect?: (event: WeblinkEvent) => void;
  uniqueKey?: string;
}> = ({ courseCode, learningPathId, regionCode, onEventSelect, uniqueKey }) => {
  const { t } = useTranslation();
  const { captureEvent } = useAnalytics();

  const [filterArgs, setFilterArgs] = useState<DateAndLocationsFilterArgs>({
    location: undefined,
    learners: undefined,
    dates: {
      from: undefined,
    },
  });

  const { viewer } = useViewer();
  const viewerTimeZone = viewer?.timeZoneName ?? moment.tz.guess();

  const eventLimit = 10;
  const gridColumns = "1fr 2fr 2fr 1fr";
  const eventFilters = getEventFilters(
    filterArgs,
    viewerTimeZone,
  ) as EventFieldFilter[];
  const { events, eventsLoading, totalEvents, eventOffset, setEventOffset } =
    useLearningPathEventSelectionFetcher(
      eventFilters,
      courseCode,
      regionCode,
      learningPathId,
    );

  useEffect(() => {
    if (eventsLoading || !events) return;
    captureEvent(
      SearchEvent.fromLearningPathObjectivesSearch(totalEvents, eventFilters),
    );
  }, [eventsLoading, events, captureEvent, totalEvents, eventFilters]);

  const uniqueRegions = Array.from(
    new Set(events.map(event => event.location.name)),
  );

  const getEventLocations = (): OptionType[] => {
    return uniqueRegions.map(name => ({
      label: name,
      value: name,
    }));
  };

  const { dateFormat } = useDateFormatter({
    showDayName: false,
    showTime: true,
  });

  const filtersDisabled = !regionCode;

  return (
    <List>
      {filtersDisabled && (
        <div style={{ height: 33, marginTop: 15, marginLeft: 15 }}>
          <Prompt message={t("youMustSelectRegion")} type="error" />
        </div>
      )}
      <div
        className="filters"
        role={`search`}
        aria-label={`${t("for")} ${courseCode}`}
      >
        <Row>
          <Col lg={4}>
            <Select
              options={getEventLocations()}
              label={t("location")}
              name={uniqueKey ? `eventLocation-${uniqueKey}` : "eventLocation"}
              disabled={filtersDisabled}
              onChange={value =>
                setFilterArgs({
                  ...filterArgs,
                  location: value,
                })
              }
              uniqueId={getUniqueId()}
            />
          </Col>
          <Col lg={4}>
            <DateTimeInput
              type="date"
              label={t("startDate")}
              name={uniqueKey ? `eventStart-${uniqueKey}` : "eventStart"}
              disabled={filtersDisabled}
              onChange={value =>
                setFilterArgs({
                  ...filterArgs,
                  dates: {
                    ...filterArgs.dates,
                    from: value,
                  },
                })
              }
              uniqueId={getUniqueId()}
            />
          </Col>
          <Col lg={4}>
            <Input
              name={uniqueKey ? `learners-${uniqueKey}` : "learners"}
              label={t("learnerNumbers")}
              disabled={filtersDisabled}
              onChange={value =>
                setFilterArgs({
                  ...filterArgs,
                  learners: value,
                })
              }
              type="number"
              min={1}
              uniqueId={getUniqueId()}
            />
          </Col>
        </Row>
      </div>
      {!filtersDisabled && (
        <ListHeader
          gridColumns={`${gridColumns} ${EXTRA_TABLE_PADDING}px`}
          headings={[
            {
              title: t("language"),
            },
            {
              title: t("location"),
            },
            {
              title: t("courseDates"),
            },
            {
              title: t("availability"),
            },
          ]}
        />
      )}

      {!eventsLoading && !filtersDisabled && events.length > 0 && (
        <Fragment>
          {events.map(event => {
            return (
              <Fragment key={event.id}>
                <ListRow
                  gridColumns={gridColumns}
                  dataLabel={event.name}
                  extra={
                    <FormConsumer>
                      {({ values }) =>
                        event.registrationOpen ? (
                          <Button
                            type="suppressed"
                            disabled={event.remainingPlaces === 0}
                            ariaLabel={
                              event.remainingPlaces === 0
                                ? t("eventNotAvailable")
                                : t("selectEvent")
                            }
                            label={t("select")}
                            onClick={() => {
                              if (onEventSelect) {
                                onEventSelect(event);
                              } else {
                                values.objective.event.eventId = event.id;
                                values.objective.event.location = event.location
                                  ? event.location.name
                                  : "-";
                                values.objective.event.start = event.start;
                                values.objective.event.end = event.end;
                                values.objective.event.timeZoneName =
                                  event.timeZoneName;
                                values.objective.event.deliveryMethod =
                                  event.deliveryMethod;
                                values.objective.event.remainingPlaces =
                                  event.remainingPlaces;
                              }
                            }}
                          />
                        ) : (
                          <div>
                            <small>{t("registrationOpensOn")}:</small>
                            <p>
                              {dateFormat({ date: event.registrationOpensAt })}
                            </p>
                          </div>
                        )
                      }
                    </FormConsumer>
                  }
                  extraWidth={EXTRA_TABLE_PADDING}
                >
                  <ListCell label={t("language")}>
                    <p>{event.locale?.language ?? "English"}</p>
                  </ListCell>
                  <ListCell label={t("location")}>
                    {event.location &&
                      `${event.location.name}, ${
                        event.location.region && event.location.region.name
                      }`}
                  </ListCell>
                  <ListCell label={t("courseDates")}>
                    <EventDateRange event={event} />
                  </ListCell>
                  <ListCell label={t("availability")}>
                    {hasUnlimitedPlaces(event)
                      ? "Unlimited"
                      : event.remainingPlaces}
                  </ListCell>
                </ListRow>
              </Fragment>
            );
          })}
          {totalEvents > eventLimit && (
            <Pagination
              offset={eventOffset}
              totalRecords={totalEvents}
              limit={eventLimit}
              setOffset={setEventOffset}
              maxPagesDisplayed={3}
            />
          )}
        </Fragment>
      )}
      {eventsLoading && (
        <Fragment>
          <ListRow
            gridColumns={gridColumns}
            extra={
              <Button
                label={t("select")}
                onClick={() => []}
                disabled
                type="suppressed"
              />
            }
            extraWidth={EXTRA_TABLE_PADDING}
          >
            <ListCell label={t("language")} loading />
            <ListCell label={t("location")} loading />
            <ListCell label={t("courseDates")} loading />
            <ListCell label={t("availability")} loading />
          </ListRow>
        </Fragment>
      )}
      {!filtersDisabled && !eventsLoading && events.length === 0 && (
        <ListEmpty
          text={t("No courses available for this date and location")}
        />
      )}
    </List>
  );
};

const useLearningPathEventSelectionFetcher = (
  filters: EventFieldFilter[],
  courseCode: string,
  regionCode: string,
  learningPathId: string,
) => {
  filters.push({
    value: courseCode,
    operation: FilterOperation.Eq,
    field: EventField.CourseCode,
  });

  if (regionCode) {
    filters.push({
      value: regionCode,
      operation: FilterOperation.Eq,
      field: EventField.RegionCode,
    });
  }

  const [offset, setOffset] = useState(0);

  const { loading, data } = useWebLinkQuery<Query>(
    WEBLINK_LEARNING_PATH_EVENT_SELECTION_OPTIONS,
    {
      variables: {
        learningPathId: learningPathId,
        filters,
        offset,
        first: 10,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const totalRecords =
    data?.learningPathEventSelectionOptions?.pageInfo?.totalRecords || 0;
  const events =
    data?.learningPathEventSelectionOptions?.edges?.map(
      event => event.node.event,
    ) || [];

  return {
    events,
    eventsLoading: loading,
    totalEvents: totalRecords,
    eventOffset: offset,
    setEventOffset: setOffset,
  };
};

const getEventFilters = (
  filterArgs: DateAndLocationsFilterArgs,
  timeZoneName: string,
) => {
  const filters = [];

  if (filterArgs.location) {
    filters.push({
      value: `${filterArgs.location}`,
      operation: FilterOperation.Eq,
      field: EventField.LocationName,
    });
  }
  if (filterArgs.learners) {
    filters.push({
      value: `${filterArgs.learners}`,
      operation: FilterOperation.Ge,
      field: EventField.RemainingPlaces,
    });
  }
  if (filterArgs.dates) {
    const { from } = filterArgs.dates;
    if (from) {
      filters.push({
        value: startOfDayWithTimeZone(from, timeZoneName),
        operation: FilterOperation.Ge,
        field: EventField.Start,
      });
    }
  }
  return filters;
};

type DateAndLocationsFilterArgs = {
  location?: string;
  learners?: string;
  dates?: DateCriteria;
};
