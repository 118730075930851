import React, { FunctionComponent, Fragment } from "react";
import { RawStaticInput } from "@administrate/piston-ux/lib/StaticInput";
import { useTranslation } from "react-i18next";

import { Cart } from "../../generated/weblinkTypes";
import { getFinancialFormat } from "../../utils/displayHelpers";
import { ItemSummary } from "../../components/ItemSummary";
import { useViewer } from "../../providers/ViewerProvider";

type cartItemsProps = {
  cart: Cart;
  hidePrices: boolean;
  isZeroPriced: boolean;
};

export const CartItems: FunctionComponent<cartItemsProps> = ({
  cart,
  hidePrices = false,
  isZeroPriced,
}) => {
  const { viewer } = useViewer();
  const { t } = useTranslation();
  const currency = cart && cart.currency;

  return (
    <Fragment>
      {cart &&
        cart.items &&
        cart.items.map(item => {
          return (
            <Fragment key={item && item.id ? item.id : undefined}>
              {item && item.productOption && (
                <ItemSummary
                  name={item.productOption.name}
                  quantity={item.quantity}
                  price={
                    item && (
                      <>
                        {getFinancialFormat(
                          item.unitAmount,
                          currency,
                          viewer?.locale,
                        )}
                      </>
                    )
                  }
                  hidePrices={hidePrices}
                  isZeroPriced={isZeroPriced}
                />
              )}
              {item && item.pricingAgreementPrice && !hidePrices && (
                <RawStaticInput
                  label={t("pricingAgreement")}
                  value={item.pricingAgreementPrice.pricingAgreement.name}
                />
              )}
              {!(isZeroPriced || hidePrices) && (
                <Fragment>
                  <hr />
                  <p className="font-weight-bold">
                    {t("subtotal")}:
                    <span className="pl-2">
                      {cart.price &&
                        getFinancialFormat(
                          cart.price.subTotal,
                          currency,
                          viewer?.locale,
                        )}
                    </span>
                  </p>

                  <p className="font-weight-bold">
                    {t("totalDue")}:
                    <span className="pl-2">
                      {cart.price &&
                        getFinancialFormat(
                          cart.price.grandTotal,
                          currency,
                          viewer?.locale,
                        )}
                    </span>
                  </p>
                </Fragment>
              )}
            </Fragment>
          );
        })}
    </Fragment>
  );
};
