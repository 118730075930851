import { decodeId } from "./displayHelpers";

export const previewModeJWTRegex =
  /^\/login\/[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/;

export const handlePreview = (user: any) => {
  const pathname = window.location.pathname;
  let previewJWT;
  let isPreviewer;
  const isOnCoursePreviewPage = previewModeJWTRegex.test(pathname);

  if (isOnCoursePreviewPage) {
    previewJWT = pathname.split("/")[2];
  }

  if (user) {
    isPreviewer = isViewerPreviewer(user);
  }

  return { previewJWT, isPreviewer };
};

export const isViewerPreviewer = (user: any) => {
  const contactId = user.sub.split(":")[2];
  return decodeId(contactId).split(":")[1] === "PREVIEW";
};

export const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
