import React, { FunctionComponent, Fragment } from "react";
import { Card } from "@administrate/piston-ux";
import { ColorOptions } from "@administrate/piston-ux/lib/types";
import coursePlaceholder from "../images/course-placeholder.png";
import { Location, Maybe } from "../generated/weblinkTypes";
import { useTranslation } from "react-i18next";

type ItemInformationType = {
  image?: Maybe<string>;
  data: {
    location?: Location;
    dateAndTime?: string | JSX.Element;
    prices?: string;
    remainingPlaces?: string;
  };
  prices?: string;
  loading: boolean;
  name: "Course" | "Learning Path" | "privateCourse" | "publicCourse";
};

export const ItemInformation: FunctionComponent<ItemInformationType> = ({
  data,
  loading,
  image,
  name,
}) => {
  const { t } = useTranslation();

  const setName = () => {
    if (name === "privateCourse") {
      return t("privateCourse");
    }
    if (name === "publicCourse") {
      return t("publicCourse");
    }
    return name;
  };

  return (
    <Card
      label={{
        color: ColorOptions.Blue,
        text: `${setName()} ${t("information")}`,
        extraClass: name === "Learning Path" ? "learning-path-color" : "",
      }}
      extraClass={`card--type-${
        name === "Learning Path" ? "learningPath" : "event"
      }`}
      imageSource={image ? image : coursePlaceholder}
    >
      <ul className="reset-ul item-information">
        {loading && (
          <Fragment>
            <li>
              <span className="placeholder animated" style={{ width: 240 }} />
            </li>
            <li>
              <span className="placeholder animated" style={{ width: 120 }} />
            </li>
            <li>
              <span className="placeholder animated" style={{ width: 140 }} />
            </li>
          </Fragment>
        )}
        {!loading && (
          <Fragment>
            {data.location && (
              <li>
                <div className="icon">
                  <span className="sr-only">{t("locationAndRegion")}</span>
                  <span className="glyphicon glyphicon-map-marker"></span>
                </div>
                <div className="d-inline-block">
                  {data.location.name}
                  {data.location.region && `, ${data.location.region.name}`}
                </div>
              </li>
            )}
            {data.dateAndTime && (
              <li>
                <div className="icon">
                  <span className="sr-only">{t("dateAndTime")}</span>
                  <span className="glyphicon glyphicon-calendar"></span>
                </div>
                <div className="d-inline-block">{data.dateAndTime}</div>
              </li>
            )}
          </Fragment>
        )}
      </ul>
    </Card>
  );
};
