import { Errors } from "../types/Portal";
import { isObservableObject, values } from "mobx";
import { BookingLearner, BookingLearnersEdge } from "../types/Bookings";
import {
  Maybe,
  PointOfSaleLearnerFieldDefinition,
  Contact,
  CustomFieldValue,
  Query,
  Registration,
} from "../generated/weblinkTypes";
import { get } from "lodash";
import { LearnerType } from "../pages/Catalog/LearnerSelection";

export type LearnerDetailsType = {
  learners: LearnerType[];
  learnerLength: number;
  learnerNumber: number;
};

export const hasErrors = (error: Errors) => {
  if (isObservableObject(error)) {
    return values(error).some(hasErrors);
  }
  return error === "error";
};

const customFieldValuesFor = (object: Contact | BookingLearner) => {
  const values =
    object && object.customFieldValues ? object.customFieldValues : [];
  if (values.length === 0) {
    return {};
  }

  const keyToValueMap: Record<string, Maybe<string>> = {};
  values.forEach((value: Maybe<CustomFieldValue>) => {
    if (!value) return;

    const key = value.definitionKey as string;
    keyToValueMap[key] = value.value || null;
  });
  return keyToValueMap;
};

export const pointOfSaleFieldDefinitionsOfType = (
  typeName: string,
  definitions: PointOfSaleLearnerFieldDefinition[],
) =>
  definitions.filter(
    field =>
      field && field.mappedField && field.mappedField.entityType === typeName,
  );

export const pointOfSaleFieldValuesForLearner = (
  learner: BookingLearner,
  pointOfSaleFieldDefinitions: PointOfSaleLearnerFieldDefinition[],
) => {
  if (!pointOfSaleFieldDefinitions || !learner.contact) {
    return {};
  }

  const contactCustomFieldValuesMap = customFieldValuesFor(learner.contact);
  const learnerCustomFieldValuesMap = customFieldValuesFor(learner);

  const posFieldValues: Record<string, Maybe<string>> = {};

  pointOfSaleFieldDefinitionsOfType(
    "Contact",
    pointOfSaleFieldDefinitions,
  ).forEach(field => {
    if (!field || !field.mappedField) return;

    const key = field.key as string;
    posFieldValues[key] = mapPointOfSaleFieldValue(field, learner.contact);
  });

  pointOfSaleFieldDefinitionsOfType(
    "ContactCustom",
    pointOfSaleFieldDefinitions,
  ).forEach(field => {
    if (!field || !field.mappedField) return;

    const key = field.key as string;
    posFieldValues[key] = mapPointOfSaleFieldValue(
      field,
      contactCustomFieldValuesMap,
    );
  });

  pointOfSaleFieldDefinitionsOfType(
    "LearnerCustom",
    pointOfSaleFieldDefinitions,
  ).forEach(field => {
    if (!field || !field.mappedField) return;

    const key = field.key as string;
    posFieldValues[key] = mapPointOfSaleFieldValue(
      field,
      learnerCustomFieldValuesMap,
    );
  });

  pointOfSaleFieldDefinitionsOfType(
    "LearningPathLearnerCustom",
    pointOfSaleFieldDefinitions,
  ).forEach(field => {
    if (!field || !field.mappedField) return;

    const key = field.key as string;
    posFieldValues[key] = mapPointOfSaleFieldValue(
      field,
      learnerCustomFieldValuesMap,
    );
  });

  return posFieldValues;
};

export const mapPreExistingContactValues = (
  pointOfSaleFieldDefinitions: PointOfSaleLearnerFieldDefinition[],
  currentLearner: LearnerType,
  contact: Contact | null | undefined,
) => {
  if (!pointOfSaleFieldDefinitions || !contact) {
    return;
  }
  const customFieldValuesMap = customFieldValuesFor(contact);

  pointOfSaleFieldDefinitionsOfType(
    "ContactCustom",
    pointOfSaleFieldDefinitions,
  ).forEach(field => {
    if (!field || !field.mappedField) return;

    const key = field.key as string;
    currentLearner.posFieldValues[key] = mapPointOfSaleFieldValue(
      field,
      customFieldValuesMap,
    );
  });

  pointOfSaleFieldDefinitionsOfType(
    "Contact",
    pointOfSaleFieldDefinitions,
  ).forEach(field => {
    if (!field || !field.mappedField) return;

    const key = field.key as string;
    currentLearner.posFieldValues[key] = mapPointOfSaleFieldValue(
      field,
      contact,
    );
  });
};

const castMultichoice = (value: string | null | undefined) => {
  return value ? value.split("|") : [];
};

const mapPointOfSaleFieldValue = (
  fieldType: PointOfSaleLearnerFieldDefinition,
  values: any,
) => {
  const mappedField = fieldType.mappedField?.field;

  if (!mappedField) {
    return "";
  }

  switch (fieldType.type) {
    case "multichoice":
      return castMultichoice(get(values, mappedField));
    default:
      return get(values, mappedField, "");
  }
};

// As the user loads more learners (via a paginated query), we need to add those to our form values
export const updateFormLearners = (
  learners: BookingLearnersEdge[],
  existingFormValues: any,
  pointOfSaleFieldDefinitions: PointOfSaleLearnerFieldDefinition[],
) => {
  const mapLearner = (learner: BookingLearner) => {
    const contact = learner.contact || {
      firstName: "",
      lastName: "",
      account: null,
    };
    return {
      id: learner.id,
      contact: learner.contact,
      account: contact.account ? contact.account.name : "-",
      posFieldValues: pointOfSaleFieldValuesForLearner(
        learner,
        pointOfSaleFieldDefinitions,
      ),
      isCancelled: learner.isCancelled,
      isUnnamed: learner.isUnnamed,
      // Only applicable for Unnamed Learners
      quantity: learner.quantity,
      isNew: false,
      learningOutcomes: learner.learningOutcomes,
      registration: learner.registration,
    };
  };

  existingFormValues.learners = [
    ...learners.map(({ node: learner }: BookingLearnersEdge) =>
      mapLearner(learner),
    ),
  ];
};

export const mergeLearnerResults = (
  type: "eventBookings" | "learningPathBookings",
  prev: Query,
  fetchMoreResult: Query | undefined,
) => {
  if (!(fetchMoreResult && fetchMoreResult.viewer && prev.viewer)) {
    return prev;
  }

  const prevBookingConnection = get(prev.viewer, type);
  const newBookingConnection = get(fetchMoreResult.viewer, type);

  return {
    ...prev,
    viewer: {
      ...prev.viewer,
      [type]: {
        ...prevBookingConnection,
        edges: [
          {
            ...prevBookingConnection.edges[0],
            node: {
              ...prevBookingConnection.edges[0].node,
              learners: {
                ...prevBookingConnection.edges[0].node.learners,
                edges: [
                  ...prevBookingConnection.edges[0].node.learners.edges,
                  ...newBookingConnection.edges[0].node.learners.edges,
                ],
              },
            },
          },
        ],
      },
    },
  };
};

export const generateLearnerName = (learner: {
  contact?: Maybe<Contact>;
  isUnnamed: boolean;
  quantity: number;
  registration?: Maybe<Registration>;
}) => {
  // Unsure if this scenario can exist, but we should accommodate it
  if (!learner.contact && !learner.isUnnamed) {
    return "";
  }

  if (learner.isUnnamed && learner.quantity > 0) {
    return `Unnamed Learner x ${learner.quantity} ${
      learner.registration
        ? `(Reg Number: ${learner.registration.registrationNumber})`
        : ""
    }`;
  }

  if (learner.contact) {
    return learnerNameFromContact(learner.contact);
  }

  return "";
};

const learnerNameFromContact = (contact: Contact) =>
  contact
    ? `${contact.firstName}${contact.firstName ? " " : ""}${contact.lastName}`
    : "";
