import React, { FunctionComponent, useCallback, useState } from "react";
import { Card, OpacityOverlay, LoadingBar } from "@administrate/piston-ux";
import { groupBy } from "lodash";
import gql from "graphql-tag";

import { CatalogPage } from "./CatalogPage";
import { useWebLinkClient, useWebLinkQuery } from "../../hooks/weblink";
import {
  FilterOperation,
  BookableField,
  BookableFieldFilter,
  BookableType,
  Query,
} from "../../generated/weblinkTypes";
import { useViewer } from "../../providers/ViewerProvider";
import {
  endOfDayWithTimeZone,
  getDateTimeForEndWeek,
  getDateTimeForStartWeek,
  startOfDayWithTimeZone,
} from "../../utils/dateTimeHelpers";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { BookerIntention } from "../Order/BookingPage";
import { useBookerIntention } from "../../hooks/useBookerIntention";
import { useParams } from "../../useHistory";
import { useWeblinkSettings } from "../../hooks/useWeblinkSettings";
import { LoadingCard } from "../../components/LoadingCard";
import { BookableList } from "../../components/bookables/BookableList";
import { AllDatesArticleHeader } from "../../components/bookables/AllDatesArticleHeader";
import { PAGE_INFO_FRAGMENT } from "../../queries/fragments";
import { extractNodes } from "../../utils/extractNodes";
import {
  WEBLINK_BASIC_COURSES_QUERY,
  WEBLINK_BASIC_LOCALES_QUERY,
  WEBLINK_BASIC_LOCATIONS_QUERY,
} from "../../queries/filters";
import { CatalogFilter, useCatalogFilters } from "./CatalogFilterContext";

const WEBLINK_BOOKABLES_QUERY = gql`
  query getBookables(
    $filters: [BookableFieldFilter!]
    $search: String
    $first: Int
    $offset: Int
  ) {
    bookables(
      first: $first
      offset: $offset #order: { field: start, direction: asc }
      filters: $filters
      search: $search
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          __typename
          ... on Event {
            id
            name
            deliveryMethod
            start
            end
            timeZoneName
            remainingPlaces
            location {
              id
              name
            }
            locale {
              id
              language
            }
            prices {
              amount
              financialUnit {
                name
                symbol
                code
              }
              region {
                id
                code
                name
              }
            }
            course {
              includedInPassTypeIds
            }
            registrationOpen
            registrationOpensAt
          }
          ... on LearningPath {
            id
            name
            start
            end
            #timeZoneName // TODO
            remainingPlaces
            locale {
              id
              language
            }
            prices {
              amount
              financialUnit {
                name
                symbol
                code
              }
              region {
                id
                code
                name
              }
            }
            learningObjectives {
              edges {
                node {
                  id
                  __typename
                  ... on EventObjective {
                    id
                    event {
                      id
                      remainingPlaces
                      location {
                        id
                        name
                        region {
                          id
                          code
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
            includedInPassTypeIds
          }
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

const useBookablesFetcher = (
  filters: BookableFieldFilter[],
  search?: string,
) => {
  const [offset, setOffset] = useState(0);

  const { loading, data } = useWebLinkQuery<Query>(WEBLINK_BOOKABLES_QUERY, {
    variables: {
      filters,
      search,
      offset,
      first: 15, // TODO: Use pagniation limit from BookableList.tsx?
    },
    notifyOnNetworkStatusChange: true,
  });

  const totalRecords = data?.bookables?.pageInfo?.totalRecords || 0;
  const bookables = data?.bookables?.edges || [];

  return {
    bookables,
    bookablesLoading: loading,
    totalBookables: totalRecords,
    offset,
    setOffset,
  };
};

export const FindBookable: FunctionComponent = () => {
  const { t } = useTranslation();
  const { catalogType } = useParams<{catalogType:BookerIntention}>();
  const [bookerIntention, setBookerIntention] = useBookerIntention();

  const {
    hidePricesBeforePricingAgreementApplied,
    hidePrices,
    loading: weblinkLoading,
  } = useWeblinkSettings();

  if (bookerIntention !== catalogType) {
    setBookerIntention(catalogType);
  }
  const [mutationRunning, setMutationRunning] = useState(false);

  const { filters, setFilterValue } = useCatalogFilters();

  const { viewer } = useViewer();
  const viewerTimeZone = viewer?.timeZoneName ?? moment.tz.guess();

  const { bookables, bookablesLoading, totalBookables, offset, setOffset } =
    useBookablesFetcher(
      buildBookableFilters(filters, viewerTimeZone) as BookableFieldFilter[],
      filters.search,
    );

  const onFilterChange = useCallback(
    (key: keyof CatalogFilter, value: unknown) => {
      setFilterValue(key, value);
      // Set our offsets back to 0 on the chance we're at an offset
      // that's no longer available due to our filters
      setOffset(0);
    },
    [setFilterValue, setOffset],
  );

  const client = useWebLinkClient();

  const locationOptions = (inputValue: string) =>
    client
      .query({
        query: WEBLINK_BASIC_LOCATIONS_QUERY,
        variables: {
          filters: [
            {
              field: "name",
              operation: "like",
              value: `%${inputValue}%`,
            },
          ],
        },
      })
      .then(result => extractNodes(result.data.locations.edges));

  const localeOptions = async (inputValue: string) => {
    const result = await client.query({
      query: WEBLINK_BASIC_LOCALES_QUERY,
      variables: {
        filters: [
          {
            field: "name",
            operation: "like",
            value: `%${inputValue}%`,
          },
        ],
      },
    });
    const nodes = extractNodes(result.data?.locales.edges);

    const uniqueLanguages = Object.keys(groupBy(nodes, node => node.language));

    return uniqueLanguages.map(language => ({ name: language })); // Piston component is expecting an object
  };

  const courseOptions = (inputValue: string) =>
    client
      .query({
        query: WEBLINK_BASIC_COURSES_QUERY,
        variables: {
          filters: [
            {
              field: "name",
              operation: "like",
              value: `%${inputValue}%`,
            },
          ],
        },
      })
      .then(result => extractNodes(result.data.courses.edges));

  const getTitle = () => {
    if (bookerIntention === BookerIntention.Coordinating) {
      return t("coordinatorCatalog");
    }
    return t("myCatalog");
  };

  return (
    <OpacityOverlay on={!!mutationRunning}>
      <CatalogPage tab="find" title={getTitle()}>
        {weblinkLoading ? (
          <LoadingCard />
        ) : (
          <>
            <AllDatesArticleHeader
              onFilterChange={onFilterChange}
              loading={bookablesLoading}
              locationOptions={locationOptions}
              localeOptions={localeOptions}
              courseOptions={courseOptions}
            />
            <Card>
              {mutationRunning && <LoadingBar isLoading />}
              <BookableList
                bookables={extractNodes(bookables)}
                loading={bookablesLoading}
                totalRecords={totalBookables}
                offset={offset}
                hidePricesBeforePricingAgreementApplied={
                  hidePricesBeforePricingAgreementApplied
                }
                hidePrices={hidePrices}
                setOffset={setOffset}
                setMutationRunning={(loading: boolean) =>
                  setMutationRunning(loading)
                }
              />
            </Card>
          </>
        )}
      </CatalogPage>
    </OpacityOverlay>
  );
};

const buildBookableFilters = (
  catalogFilters: CatalogFilter,
  timeZoneName: string,
) => {
  const filters = [];
  if (catalogFilters.learners) {
    filters.push({
      value: catalogFilters.learners,
      operation: FilterOperation.Ge,
      field: BookableField.RemainingPlaces,
    });
  }
  if (catalogFilters.learningMode) {
    filters.push({
      value: catalogFilters.learningMode,
      operation: FilterOperation.Eq,
      field: BookableField.DeliveryMethod,
    });
  }
  if (catalogFilters.bookableType) {
    filters.push({
      value: convertBookableType(catalogFilters.bookableType),
      operation: FilterOperation.Eq,
      field: BookableField.Type,
    });
  }
  if (catalogFilters.fromDate) {
    filters.push({
      value: startOfDayWithTimeZone(catalogFilters.fromDate, timeZoneName),
      operation: FilterOperation.Ge,
      field: BookableField.Start,
    });
    if (catalogFilters.toDate) {
      filters.push({
        value: endOfDayWithTimeZone(catalogFilters.toDate, timeZoneName),
        operation: FilterOperation.Le,
        field: BookableField.End,
      });
    }
  }
  if (catalogFilters.fromWeek && catalogFilters.year) {
    filters.push({
      value: getDateTimeForStartWeek(
        catalogFilters.fromWeek,
        catalogFilters.year,
        timeZoneName,
      ),
      operation: FilterOperation.Ge,
      field: BookableField.Start,
    });
  }
  if (catalogFilters.toWeek && catalogFilters.year) {
    filters.push({
      value: getDateTimeForEndWeek(
        catalogFilters.toWeek,
        catalogFilters.year,
        timeZoneName,
      ),
      operation: FilterOperation.Le,
      field: BookableField.End,
    });
  }
  if (catalogFilters.location) {
    filters.push({
      value: catalogFilters.location.id,
      operation: FilterOperation.Eq,
      field: BookableField.LocationId,
    });
  }
  if (catalogFilters.language) {
    filters.push({
      value: catalogFilters.language.name,
      operation: FilterOperation.Eq,
      field: BookableField.Language,
    });
  }
  if (catalogFilters.course) {
    filters.push({
      value: catalogFilters.course.id,
      operation: FilterOperation.Eq,
      field: "courseId",
      // field: BookableField.CourseId,
    });
  }

  return filters;
};

// TODO I'm not sure if this has to be here or if it should be fixed in the Weblink API
// I need to leave this now as this needs to be released and don't want it blocking us
const convertBookableType = (type: BookableType) => {
  if (type === BookableType.Event) {
    return "Event";
  }
  if (type === BookableType.Path) {
    return "LearningPath";
  }
  return "";
};
