import { Client, ThemeProvider } from "@administrate/piston-ux";
import { THEME } from "@administrate/piston-ux/lib/theming/theming";
import React, { FunctionComponent } from "react";
import { PistonTranslationProvider } from "./PistonTranslationProvider";
import { usePortal } from "./PortalProvider";

export const PistonProvider: FunctionComponent = props => {
  const { branding } = usePortal();
  return (
    <PistonTranslationProvider>
      <ThemeProvider
        client={Client.Portal}
        brandedTable={{
          backgroundColor:
            branding?.colours?.primary ?? THEME.palette.primary.base.color,
          color: branding?.colours?.contrastText ?? "#ffffff",
        }}
        buttonStyles={{
          primary: {
            backgroundColor:
              branding?.colours?.primaryActionButton ??
              THEME.palette.primary.base.color,
            color: branding?.colours?.primaryActionButtonText ?? "#ffffff",
            borderRadius: branding?.buttons?.borderRadius ?? "4px",
          },
        }}
      >
        {props?.children}
      </ThemeProvider>
    </PistonTranslationProvider>
  );
};
