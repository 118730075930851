import React, { useState, Fragment, FunctionComponent } from "react";
import {
  Checkbox,
  Form,
  useFormValues,
  Button,
  Card,
  SectionHeader,
  List,
  ListRow,
  ListCell,
  StatusLabel,
  Row,
  Col,
} from "@administrate/piston-ux";
import { Link } from "react-router-dom";
import { NetworkStatus } from "apollo-boost";
import { useTranslation } from "react-i18next";
import { ColorOptions } from "@administrate/piston-ux/lib/types";

import { useDateFormatter } from "../../hooks/useDateFormatter";
import { CANCELLATION_REQUESTS_QUERY } from "../../queries/cancellation";
import {
  CancellationRequestsResponse,
  CancellationRequestEdge,
  CancellationRequest,
} from "../../types/Cancellation";
import { useLmsQuery } from "../../hooks/lms";
import { requestGridColumns, LoadingRequests } from "./TrainingRequests";

export const CancellationRequests: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const initialValues = {
    showAllCancellationRequests: false,
  };
  const formValues = useFormValues(initialValues);
  const pagination = 6;
  const [showAllCancellationRequests, setShowAllCancellationRequests] =
    useState(initialValues.showAllCancellationRequests);

  const {
    loading: cancellationRequestsLoading,
    data: cancellationRequestsResponse,
    fetchMore: fetchMoreCancellationRequests,
    networkStatus,
  } = useLmsQuery<CancellationRequestsResponse>(CANCELLATION_REQUESTS_QUERY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      offset: 0,
      first: pagination,
      ...(showAllCancellationRequests
        ? {}
        : {
            filters: [
              {
                field: "approvalState",
                operation: "ne",
                value: "approved",
              },
              {
                field: "approvalState",
                operation: "ne",
                value: "rejected",
              },
            ],
          }),
    },
  });
  const cancellationRequests =
    cancellationRequestsResponse?.cancellationRequests?.edges || [];
  const hasNextCancellationRequestPage =
    cancellationRequestsResponse?.cancellationRequests?.pageInfo?.hasNextPage ||
    false;

  return (
    <article>
      <SectionHeader titleLevel="h2" title={t("cancellationRequests")}>
        <Form values={formValues}>
          <div className="single-checkbox-filter">
            <Checkbox
              name="showAllCancellationRequests"
              description={t("showAllCancellationRequests")}
              onChange={newValue => setShowAllCancellationRequests(newValue)}
            />
          </div>
        </Form>
      </SectionHeader>
      <Card>
        <List>
          {(networkStatus === NetworkStatus.fetchMore ||
            networkStatus === NetworkStatus.ready) && (
            <Fragment>
              {cancellationRequests.map(({ node }: CancellationRequestEdge) => {
                return node.approvalState === "approved" &&
                  node.registerableId ? (
                  <Fragment key={node.id}>
                    <CancellationRequestRow node={node} />
                  </Fragment>
                ) : (
                  <Link
                    to={`/my-courses/course/${node.registerableId}`}
                    className="no-underline"
                    key={node.id}
                  >
                    <CancellationRequestRow node={node} />
                  </Link>
                );
              })}
            </Fragment>
          )}
          {cancellationRequestsLoading ? (
            <LoadingRequests />
          ) : (
            <Row>
              <Col style={{ textAlign: "center" }}>
                {!cancellationRequestsLoading &&
                  hasNextCancellationRequestPage && (
                    <Button
                      type="suppressed"
                      label={t("showMore")}
                      onClick={() =>
                        fetchMoreCancellationRequests({
                          variables: { offset: cancellationRequests.length },
                          updateQuery: (prev, { fetchMoreResult }) => {
                            return fetchMoreResult
                              ? {
                                  ...prev,
                                  cancellationRequests: {
                                    ...prev.cancellationRequests,
                                    pageInfo:
                                      fetchMoreResult.cancellationRequests
                                        .pageInfo,
                                    edges: [
                                      ...prev.cancellationRequests.edges,
                                      ...fetchMoreResult.cancellationRequests
                                        .edges,
                                    ],
                                  },
                                }
                              : prev;
                          },
                        })
                      }
                    />
                  )}
              </Col>
            </Row>
          )}
        </List>
      </Card>
    </article>
  );
};

const CancellationRequestRow: FunctionComponent<{
  node: CancellationRequest;
}> = ({ node }) => {
  const { t } = useTranslation();
  const { dateToDateFormat } = useDateFormatter();

  const start = node?.event?.start;
  const end = node?.event?.end;
  return (
    <ListRow
      gridColumns={requestGridColumns}
      disabled={node.approvalState === "approved" || !node.registerableId}
    >
      <ListCell label={t("course")}>{node?.event?.name || ""}</ListCell>
      <ListCell label={t("location")} icon="location">
        {node?.event?.location?.name || t("unknown")}
      </ListCell>
      <ListCell label={t("date")} icon="calendar">
        {dateToDateFormat({ start, end })}
      </ListCell>
      <ListCell label={t("status")}>
        <CancellationTag state={node.approvalState} />
      </ListCell>
    </ListRow>
  );
};

export const CancellationTag: FunctionComponent<{
  state: string;
}> = ({ state }) => {
  const { t } = useTranslation();
  switch (state) {
    case "approved":
      return (
        <StatusLabel text={t("canceled")} color={ColorOptions.LightGrey} />
      );
    case "rejected":
      return (
        <StatusLabel
          text={t("cancellationRejected")}
          color={ColorOptions.LightGrey}
        />
      );

    default:
      return (
        <StatusLabel
          text={t("awaitingCancellation")}
          color={ColorOptions.Yellow}
        />
      );
  }
};
