import React, { FunctionComponent, useState } from "react";
import {
  List,
  ListRow,
  ListCell,
  Alert,
  Pagination,
  ListHeader,
  Placeholder,
  LoadingBar,
} from "@administrate/piston-ux";
import { ListEmpty } from "@administrate/piston-ux/lib/List";
import { useTranslation } from "react-i18next";
import { useViewer } from "../../providers/ViewerProvider";
import { useBookerIntention } from "../../hooks/useBookerIntention";
import { useWebLinkMutation } from "../../hooks/weblink";
import { BookerIntention } from "../../pages/Order/BookingPage";
import {
  ADD_CART_LINE_ITEM_MUTATION,
  CREATE_CART_MUTATION,
} from "../../queries/cart";
import { Bookable } from "../../generated/weblinkTypes";
import { BookableRow } from "./BookableRow";
import { BookableDetails } from "./BookableDetails";
import { useTrainingPassTypes } from "../../hooks/useTrainingPassTypes";
import { useViewersLastCart } from "../../hooks/useViewersLastCart";
import { Maybe } from "../../generated/lmsTypes";

type BookableListProps = {
  bookables: Bookable[];
  loading: boolean;
  totalRecords: number;
  offset: number;
  hidePrices: boolean;
  hidePricesBeforePricingAgreementApplied: boolean;
  cartlessCheckout?: boolean;
  setOffset: (offset: number) => void;
  setMutationRunning: (loading: boolean) => void;
};

let PAGINATION_LIMIT = 15;

export const BookableList: FunctionComponent<BookableListProps> = ({
  bookables,
  loading,
  totalRecords,
  offset,
  hidePricesBeforePricingAgreementApplied,
  hidePrices,
  cartlessCheckout,
  setOffset,
  setMutationRunning,
}) => {
  const { t } = useTranslation();
  const trainingPassTypes = useTrainingPassTypes();
  const [apiError, setApiError] = useState(false);
  const [passRegisterError, setPassRegisterError] =
    useState<Maybe<React.ReactNode | string>>(null);
  const { viewer } = useViewer();
  const [bookerIntention] = useBookerIntention();
  const [selectedBookable, setSelectedBookable] =
    useState<{ id: string; typename: "Event" | "LearningPath" | null } | null>(
      null,
    );

  const { cart: viewersLastCart, loading: viewersCartLoading } =
    useViewersLastCart({
      skip: bookerIntention === BookerIntention.Coordinating,
    });

  const selfRegistrationCartLoading =
    bookerIntention === BookerIntention.Self && viewersCartLoading;

  const bookablesOrCartLoading = loading || selfRegistrationCartLoading;

  const [
    createCart,
    { loading: createCartMutationLoading, error: createCartMutationError },
  ] = useWebLinkMutation(CREATE_CART_MUTATION);

  const [
    addCartLineItem,
    {
      loading: addCartLineItemMutationLoading,
      error: addCartLineItemMutationError,
    },
  ] = useWebLinkMutation(ADD_CART_LINE_ITEM_MUTATION);

  const baseHeadings = [
    {
      title: t("startDate"),
      columnWidth: "1fr",
    },
    {
      title: t("startWeek"),
      columnWidth: "1fr",
    },
    {
      title: t("name"),
      columnWidth: "2fr",
    },
    {
      title: t("learningMode"),
      columnWidth: "1fr",
    },
    {
      title: t("language"),
      columnWidth: "1fr",
    },
    {
      title: t("location"),
      columnWidth: "1fr",
    },
    { title: t("duration"), columnWidth: "1fr" },
    { title: t("availability"), columnWidth: "1fr" },
    {
      title: t("currency"),
      columnWidth: "1fr",
      hide: hidePrices,
    },
    {
      title: t("price"),
      columnWidth: "1fr",
      hide: hidePrices || hidePricesBeforePricingAgreementApplied,
    },
    {
      title: t("pass"),
      columnWidth: "1fr",
    },
  ];

  const visibleHeadings = baseHeadings.filter(h => !h.hide);

  const gridColumns = visibleHeadings
    .map(({ columnWidth }) => columnWidth)
    .join(" ");

  return (
    <>
      {(createCartMutationLoading || addCartLineItemMutationLoading) && (
        <LoadingBar isLoading overPage />
      )}
      {passRegisterError && (
        <Alert
          dismissButtonType="simple"
          onDismiss={() => setPassRegisterError(null)}
          type="error"
          message={passRegisterError}
          glyph="exclamationSign"
        />
      )}
      {(createCartMutationError ||
        apiError ||
        addCartLineItemMutationError) && (
        <Alert
          type="error"
          message={t("anErrorOccurredWhileCreatingYourCart")}
          glyph="removeSign"
          overPage={false}
        />
      )}
      <List extraClass="" loading={bookablesOrCartLoading} hover>
        <ListHeader
          gridColumns={`${gridColumns} 133px`}
          headings={visibleHeadings}
        />
        {!bookablesOrCartLoading &&
          bookables.map(bookable => (
            <BookableRow
              key={bookable.id}
              bookable={bookable}
              createCartMutationLoading={createCartMutationLoading}
              onClickDate={b =>
                setSelectedBookable({
                  id: b.id,
                  typename: b.__typename ?? null,
                })
              }
              hidePrices={hidePrices}
              hidePricesBeforePricingAgreementApplied={
                hidePricesBeforePricingAgreementApplied
              }
              trainingPassTypes={trainingPassTypes}
              gridColumns={gridColumns}
              learnerPasses={viewer?.trainingPasses ?? []}
              cartlessCheckout={cartlessCheckout}
              viewersLastCart={viewersLastCart}
              viewersCartLoading={viewersCartLoading}
              addCartLineItem={addCartLineItem}
              createCart={createCart}
              setApiError={setApiError}
              setPassRegisterError={setPassRegisterError}
              setMutationRunning={setMutationRunning}
            />
          ))}
        {!bookablesOrCartLoading && bookables.length === 0 && (
          <ListEmpty text={t("noResults")} />
        )}
        {bookablesOrCartLoading && (
          <BookableListPlaceholder
            hidePrices={hidePrices}
            hidePricesBeforePricingAgreementApplied={
              hidePricesBeforePricingAgreementApplied
            }
            gridColumns={gridColumns}
          />
        )}
        {!bookablesOrCartLoading &&
          bookables &&
          totalRecords > PAGINATION_LIMIT && (
            <Pagination
              totalRecords={totalRecords}
              limit={PAGINATION_LIMIT}
              offset={offset}
              setOffset={setOffset}
            />
          )}
      </List>
      {!!selectedBookable && !!selectedBookable.typename && (
        <BookableDetails
          bookableId={selectedBookable.id}
          type={selectedBookable.typename}
          onDone={() => setSelectedBookable(null)}
        />
      )}
    </>
  );
};

const BookableListPlaceholder: FunctionComponent<{
  hidePrices: boolean;
  hidePricesBeforePricingAgreementApplied: boolean;
  gridColumns: string;
}> = ({ hidePrices, hidePricesBeforePricingAgreementApplied, gridColumns }) => {
  const { t } = useTranslation();
  return (
    <>
      {[...Array(3)].map((_, i) => (
        <ListRow
          key={i}
          gridColumns={gridColumns}
          extra={<Placeholder />}
          extraWidth={160}
        >
          <ListCell loading label={t("startDate")} />
          <ListCell loading label={t("startWeek")} />
          <ListCell loading label={t("name")} />
          <ListCell loading label={t("learningMode")} />
          <ListCell loading label={t("language")} />
          <ListCell loading label={t("location")} />
          <ListCell loading label={t("duration")} />
          <ListCell loading label={t("availability")} />
          {!hidePrices && <ListCell loading label={t("currency")} />}
          {!(hidePrices || hidePricesBeforePricingAgreementApplied) && (
            <ListCell loading />
          )}
          <ListCell loading label={t("pass")} />
        </ListRow>
      ))}
    </>
  );
};
