import React, { useCallback, useState } from "react";
import {
  Form,
  useFormValues,
  Submit,
  Select,
  Alert,
  Card,
  FutureArticleHeader,
  FormActions,
  Row,
  Col,
} from "@administrate/piston-ux";
import moment from "moment-timezone";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { Viewer } from "../../types/Viewer";
import { Maybe } from "../../types/Maybe";
import { useViewer } from "../../providers/ViewerProvider";

type PreferencesProps = {
  viewer: Viewer;
};

type ViewerUpdate = {
  locale?: Maybe<string>;
  timeZoneName?: Maybe<string>;
};

export const Preferences: React.FunctionComponent<PreferencesProps> = ({
  viewer,
}) => {
  const { t } = useTranslation();
  const initialValues: ViewerUpdate = {
    locale: viewer.locale,
    timeZoneName: viewer.timeZoneName,
  };
  const values = useFormValues(initialValues);
  const [updateViewerCalled, setUpdateViewerCalled] = useState(false);
  const {
    updateViewerMutation,
    updateViewerMutationIsLoading,
    updateViewerErrors,
  } = useViewer();

  const onSubmit = useCallback(
    async formValues => {
      setUpdateViewerCalled(true);
      const result = await updateViewerMutation({
        variables: formValues as ViewerUpdate,
      });
      if (result.errors && result.errors.length > 0) {
        // TODO handle error state for mutation
        console.log("error while saving preferfences");
        console.log(result.errors);
      } else {
        i18n.changeLanguage(formValues.locale);
      }
    },
    [updateViewerMutation],
  );

  return (
    <div className="profile-preferences">
      <Form
        values={values}
        onSubmit={onSubmit}
        disabled={updateViewerMutationIsLoading}
      >
        <Card>
          <FutureArticleHeader title={t("preferences")} />
          {!updateViewerMutationIsLoading &&
            updateViewerCalled &&
            (!updateViewerErrors.length ? (
              <Alert
                type="success"
                message={t("Successful profile update")}
                overPage
              />
            ) : (
              <Alert type="error" message={t("Error occurred during update")} />
            ))}
          <Row>
            <Col sm={6}>
              <Select
                label={t("language")}
                options={[
                  { label: t("english-us"), value: "en-US" },
                  { label: t("english-gb"), value: "en-GB" },
                  { label: t("french"), value: "fr" },
                ]}
                name="locale"
                valid={v => (!!v ? true : `${t("pleaseSelectValue")}`)}
              />
            </Col>
            <Col sm={6}>
              <Select
                label={t("timeZone")}
                options={moment.tz
                  .names()
                  .map(name => ({ label: name, value: name }))}
                name="timeZoneName"
                valid={v => (!!v ? true : `${t("pleaseSelectValue")}`)}
              />
            </Col>
          </Row>
          <FormActions>
            {updateViewerMutationIsLoading && (
              <span
                className="glyphicon glyphicon-refresh glyphicon-spinner"
                style={{ marginRight: "5px" }}
              />
            )}
            <Submit
              label={t("updatePreferences")}
              inline
              disabled={updateViewerMutationIsLoading}
            />
          </FormActions>
        </Card>
      </Form>
    </div>
  );
};
