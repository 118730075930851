import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  BookableType,
  Course,
  DeliveryMethod,
  Location,
} from "../../generated/weblinkTypes";
import { getCurrentYear } from "../../utils/dateTimeHelpers";
import { BookableLanguage } from "../../components/bookables/AllDatesArticleHeader";

export interface CatalogFilter {
  bookableType?: BookableType;
  category?: string;
  course?: Course;
  dateFilterType?: string;
  fromDate?: string;
  learners?: string;
  learningMode?: DeliveryMethod;
  location?: Location;
  language?: BookableLanguage;
  search: string;
  toDate?: string;
  type?: "CourseTemplate" | "LearningPath";
  year?: string;
  fromWeek?: string;
  toWeek?: string;
  trainingPassType?: string;
}
export interface FilterContextType {
  filters: CatalogFilter;
  setFilters: Dispatch<SetStateAction<CatalogFilter>>;
  setFilterValue: (key: keyof CatalogFilter, value: unknown) => void;
}
export const CatalogFilterContext = createContext({} as FilterContextType);
const useCatalogFilterContext = () => useContext(CatalogFilterContext);

export function CatalogFilterContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const emptyFilters = {
    search: "",
    type: undefined,
    category: undefined,
    bookableType: undefined,
    learningMode: undefined,
    location: undefined,
    language: undefined,
    course: undefined,
    learners: undefined,
    fromDate: undefined,
    toDate: undefined,
    dateFilterType: "date",
    year: getCurrentYear(),
    fromWeek: undefined,
    toWeek: undefined,
    trainingPassType: undefined,
  };
  const [filters, setFilters] = useState<CatalogFilter>(emptyFilters);
  const setFilterValue = useCallback(
    (key, value) => {
      setFilters(prevState => ({ ...prevState, [key]: value }));
    },
    [setFilters],
  );

  return (
    <CatalogFilterContext.Provider
      value={{
        filters,
        setFilters,
        setFilterValue,
      }}
    >
      {children}
    </CatalogFilterContext.Provider>
  );
}

export function useCatalogFilters() {
  const context = useCatalogFilterContext();
  if (context === undefined) {
    throw new Error("useCatalogFilters must be used within a Catalog Route");
  }
  return context;
}
