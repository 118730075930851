import React, { FunctionComponent, Fragment } from "react";
import { PointOfSaleLearnerFieldDefinition as WebLinkPointOfSaleLearnerFieldDefinition } from "../generated/weblinkTypes";
import { PointOfSaleLearnerFieldDefinition as LMSPointOfSaleLearnerFieldDefinition } from "../generated/lmsTypes";
import { Maybe } from "@administrate/piston-ux/lib/types";
import {
  SubSection,
  CustomFieldInput,
  Row,
  Col,
} from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { Errors } from "../types/Portal";
import { getUniqueId } from "../utils/displayHelpers";
import { CustomFieldType } from "@administrate/piston-ux/lib/CustomFieldInput";
import { isObservableArray } from "mobx";

const browserLocaleTypes = ["date", "datetime", "time"];

const WrappedSubSection: FunctionComponent<{
  skipSubSection: boolean;
  title: string;
}> = ({ skipSubSection, title, children }) =>
  skipSubSection ? (
    <>{children}</>
  ) : (
    <SubSection titleLevel="span" title={title}>
      {children}
    </SubSection>
  );

export const PointOfSaleLearnerFields: FunctionComponent<{
  posFields?: Maybe<
    | WebLinkPointOfSaleLearnerFieldDefinition
    | LMSPointOfSaleLearnerFieldDefinition
  >[];
  learnerNumber: number;
  errors?: Errors;
  readonly?: boolean;
  disabled?: boolean;
  hideSubtitles?: boolean;
}> = ({
  learnerNumber,
  posFields,
  readonly,
  disabled = false,
  hideSubtitles: hideSubSectionSubTitles = false,
}) => {
  const { t } = useTranslation();
  const hasPosFields = (section: "Contact" | "Event" | "Path") => {
    return (
      posFields &&
      posFields.filter(field => field && field.section === section).length > 0
    );
  };

  const hasContactFields = hasPosFields("Contact");
  const hasEventFields = hasPosFields("Event");
  const hasPathFields = hasPosFields("Path");
  if (!hasContactFields && !hasEventFields && !hasPosFields) {
    return null;
  }

  const getAdditionalProps = (type: CustomFieldType) => {
    if (browserLocaleTypes.includes(type)) {
      return { useBrowserLocale: true };
    }
    return undefined;
  };

  const isValid =
    (
      field: Maybe<
        | WebLinkPointOfSaleLearnerFieldDefinition
        | LMSPointOfSaleLearnerFieldDefinition
      >,
    ) =>
    (value: string | string[]) => {
      if (readonly) {
        return true;
      }

      if (!field?.isRequired) {
        return true;
      }

      return Array.isArray(value) || isObservableArray(value)
        ? !!value.length
        : !!value;
    };

  return (
    <Fragment>
      {posFields && hasContactFields && (
        <WrappedSubSection
          skipSubSection={hideSubSectionSubTitles}
          title={t("contactInformation")}
        >
          <Row>
            {
              /* TODO: Remove this any once the types are fixed in weblink2-api */
              posFields.map((field: any) => {
                if (field.section === "Contact") {
                  return (
                    <Col sm={6} key={`learner-${learnerNumber}-${field.key}`}>
                      <CustomFieldInput
                        name={`posFieldValues.${field.key}`}
                        label={field.label}
                        type={field.type}
                        options={field.options}
                        valid={isValid(field)}
                        disabled={readonly || disabled}
                        uniqueId={getUniqueId()}
                        additionalInputProps={getAdditionalProps(field.type)}
                      />
                    </Col>
                  );
                }
                return undefined;
              })
            }
          </Row>
        </WrappedSubSection>
      )}
      {posFields && hasEventFields && (
        <WrappedSubSection
          skipSubSection={hideSubSectionSubTitles}
          title={t("eventInformation")}
        >
          <Row>
            {
              /* TODO: Remove this any once the types are fixed in weblink2-api */
              posFields.map((field: any) => {
                if (field.section === "Event") {
                  return (
                    <Col sm={6} key={`learner-${learnerNumber}-${field.key}`}>
                      <CustomFieldInput
                        name={`posFieldValues.${field.key}`}
                        label={field.label}
                        type={field.type}
                        options={field.options}
                        valid={isValid(field)}
                        disabled={readonly || disabled}
                        uniqueId={getUniqueId()}
                        additionalInputProps={getAdditionalProps(field.type)}
                      />
                    </Col>
                  );
                }
                return undefined;
              })
            }
          </Row>
        </WrappedSubSection>
      )}
      {posFields && hasPathFields && (
        <WrappedSubSection
          skipSubSection={hideSubSectionSubTitles}
          title={t("pathInformation")}
        >
          <Row>
            {
              /* TODO: Remove this any once the types are fixed in weblink2-api */
              posFields.map((field: any) => {
                if (field.section === "Path") {
                  return (
                    <Col sm={6} key={`learner-${learnerNumber}-${field.key}`}>
                      <CustomFieldInput
                        name={`posFieldValues.${field.key}`}
                        label={field.label}
                        type={field.type}
                        options={field.options}
                        valid={isValid(field)}
                        disabled={readonly || disabled}
                        uniqueId={getUniqueId()}
                        additionalInputProps={getAdditionalProps(field.type)}
                      />
                    </Col>
                  );
                }
                return undefined;
              })
            }
          </Row>
        </WrappedSubSection>
      )}
    </Fragment>
  );
};
