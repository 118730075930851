import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { Dedupe as DedupeIntegration } from "@sentry/integrations";
import { throwIfNotOK } from "../fetchHelpers";
import { LoadingScreen } from "@administrate/piston-ux";
import { GlobalStoreProvider } from "./GlobalStore";
import { ApolloError } from "apollo-boost";
import { asDiagnostic } from "../utils/errorHelpers";

export const loadConfig = () =>
  fetch("/config")
    .then(throwIfNotOK)
    .then(result => result.json());

export const ConfigLoader: React.FunctionComponent = ({ children }) => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    loadConfig()
      .then(config => {
        if (config.ui_sentry_dsn) {
          Sentry.init({
            dsn: config.ui_sentry_dsn,
            denyUrls: [/\/files\/[a-f0-9]{128}/i],
            integrations: [new DedupeIntegration()],
            environment: process.env.NODE_ENV || "development",
            beforeSend: (event, hint) => {
              const exception = hint?.originalException;
              if (exception instanceof ApolloError) {
                event.fingerprint = [
                  exception.networkError ? "Network" : "GraphQL",
                  exception.message,
                ];
              }
              return event;
            },
          });
        }

        setConfig(config);
      })
      .catch(
        asDiagnostic(
          "Config",
          document.location.origin + "/config",
          "Failed to Fetch",
        ),
      );
  }, []);

  if (!config) {
    return <LoadingScreen />;
  }

  return <GlobalStoreProvider config={config}>{children}</GlobalStoreProvider>;
};
