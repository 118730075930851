import { RefObject, useEffect } from "react";

export const useOnKeyHit = (
  ref: RefObject<HTMLElement>,
  handler: (ev?: KeyboardEvent) => void,
  key: string,
) => {
  useEffect(() => {
    const keyListener = (ev: KeyboardEvent) => {
      if (ev.key === key) {
        handler(ev);
      }
    };
    document.addEventListener("keydown", keyListener);
    return () => {
      document.removeEventListener("keydown", keyListener);
    };
  }, [ref, handler, key]);
};
