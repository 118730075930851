import { Dispatch, SetStateAction } from "react";
import { BookerIntention } from "../pages/Order/BookingPage";
import { useGlobalStore } from "../providers/GlobalStore";
import { usePortal } from "../providers/PortalProvider";
import { useViewer } from "../providers/ViewerProvider";
import { useLocalStorage } from "./useLocalStorage";

export function useBookerIntention(
  initialValue?: BookerIntention,
): [BookerIntention, Dispatch<SetStateAction<BookerIntention>>] {
  const { isBookingPortal } = usePortal();
  const globalStore = useGlobalStore();
  const { viewer } = useViewer();
  const intentionKey = `bookerIntention:${globalStore.config.brand}`;

  const [bookerIntention, setBookerIntention] = useLocalStorage(intentionKey);
  if (!bookerIntention) {
    setBookerIntention(
      getDefaultBookerIntention(
        isBookingPortal,
        !!viewer && viewer.isCoordinator,
        initialValue,
      ),
    );
  }

  return useLocalStorage(intentionKey);
}

const getDefaultBookerIntention = (
  isBookingPortal: boolean,
  isCoordinator: boolean,
  initialValue?: BookerIntention,
) => {
  if (initialValue) {
    return initialValue;
  }
  if (isBookingPortal && isCoordinator) {
    return BookerIntention.Coordinating;
  }
  return BookerIntention.Self;
};
