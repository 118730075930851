import {
  TrainingPassType,
  TrainingPassTypeConnection,
} from "../generated/lmsTypes";
import { TRAINING_PASS_TYPES } from "../queries/trainingPasses";
import { useLmsQuery } from "./lms";

export const useTrainingPassTypes = () => {
  const { data, loading } =
    useLmsQuery<{ trainingPassTypes: TrainingPassTypeConnection }>(
      TRAINING_PASS_TYPES,
    );

  if (loading || !data || (data?.trainingPassTypes?.edges?.length ?? 0) === 0) {
    return [];
  }

  return (data.trainingPassTypes.edges
    ?.map(passTypeEdge => passTypeEdge?.node)
    ?.filter(passType => passType != null) ?? []) as TrainingPassType[];
};
