import React, { Fragment, FunctionComponent, useState } from "react";
import {
  Card,
  Col,
  Form,
  FutureArticleHeader,
  FutureListPage,
  List,
  ListCell,
  ListEmpty,
  ListRow,
  Pagination,
  Placeholder,
  Row,
  Select,
  StatusLabel,
  useFormValues,
} from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { range, get } from "lodash";
import { useLmsQuery } from "../../hooks/lms";
import { GET_ACHIEVEMENTS } from "../../queries/achievements";
import {
  AchievementField,
  AchievementState,
  OrderDirection,
  Query,
} from "../../generated/lmsTypes";
import { ColorOptions } from "@administrate/piston-ux/lib/types";
import { formatLocalisedDate } from "../../utils/dateTimeHelpers";
import { legacyID } from "../../utils/convertID";
import { Link } from "react-router-dom";
import { useGlobalStore } from "../../providers/GlobalStore";
import { MenuBar } from "../../MenuBar";
import { useHistory, useParams } from "../../useHistory";
import { AchievementLink } from "../../components/AchievementLink";

const ITEM_CLASSNAME = "d-block d-lg-inline-block mb-2 mb-lg-0 mr-0 mr-lg-4";

const LIST_LIMIT = 10;

export const Achievements: FunctionComponent = () => {
  const [offset, setOffset] = useState(0);
  const { t } = useTranslation();
  const [sort, setSort] = useState({
    direction: OrderDirection.Desc,
    field: AchievementField.ExpiresAt,
  });

  const onAffect = (change: AchievementsAffectType) => {
    setSort(change.sort);
  };

  const {
    data,
    loading,
    refetch: refetchAchievments,
  } = useLmsQuery<Query>(GET_ACHIEVEMENTS, {
    variables: {
      offset: 0,
      first: LIST_LIMIT,
      order: sort,
    },
  });

  const pageInfo = data?.achievements?.pageInfo;

  const getLifecycleStateColour = (lifecycleState: AchievementState) => {
    const colourMapper = {
      active: ColorOptions.Green,
      revoked: ColorOptions.Red,
      expired: ColorOptions.LightGrey,
    };
    const defaultColour = ColorOptions.LightGrey;

    return colourMapper[lifecycleState] || defaultColour;
  };

  return (
    <FutureListPage title={t("myAchievements")}>
      <Card>
        <AchievementsHeader onChange={onAffect} />
        <List>
          {!loading && data?.achievements?.edges.length === 0 && (
            <ListEmpty text={t("noResults")} />
          )}
          {!loading &&
            data?.achievements?.edges?.map((edge, key) => {
              const achievement = edge?.node;

              const certificateUrl = achievement?.certificateUrl;
              const firstDocumentUrl =
                achievement?.documentUrls && achievement?.documentUrls[0];
              const firstDocumentIdUrl =
                achievement?.documentIds &&
                achievement?.documentIds[0] &&
                `/achievements/${achievement?.id}/documents/${achievement?.documentIds[0]}`;
              const url =
                certificateUrl || firstDocumentUrl || firstDocumentIdUrl;

              const issuedFromUrl =
                get(achievement, "registerable.__typename") ===
                "LearningPathRegistration"
                  ? "learning-path"
                  : "course";

              const id =
                achievement?.id &&
                `(${t("achievement")} ID: ${legacyID(achievement?.id)})`;

              const isExternalLink = url !== null && url === firstDocumentUrl;

              return (
                <Fragment key={key}>
                  <ListRow
                    gridColumns="auto"
                    extraClass="pt-3 pb-4"
                    extraWidth="113px"
                    extra={
                      url && (
                        <AchievementLink
                          url={url}
                          external={isExternalLink}
                          className="btn btn-link mt-0 mt-lg-3"
                        >
                          {t("view")}
                          <span className="sr-only">{id}</span>
                        </AchievementLink>
                      )
                    }
                    dataLabel={
                      achievement?.identifier ||
                      achievement?.achievementType?.name ||
                      undefined
                    }
                  >
                    <ListCell label={t("achievement")}>
                      <Row>
                        <Col xs={12}>
                          <h2 className="mt-0 mb-2">
                            {achievement?.achievementType?.name}
                          </h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          {achievement?.lifecycleState && (
                            <span className={ITEM_CLASSNAME}>
                              <StatusLabel
                                color={getLifecycleStateColour(
                                  achievement?.lifecycleState,
                                )}
                                text={t(`${achievement?.lifecycleState}`)}
                              />
                            </span>
                          )}
                          {achievement?.expiresAt && (
                            <span className={`expires ${ITEM_CLASSNAME}`}>
                              {`${t("expiresAt")}: `}
                              <strong className="pl-1">
                                {formatLocalisedDate(achievement?.expiresAt)}
                              </strong>
                            </span>
                          )}
                          {achievement?.identifier && (
                            <span className={`identifier ${ITEM_CLASSNAME}`}>
                              {t("number")}
                              <strong className="pl-1">
                                {achievement?.identifier}
                              </strong>
                            </span>
                          )}
                          {achievement?.registerable && (
                            <span className={`issued-from ${ITEM_CLASSNAME}`}>
                              {t("issuedFrom")}
                              <Link
                                className="d-inline-block font-weight-bold pl-1"
                                to={`/my-courses/${issuedFromUrl}/${achievement?.registerable.id}`}
                              >
                                {achievement?.registerable.title}{" "}
                                <span className="sr-only">{id}</span>
                              </Link>
                            </span>
                          )}
                        </Col>
                      </Row>
                    </ListCell>
                  </ListRow>
                </Fragment>
              );
            })}
          {loading && <MyAchievementsTableSkeleton />}
          {!loading && pageInfo && pageInfo?.totalRecords > LIST_LIMIT && (
            <Pagination
              limit={LIST_LIMIT}
              offset={offset}
              totalRecords={pageInfo?.totalRecords}
              setOffset={newOffset => {
                refetchAchievments({
                  first: LIST_LIMIT,
                  offset: newOffset,
                });
                setOffset(newOffset);
              }}
            />
          )}
        </List>
      </Card>
    </FutureListPage>
  );
};

const MyAchievementsTableSkeleton: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {range(4).map(i => (
        <Fragment key={i}>
          <ListRow>
            <ListCell label={t("achievement")}>
              <Row>
                <Col xs={4}>
                  <Placeholder />
                </Col>
              </Row>
              <Row>
                <Col xs={8}>
                  <Placeholder />
                </Col>
              </Row>
            </ListCell>
          </ListRow>
        </Fragment>
      ))}
    </Fragment>
  );
};

type AchievementsAffectType = {
  sort: AchievementSort;
};

type Sort<T> = {
  direction: OrderDirection;
  field: T;
  label?: string;
};

type AchievementSort = Sort<AchievementField>;

const AchievementsHeader: FunctionComponent<{
  onChange: ({ sort }: AchievementsAffectType) => void;
}> = ({ onChange }) => {
  const { t } = useTranslation();

  const EXPIRES_AT_DESC: AchievementSort = {
    direction: OrderDirection.Desc,
    field: AchievementField.ExpiresAt,
    label: t("expiryDateFuture"),
  };
  const EXPIRES_AT_ASC: AchievementSort = {
    direction: OrderDirection.Asc,
    field: AchievementField.ExpiresAt,
    label: t("expiryDatePast"),
  };
  const NAME_ASC: AchievementSort = {
    direction: OrderDirection.Asc,
    field: AchievementField.AchievementTypeName,
    label: t("alphabeticalAZ"),
  };
  const NAME_DESC: AchievementSort = {
    direction: OrderDirection.Desc,
    field: AchievementField.AchievementTypeName,
    label: t("alphabeticalZA"),
  };

  const formValues = useFormValues({
    sort: EXPIRES_AT_DESC,
  });

  return (
    <FutureArticleHeader
      extra={
        <div className="article-header--sort">
          <Form values={formValues}>
            <Select
              name="sort"
              label="Sort by"
              prepend
              onChange={(value: AchievementSort) => {
                if (!value) return null;
                const { label, ...sort } = value;
                onChange({ sort });
              }}
              options={[
                {
                  label: EXPIRES_AT_DESC.label || "",
                  value: EXPIRES_AT_DESC,
                },
                {
                  label: EXPIRES_AT_ASC.label || "",
                  value: EXPIRES_AT_ASC,
                },
                {
                  label: NAME_ASC.label || "",
                  value: NAME_ASC,
                },
                {
                  label: NAME_DESC.label || "",
                  value: NAME_DESC,
                },
              ]}
            />
          </Form>
        </div>
      }
    />
  );
};

export const AchievementDocumentResource: React.FunctionComponent<{}> = () => {
  const { token } = useGlobalStore();
  const history = useHistory();
  const { achievementId, documentId } = useParams<{achievementId:string, documentId:string}>();
  const { t } = useTranslation();

  let achievementUrl = `/certificate/achievement/${achievementId}`;

  if (documentId) {
    achievementUrl = `/achievements/${achievementId}/documents/${documentId}`;
  }

  return (
    <>
      <MenuBar
        type="player"
        back={{
          label: t("achievement"),
          onClick: () => history.goBack(),
        }}
      />
      <iframe
        title="certificate"
        src={`${achievementUrl}?access_token=${token}`}
        style={{
          width: "1px",
          minWidth: "100%",
          height: "calc(100% - 64px)",
          position: "absolute",
          left: 0,
          top: "64px",
          border: "none",
        }}
      />
    </>
  );
};
