import React, {
  FunctionComponent,
  useState,
  Fragment,
  useCallback,
} from "react";
import {
  Card,
  Alert,
  List,
  ListRow,
  ListCell,
  Pagination,
  Button,
  StatusLabel,
} from "@administrate/piston-ux";
import moment from "moment";
import { ColorOptions, Maybe } from "@administrate/piston-ux/lib/types";
import { ListEmpty, ListHeader } from "@administrate/piston-ux/lib/List";
import { useTranslation } from "react-i18next";
import { get, range } from "lodash";

import { MANAGED_REGISTRATIONS_FOR_CONTACT_QUERY } from "../../queries/registrations";
import {
  Registration,
  Query,
  OrderDirection,
  RegistrationField,
  ContactEdge,
  RegistrationEdge,
  LearningPathRegistration,
} from "../../generated/lmsTypes";
import {
  LearnerCoursesArticleHeader,
  LearnerCoursesFilters,
} from "./LearnerCoursesArticleHeader";
import { useParams } from "../../useHistory";
import { LearnerPage } from "./LearnerPage";
import { useLmsQuery } from "../../hooks/lms";
import { WEBLINK_BASIC_LOCATIONS_QUERY } from "../../queries/filters";
import { useWebLinkClient } from "../../hooks/weblink";
import { extractNodes } from "../../utils/extractNodes";
import { FilterOperation } from "../../generated/weblinkTypes";
import {
  startOfDayWithTimeZone,
  endOfDayWithTimeZone,
  formatDateRange,
} from "../../utils/dateTimeHelpers";
import { useViewer } from "../../providers/ViewerProvider";
import { useDateFormatter } from "../../hooks/useDateFormatter";
import {
  OutcomeInfo,
  getOutcomeInfo,
  LearningOutcomeUnion,
} from "../../utils/lmsLearningPathHelpers";
import { LearnerOutcomesModal } from "./LearnerOutcomesModal";
import {
  CancelLearnerModal,
  LearnerCategory,
} from "../LearnerManagement/CancelLearnerModal";
import { OptionsMenu } from "../../components/OptionsMenu";
import { TransferLearnerModal } from "../LearnerManagement/TransferLearnerModal";

export const LearnerCourses: FunctionComponent = () => {
  const { id: learnerId } = useParams<{id:string}>();

  return (
    <LearnerPage page="courses" id={learnerId}>
      <CourseRegistrations learnerId={learnerId} />
    </LearnerPage>
  );
};

const CourseRegistrations: FunctionComponent<{
  learnerId: string;
}> = ({ learnerId }) => {
  const { t } = useTranslation();
  const [cancellingRegistrationId, setCancellingRegistrationId] =
    useState<string | null>(null);
  const [transferringLearnerId, setTransferringLearnerId] =
    useState<string | null>(null);
  const [transferringBookingId, setTransferringBookingId] =
    useState<string | null>(null);
  const [courseOffset, setCourseOffset] = useState(0);
  const [affectBarFilters, setAffectBarFilters] =
    useState<LearnerCoursesFilters>({
      search: "",
    });
  const initialAmountToLoad = 10;
  const { viewer } = useViewer();
  const viewerTimeZone = viewer?.timeZoneName ?? moment.tz.guess();

  const {
    data: courseData,
    loading: courseLoading,
    error: courseError,
    refetch: courseRefetch,
  } = useLmsQuery<Query>(MANAGED_REGISTRATIONS_FOR_CONTACT_QUERY, {
    variables: {
      contactId: learnerId,
      registrationFilters: getFilters(affectBarFilters, viewerTimeZone),
      offset: courseOffset,
      first: initialAmountToLoad,
      order: {
        field: RegistrationField.Id,
        direction: OrderDirection.Desc,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });
  const contact = get(courseData, "coordinatorManagedContacts.edges", []).map(
    (edge: ContactEdge) => edge.node,
  )[0];
  const registrations = get(contact, "registrations.edges", [])
    .map((edge: Maybe<RegistrationEdge>) => (edge ? edge.node : null))
    .filter(
      (node: Registration | null | undefined) =>
        node !== undefined && node !== null,
    );
  const courseTotalRecords = get(
    contact,
    "registrations.pageInfo.totalRecords",
    0,
  );
  const client = useWebLinkClient();
  const locationOptions = (inputValue: string) =>
    client
      .query({
        query: WEBLINK_BASIC_LOCATIONS_QUERY,
        variables: {
          filters: [
            {
              field: "name",
              operation: "like",
              value: `%${inputValue}%`,
            },
          ],
        },
      })
      .then(result => extractNodes(result.data.locations.edges));

  const affectBarChange = useCallback(
    filters => {
      setAffectBarFilters(filters);
      setCourseOffset(0);
    },
    [setAffectBarFilters, setCourseOffset],
  );

  return (
    <>
      <Card id="learner-courses">
        <LearnerCoursesArticleHeader
          onChange={affectBarChange}
          locationOptions={locationOptions}
          loading={courseLoading}
        />
        <List loading={courseLoading}>
          <ListHeader
            gridColumns={`${courseGridColumns} 160px`}
            headings={[
              { title: t("name") },
              { title: t("result") },
              { title: t("location") },
              { title: t("dateAndTime") },
            ]}
          />
          {courseLoading ? (
            <CourseRegistrationListLoading />
          ) : registrations.length > 0 ? (
            <CourseRegistrationList
              registrations={registrations}
              onCancelRegistrationClick={setCancellingRegistrationId}
              onTransferRegistrationClick={registration => {
                setTransferringLearnerId(registration.id);
                setTransferringBookingId(registration.course?.id!);
              }}
            />
          ) : (
            <ListEmpty text={t("noResults")} />
          )}
          {courseError && (
            <div className="m-4">
              <Alert message={t("somethingWentWrong")} />
            </div>
          )}
          {!courseLoading &&
            registrations.length > 0 &&
            courseTotalRecords &&
            courseTotalRecords > initialAmountToLoad && (
              <Pagination
                totalRecords={courseTotalRecords}
                offset={courseOffset}
                setOffset={newOffset => {
                  courseRefetch({
                    contactId: learnerId,
                    first: initialAmountToLoad,
                    offset: newOffset,
                  });
                  setCourseOffset(newOffset);
                }}
                limit={initialAmountToLoad}
              />
            )}
        </List>
      </Card>
      {!!cancellingRegistrationId && (
        <CancelLearnerModal
          onModalClose={submitted => {
            if (submitted) {
              courseRefetch();
            }
            setCancellingRegistrationId(null);
          }}
          learnerId={cancellingRegistrationId}
          learnerCategory={LearnerCategory.Event}
        />
      )}
      {transferringLearnerId && transferringBookingId && (
        <TransferLearnerModal
          onModalClose={submitted => {
            if (submitted) {
              courseRefetch();
            }
            setTransferringLearnerId(null);
            setTransferringBookingId(null);
          }}
          learnerId={transferringLearnerId}
          bookingId={transferringBookingId}
        />
      )}
    </>
  );
};

const courseGridColumns = "4fr 1fr 2fr 2fr";
const CourseRegistrationList: FunctionComponent<{
  registrations: (Registration | null | undefined)[];
  onCancelRegistrationClick: (registrationId: string) => void;
  onTransferRegistrationClick: (registration: Registration) => void;
}> = ({
  registrations,
  onCancelRegistrationClick,
  onTransferRegistrationClick,
}) => {
  const { t } = useTranslation();
  const { dateFormat } = useDateFormatter({ showTime: true });
  const [selectedOutcomes, setSelectedOutcomes] =
    useState<(OutcomeInfo | null)[] | null>(null);
  const [selectedPathTitle, setSelectedPathTitle] =
    useState<string | undefined>(undefined);

  const handleClickPath = (
    learningPathRegistration: LearningPathRegistration,
  ) => {
    const outcomes = get(learningPathRegistration, "results.edges", []).map(
      (edge: { node: LearningOutcomeUnion }) =>
        edge ? getOutcomeInfo(edge.node) : null,
    );
    setSelectedPathTitle(learningPathRegistration.learningPath?.name);
    setSelectedOutcomes(outcomes);
  };
  return (
    <Fragment>
      {registrations.map((registration, i: number) => {
        if (!registration) return null;
        const {
          title,
          location,
          lastAccessed,
          start,
          end,
          learningPathRegistration,
          hasPassed,
        } = getCourseRegistrationListData(registration);
        const lastAccessedDate = moment(lastAccessed);
        const lastAccessedString = lastAccessedDate.isValid()
          ? lastAccessedDate.format("ll")
          : t("notAccessed");
        return (
          <ListRow
            key={`$registration-row-${registration.id}`}
            gridColumns={courseGridColumns}
            extra={
              <>
                {learningPathRegistration && (
                  <Button
                    label={t("learningPath")}
                    onClick={() => handleClickPath(learningPathRegistration)}
                    type="suppressed"
                  />
                )}
                <OptionsMenu
                  optionsMenuItems={[
                    {
                      label: t("transferLearner"),
                      onClick: () => onTransferRegistrationClick(registration),
                      disabled: !!hasPassed,
                    },
                    {
                      label: t("cancelLearner"),
                      onClick: () => onCancelRegistrationClick(registration.id),
                      disabled: !!hasPassed,
                    },
                  ]}
                />
              </>
            }
            extraWidth={150}
            dataLabel={title}
          >
            <ListCell label={t("name")}>
              {title}
              <div>
                <span className="mt-2 d-block" style={{ fontSize: 12 }}>
                  <em>
                    ({t("updateLast")}: {lastAccessedString})
                  </em>
                </span>
              </div>
            </ListCell>
            <ListCell label={t("result")}>
              <LearnerCourseStatusLabel passed={hasPassed} />
            </ListCell>
            <ListCell label={t("location")}>{location}</ListCell>
            <ListCell label={t("date")}>
              {formatDateRange(start, end, dateFormat)}
            </ListCell>
          </ListRow>
        );
      })}
      {selectedOutcomes && (
        <LearnerOutcomesModal
          title={selectedPathTitle}
          outcomes={selectedOutcomes}
          onDone={() => {
            setSelectedPathTitle(undefined);
            setSelectedOutcomes(null);
          }}
        />
      )}
    </Fragment>
  );
};

const LearnerCourseStatusLabel: FunctionComponent<{
  passed?: Maybe<boolean>;
}> = ({ passed }) => {
  if (passed) {
    return <StatusLabel text="Passed" color={ColorOptions.Green} />;
  }
  if (passed === false) {
    return <StatusLabel text="Failed" color={ColorOptions.Red} />;
  }
  return <></>;
};

const CourseRegistrationListLoading: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {range(4).map(i => (
        <Fragment key={i}>
          <ListRow gridColumns={`${courseGridColumns} 120px`}>
            <ListCell label={t("name")} loading />
            <ListCell label={t("result")} loading />
            <ListCell label={t("location")} loading />
            <ListCell label={t("date")} loading />
          </ListRow>
        </Fragment>
      ))}
    </Fragment>
  );
};

const getFilters = (
  affectFilters: LearnerCoursesFilters,
  timeZoneName: string,
) => {
  const filters = [];

  if (affectFilters.search) {
    filters.push({
      field: RegistrationField.CourseTitle,
      operation: FilterOperation.Like,
      value: `%${affectFilters.search}%`,
    });
  }
  if (affectFilters.status) {
    if (affectFilters.status.selected?.value === "active") {
      filters.push({
        field: RegistrationField.Active,
        operation: FilterOperation.Eq,
        value: `true`,
      });
    }
    if (affectFilters.status.selected?.value === "isComplete") {
      filters.push({
        field: RegistrationField.IsComplete,
        operation: FilterOperation.Eq,
        value: `true`,
      });
    }
  }
  if (affectFilters.location) {
    filters.push({
      value: `${affectFilters.location.name}`,
      operation: FilterOperation.Eq,
      field: RegistrationField.CourseLocationName,
    });
  }
  if (affectFilters.dates) {
    const { from, to } = affectFilters.dates;
    if (from) {
      filters.push({
        value: startOfDayWithTimeZone(from, timeZoneName),
        operation: FilterOperation.Ge,
        field: RegistrationField.CourseStart,
      });
    }
    if (to) {
      filters.push({
        value: endOfDayWithTimeZone(to, timeZoneName),
        operation: FilterOperation.Le,
        field: RegistrationField.CourseEnd,
      });
    }
  }

  return filters;
};

const getCourseRegistrationListData = (registration: Registration) => {
  const { course, lastAccessed } = registration;

  return {
    title: course && course.title ? course.title : "Unknown",
    location: course && course.location ? course.location.name : "Unknown",
    start: course ? course.start : null,
    end: course ? course.end : null,
    lastAccessed: lastAccessed,
    learningMode: course ? course.learningMode : null,
    learningPathRegistration: registration.learningPathRegistration,
    hasPassed: registration.hasPassed,
  };
};
