import gql from "graphql-tag";

import {
  PAGE_INFO_FRAGMENT,
  TRAINING_REQUEST_FRAGMENT,
  ERROR_FRAGMENT,
} from "./fragments";

export const TRAINING_REQUEST_MUTATION = gql`
  mutation createTrainingRequest($input: CreateTrainingRequestInputType!) {
    trainingRequest {
      createTrainingRequest(input: $input) {
        errors {
          ...Error
        }
        trainingRequest {
          id
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const TRAINING_REQUESTS_QUERY = gql`
  query trainingRequests(
    $filters: [TrainingRequestFieldFilter]
    $order: TrainingRequestFieldOrder
    $first: Int
    $offset: Int
    $after: String
  ) {
    trainingRequests(
      filters: $filters
      first: $first
      offset: $offset
      order: $order
      after: $after
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          ...TrainingRequest
        }
      }
    }
  }
  ${TRAINING_REQUEST_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;

export const TRAINING_REQUEST_WORKFLOW_CONFIGURATION = gql`
  query requestWorkflow {
    store {
      trainingRequestWorkflow {
        enabled
        id
        successMessage
        workflowFields {
          id
          label
          position
          required
          type
          options {
            value
            label
          }
        }
      }
    }
  }
`;

export const REQUEST_TRAINING_MUTATION = gql`
  mutation requestTraining($input: TrainingRequestInput!) {
    requestTraining(input: $input) {
      trainingRequest {
        id
      }
      errors {
        label
        value
        message
      }
    }
  }
`;
