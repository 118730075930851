import React, { FunctionComponent } from "react";
import {
  Card,
  FutureArticleHeader,
  Placeholder,
} from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { Event, Cart, LearningPath } from "../../generated/weblinkTypes";
import { CartItems } from "./CartItems";
import { getFinancialFormat } from "../../utils/displayHelpers";
import { LearningPathLocation } from "../Catalog/LearningPaths";
import { Event as TrainingRequestEvent } from "../../types/Event";
import { BookingStep } from "./BookingPage";
import { ItemSummary } from "../../components/ItemSummary";
import { useViewer } from "../../providers/ViewerProvider";
import { DateRange, EventDateRange } from "../../components/EventDateRange";

type OrderSummaryType = {
  event?: Event;
  currencyCode?: string;
  loading: boolean;
  cart?: Cart | null;
  path?: LearningPath;
  trainingRequest?: TrainingRequestEvent;
  hasAccount?: boolean;
  step: BookingStep;
  hidePrices: boolean;
  isZeroPriced: boolean;
};

export const OrderSummary: FunctionComponent<OrderSummaryType> = ({
  event,
  loading,
  cart,
  path,
  trainingRequest,
  hasAccount,
  step,
  hidePrices,
  isZeroPriced,
}) => {
  const { viewer } = useViewer();
  const { t } = useTranslation();

  return (
    <Card>
      <FutureArticleHeader title={t("summary")} />
      {loading && <OrderSummaryLoading />}
      {!loading && event && (
        <ItemSummary
          name={event.name}
          location={
            event.location &&
            `${event.location.name}${
              event.location.region && `, ${event.location.region.name}`
            }`
          }
          date={<EventDateRange event={event} />}
          price={
            <>
              {getFinancialFormat(
                event.prices[0].amount,
                event.prices[0].financialUnit,
                viewer?.locale,
              )}
            </>
          }
          hidePrices={hidePrices}
          isZeroPriced={isZeroPriced}
        />
      )}
      {!loading && path && (
        <ItemSummary
          name={path.name}
          date={<DateRange start={path.start} end={path.end} />}
          price={
            <>
              {getFinancialFormat(
                path.prices[0].amount,
                path.prices[0].financialUnit,
                viewer?.locale,
              )}
            </>
          }
          location={<LearningPathLocation path={path} />}
          hidePrices={hidePrices}
          isZeroPriced={isZeroPriced}
        />
      )}
      {!loading && trainingRequest && (
        <ItemSummary
          name={trainingRequest.name ? trainingRequest.name : ""}
          date={
            <DateRange
              start={trainingRequest.start}
              end={trainingRequest.end}
            />
          }
          location={
            trainingRequest.location &&
            `${trainingRequest.location.name}${
              trainingRequest.location.region
                ? `, ${trainingRequest.location.region.name}`
                : ""
            }`
          }
          quantity={1}
          hidePrices={hidePrices}
          isZeroPriced={true}
        />
      )}
      {!loading && cart && (
        <CartItems
          cart={cart}
          hidePrices={
            !hasAccount && hidePrices && step === BookingStep.bookingDetails
          }
          isZeroPriced={isZeroPriced}
        />
      )}
    </Card>
  );
};

const OrderSummaryLoading: FunctionComponent = () => {
  return (
    <div>
      <p>
        <Placeholder width={200} />
      </p>
      <p>
        <Placeholder width={50} />
      </p>
      <p>
        <Placeholder width={75} />
      </p>
    </div>
  );
};
