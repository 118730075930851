import React from "react";
import {
  Modal,
  useFormValues,
  Input,
  Typeahead,
  Col,
  Row,
} from "@administrate/piston-ux";
import { AccountWithAncestors } from "../../components/Ancestors";
import { AccountNode } from "../../types/LearnerManagement";
import { useTranslation } from "react-i18next";

export const AddLearnerModal: React.FunctionComponent<{
  onDone: Function;
  accountOptions: any;
}> = ({ onDone, accountOptions }) => {
  const { t } = useTranslation();
  const initialValues: any = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    account: null,
    jobTitle: "",
  };
  const values = useFormValues(initialValues);
  return (
    <Modal
      show
      title={t("addLearner")}
      onDone={(submitted: boolean) => onDone(submitted, values)}
      values={values}
      primaryActionText={t("OK")}
      id="add-learner-modal"
    >
      <Row className="add-learner-modal">
        <Col md={6}>
          <Input
            name="firstName"
            label={t("firstName")}
            valid={v => (!!v ? true : `${t("firstNameIsRequired")}`)}
          />
        </Col>
        <Col md={6}>
          <Input
            name="lastName"
            label={t("lastName")}
            valid={v => (!!v ? true : `${t("lastNameIsRequired")}`)}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Input name="jobRole" label={t("jobRole")} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Input
            name="emailAddress"
            label={t("emailAddress")}
            valid={v => (!!v ? true : `${t("emailAddressIsRequired")}`)}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="account-hierarchy">
            <Typeahead
              label={t("account")}
              name="account"
              loadOptions={accountOptions}
              formatter={(coordinatorManagedAccounts: AccountNode) =>
                AccountWithAncestors(coordinatorManagedAccounts)
              }
              valid={v => (!!v ? true : `${t("accountIsRequired")}`)}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};
