import React, { FunctionComponent } from "react";
import {
  ListRow,
  ListCell,
  ListDrawer,
  Row,
  Col,
  SafeHTMLDisplay,
  Scrollable,
} from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { useHistory } from "../../useHistory";
import { ContentListProps } from "./ContentList";
import { OptionalContentPrompt } from "./OptionalContentPrompt";
import { ObjectiveStatus } from "@administrate/piston-ux";
import { RemainingAttempts } from "./RemainingAttempts";
import { courseDescriptionForType } from "./CourseDetailList";
import {
  TrainingPassContentStatusLabel,
  getTrainingPassTypesForContent,
} from "../TrainingPass/ContentStatusLabel";

export const PlayerContentList: FunctionComponent<
  ContentListProps & {
    isActive: boolean;
    contentUrl: string;
  }
> = ({
  passTypes,
  hasLockedPremiumContent,
  includedInPassTypeIds,
  contentId,
  learnerPasses,
  contentLocked,
  typeLabel,
  type,
  name,
  description,
  results,
  isActive,
  remainingAttempts,
  isRequired,
  dataLabel,
  contentUrl,
  instructions,
  externalActivityInstructions,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const onContentClick = () => {
    if (!contentLocked && !hasLockedPremiumContent) {
      history.push(contentUrl);
    }
  };
  const descriptionForType = courseDescriptionForType(
    type,
    description,
    instructions,
    externalActivityInstructions,
  );
  const shouldDisplayDescription =
    descriptionForType && !contentLocked && !hasLockedPremiumContent;
  return (
    <ListRow
      extraClass={`content-menu--row ${isActive ? "active" : ""}`}
      disabled={contentLocked || hasLockedPremiumContent}
      dataLabel={dataLabel}
      extra={
        hasLockedPremiumContent &&
        learnerPasses && (
          <TrainingPassContentStatusLabel
            courseCardId={contentId}
            learnerPasses={learnerPasses}
            contentPasses={getTrainingPassTypesForContent(
              passTypes,
              includedInPassTypeIds,
            )}
            hasPricing={false}
          />
        )
      }
    >
      <ListCell>
        <div
          onClick={onContentClick}
          onKeyPress={onContentClick}
          tabIndex={0}
          role="button"
          aria-label={name || typeLabel}
          aria-disabled={contentLocked || hasLockedPremiumContent}
          className={`content-menu--block ${
            shouldDisplayDescription ? "content-menu--block-drawer" : ""
          }`}
        >
          <div className="content-menu--block-left" />
          <Row>
            <Col xs={6}>
              <span className="content-menu--typeLabel">{typeLabel}</span>
            </Col>
            {!hasLockedPremiumContent && results && (
              <Col xs={6} className="text-right">
                <ObjectiveStatus
                  results={results}
                  type={type}
                  active={isActive}
                  size="xs"
                />
              </Col>
            )}
          </Row>
          {name && (
            <Row>
              <Col xs={12}>
                <p className="content-menu--name">{name}</p>
              </Col>
            </Row>
          )}
          {remainingAttempts !== undefined && (
            <Row>
              <Col xs={12}>
                <div className="float-right float-sm-none">
                  <RemainingAttempts
                    remainingAttempts={remainingAttempts}
                    contentLocked={contentLocked || hasLockedPremiumContent}
                  />
                </div>
              </Col>
            </Row>
          )}
          {!isRequired && (
            <Row>
              <Col xs={12}>
                <OptionalContentPrompt />
              </Col>
            </Row>
          )}
        </div>
        {shouldDisplayDescription && (
          <ListDrawer title={t("description")} open={false}>
            <Scrollable
              axis="y"
              className="content-menu--description--scrollable"
            >
              <div className="content-menu--description">
                <SafeHTMLDisplay html={descriptionForType ?? ""} />
              </div>
            </Scrollable>
          </ListDrawer>
        )}
      </ListCell>
    </ListRow>
  );
};
