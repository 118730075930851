import produce from "immer";
import { CourseRegistrationResponse } from "../types/Registration";
import _ from "lodash";
import { FetchMoreCallback } from "../types/ApolloCallbacks";

export const fetchMoreCourseDetailsWrapper = (
  offset: number,
  fetchMoreCourseDetails: FetchMoreCallback,
) => {
  return fetchMoreCourseDetails({
    variables: { offset: offset },
    updateQuery: (
      prev: CourseRegistrationResponse,
      options: { fetchMoreResult?: CourseRegistrationResponse },
    ) => {
      return produce(prev, draft => {
        if (!options.fetchMoreResult) return;
        if (prev.registrations) {
          draft.registrations.edges[0].node.contentResults.edges = _.unionBy(
            prev.registrations.edges[0].node.contentResults.edges,
            options.fetchMoreResult.registrations.edges?.[0].node.contentResults
              .edges,
            "node.id",
          );
          draft.registrations.edges[0].node.course.content.pageInfo =
            options.fetchMoreResult.registrations.edges[0].node.course.content.pageInfo;
          draft.registrations.edges[0].node.course.content.edges = _.unionBy(
            prev.registrations.edges[0].node.course.content.edges,
            options.fetchMoreResult.registrations.edges[0].node.course.content
              .edges,
            "node.id",
          );
        } else {
          draft.coursePreview.edges[0].node.course.content.pageInfo =
            options.fetchMoreResult.coursePreview.edges[0].node.course.content.pageInfo;
          draft.coursePreview.edges[0].node.course.content.edges = _.unionBy(
            prev.coursePreview.edges[0].node.course.content.edges,
            options.fetchMoreResult.coursePreview.edges[0].node.course.content
              .edges,
            "node.id",
          );
        }
      });
    },
  });
};
