import { Bookable, LearningPath, Event } from "../../generated/weblinkTypes";

export const bookableIsLearningPath = (
  bookable: Bookable,
): bookable is LearningPath => {
  return (bookable as LearningPath).__typename === "LearningPath";
};

export const bookableIsEvent = (bookable: Bookable): bookable is Event => {
  return (bookable as Event).__typename === "Event";
};
