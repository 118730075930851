import React, { useState, Fragment, FunctionComponent } from "react";
import {
  Checkbox,
  Form,
  useFormValues,
  Button,
  SectionHeader,
  List,
  ListRow,
  ListCell,
  StatusLabel,
  Card,
  Row,
  Col,
} from "@administrate/piston-ux";
import { Link } from "react-router-dom";
import { NetworkStatus } from "apollo-boost";
import { useTranslation } from "react-i18next";
import { ColorOptions } from "@administrate/piston-ux/lib/types";

import { useDateFormatter } from "../../hooks/useDateFormatter";
import { TRAINING_REQUESTS_QUERY } from "../../queries/trainingRequests";
import {
  TrainingRequestsResponse,
  TrainingRequest,
} from "../../types/TrainingRequest";
import { useLmsQuery } from "../../hooks/lms";

export const requestGridColumns = "4fr 1fr 2fr 200px";

export const TrainingRequests: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const initialValues = {
    showAllCourseRequests: false,
  };
  const formValues = useFormValues(initialValues);

  // Had to use nextOffset for fetchMore, because LMS queries opportunities and list out interests
  // --> Offset will not equal to trainingRequests.length
  const pagination = 6;
  const [nextTrainingRequestsOffset, setNextTrainingRequestsOffset] =
    useState(pagination);
  const [showAllCourseRequests, setShowAllCourseRequests] = useState(
    initialValues.showAllCourseRequests,
  );
  const {
    loading: trainingRequestsLoading,
    data: trainingRequestsResponse,
    fetchMore: fetchMoreTrainingRequests,
    networkStatus,
  } = useLmsQuery<TrainingRequestsResponse>(TRAINING_REQUESTS_QUERY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      offset: 0,
      first: pagination,
      order: { field: "updatedAt", direction: "asc" },
      ...(showAllCourseRequests
        ? {}
        : {
            filters: [
              {
                field: "state",
                operation: "ne",
                value: "Won",
              },
              {
                field: "state",
                operation: "ne",
                value: "Lost",
              },
            ],
          }),
    },
  });
  const trainingRequests =
    trainingRequestsResponse?.trainingRequests?.edges || [];
  const hasNextTrainingRequestPage =
    trainingRequestsResponse?.trainingRequests?.pageInfo?.hasNextPage ?? false;

  return (
    <article>
      <SectionHeader titleLevel="h2" title={t("courseRequests")}>
        <Form values={formValues}>
          <div className="single-checkbox-filter">
            <Checkbox
              name="showAllCourseRequests"
              description={t("showAllCourseRequests")}
              onChange={newValue => {
                setShowAllCourseRequests(newValue);
                setNextTrainingRequestsOffset(pagination);
              }}
            />
          </div>
        </Form>
      </SectionHeader>
      <Card>
        <List>
          {(networkStatus === NetworkStatus.fetchMore ||
            networkStatus === NetworkStatus.ready) && (
            <Fragment>
              {trainingRequests.map(({ node: trainingRequest }) => {
                const eventId = trainingRequest.training?.id;
                const opportunityId = trainingRequest.id;
                const registerableId = trainingRequest.registerableId;

                if (!registerableId) {
                  return (
                    <Fragment key={`${opportunityId}:${eventId}`}>
                      <CourseRequestRow trainingRequest={trainingRequest} />
                    </Fragment>
                  );
                }
                return (
                  <Link
                    to={`/my-courses/course/${registerableId}`}
                    className="no-underline"
                    key={`${opportunityId}:${eventId}`}
                  >
                    <CourseRequestRow trainingRequest={trainingRequest} />
                  </Link>
                );
              })}
            </Fragment>
          )}

          {trainingRequestsLoading ? (
            <LoadingRequests />
          ) : (
            <Row>
              <Col style={{ textAlign: "center" }}>
                {hasNextTrainingRequestPage && (
                  <Button
                    label={t("showMore")}
                    type="suppressed"
                    onClick={() => {
                      fetchMoreTrainingRequests({
                        variables: { offset: nextTrainingRequestsOffset },
                        updateQuery: (prev, { fetchMoreResult }) => {
                          return fetchMoreResult
                            ? {
                                ...prev,
                                trainingRequests: {
                                  ...prev.trainingRequests,
                                  pageInfo:
                                    fetchMoreResult.trainingRequests.pageInfo,
                                  edges: [
                                    ...prev.trainingRequests.edges,
                                    ...fetchMoreResult.trainingRequests.edges,
                                  ],
                                },
                              }
                            : prev;
                        },
                      });
                      setNextTrainingRequestsOffset(
                        nextTrainingRequestsOffset + pagination,
                      );
                    }}
                  />
                )}
              </Col>
            </Row>
          )}
        </List>
      </Card>
    </article>
  );
};

const CourseRequestRow: FunctionComponent<{
  trainingRequest: TrainingRequest;
}> = ({ trainingRequest }) => {
  const { t } = useTranslation();
  const { dateToDateFormat } = useDateFormatter();

  const start = trainingRequest.training?.start;
  const end = trainingRequest.training?.end;

  return (
    <ListRow gridColumns={requestGridColumns}>
      <ListCell label={t("course")}>
        {trainingRequest.training?.title
          ? trainingRequest.training?.title
          : "-"}
      </ListCell>
      <ListCell label={t("location")} icon="location">
        {trainingRequest.training?.location
          ? trainingRequest.training?.location
          : t("unknown")}
      </ListCell>
      <ListCell label={t("date")} icon="calendar">
        {dateToDateFormat({ start, end })}
      </ListCell>
      <ListCell label={t("status")}>
        <ApprovalTag state={trainingRequest.state} />
      </ListCell>
    </ListRow>
  );
};

export const ApprovalTag: FunctionComponent<{
  state: string;
}> = ({ state }) => {
  const { t } = useTranslation();
  switch (state) {
    case "Won":
      return <StatusLabel text={t("approved")} color={ColorOptions.Green} />;
    case "Lost":
      return (
        <StatusLabel text={t("rejected")} color={ColorOptions.LightGrey} />
      );
    case "Archived":
      return <StatusLabel text={t("waitList")} color={ColorOptions.Blue} />;
    default:
      return (
        <StatusLabel text={t("awaitingApproval")} color={ColorOptions.Yellow} />
      );
  }
};

export const LoadingRequests: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {[...Array(3)].map((_, i) => (
        <Fragment key={i}>
          <ListRow gridColumns={requestGridColumns}>
            <ListCell label={t("course")} loading />
            <ListCell label={t("location")} icon="location" loading />
            <ListCell label={t("date")} icon="calendar" loading />
            <ListCell label={t("status")} loading />
          </ListRow>
        </Fragment>
      ))}
    </Fragment>
  );
};
