import React, { useCallback, useMemo, useEffect } from "react";
import {
  Form,
  useFormValues,
  Card,
  Submit,
  FutureArticleHeader,
  FormActions,
  useDetailFormState,
  FormStateStatus,
  ValidationErrors,
  Prompt,
} from "@administrate/piston-ux";
import { reduce } from "lodash";

import { Viewer, CustomFieldValueInputType } from "../../types/Viewer";
import { usePortal } from "../../providers/PortalProvider";
import { useViewer } from "../../providers/ViewerProvider";
import {
  ContactDetailForm,
  StaticContactInputs,
} from "../../forms/ContactDetailForm";
import { useTranslation } from "react-i18next";
import { ProfileSettings, ViewerUpdateInput } from "../../generated/lmsTypes";

type ContactDetailProps = {
  viewer: Viewer;
};

export const ContactDetail: React.FunctionComponent<ContactDetailProps> = ({
  viewer,
}) => {
  const { t } = useTranslation();
  const { profileSettings, isCustomViewerFieldsLoading, customViewerFields } =
    usePortal();
  const {
    updateViewerMutation,
    updateViewerMutationIsLoading,
    updateViewerErrors,
  } = useViewer();

  const { messages, setMessages, saveState, setSaveState } =
    useDetailFormState();

  const viewerCustomFieldValues = viewer.customFieldValues || [];

  const savedCustomFieldValues = useMemo(
    () =>
      reduce(
        viewerCustomFieldValues,
        (initialValues: any, { definitionKey, value }) => {
          initialValues[definitionKey] = value;
          return initialValues;
        },
        {},
      ),
    [viewer], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const editable =
    viewerCustomFieldValues.length > 0 ||
    profileSettings?.allowEditJobTitle ||
    profileSettings?.allowEditName;

  const initialValues = {
    firstName: viewer.firstName,
    lastName: viewer.lastName,
    emailAddress: viewer.emailAddress,
    jobRole: viewer.jobRole,
    ...savedCustomFieldValues,
  };

  const values = useFormValues(initialValues);

  const onSubmit = useCallback(
    results => {
      setSaveState("saving");
      if (!results) setSaveState("failed");
      if (results) {
        const updateResourceVariables = getUpdateViewerVariables(
          results,
          profileSettings,
        );
        const customFieldValues: Array<CustomFieldValueInputType> = reduce(
          results,
          (
            customFieldValues: Array<CustomFieldValueInputType>,
            value: string,
            key: string,
          ) => {
            if (!(key in StaticContactInputs)) {
              customFieldValues.push({
                definitionKey: key,
                value: value,
              });
            }
            return customFieldValues;
          },
          [],
        );
        updateViewerMutation({
          variables: {
            customFieldValues,
            jobRole: updateResourceVariables.jobRole,
            personalName: updateResourceVariables.personalName,
          } as ViewerUpdateInput,
        });
      }
    },
    [setSaveState, updateViewerMutation, profileSettings],
  );

  useEffect(() => {
    if (updateViewerErrors.length) {
      setMessages({
        errors: (
          <ValidationErrors
            title={t("Error occurred during update")}
            mutationValidationErrors={updateViewerErrors}
          />
        ),
      });
    }
  }, [setMessages, setSaveState, t, updateViewerErrors]);

  useEffect(() => {
    if (
      !updateViewerMutationIsLoading &&
      saveState === "saving" &&
      !updateViewerErrors.length
    ) {
      setSaveState("saved");
      setMessages({ success: t("Successful profile update") });
    }
  }, [
    saveState,
    setMessages,
    setSaveState,
    t,
    updateViewerErrors.length,
    updateViewerMutationIsLoading,
  ]);

  const getContactAdminInfo = () => {
    // We eventually will allow a user to update location info from this page,
    // when we do we will only show the error message this controls conditionally
    const allowEditLocation = false;

    if (
      !allowEditLocation &&
      !profileSettings?.allowEditJobTitle &&
      !profileSettings?.allowEditName
    ) {
      return t("contact_admin_to_update_contact_info");
    }

    return t("contact_admin_to_update_some_contact_info");
  };

  return (
    <div className="profile-personal">
      <Form
        values={values}
        onSubmit={onSubmit}
        disabled={updateViewerMutationIsLoading}
      >
        <Card>
          <FutureArticleHeader
            title={t("personalInformation")}
            extra={
              <div className="mt-3">
                <Prompt type="info" message={getContactAdminInfo()} />
              </div>
            }
          />
          <FormStateStatus saveState={saveState} messages={messages} />
          <ContactDetailForm
            customViewerFields={customViewerFields}
            isCustomViewerFieldsLoading={isCustomViewerFieldsLoading}
          />
          {editable && (
            <FormActions>
              {updateViewerMutationIsLoading && (
                <span
                  className="glyphicon glyphicon-refresh glyphicon-spinner"
                  style={{ marginRight: "5px" }}
                />
              )}
              <Submit
                label={t("updateProfile")}
                inline
                disabled={
                  isCustomViewerFieldsLoading || updateViewerMutationIsLoading
                }
              />
            </FormActions>
          )}
        </Card>
      </Form>
    </div>
  );
};

const getUpdateViewerVariables = (
  results: {
    jobRole: string;
    firstName: string;
    lastName: string;
  },
  profileSettings?: ProfileSettings,
): ViewerUpdateInput => {
  let variables = {};

  if (profileSettings?.allowEditJobTitle) {
    variables = {
      ...variables,
      jobRole: results.jobRole,
    };
  }

  if (profileSettings?.allowEditName) {
    variables = {
      ...variables,
      personalName: {
        firstName: results.firstName,
        lastName: results.lastName,
      },
    };
  }

  return variables;
};
