import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import _ from "lodash";
import {
  DateTimeInput,
  FutureInputFilter,
  RadioButtons,
  Select,
} from "@administrate/piston-ux";
import {
  formatFilterDateRange,
  formatWeekNumberRange,
  getCurrentYear,
  getWeeksInYear,
  isNotInPast,
} from "../../utils/dateTimeHelpers";
import { useDateFormatter } from "../../hooks/useDateFormatter";
import {
  CatalogFilter,
  useCatalogFilters,
} from "../../pages/Catalog/CatalogFilterContext";

type BookableDateFilterProps = {
  values: CatalogFilter;
};

export const BookableDateFilter: FunctionComponent<BookableDateFilterProps> =
  observer(({ values }) => {
    const { t } = useTranslation();

    const { filters, setFilterValue } = useCatalogFilters();

    const { simpleDateFormat } = useDateFormatter({ showDayName: true });

    const clearDateFilters = () => {
      setFilterValue("fromDate", undefined);
      setFilterValue("toDate", undefined);
    };

    const clearWeekFilters = () => {
      setFilterValue("fromWeek", undefined);
      setFilterValue("toWeek", undefined);
      setFilterValue("year", undefined);
    };

    const onSelected = () => {
      if (filters.fromDate || filters.toDate) {
        return formatFilterDateRange(
          { from: filters.fromDate, to: filters.toDate },
          simpleDateFormat,
        );
      }
      if (filters.fromWeek || filters.toWeek) {
        return formatWeekNumberRange(filters.fromWeek, filters.toWeek);
      }
      return "";
    };

    const onSubmit = () => {
      if (values.dateFilterType === "date") {
        setFilterValue("fromDate", values.fromDate);
        setFilterValue("toDate", values.toDate);
        clearWeekFilters();
      }
      if (values.dateFilterType === "week") {
        setFilterValue("fromWeek", values.fromWeek);
        setFilterValue("toWeek", values.toWeek);
        setFilterValue("year", values.year);
        clearDateFilters();
      }
    };

    const onClear = (values: CatalogFilter) => {
      if (values.fromDate || values.toDate) {
        values.fromDate = undefined;
        values.toDate = undefined;
      }
      if (values.fromWeek || values.toWeek || values.year) {
        values.fromWeek = undefined;
        values.toWeek = undefined;
        values.year = getCurrentYear();
      }
      clearDateFilters();
      clearWeekFilters();
    };

    return (
      <FutureInputFilter
        label={t("dates")}
        selected={onSelected()}
        onSubmit={onSubmit}
        onClear={() => onClear(values)}
        id="date-filter-all-dates-catalog"
      >
        <RadioButtons
          label={t("Filter by")}
          name="dateFilterType"
          options={[
            { label: "Date", value: "date" },
            { label: "Week", value: "week" },
          ]}
          onChange={(value: string) => {
            setFilterValue("dateFilterType", value);
          }}
          type={"button"}
        ></RadioButtons>
        {values.dateFilterType === "date" ? (
          <>
            <DateTimeInput
              label={t("startDate")}
              type="date"
              name="fromDate"
              placeholder={t("today")}
              isValidDate={isNotInPast}
            />
            <DateTimeInput
              label={t("endDate")}
              type="date"
              placeholder={t("future")}
              name="toDate"
              isValidDate={isNotInPast}
            />
          </>
        ) : (
          <>
            <Select
              label={t("startWeek")}
              name="fromWeek"
              options={getFromWeekOptions(values)}
              disabled={!values.year}
              onChange={newValue => {
                if (Number(newValue) > Number(values.toWeek)) {
                  values.toWeek = undefined;
                }
              }}
            />
            <Select
              label={t("endWeek")}
              name="toWeek"
              options={getToWeekOptions(values)}
              disabled={!values.year}
              onChange={newValue => {
                if (Number(newValue) < Number(values.fromWeek)) {
                  values.fromWeek = undefined;
                }
              }}
            />
            <DateTimeInput
              label={t("year")}
              name="year"
              type="year"
              isValidDate={isNotInPast}
              onChange={(curr, _) => {
                if (!curr) {
                  values.fromWeek = undefined;
                  values.toWeek = undefined;
                }
              }}
            />
          </>
        )}
      </FutureInputFilter>
    );
  });

const getFromWeekOptions = (values: CatalogFilter) => {
  if (!values.year) {
    return [];
  }

  if (values.toWeek && !values.fromWeek) {
    return getWeekNumberRange(values.year, { end: Number(values.toWeek) });
  }

  return getWeekNumberRange(values.year);
};

const getToWeekOptions = (values: CatalogFilter) => {
  if (!values.year) {
    return [];
  }

  if (values.fromWeek && !values.toWeek) {
    return getWeekNumberRange(values.year, { start: Number(values.fromWeek) });
  }

  return getWeekNumberRange(values.year);
};

type WeekRange = {
  start?: number;
  end?: number;
};

const getWeekNumberRange = (year: string, range?: WeekRange) => {
  const start = range?.start || 1;
  const weeksInYear = getWeeksInYear(year);
  const end = range?.end || weeksInYear;
  return _.range(start, end + 1).map(value => ({
    label: value.toString(),
    value,
  }));
};
