import React, { useState } from "react";
import { Card, List, ListCell, ListRow } from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { CourseResource, Document } from "../../generated/lmsTypes";
import { useParams } from "../../useHistory";
import { useGlobalStore } from "../../providers/GlobalStore";

const makeOpenDoc =
  (
    document: Document,
    learnerId: string,
    token: string,
    isLoading: boolean,
    setIsLoading: (val: boolean) => void,
  ) =>
  (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (isLoading) {
      return false;
    }

    setIsLoading(true);
    fetch(
      `/learner/${learnerId}/document/${document.id}/url?access_token=${token}`,
    )
      .then(resp => resp.json())
      .then(json => {
        if (json.url) {
          window.open(json.url, "_blank");
        } else {
          console.error("No URL given.", json);
        }
      })
      .catch(err => {
        console.error("Error getting document url", err);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return false;
  };

interface DocumentResourceItemProps {
  document: Document;
  learnerId: string;
  token: string;
}

export const DocumentResourceItem: React.FC<DocumentResourceItemProps> = ({
  document,
  learnerId,
  token,
}) => {
  const [loading, setIsLoading] = useState(false);

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      key={document.id ?? ""}
      href="#"
      title={document.description ?? undefined}
      className="no-underline no-link-color"
      onClick={makeOpenDoc(document, learnerId, token, loading, setIsLoading)}
    >
      <ListRow>
        <ListCell>
          {loading ? (
            <div className="loading text-center">
              <span className="placeholder animated placeholder-text-value" />
            </div>
          ) : (
            document.name
          )}
        </ListCell>
      </ListRow>
    </a>
  );
};

interface LinkResourceItemProps {
  resource: CourseResource;
}

const LinkResourceItem: React.FC<LinkResourceItemProps> = ({ resource }) => (
  <a
    href={resource.link}
    target="_blank"
    className="no-underline no-link-color"
    rel="noopener noreferrer"
  >
    <ListRow>
      <ListCell>{resource.label}</ListCell>
    </ListRow>
  </a>
);

export interface CourseResourcesProps {
  documents: Document[];
  resources: CourseResource[];
}

export const CourseResources: React.FC<CourseResourcesProps> = ({
  documents,
  resources,
}) => {
  const { t } = useTranslation();
  const { id: learnerId } = useParams<{id:string}>();
  const globalStore = useGlobalStore();

  const hasResources = resources.length > 0;
  const hasDocuments = documents.length > 0;

  if (!(hasResources || hasDocuments)) {
    return <></>;
  }

  return (
    <Card extraClass="course-resources">
      <List>
        <ListRow extraClass="heading">
          <h2>{t("Resources")}</h2>
        </ListRow>
        {hasDocuments && [
          <ListRow
            key="document-heading"
            extraClass="heading resource__document__list"
          >
            <ListCell icon="file">
              <h4>{t("Documents")}</h4>
            </ListCell>
          </ListRow>,
          ...documents.map(document => (
            <DocumentResourceItem
              key={document.id ?? ""}
              learnerId={learnerId}
              document={document}
              token={globalStore.token ?? ""}
            />
          )),
        ]}
        {hasResources && [
          <ListRow
            key="resource-heading"
            extraClass="heading resource__link__list"
          >
            <ListCell icon="link">
              <h4>{t("Links")}</h4>
            </ListCell>
          </ListRow>,
          ...resources.map((resource, i) => (
            <LinkResourceItem key={i} resource={resource} />
          )),
        ]}
      </List>
    </Card>
  );
};
