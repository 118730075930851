import gql from "graphql-tag";
import {
  PAGE_INFO_FRAGMENT,
  WEBLINK_EVENT_LEARNER_FRAGMENT,
  WEBLINK_PATH_LEARNER_FRAGMENT,
  POINT_OF_SALE_LEARNER_FIELD_DEFINITION_FRAGMENT,
} from "./fragments";

export const WEBLINK_COURSE_BOOKINGS_EXPORT_QUERY = gql`
  query getCourseBookings(
    $offset: Int!
    $first: Int
    $after: String
    $order: EventFieldOrder
    $filters: [EventFieldFilter!]
    $noOfLearners: Int
  ) {
    viewer {
      eventBookings(
        offset: $offset
        order: $order
        first: $first
        after: $after
        filters: $filters
      ) {
        pageInfo {
          ...PageInfo
        }
        edges {
          node {
            id
            name
            location {
              id
              name
              region {
                id
                name
              }
            }
            start
            end
            bookedPlaces
            maxPlaces
            type
            learners(
              first: $noOfLearners
              order: { field: contactName, direction: asc }
            ) {
              pageInfo {
                ...PageInfo
              }
              edges {
                node {
                  ...EventLearner
                }
              }
            }
          }
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
  ${WEBLINK_EVENT_LEARNER_FRAGMENT}
`;

export const WEBLINK_COURSE_BOOKINGS_QUERY = gql`
  query getCourseBookings(
    $offset: Int!
    $first: Int
    $after: String
    $order: EventFieldOrder
    $filters: [EventFieldFilter!]
  ) {
    viewer {
      eventBookings(
        offset: $offset
        order: $order
        first: $first
        after: $after
        filters: $filters
      ) {
        pageInfo {
          ...PageInfo
        }
        edges {
          node {
            id
            name
            namedLearnerQuantity
            unnamedLearnerQuantity
            location {
              id
              name
              region {
                id
                name
              }
            }
            start
            end
            bookedPlaces
            maxPlaces
            type
            course {
              id
              code
            }
          }
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

export const WEBLINK_COURSE_BOOKING_LEARNER_QUERY = gql`
  query getBookingLearners(
    $offset: Int
    $first: Int
    $after: String
    $order: EventFieldOrder
    $filters: [EventFieldFilter!]
    $noOfLearners: Int
  ) {
    viewer {
      eventBookings(
        offset: $offset
        order: $order
        first: $first
        after: $after
        filters: $filters
      ) {
        edges {
          node {
            learners(
              first: $noOfLearners
              order: { field: contactName, direction: asc }
            ) {
              pageInfo {
                ...PageInfo
              }
              edges {
                node {
                  ...EventLearner
                }
              }
            }
          }
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
  ${WEBLINK_EVENT_LEARNER_FRAGMENT}
`;

export const WEBLINK_BOOKING_BY_ID_QUERY = gql`
  query getBooking(
    $bookingId: String!
    $learnerOffset: Int
    $noOfLearners: Int
    $learnerFilters: [LearnerFieldFilter!]
  ) {
    pointOfSaleLearnerFields(entityType: CartLineItemEventLearner) {
      ...PointOfSaleLearnerFieldDefinition
    }
    viewer {
      eventBookings(
        filters: [{ field: id, operation: eq, value: $bookingId }]
      ) {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            id
            name
            imageUrl
            location {
              id
              name
              region {
                id
                name
              }
            }
            start
            end
            bookedPlaces
            maxPlaces
            remainingPlaces
            type
            learners(
              offset: $learnerOffset
              first: $noOfLearners
              order: { field: contactName, direction: asc }
              filters: $learnerFilters
            ) {
              pageInfo {
                ...PageInfo
              }
              edges {
                node {
                  ...EventLearner
                }
              }
            }
          }
        }
      }
    }
  }
  ${POINT_OF_SALE_LEARNER_FIELD_DEFINITION_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
  ${WEBLINK_EVENT_LEARNER_FRAGMENT}
`;

export const WEBLINK_LEARNING_PATH_BOOKINGS_BY_ID = gql`
  query getLearningPathBooking(
    $bookingId: String!
    $learnerOffset: Int
    $noOfLearners: Int
    $learnerFilters: [LearnerFieldFilter!]
  ) {
    pointOfSaleLearnerFields(entityType: CartLineItemPathLearner) {
      ...PointOfSaleLearnerFieldDefinition
    }
    viewer {
      learningPathBookings(
        filters: [{ field: id, operation: eq, value: $bookingId }]
      ) {
        edges {
          node {
            id
            name
            start
            end
            imageUrl
            learners(
              offset: $learnerOffset
              first: $noOfLearners
              order: { field: contactName, direction: asc }
              filters: $learnerFilters
            ) {
              pageInfo {
                ...PageInfo
              }
              edges {
                node {
                  ...PathLearner
                }
              }
            }
          }
        }
      }
    }
  }

  ${POINT_OF_SALE_LEARNER_FIELD_DEFINITION_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
  ${WEBLINK_PATH_LEARNER_FRAGMENT}
`;
