import React, {
  FunctionComponent,
  useCallback,
  useState,
  Fragment,
} from "react";
import {
  Card,
  StatusLabel,
  List,
  ListRow,
  ListCell,
  ListHeader,
  Avatar,
} from "@administrate/piston-ux";
import { Link } from "react-router-dom";
import { range } from "lodash";
import { useTranslation } from "react-i18next";
import { ColorOptions } from "@administrate/piston-ux/lib/types";

import {
  LEARNERS_QUERY,
  COORDINATED_ACCOUNTS_QUERY,
  CREATE_CONTACT_MUTATION,
} from "../../queries/learnerManagement";
import { Query, FilterOperation } from "../../generated/lmsTypes";
import { ScrollTrigger } from "../../ScrollTrigger";
import { extractNodes } from "../../utils/extractNodes";
import { AddLearnerModal } from "./AddLearnerModal";
import { LearnerListArticleHeader } from "./LearnerListArticleHeader";
import { useLmsQuery, useLmsClient, useLmsMutation } from "../../hooks/lms";
import { decodeId } from "../../utils/displayHelpers";
import { LearnerManagementPage } from "./LearnerManagementPage";
import {
  useLearnerManagementLearnerFilters,
  LearnerManagementLearnerFilter,
} from "./LearnerFilterContext";

const gridColumns = "64px 1fr 1fr minmax(190px, 1fr) 1fr 160px";

export const LearnerList: FunctionComponent = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const { filters } = useLearnerManagementLearnerFilters();

  const graphQLFilters = getFilters(filters);

  const {
    loading: areLearnersLoading,
    data: learnersResponse,
    refetch: refetchLearners,
    fetchMore: fetchMoreLeaners,
    networkStatus: learnersNetworkStatus,
  } = useLmsQuery<Query>(LEARNERS_QUERY, {
    variables: {
      offset: 0,
      filters: graphQLFilters,
      order: {
        direction: "asc",
        field: "firstName",
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const hasNextPage =
    learnersResponse?.coordinatorManagedContacts?.pageInfo?.hasNextPage ??
    false;

  const loadNextPage = useCallback(() => {
    fetchMoreLeaners({
      variables: {
        offset:
          learnersResponse?.coordinatorManagedContacts?.edges?.length || 0,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return fetchMoreResult
          ? {
              ...prev,
              coordinatorManagedContacts: {
                ...prev.coordinatorManagedContacts,
                pageInfo: fetchMoreResult.coordinatorManagedContacts.pageInfo,
                edges: [
                  ...prev.coordinatorManagedContacts.edges,
                  ...fetchMoreResult.coordinatorManagedContacts.edges,
                ],
              },
            }
          : prev;
      },
    });
  }, [fetchMoreLeaners, learnersResponse]);

  const client = useLmsClient();
  const accountOptions = (inputValue: string) =>
    client
      .query({
        query: COORDINATED_ACCOUNTS_QUERY,
        variables: getAccountVariables(inputValue),
      })
      .then(result =>
        extractNodes(result.data.coordinatorManagedAccounts.edges),
      );

  const [createContact] = useLmsMutation(CREATE_CONTACT_MUTATION);
  const handleAddContactModalDone = async (submitted: boolean, values: any) => {
    if (submitted) {
      await createContact({
        variables: getCreateContactVariables(values),
      });
      refetchLearners();
    }
    setShowModal(false);
  };

  const handleAddContactModalShow = () => {
    setShowModal(true);
  };

  const openMailto = (
    emailAddress: string,
    event: { preventDefault: () => void } | undefined,
  ) => {
    if (event) {
      event.preventDefault();
      window.open(`mailto:${emailAddress}`, "_blank");
    }
  };
  return (
    <LearnerManagementPage tab="learners">
      {showModal && (
        <AddLearnerModal
          onDone={handleAddContactModalDone}
          accountOptions={accountOptions}
        />
      )}
      <Card>
        <LearnerListArticleHeader
          loading={areLearnersLoading}
          accountOptions={accountOptions}
          onCreate={handleAddContactModalShow}
        />
        <List extraClass="learners" hover loading={areLearnersLoading}>
          <ListHeader
            gridColumns={gridColumns}
            headings={[
              {
                title: t("name"),
              },
              {
                title: "",
                blank: true,
              },
              {
                title: t("account"),
              },
              {
                title: t("emailAddress"),
              },
              {
                title: t("jobRole"),
              },
              {
                title: t("userType"),
              },
            ]}
            icon
          />
          {!isRefetchingLearners(learnersNetworkStatus) &&
            learnersResponse?.coordinatorManagedContacts?.edges?.map(
              (edge, key) => {
                return (
                  edge &&
                  edge.node && (
                    <Link
                      to={`/learner-management/learners/${
                        edge && edge.node && edge.node.id
                      }`}
                      className="no-underline"
                      aria-label={`${t("goTo")} ${edge.node.firstName} ${
                        edge.node.lastName
                      } (ID: ${decodeId(edge.node.id)})`}
                      key={key}
                      data-label={`${edge.node.firstName} ${edge.node.lastName}`}
                    >
                      <ListRow gridColumns={gridColumns} arrow>
                        <ListCell
                          label={t("avatar")}
                          extraClass="d-none d-lg-block"
                        >
                          <Avatar text={edge.node.firstName} />
                        </ListCell>
                        <ListCell label={t("name")}>
                          {edge.node.firstName} {edge.node.lastName}
                        </ListCell>
                        <ListCell label={t("account")}>
                          {edge.node.account.name && edge.node.account.name}
                        </ListCell>
                        <ListCell
                          label={t("emailAddress")}
                          link={
                            edge.node.emailAddress
                              ? {
                                  onClick: event =>
                                    edge.node.emailAddress &&
                                    openMailto(edge.node.emailAddress, event),
                                }
                              : undefined
                          }
                        >
                          {edge.node.emailAddress && edge.node.emailAddress}
                        </ListCell>
                        <ListCell
                          label={t("jobRole")}
                          extraClass={
                            edge.node.jobRole ? "" : "d-none d-none d-lg-block"
                          }
                        >
                          {edge.node.jobRole && edge.node.jobRole}
                        </ListCell>
                        <ListCell
                          label={t("userType")}
                          extraClass={
                            edge.node.isOverlappingCoordinator
                              ? ""
                              : "d-none d-none d-lg-block"
                          }
                        >
                          {edge.node.isOverlappingCoordinator && (
                            <StatusLabel
                              text={t("coordinator")}
                              color={ColorOptions.LightGrey}
                            ></StatusLabel>
                          )}
                        </ListCell>
                      </ListRow>
                    </Link>
                  )
                );
              },
            )}
          {areLearnersLoading && <LoadingPlaceholder />}
          {!areLearnersLoading && hasNextPage && (
            <ScrollTrigger onEnter={loadNextPage} />
          )}
        </List>
      </Card>

      {!areLearnersLoading && !hasNextPage && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p className="text-muted">{t("youveReachedTheEnd")}</p>
        </div>
      )}
    </LearnerManagementPage>
  );
};

const LoadingPlaceholder: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {range(4).map(i => (
        <Fragment key={i}>
          <ListRow
            gridColumns={gridColumns}
            extra={
              <span
                className="btn-link glyphicon glyphicon-chevron-right d-none d-lg-block"
                style={{ top: 7.5 }}
              ></span>
            }
          >
            <ListCell
              loading
              label={t("avatar")}
              extraClass="d-none d-lg-block"
            >
              <Avatar loading />
            </ListCell>
            <ListCell loading label={t("name")} />
            <ListCell loading label={t("account")} />
            <ListCell loading label={t("emailAddress")} />
            <ListCell loading label={t("jobRole")} />
            <ListCell loading label={t("userType")} />
          </ListRow>
        </Fragment>
      ))}
    </Fragment>
  );
};

const getCreateContactVariables = (result: any) => ({
  input: {
    contactId: result.id,
    personalName: {
      firstName: result.firstName,
      lastName: result.lastName,
    },
    accountId: result.account.id,
    jobRole: result.jobRole,
    phoneNumbers: { usage: "office", number: result.phoneNumber },
    emailAddresses: { usage: "primary", address: result.emailAddress },
  },
});

const getAccountVariables = (inputValue: string) => ({
  filters: [
    {
      field: "name",
      operation: "like",
      value: `%${inputValue}%`,
    },
    {
      field: "name",
      operation: "ne",
      value: "",
    },
  ],
});

const isRefetchingLearners = (networkStatus: number) => networkStatus === 4;

const getFilters = (articleHeaderFilters: LearnerManagementLearnerFilter) => {
  const filters = [];

  if (articleHeaderFilters.search) {
    filters.push({
      value: `%${articleHeaderFilters.search}%`,
      operation: FilterOperation.Like,
      field: "name",
    });
  }
  if (articleHeaderFilters.userType) {
    filters.push({
      value: true,
      operation: FilterOperation.Eq,
      field: "isOverlappingCoordinator",
    });
  }
  if (articleHeaderFilters.account) {
    filters.push({
      value: articleHeaderFilters.account.id,
      operation: FilterOperation.Eq,
      field: "accountId",
    });
  }
  return filters;
};
