import React from "react";
import { Container } from "@administrate/piston-ux";
import { useEffect } from "react";

export const ExternalObjective: React.FunctionComponent<{
  url?: string;
  description?: string;
  contentId: string;
  tryAgainClicked: boolean;
  setInteractionId: () => void;
}> = ({ url, description, contentId, tryAgainClicked, setInteractionId }) => {
  useEffect(() => {
    setInteractionId();
  }, [tryAgainClicked, contentId, setInteractionId]);

  if (url) {
    return (
      <iframe
        title="externalObjective"
        src={url}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          left: 0,
          top: "64px",
          border: "none",
        }}
      />
    );
  }
  return (
    <Container>
      <div
        dangerouslySetInnerHTML={{
          __html: description || "",
        }}
      ></div>
    </Container>
  );
};
