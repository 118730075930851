import { RefObject, useEffect } from "react";

export const useOnTabOut = (
  ref: RefObject<HTMLElement>,
  handler: (ev?: KeyboardEvent) => void,
) => {
  useEffect(() => {
    const keyListener = (ev: KeyboardEvent) => {
      if (ev.key === "Tab") {
        // Set Timeout so the document.activeElement has time to update
        setTimeout(() => {
          // Do nothing if tabbing into ref's element or descendent elements
          if (ref.current && !ref.current.contains(document.activeElement)) {
            handler(ev);
          }
        }, 1);
      }
    };

    document.addEventListener("keydown", keyListener);
    return () => {
      document.removeEventListener("keydown", keyListener);
    };
  }, [ref, handler]);
};
