import React, { FunctionComponent, useEffect, useState } from "react";

import { useParams } from "../../useHistory";

import {
  CONTENT_PROGRESS_DETAILS_QUERY,
  COURSE_REGISTRATION_DETAILS_QUERY,
  GET_PREVIEW_COURSE_QUERY,
} from "../../queries/registrations";
import { useLazyLmsQuery, useLmsClient, useLmsQuery } from "../../hooks/lms";
import { Registration, Query } from "../../generated/lmsTypes";
import { useGlobalStore } from "../../providers/GlobalStore";
import { fetchMoreCourseDetailsWrapper } from "../../utils/fetchMoreHelpers";
import { ContentPlayerInfo, ContentResultUnion } from "./ContentPlayerInfo";
import { FetchMoreCallback } from "../../types/ApolloCallbacks";

const FetchAllPages = (
  registration: Registration | null | undefined,
  fetchMoreCourses: FetchMoreCallback,
): boolean => {
  const fetchedResultsCount = registration?.course?.content?.edges?.length || 0;
  const shouldLoadMore =
    registration?.course?.content?.pageInfo?.hasNextPage || false;

  useEffect(() => {
    if (shouldLoadMore) {
      fetchMoreCourseDetailsWrapper(fetchedResultsCount, fetchMoreCourses);
    }
  }, [fetchMoreCourses, shouldLoadMore, fetchedResultsCount]);

  return shouldLoadMore;
};

const LoadList = (
  registrationId: string,
): {
  registration: Registration | null | undefined;
  listIsLoading: boolean;
} => {
  const {
    loading: registrationListLoading,
    data: registrationListResponse,
    fetchMore: fetchMoreRegistrationList,
  } = useLmsQuery<Query>(COURSE_REGISTRATION_DETAILS_QUERY, {
    variables: {
      id: registrationId,
    },
  });
  const registration =
    registrationListResponse?.registrations?.edges?.[0]?.node;
  const isLoadingMoreRegistrationList = FetchAllPages(
    registration,
    fetchMoreRegistrationList,
  );
  const listIsLoading =
    registrationListLoading || isLoadingMoreRegistrationList;
  return { registration, listIsLoading };
};

const LoadContent = (registrationId: string, contentId: string) => {
  const [loadedContentId, setLoadedContentId] = useState<string | null>(null);

  const [
    loadProgressDetails,
    { loading: progressDetailsLoading, data: progressDetailsResponse },
  ] = useLazyLmsQuery<Query>(CONTENT_PROGRESS_DETAILS_QUERY(true), {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted: () => {
      setLoadedContentId(contentId);
    },
    onError: () => {
      // don't get stuck on a loading screen
      setLoadedContentId(contentId);
    },
  });
  useEffect(() => {
    loadProgressDetails({
      variables: {
        // apollo bug fix, the variables have to be here, not in the query definition
        id: registrationId,
        contentId: contentId,
      },
    });
  }, [loadProgressDetails, registrationId, contentId]);

  const contentIsLoading =
    progressDetailsLoading || loadedContentId !== contentId;

  const contentResult: ContentResultUnion = contentIsLoading
    ? undefined
    : progressDetailsResponse?.registrations?.edges?.[0]?.node?.contentResults
        ?.edges?.[0]?.node;

  return { contentResult, contentIsLoading };
};

export const ContentPlayer: FunctionComponent = () => {
  const { registrationId, contentId, learningPathId } =
    useParams<{
      registrationId: string;
      contentId: string;
      learningPathId: string;
    }>();

  const { registration, listIsLoading } = LoadList(registrationId);
  const { contentResult, contentIsLoading } = LoadContent(
    registrationId,
    contentId,
  );

  const parentPage = learningPathId
    ? `/my-courses/learning-path/${learningPathId}/course/${registrationId}`
    : `/my-courses/course/${registrationId}`;
  const nextUrl = learningPathId
    ? `/my-courses/learning-path/${learningPathId}/course/${registrationId}/content`
    : `/my-courses/course/${registrationId}/content`;

  // refresh just watched content to update progress (yes, it's a hack)
  const lmsClient = useLmsClient();
  useEffect(() => {
    return () => {
      // noinspection JSIgnoredPromiseFromCall
      lmsClient.query({
        query: CONTENT_PROGRESS_DETAILS_QUERY(false),
        variables: {
          id: registrationId,
          contentId: contentId,
        },
        fetchPolicy: "network-only",
      });
    };
  }, [lmsClient, registrationId, contentId]);

  return (
    <ContentPlayerInfo
      registration={registration ?? undefined}
      contentId={contentId}
      parentPage={parentPage}
      nextUrl={nextUrl}
      listIsLoading={listIsLoading}
      contentIsLoading={contentIsLoading}
      contentResult={contentResult}
      learningPathId={learningPathId}
    />
  );
};

export const PreviewContentPlayer: FunctionComponent = () => {
  const { contentId } = useParams<{contentId:string}>();
  const { previewJWT } = useGlobalStore();
  const {
    loading: courseDetailsAreLoading,
    data: courseDetailsResponse,
    fetchMore: fetchMoreCourseDetails,
  } = useLmsQuery<Query>(GET_PREVIEW_COURSE_QUERY, {
    variables: {
      jwt: previewJWT,
    },
    fetchPolicy: "network-only",
  });

  const registration = courseDetailsResponse?.coursePreview?.edges?.[0]?.node;
  const parentPage = "/preview";
  const nextUrl = `/preview/content`;

  const isLoadingMoreCourseDetails = FetchAllPages(
    registration,
    fetchMoreCourseDetails,
  );

  return (
    <ContentPlayerInfo
      registration={registration ?? undefined}
      contentId={contentId}
      parentPage={parentPage}
      nextUrl={nextUrl}
      listIsLoading={courseDetailsAreLoading || isLoadingMoreCourseDetails}
      contentIsLoading={false}
      previewMode
    />
  );
};
