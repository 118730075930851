import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { TooltipText } from "../toPistonUX/TooltipText";
import { Bookable } from "../types/Bookable";
import { hasUnlimitedPlaces } from "../utils/eventHelpers";

export const AvailableSeats: FunctionComponent<{
  bookable: Bookable;
  showTooltip?: boolean;
}> = ({ bookable, showTooltip = false }) => {
  const { t } = useTranslation();
  if (hasUnlimitedPlaces(bookable)) return <>{t("unlimited")}</>;

  if (!showTooltip) {
    return <>{bookable.remainingPlaces}</>;
  }

  return (
    <TooltipText
      tooltipId={`tooltip${bookable.id}`}
      text={`${bookable.remainingPlaces}`}
      content={t("availability")}
    />
  );
};
