import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "intersection-observer";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Necessary since we don't have access to webpack config inside CRA without ejecting.
// We need to load our index.scss file in development because we don't want to have to
// call out to the branding service for a specific sha that may not exist while developing
if (process.env.NODE_ENV === "development") {
  require("./_styles/index.scss");
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
