import {
  CustomFieldType,
  PointOfSaleLearnerFieldDefinition as WeblinkPointOfSaleLearnerFieldDefinition,
} from "../generated/weblinkTypes";
import { PointOfSaleLearnerFieldDefinition as LmsPointOfSaleLearnerFieldDefinition } from "../generated/lmsTypes";
import { isObservableArray } from "mobx";

type PointOfSaleLearnerFieldDefinition =
  | WeblinkPointOfSaleLearnerFieldDefinition
  | LmsPointOfSaleLearnerFieldDefinition;

export class CustomFieldValueError extends Error {
  constructor(
    key: string,
    type: string | null,
    value: unknown,
    message: string,
  ) {
    super(
      `Custom field value "${value}" for definition "${key}" of type ${type} cannot be processed: ${message}`,
    );
  }
}

export function mapPointOfSaleFieldValues(
  pointOfSaleFields: PointOfSaleLearnerFieldDefinition[],
  pointOfSaleFieldValues: Record<string, unknown>,
) {
  return Object.keys(pointOfSaleFieldValues).map(key => {
    const value =
      pointOfSaleFieldValues[key] !== "" ? pointOfSaleFieldValues[key] : null;
    return {
      definitionKey: key,
      value: castValue(key, value, pointOfSaleFields),
    };
  });
}

function castValue(
  key: string,
  value: unknown,
  pointOfSaleFieldDefinitions: PointOfSaleLearnerFieldDefinition[],
): unknown {
  const fieldType = pointOfSaleFieldDefinitions.find(
    definition => definition.key === key,
  );
  if (!fieldType) {
    throw new CustomFieldValueError(key, null, value, "definition not found");
  }
  const type = fieldType.type;
  switch (type) {
    case CustomFieldType.Checkbox:
      return castBooleanValue(value, key, type);
    case CustomFieldType.Multichoice:
      return castMultichoiceValue(value, key, type);
    default:
      return value;
  }
}

function castBooleanValue(
  value: unknown,
  key: string,
  type: string,
): string | null {
  if (value === null || value === undefined) {
    return null;
  }
  if (typeof value === "boolean") {
    return value.toString();
  }
  if (value === "true" || value === "false") {
    return value;
  }
  throw new CustomFieldValueError(key, type, value, "invalid value");
}

function castMultichoiceValue(
  value: unknown,
  key: string,
  type: string,
): string | null {
  if (value === null || value === undefined) {
    return null;
  }

  if (Array.isArray(value) || isObservableArray(value)) {
    return value.length ? value.join("|") : null;
  }

  throw new CustomFieldValueError(key, type, value, "invalid value");
}
