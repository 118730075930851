import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { Account } from "../../generated/lmsTypes";

export interface LearnerManagementLearnerFilter {
  search: string;
  account?: Account;
  userType?: string;
}
export interface LearnerFilterContextType {
  filters: LearnerManagementLearnerFilter;
  setFilters: Dispatch<SetStateAction<LearnerManagementLearnerFilter>>;
  setFilterValue: (
    key: keyof LearnerManagementLearnerFilter,
    value: unknown,
  ) => void;
}
const LearnerFilterContext = createContext({} as LearnerFilterContextType);
const useLearnerFilterContext = () => useContext(LearnerFilterContext);

export function LearnerFilterContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const emptyFilters = {
    search: "",
    account: undefined,
    userType: undefined,
  };
  const [filters, setFilters] =
    useState<LearnerManagementLearnerFilter>(emptyFilters);
  const setFilterValue = useCallback(
    (key, value) => {
      setFilters(prevState => ({ ...prevState, [key]: value }));
    },
    [setFilters],
  );

  return (
    <LearnerFilterContext.Provider
      value={{
        filters,
        setFilters,
        setFilterValue,
      }}
    >
      {children}
    </LearnerFilterContext.Provider>
  );
}

export function useLearnerManagementLearnerFilters() {
  const context = useLearnerFilterContext();
  if (context === undefined) {
    throw new Error(
      "useLearnerManagementLearnerFilters must be used within a Learner Management Route",
    );
  }
  return context;
}
