import React, { FunctionComponent } from "react";
import moment from "moment-timezone";
import { StatusLabel, Tooltip } from "@administrate/piston-ux";
import { ColorOptions } from "@administrate/piston-ux/lib/types";
import { useTranslation } from "react-i18next";
import { TooltipContent } from "./Tooltip";
import {
  Content,
  Maybe,
  TrainingPass,
  TrainingPassType,
} from "../../generated/lmsTypes";
import { Viewer } from "../../types/Viewer";

type PassTypeLookup = {
  [key: string]: boolean;
};

const isActivePass = (pass: TrainingPass) =>
  moment().isBetween(
    moment(pass?.validFrom ?? undefined),
    moment(pass?.validUntil ?? undefined),
  );

export const computePassStatus = (
  learnerPasses: TrainingPass[],
  contentPassTypeIds: Maybe<string>[],
) => {
  if (contentPassTypeIds.length === 0) {
    return { validPassHeld: false, anyValidPassHeld: false };
  }

  // Lookup to use if any active learner pass is valid for this content
  const contentPassTypeLookup = reducePassTypeIdsToObject(contentPassTypeIds);

  let anyActivePassHeld = false;
  let validActivePassId: Maybe<string> = null;

  const learnerHasAccess = learnerPasses.reduce(
    (accessAccumulator, learnerPass) => {
      if (isActivePass(learnerPass)) {
        // book keeping to pass up that the learner _does_ have at least one active pass
        anyActivePassHeld = true;
      } else {
        // the current pass to evaluate is not active, we can return early as this pass has no effect on access
        return accessAccumulator;
      }

      if (accessAccumulator) {
        // reducer has determined learner has access, we can return early
        return accessAccumulator;
      }

      const contentAcceptsType =
        contentPassTypeLookup[learnerPass.trainingPassTypeId ?? ""];
      if (contentAcceptsType) {
        validActivePassId = learnerPass.id ?? null;
      }

      return contentAcceptsType;
    },
    false,
  );

  return {
    validPassHeld: learnerHasAccess,
    anyActivePassHeld: anyActivePassHeld,
    validActivePassId,
  };
};

export interface TrainingPassContentStatusLabelProps {
  courseCardId: string;
  learnerPasses: TrainingPass[];
  contentPasses: TrainingPassType[];
  hasPricing: boolean;
}

export const TrainingPassContentStatusLabel: FunctionComponent<TrainingPassContentStatusLabelProps> =
  ({ courseCardId, learnerPasses, contentPasses, hasPricing }) => {
    const { t } = useTranslation();

    if (contentPasses.length === 0) {
      return <></>;
    }

    const { validPassHeld, anyActivePassHeld } = computePassStatus(
      learnerPasses,
      contentPasses.map(cp => cp.id ?? ""),
    );

    return (
      <Tooltip
        tooltipId={courseCardId}
        iconShown={false}
        content={
          <TooltipContent
            tooltipContext={{
              validPassHeld,
              anyActivePassHeld: anyActivePassHeld || false,
              isPassRequired: !hasPricing,
              learnerPasses,
              contentPasses,
            }}
          />
        }
      >
        <StatusLabel
          dataTestId="status-label"
          text={t("pass")}
          glyph={validPassHeld ? "circleCheck" : "trainingPass"}
          color={validPassHeld ? ColorOptions.Green : ColorOptions.Yellow}
          size="small"
          description={
            learnerPasses.length === 0
              ? t("courseInPasses")
              : validPassHeld
              ? t("courseInYourPass")
              : hasPricing
              ? t("purchasableCourseInOtherPass")
              : t("courseInOtherPass")
          }
        />
      </Tooltip>
    );
  };

export const getTrainingPassTypesForContent = (
  passTypes: TrainingPassType[],
  includedInPassTypeIds?: Maybe<Maybe<string>[]>,
): TrainingPassType[] => {
  const includedInPassTypeIdsLookup =
    includedInPassTypeIds && reducePassTypeIdsToObject(includedInPassTypeIds);

  return passTypes.filter(
    type =>
      includedInPassTypeIdsLookup && includedInPassTypeIdsLookup[type.id ?? ""],
  );
};

export const checkHasLockedContent = (
  onlineContent: Content,
  viewer?: Maybe<Viewer>,
): boolean => {
  let hasLockedPremiumContent = false;
  if (
    onlineContent?.includedInPassTypeIds &&
    onlineContent?.includedInPassTypeIds?.length > 0 &&
    viewer?.trainingPasses
  ) {
    if (viewer?.trainingPasses?.length === 0) {
      hasLockedPremiumContent = true;
    } else {
      hasLockedPremiumContent = !computePassStatus(
        viewer?.trainingPasses,
        onlineContent?.includedInPassTypeIds,
      ).validPassHeld;
    }
  }
  return hasLockedPremiumContent;
};

export const reducePassTypeIdsToObject = (
  contentPassTypeIds: Maybe<string>[],
) =>
  contentPassTypeIds.reduce<PassTypeLookup>(
    (intermediateLookup, contentPassTypeId) => {
      if (contentPassTypeId !== null) {
        intermediateLookup[contentPassTypeId] = true;
      }
      return intermediateLookup;
    },
    {},
  );
