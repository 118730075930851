import React, { createContext, useMemo, useContext, useEffect } from "react";

import {
  PORTAL_FOR_HOST_QUERY,
  PORTAL_CUSTOM_VIEWER_FIELD_QUERY,
} from "../queries/portal";
import {
  CustomViewerFields,
  CustomViewerFieldsResponse,
  PortalForHostResponse,
} from "../types/Portal";
import { DEV_PORTAL_URL } from "../config";
import {
  ProfileSettings,
  Maybe,
  ExternalLink,
  WelcomeMessage,
  Branding,
} from "../generated/lmsTypes";
import { useLmsQuery } from "../hooks/lms";

type PortalContextType = {
  externalLinks: Array<Maybe<ExternalLink>> | [];
  profileSettings?: ProfileSettings;
  isPortalLoading: boolean;
  isCustomViewerFieldsLoading: boolean;
  customViewerFields: Array<CustomViewerFields>;
  termsAndConditions: string;
  isApprovalPortal: boolean;
  isBookingPortal: boolean;
  isSelfRegistrationPortal: boolean;
  host: string;
  brandName: string;
  brandId: string;
  welcomeMessage?: WelcomeMessage;
  isSingleSignOnOnly: boolean;
  emptyPortalRedirectUrl?: Maybe<string>;
  branding?: Branding;
};

const PortalContext = createContext<PortalContextType | undefined>(undefined);

export const usePortal = () => {
  const context = useContext(PortalContext);
  if (context === undefined) {
    throw new Error("PortalProvider not found");
  }
  return context;
};

export const getHost = (): string => {
  if (process.env.NODE_ENV === "development") {
    return DEV_PORTAL_URL;
  }
  const pageUrl = new URL(window.location.href);
  return `${pageUrl.protocol}//${pageUrl.hostname}`;
};

export const PortalProvider: React.FunctionComponent = ({ children }) => {
  const host = useMemo<string>(() => getHost(), []);

  const { data: portalForHostResponse, loading: isPortalLoading } =
    useLmsQuery<PortalForHostResponse>(PORTAL_FOR_HOST_QUERY, {
      variables: { host },
    });

  const {
    data: customViewerFieldsResponse,
    loading: isCustomViewerFieldsLoading,
  } = useLmsQuery<CustomViewerFieldsResponse>(
    PORTAL_CUSTOM_VIEWER_FIELD_QUERY,
    {
      variables: { host },
    },
  );

  const externalLinks =
    portalForHostResponse?.portalForHost?.externalLinks || [];

  const brand = portalForHostResponse?.portalForHost?.brand;
  const brandName = brand?.name || "";
  const brandId = brand?.id || "";
  const welcomeMessage = brand?.welcomeMessage;
  useEffect(() => {
    document.title = brandName;
  }, [brandName]);

  const customViewerFields =
    customViewerFieldsResponse?.portalCustomFieldTemplates || [];

  const termsAndConditions =
    portalForHostResponse?.portalForHost?.termsAndConditions || "";

  const isApprovalPortal =
    portalForHostResponse?.portalForHost?.isApprovalPortal ?? false;

  const isBookingPortal =
    portalForHostResponse?.portalForHost?.isBookingPortal ?? false;

  const isSelfRegistrationPortal =
    portalForHostResponse?.portalForHost?.isSelfRegistrationPortal ?? false;

  const profileSettings = portalForHostResponse?.portalForHost?.profileSettings;
  const isSingleSignOnOnly =
    portalForHostResponse?.portalForHost?.brand?.singleSignOnOnly || false;

  const emptyPortalRedirectUrl = isPortalLoading
    ? null
    : portalForHostResponse?.portalForHost?.emptyPortalRedirectUrl || "";

  return (
    <PortalContext.Provider
      value={{
        externalLinks,
        isPortalLoading,
        isCustomViewerFieldsLoading,
        customViewerFields,
        termsAndConditions,
        isApprovalPortal,
        isBookingPortal,
        isSelfRegistrationPortal,
        host,
        brandName,
        brandId,
        welcomeMessage,
        isSingleSignOnOnly,
        profileSettings,
        emptyPortalRedirectUrl,
        branding: portalForHostResponse?.portalForHost?.brand.branding,
      }}
    >
      {children}
    </PortalContext.Provider>
  );
};
