import React, { FunctionComponent } from "react";
import { BrowseCatalog } from "./BrowseCatalog";
import { BookerIntention } from "../Order/BookingPage";
import { useTranslation } from "react-i18next";
import { useBookerIntention } from "../../hooks/useBookerIntention";

export const CoordinatorCatalog: FunctionComponent = () => {
  const { t } = useTranslation();

  const [bookerIntention, setBookerIntention] = useBookerIntention(
    BookerIntention.Coordinating,
  );

  if (bookerIntention !== BookerIntention.Coordinating) {
    setBookerIntention(BookerIntention.Coordinating);
  }

  return <BrowseCatalog title={t("coordinatorCatalog")} />;
};
