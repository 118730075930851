import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { List, StatusLabel, Modal } from "@administrate/piston-ux";
import { ListEmpty, ListRow, ListCell } from "@administrate/piston-ux/lib/List";
import { ColorOptions } from "@administrate/piston-ux/lib/types";
import { useDateFormatter } from "../../hooks/useDateFormatter";
import { OutcomeInfo } from "../../utils/lmsLearningPathHelpers";
import { formatDateRange } from "../../utils/dateTimeHelpers";

export const LearnerOutcomesModal: FunctionComponent<{
  title?: string | undefined;
  outcomes: (OutcomeInfo | null)[];
  onDone: (value: boolean) => void;
}> = ({ title, outcomes, onDone }) => {
  const { t } = useTranslation();
  const gridColumns = "2fr 3fr 2fr 3fr";
  const { dateFormat } = useDateFormatter({ showTime: true });

  const statusLabelText = (type: string) => {
    if (type === "LearningPath") {
      return t("learningPath");
    } else if (type === "Event") {
      return t("course");
    } else {
      return type;
    }
  };

  return (
    <Modal
      onDone={onDone}
      title={title || t("objectives")}
      show={true}
      size="large"
      primaryActionText={t("OK")}
    >
      <List>
        {outcomes.length === 0 && <ListEmpty text={t("noObjectivesForPath")} />}
        {outcomes.map(
          outcome =>
            outcome && (
              <ListRow
                gridColumns={gridColumns}
                key={outcome.id ? outcome.id : undefined}
                dataLabel={outcome.title || undefined}
              >
                <ListCell label={t("type")}>
                  <StatusLabel
                    color={ColorOptions.Green}
                    text={statusLabelText(outcome.type)}
                  />
                </ListCell>
                <React.Fragment>
                  <ListCell label={t("name")}>{outcome.title}</ListCell>
                  <ListCell label={t("location")} icon="location">
                    {outcome.course && outcome.course.location
                      ? outcome.course.location.name
                      : "-"}
                  </ListCell>
                  <ListCell label={t("dates")} icon="calendar">
                    {outcome.start
                      ? formatDateRange(outcome.start, outcome.end, dateFormat)
                      : "-"}
                  </ListCell>
                </React.Fragment>
              </ListRow>
            ),
        )}
      </List>
    </Modal>
  );
};
