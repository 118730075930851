import gql from "graphql-tag";
import { PAGE_INFO_FRAGMENT } from "./fragments";

export const LEARNERS_QUERY = gql`
  query learners(
    $offset: Int
    $first: Int
    $after: String
    $order: ContactFieldOrder
    $filters: [ContactFieldFilter!]
  ) {
    coordinatorManagedContacts(
      offset: $offset
      order: $order
      first: $first
      filters: $filters
      after: $after
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          id
          firstName
          lastName
          emailAddress
          jobRole
          isCoordinator
          isOverlappingCoordinator
          phoneNumbers {
            usage
            number
          }
          account {
            id
            name
            ancestors {
              id
              name
            }
          }
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

export const LEARNER_QUERY = gql`
  query learner($filters: [ContactFieldFilter!]) {
    coordinatorManagedContacts(filters: $filters) {
      edges {
        node {
          id
          firstName
          lastName
          jobRole
          emailAddress
          isOverlappingCoordinator
          account {
            id
            name
          }
          phoneNumbers {
            usage
            number
          }
        }
      }
    }
  }
`;

export const COORDINATED_ACCOUNTS_QUERY = gql`
  query accounts($filters: [AccountFieldFilter!]) {
    coordinatorManagedAccounts(
      filters: $filters
      order: { direction: asc, field: name }
    ) {
      edges {
        node {
          id
          name
          ancestors {
            id
            name
          }
        }
      }
    }
  }
`;

export const MANAGED_ACCOUNTS_QUERY = gql`
  query managedAccounts($filters: [AccountFieldFilter!]) {
    managedAccounts(filters: $filters, order: { direction: asc, field: name }) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          id
          name
          canSelfCheckoutByInvoice
          ancestors {
            id
            name
          }
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

export const UPDATE_CONTACT_MUTATION = gql`
  mutation update($input: ContactUpdateInput!) {
    contact {
      update(input: $input) {
        contact {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const CREATE_CONTACT_MUTATION = gql`
  mutation create($input: ContactCreateInput!) {
    contact {
      create(input: $input) {
        contact {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const COURSES_QUERY = gql`
  query courses(
    $offset: Int!
    $order: CourseFieldOrder
    $filters: [CourseFieldFilter!]
  ) {
    courses(offset: $offset, first: 48, order: $order, filters: $filters) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          name
          id
          priceRange {
            minimumPrice {
              amount
            }
            normalPrice {
              amount
            }
            maximumPrice {
              amount
            }
          }
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

export const COORDINATED_LEARNER_QUERY = gql`
  query learners($filters: [ContactFieldFilter!]) {
    coordinatorManagedContacts(
      order: { direction: asc, field: name }
      filters: $filters
    ) {
      edges {
        node {
          id
          firstName
          lastName
          emailAddress
          jobRole
          department
          faxPhoneNumber
          homePhoneNumber
          mobilePhoneNumber
          officePhoneNumber
          postalAddress {
            streetAddress1
            streetAddress2
            streetAddress3
            town
            region
            postcode
            country {
              code
            }
          }
          customFieldValues {
            definitionKey
            value
          }
        }
      }
    }
  }
`;

export const LEARNER_MANAGED_ACCOUNTS_QUERY = gql`
  query accountsManagedByLearner($offset: Int!, $contactId: String) {
    coordinatorManagedContacts(
      filters: [{ field: id, operation: eq, value: $contactId }]
      order: { direction: asc, field: name }
      offset: $offset
    ) {
      edges {
        node {
          managedAccounts {
            pageInfo {
              ...PageInfo
            }
            edges {
              node {
                id
                name
                ancestors {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

export const ADD_PERMISSION_MUTATION = gql`
  mutation addPermission($input: ContactCoordinatorInput!) {
    contact {
      makeCoordinatorForAccounts(input: $input) {
        contact {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const REMOVE_PERMISSION_MUTATION = gql`
  mutation removePermission($input: ContactCoordinatorInput!) {
    contact {
      revokeCoordinatorForAccounts(input: $input) {
        contact {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const CANCEL_EVENT_LEARNER_MUTATION = gql`
  mutation cancelEventLearner($input: EventLearnerCancelInput!) {
    booking {
      cancelEventLearner(input: $input) {
        learner {
          id
          isCancelled
          contact {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const CANCEL_PATH_LEARNER_MUTATION = gql`
  mutation cancelPathLearner($input: PathLearnerCancelInput!) {
    booking {
      cancelPathLearner(input: $input) {
        learner {
          id
          isCancelled
        }
      }
    }
  }
`;

export const TRANSFER_LEARNER_MUTATION = gql`
  mutation transferLearner($input: TransferLearnerInput!) {
    booking {
      transferLearner(input: $input) {
        learner {
          id
        }
        errors {
          label
          message
          value
        }
      }
    }
  }
`;
