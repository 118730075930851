import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import {
  SubSection,
  Scrollable,
  Checkbox,
  Form,
  Card,
  FutureArticleHeader,
  FormActions,
  Submit,
  FormStateStatus,
  useDetailFormState,
  ValidationErrors,
  Col,
  Row,
  AddressInput,
  useTypedFormValues,
} from "@administrate/piston-ux";
import { pick as _pick } from "lodash";
import { RawStaticInput } from "@administrate/piston-ux/lib/StaticInput";
import { Maybe } from "@administrate/piston-ux/lib/types";
import { useTranslation } from "react-i18next";
import {
  Cart,
  PointOfSaleFieldDefinition,
  PointOfSaleFieldValue,
  Province,
  Term,
} from "../../generated/weblinkTypes";
import { CheckoutPage, CheckoutStep } from "./CheckoutPage";
import { useHistory } from "../../useHistory";
import { useCheckoutContext } from "../../providers/CheckoutProvider";
import { WonCartAlert } from "../../components/WonCartAlert";
import { getCheckoutRoute } from "../../utils/checkoutHelpers";
import { usePlaceOrder } from "../../hooks/usePlaceOrder";
import { useWebLinkMutation, useWebLinkQuery } from "../../hooks/weblink";
import { COUNTRY_QUERY } from "../../queries/country";
import { UPDATE_CONTACT_MUTATION } from "../../queries/cart";
import { useWeblinkSettings } from "../../hooks/useWeblinkSettings";
import { POINT_OF_SALE_ORDER_FIELDS } from "../../queries/pointOfSaleOrderFields";
import { POINT_OF_SALE_ORDER_FIELD_VALUES } from "../../queries/pointOfSaleOrderFieldValues";
import { toJS } from "mobx";
import {
  PointOfSaleCustomFieldInput,
  serialisePoSValuesForMutation,
} from "../../components/PointOfSaleCustomFieldInput";
import { Viewer } from "../../generated/weblinkTypes";
import { useAnalytics } from "../../providers/AnalyticsProvider";
import { CartEvent } from "../../analytics/events";
import { useViewersLastCart } from "../../hooks/useViewersLastCart";

type AdditionalInformationContent = {
  billingAddress?: any;
  pointOfSaleFields?: any;
};

const billingAddressFieldKey = {
  streetAddress1: "streetAddress1",
  streetAddress2: "streetAddress2",
  streetAddress3: "streetAddress3",
  town: "town",
  postcode: "postcode",
  countryId: "countryId",
  provinceCode: "provinceCode",
};

export const BookerInformation: FunctionComponent = () => {
  const { hasLearnerDetails, hasBookingDetails, viewer, hasBookerInformation } =
    useCheckoutContext();
  const { cart, loading } = useViewersLastCart();
  const { captureEvent } = useAnalytics();
  const history = useHistory();
  useEffect(() => {
    if (loading || !cart) return;
    captureEvent(CartEvent.fromCheckoutBegin(cart));
  }, [loading, cart, captureEvent]);

  const route = getCheckoutRoute(
    "onCheckoutLoad",
    hasBookerInformation,
    hasLearnerDetails,
    hasBookingDetails,
  );

  if (route) {
    history.push({
      pathname: route,
    });
  }

  return (
    <CheckoutPage step={CheckoutStep.BookerInformation}>
      <BookingInformation />
      <WonCartAlert cart={viewer?.lastCart} />
    </CheckoutPage>
  );
};

const extractBillingAddressFromFormValues = (objectifiedFormValue: any) => {
  return _pick(objectifiedFormValue, Object.keys(billingAddressFieldKey));
};

const submitUXForm = (
  formValues: any,
  updateContact: Function,
  pointOfSaleOrderFields: PointOfSaleFieldDefinition[],
  refetchCartResponse: Function,
  requireBookerBillingAddress: boolean,
  viewer?: Maybe<Viewer>,
) => {
  const objectFormValues = toJS(formValues);
  if (objectFormValues) {
    const contentToUpdate: AdditionalInformationContent = {};
    if (
      requireBookerBillingAddress &&
      objectFormValues[billingAddressFieldKey.countryId]
    ) {
      contentToUpdate["billingAddress"] =
        extractBillingAddressFromFormValues(objectFormValues);
    }
    if (pointOfSaleOrderFields.length > 0) {
      contentToUpdate["pointOfSaleFields"] = serialisePoSValuesForMutation(
        objectFormValues,
        pointOfSaleOrderFields,
      );
    }
    if (Object.keys(contentToUpdate).length > 0 && viewer) {
      updateContact({
        variables: {
          cartId: viewer.lastCart?.id,
          firstName: viewer.contact.firstName,
          lastName: viewer.contact.lastName,
          email: viewer.contact.emailAddress,
          ...contentToUpdate,
        },
      }).then(() => refetchCartResponse());
    }
  }
};

const BookingInformation: FunctionComponent = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const { messages, saveState, setMessages } = useDetailFormState();

  const {
    hasLearnerDetails,
    hasBookingDetails,
    viewer,
    hasBookerInformation,
    refetch: refetchCartResponse,
    terms,
  } = useCheckoutContext();

  const { requireBookerBillingAddress } = useWeblinkSettings();

  const {
    loading: placeOrderLoading,
    submit: placeOrder,
    mutationError,
    validationErrors,
  } = usePlaceOrder(viewer?.lastCart?.id);
  const { captureEvent } = useAnalytics();

  const [updateContact] = useWebLinkMutation(UPDATE_CONTACT_MUTATION);

  const onSubmit = () => {
    submitUXForm(
      formValues,
      updateContact,
      pointOfSaleOrderFields,
      refetchCartResponse,
      requireBookerBillingAddress,
      viewer,
    );
    captureEvent(
      CartEvent.fromBookerDetailsSubmitted(viewer?.lastCart as Cart),
    );
    if (!hasLearnerDetails && !hasBookingDetails) {
      placeOrder();
      if (!placeOrderLoading) {
        if (mutationError) {
          setMessages({ failed: mutationError });
        }
        if (validationErrors) {
          setMessages({
            errors: (
              <ValidationErrors
                title={t("placeOrderError")}
                mutationValidationErrors={validationErrors}
              />
            ),
          });
        }
      }
    }
    const route = getCheckoutRoute(
      "onBookerInformationSubmit",
      hasBookerInformation,
      hasLearnerDetails,
      hasBookingDetails,
    );
    if (route) {
      history.push({
        pathname: route,
      });
    }
  };

  const [countryOptions, setCountryOptions] = useState([]);
  const [pointOfSaleOrderFields, setPointOfSaleOrderFields] = useState<
    PointOfSaleFieldDefinition[]
  >([]);
  const [pointOfSaleOrderValues, setPointOfSaleOrderValues] = useState<
    PointOfSaleFieldValue[]
  >([]);

  const { loading: loadingPoSOrderFields } = useWebLinkQuery(
    POINT_OF_SALE_ORDER_FIELDS,
    {
      fetchPolicy: "cache-first",
      onCompleted: ({ pointOfSaleOrderFields: fetchedPoSFields }) => {
        setPointOfSaleOrderFields(fetchedPoSFields);
      },
    },
  );

  const { loading: loadingPoSOrderFieldValues } = useWebLinkQuery(
    POINT_OF_SALE_ORDER_FIELD_VALUES,
    {
      variables: {
        cartId: viewer?.lastCart?.id,
      },
      onCompleted: ({ pointOfSaleOrderFieldValues: fetchedPoSFieldValues }) => {
        setPointOfSaleOrderValues(fetchedPoSFieldValues);
      },
    },
  );

  useWebLinkQuery(COUNTRY_QUERY, {
    fetchPolicy: "cache-first",
    onCompleted: ({ countries: fetchedCountries }) => {
      setCountryOptions(
        fetchedCountries.map(
          ({
            name,
            id,
            provinces,
          }: {
            name: string;
            id: string;
            provinces: Province[];
          }) => ({
            name,
            code: id,
            provinces,
          }),
        ),
      );
    },
  });

  const formValues = useTypedFormValues(
    viewer?.lastCart?.buyerDetails?.billingAddress
      ? {
          countryId: viewer.lastCart.buyerDetails.billingAddress.country?.id,
          provinceCode:
            viewer.lastCart.buyerDetails.billingAddress.province?.code,
          ...viewer.lastCart.buyerDetails.billingAddress,
        }
      : {},
  );

  const pageIsLoading = loadingPoSOrderFields || loadingPoSOrderFieldValues;
  return (
    <Card>
      <FutureArticleHeader title={t("bookerInformation")} />
      {saveState !== "saved" && (
        <FormStateStatus messages={messages} saveState={saveState} />
      )}
      <Row className="mb-4">
        <Col sm={6}>
          <RawStaticInput
            label={t("name")}
            value={`${viewer?.contact.firstName}${
              viewer?.contact.lastName ? ` ${viewer?.contact.lastName}` : ""
            }`}
          />
        </Col>
        <Col sm={6}>
          <RawStaticInput
            label={t("emailAddress")}
            value={viewer?.contact.emailAddress}
          />
        </Col>
      </Row>
      <Form values={formValues} loading={pageIsLoading} onSubmit={onSubmit}>
        <PointOfSaleCustomFieldInput
          pointOfSaleFields={pointOfSaleOrderFields}
          pointOfSaleValues={pointOfSaleOrderValues}
        />
        {requireBookerBillingAddress && (
          <AddressInput
            legend={t("billingAddress")}
            inputs={{
              line1: { name: billingAddressFieldKey.streetAddress1 },
              line2: { name: billingAddressFieldKey.streetAddress2 },
              line3: { name: billingAddressFieldKey.streetAddress3 },
              city: { name: billingAddressFieldKey.town },
              postalCode: { name: billingAddressFieldKey.postcode },
              country: { name: billingAddressFieldKey.countryId },
              province: { name: billingAddressFieldKey.provinceCode },
            }}
            countryList={countryOptions}
          />
        )}
        {terms &&
          terms.map((term: Maybe<Term>, i: number) => {
            const title = term?.title;
            return (
              <Fragment key={i}>
                <SubSection title={title || ""} titleLevel="h3">
                  {term?.text && (
                    <Scrollable className="term-text mb-2" axis="y">
                      <div dangerouslySetInnerHTML={{ __html: term?.text }} />
                    </Scrollable>
                  )}
                  <Checkbox
                    name={`checkbox${i}`}
                    description={t("termAgreeText", { title })}
                    valid={value => !!value}
                  />
                </SubSection>
              </Fragment>
            );
          })}
        <FormActions>
          <Submit label={t("continue")} />
        </FormActions>
      </Form>
    </Card>
  );
};
