import React, { FunctionComponent } from "react";
import { StatusLabel, Container, Row, Col } from "@administrate/piston-ux";
import { ColorOptions } from "@administrate/piston-ux/lib/types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Achievement } from "../generated/lmsTypes";
import { AchievementLink } from "./AchievementLink";

export const AchievementCard: FunctionComponent<{
  achievementTypeName: string | null | undefined;
  achievement?: Achievement | null;
}> = ({ achievementTypeName, achievement }) => {
  const { t } = useTranslation();

  const certificateUrl = achievement?.certificateUrl;
  const firstDocumentUrl =
    achievement?.documentUrls && achievement?.documentUrls[0];
  const firstDocumentIdUrl =
    achievement?.documentIds &&
    achievement?.documentIds[0] &&
    `/achievements/${achievement?.id}/documents/${achievement?.documentIds[0]}`;
  const earnedAchievementUrl =
    certificateUrl || firstDocumentUrl || firstDocumentIdUrl;

  const isExternalLink =
    earnedAchievementUrl !== null && earnedAchievementUrl === firstDocumentUrl;

  return (
    <div className="achievement-wrapper">
      <Container fluid>
        <Row className="icon-wrapper">
          <Col md={12} className="text-center">
            <span
              className={`glyphicon-pro glyphicon-pro-certificate  ${
                achievement ? " earned" : " unearned"
              }`}
            ></span>
          </Col>
        </Row>
        <Row className="name-wrapper">
          <Col md={12} className="text-center pt-2">
            <strong>{t("achievement")}</strong>
            <br />
            <h2 className="mt-0">{achievementTypeName}</h2>
          </Col>
        </Row>
        <Row className="status-wrapper">
          <Col md={12} className="text-center">
            <StatusLabel
              color={achievement ? ColorOptions.Green : ColorOptions.LightGrey}
              text={
                achievement
                  ? t("achievementEarned")
                  : t("achievementNotYetEarned")
              }
            />
          </Col>
        </Row>
        {achievement && (achievement.identifier || achievement.expiresAt) && (
          <Row className="info-wrapper">
            <Col md={12} className="text-center">
              <p className="pt-3">
                {achievement.identifier && [
                  <strong>{t("identifier")}</strong>,
                  <span>{achievement.identifier}</span>,
                  <br />,
                ]}
                {achievement.expiresAt && [
                  <strong>{t("expiresAt")}</strong>,
                  <span>{moment(achievement.expiresAt).format("ll")}</span>,
                ]}
              </p>
            </Col>
          </Row>
        )}
        {achievement && earnedAchievementUrl && (
          <Row>
            <Col xs={12} className="text-center mt-4">
              <AchievementLink
                url={earnedAchievementUrl}
                external={isExternalLink}
                className="achievement no-decoration btn-link"
              >
                {t("view")} <span className="sr-only">{t("achievement")}</span>
              </AchievementLink>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};
