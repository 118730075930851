import React, { useState, useEffect, useMemo } from "react";
import {
  Modal,
  Select,
  useFormValues,
  Col,
  Row,
} from "@administrate/piston-ux";

import { EVENTS_FOR_APPROVAL_PORTAL_COURSE_QUERY } from "../queries/events";
import { useDateFormatter } from "../hooks/useDateFormatter";
import { EventsResponse } from "../types/Event";
import { useLazyLmsQuery } from "../hooks/lms";
import { useTranslation } from "react-i18next";

type SelectOption = {
  label: string;
  value: string;
};

export const BookTrainingModal: React.FunctionComponent<{
  onDone: Function;
  title: string;
  courseCode: string | null;
  preSelectedEvent: { id: string; location: string } | null;
}> = ({ onDone, title, courseCode, preSelectedEvent }) => {
  const { t } = useTranslation();
  const initialValues = {
    location: preSelectedEvent?.location || "",
    date: preSelectedEvent?.id || "",
  };
  const formValues = useFormValues(initialValues);
  const [selectedLocation, setSelectedLocation] = useState(
    preSelectedEvent?.location,
  );
  const [selectedEventId, setSelectedEventId] = useState(preSelectedEvent?.id);
  const currentDate = useMemo(() => new Date(), []);

  const [
    loadEvents,
    { called: eventsIsCalled, loading: eventsIsLoading, data: eventsResponse },
  ] = useLazyLmsQuery<EventsResponse>(EVENTS_FOR_APPROVAL_PORTAL_COURSE_QUERY, {
    variables: { courseCode, minDate: currentDate },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    if (courseCode) {
      loadEvents();
    }
  }, [courseCode, loadEvents]);

  const locationOptions = useMemo<SelectOption[]>(() => {
    if (!eventsResponse) return [];
    const seen: string[] = [];
    return (
      eventsResponse?.events?.edges
        ?.map(edge => edge.node.location)
        .filter(location => {
          if (!location || seen.indexOf(location.id) !== -1) return false;
          seen.push(location.id);
          return true;
        })
        .map(location => ({
          label: location!.name,
          value: location!.id,
        })) || []
    );
  }, [eventsResponse]);

  const { dateToDateFormat } = useDateFormatter({ showTime: true });
  const timeOptions = useMemo<SelectOption[]>(
    () =>
      selectedLocation
        ? eventsResponse?.events?.edges
            ?.filter(
              ({ node }) =>
                node?.location?.id === selectedLocation && node.start,
            )
            .map(({ node }) => ({
              label: dateToDateFormat({
                start: node.start,
                end: node.end,
                ...(node.deliveryMethod === "classroom"
                  ? { customTimeZoneName: node.timeZoneName }
                  : {}),
              }),
              value: node.id,
            })) || []
        : [],
    [selectedLocation, eventsResponse, dateToDateFormat],
  );
  return (
    <Modal
      show
      title={title}
      onDone={(success: boolean) => onDone({ success, selectedEventId })}
      values={formValues}
      primaryActionText={t("OK")}
    >
      <Row className="event-selector">
        {(eventsIsLoading || !eventsIsCalled) && (
          <Col md={12}>
            <span
              className="placeholder animated"
              style={{ fontSize: "18px" }}
            />
          </Col>
        )}
        <Col md={6}>
          {!eventsIsLoading && eventsIsCalled && (
            <Select
              label={t("location")}
              options={locationOptions}
              name="location"
              valid={v => !!v}
              onChange={(newValue: string) => setSelectedLocation(newValue)}
            />
          )}
        </Col>
        <Col md={6}>
          {!eventsIsLoading && eventsIsCalled && (
            <Select
              label={t("datesAndTimes")}
              options={timeOptions}
              name="date"
              valid={v => !!v}
              onChange={(newValue: string) => setSelectedEventId(newValue)}
            />
          )}
        </Col>
      </Row>
    </Modal>
  );
};
