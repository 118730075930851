import React, { FunctionComponent, useState } from "react";
import {
  Form,
  Input,
  StaticInput,
  useFormValues,
  Row,
  Col,
  FutureDetailPage,
  DetailPageContent,
} from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";

import {
  COORDINATED_ACCOUNTS_QUERY,
  UPDATE_CONTACT_MUTATION,
} from "../../queries/learnerManagement";
import { extractNodes } from "../../utils/extractNodes";
import { AccountPicker } from "../../components/AccountPicker";
import {
  Contact,
  ContactPhoneNumber,
  ContactPhoneNumberUsage,
  Account,
} from "../../generated/lmsTypes";
import { Maybe } from "../../generated/weblinkTypes";
import { useHistory } from "../../useHistory";
import { useLmsClient, useLmsMutation } from "../../hooks/lms";

type LearnerInformationProps = {
  data: Contact;
  learnerLoading: boolean;
};

type LearnerFormValuesType = {
  account: Account;
  emailAddress?: Maybe<string>;
  firstName: string;
  id: string;
  isOverlappingCoordinator: boolean;
  jobRole?: Maybe<string>;
  lastName: string;
  phoneNumber?: Maybe<string>;
};

export const LearnerInformation: FunctionComponent<LearnerInformationProps> = ({
  data,
  learnerLoading,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [
    updateContact,
    { loading: updateContactLoading, error: updateContactError },
  ] = useLmsMutation(UPDATE_CONTACT_MUTATION);
  const [updateContactSuccess, setUpdateContactSuccess] = useState(false);
  const allPhoneNumbers = data ? data.phoneNumbers : [];
  let initialValues: LearnerFormValuesType = getInitialValues(
    data,
    allPhoneNumbers,
  );
  const formValues = useFormValues({
    learner: initialValues,
  });

  const client = useLmsClient();

  const loadOptions = (inputValue: string) =>
    client
      .query({
        query: COORDINATED_ACCOUNTS_QUERY,
        variables: {
          filters: [
            {
              field: "name",
              operation: "like",
              value: `%${inputValue}%`,
            },
            {
              field: "name",
              operation: "ne",
              value: "",
            },
          ],
        },
      })
      .then(result =>
        extractNodes(result.data.coordinatorManagedAccounts.edges),
      );

  const handleEditContact = async (result: {
    learner: LearnerFormValuesType;
  }) => {
    if (result) {
      const updateContactVariables = getUpdateContactVariables(
        result,
        allPhoneNumbers,
      );
      const mutationResult = await updateContact({
        variables: { input: updateContactVariables },
      });

      if (mutationResult.data) {
        initialValues = result.learner;
        setUpdateContactSuccess(true);
      } else {
        setUpdateContactSuccess(false);
      }
    }
  };

  const handleCancel = () => {
    formValues.learner = initialValues;
  };

  return (
    <FutureDetailPage
      layout="stacked"
      detailType="Learner"
      legacyId=""
      page="overview"
      form={{ saveState: null }}
      title={t("learner")}
      back={{
        label: t("learner"),
        onClick: history.goBack,
      }}
    >
      <DetailPageContent>
        <Row>
          <Col xs={8} xsOffset={2}>
            <Form
              values={formValues}
              onSubmit={handleEditContact}
              onCancel={handleCancel}
              type="live"
              title={t("learnerInformation")}
              loading={learnerLoading || updateContactLoading}
              error={updateContactError}
              success={updateContactSuccess}
              id="learner-edit-form"
            >
              <Row>
                <Col md={6}>
                  <Input
                    name="learner.firstName"
                    label={t("firstName")}
                    valid={v => (!!v ? true : `${t("firstNameIsRequired")}`)}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    name="learner.lastName"
                    label={t("lastName")}
                    valid={v => (!!v ? true : `${t("lastNameIsRequired")}`)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <StaticInput
                    name="learner.emailAddress"
                    label={t("emailAddress")}
                  />
                </Col>
                <Col md={6}>
                  <Input name="learner.phoneNumber" label={t("phoneNumber")} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AccountPicker
                    label={t("account")}
                    name="learner.account"
                    loadOptions={loadOptions}
                    valid={v => (!!v ? true : `${t("pleaseSelectAccount")}`)}
                  />
                </Col>
                <Col md={6}>
                  <Input name="learner.jobRole" label={t("jobRole")} />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </DetailPageContent>
    </FutureDetailPage>
  );
};

const getInitialValues = (
  data: Contact,
  allPhoneNumbers: Maybe<ContactPhoneNumber>[] | null | undefined,
) => {
  return {
    ...data,
    phoneNumber: data && allPhoneNumbers && getOfficeNumber(allPhoneNumbers),
  };
};

export const getOfficeNumber = (
  numbers: Maybe<ContactPhoneNumber>[],
): string | undefined | null => {
  const officeNumbers = numbers.filter(
    number => number && number.usage === ContactPhoneNumberUsage.Office,
  );
  const officeNumber = officeNumbers[0];
  return officeNumber !== null && officeNumber !== undefined
    ? officeNumber.number
    : undefined;
};

const getUpdateContactVariables = (
  result: any,
  allPhoneNumbers: Maybe<ContactPhoneNumber>[] | null | undefined,
) => {
  if (allPhoneNumbers) {
    if (result.learner.phoneNumber) {
      // override existing office number

      allPhoneNumbers = allPhoneNumbers
        .filter(phoneNumber => {
          return (
            phoneNumber && phoneNumber.usage !== ContactPhoneNumberUsage.Office
          );
        })
        .map(phoneNumber => {
          return (
            phoneNumber && {
              usage: phoneNumber.usage,
              number: phoneNumber.number,
            }
          );
        });
      allPhoneNumbers.push({
        usage: ContactPhoneNumberUsage.Office,
        number: result.learner.phoneNumber,
      });
    } else if (allPhoneNumbers.length > 0) {
      // delete existing office number
      allPhoneNumbers = allPhoneNumbers.filter(phoneNumber => {
        return (
          phoneNumber && phoneNumber.usage !== ContactPhoneNumberUsage.Office
        );
      });
    }
  }

  return {
    contactId: result.learner.id,
    personalName: {
      firstName: result.learner.firstName,
      lastName: result.learner.lastName,
    },
    accountId: result.learner.account.id,
    jobRole: result.learner.jobRole,
    phoneNumbers: allPhoneNumbers,
  };
};
