import { RegistrationWithProgress } from "../generated/lmsTypes";
import { Registerable } from "../types/Registerables";
import { Registration } from "../types/Registration";

export const MAX_SCORE = 100;

export const getProgress = (
  totalPiecesOfContent: number,
  completedPiecesOfContent: number,
) => {
  return completedSteps <= totalSteps
    ? Math.round(
        (completedSteps(completedPiecesOfContent) * MAX_SCORE) /
          totalSteps(totalPiecesOfContent),
      )
    : MAX_SCORE;
};

export const totalSteps = (totalPiecesOfContent: number) =>
  totalPiecesOfContent < 0 ? 0 : Math.round(totalPiecesOfContent);

export const completedSteps = (completedPiecesOfContent: number) =>
  completedPiecesOfContent < 0 ? 0 : Math.round(completedPiecesOfContent);

export const getRequiredSessions = (
  reg: Registerable | Registration | RegistrationWithProgress | undefined,
) => {
  const numberOfSessions = reg?.attendance?.totalSessions || 0;
  const attendedSessions = reg?.attendance?.sessionsAttended || 0;
  const missedSessions = reg?.attendance?.sessionsMissed || 0;
  const allowedMissedSessions = reg?.course?.allowedMissedSessions || 0;

  const requiredSessions = numberOfSessions - allowedMissedSessions;
  const missedRequiredSessions = missedSessions - allowedMissedSessions;
  const attendedRequiredSessions =
    attendedSessions > requiredSessions && missedRequiredSessions < 0
      ? attendedSessions + missedRequiredSessions
      : attendedSessions;

  return { requiredSessions, attendedRequiredSessions };
};
