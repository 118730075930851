import gql from "graphql-tag";
import {
  CART_ANALYTICS_DETAILS,
  CART_FRAGMENT_QUERY,
  CART_ITEM_ANALYTICS_DETAILS,
  POINT_OF_SALE_LEARNER_FIELD_DEFINITION_FRAGMENT,
} from "./fragments";

export const ACCOUNT_FRAGMENT = gql`
  fragment Account on Account {
    id
    name
    ancestors {
      id
      name
    }
    canSelfCheckoutByInvoice
  }
`;

export const CONTACT_FRAGMENT = gql`
  fragment Contact on Contact {
    id
    firstName
    lastName
    emailAddress
    jobRole
    department
    locale
    timeZoneName
    isCoordinator
    faxPhoneNumber
    homePhoneNumber
    mobilePhoneNumber
    officePhoneNumber
    account {
      ...Account
    }
    personalName {
      firstName
      middleName
      lastName
    }
    customFieldValues {
      definitionKey
      value
    }
    postalAddress {
      streetAddress1
      streetAddress2
      streetAddress3
      town
      postcode
      province {
        name
        code
      }
      country {
        name
        code
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const UPDATE_CONTACT_MUTATION = gql`
  mutation updateContact(
    $cartId: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $billingAddress: PostalAddressInput
    $pointOfSaleFields: [PointOfSaleFieldValueInput!]
  ) {
    cart {
      updateCart(
        input: {
          id: $cartId
          firstName: $firstName
          lastName: $lastName
          email: $email
          billingAddress: $billingAddress
          pointOfSaleFields: $pointOfSaleFields
        }
      ) {
        errors {
          label
          value
          message
        }
        cart {
          ...Cart
        }
      }
    }
  }
  ${CART_FRAGMENT_QUERY}
`;

export const GET_CART = gql`
  query getCart($id: ID!) {
    cart(id: $id) {
      id
      invoiceId
      state
      buyerDetails {
        firstName
        lastName
        email
        billingAddress {
          streetAddress1
          streetAddress2
          streetAddress3
          town
          province {
            name
            code
          }
          postcode
          country {
            id
            name
            code
          }
        }
      }
      items {
        id
        learnerDetails {
          firstName
          lastName
          email
        }
        productOption {
          id
          name
          price {
            amount
          }
        }
        totalAmount
        unitAmount
        quantity
        pricingAgreementPrice {
          id
          pricingAgreement {
            id
            name
          }
        }
      }
      price {
        subTotal
        grandTotal
        taxes {
          totalAmount
          type {
            id
            name
            effectiveRate
          }
        }
      }
      currency {
        name
        symbol
        code
      }
    }
  }
`;

export const GET_VIEWERS_LAST_CART = gql`
  query getViewersLastCart {
    viewer {
      contact {
        ...Contact
      }
      lastCart {
        ...Cart
        ...CartAnalyticsDetails
        items {
          ...CartItemAnalyticsDetails
        }
      }
    }
  }
  ${CONTACT_FRAGMENT}
  ${CART_FRAGMENT_QUERY}
  ${CART_ANALYTICS_DETAILS}
  ${CART_ITEM_ANALYTICS_DETAILS}
`;

export const VIEWER_CONTACT_DETAILS_QUERY = gql`
  query coordinator {
    viewer {
      isCoordinator
      contact {
        ...Contact
      }
    }
  }
  ${CONTACT_FRAGMENT}
`;

export const CREATE_CART_MUTATION = gql`
  mutation createCart($input: CreateCartInput!) {
    cart {
      createCart(input: $input) {
        cart {
          id
        }
        errors {
          message
        }
      }
    }
  }
`;

export const POINT_OF_SALE_LEARNER_FIELD_QUERY = gql`
  query getPointOfSaleLearnerFields(
    $entityType: PointOfSaleFieldEntityType
    $filters: [PointOfSaleFieldFilter!]
  ) {
    pointOfSaleLearnerFields(entityType: $entityType, filters: $filters) {
      ...PointOfSaleLearnerFieldDefinition
    }
  }

  ${POINT_OF_SALE_LEARNER_FIELD_DEFINITION_FRAGMENT}
`;

export const ADD_CART_LINE_ITEM_MUTATION = gql`
  mutation addCartLineItem($input: AddCartLineItemInput!) {
    cart {
      addLineItem(input: $input) {
        cart {
          id
          ...CartAnalyticsDetails
          items {
            id
            ...CartItemAnalyticsDetails
            productOption {
              id
              name
              price {
                amount
              }
            }
            learnerDetails {
              firstName
              lastName
              email
            }
          }
        }
        errors {
          message
        }
      }
    }
  }
  ${CART_ANALYTICS_DETAILS}
  ${CART_ITEM_ANALYTICS_DETAILS}
`;

export const UPDATE_EVENT_CART_LINE_ITEM_MUTATION = gql`
  mutation updateEventCartLineItem($input: UpdateEventLineItemInput!) {
    cart {
      updateEventLineItem(input: $input) {
        cart {
          id
          items {
            id
            productOption {
              id
              name
              price {
                amount
              }
            }
            learnerDetails {
              firstName
              lastName
              email
            }
          }
        }
        errors {
          message
        }
      }
    }
  }
`;

export const UPDATE_PATH_CART_LINE_ITEM_MUTATION = gql`
  mutation updatePathCartLineItem($input: UpdatePathLineItemInput!) {
    cart {
      updatePathLineItem(input: $input) {
        cart {
          id
          items {
            id
            productOption {
              id
              name
              price {
                amount
              }
            }
            learnerDetails {
              firstName
              lastName
              email
            }
          }
        }
        errors {
          message
        }
      }
    }
  }
`;

export const REGISTER_LEARNERS_ONTO_PRIVATE_BOOKING_MUTATION = gql`
  mutation registerLearnersOntoPrivateBooking(
    $input: RegisterLearnersOntoPrivateBookingInput!
  ) {
    booking {
      registerLearnersOntoPrivateBooking(input: $input) {
        booking {
          id
          name
          learners {
            edges {
              node {
                id
                contact {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
        errors {
          label
          value
          message
        }
      }
    }
  }
`;

export const PLACE_ORDER_MUTATION = gql`
  mutation placeOrder($input: PlaceOrderInput!) {
    cart {
      placeOrder(input: $input) {
        cart {
          ...Cart
          ...CartAnalyticsDetails
          items {
            ...CartItemAnalyticsDetails
          }
        }
        errors {
          message
        }
      }
    }
  }
  ${CART_FRAGMENT_QUERY}
  ${CART_ANALYTICS_DETAILS}
  ${CART_ITEM_ANALYTICS_DETAILS}
`;

export const APPLY_PRICING_MUTATION = gql`
  mutation apply($input: ApplyPricingInput!) {
    cart {
      applyPricing(input: $input) {
        cart {
          id
          invoiceId
          state
          items {
            id
            productOption {
              id
              name
              price {
                amount
              }
            }
            totalAmount
            unitAmount
            quantity
            pricingAgreementPrice {
              id
              pricingAgreement {
                id
                name
              }
            }
          }
          price {
            subTotal
            grandTotal
            taxes {
              totalAmount
              type {
                id
                name
                effectiveRate
              }
            }
          }
          currency {
            name
            symbol
            code
          }
        }
        errors {
          label
          message
          value
        }
      }
    }
  }
`;

export const CHECKOUT_QUERY = gql`
  query checkoutQuery {
    viewer {
      contact {
        ...Contact
      }
      lastCart {
        ...Cart
        ...CartAnalyticsDetails
        items {
          ...CartItemAnalyticsDetails
        }
      }
    }
    eventLearnerPoSFields: pointOfSaleLearnerFields(
      entityType: CartLineItemEventLearner
    ) {
      ...PointOfSaleLearnerFieldDefinition
    }
    pathLearnerPoSFields: pointOfSaleLearnerFields(
      entityType: CartLineItemPathLearner
    ) {
      ...PointOfSaleLearnerFieldDefinition
    }
    store {
      hidePricesBeforePricingAgreementApplied
      terms {
        title
        text
      }
      paymentMethods {
        id
        __typename
        paymentAttributeDefinitions {
          key
          label
          type
          options {
            label
            value
          }
        }
      }
    }
  }
  ${CONTACT_FRAGMENT}
  ${CART_FRAGMENT_QUERY}
  ${POINT_OF_SALE_LEARNER_FIELD_DEFINITION_FRAGMENT}
  ${CART_ANALYTICS_DETAILS}
  ${CART_ITEM_ANALYTICS_DETAILS}
`;

export const REMOVE_CART_LINE_ITEM_MUTATION = gql`
  mutation removeCartLineItem($input: RemoveCartLineItemInput!) {
    cart {
      removeCartLineItem(input: $input) {
        cart {
          ...Cart
          ...CartAnalyticsDetails
          items {
            ...CartItemAnalyticsDetails
          }
        }
        errors {
          message
        }
      }
    }
  }
  ${CART_FRAGMENT_QUERY}
  ${CART_ANALYTICS_DETAILS}
  ${CART_ITEM_ANALYTICS_DETAILS}
`;

export const APPLY_PROMO_CODE_MUTATION = gql`
  mutation applyPromotionCode($input: ApplyPromotionCodeInput!) {
    cart {
      applyPromotionCode(input: $input) {
        cart {
          ...Cart
        }
        errors {
          message
        }
      }
    }
  }
  ${CART_FRAGMENT_QUERY}
`;

export const REMOVE_PROMO_CODE_MUTATION = gql`
  mutation removePromotionCode($input: RemovePromotionCodeInput!) {
    cart {
      removePromotionCode(input: $input) {
        cart {
          ...Cart
        }
        errors {
          message
        }
      }
    }
  }
  ${CART_FRAGMENT_QUERY}
`;

export const CREATE_PAYMENT_SESSION = gql`
  mutation createPaymentSession($input: PaymentSessionCreateInput!) {
    createPaymentSession(input: $input) {
      token
      validUntil
    }
  }
`;

export const CREATE_STRIPE_PAYMENT_INTENT = gql`
  mutation createPaymentIntent($input: CreateStripePaymentIntentInput!) {
    stripe {
      createPaymentIntent(input: $input) {
        paymentIntent {
          id
          clientSecret
          requiresAction
        }
        cart {
          id
        }
        errors {
          message
          label
          value
        }
      }
    }
  }
`;

export const PLACE_STRIPE_ORDER = gql`
  mutation placeStripeOrder($input: PlaceStripeOrderInput!) {
    stripe {
      placeOrder(input: $input) {
        cart {
          id
        }
        errors {
          message
          value
          label
        }
      }
    }
  }
`;

export const PLACE_TRAINING_TOKEN_ORDER = gql`
  mutation placeTrainingTokenOrder($input: PlaceTrainingTokenOrderInput!) {
    trainingToken {
      placeOrder(input: $input) {
        cart {
          id
        }
        errors {
          message
          value
          label
        }
      }
    }
  }
`;

export const UPDATE_FINANCIAL_UNIT = gql`
  mutation updateFinancialUnit($input: UpdateCartFinancialUnitInput!) {
    cart {
      updateFinancialUnit(input: $input) {
        cart {
          ...Cart
          ...CartAnalyticsDetails
          items {
            ...CartItemAnalyticsDetails
          }
        }
        errors {
          message
        }
        removedLineItems {
          id
          quantity
          unitAmount
          productOption {
            id
            name
            price {
              financialUnit {
                name
                symbol
                code
              }
            }
            ... on Event {
              start
              end
              location {
                id
                name
              }
            }
          }
        }
      }
    }
  }
  ${CART_FRAGMENT_QUERY}
  ${CART_ANALYTICS_DETAILS}
  ${CART_ITEM_ANALYTICS_DETAILS}
`;
