import React, { FunctionComponent, Fragment } from "react";
import {
  Container,
  Card,
  SubSection,
  FutureArticleHeader,
  Placeholder,
  Alert,
  Row,
  Col,
  LoadingScreen,
} from "@administrate/piston-ux";
import { get, range } from "lodash";
import { useTranslation } from "react-i18next";

import {
  Cart,
  CartLineItem,
  LearnerDetails,
  Maybe,
} from "../../generated/weblinkTypes";
import { GET_CART } from "../../queries/cart";
import { useParams } from "../../useHistory";
import { useWebLinkQuery } from "../../hooks/weblink";
import { BookerIntention, BookingPage, BookingStep } from "./BookingPage";
import { OrderSummary } from "./OrderSummary";
import { useWeblinkSettings } from "../../hooks/useWeblinkSettings";
import { useBookerIntention } from "../../hooks/useBookerIntention";

export const BookingConfirmation: FunctionComponent = () => {
  const { t } = useTranslation();
  const { bookingId } = useParams<{bookingId:string}>();
  const [bookerIntention] = useBookerIntention();
  const { hidePricesBeforePricingAgreementApplied: hidePrices } =
    useWeblinkSettings();

  const { data: cartResponse, loading: cartLoading } = useWebLinkQuery<Cart>(
    GET_CART,
    {
      variables: { id: bookingId },
    },
  );
  const cart = get(cartResponse, "cart");
  const cartHasItems = (cart?.items || []).length > 0;
  const isZeroPriced =
    bookerIntention === BookerIntention.Self &&
    cartHasItems &&
    parseInt(cart?.price?.grandTotal, 10) === 0;

  if (bookerIntention === BookerIntention.Self && !cart) {
    return <LoadingScreen />;
  }

  return (
    <BookingPage
      step={3}
      bookerIntention={bookerIntention}
      isZeroPriced={isZeroPriced}
    >
      <Alert
        overPage
        type="success"
        message={t("paymentSuccessfultMessage")}
        glyph="okSign"
      />
      <Container>
        <Row>
          <Col
            lg={3}
            lgOffset={1}
            lgPush={7}
            md={4}
            mdPush={8}
            data-label="Order Summary"
          >
            <OrderSummary
              loading={cartLoading}
              cart={cart}
              step={BookingStep.bookingComplete}
              hidePrices={hidePrices}
              isZeroPriced={isZeroPriced}
            />
          </Col>
          <Col lg={7} lgPull={3} md={8} mdPull={4} className="booking-page">
            <Card>
              <FutureArticleHeader
                title={
                  bookerIntention === BookerIntention.Self
                    ? t("registrationComplete")
                    : t("bookingComplete")
                }
              />
              <p id="successful-order-message">
                {bookerIntention === BookerIntention.Self
                  ? t("successfulRegistrationMessage")
                  : t("successfulBookingMessage")}
              </p>
              <div>
                <span className="form-control-static-label">
                  {t("orderNumber")}
                </span>
                <p>{atob(bookingId).split(":")[1]}</p>
              </div>
              <SubSection
                titleLevel="h3"
                title={
                  bookerIntention === BookerIntention.Self
                    ? t("registrationInformation")
                    : t("bookerInformation")
                }
              >
                <PersonRow
                  loading={cartLoading}
                  firstName={
                    cart && cart.buyerDetails && cart.buyerDetails.firstName
                  }
                  lastName={
                    cart && cart.buyerDetails && cart.buyerDetails.lastName
                  }
                  email={cart && cart.buyerDetails && cart.buyerDetails.email}
                />
              </SubSection>
              <SubSection
                titleLevel="h3"
                title={
                  bookerIntention === BookerIntention.Self
                    ? t("information")
                    : t("learnerDetails")
                }
              >
                {!cartLoading &&
                  cart.items.map((item: CartLineItem, i: number) => {
                    return (
                      <Fragment key={i}>
                        {item.productOption && (
                          <p className="font-weight-bold">
                            {t("course")}: {item.productOption.name}
                          </p>
                        )}
                        {bookerIntention === BookerIntention.Coordinating &&
                          item.learnerDetails &&
                          item.learnerDetails.map(
                            (learner: Maybe<LearnerDetails>, i: number) => {
                              if (learner) {
                                return (
                                  <Fragment key={i}>
                                    <PersonRow
                                      loading={cartLoading}
                                      firstName={learner.firstName}
                                      lastName={learner.lastName}
                                      email={learner.email}
                                    />
                                  </Fragment>
                                );
                              }
                              return undefined;
                            },
                          )}
                        {bookerIntention === BookerIntention.Coordinating && (
                          <UnnamedLearners
                            item={item}
                            cartLoading={cartLoading}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                {cartLoading && (
                  <Fragment>
                    <p className="font-weight-bold">
                      {t("course")}: <Placeholder />
                    </p>
                    <LoadingLearnerDetails />
                  </Fragment>
                )}
              </SubSection>
            </Card>
          </Col>
        </Row>
      </Container>
    </BookingPage>
  );
};

export const PersonRow: FunctionComponent<{
  firstName?: null | string;
  lastName?: null | string;
  email?: null | string;
  loading: boolean;
}> = ({ firstName, lastName, email, loading }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col sm={6}>
        <div>
          <span className="form-control-static-label">{t("name")}</span>
          {loading && <Placeholder />}
          {!loading && (
            <p>
              {firstName && firstName}
              {firstName && lastName && " "}
              {lastName && lastName}
            </p>
          )}
        </div>
      </Col>
      <Col sm={6}>
        <div>
          <span className="form-control-static-label">{t("emailAddress")}</span>
          {loading && <Placeholder />}
          {!loading && <p>{email || "-"}</p>}
        </div>
      </Col>
    </Row>
  );
};

const LoadingLearnerDetails: FunctionComponent = () => {
  return (
    <Fragment>
      {range(1).map(i => (
        <Fragment key={i}>
          <PersonRow loading={true} />
        </Fragment>
      ))}
    </Fragment>
  );
};

export const UnnamedLearners: FunctionComponent<{
  item: CartLineItem;
  cartLoading: boolean;
}> = ({ item, cartLoading }) => {
  const { t } = useTranslation();
  let unnamedLearners = 0;
  let namedLearners = 0;

  if (item.learnerDetails) {
    namedLearners = item.learnerDetails.length;
  }
  if (item.quantity && item.quantity > namedLearners) {
    unnamedLearners = item.quantity - namedLearners;
    return (
      <PersonRow
        loading={cartLoading}
        firstName={`${t("unnamedLearner")} (x${unnamedLearners})`}
      />
    );
  }
  return <Fragment />;
};
