import React, { useState, FunctionComponent } from "react";
import { Input } from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";

import { CardElement } from "@stripe/react-stripe-js";

export const StripePaymentInfo: FunctionComponent<{
  isBillingAddressProvided: boolean;
}> = ({ isBillingAddressProvided }) => {
  const { t } = useTranslation();
  const [validCard, setValidCard] = useState<boolean>(false);

  return (
    <React.Fragment>
      <p>{t("encryptedTransactions")}</p>
      <label
        className="control-label"
        style={{
          color: validCard ? "#333333" : "#a94442",
        }}
      >
        {t("cardNumber")}
      </label>
      <CardElement
        options={{
          hidePostalCode: isBillingAddressProvided,
          style: {
            base: {
              fontSize: "14px",
            },
            invalid: {
              color: "#a94442",
            },
          },
        }}
        onChange={e => {
          setValidCard(e.complete);
        }}
      />
      <span style={{ display: "none" }}>
        <Input
          name="validCard"
          valid={() => validCard}
          label=""
          type="hidden"
        />
      </span>
    </React.Fragment>
  );
};
