export const translateLearningObjectiveName = (
  name: string,
  t: Function,
): string => {
  if (name === "Course") {
    return t("course");
  }
  if (name === "Learning Path") {
    return t("learningPath");
  }
  if (name === "External") {
    return t("external");
  }
  if (name === "Event") {
    return t("event");
  }
  return name;
};
