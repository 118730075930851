import React, { FunctionComponent } from "react";
import {
  Card,
  Alert,
  StatusLabel,
  Button,
  LoadingScreen,
  FutureDetailPage,
  DetailPageContent,
} from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ColorOptions } from "@administrate/piston-ux/lib/types";

import { useHistory, useParams } from "../../useHistory";
import { LEARNER_QUERY } from "../../queries/learnerManagement";
import { Query } from "../../generated/lmsTypes";
import { LearnerInformation } from "./LearnerInformation";
import { useLmsQuery } from "../../hooks/lms";
import { DetailPageOverview } from "@administrate/piston-ux/lib/layouts/DetailPage/DetailPageOverview";

export const LearnerPage: FunctionComponent<{
  page: "courses" | "learningPaths" | "coordinatedAccounts";
  id: string;
}> = ({ children, page, id }) => {
  const { id: learnerId } = useParams<{id:string}>();

  const history = useHistory();
  const { t } = useTranslation();

  const { data, error, loading } = useLmsQuery<Query>(LEARNER_QUERY, {
    variables: {
      filters: { field: "id", operation: "eq", value: id || learnerId },
    },
    fetchPolicy: "cache-and-network",
  });

  if (!data) {
    if (loading) return <LoadingScreen />;
    return <Alert type="error" message={t("problemFetchingLearner")} />;
  }

  const values = data.coordinatorManagedContacts.edges;

  if (error || (!loading && (!values || values.length === 0))) {
    if (error) {
      console.error(error);
    }
    return <Alert type="error" message={t("problemFetchingLearner")} />;
  }

  const learner = values[0].node;

  if (!page) {
    if (loading) return <LoadingScreen />;
    return <LearnerInformation learnerLoading={loading} data={learner} />;
  }

  return (
    <FutureDetailPage
      detailType="Learner"
      layout="adjacent"
      title={t("learner")}
      back={{
        label: t("learners"),
        onClick: () => history.push("/learner-management/learners/"),
      }}
      legacyId=""
      page="overview"
      form={{
        saveState: null,
      }}
      navigation={[
        {
          label: t("courses"),
          link: (
            <Link to={`/learner-management/learners/${id}/courses`}>
              {t("courses")}
            </Link>
          ),
          active: page === "courses",
        },
        {
          label: t("learningPaths"),
          link: (
            <Link to={`/learner-management/learners/${id}/learning-paths`}>
              {t("learningPaths")}
            </Link>
          ),
          active: page === "learningPaths",
        },
        {
          label: t("coordinatedAccounts"),
          link: (
            <Link
              to={`/learner-management/learners/${id}/coordinated-accounts`}
            >
              {t("coordinatedAccounts")}
            </Link>
          ),
          active: page === "coordinatedAccounts",
        },
      ]}
    >
      <DetailPageOverview>
        <Card
          title={`${learner.firstName} ${learner.lastName}`}
          profile={{ type: "contact", alignment: "vertical" }}
        >
          <div className="center-block mb-4">
            <span className="d-block font-weight-bold">
              {learner.account.name}
            </span>
            <span className="d-block mb-2">{learner.jobRole}</span>
            {learner.isOverlappingCoordinator && (
              <StatusLabel
                text={t("coordinator")}
                color={ColorOptions.LightGrey}
              />
            )}
            {learner.emailAddress && (
              <span className="d-block mt-4">
                <a href={`mailto:${learner.emailAddress}`}>
                  {learner.emailAddress}
                </a>
              </span>
            )}
          </div>

          <div
            style={{
              position: "absolute",
              top: 12,
              right: 12,
            }}
          >
            <Button
              onClick={() =>
                history.push(`/learner-management/learners/${id}/edit`)
              }
              type="default"
              label={t("edit")}
              id="learner__edit"
            />
          </div>
        </Card>
      </DetailPageOverview>
      <DetailPageContent>{children}</DetailPageContent>
    </FutureDetailPage>
  );
};
