import React, { Fragment, FunctionComponent } from "react";
import { ContentResult, Registration } from "../../types/Registration";
import { useTranslation } from "react-i18next";
import { useDateFormatter } from "../../hooks/useDateFormatter";
import { CourseDetailList } from "./CourseDetailList";
import { PlayerContentList } from "./PlayerContentList";
import { useViewer } from "../../providers/ViewerProvider";
import { Button } from "@administrate/piston-ux";
import { fetchMoreCourseDetailsWrapper } from "../../utils/fetchMoreHelpers";
import {
  Content,
  Maybe,
  Scalars,
  Scorm,
  TrainingPass,
  TrainingPassType,
} from "../../generated/lmsTypes";
import { FetchMoreCallback } from "../../types/ApolloCallbacks";
import { ListSeparator } from "@administrate/piston-ux/lib/List";
import { Link } from "react-router-dom";
import { checkHasLockedContent } from "../TrainingPass/ContentStatusLabel";
import { useTrainingPassTypes } from "../../hooks/useTrainingPassTypes";

export const ContentList: FunctionComponent<{
  usage: "player" | "courseDetail";
  activeContentId?: string;
  registration: Registration;
  learningPathId?: string;
  fetchMoreCourseDetails?: FetchMoreCallback;
  courseDetailsIsLoading?: boolean;
  isAccessible: boolean;
}> = ({
  usage,
  registration,
  activeContentId,
  learningPathId,
  fetchMoreCourseDetails,
  courseDetailsIsLoading,
  isAccessible,
}) => {
  const { t } = useTranslation();
  const passTypes = useTrainingPassTypes();
  const { dateFormat } = useDateFormatter({ showTime: true });
  const { viewer } = useViewer();

  const online = registration?.course?.content?.edges || [];
  const contentResults = registration?.contentResults?.edges || [];
  const registrationId = registration.id;

  const objectiveType = (type: string) => {
    let label: string;
    switch (type) {
      case "video":
        label = t("video");
        break;
      case "document":
        label = t("document");
        break;
      case "resource":
        label = t("resource");
        break;
      case "scorm":
        label = t("scorm");
        break;
      case "presentation":
        label = t("presentation");
        break;
      case "classroom":
        label = t("classroom");
        break;
      case "quiz":
        label = t("quiz");
        break;
      case "external":
        label = t("external");
        break;
      case "discussion":
        label = t("discussion");
        break;
      case "kryterion":
        label = t("kryterion");
        break;
      default:
        label = "";
    }

    return label;
  };

  const getContentUrl = (
    isPreviewer: boolean,
    contentId: string,
    learningPathId: string | undefined,
  ) => {
    if (isPreviewer) {
      return `/preview/content/${contentId}`;
    }
    if (learningPathId) {
      return `/my-courses/learning-path/${learningPathId}/course/${registration.id}/content/${contentId}`;
    } else {
      return `/my-courses/course/${registration.id}/content/${contentId}`;
    }
  };

  return (
    <Fragment>
      {online
        .sort((a, b) => (a.node.order ?? 0) - (b.node.order ?? 0))
        .map(({ node: onlineContent }) => {
          if (onlineContent.type === "separator") {
            return (
              <ListSeparator
                label={onlineContent?.displayName ?? ""}
                key={onlineContent.id!}
              />
            );
          }
          const results = contentResults.find(
            ({ node: contentResultsNode }) =>
              contentResultsNode.contentId === onlineContent.id,
          )?.node;

          const lastAccessed =
            results?.lastAccessed &&
            dateFormat({
              date: results?.lastAccessed,
            });

          let contentLocked = !isAccessible;
          let remainingAttempts;
          const dataLabel =
            onlineContent.displayName || onlineContent.htmlDescription;
          if (isScormContent(onlineContent)) {
            contentLocked =
              contentLocked ||
              onlineContent?.maxAttempts === results?.completedAttempts;
            if (onlineContent?.maxAttempts && results?.completedAttempts) {
              remainingAttempts = Math.max(
                onlineContent?.maxAttempts - results?.completedAttempts,
                0,
              );
            }
          }
          const hasLockedPremiumContent = checkHasLockedContent(
            onlineContent,
            viewer,
          );

          const isPreviewer = viewer?.isPreviewer || false;

          const contentUrl = getContentUrl(
            isPreviewer,
            onlineContent.id!,
            learningPathId,
          );

          if (usage === "player" && activeContentId && registrationId) {
            return (
              <Fragment key={onlineContent.id!}>
                <PlayerContentList
                  passTypes={passTypes}
                  hasLockedPremiumContent={hasLockedPremiumContent}
                  remainingAttempts={remainingAttempts}
                  lastAccessed={lastAccessed}
                  contentLocked={contentLocked}
                  type={onlineContent.type!}
                  typeLabel={objectiveType(onlineContent.type!)}
                  name={onlineContent?.displayName!}
                  description={onlineContent?.htmlDescription!}
                  isRequired={onlineContent.isRequired}
                  results={results}
                  isActive={activeContentId === onlineContent.id}
                  instructions={onlineContent.htmlDescription || ""}
                  externalActivityInstructions={
                    onlineContent.htmlDescription || ""
                  }
                  dataLabel={dataLabel!}
                  contentUrl={contentUrl}
                  contentId={onlineContent.id!}
                  learnerPasses={viewer?.trainingPasses as TrainingPass[]}
                  includedInPassTypeIds={onlineContent?.includedInPassTypeIds}
                />
              </Fragment>
            );
          }

          return (
            <Fragment key={onlineContent.id!}>
              {[
                "video",
                "scorm",
                "resource",
                "discussion",
                "external",
                "quiz",
                "presentation",
                "kryterion",
              ].includes(onlineContent.type!) &&
              !contentLocked &&
              !hasLockedPremiumContent ? (
                <Link
                  to={contentUrl}
                  data-label={dataLabel}
                  role="listitem"
                  className="no-underline no-link-color"
                >
                  <CourseDetailList
                    passTypes={passTypes}
                    hasLockedPremiumContent={hasLockedPremiumContent}
                    contentId={onlineContent.id!}
                    learnerPasses={viewer?.trainingPasses as TrainingPass[]}
                    includedInPassTypeIds={onlineContent?.includedInPassTypeIds}
                    remainingAttempts={remainingAttempts}
                    lastAccessed={lastAccessed}
                    contentLocked={contentLocked}
                    type={onlineContent.type!}
                    typeLabel={objectiveType(onlineContent.type!)}
                    name={onlineContent?.displayName!}
                    description={onlineContent?.htmlDescription!}
                    isRequired={onlineContent.isRequired}
                    results={results}
                    instructions={onlineContent.htmlDescription || ""}
                    externalActivityInstructions={
                      onlineContent.htmlDescription || ""
                    }
                    dataLabel={dataLabel!}
                    hasUrl={!!contentUrl}
                  />
                </Link>
              ) : (
                <CourseDetailList
                  passTypes={passTypes}
                  hasLockedPremiumContent={hasLockedPremiumContent}
                  contentId={onlineContent.id!}
                  learnerPasses={viewer?.trainingPasses as TrainingPass[]}
                  includedInPassTypeIds={onlineContent?.includedInPassTypeIds}
                  remainingAttempts={remainingAttempts}
                  lastAccessed={lastAccessed}
                  contentLocked={contentLocked}
                  type={onlineContent.type!}
                  typeLabel={objectiveType(onlineContent.type!)}
                  name={onlineContent?.displayName!}
                  description={onlineContent?.htmlDescription!}
                  isRequired={onlineContent.isRequired}
                  results={results}
                  dataLabel={dataLabel!}
                />
              )}
            </Fragment>
          );
        })}
      {fetchMoreCourseDetails &&
        registration?.course?.content?.pageInfo?.hasNextPage && (
          <div style={{ textAlign: "center" }}>
            <Button
              type="suppressed"
              label={t("showMore")}
              onClick={() =>
                fetchMoreCourseDetailsWrapper(
                  online.length,
                  fetchMoreCourseDetails,
                ).catch(error => {
                  // apollo error: update query doesn't get cancelled after unmount
                  if (error.name !== "Invariant Violation") {
                    throw error;
                  }
                })
              }
              disabled={courseDetailsIsLoading}
            />
          </div>
        )}
    </Fragment>
  );
};

export type ContentListProps = {
  passTypes: TrainingPassType[];
  hasLockedPremiumContent?: boolean;
  contentId: string;
  learnerPasses?: TrainingPass[];
  includedInPassTypeIds?: Maybe<Maybe<string>[]> | undefined;
  contentLocked?: boolean;
  type: string;
  typeLabel: string;
  name?: string;
  description?: string;
  isRequired?: Maybe<Scalars["Boolean"]>;
  lastAccessed?: string;
  results?: ContentResult;
  remainingAttempts?: number;
  dataLabel?: string;
  instructions?: string;
  externalActivityInstructions?: string;
  hasUrl?: boolean;
};

function isScormContent(content: Content): content is Scorm {
  return content.type === "scorm";
}
