import { Alert } from "@administrate/piston-ux";
import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { isIE } from "react-device-detect";

export const SupportMessage: FunctionComponent = () => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (isIE) setShowMessage(true);
  }, []);

  return (
    <Fragment>
      {showMessage && (
        <Alert
          type="warning"
          message={
            <Fragment>
              As of 31 March, 2022, your Student Portal will no longer be
              supporting the Internet Explorer 11 (IE11) browser. Accessing this
              application with IE11 on or after that date may result in degraded
              or unexpected site behavior. We suggest that you upgrade as soon
              as possible to a more modern browser such as Google Chrome. Read
              more on our{" "}
              <a
                target="_blank"
                href="https://support.getadministrate.com/hc/en-us/articles/360012089854"
                rel="noopener noreferrer"
              >
                supported browser list page
              </a>
              .
            </Fragment>
          }
          className="support-message"
        />
      )}
    </Fragment>
  );
};
