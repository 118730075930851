import React, { Fragment, FunctionComponent } from "react";
import {
  LearningPath,
  LearningObjective,
  Col,
  Row,
  FutureArticleHeader,
  Card,
  FutureDetailPage,
  DetailPageContent,
  Placeholder,
  Alert,
} from "@administrate/piston-ux";
import { get } from "lodash";
import {
  Query,
  LearningPathResult,
  LearningPathRegistration,
  CourseOutcome,
} from "../../generated/lmsTypes";
import { LMS_LEARNING_PATH_DETAILS_QUERY } from "../../queries/learningPaths";
import { useHistory, useParams } from "../../useHistory";
import {
  LearningObjectiveUnion,
  LearningOutcomeUnion,
  mapObjective,
  RegisterableUnion,
  getObjectiveAvailability,
  getObjectiveAvailabilityMap,
  ObjectiveAvailability,
} from "../../utils/lmsLearningPathHelpers";
import { determinePassAccessStatus } from "../../utils/registrationHelpers";
import coursePlaceholder from "../../images/course-placeholder.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLmsQuery } from "../../hooks/lms";
import { AchievementCard } from "../../components/AchievementCard";
import { LearningPathRegistrationModal } from "./LearningPathRegistrationModal";
import { extractNodes } from "../../utils/extractNodes";
import { DetailPageOverview } from "@administrate/piston-ux/lib/layouts/DetailPage/DetailPageOverview";
import { ColorOptions } from "@administrate/piston-ux/lib/types";
import { useGlobalStore } from "../../providers/GlobalStore";
import { ContentLoadingSkeleton } from "../../components/OnlineContent/ContentLoadingSkeleton";
import { translateLearningObjectiveName } from "../../utils/translationHelpers";
import { useViewer } from "../../providers/ViewerProvider";

export const LearningPathDetail: FunctionComponent = () => {
  const { id, status } = useParams<{id:string, status: string}>();
  const { t } = useTranslation();
  const history = useHistory();
  const { convertLMSImageUrl } = useGlobalStore();
  const { viewer } = useViewer();

  const {
    data: registrableResponse,
    refetch,
    loading: learningPathDetailsIsLoading,
  } = useLmsQuery<Query>(LMS_LEARNING_PATH_DETAILS_QUERY, {
    variables: { registrationId: id },
    fetchPolicy: "network-only",
  });

  const registration: RegisterableUnion = registrableResponse?.registerables
    ?.edges?.[0]?.node as LearningPathRegistration;
  const learningPath = registration?.learningPath;
  const learningPathTitle = learningPath?.name || "";
  const learningPathDescription = learningPath?.longDescription || "";
  const objectiveEdges = get(learningPath, "objectives.edges");
  const enforceObjectiveOrder = learningPath?.enforceObjectiveOrder || false;
  const passAccessExpired = !!registration?.passAccessExpired;

  let passExpiryMessage = "";
  if (registration?.trainingPassTypeId && passAccessExpired && viewer) {
    passExpiryMessage = determinePassAccessStatus(
      viewer,
      registration.trainingPassTypeId,
      t,
    );
  }

  return (
    <Fragment>
      {status === "registrationSuccess" && (
        <Alert message={t("registrationSuccess")} type="success" overPage />
      )}
      <FutureDetailPage
        title={learningPathTitle}
        layout="adjacent"
        detailType="Learning Path"
        legacyId={0}
        page="overview"
        form={{
          saveState: null,
        }}
        back={{
          label: t("myCourses"),
          onClick: () => history.push("/my-courses"),
        }}
      >
        <DetailPageOverview>
          {learningPathDetailsIsLoading && (
            <Card
              imageSource={coursePlaceholder}
              label={{
                text: t("learningPath"),
                color: ColorOptions.Blue,
              }}
              extraClass="card--type-learningPath"
            >
              <Placeholder />
            </Card>
          )}
          {!learningPathDetailsIsLoading && (
            <Card
              imageSource={
                learningPath?.imageUrl
                  ? convertLMSImageUrl(learningPath?.imageUrl)
                  : coursePlaceholder
              }
              label={{
                text: t("learningPath"),
                color: ColorOptions.Blue,
              }}
              extraClass="card--type-event"
            >
              {learningPath?.achievementType && (
                <AchievementCard
                  achievementTypeName={learningPath.achievementType.name}
                  achievement={registration?.achievement}
                />
              )}
            </Card>
          )}
        </DetailPageOverview>
        <DetailPageContent>
          {passExpiryMessage.length > 0 && (
            <Alert type="warning" message={passExpiryMessage} />
          )}
          {learningPathDetailsIsLoading && <ContentLoadingSkeleton />}
          {!!learningPathDescription.length && (
            <Card>
              <FutureArticleHeader title={t("description")} />
              <Row>
                <Col md={12}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: learningPathDescription,
                    }}
                  />
                </Col>
              </Row>
            </Card>
          )}
          {objectiveEdges && (
            <LearningPath>
              <LearningPathOutcomes
                objectives={extractNodes(objectiveEdges)}
                outcomes={extractNodes(get(registration, "results.edges"))}
                learningPathRegistrationId={id}
                refetchPath={refetch}
                enforceObjectiveOrder={enforceObjectiveOrder}
                disabled={passAccessExpired ?? false}
              />
            </LearningPath>
          )}
        </DetailPageContent>
      </FutureDetailPage>
    </Fragment>
  );
};

const LearningPathOutcomes: React.FunctionComponent<{
  objectives: LearningObjectiveUnion[];
  outcomes: (LearningPathResult | CourseOutcome)[];
  learningPathRegistrationId: string;
  refetchPath: any;
  enforceObjectiveOrder: boolean;
  disabled: boolean;
}> = ({
  objectives,
  outcomes,
  learningPathRegistrationId,
  refetchPath,
  enforceObjectiveOrder,
  disabled,
}) => {
  const objectiveAvailabilityMap = getObjectiveAvailabilityMap(
    outcomes,
    objectives,
    enforceObjectiveOrder,
  );
  return (
    <React.Fragment>
      {objectives.map((currentObjectiveData: LearningObjectiveUnion, i) => {
        return (
          <LearningOutcome
            key={currentObjectiveData?.id || i}
            learningObjectiveData={currentObjectiveData}
            outcomes={outcomes}
            learningPathRegistrationId={learningPathRegistrationId}
            refetchPath={refetchPath}
            objectiveAvailability={getObjectiveAvailability(
              currentObjectiveData?.id,
              objectiveAvailabilityMap,
              enforceObjectiveOrder,
              disabled,
            )}
          />
        );
      })}
    </React.Fragment>
  );
};

const mapObjectiveWithOutcome = (
  learningObjectiveData: LearningObjectiveUnion,
  learningPathRegistrationId: string,
  learningOutcome?: LearningOutcomeUnion,
) => {
  const objective = mapObjective(learningObjectiveData);
  if (
    objective.type === "Course" &&
    learningOutcome &&
    learningOutcome.__typename === "CourseOutcome"
  ) {
    objective.type = "Event";
    objective.withLinkProps = {
      routerLink: Link,
      url: `/my-courses/learning-path/${learningPathRegistrationId}/course/${learningOutcome.registrationId}`,
    };
  }
  return objective;
};

const LearningOutcome: React.FunctionComponent<{
  learningObjectiveData: LearningObjectiveUnion;
  outcomes: LearningPathResult[];
  learningPathRegistrationId: string;
  refetchPath: any;
  objectiveAvailability: ObjectiveAvailability;
}> = ({
  learningObjectiveData,
  outcomes,
  learningPathRegistrationId,
  refetchPath,
  objectiveAvailability,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const learningOutcome = outcomes.find(
    outcome => outcome.objectiveId === learningObjectiveData?.id,
  );
  const { t } = useTranslation();

  const courseCode =
    learningObjectiveData?.__typename === "CourseObjective" &&
    learningObjectiveData?.course
      ? learningObjectiveData.course.code
      : null;

  const objective = mapObjectiveWithOutcome(
    learningObjectiveData,
    learningPathRegistrationId,
    learningOutcome,
  );

  let description = objective.description || "";
  if (objective.type === "External" && !objectiveAvailability.isAvailable) {
    description = "";
  }

  return (
    <>
      <LearningObjective
        type={translateLearningObjectiveName(String(objective.type), t)}
        title={objective.title}
        onClick={
          objectiveAvailability.isAvailable && courseCode && !learningOutcome
            ? () => setShowModal(true)
            : undefined
        }
        description={description}
        status={learningOutcome?.completed ? "completed" : "incomplete"}
        withLinkProps={
          objectiveAvailability.isAvailable
            ? objective.withLinkProps
            : undefined
        }
        disabled={
          objectiveAvailability.isAvailable
            ? false
            : objectiveAvailability.messageOnDisabled || true
        }
      >
        {learningObjectiveData?.__typename === "LearningPathObjective" && (
          <LearningPathOutcomes
            objectives={extractNodes(
              get(learningObjectiveData, "learningPath.objectives.edges", []),
            )}
            outcomes={outcomes}
            learningPathRegistrationId={learningPathRegistrationId}
            refetchPath={refetchPath}
            enforceObjectiveOrder={
              learningObjectiveData.learningPath?.enforceObjectiveOrder ?? false
            }
            disabled={!objectiveAvailability.isAvailable}
          />
        )}
      </LearningObjective>
      {learningObjectiveData?.__typename === "CourseObjective" &&
        courseCode &&
        !learningOutcome && (
          // TODO this is the old tms-ux modal. we need to factor LearningPathDateAndLocations such that it can be used with lms-ux
          // TODO then put it in a modern archetypal piston-ux Modal component. Then we can swap this modal out
          <LearningPathRegistrationModal
            show={showModal}
            onClose={() => {
              refetchPath();
              setShowModal(false);
            }}
            courseTitle={learningObjectiveData?.course?.title || ""}
            learningPathRegistrationId={learningPathRegistrationId}
            objectiveId={learningObjectiveData?.id || undefined}
            courseCode={courseCode}
          />
        )}
    </>
  );
};
