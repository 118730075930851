import React from "react";
import useScormSetup from "../../hooks/useScormSetup";
import { LoadingScreen } from "@administrate/piston-ux";
import { setStateType } from "../../types/SetStateType";
import { isMobileDevice } from "../../utils/previewHelpers";

export const ScormLauncherInline: React.FunctionComponent<{
  registrationId?: string;
  contentId: string;
  entrypoint: string;
  version: string;
  setApolloError: setStateType<any>;
}> = ({ registrationId, contentId, entrypoint, version, setApolloError }) => {
  const { scormApiReady, frameSrc, scormDataModel } = useScormSetup(
    contentId,
    entrypoint,
    version,
    setApolloError,
    registrationId,
  );

  if (isMobileDevice) {
    setInterval(function () {
      scormDataModel?.commit();
    }, 30000);
  } else {
    window.addEventListener("beforeunload", function handleBeforeUnload() {
      scormDataModel?.commit();
    });
  }

  if (!scormApiReady || !frameSrc) return <LoadingScreen />;
  return (
    <iframe
      title="scorm"
      src={frameSrc}
      style={{
        width: "1px",
        minWidth: "100%",
        height: "calc(100% - 64px)",
        position: "absolute",
        left: 0,
        top: "64px",
        border: "none",
      }}
    />
  );
};
