import {
  EventObjective,
  CourseObjective,
  DocumentObjective,
  UrlObjective,
  LearningPathObjective,
  ExternalObjective,
  LearningObjectiveEdge,
  CourseOutcome,
  DocumentOutcome,
  UrlOutcome,
  LearningPathOutcome,
  ExternalOutcome,
  LearningOutcomeEdge,
  LearningPath as LearningPathType,
  Region,
  Price,
  Maybe,
} from "../generated/weblinkTypes";
import {
  LearningPathFormValues,
  NestedPathObjectiveOptionChoice,
} from "../types/LearningPaths";

export type LearningObjectiveUnion =
  | EventObjective
  | CourseObjective
  | DocumentObjective
  | UrlObjective
  | LearningPathObjective
  | ExternalObjective;

type LearningOutcomeUnion =
  | CourseOutcome
  | DocumentOutcome
  | UrlOutcome
  | LearningPathOutcome
  | ExternalOutcome;

export const getLearningObjectiveOptions = (
  objectives: LearningObjectiveEdge[],
) => {
  const learningObjectives: LearningObjectiveUnion[] = objectives.map(
    objectiveEdge => objectiveEdge.node,
  );
  return learningObjectives.map(learningObjective => {
    return {
      id: learningObjective.id,
      objective: mapObjective(learningObjective),
    };
  });
};

export const getLearningOutcomeOptions = (outcomes: LearningOutcomeEdge[]) => {
  const learningOutcomes: LearningOutcomeUnion[] = outcomes.map(
    outcomeEdge => outcomeEdge.node,
  );
  return learningOutcomes.map((learningOutcome: LearningOutcomeUnion) => {
    return {
      id: learningOutcome.id,
      type: extractOutcomeType(learningOutcome),
      event: extractOutcomeEvent(learningOutcome),
    };
  });
};

export const mapObjective = (objective: LearningObjectiveUnion) => {
  switch (objective.__typename) {
    case "CourseObjective":
      return {
        id: objective.id,
        type: "Course",
        name: objective.course.name,
        courseCode: objective.course.code,
        event: {
          eventId: "",
          location: null,
          remainingPlaces: null,
          start: null,
          end: null,
          timeZoneName: null,
          deliveryMethod: null,
        },
      };
    case "EventObjective":
      return {
        id: objective.id,
        type: "Event",
        name: objective.event.name,
        courseCode: "",
        event: {
          eventId: objective.event.id,
          location: objective.event.location
            ? objective.event.location.name
            : "-",
          remainingPlaces: objective.event.remainingPlaces,
          start: objective.event.start,
          end: objective.event.end,
          timeZoneName: null,
          deliveryMethod: null,
        },
      };
    case "ExternalObjective":
      return {
        id: objective.id,
        type: "External",
        name: objective.name,
      };
    case "LearningPathObjective":
      return {
        id: objective.id,
        type: "LearningPath",
        name: objective.learningPath.name,
        learningPath: objective.learningPath,
        nestedOptions: {},
      };
    default:
      return {
        id: objective.id,
        type: extractObjectiveType(objective),
        name: "",
        courseCode: "",
      };
  }
};

const extractObjectiveType = (objective: LearningObjectiveUnion) =>
  objective.__typename ? objective.__typename.replace("Objective", "") : "";

const extractOutcomeType = (outcome: LearningOutcomeUnion) =>
  outcome.__typename
    ? outcome.__typename
        .replace("Outcome", "")
        .replace("LearningPath", "Learning Path")
    : "";

const extractOutcomeEvent = (outcome: LearningOutcomeUnion) =>
  outcome.__typename === "CourseOutcome" ? outcome.event : null;

export const isPathStartable = (
  learningPath: LearningPathType,
  currencyCode: string,
  regionId: string,
) => {
  const priceOutput = learningPath.prices.filter(
    price =>
      price?.region?.id === regionId &&
      price?.financialUnit?.code === currencyCode,
  );
  const price = priceOutput && priceOutput[0].amount;
  return price && parseInt(price, 10) === 0;
};

export const getRegions = (
  prices: Maybe<Price>[] | null | undefined,
): Array<Region> => {
  const allRegions = prices
    ? prices.map(price => price?.region).filter(Boolean)
    : [];

  const uniqueMap = new Map();

  for (const region of allRegions) {
    if (region && !uniqueMap.has(region.code)) {
      uniqueMap.set(region?.code, region);
    }
  }

  return [...Array.from(uniqueMap.values())];
};

export const getRegionId = (regions: Array<Region>, regionCode: string) => {
  const desiredRegions = regions.filter(({ code }) => code === regionCode);
  return desiredRegions[0].id;
};

export const getLPInfo = (learningPath: LearningPathType | undefined) => {
  const LPPrice =
    learningPath && learningPath.prices.length > 0 && learningPath.prices[0];

  if (!(learningPath && LPPrice && LPPrice.region && LPPrice.financialUnit)) {
    return null;
  }

  const regions = getRegions(learningPath.prices);
  const regionCode = LPPrice.region.code;
  const learningObjectives = learningPath.learningObjectives?.edges || [];
  const getLPObjectives = getLearningObjectiveOptions(learningObjectives);

  return {
    learningPath,
    price: LPPrice,
    regionCode: regionCode,
    currencyCode: LPPrice.financialUnit.code,
    regionId: getRegionId(regions, regionCode),
    objectives: getLPObjectives,
  };
};

export const handleNestedPathObjectiveSelection = (
  formValues: LearningPathFormValues,
  nestedObjective: LearningPathObjective,
  objectiveOptions: NestedPathObjectiveOptionChoice,
) => {
  const objectiveIndex = formValues.objectives.findIndex(
    objective => objective.id === nestedObjective.id,
  );
  formValues.objectives[objectiveIndex].objective.nestedOptions =
    objectiveOptions;

  formValues.nestedPathObjectiveOptions = {
    ...formValues.nestedPathObjectiveOptions,
    ...objectiveOptions,
  };
};
