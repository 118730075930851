import { useGlobalStore } from "../providers/GlobalStore";

export const useTrainingTokensEnabled = () => {
  const { config } = useGlobalStore();

  return config?.training_tokens_enabled ?? false;
};

export const useIndependentBalanceTrainingTokensEnabled = () => {
  const { config } = useGlobalStore();

  return config?.token_balances_independent ?? false;
};
