import gql from "graphql-tag";

import { ERROR_FRAGMENT, VIEWER_FRAGMENT } from "./fragments";

export const VIEWER_QUERY = gql`
  query viewer {
    viewer {
      ...Viewer
    }
  }
  ${VIEWER_FRAGMENT}
`;

export const UPDATE_VIEWER_MUTATION = gql`
  mutation updatePersonalInformation(
    $customFieldValues: [CustomFieldValueInputType]
    $timeZoneName: String
    $locale: String
    $jobRole: String
    $personalName: PersonalNameInputType
  ) {
    viewer {
      update(
        input: {
          customFieldValues: $customFieldValues
          timeZoneName: $timeZoneName
          locale: $locale
          jobRole: $jobRole
          personalName: $personalName
        }
      ) {
        viewer {
          ...Viewer
        }
        errors {
          ...Error
        }
      }
    }
  }
  ${VIEWER_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

export const UPDATE_VIEWER_CREDENTIALS = gql`
  mutation updatecredentials(
    $currentPassword: String!
    $newPassword: String!
    $host: String!
  ) {
    viewerCredentials {
      setPassword(
        input: {
          currentPassword: $currentPassword
          newPassword: $newPassword
          host: $host
        }
      ) {
        viewerCredentials {
          id
        }
        errors {
          ...Error
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const MARK_LEARNER_WELCOMED = gql`
  mutation markLearnerWelcomed {
    viewerCredentials {
      markLearnerWelcomed {
        errors {
          label
          value
          message
        }
        viewerCredentials {
          id
        }
      }
    }
  }
`;
