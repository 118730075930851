import moment from "moment";
import React, { FunctionComponent } from "react";
import { Event as generatedEvent } from "../generated/weblinkTypes";
import { useDateFormatter } from "../hooks/useDateFormatter";
import { Event } from "../types/WeblinkEvent";

interface EventDateRangeProps {
  event: Event | generatedEvent;
}

export const EventDateRange: FunctionComponent<EventDateRangeProps> = ({
  event,
}) => {
  const {
    classroomStart: start,
    classroomEnd: end,
    timeZoneName: timezone,
  } = event;

  return <DateRange start={start} end={end} timezone={timezone} />;
};

interface DateRangeProps {
  start: string | undefined | null;
  end: string | undefined | null;
  timezone?: string | null;
  hideTime?: boolean;
}

export const DateRange: FunctionComponent<DateRangeProps> = ({
  start,
  end,
  timezone,
  hideTime = false,
}: DateRangeProps) => {
  const { dateFormat: formatWithoutTime, timeToTimeFormat } = useDateFormatter({
    showDayName: false,
    showTime: false,
  });
  const { dateFormat: formatWithTime, dateToDateFormat: formatDateRange } =
    useDateFormatter({
      showDayName: false,
      showTime: !hideTime,
    });

  const startDate = start ? moment(start) : null;
  const endDate = end ? moment(end) : null;

  if (!startDate) {
    return null;
  }

  if (!endDate) {
    return (
      <>
        {formatWithTime({
          date: startDate.toISOString(),
          customTimeZoneName: timezone || undefined,
        })}
      </>
    );
  }

  const runsOverMultipleDays = endDate.diff(startDate, "days") > 0;

  if (!runsOverMultipleDays) {
    return (
      <>
        {`${formatWithoutTime({
          date: startDate.toISOString(),
        })} ${timeToTimeFormat({
          start: startDate.toISOString(),
          end: endDate.toISOString(),
          customTimeZoneName: timezone || undefined,
        })}`}
      </>
    );
  }

  return (
    <>
      {formatDateRange({
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        customTimeZoneName: timezone || undefined,
      })}
    </>
  );
};
