import gql from "graphql-tag";

export const PORTAL_FOR_HOST_QUERY = gql`
  query portalForHost($host: URL!) {
    portalForHost(host: $host) {
      termsAndConditions
      isBookingPortal
      isApprovalPortal
      isSelfRegistrationPortal
      emptyPortalRedirectUrl
      externalLinks {
        displayText
        href
        iconName
        position
      }
      brand {
        id
        name
        singleSignOnOnly
        welcomeMessage {
          body
          buttonText
          imageUrl
        }
        branding {
          colours {
            contrastText
            mainText
            navigationBackground
            navigationButtonText
            primary
            primaryActionButton
            primaryActionButtonText
            secondary
            secondaryActionButton
            secondaryActionButtonText
          }
          buttons {
            borderRadius
          }
        }
      }
      profileSettings {
        allowEditName
        allowEditEmail
        allowEditJobTitle
        showJobTitle
      }
      linkedWebLinkPortals {
        id
        url
        isWeblinkBookingPortal: isBookingPortal
        isWeblinkSelfRegistrationPortal: isSelfRegistrationPortal
        paymentProviders {
          __typename
          paymentMethod {
            id
            name
          }
          ... on WebLinkApprovalPaymentProvider {
            approvalSignOffSteps {
              id
              name
            }
            approvalEntryStep {
              id
              name
            }
            requestApprovedStep {
              id
              name
            }
            requestRejectedStep {
              id
              name
            }
            requestOnWaitlistStep {
              id
              name
            }
          }
          ... on WebLinkStripePaymentProvider {
            stripePublicKey
            name
          }
        }
      }
    }
  }
`;

export const PORTAL_CUSTOM_VIEWER_FIELD_QUERY = gql`
  query portalCustomViewerFieldQuery {
    portalCustomFieldTemplates(type: Viewer) {
      definitionKey: key
      name
      type
      description
      options {
        label
        value
      }
    }
  }
`;

export const PORTAL_MESSAGES_QUERY = gql`
  query portalMessages {
    store {
      messages {
        cancellation
        transfer
      }
    }
  }
`;
