import React, { FunctionComponent } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Card, Row, Col, Progress } from "@administrate/piston-ux";

import { useDateFormatter } from "../../hooks/useDateFormatter";
import { Registerable } from "../../types/Registerables";
import PLACEHOLDER_IMAGE_URL from "../../images/course-placeholder.png";
import { useGlobalStore } from "../../providers/GlobalStore";
import { Trans, useTranslation } from "react-i18next";
import { decodeId } from "../../utils/displayHelpers";
import { ColorOptions, Maybe } from "@administrate/piston-ux/lib/types";
import { LearningMode } from "../../generated/lmsTypes";
import {
  completedSteps,
  getProgress,
  getRequiredSessions,
  MAX_SCORE,
  totalSteps,
} from "../../utils/event";
import {
  isLmsContentAccessible,
  isLmsContentInThePast,
} from "../../utils/eventHelpers";

type RegistrableDisplayType = {
  type: "learningPath" | "event";
  imageUrl: string;
  title: string;
  dataLabel: string;
  location?: string;
  start?: string;
  end?: string;
  lmsStart?: string;
  lmsEnd?: string;
  lastAccessed?: string;
  link?: string;
  totalPiecesOfContent?: number;
  completedPiecesOfContent?: number;
};

type RegisterableCardProps = {
  registerable: Registerable;
  section: string;
};

export const RegistrableCard: React.FunctionComponent<RegisterableCardProps> =
  ({ registerable: rawRegisterable, section }) => {
    const { t } = useTranslation();

    const mapRegistrable = (
      node?: Registerable,
    ): Maybe<RegistrableDisplayType> => {
      const type = node?.__typename || "";

      switch (type) {
        case "Registration":
          return {
            type: "event",
            imageUrl: node?.course?.imageUrl || PLACEHOLDER_IMAGE_URL,
            title: node?.course?.title || "",
            dataLabel: node?.title || "",
            location: node?.course?.location?.name,
            start: node?.course?.start,
            end: node?.course?.end,
            lmsStart: node?.course?.lmsStart,
            lmsEnd: node?.course?.lmsEnd,
            lastAccessed: node?.lastAccessed,
            link: `/my-courses/course/${node?.id}`,
          };
        case "LearningPathRegistration":
          return {
            type: "learningPath",
            imageUrl: node?.learningPath?.imageUrl || PLACEHOLDER_IMAGE_URL,
            title: node?.learningPath?.name || "",
            dataLabel: node?.learningPath?.name || "",
            lastAccessed: node?.lastAccessed,
            link: `/my-courses/learning-path/${node?.id}`,
          };
        default:
          return null;
      }
    };

    const node = rawRegisterable;
    const registrable = mapRegistrable(node);
    const { convertLMSImageUrl } = useGlobalStore();
    const { dateFormat } = useDateFormatter({ showDayName: false });
    if (registrable === null) {
      return null;
    }
    // TODO: add tags (based on what?)

    const label =
      registrable.type === "learningPath"
        ? {
            color: ColorOptions.Blue,
            text: t("learningPath"),
          }
        : { color: ColorOptions.LightGrey, text: t("course") };

    const classroom =
      rawRegisterable?.course?.learningMode === LearningMode.Blended ||
      rawRegisterable?.course?.learningMode === LearningMode.Classroom;
    const online =
      rawRegisterable?.course?.learningMode === LearningMode.Blended ||
      rawRegisterable?.course?.learningMode === LearningMode.SelfPaced;

    const totalPiecesOfContent = rawRegisterable?.totalPiecesOfContent || 0;
    const completedPiecesOfContent =
      rawRegisterable?.completedPiecesOfContent || 0;

    const { requiredSessions, attendedRequiredSessions } =
      getRequiredSessions(rawRegisterable);

    const totalContentAndSessions = totalSteps(
      totalPiecesOfContent + requiredSessions,
    );
    const totalCompletedAndAttended = completedSteps(
      completedPiecesOfContent + attendedRequiredSessions,
    );

    const hasPassed = rawRegisterable?.hasPassed || null;

    const currentProgress = getProgress(
      totalContentAndSessions,
      totalCompletedAndAttended,
    );

    const lmsContentAccessible = isLmsContentAccessible(
      false,
      registrable?.lmsStart,
      registrable?.lmsEnd,
    );
    const lmsContentInThePast = isLmsContentInThePast(registrable?.lmsEnd);
    const lmsCutoffDate = lmsContentInThePast
      ? registrable.lmsEnd
      : registrable.lmsStart;

    const registrableDisabled =
      (!rawRegisterable?.isActive &&
        rawRegisterable?.lifecycleState !== "completed") ||
      (!classroom && !lmsContentAccessible);

    return (
      <Link
        registrable={registrable}
        section={section}
        id={node.id}
        disabled={registrableDisabled}
      >
        <Card
          imageSource={convertLMSImageUrl(registrable.imageUrl)}
          label={label || undefined}
          title={registrable.title}
          stacked={registrable.type === "learningPath"}
          disabled={registrableDisabled}
          progress={
            online &&
            totalPiecesOfContent !== 0 &&
            (lmsContentAccessible || currentProgress) ? (
              <Progress
                value={hasPassed ? 1 : currentProgress}
                maxValue={hasPassed ? 1 : MAX_SCORE}
                title={
                  <Trans i18nKey="stepsComplete">
                    <strong>{{ totalCompletedAndAttended }}</strong>
                    <strong>{{ totalContentAndSessions }}</strong>
                  </Trans>
                }
              />
            ) : undefined
          }
          extraClass={`card--type-${registrable.type}`}
        >
          {(classroom || registrable.type === "learningPath") && (
            <Row>
              {registrable.location && (
                <Col sm={6}>
                  <span className="font-weight-bold">{t("location")}</span>
                  <p>{registrable.location}</p>
                </Col>
              )}
              {registrable.start && (
                <Col sm={6}>
                  <span className="font-weight-bold">{t("courseDate")}</span>
                  <div>
                    {!registrable.start && !registrable.end && t("unknown")}
                    {registrable.start &&
                      dateFormat({ date: registrable.start })}
                    {registrable.end && " -"}
                    {registrable.end && (
                      <div>{`${dateFormat({ date: registrable.end })}`}</div>
                    )}
                  </div>
                </Col>
              )}
            </Row>
          )}
          {!classroom && !lmsContentAccessible && (
            <Row>
              <Col sm={12}>
                <span className="font-weight-bold">
                  {lmsContentInThePast ? t("finishedOn") : t("opensOn")}:
                </span>
                <div>
                  {lmsCutoffDate && dateFormat({ date: lmsCutoffDate })}
                </div>
              </Col>
            </Row>
          )}
        </Card>
      </Link>
    );
  };

const Link: FunctionComponent<{
  registrable: RegistrableDisplayType;
  section: string;
  id: string;
  disabled?: boolean;
}> = ({ children, registrable, section, id, disabled }) => {
  const { t } = useTranslation();
  if (disabled) {
    return (
      <div
        aria-disabled
        aria-label={registrable.title}
        style={{ display: "inline" }}
      >
        {children}
      </div>
    );
  }
  return (
    <ReactRouterLink
      to={registrable.link || ""}
      className="no-underline"
      aria-label={`${t("goTo")} ${registrable.title} ${t(
        "_from",
      )} ${section} (ID: ${decodeId(id)})`}
      data-label={registrable.title}
    >
      {children}
    </ReactRouterLink>
  );
};
