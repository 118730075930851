import { DropdownButton } from "@administrate/piston-ux";
import React from "react";
import { useTranslation } from "react-i18next";

export type OptionsMenuItem = {
  label: string;
  onClick: () => void;
  displayed?: boolean;
  disabled?: boolean;
};

export type OptionsMenuProps = {
  optionsMenuItems: OptionsMenuItem[];
};

export const OptionsMenu = ({ optionsMenuItems }: OptionsMenuProps) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: "inline-block", marginLeft: 20 }}>
      <DropdownButton
        label={t("options")}
        iconOnly={true}
        items={optionsMenuItems}
        type={"suppressed"}
        direction={"right"}
      />
    </div>
  );
};
