import React, { Fragment, ReactText } from "react";
import uuidv4 from "uuid/v4";
import {
  Currency,
  EventLearner,
  FinancialUnit,
  Location,
} from "../generated/weblinkTypes";

export const getFinancialFormat = (
  amount: string | number | ReactText,
  currency?: Currency | FinancialUnit | null,
  locale?: string | null,
) => {
  const userLocale = locale || navigator.language;
  if (typeof amount === "string") {
    amount = parseFloat(amount);
  }

  const formatMonetaryValue = (amount: number) => {
    const formatConfig = {
      style: currency?.code ? "currency" : "decimal",
      currency: currency?.code || undefined,
      minimumFractionDigits: 2,
      currencyDisplay: "symbol",
    };
    return new Intl.NumberFormat(userLocale, formatConfig).format(amount);
  };

  return formatMonetaryValue(amount);
};

export const getNumberFormat = (value: number, locale?: string | null) => {
  const userLocale = locale || navigator.language;
  return new Intl.NumberFormat(userLocale, { minimumFractionDigits: 0 }).format(
    value,
  );
};

export const getLearnerName = (learner: EventLearner) => {
  if (learner.isUnnamed) {
    return "Unnamed Learner";
  }
  const name = learner?.contact?.personalName;
  return name ? `${name.firstName} ${name.lastName}` : "";
};

export const getLocationRegionFormat = (location: Location) => {
  if (location) {
    return (
      <Fragment>
        {location.name}
        {location.region && `, ${location.region.name}`}
      </Fragment>
    );
  }

  return undefined;
};

export const getUniqueId = () => {
  return uuidv4();
};

export const decodeId = (id: string) => {
  return atob(id);
};

export function shouldAlternateBgColor(index: number) {
  return isNotFirst(index) && isOdd(index);
}

function isNotFirst(index: number) {
  return index !== 0;
}

function isOdd(index: number) {
  return index % 2 !== 0;
}
