import React, { createContext, useContext, useEffect, useState } from "react";
import ApolloClient from "apollo-client";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  IntrospectionResultData,
} from "apollo-cache-inmemory";
import { ApolloLink, Observable } from "apollo-link";
import { Operation } from "apollo-boost";
import { HttpLink } from "apollo-link-http";
import { ErrorLink, onError } from "apollo-link-error";
import { ServerError } from "apollo-link-http-common";
import * as Sentry from "@sentry/browser";

import {
  LoadingScreen,
  useSetOverPageLoadingBarState as useSetPageHeaderLoadingBarState,
} from "@administrate/piston-ux";

import { useAuth0 } from "../react-auth0-wrapper";
import { throwIfNotOK } from "../fetchHelpers";
import { useGlobalStore } from "./GlobalStore";
import { getHost } from "./PortalProvider";
import { Maybe } from "@administrate/piston-ux/lib/types";
import { asDiagnostic, hasGraphqlErrors } from "../utils/errorHelpers";
import { RetryLink } from "apollo-link-retry";

const SCHEMA_QUERY = `
  {
    __schema {
      types {
        kind
        name
        possibleTypes {
          name
        }
      }
    }
  }
`;

const LINKED_WEBLINK_PORTALS_QUERY = `
  query linkedWebLinkPortals($host: URL!) {
    portalForHost(host: $host) {
      linkedWebLinkPortals {
        isWeblinkBookingPortal: isBookingPortal
        isWeblinkSelfRegistrationPortal: isSelfRegistrationPortal
        id
        url
      }
    }
  }
`;

export type GraphQLContextValue = {
  lmsClient: ApolloClient<any>;
  scormLmsClient: ApolloClient<any>;
  bookingWebLinkClient: Maybe<ApolloClient<any>>;
  selfRegistrationWebLinkClient: Maybe<ApolloClient<any>>;
};

const GraphQLContext =
  createContext<GraphQLContextValue | undefined>(undefined);

export const useGraphQLContext = () => {
  const context = useContext(GraphQLContext);
  if (context === undefined) {
    throw new Error("GraphQL context is not set up");
  }

  return context;
};

const getOperationName = (operation: Operation) => {
  const operationTypeDetails = operation.query.definitions.find(
    (definition: any) => definition.kind === "OperationDefinition",
  );
  return `${operationTypeDetails.operation || "unknown operation"} ${
    operation.operationName
  }`;
};

function isServerError(
  error: Error | ServerError | undefined,
): error is ServerError {
  return (
    typeof error !== "undefined" &&
    typeof (error as any).statusCode === "number"
  );
}

export const GraphQLProvider: React.FunctionComponent = ({ children }) => {
  const [lmsClient, setLmsClient] = useState<null | ApolloClient<any>>(null);
  const [scormLmsClient, setScormLmsClient] =
    useState<null | ApolloClient<any>>(null);

  const [bookingWebLinkClient, setBookingWebLinkClient] =
    useState<null | ApolloClient<any>>(null);
  const [selfRegistrationWebLinkClient, setSelfRegistrationWebLinkClient] =
    useState<null | ApolloClient<any>>(null);
  const [hasLinkedBookingWebLinkPortal, setHasLinkedBookingWebLinkPortal] =
    useState(true);
  const [
    hasLinkedSelfRegistrationWebLinkPortal,
    setHasLinkedSelfRegistrationWebLinkPortal,
  ] = useState(true);

  const { getTokenSilently, logout } = useAuth0();
  const globalStore = useGlobalStore();
  const setLoadingBarState = useSetPageHeaderLoadingBarState();

  const errorLink = onError((({ networkError, operation, forward }) => {
    if (isServerError(networkError) && networkError.statusCode === 401) {
      Sentry.addBreadcrumb({
        type: "error",
        category: "fetch",
        message: "401 encountered, attempting to reauthenticate...",
        data: {
          errorMessage: networkError.message,
          operation: getOperationName(operation),
          variables: operation.variables,
          headers: operation.getContext().headers,
          status: networkError.statusCode,
        },
        level: Sentry.Severity.Warning,
        timestamp: Date.now() / 1000,
      });

      return new Observable(observer => {
        getTokenSilently()
          .then((token: string) => {
            globalStore.token = token;
          })
          .then(() => {
            Sentry.addBreadcrumb({
              type: "info",
              category: "auth",
              message: "Successfully reauthenticated, retrying failed request",
              level: Sentry.Severity.Info,
              timestamp: Date.now() / 1000,
            });

            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };

            // Retry last failed request
            forward(operation).subscribe(subscriber);
          })
          .catch((error: any) => {
            Sentry.addBreadcrumb({
              type: "info",
              category: "auth",
              message: "Failed to silently reauth, logging the user out",
              data: { error },
              level: Sentry.Severity.Info,
              timestamp: Date.now() / 1000,
            });

            observer.error(error);
            logout({
              redirect_uri: window.location.href,
              brand: globalStore.config.brand,
            });
          });
      });
    }

    if (networkError) {
      Sentry.addBreadcrumb({
        type: "error",
        category: "fetch",
        message: networkError.toString(),
        data: {
          operation: getOperationName(operation),
          variables: operation.variables,
          headers: operation.getContext().headers,
        },
        level: Sentry.Severity.Error,
        timestamp: Date.now() / 1000,
      });
    }
  }) as ErrorLink.ErrorHandler);

  const loggingLink = new ApolloLink((operation, forward) => {
    const startTimestamp = Date.now();
    const operationName = getOperationName(operation);

    if (process.env.NODE_ENV === "development") {
      console.log(`[apollo] > ${operationName}`, operation.variables);
    }

    return forward(operation).map(response => {
      const responseHasErrors = hasGraphqlErrors(response);
      const endTimestamp = Date.now();
      const timeTaken = Math.round(endTimestamp - startTimestamp);
      Sentry.addBreadcrumb({
        type: "query",
        category: "graphql",
        message: `responded to ${operationName} in ${timeTaken} ms${
          responseHasErrors ? " with GraphQL errors" : ""
        }`,
        level: responseHasErrors
          ? Sentry.Severity.Warning
          : Sentry.Severity.Info,
        timestamp: endTimestamp / 1000,
      });
      return response;
    });
  });

  const lmsApi = new HttpLink({
    uri: globalStore.config.graphql_uri,
    credentials: "same-origin",
  });

  const getAdditionalWebLinkHeaders = (
    linkedWeblinkPortals: {
      id: string;
      url: string;
      isWeblinkSelfRegistrationPortal: boolean;
      isWeblinkBookingPortal: boolean;
    }[],
  ) => ({
    bookingPortal:
      linkedWeblinkPortals.find(
        linkedPortal =>
          !linkedPortal.isWeblinkSelfRegistrationPortal &&
          linkedPortal.isWeblinkBookingPortal,
      )?.url || "",
    selfRegistrationPortal:
      linkedWeblinkPortals.find(
        linkedPortal =>
          linkedPortal.isWeblinkSelfRegistrationPortal &&
          !linkedPortal.isWeblinkBookingPortal,
      )?.url || "",
  });

  const getBookingWeblinkApiHttpLink = (weblinkPortalHeader: {
    "weblink-portal": string;
  }) =>
    new HttpLink({
      uri: globalStore.config.weblink_graphql_uri,
      credentials: "same-origin",
      headers: weblinkPortalHeader,
    });

  const getSelfRegistrationWeblinkApiHttpLink = (weblinkPortalHeader: {
    "weblink-portal": string;
  }) =>
    new HttpLink({
      uri: globalStore.config.weblink_graphql_uri,
      credentials: "same-origin",
      headers: weblinkPortalHeader,
    });

  const loadSchema = (
    token: string,
    graphQlUri: string,
    additionalHeaders?: {
      [Key: string]: string;
    },
  ) =>
    fetch(graphQlUri, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        ...additionalHeaders,
      },
      body: JSON.stringify({
        query: SCHEMA_QUERY,
      }),
    })
      .then(throwIfNotOK)
      .then(result => result.json())
      .then(result => {
        result.data.__schema.types = result.data.__schema.types.filter(
          (type: any) => type.possibleTypes !== null,
        );
        return result.data;
      });

  const loadLinkedWeblinkPortals = (token: string) =>
    fetch(globalStore.config.graphql_uri, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: LINKED_WEBLINK_PORTALS_QUERY,
        variables: {
          host: getHost(),
        },
      }),
    })
      .then(throwIfNotOK)
      .then(result => result.json())
      .then(result => {
        return result.data.portalForHost.linkedWebLinkPortals;
      });

  const createApolloLink = (httpLink: HttpLink) =>
    ApolloLink.from([
      new RetryLink({
        delay: {
          initial: 500,
          max: Infinity,
          jitter: true,
        },
        attempts: {
          max: 4,
          retryIf: (error, _operation) =>
            error &&
            error instanceof TypeError &&
            error.message === "Failed to fetch",
        },
      }),
      errorLink,
      new ApolloLink((operation, forward) => {
        setLoadingBarState(true);
        operation.setContext({
          headers: {
            Authorization: `Bearer ${globalStore?.token}`,
            "lms-version": 3,
            "weblink-client": "lms",
          },
        });
        return forward(operation).map(response => {
          setLoadingBarState(false);
          return response;
        });
      }),
      loggingLink,
      httpLink,
    ]);

  const createApolloClient = (
    schema: IntrospectionResultData,
    httpLink: HttpLink,
  ) =>
    new ApolloClient({
      cache: new InMemoryCache({
        fragmentMatcher: new IntrospectionFragmentMatcher({
          introspectionQueryResultData: schema,
        }),
      }),
      link: createApolloLink(httpLink),
      // This is required to be able to cancel queries
      // See: https://github.com/apollographql/apollo-client/issues/4150#issuecomment-500127694
      //
      // You _should_ be able to override this just on queries you want to cancel, but this option is
      // currently broken: https://github.com/apollographql/apollo-link/issues/517
      //
      // With query deduplication turned on, Apollo Client will have an additional subscription to each
      // query, which it uses to remove queries from it's "in-flight" list when they complete. This means
      // that when we unsubscribe from the query in our useCancellableClient hook, there is still an active
      // subscription, and so the query is not cancelled.
      //
      // Disabling this option should not have much effect elsewhere on our app, as it only prevents two
      // _identical_ queries running at once, which we don't do.
      queryDeduplication: false,
    });

  const getClients = () => {
    getTokenSilently().then((token: string) => {
      globalStore.token = token;
      loadSchema(token, globalStore.config.graphql_uri)
        .then(schema => {
          loadLinkedWeblinkPortals(token).then(linkedWeblinkPortals => {
            setLmsClient(createApolloClient(schema, lmsApi));
            setScormLmsClient(createApolloClient(schema, lmsApi));

            if (!linkedWeblinkPortals.length) {
              setHasLinkedBookingWebLinkPortal(false);
              setHasLinkedSelfRegistrationWebLinkPortal(false);
              return;
            }

            const { bookingPortal, selfRegistrationPortal } =
              getAdditionalWebLinkHeaders(linkedWeblinkPortals);

            if (!bookingPortal) {
              setHasLinkedBookingWebLinkPortal(false);
            }

            if (!selfRegistrationPortal) {
              setHasLinkedSelfRegistrationWebLinkPortal(false);
            }

            const bookingPortalHeader = {
              "weblink-portal": `${bookingPortal}`,
            };

            const selfRegistrationPortalHeader = {
              "weblink-portal": `${selfRegistrationPortal}`,
            };

            const bookingWeblinkApi =
              getBookingWeblinkApiHttpLink(bookingPortalHeader);
            const selfRegistrationWeblinkApi =
              getSelfRegistrationWeblinkApiHttpLink(
                selfRegistrationPortalHeader,
              );

            if (bookingPortal) {
              loadSchema(
                token,
                globalStore.config.weblink_graphql_uri,
                bookingPortalHeader,
              )
                .then(webLinkSchema => {
                  setBookingWebLinkClient(
                    createApolloClient(webLinkSchema, bookingWeblinkApi),
                  );
                })
                .catch(
                  asDiagnostic(
                    "WebLink Booking Portal API",
                    globalStore.config.weblink_graphql_uri,
                    "Fetch to Fetch",
                    false,
                  ),
                );
            }

            if (selfRegistrationPortal) {
              loadSchema(
                token,
                globalStore.config.weblink_graphql_uri,
                selfRegistrationPortalHeader,
              )
                .then(webLinkSchema => {
                  setSelfRegistrationWebLinkClient(
                    createApolloClient(
                      webLinkSchema,
                      selfRegistrationWeblinkApi,
                    ),
                  );
                })
                .catch(
                  asDiagnostic(
                    "WebLink SelfRegistration API",
                    globalStore.config.weblink_graphql_uri,
                    "Failed to Fetch",
                    false,
                  ),
                );
            }

            if (process.env.NODE_ENV === "development") {
              console.info("Auth Token is: '%s'", token);
              console.info(
                "LMS API GraphQL Editor: '%s?access_token=%s'",
                globalStore.config.graphql_uri,
                token,
              );
            }
          });
        })
        .catch(
          asDiagnostic(
            "LMS API",
            globalStore.config.graphql_uri,
            "Failed to fetch",
          ),
        );
    });
  };

  useEffect(getClients, []); // eslint-disable-line

  if (
    !lmsClient ||
    !scormLmsClient ||
    (hasLinkedBookingWebLinkPortal && !bookingWebLinkClient) ||
    (hasLinkedSelfRegistrationWebLinkPortal && !selfRegistrationWebLinkClient)
  ) {
    return <LoadingScreen />;
  }

  return (
    <GraphQLContext.Provider
      value={{
        lmsClient,
        scormLmsClient,
        bookingWebLinkClient,
        selfRegistrationWebLinkClient,
      }}
    >
      {children}
    </GraphQLContext.Provider>
  );
};
