import React, { FunctionComponent, ReactNode, useCallback } from "react";
import moment from "moment";
import {
  FutureArticleHeader,
  FutureAsyncFilter,
  FutureSelectFilter,
  DateTimeInput,
  useTypedFormValues,
} from "@administrate/piston-ux";
import { FutureInputFilter } from "@administrate/piston-ux/lib/future/ArticleHeader/FutureInputFilter";
import {
  LocationEdge,
  Location,
  EventBookingType,
} from "../../generated/weblinkTypes";
import { useDateFormatter } from "../../hooks/useDateFormatter";
import { formatFilterDateRange } from "../../utils/dateTimeHelpers";
import { useTranslation } from "react-i18next";
import { useLearnerManagementBookingFilters } from "./BookingFilterContext";

export type BookingsArticleHeaderProps = {
  locations: (inputValue: string) => Promise<LocationEdge[]>;
  extra?: ReactNode;
  loading: boolean;
};

export const BookingsArticleHeader: FunctionComponent<BookingsArticleHeaderProps> =
  ({ locations, extra, loading }) => {
    const { t } = useTranslation();
    const { filters, setFilterValue } = useLearnerManagementBookingFilters();

    const defaultFromDate = moment().format("YYYY-MM-DD");
    const { simpleDateFormat } = useDateFormatter({ showDayName: true });

    const values = useTypedFormValues(filters);

    const onSearch = useCallback(
      value => {
        setFilterValue("search", value);
      },
      [setFilterValue],
    );

    return (
      <FutureArticleHeader
        id="bookings-header"
        title={t("bookings")}
        extra={extra}
        loading={loading}
        filters={{ values }}
        search={{
          initialValue: filters.search,
          label: t("bookings"),
          onChange: onSearch,
        }}
      >
        <FutureAsyncFilter
          name="location"
          label={t("location")}
          loadOptions={locations}
          formatter={(location: Location) => location.name}
          onChange={location => setFilterValue("location", location)}
          id="bookings__location-filter"
        />
        <FutureSelectFilter
          name="courseType"
          label={t("courseType")}
          options={[
            {
              label: t("public"),
              value: EventBookingType.Public,
            },
            { label: t("private"), value: EventBookingType.Private },
          ]}
          onChange={courseType =>
            setFilterValue("courseType", courseType.selected?.value ?? null)
          }
          id="bookings__course-type-filter"
        />
        <FutureInputFilter
          label={t("dates")}
          selected={
            filters.fromDate || filters.toDate
              ? formatFilterDateRange(
                  {
                    from: filters.fromDate,
                    to: filters.toDate,
                  },
                  simpleDateFormat,
                )
              : ""
          }
          onSubmit={() => {
            setFilterValue("fromDate", values.fromDate);
            setFilterValue("toDate", values.toDate);
          }}
          onClear={() => {
            setFilterValue("fromDate", defaultFromDate);
            setFilterValue("toDate", "");
          }}
          id="bookings__dates-filter"
        >
          <DateTimeInput
            label={t("startDate")}
            type="date"
            name="fromDate"
            placeholder={t("today")}
          />
          <DateTimeInput
            label={t("endDate")}
            type="date"
            placeholder={t("future")}
            name="toDate"
          />
        </FutureInputFilter>
      </FutureArticleHeader>
    );
  };
