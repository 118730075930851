import {
  BodyText,
  Form,
  ListCell,
  ListRow,
  OptionType,
  Select,
  useTypedFormValues,
} from "@administrate/piston-ux";
import { includes } from "lodash";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Maybe,
  TrainingPass,
  TrainingPassType,
} from "../../generated/lmsTypes";
import {
  Bookable,
  Cart,
  LearningPath,
  Price,
} from "../../generated/weblinkTypes";
import { LearningPathLocation } from "../../pages/Catalog/LearningPaths";
import { useViewer } from "../../providers/ViewerProvider";
import { getFinancialFormat } from "../../utils/displayHelpers";
import { Duration } from "../../utils/eventHelpers";
import { AvailableSeats } from "../AvailableSeats";
import { DateRange } from "../EventDateRange";
import { LearningMode } from "../LearningMode";
import {
  computePassStatus,
  TrainingPassContentStatusLabel,
} from "../TrainingPass";
import { WeekNumber } from "../WeekNumber";
import { bookableIsEvent } from "./bookables";
import { BookNowButton } from "./BookNowButton";
import { MutationFunctionOptions, ExecutionResult } from "@apollo/react-common";

// TODO: Rename this
const ClickableText: FunctionComponent<{
  onClick: () => void;
}> = ({ onClick, children }) => {
  return (
    <span onClick={onClick} className="clickable-text">
      {children}
    </span>
  );
};

export const BookableRow: FunctionComponent<{
  bookable: Bookable;
  onClickDate: (bookable: Bookable) => void;
  createCartMutationLoading: boolean;
  hidePrices: boolean;
  hidePricesBeforePricingAgreementApplied: boolean;
  trainingPassTypes: TrainingPassType[];
  gridColumns: string;
  learnerPasses: TrainingPass[];
  cartlessCheckout?: boolean;
  viewersLastCart: Cart | null;
  viewersCartLoading: boolean;
  addCartLineItem: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<ExecutionResult<any>>;
  createCart: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<ExecutionResult<any>>;
  setApiError: React.Dispatch<React.SetStateAction<boolean>>;
  setPassRegisterError: React.Dispatch<
    React.SetStateAction<Maybe<React.ReactNode>>
  >;
  setMutationRunning: (loading: boolean) => void;
}> = ({
  bookable,
  onClickDate,
  createCartMutationLoading,
  hidePrices,
  hidePricesBeforePricingAgreementApplied,
  trainingPassTypes,
  gridColumns,
  learnerPasses,
  cartlessCheckout,
  viewersLastCart,
  viewersCartLoading,
  addCartLineItem,
  createCart,
  setApiError,
  setPassRegisterError,
  setMutationRunning,
}) => {
  const { t } = useTranslation();
  const [financialUnit, setFinancialUnit] =
    useState<string | undefined>(undefined);

  const contentPassTypeIds = getTrainingPassTypesFromBookable(bookable);
  const { validPassHeld } = computePassStatus(
    learnerPasses,
    contentPassTypeIds,
  );
  const contentPasses = trainingPassTypes.filter(tpt =>
    includes(contentPassTypeIds, tpt.id),
  );

  return (
    <>
      <ListRow
        gridColumns={gridColumns}
        extra={
          <BookNowButton
            bookable={bookable}
            financialUnit={financialUnit}
            hidePrices={hidePrices}
            disabled={createCartMutationLoading}
            setMutationRunning={setMutationRunning}
            contentPasses={contentPasses}
            cartlessCheckout={cartlessCheckout}
            viewersLastCart={viewersLastCart}
            viewersCartLoading={viewersCartLoading}
            addCartLineItem={addCartLineItem}
            createCart={createCart}
            setApiError={setApiError}
            setPassRegisterError={setPassRegisterError}
          />
        }
        extraWidth={160}
        dataLabel={bookable.name}
      >
        {/* // TODO: Unify this with `headings` from BookableList */}
        <ListCell label={t("startDate")}>
          <ClickableText onClick={() => onClickDate(bookable)}>
            <DateRange
              start={bookable.start}
              end={null}
              timezone={
                bookableIsEvent(bookable) ? bookable.timeZoneName : null
              }
              hideTime
            />
          </ClickableText>
        </ListCell>
        <ListCell label={t("startWeek")}>
          <WeekNumber date={bookable.start} />
        </ListCell>
        <ListCell label={t("name")}>{bookable.name}</ListCell>
        <ListCell label={t("learningMode")}>
          {bookableIsEvent(bookable) ? <LearningMode event={bookable} /> : "-"}
        </ListCell>
        <ListCell label={t("language")}>
          {bookable.locale?.language ?? "English"}
        </ListCell>
        <ListCell label={t("location")}>
          {
            // TODO: We currently have a LearningPathLocation component which uses the event objectives to get the
            // location. We should either do that in Weblink or Core API, not in frontend.
          }
          {bookableIsEvent(bookable) ? (
            bookable.location?.name
          ) : (
            <LearningPathLocation path={bookable} hideRegion />
          )}
        </ListCell>
        <ListCell label={t("duration")}>
          <Duration start={bookable.start} end={bookable.end} />
        </ListCell>
        <ListCell label={t("availability")}>
          <AvailableSeats bookable={bookable} />
        </ListCell>
        {!hidePrices && (
          <BookablePrice
            prices={bookable.prices}
            onFinancialUnitSelection={code => setFinancialUnit(code)}
            hidePricesBeforePricingAgreementApplied={
              hidePricesBeforePricingAgreementApplied
            }
            disabled={validPassHeld}
          />
        )}
        <ListCell label={t("pass")}>
          <TrainingPassContentStatusLabel
            courseCardId={bookable.id}
            learnerPasses={learnerPasses}
            contentPasses={contentPasses}
            hasPricing={bookableHasPricing(bookable)}
          />
        </ListCell>
      </ListRow>
    </>
  );
};

// TODO unify with EventPrice in EventList.tsx
const BookablePrice: FunctionComponent<{
  prices?: Price[];
  onFinancialUnitSelection: (financialUnit?: string) => void;
  hidePricesBeforePricingAgreementApplied: boolean;
  disabled: boolean;
}> = ({
  prices,
  onFinancialUnitSelection,
  hidePricesBeforePricingAgreementApplied,
  disabled,
}) => {
  const { viewer } = useViewer();
  const [activePrice, setActivePrice] = useState<Price>();
  const { t } = useTranslation();

  let currencyOptions: OptionType[] = [];

  let values = useTypedFormValues({
    currency: prices && prices.length === 1 && prices[0].financialUnit?.code,
  });

  if (prices) {
    if (prices?.length > 1) {
      currencyOptions = prices.map(price => ({
        label: price.financialUnit?.code ?? "",
        value: price.financialUnit?.code ?? "",
      }));
    } else if (prices?.length === 1) {
      if (!activePrice) {
        setActivePrice(prices[0]);
        onFinancialUnitSelection(prices[0].financialUnit?.code);
      }
    }
  }

  const handleFinancialUnitChange = (financialUnit: string) => {
    if (financialUnit) {
      const activePrice = prices?.filter(
        price => price.financialUnit?.code === financialUnit,
      );
      if (activePrice) {
        setActivePrice(activePrice && activePrice[0]);
        onFinancialUnitSelection(financialUnit);
      }
    } else {
      setActivePrice(undefined);
      onFinancialUnitSelection(undefined);
    }
  };

  const getPrice = (activePrice?: Price) => {
    if (!activePrice) {
      return "-";
    }

    return getFinancialFormat(
      activePrice.amount,
      activePrice?.financialUnit,
      viewer?.locale,
    );
  };
  return (
    <>
      <ListCell label={t("currency")}>
        <div className="list__cell--text">
          <Form values={values} disabled={disabled}>
            {prices && (
              <>
                {prices?.length > 1 && (
                  <div className="d-block" style={{ width: 120 }}>
                    <Select
                      labelHidden
                      label={t("currency")}
                      name="currency"
                      options={currencyOptions}
                      onChange={(financialUnit: string) =>
                        handleFinancialUnitChange(financialUnit)
                      }
                      disabled={disabled}
                    />
                  </div>
                )}
                {prices?.length === 1 && <span>{values.currency}</span>}
              </>
            )}
          </Form>
        </div>
      </ListCell>
      {!hidePricesBeforePricingAgreementApplied && (
        <ListCell label={t("price")}>
          <BodyText
            className="font-weight-bold"
            message={getPrice(activePrice)}
            disabled={disabled}
            lineThrough={disabled}
          />
        </ListCell>
      )}
    </>
  );
};

const getTrainingPassTypesFromBookable = (bookable: Bookable) => {
  if (bookable.__typename === undefined) {
    return [];
  }

  if (bookable.__typename === "Event") {
    return bookable.course?.includedInPassTypeIds ?? [];
  }

  // Typescript needs some extra help, Bookable must be complex enough
  // that the discriminants above are not enough to narrow the type.
  // It may be that TS 4.x and above would narrow properly.
  return (bookable as LearningPath).includedInPassTypeIds;
};

const bookableHasPricing = (bookable: Bookable) => {
  let price = bookable.price;
  if (bookable.prices.length > 0) {
    price = bookable.prices[0];
  }

  return (price?.amount ?? null) !== null;
};
