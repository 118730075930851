import React from "react";
import { useTranslation } from "react-i18next";
import { TranslationProvider } from "@administrate/piston-ux";

export const PistonTranslationProvider: React.FunctionComponent = ({
  children,
}) => {
  const { t } = useTranslation();
  return <TranslationProvider t={t}>{children}</TranslationProvider>;
};
