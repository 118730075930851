export class ScormApi12 {
  constructor(dataModel, defaults) {
    this._dataModel = dataModel;
    this._defaults = defaults;

    this._errorCode = 0;
    this._initialized = false;
    this._terminated = false;
  }

  get variant() {
    // For debug: window.API.variant
    return "SCORM12";
  }

  LMSInitialize(emptyString) {
    // Invalid Argument Error (201)
    if (emptyString !== "") {
      this._errorCode = 201;
      return "false";
    }

    // General Exception (101)
    if (this._initialized) {
      this._errorCode = 101;
      return "false";
    }

    // General Exception (101)
    if (this._terminated) {
      this._errorCode = 101;
      return "false";
    }

    this._dataModel.load();

    this._dataModel.defaults({
      cmi: {
        core: {
          lesson_status: "not attempted",
        },
      },
    });

    this._dataModel.defaults(this._defaults);

    this._initialized = true;
    this._errorCode = 0;
    return "true";
  }

  LMSFinish(emptyString) {
    // Invalid Argument Error (201)
    if (emptyString !== "") {
      this._errorCode = 201;
      return "false";
    }

    // Not Initialized (301)
    if (!this._initialized) {
      this._errorCode = 301;
      return "false";
    }

    // General Exception (101)
    if (this._terminated) {
      this._errorCode = 101;
      return "false";
    }

    this._dataModel.commit(true);
    return "true";
  }

  LMSGetValue(element) {
    // Not Initialized (301)
    if (!this._initialized) {
      this._errorCode = 301;
      return "false";
    }

    // General Exception (101)
    if (this._terminated) {
      this._errorCode = 101;
      return "false";
    }

    return this._dataModel.get(element);
  }

  LMSSetValue(element, value) {
    // Not Initialized (301)
    if (!this._initialized) {
      this._errorCode = 301;
      return "false";
    }

    // General Exception (101)
    if (this._terminated) {
      this._errorCode = 101;
      return "false";
    }

    this._dataModel.set(element, value);
    return "true";
  }

  LMSCommit(emptyString) {
    // Invalid Argument Error (201)
    if (emptyString !== "") {
      this._errorCode = 201;
      return "false";
    }

    this._dataModel.commit();
    this._errorCode = 0;
    return "true";
  }

  LMSGetLastError() {
    return this._errorCode.toString();
  }

  LMSGetErrorString() {
    return "";
  }

  LMSGetDiagnostic() {
    return "";
  }
}
