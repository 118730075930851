import React from "react";
import { Select, StaticInput, Input, Row, Col } from "@administrate/piston-ux";

import { CustomViewerFields } from "../types/Portal";
import { useTranslation } from "react-i18next";
import { usePortal } from "../providers/PortalProvider";

type ContactDetailFormProps = {
  customViewerFields: Array<CustomViewerFields>;
  isCustomViewerFieldsLoading: boolean;
};

export enum StaticContactInputs {
  firstName,
  lastName,
  emailAddress,
  jobRole,
}

export const ContactDetailForm: React.FunctionComponent<ContactDetailFormProps> =
  ({ customViewerFields, isCustomViewerFieldsLoading }) => {
    const { t } = useTranslation();
    const { profileSettings } = usePortal();
    return (
      <>
        <Row>
          <Col sm={6}>
            {profileSettings?.allowEditName ? (
              <Input label={t("firstName")} name="firstName" />
            ) : (
              <StaticInput label={t("firstName")} name="firstName" />
            )}
          </Col>
          <Col sm={6}>
            {profileSettings?.allowEditName ? (
              <Input label={t("lastName")} name="lastName" />
            ) : (
              <StaticInput label={t("lastName")} name="lastName" />
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <StaticInput label={t("emailAddress")} name="emailAddress" />
          </Col>
          <Col sm={6}>
            {profileSettings?.allowEditJobTitle ? (
              <Input label={t("jobRole")} name="jobRole" />
            ) : (
              <StaticInput label={t("jobRole")} name="jobRole" />
            )}
          </Col>
        </Row>
        <Row>
          {isCustomViewerFieldsLoading ? (
            <Col sm={12}>
              <span
                className="placeholder animated"
                style={{ fontSize: "18px" }}
              />
            </Col>
          ) : (
            customViewerFields.map(({ definitionKey, name, options, type }) => {
              switch (type) {
                case "string":
                  return (
                    <Col sm={6} key={definitionKey}>
                      <Input
                        label={name}
                        type="text"
                        name={definitionKey}
                        valid={v => (!!v ? true : "")}
                      />
                    </Col>
                  );
                case "choice":
                  return (
                    <Col sm={6} key={definitionKey}>
                      <Select
                        label={name}
                        options={options}
                        name={definitionKey}
                        valid={v => (!!v ? true : `${t("pleaseSelectValue")}`)}
                      />
                    </Col>
                  );
                // TODO: add further input types when supported
                default:
                  return null;
              }
            })
          )}
        </Row>
      </>
    );
  };
