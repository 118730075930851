import {
  Button,
  Card,
  Col,
  DetailPageContent,
  FormStateStatus,
  FutureArticleHeader,
  FutureDetailPage,
  Link,
  List,
  ListCell,
  ListHeader,
  ListRow,
  LoadingBar,
  LoadingScreen,
  OpacityOverlay,
  Row,
  SaveStates,
  useDetailFormState,
  UtilityStyle,
  ValidationErrors,
} from "@administrate/piston-ux";
import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation } from "react-router-dom";

import {
  CartLineItem,
  Maybe,
  LearningPath,
  Event as EventType,
  Cart,
} from "../../generated/weblinkTypes";
import { useWeblinkSettings } from "../../hooks/useWeblinkSettings";
import { useWebLinkMutation } from "../../hooks/weblink";
import { REMOVE_CART_LINE_ITEM_MUTATION } from "../../queries/cart";
import { BookerIntention } from "../Order/BookingPage";
import coursePlaceholder from "../../images/course-placeholder.png";
import { useGlobalStore } from "../../providers/GlobalStore";
import {
  getFinancialFormat,
  getLocationRegionFormat,
} from "../../utils/displayHelpers";
import { useDateFormatter } from "../../hooks/useDateFormatter";
import { formatDateRange } from "../../utils/dateTimeHelpers";
import { useBookerIntention } from "../../hooks/useBookerIntention";
import { CartSummary, CartSummaryUsage } from "../../components/CartSummary";
import { getProductOption } from "../../utils/checkoutHelpers";
import { MessageOptions } from "@administrate/piston-ux/lib/utils/FormStateStatus";
import { PromoCode } from "../../components/PromoCode";
import { RefetchStatus } from "../../providers/CheckoutProvider";
import { NetworkStatus } from "apollo-boost";
import { useViewer } from "../../providers/ViewerProvider";
import { LoadingCard } from "../../components/LoadingCard";
import { useViewersLastCart } from "../../hooks/useViewersLastCart";
import { useAnalytics } from "../../providers/AnalyticsProvider";
import { CartEvent } from "../../analytics/events";

const CART_GRIDCOLUMNS = "5fr";
const HAS_PRICE_GRIDCOLUMNS = "1fr";

export const CartPage: FunctionComponent = () => {
  const [cartSummaryMutationRunning, setCartSummaryMutationRunning] =
    useState(false);
  const [cartItemsMutationRunning, setCartItemsMutationRunning] =
    useState(false);
  const [cartRefetching, setCartRefecting] = useState<RefetchStatus>();
  const {
    hidePricesBeforePricingAgreementApplied: hidePrices,
    loading: weblinkLoading,
  } = useWeblinkSettings();
  const { t } = useTranslation();
  const location = useLocation();
  const { captureEvent } = useAnalytics();
  const quickRegister = new URLSearchParams(location.search).get(
    "quickRegister",
  );

  const [bookerIntention, setBookerIntention] = useBookerIntention(
    BookerIntention.Self,
  );

  if (bookerIntention !== BookerIntention.Self) {
    setBookerIntention(BookerIntention.Self);
  }

  const {
    messages,
    saveState,
    setSaveState,
    setMessages,
    reset: resetFormState,
  } = useDetailFormState();

  const {
    cart,
    loading,
    refetch: refetchCartResponse,
    networkStatus,
  } = useViewersLastCart({
    notifyOnNetworkStatusChange: true,
  });

  const refetchCart = (refetch: RefetchStatus) => {
    if (refetch === RefetchStatus.Cancel) {
      setCartRefecting(undefined);
    }
    if (refetch === RefetchStatus.StartRefetch) {
      refetchCartResponse();
    }
  };

  const [
    removeCartLineItem,
    { loading: mutationLoading, error: mutationError },
  ] = useWebLinkMutation(REMOVE_CART_LINE_ITEM_MUTATION);

  const handleRemoveCartLineItem = async (itemId: string) => {
    if (!itemId) {
      setSaveState("failed");
      return;
    }
    const removedItem = (cart as Cart).items?.find(
      cartItem => cartItem?.id === itemId,
    );
    if (itemId) {
      setCartItemsMutationRunning(true);
      setSaveState("saving");
      const response = await removeCartLineItem({
        variables: {
          input: {
            cartId: cart?.id,
            itemId,
          },
        },
      });
      const validationErrors = response.data.cart.errors;

      setCartItemsMutationRunning(false);
      if (!mutationLoading) {
        if (mutationError) {
          refetchCart(RefetchStatus.Cancel);
          setMessages({ failed: mutationError.message });
        } else if (validationErrors && validationErrors.length > 0) {
          refetchCart(RefetchStatus.Cancel);
          setMessages({
            errors: (
              <ValidationErrors
                title={t("errorRemovingItemFromCart")}
                mutationValidationErrors={validationErrors}
              />
            ),
          });
        } else {
          if (removedItem) {
            captureEvent(
              CartEvent.fromCartRemove(
                response.data.cart.removeCartLineItem.cart,
                removedItem,
              ),
            );
          }
          resetFormState();
          refetchCart(RefetchStatus.StartRefetch);
        }
      }
    }
  };

  useEffect(() => {
    if (
      cartRefetching === RefetchStatus.StartRefetch &&
      networkStatus === NetworkStatus.refetch
    ) {
      setCartRefecting(RefetchStatus.Done);
    }
  }, [cartRefetching, networkStatus]);

  useEffect(() => {
    if (loading || !cart) return;
    captureEvent(CartEvent.fromCartView(cart));
  }, [loading, cart, captureEvent]);

  if (loading || weblinkLoading) return <LoadingScreen />;

  const cartHasItems = (cart?.items || []).length > 0;
  const zeroPricedCart =
    cartHasItems && parseInt(cart?.price?.grandTotal, 10) === 0;

  const shouldSkipBookerInformation = () => {
    return quickRegister === "1" ? `/checkout/learner-details` : `/checkout`;
  };

  return (
    <FutureDetailPage
      form={{ saveState: null }}
      layout="stacked"
      detailType="cart"
      title={t("myCart")}
      legacyId={0}
      page="overview"
    >
      <DetailPageContent>
        {cart?.items ? (
          <Fragment>
            {cart?.items?.length !== 0 ? (
              <Fragment>
                <OpacityOverlay
                  on={
                    cartRefetching === RefetchStatus.StartRefetch ||
                    cartItemsMutationRunning
                  }
                >
                  <Row>
                    <Col xs={12} data-label="Items">
                      <CartItems
                        hidePrices={hidePrices}
                        cart={cart}
                        onCartLineItemHandler={handleRemoveCartLineItem}
                        messages={messages}
                        saveState={saveState}
                        cartRefetching={
                          cartRefetching === RefetchStatus.StartRefetch ||
                          cartItemsMutationRunning
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6} data-label="Promo Code">
                      {!zeroPricedCart && (
                        <PromoCode cart={cart} refetchCart={refetchCart} />
                      )}
                    </Col>
                    <Col xs={12} sm={6} data-label="Summary">
                      <Card>
                        <FutureArticleHeader title={t("summary")} />
                        {cartSummaryMutationRunning && <LoadingBar isLoading />}
                        <OpacityOverlay on={cartSummaryMutationRunning}>
                          {!hidePrices && !zeroPricedCart && (
                            <CartSummary
                              cart={cart}
                              usage={CartSummaryUsage.Cart}
                              refetchCart={refetchCart}
                              setIsRemovingPromoCode={(value: boolean) =>
                                setCartSummaryMutationRunning(value)
                              }
                            />
                          )}
                          <div>
                            <Link
                              type="suppressed"
                              url={`/catalog/${BookerIntention.Self}`}
                              label={t("continueShopping")}
                              routerLink={RouterLink}
                            />
                            <Link
                              url={shouldSkipBookerInformation()}
                              type="primary"
                              label={t("proceedToCheckout")}
                              styleOptions={[UtilityStyle.FloatRight]}
                              routerLink={RouterLink}
                            />
                          </div>
                        </OpacityOverlay>
                      </Card>
                    </Col>
                  </Row>
                </OpacityOverlay>
              </Fragment>
            ) : (
              <Row>
                <Col xs={12}>
                  <EmptyCartText />
                </Col>
              </Row>
            )}
          </Fragment>
        ) : (
          <Row>
            <Col xs={12}>
              <LoadingCard header={false} />
            </Col>
          </Row>
        )}
      </DetailPageContent>
    </FutureDetailPage>
  );
};

const CartItems: FunctionComponent<{
  hidePrices: boolean;
  cart: Cart;
  onCartLineItemHandler: (itemId: string) => Promise<void>;
  messages: MessageOptions;
  saveState: SaveStates;
  cartRefetching: boolean;
}> = ({
  hidePrices,
  cart,
  onCartLineItemHandler,
  messages,
  saveState,
  cartRefetching,
}) => {
  const { viewer } = useViewer();
  const { t } = useTranslation();
  const headings = [{ title: t("item") }];
  const { convertLMSImageUrl } = useGlobalStore();
  const { dateFormat } = useDateFormatter({ showTime: true });

  if (!hidePrices) {
    headings.push({
      title: t("price"),
    });
  }

  const getImage = (event?: EventType, learningPath?: LearningPath) => {
    if (learningPath && learningPath.imageUrl) {
      return learningPath.imageUrl;
    }

    if (event && event?.course?.imageUrl) {
      return event?.course?.imageUrl;
    }
    return coursePlaceholder;
  };

  return (
    <Card>
      <List>
        <ListHeader
          gridColumns={`${CART_GRIDCOLUMNS} ${
            hidePrices ? "" : HAS_PRICE_GRIDCOLUMNS
          } 100px`}
          headings={headings}
        />
        {cartRefetching && <LoadingBar isLoading />}
        {saveState !== "saving" && (
          <FormStateStatus messages={messages} saveState={saveState} />
        )}
        {cart.items &&
          cart.items.map((item: Maybe<CartLineItem>, i: number) => {
            const { event, learningPath } = getProductOption(item);
            const image = getImage(event, learningPath);
            const rowLabel = event?.name || learningPath?.name;
            return (
              <Fragment key={i}>
                <ListRow
                  gridColumns={`${CART_GRIDCOLUMNS} ${
                    hidePrices ? "" : HAS_PRICE_GRIDCOLUMNS
                  }`}
                  extra={
                    <div style={{ marginTop: 18 }}>
                      <Button
                        type="suppressed"
                        icon="remove-circle"
                        label="Remove"
                        ariaLabel={t("removeFromCart")}
                        onClick={() =>
                          item?.id && onCartLineItemHandler(item.id)
                        }
                      />
                    </div>
                  }
                  extraWidth={100}
                  dataLabel={rowLabel}
                >
                  <ListCell>
                    <div className="cart-item">
                      <div className="cart-item--image--container">
                        <div
                          className="cart-item--image"
                          style={{
                            backgroundImage: `url(${convertLMSImageUrl(
                              image,
                            )})`,
                          }}
                        ></div>
                      </div>
                      <div className="cart-item--details">
                        <h3>{rowLabel}</h3>
                        <div>
                          {event?.location && (
                            <div>
                              {getLocationRegionFormat(event?.location)}
                            </div>
                          )}
                          {event?.start && (
                            <div>
                              {formatDateRange(
                                event?.start,
                                event?.end || "",
                                dateFormat,
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </ListCell>
                  {!hidePrices && (
                    <Fragment>
                      <ListCell extraClass="cart-item--value">
                        <p className="font-weight-bold">
                          <span className="d-lg-none">{t("price")}: </span>
                          {getFinancialFormat(
                            item?.totalAmount,
                            cart?.currency,
                            viewer?.locale,
                          )}
                        </p>
                      </ListCell>
                    </Fragment>
                  )}
                </ListRow>
              </Fragment>
            );
          })}
      </List>
    </Card>
  );
};

const EmptyCartText: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col xs={12}>
        <h2>{t("yourCartIsEmpty")}</h2>
        <p>
          <Trans i18nKey="emptyCartHelper">
            <RouterLink to="/catalog/self" />
          </Trans>
        </p>
      </Col>
    </Row>
  );
};
