import React, { Fragment, FunctionComponent, ReactNode, useState } from "react";
import { Nav, Navbar, NavDropdown, MenuItem, Glyphicon } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import {
  Avatar,
  ButtonEvent,
  Container,
  Button,
} from "@administrate/piston-ux";
import { BackButtonConfig } from "@administrate/piston-ux/lib/Header/types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

import { useGlobalStore } from "./providers/GlobalStore";
import { useAuth0 } from "./react-auth0-wrapper";
import { useViewer } from "./providers/ViewerProvider";
import { Maybe } from "./generated/lmsTypes";

export type NextButtonConfig = {
  label?: string | undefined | ReactNode;
  buttonLabel: string;
  onClick: (e: ButtonEvent) => void;
  disabled?: boolean;
};

export const MenuBar: FunctionComponent<{
  type?: "player" | "basic" | "general";
  back?: BackButtonConfig;
  next?: NextButtonConfig;
  playerContent?: PlayerTitleData;
}> = observer(({ type = "general", back, next, playerContent }) => {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  let className = `navbar navbar-default navbar-fixed-top ${
    viewer?.isPreviewer ? "navbar-preview" : ""
  }`;

  return (
    <header className={`menubar--${type}`}>
      <nav className={className} aria-label={t("applicationHeader")}>
        <Container fluid className="pr-2 pr-sm-4">
          {(type === "basic" || type === "player") && back && (
            <Back back={back} srOnly={type === "player"} />
          )}
          {type === "general" && <SideBarToggle />}
          <AppMenu isPreview={!!viewer?.isPreviewer} />
          {type === "player" && playerContent && (
            <PlayerTitle content={playerContent} />
          )}
          {type === "general" && <StatusMenus />}
          {(type === "basic" || type === "player") && next && (
            <PlayerActions next={next} />
          )}
        </Container>
      </nav>
    </header>
  );
});

const Back: FunctionComponent<{ back: BackButtonConfig; srOnly: boolean }> = ({
  back,
  srOnly,
}) => {
  const { t } = useTranslation();
  return (
    <button
      className="navbar-toggle navbar-back"
      onClick={back.onClick}
      disabled={back.disabled}
    >
      <span className="icon glyphicon glyphicon-chevron-left" />
      <span className={`navbar-back--label ${srOnly ? "sr-only" : ""}`}>
        {t("backTo")} {back.label}
      </span>
    </button>
  );
};

const PlayerActions: FunctionComponent<{ next: NextButtonConfig }> = ({
  next,
}) => {
  const { t } = useTranslation();
  const globalState = useGlobalStore();
  return (
    <div className="navbar-next">
      {next.label && <div className="navbar-next--container">{next.label}</div>}
      <Button
        type="primary"
        onClick={next.onClick}
        disabled={next.disabled}
        label={
          <div>
            <span className="d-none d-sm-block">{next.buttonLabel}</span>
            <span className={`glyphicon glyphicon-chevron-right d-sm-none`} />
            <span className="sr-only">{next.label}</span>
          </div>
        }
      />
      <button
        className="navbar-toggle content-menu-toggle"
        onClick={() =>
          (globalState.contentMenuOpen = !globalState.contentMenuOpen)
        }
        tabIndex={0}
        aria-label={t("toggleContentMenu")}
      >
        <span className="glyphicon-pro glyphicon-pro-more-vertical" />
      </button>
    </div>
  );
};

const SideBarToggle: FunctionComponent = () => {
  const { t } = useTranslation();
  const globalState = useGlobalStore();
  const { viewer } = useViewer();
  return (
    <Fragment>
      {!viewer?.isPreviewer && (
        <button
          className="navbar-toggle"
          onClick={() => (globalState.sideBarOpen = !globalState.sideBarOpen)}
          tabIndex={0}
          aria-label={t("toggleNavigation")}
          id="menubar--toggle"
        >
          <Glyphicon glyph="menu-hamburger" />
        </button>
      )}
    </Fragment>
  );
};

const AppMenu: FunctionComponent<{ isPreview: boolean }> = ({ isPreview }) => {
  return (
    <div className="navbar-brand-wrapper">
      <Navbar.Brand>
        {isPreview ? (
          <div className="navbar-title" />
        ) : (
          <Link className="navbar-title" to="/" />
        )}
      </Navbar.Brand>
    </div>
  );
};

const StatusMenus: FunctionComponent = () => {
  return (
    <Nav pullRight className="navbar-nav">
      <UserMenu />
    </Nav>
  );
};

const UserMenu: FunctionComponent = () => {
  const { t } = useTranslation();
  const globalStore = useGlobalStore();
  const { viewer } = useViewer();
  const { isAuthenticated, user, logout } = useAuth0();

  return (
    <NavDropdown
      id="menubar_user"
      title={
        <>
          <Avatar icon="user" size="xs" />{" "}
          <span className="d-none d-sm-inline-block">
            {isAuthenticated
              ? `${user.given_name} ${user.family_name}`
              : t("guest")}
          </span>
        </>
      }
    >
      {viewer?.isPreviewer ? null : (
        <LinkContainer to={`/my-profile`} exact>
          <MenuItem id="menubar_user_my_profile">{t("myProfile")}</MenuItem>
        </LinkContainer>
      )}

      <MenuItem
        id="menubar_user_sign_out"
        onClick={() =>
          logout({
            redirect_uri: globalStore?.config?.brand_details
              ?.post_signout_redirect_url
              ? globalStore.config.brand_details.post_signout_redirect_url
              : window.location.origin,
            brand: globalStore.config.brand,
          })
        }
      >
        {t("signOut")}
      </MenuItem>
    </NavDropdown>
  );
};

export type PlayerTitleData = {
  type?: string;
  name?: Maybe<string> | undefined;
};

const PlayerTitle: FunctionComponent<{
  content?: PlayerTitleData;
}> = ({ content }) => {
  const [showAllTitle, setShowAllTitle] = useState(false);
  return (
    <div
      className="menubar--player--content"
      onClick={() => setShowAllTitle(!showAllTitle)}
    >
      <div>
        <h1 style={showAllTitle ? { whiteSpace: "initial" } : {}}>
          {content?.name}
        </h1>
        <p>{content?.type}</p>
      </div>
    </div>
  );
};

export const MainWrapper: FunctionComponent<{ hasIframe?: boolean }> = observer(
  ({ children, hasIframe }) => {
    const globalState = useGlobalStore();
    return (
      <main
        className={`${
          globalState.contentMenuOpen
            ? "contentMenu--open"
            : "contentMenu--closed"
        } ${hasIframe ? "iframe-shim" : ""}`}
      >
        {children}
      </main>
    );
  },
);
