import { Button, Card } from "@administrate/piston-ux";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "../useHistory";

type NoMatchType = {
  title?: string;
  description?: string;
  buttonPath?: string;
  buttonLabel?: string;
};

export const NoMatch: FunctionComponent<NoMatchType> = ({
  title,
  description,
  buttonPath,
  buttonLabel,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 80,
      }}
    >
      <div style={{ width: 304 }}>
        <Card>
          <h1 className="text-center m-0">{t(title || "pageNotFound")}</h1>
          <p className="text-center mt-4">
            {t(
              description ||
                "The page you're trying to access doesn't appear to exist",
            )}
          </p>
          <div className="text-center mt-5">
            <Button
              type="primary"
              label={t(buttonLabel || "Back to My Courses")}
              onClick={() =>
                history.push({
                  pathname: buttonPath || `/`,
                })
              }
            />
          </div>
        </Card>
      </div>
    </div>
  );
};
