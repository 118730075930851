import gql from "graphql-tag";
import { PAGE_INFO_FRAGMENT } from "./fragments";

export const TRAINING_TOKEN_ISSUES = gql`
  query tokenIssues {
    trainingTokenIssues {
      edges {
        node {
          id
          tokenType
          tokenTypeId
          initialBalance
          currentBalance
          redeemed
          createdAt
          expiresAt
          isExpired
          paymentCode
          legacyCode
        }
      }
    }
  }
`;

export const TRAINING_TOKEN_ISSUE_LOG = gql`
  query transaction(
    $tokenIssueId: ID!
    $order: TokenIssueTransactionFieldOrder
    $first: Int
    $offset: Int
    $after: String
  ) {
    tokenIssueTransactions(
      tokenIssueId: $tokenIssueId
      first: $first
      offset: $offset
      order: $order
      after: $after
    ) {
      transactions {
        pageInfo {
          ...PageInfo
        }
        edges {
          node {
            type
            notes
            balanceDelta
            occurredAt
            registerableTitle
            tokenReceiptNumber
          }
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;
