import React, { FunctionComponent, Fragment, useState } from "react";
import {
  Modal,
  useFormValues,
  Button,
  StaticInput,
  RadioButtons,
  Select,
  Typeahead,
  FormStateStatus,
  CustomFieldInput,
  LoadingBar,
} from "@administrate/piston-ux";
import { get } from "lodash";
import { useWebLinkQuery, useWebLinkClient } from "../hooks/weblink";
import { Query as WebLinkQuery, Maybe } from "../generated/weblinkTypes";
import { extractNodes } from "../utils/extractNodes";
import { getUniqueId } from "../utils/displayHelpers";
import { WEBLINK_REGIONS_QUERY } from "../queries/filters";
import { WEBLINK_BASIC_CATALOG_QUERY } from "../queries/catalogue";
import {
  TRAINING_REQUEST_WORKFLOW_CONFIGURATION,
  REQUEST_TRAINING_MUTATION,
} from "../queries/trainingRequests";
import { Viewer, Query as LMSQuery } from "../generated/lmsTypes";
import { TrainingRequestWorkflowField } from "../generated/weblinkTypes";
import { VIEWER_QUERY } from "../queries/viewer";
import { useWebLinkMutation } from "../hooks/weblink";
import { useLmsQuery } from "../hooks/lms";
import { useTranslation } from "react-i18next";
import { useBookerIntention } from "../hooks/useBookerIntention";
import { BookerIntention } from "../pages/Order/BookingPage";
import { useWeblinkSettings } from "../hooks/useWeblinkSettings";

/**
 * Component to render modal for requesting a training,
 * which might be either Course or Learning Path.
 */
export const RequestTraining: FunctionComponent<{
  course?: Interest;
  learningPath?: Interest;
}> = ({ course, learningPath }) => {
  const { trainingRequestWorkflowEnabled, regionId } = useWeblinkSettings();
  const interest = course || learningPath;

  const { t } = useTranslation();
  const [requestModal, setRequestModal] = useState(false);
  const [save, setSave] =
    useState<"saving" | "saved" | "error" | string | null>(null);

  const { loading: isViewerLoading, data: viewerResponse } =
    useLmsQuery<LMSQuery>(VIEWER_QUERY);

  const [requestTraining] = useWebLinkMutation(REQUEST_TRAINING_MUTATION);

  const onDone = async (submitted: boolean, values: RequestTrainingDetails) => {
    setSave("saving");

    if (submitted) {
      const requestTrainingResponse = await requestTraining({
        variables: getRequestTrainingVariables(values),
      });

      const trainingRequestId = get(
        requestTrainingResponse,
        "data.requestTraining.trainingRequest.id",
        null,
      );

      const mutationErrors = get(
        requestTrainingResponse,
        "data.requestTraining.errors",
        null,
      );

      if (
        requestTrainingResponse.errors &&
        requestTrainingResponse.errors.length > 0
      ) {
        console.error(
          "Encountered an error while requesting training",
          requestTrainingResponse.errors,
        );

        setSave("error");
      } else if (
        (mutationErrors && mutationErrors.length > 0) ||
        !trainingRequestId
      ) {
        console.error(
          "Encountered an error while requesting training",
          trainingRequestId,
          mutationErrors,
        );

        setSave("error");
      } else {
        setRequestModal(false);
        setSave("saved");
      }
    } else {
      setSave(null);
      setRequestModal(false);
    }
  };

  return (
    <Fragment>
      {trainingRequestWorkflowEnabled && (
        <Button
          label={t("requestTraining")}
          onClick={() => setRequestModal(!requestModal)}
          type="suppressed"
        />
      )}
      {requestModal &&
        viewerResponse &&
        viewerResponse.viewer &&
        !isViewerLoading && (
          <RequestTrainingModal
            onDone={onDone}
            viewer={viewerResponse.viewer}
            saving={save === "saving"}
            interest={interest}
            error={save === "error"}
            regionId={regionId}
          />
        )}
      {save === "saved" && <FormStateStatus saveState="saved" />}
    </Fragment>
  );
};

type Interest = {
  id: string;
  name: string;
};

type RequestTrainingDetails = {
  interestType: "private" | "public";
  interest?: Interest;
  region: string;
} & {
  // Allow any string to be on form's payload as it could contain
  // names of Training request fields, which in their turn have
  // ID as part of the name
  [key: string]: string;
};

const RequestTrainingModal: FunctionComponent<{
  onDone: Function;
  viewer: Viewer;
  saving?: boolean;
  interest?: Interest;
  error: boolean;
  regionId: Maybe<String>;
}> = ({ onDone, viewer, saving, interest, error, regionId }) => {
  const { t } = useTranslation();
  const [bookerIntention] = useBookerIntention();
  const values = useFormValues({
    interestType: interestTypePrivate,
    interest: interest || null,
    region: regionId,
  });

  const {
    loading: isTrainingRequestWorkflowLoading,
    data: trainingRequestWorkflowResponse,
  } = useWebLinkQuery<WebLinkQuery>(TRAINING_REQUEST_WORKFLOW_CONFIGURATION);

  const trainingRequestWorkflowFields = extractWorkflowFields(
    trainingRequestWorkflowResponse,
  );

  const { loading: isRegionsLoading, data: regionsResponse } =
    useWebLinkQuery<WebLinkQuery>(WEBLINK_REGIONS_QUERY);

  const regions = regionsResponse
    ? extractNodes(regionsResponse.regions.edges)
    : [];

  const regionOptions = regions.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const client = useWebLinkClient();
  const interestOptionsLoader = getInterestOptionsLoader(client);

  const isLoading = saving || isTrainingRequestWorkflowLoading;

  return (
    <Modal
      title={t("requestTraining")}
      show
      onDone={(submitted: boolean) => onDone(submitted, values)}
      values={values}
      disabled={isLoading}
      error={error && t("encounteredAnErrorWhileRequestingTraining")}
      primaryActionText={t("requestTraining")}
    >
      <LoadingBar isLoading={isLoading} />
      {bookerIntention === BookerIntention.Coordinating && (
        <Select
          name="region"
          label={t("region")}
          options={regionOptions}
          valid={v => !!v}
          loading={isRegionsLoading}
        />
      )}
      {values.interest && (
        <StaticInput name="interest.name" label={t("training")} />
      )}
      {!values.interest && (
        <Typeahead
          loadOptions={interestOptionsLoader}
          label={t("training")}
          name="interest"
          formatter={(interest: Interest) => interest.name}
          placeholder={`${t("selectOrLeaveBlankForOther")}...`}
        />
      )}
      <RadioButtons
        name="interestType"
        options={[
          { label: t(interestTypePrivate), value: interestTypePrivate },
          { label: t(interestTypePublic), value: interestTypePublic },
        ]}
        label={t("trainingType")}
        type="button"
      />
      {trainingRequestWorkflowFields &&
        trainingRequestWorkflowFields.map(field => {
          return (
            <CustomFieldInput
              key={field.id}
              name={`${trainingWorkflowFieldNamePrefix}${field.id}`}
              label={field.label}
              type={field.type}
              options={field.options}
              valid={field.required ? a => !!a : undefined}
              uniqueId={getUniqueId()}
            />
          );
        })}
    </Modal>
  );
};

/**
 * Convert form payload into format suitable to send over to requestTraining mutation.
 */
const getRequestTrainingVariables = (formValues: RequestTrainingDetails) => ({
  input: {
    interestId: formValues.interest ? formValues.interest.id : "",
    regionId: formValues.region,
    isPrivate: formValues.interestType === interestTypePrivate,
    requestFields: Object.keys(formValues)
      .filter(k => k.startsWith(trainingWorkflowFieldNamePrefix))
      .map(name => [name.slice(trainingWorkflowFieldNamePrefix.length), name])
      .map(([fieldId, nameAsInForm]) => ({
        id: fieldId,
        value: formValues[nameAsInForm],
      })),
  },
});

/**
 * Helper to extract workflow fields from Training request workflow information
 * received from server.
 */
const extractWorkflowFields: (
  rawResponse?: WebLinkQuery,
) => TrainingRequestWorkflowField[] = response => {
  return response?.store?.trainingRequestWorkflow?.workflowFields ?? [];
};

const trainingWorkflowFieldNamePrefix = "field_";

const interestTypePrivate = "private";
const interestTypePublic = "public";

/**
 * Build loader function to load available interests to request training for.
 * Currently it is either Course or Learning path.
 */
const getInterestOptionsLoader: (
  webLinkClient: ReturnType<typeof useWebLinkClient>,
) => (inputValue: string) => Promise<any[]> = client => {
  const interestOptions = async (inputValue: string) => {
    const catalogueInfo = await client.query({
      query: WEBLINK_BASIC_CATALOG_QUERY,
      variables: {
        search: inputValue,
        order: { field: "name", direction: "asc" },
      },
    });

    return extractNodes(catalogueInfo.data.catalogue.edges).map(
      ({ id, name }) => ({ id, name }),
    );
  };

  return interestOptions;
};
