import React, { FunctionComponent } from "react";
import { Alert } from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { useGlobalStore } from "../providers/GlobalStore";
import { useHistory, useParams } from "../useHistory";

export const PreviewLogin: FunctionComponent = () => {
  const { previewJWT } = useParams<{previewJWT:string}>();
  const { t } = useTranslation();
  const globalStore = useGlobalStore();
  const history = useHistory();
  if (previewJWT) {
    globalStore.previewJWT = previewJWT;
    history.push("/preview");
    return <div>{t("redirecting")}</div>;
  }

  return <Alert message={t("invalidPreviewToken")} />;
};
