import { useState } from "react";
import { CheckoutURLS } from "../providers/CheckoutProvider";
import { PLACE_ORDER_MUTATION } from "../queries/cart";
import { useHistory } from "../useHistory";
import { useWebLinkMutation } from "./weblink";
import { CartEvent } from "../analytics/events";
import { useAnalytics } from "../providers/AnalyticsProvider";

export type FieldError = {
  __typename?: "FieldError";
  label?: string;
  message: string;
  value?: string;
};

export const usePlaceOrder = (cartId?: null | string) => {
  const [validationErrors, setValidationError] = useState<FieldError[]>();
  const [mutationError, setMutationError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { captureEvent } = useAnalytics();

  const [
    placeOrder,
    { loading: placeOrderLoading, error: mutationPlaceOrderError },
  ] = useWebLinkMutation(PLACE_ORDER_MUTATION);

  const submit = async () => {
    setLoading(true);
    placeOrder({
      variables: {
        input: {
          cartId,
        },
      },
    })
      .then(response => {
        setLoading(false);
        const validationErrors: FieldError[] = response.data.cart.errors;

        if (!placeOrderLoading) {
          if (mutationPlaceOrderError) {
            setMutationError(mutationPlaceOrderError.message);
          } else if (validationErrors && validationErrors.length > 0) {
            setValidationError(validationErrors);
          } else {
            captureEvent(
              CartEvent.fromCheckoutComplete(
                response.data.cart.placeOrder.cart,
                null,
              ),
            );
            history.push({
              pathname: `/checkout/${CheckoutURLS.OrderComplete}`,
            });
          }
        }
      })
      .catch(e => {
        setLoading(false);
        const errors: string = e.graphQLErrors
          .map((error: FieldError) => error.message)
          .join(", ");
        setMutationError(errors);
      });
  };

  return {
    loading,
    mutationError,
    validationErrors,
    submit,
  };
};
