import React, { useCallback, useEffect, useState } from "react";

import { useLmsMutation } from "../../../hooks/lms";
import {
  RECORD_EXTERNAL_CONTENT_ACCESSED,
  RECORD_EXTERNAL_PROGRESS_STATUS,
} from "../../../mutations/registrations";
import { useViewer } from "../../../providers/ViewerProvider";
import { ExternalActivityResult } from "./ExternalActivityResult";
import { ExternalObjective } from "./ExternalObjective";
import { KryterionObjective } from "./KryterionObjective";
import {
  ContentResultStatus,
  ExternalActivity,
  ExternalContentResult,
  Mutation,
} from "../../../generated/lmsTypes";

type ExternalArgs = {
  kind: "External";
  url?: string;
  description?: string;
};

type KryterionArgs = {
  kind: "Kryterion";
  url: string;
  externalCourseId: string;
  instructions?: string;
  title?: string;
  accessedPreviously?: boolean;
};

type ExtraArgs = ExternalArgs | KryterionArgs;

const getDefaultExternalActivityUrl = (kind: string, url?: string) => {
  return kind === "External" ? null : url;
};

export const BaseExternalObjective: React.FunctionComponent<{
  status?: ContentResultStatus;
  registrationId?: string;
  contentId: string;
  shouldAutoComplete: boolean;
  extraArgs: ExtraArgs;
}> = ({ status, registrationId, contentId, shouldAutoComplete, extraArgs }) => {
  const [tryAgainClicked, setTryAgainClicked] = useState(false);
  const [latestAttemptId, setLatestAttemptId] = useState<string | null>(null);
  const [externalActivityUrl, setExternalActivityUrl] = useState(
    getDefaultExternalActivityUrl(extraArgs.kind, extraArgs.url),
  );

  const [recordExternalContentAccessed] = useLmsMutation<Mutation>(
    RECORD_EXTERNAL_CONTENT_ACCESSED,
    {
      onCompleted: data => {
        if (extraArgs.kind !== "External") return;
        setExternalActivityUrl(
          (
            data.registration?.recordExternalContentAccessed?.registration
              ?.contentResults?.edges?.[0]?.node?.content as ExternalActivity
          ).externalActivityUrl,
        );
      },
    },
  );

  const [recordExternalProgressStatus] = useLmsMutation(
    RECORD_EXTERNAL_PROGRESS_STATUS,
  );

  const { viewer } = useViewer();

  const setInteractionId = useCallback(() => {
    const shouldRecord =
      (status !== ContentResultStatus.Passed &&
        status !== ContentResultStatus.Failed) ||
      tryAgainClicked;
    if (shouldRecord && !viewer?.isPreviewer) {
      recordExternalContentAccessed({
        variables: {
          registrationId: registrationId,
          contentId: contentId,
          stringContentId: contentId,
        },
      }).then(({ data }) => {
        setLatestAttemptId(
          (
            data?.registration?.recordExternalContentAccessed?.registration
              ?.contentResults?.edges?.[0]?.node as ExternalContentResult
          ).latestAttempt?.id ?? null,
        );
      });
    }
  }, [
    contentId,
    registrationId,
    recordExternalContentAccessed,
    status,
    tryAgainClicked,
    viewer,
  ]);

  const urlFromArgs = extraArgs.url;
  const isPreviewer = viewer?.isPreviewer;
  useEffect(() => {
    if (isPreviewer) {
      setExternalActivityUrl(urlFromArgs);
    }
  }, [isPreviewer, urlFromArgs]);

  useEffect(() => {
    if (
      !isPreviewer &&
      shouldAutoComplete &&
      latestAttemptId &&
      status === ContentResultStatus.Incomplete
    ) {
      // noinspection JSIgnoredPromiseFromCall - yolo mutations
      recordExternalProgressStatus({
        variables: {
          attemptId: latestAttemptId,
          status: ContentResultStatus.Completed,
        },
      });
    }
  }, [
    isPreviewer,
    latestAttemptId,
    recordExternalProgressStatus,
    shouldAutoComplete,
    status,
  ]);

  const handleClickTryAgain = () => {
    setTryAgainClicked(true);
    setInteractionId();
  };

  if (
    status === ContentResultStatus.Passed ||
    (status === ContentResultStatus.Failed && !tryAgainClicked)
  ) {
    return (
      <ExternalActivityResult
        status={status}
        onClickTryAgain={handleClickTryAgain}
      />
    );
  }

  switch (extraArgs.kind) {
    case "External":
      return (
        <ExternalObjective
          url={externalActivityUrl || ""}
          description={extraArgs.description}
          contentId={contentId}
          tryAgainClicked={tryAgainClicked}
          setInteractionId={setInteractionId}
        />
      );
    case "Kryterion":
      return (
        <KryterionObjective
          url={externalActivityUrl || ""}
          instructions={extraArgs.instructions}
          title={extraArgs.title}
          externalCourseId={extraArgs.externalCourseId}
          registrationId={registrationId!}
          contentId={contentId}
          status={status}
          setInteractionId={setInteractionId}
          accessedPreviously={extraArgs.accessedPreviously}
        />
      );
  }
};
