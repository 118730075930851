import { sha256 } from "js-sha256";
import ReactGA from "react-ga";
import { Maybe } from "./generated/lmsTypes";
import { legacyID } from "./utils/convertID";

const TRACKING_ID = "UA-85231646-2";
const ENABLE_DEBUG = false;

export const initAnalytics = async (
  userId: Maybe<string> | undefined,
  instance: string,
) => {
  if (userId) {
    const idString = `${instance}_${legacyID(userId)}`;
    const hash = sha256(idString);

    ReactGA.initialize(TRACKING_ID, {
      debug: ENABLE_DEBUG,
      gaOptions: {
        userId: hash,
      },
    });
    ReactGA.set({ dimension1: instance, dimension2: "no" });
  } else {
    // preview mode
    ReactGA.initialize(TRACKING_ID, {
      debug: ENABLE_DEBUG,
    });
    ReactGA.set({ dimension1: instance, dimension2: "yes" });
  }
};

export const sendEvent = ({
  location,
  action,
  value,
}: {
  location: string;
  action: string;
  value?: number;
}) => {
  ReactGA.event({
    category: location,
    action,
    label: `${location} - ${action}`,
    value,
  });
};

export const sendPageView = (
  path: string,
  trackerNames?: ReactGA.TrackerNames | undefined,
  title?: string | undefined,
) => {
  ReactGA.set({ page: path });
  ReactGA.pageview(path, trackerNames, title);
};
