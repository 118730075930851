import React, { createContext, useContext } from "react";

import {
  VIEWER_QUERY,
  UPDATE_VIEWER_MUTATION,
  MARK_LEARNER_WELCOMED,
} from "../queries/viewer";
import {
  ViewerResponse,
  Viewer,
  UpdateViewerResponse,
  ViewerUpdate,
} from "../types/Viewer";
import { GraphqlError } from "../types/GraphqlError";
import { Maybe } from "../types/Maybe";
import { setViewerLocale } from "../i18n";
import { useLmsQuery, useLmsMutation } from "../hooks/lms";
import { useAuth0 } from "../react-auth0-wrapper";
import { isViewerPreviewer } from "../utils/previewHelpers";

type ViewerContextType = {
  isViewerLoading: boolean;
  viewer?: Maybe<Viewer>;
  updateViewerMutation: Function;
  updateViewerMutationIsLoading: boolean;
  updateViewerErrors: Array<GraphqlError>;
  markLearnerWelcomed: Function;
};

const ViewerContext = createContext<ViewerContextType | undefined>(undefined);

export const useViewer = () => {
  const context = useContext(ViewerContext);
  if (context === undefined) {
    throw new Error("ViewerProvider not found");
  }
  return context;
};

export const ViewerProvider: React.FunctionComponent = ({ children }) => {
  const { data: viewerResponse, loading: isViewerLoading } =
    useLmsQuery<ViewerResponse>(VIEWER_QUERY);

  const viewer = viewerResponse?.viewer;
  const { user } = useAuth0();

  if (viewer) {
    viewer.isPreviewer = isViewerPreviewer(user);
  }

  if (!isViewerLoading && viewer) {
    setViewerLocale(viewer);
  }

  const [
    updateViewerMutation,
    { loading: updateViewerMutationIsLoading, data: updateViewerMutationData },
  ] = useLmsMutation<UpdateViewerResponse, ViewerUpdate>(
    UPDATE_VIEWER_MUTATION,
    {
      update(cache, { data: updateViewerMutationData }) {
        const viewerResponse = cache.readQuery<ViewerResponse>({
          query: VIEWER_QUERY,
        });
        const updatedViewer = updateViewerMutationData?.viewer?.update
          ?.viewer || {
          isCoordinator: viewer ? viewer.isCoordinator : false,
        };
        cache.writeQuery({
          query: VIEWER_QUERY,
          data: {
            viewer: {
              ...viewerResponse!.viewer,
              ...updatedViewer,
            },
          },
        });
      },
    },
  );

  const updateViewerErrors =
    updateViewerMutationData?.viewer?.update?.errors || [];

  const [markLearnerWelcomed] = useLmsMutation(MARK_LEARNER_WELCOMED, {
    update(cache) {
      const viewerResponse = cache.readQuery<ViewerResponse>({
        query: VIEWER_QUERY,
      });

      if (viewerResponse?.viewer) {
        viewerResponse.viewer.learnerWelcomed = true;
      }

      cache.writeQuery({
        query: VIEWER_QUERY,
        data: {
          viewer: {
            ...viewerResponse!.viewer,
          },
        },
      });
    },
  });

  return (
    <ViewerContext.Provider
      value={{
        isViewerLoading,
        viewer,
        updateViewerMutation,
        updateViewerMutationIsLoading,
        updateViewerErrors,
        markLearnerWelcomed,
      }}
    >
      {children}
    </ViewerContext.Provider>
  );
};
