import React, { FunctionComponent, Fragment } from "react";
import moment from "moment";
import {
  Event as generatedEvent,
  EventEdge as generatedEventEdge,
  EventField,
  FilterOperation,
} from "../generated/weblinkTypes";
import { StatusLabel } from "@administrate/piston-ux";
import { ColorOptions } from "@administrate/piston-ux/lib/types";
import { useTranslation } from "react-i18next";
import {
  endOfDayWithTimeZone,
  startOfDayWithTimeZone,
} from "./dateTimeHelpers";
import { Bookable } from "../types/Bookable";
import { DetailFilter } from "../pages/Catalog/DetailFilterContext";

export const EVENT_TABLE_PAGINATION = 10;

export const Duration: FunctionComponent<{
  start: string | undefined | null;
  end: string | undefined | null;
}> = ({ start, end }) => {
  const { t } = useTranslation();
  if (!start) {
    return null;
  }

  if (!end) {
    return <Fragment>{t("openEnrollment")}</Fragment>;
  }

  const dayDiff = moment(end).endOf("day").diff(moment(start).startOf("day"));

  return (
    <Fragment>
      {moment.duration(dayDiff).asDays().toFixed()} {t("days")}
    </Fragment>
  );
};

export const hasUnlimitedPlaces = (event: Bookable) =>
  event.remainingPlaces === null || event.remainingPlaces === undefined;

export const EventType: FunctionComponent<{ type: string }> = ({ type }) => {
  const { t } = useTranslation();
  if (type === "private") {
    return <StatusLabel color={ColorOptions.Blue} text={t("private")} />;
  }
  return <StatusLabel color={ColorOptions.Green} text={t("public")} />;
};

export const isCourseStartable = (events: Array<generatedEventEdge>) =>
  events && events.length === 1 && isEventFree(events[0].node);

const isEventFree = (event: generatedEvent) => {
  const price = event.price || event.prices[0];
  return price.amount && parseInt(price.amount, 10) === 0;
};

export const isLmsContentAccessible = (
  isPreview: boolean,
  lmsStart?: string,
  lmsEnd?: string,
) => {
  if (isPreview) {
    return true;
  }

  const now = moment();
  return (
    (!lmsStart || now.isAfter(lmsStart)) && (!lmsEnd || now.isBefore(lmsEnd))
  );
};

export const isLmsContentInThePast = (lmsEnd?: string) => {
  return lmsEnd && moment().isSameOrAfter(lmsEnd);
};

export const getEventFilters = (
  courseCode: string,
  affectBarFilters: DetailFilter,
  timeZoneName: string,
) => {
  const filters = [
    {
      value: courseCode,
      operation: FilterOperation.Eq,
      field: EventField.CourseCode,
    },
  ];
  if (affectBarFilters.location) {
    filters.push({
      value: `${affectBarFilters.location.name}`,
      operation: FilterOperation.Eq,
      field: EventField.LocationName,
    });
  }
  if (affectBarFilters.region) {
    filters.push({
      value: `${affectBarFilters.region.code}`,
      operation: FilterOperation.Eq,
      field: EventField.RegionCode,
    });
  }
  if (affectBarFilters.fromDate) {
    filters.push({
      value: startOfDayWithTimeZone(affectBarFilters.fromDate, timeZoneName),
      operation: FilterOperation.Ge,
      field: EventField.Start,
    });
    if (affectBarFilters.toDate) {
      filters.push({
        value: endOfDayWithTimeZone(affectBarFilters.toDate, timeZoneName),
        operation: FilterOperation.Le,
        field: EventField.End,
      });
    }
  }

  if (affectBarFilters.minSeats) {
    filters.push({
      value: affectBarFilters.minSeats.toString(),
      operation: FilterOperation.Ge,
      field: EventField.RemainingPlaces,
    });
  }

  return filters;
};
