import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

export const AchievementLink: FunctionComponent<{
  url: string;
  external: boolean;
  className: string;
}> = ({ url, external, className, children }) => {
  if (external) {
    return (
      <a
        className={className}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }
  return (
    <Link
      className={className}
      to={`/my-achievements${url}`}
      rel="noopener noreferrer"
    >
      {children}
    </Link>
  );
};
