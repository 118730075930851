import { OpacityOverlay } from "@administrate/piston-ux";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useViewersLastCart } from "../../hooks/useViewersLastCart";

export const CartWidget: FunctionComponent = () => {
  const { t } = useTranslation();
  const { cart, loading } = useViewersLastCart();

  const quantity = cart?.items?.length || 0;

  return (
    <div style={{ position: "relative" }}>
      <OpacityOverlay on={loading}>
        <Link to="/cart" aria-disabled={loading} className="d-inline-block">
          <div className="cart-widget">
            <div className="cart-widget--icon">
              <span className="glyphicon-pro glyphicon-pro-basket"></span>
            </div>
            {quantity > 0 && (
              <span className="cart-widget--quantity">{quantity}</span>
            )}
            <span className="sr-only">{t("myCart")}</span>
          </div>
        </Link>
      </OpacityOverlay>
    </div>
  );
};
