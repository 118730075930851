export class ScormApi2004 {
  constructor(dataModel, defaults) {
    this._dataModel = dataModel;
    this._defaults = defaults;
    this._version = "1.0";
    this._errorCode = 0;
    this._initialized = false;
    this._terminated = false;
  }

  get version() {
    return this._version;
  }

  get variant() {
    // For debug: window.API.variant
    return "SCORM2004";
  }

  Initialize(emptyString) {
    // General Argument Error (201)
    if (emptyString !== "") {
      this._errorCode = 201;
      return "false";
    }

    // Already Initialized (103)
    if (this._initialized) {
      // Workaround with IOS Safari Popup Issue
      this._errorCode = 0;
      return "true";
    }

    // Content Instance Terminated (104)
    if (this._terminated) {
      this._errorCode = 104;
      return "false";
    }

    this._dataModel.load();

    this._dataModel.defaults({
      cmi: {
        _version: this.version,
        mode: "normal",
        completion_status: "unknown",
        success_status: "unknown",
      },
    });

    this._dataModel.defaults(this._defaults);

    /* General Initialization Failure (102) */

    this._initialized = true;
    this._errorCode = 0;
    return "true";
  }

  Terminate(emptyString) {
    // General Argument Error (201)
    if (emptyString !== "") {
      this._errorCode = 201;
      return "false";
    }

    // Termination Before Initialization (112)
    if (!this._initialized) {
      this._errorCode = 112;
      return "false";
    }

    // Termination After Termination (113)
    if (this._terminated) {
      this._errorCode = 113;
      return "false";
    }

    /* General Termination Failure (111) */

    this._dataModel.commit(true);
    this._errorCode = 0;
    return "true";
  }

  GetValue(element) {
    // Retrieve Data Before Initialization (122)
    if (!this._initialized) {
      this._errorCode = 122;
      return "";
    }

    // Retrieve Data After Termination (123)
    if (this._terminated) {
      this._errorCode = 123;
      return "";
    }

    if (element === "cmi.interactions._children") {
      return "id,type,objectives,timestamp,correct_responses,weighting,learner_response,result,latency,description";
    }

    return this._dataModel.get(element);
  }

  SetValue(element, value) {
    this._dataModel.set(element, value);
    return "true";
  }

  Commit(emptyString) {
    // General Argument Error (201)
    if (emptyString !== "") {
      this._errorCode = 201;
      return "false";
    }

    /* General Commit Failure (391) */

    this._dataModel.commit();
    this._errorCode = 0;
    return "true";
  }

  GetLastError() {
    return this._errorCode.toString();
  }

  GetErrorString(/* errorCode */) {
    return "";
  }

  GetDiagnostic(/* errorCode */) {
    return "";
  }
}
