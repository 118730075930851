import React, { FunctionComponent } from "react";
import {
  FutureArticleHeader,
  useTypedFormValues,
  Input,
  FutureSelectFilter,
  FutureAsyncFilter,
} from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { FutureInputFilter } from "@administrate/piston-ux/lib/future/ArticleHeader/FutureInputFilter";
import {
  BookableType,
  Course,
  CourseEdge,
  DeliveryMethod,
  Location,
  LocationEdge,
} from "../../generated/weblinkTypes";
import { useCallback } from "react";
import {
  CatalogFilter,
  useCatalogFilters,
} from "../../pages/Catalog/CatalogFilterContext";
import { BookableDateFilter } from "./BookableDateFilter";

export type BookableLanguage = { name: string };

type AllDatesArticleHeaderProps = {
  onFilterChange: (key: keyof CatalogFilter, value: unknown) => void;
  locationOptions: (inputValue: string) => Promise<LocationEdge[]>;
  localeOptions: (inputValue: string) => Promise<BookableLanguage[]>;
  courseOptions: (inputValue: string) => Promise<CourseEdge[]>;
  loading: boolean;
};

export const AllDatesArticleHeader: FunctionComponent<AllDatesArticleHeaderProps> =
  ({
    onFilterChange,
    locationOptions,
    localeOptions,
    courseOptions,
    loading,
  }) => {
    const { t } = useTranslation();

    const { filters, setFilterValue } = useCatalogFilters();

    const onSearch = useCallback(
      value => {
        onFilterChange("search", value);
      },
      [onFilterChange],
    );

    const values = useTypedFormValues(filters);

    return (
      <FutureArticleHeader
        loading={loading}
        search={{
          initialValue: filters.search,
          label: "",
          onChange: onSearch,
        }}
        filters={{ values }}
      >
        <FutureSelectFilter
          label={t("type")}
          name="bookableType"
          options={[
            { label: t("event"), value: BookableType.Event },
            { label: t("learningPath"), value: BookableType.Path },
          ]}
          onChange={({ selected }) =>
            setFilterValue("bookableType", selected?.value as BookableType)
          }
        />
        <FutureSelectFilter
          label={t("learningMode")}
          name="learningMode"
          options={[
            { label: t("elearning"), value: DeliveryMethod.Lms },
            { label: t("classroom"), value: DeliveryMethod.Classroom },
          ]}
          onChange={({ selected }) =>
            setFilterValue("learningMode", selected?.value as DeliveryMethod)
          }
        />
        <FutureAsyncFilter
          label={t("location")}
          name="location"
          loadOptions={locationOptions}
          formatter={(location: Location) => location.name}
          onChange={(location: Location) =>
            setFilterValue("location", location)
          }
        />
        <FutureAsyncFilter
          label={t("language")}
          name="language"
          loadOptions={localeOptions}
          formatter={(language: BookableLanguage) => language.name}
          onChange={(language: BookableLanguage) =>
            setFilterValue("language", language)
          }
        />
        <FutureAsyncFilter
          label={t("course")}
          name="course"
          loadOptions={courseOptions}
          formatter={(course: Course) => course.name}
          onChange={(course: Course) => setFilterValue("course", course)}
        />
        <FutureInputFilter
          label={t("availability")}
          selected={filters.learners ?? ""}
          onSubmit={() => {
            setFilterValue("learners", values.learners);
          }}
          onClear={(values: CatalogFilter) => {
            values.learners = undefined;
            setFilterValue("learners", "");
          }}
        >
          <Input
            name="learners"
            label={t("learnerNumbers")}
            type="number"
            min={1}
          />
        </FutureInputFilter>
        <BookableDateFilter values={values} />
      </FutureArticleHeader>
    );
  };
