import React, { FunctionComponent, Fragment, useState } from "react";
import {
  List,
  ListRow,
  ListCell,
  Alert,
  Pagination,
  Modal,
  ListHeader,
  Form,
  useTypedFormValues,
  Select,
  OptionType,
  Placeholder,
  LoadingBar,
  BodyText,
} from "@administrate/piston-ux";
import { ListEmpty } from "@administrate/piston-ux/lib/List";
import { useTranslation } from "react-i18next";

import { useWebLinkQuery } from "../hooks/weblink";
import {
  Query,
  EventEdge,
  Event,
  Price,
  Cart,
} from "../generated/weblinkTypes";
import { Maybe, TrainingPass, TrainingPassType } from "../generated/lmsTypes";
import { WEBLINK_EVENT_SESSIONS_QUERY } from "../queries/events";
import { useWebLinkMutation } from "../hooks/weblink";
import { Duration } from "../utils/eventHelpers";
import {
  ADD_CART_LINE_ITEM_MUTATION,
  CREATE_CART_MUTATION,
} from "../queries/cart";
import { BookerIntention } from "../pages/Order/BookingPage";
import { useBookerIntention } from "../hooks/useBookerIntention";
import { useViewer } from "../providers/ViewerProvider";
import { getFinancialFormat } from "../utils/displayHelpers";
import { EventDateRange, DateRange } from "./EventDateRange";
import { LearningMode } from "./LearningMode";
import { AvailableSeats } from "./AvailableSeats";
import {
  computePassStatus,
  TrainingPassContentStatusLabel,
} from "./TrainingPass";
import { GridColumnAlignment } from "@administrate/piston-ux/lib/types/table";
import { useViewersLastCart } from "../hooks/useViewersLastCart";
import { BookNowButton } from "./bookables/BookNowButton";
import { ExecutionResult, MutationFunctionOptions } from "@apollo/react-common";

type EventListProps = {
  events: EventEdge[];
  loading: boolean;
  totalRecords: number;
  offset: number;
  hidePrices: boolean;
  hidePricesBeforePricingAgreementApplied: boolean;
  cartlessCheckout?: boolean;
  setOffset: (offset: number) => void;
  mutationRunning: boolean;
  setMutationRunning: (loading: boolean) => void;
  contentPasses: TrainingPassType[];
};

let EVENT_PAGINATION_LIMIT = 10;
const SESSION_PAGINATION_LIMIT = 5;

export const EventList: FunctionComponent<EventListProps> = ({
  events,
  loading,
  totalRecords,
  offset,
  hidePricesBeforePricingAgreementApplied,
  hidePrices,
  cartlessCheckout,
  setOffset,
  mutationRunning,
  setMutationRunning,
  contentPasses,
}) => {
  const { t } = useTranslation();
  const [apiError, setApiError] = useState(false);
  const [passRegisterError, setPassRegisterError] =
    useState<Maybe<React.ReactNode | string>>(null);
  const { viewer } = useViewer();
  const [bookerIntention] = useBookerIntention();

  const { validPassHeld } = computePassStatus(
    viewer?.trainingPasses ?? [],
    contentPasses.map(cp => cp.id ?? ""),
  );

  const { cart: viewersLastCart, loading: viewersCartLoading } =
    useViewersLastCart({
      skip: bookerIntention === BookerIntention.Coordinating,
    });

  const selfRegistrationCartLoading =
    bookerIntention === BookerIntention.Self && viewersCartLoading;

  const eventsOrCartLoading = loading || selfRegistrationCartLoading;

  const [
    createCart,
    { loading: createCartMutationLoading, error: createCartMutationError },
  ] = useWebLinkMutation(CREATE_CART_MUTATION);

  const [
    addCartLineItem,
    {
      loading: addCartLineItemMutationLoading,
      error: addCartLineItemMutationError,
    },
  ] = useWebLinkMutation(ADD_CART_LINE_ITEM_MUTATION);

  type Heading = {
    title: string;
    columnWidth: string;
    alignment?: GridColumnAlignment;
  };

  let headings: Heading[] = [
    {
      title: t("language"),
      columnWidth: ".75fr",
      alignment: GridColumnAlignment.LEFT,
    },
    {
      title: t("learningMode"),
      columnWidth: "minmax(95px, 110px)",
    },
    {
      title: t("location"),
      columnWidth: "1fr",
    },
    {
      title: t("dateAndTime"),
      columnWidth: "minmax(120px, 1.5fr)",
    },
    { title: t("duration"), columnWidth: "minmax(70px, 0.5fr)" },
    {
      title: t("availability"),
      columnWidth: "minmax(70px, 0.75fr)",
      alignment: GridColumnAlignment.LEFT,
    },
  ];

  if (!hidePrices) {
    headings.push({
      title: t("currency"),
      columnWidth: getCurrencyColumnWidth(events),
      alignment: GridColumnAlignment.LEFT,
    });
  }

  if (!(hidePrices || hidePricesBeforePricingAgreementApplied)) {
    headings.push({
      title: t("price"),
      columnWidth: "minmax(60px, 0.75fr)",
      alignment: GridColumnAlignment.CENTER,
    });
  }

  const passHeading: Heading = {
    title: t("pass"),
    columnWidth: "0.5fr",
  };
  if (hidePricesBeforePricingAgreementApplied) {
    passHeading.alignment = GridColumnAlignment.RIGHT;
  }
  headings.push(passHeading);

  const gridColumns = headings.map(({ columnWidth }) => columnWidth).join(" ");

  return (
    <Fragment>
      {(createCartMutationLoading || addCartLineItemMutationLoading) && (
        <LoadingBar isLoading overPage />
      )}
      {passRegisterError && (
        <Alert
          dismissButtonType="simple"
          onDismiss={() => setPassRegisterError(null)}
          type="error"
          message={passRegisterError}
          glyph="exclamationSign"
        />
      )}
      {(createCartMutationError ||
        apiError ||
        addCartLineItemMutationError) && (
        <Alert
          type="error"
          message={t("anErrorOccurredWhileCreatingYourCart")}
          glyph="removeSign"
        />
      )}
      <List extraClass="catalog-lists" loading={eventsOrCartLoading} hover>
        <ListHeader gridColumns={gridColumns} headings={headings} icon />
        {!eventsOrCartLoading &&
          events.map((edge: EventEdge) => (
            <Fragment key={edge.node.id}>
              <EventRow
                event={edge.node}
                createCartMutationLoading={createCartMutationLoading}
                hidePrices={hidePrices}
                hidePricesBeforePricingAgreementApplied={
                  hidePricesBeforePricingAgreementApplied
                }
                gridColumns={gridColumns}
                validPassHeld={validPassHeld}
                mutationRunning={mutationRunning}
                learnerPasses={viewer?.trainingPasses ?? []}
                contentPasses={contentPasses}
                setMutationRunning={setMutationRunning}
                cartlessCheckout={cartlessCheckout}
                viewersLastCart={viewersLastCart}
                viewersCartLoading={viewersCartLoading}
                addCartLineItem={addCartLineItem}
                createCart={createCart}
                setApiError={setApiError}
                setPassRegisterError={setPassRegisterError}
              />
            </Fragment>
          ))}
        {!eventsOrCartLoading && events.length === 0 && (
          <ListEmpty text={t("noResults")} />
        )}
        {eventsOrCartLoading && (
          <EventLoading
            hidePrices={hidePrices}
            hidePricesBeforePricingAgreementApplied={
              hidePricesBeforePricingAgreementApplied
            }
            gridColumns={gridColumns}
          />
        )}
        {!eventsOrCartLoading &&
          events &&
          totalRecords > EVENT_PAGINATION_LIMIT && (
            <Pagination
              totalRecords={totalRecords}
              limit={EVENT_PAGINATION_LIMIT}
              offset={offset}
              setOffset={setOffset}
            />
          )}
      </List>
    </Fragment>
  );
};

export const SessionModal: FunctionComponent<{
  onDone: () => void;
  eventId: string | null;
}> = ({ onDone, eventId }) => {
  const gridColumns = "1fr 1fr 1fr";
  const { t } = useTranslation();

  const [offset, setOffset] = useState(0);

  const { loading, data } = useWebLinkQuery<Query>(
    WEBLINK_EVENT_SESSIONS_QUERY,
    {
      variables: {
        eventId: eventId,
        sessionsOffset: offset,
        sessionsFirst: SESSION_PAGINATION_LIMIT,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const event = data?.events?.edges?.[0]?.node;
  const timeZone = event && event.timeZoneName ? event.timeZoneName : undefined;
  const sessions = event?.sessions?.edges || [];
  const totalRecords = event?.sessions?.pageInfo?.totalRecords;
  const hasNextPage = event?.sessions?.pageInfo?.hasNextPage;
  const hasPreviousPage = event?.sessions?.pageInfo?.hasPreviousPage;

  return (
    <Modal
      onDone={onDone}
      title={t("courseSessions")}
      show={true}
      size="large"
      primaryActionText={t("OK")}
    >
      <List>
        <ListHeader
          gridColumns={gridColumns}
          headings={[
            {
              title: t("name"),
            },

            {
              title: t("dates"),
            },
            {
              title: t("location"),
            },
          ]}
          icon
        />

        {loading && <SessionLoading />}
        {!loading && sessions.length < 1 && (
          <ListEmpty text={t("thereAreNoSessionsForThisEvent")} />
        )}
        {!loading &&
          sessions.length >= 1 &&
          sessions.map(({ node: session }) => {
            return (
              <ListRow
                key={session.id}
                gridColumns={gridColumns}
                dataLabel={session.name}
              >
                <ListCell label={t("name")}>{session.name}</ListCell>
                <ListCell label={t("dates")}>
                  <DateRange
                    start={session.start}
                    end={session.end}
                    timezone={timeZone}
                  />
                </ListCell>
                <ListCell label={t("location")}>
                  {session.location &&
                    `${session.location.name}, ${
                      session.location.region && session.location.region.name
                    }`}
                </ListCell>
              </ListRow>
            );
          })}
      </List>
      {sessions.length >= 1 &&
        totalRecords &&
        (hasNextPage || hasPreviousPage) && (
          <Pagination
            totalRecords={totalRecords}
            limit={SESSION_PAGINATION_LIMIT}
            offset={offset}
            setOffset={setOffset}
          />
        )}
    </Modal>
  );
};

const EventLoading: FunctionComponent<{
  hidePrices: boolean;
  hidePricesBeforePricingAgreementApplied: boolean;
  gridColumns: string;
}> = ({ hidePrices, hidePricesBeforePricingAgreementApplied, gridColumns }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {[...Array(3)].map((_, i) => (
        <Fragment key={i}>
          <ListRow
            gridColumns={gridColumns}
            extra={<Placeholder />}
            extraWidth={160}
          >
            <ListCell loading label={t("language")} />
            <ListCell loading label={t("location")} />
            <ListCell loading label={t("dateAndTime")} />
            <ListCell loading label={t("duration")} />
            <ListCell loading label={t("availability")} />
            {!hidePrices && <ListCell loading label={t("currency")} />}
            {!(hidePrices || hidePricesBeforePricingAgreementApplied) && (
              <ListCell loading />
            )}
            <ListCell loading label={t("pass")} />
          </ListRow>
        </Fragment>
      ))}
    </Fragment>
  );
};

const SessionLoading: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {[...Array(3)].map((_, i) => (
        <Fragment key={i}>
          <ListRow gridColumns="1fr 1fr 1fr">
            <ListCell loading label={t("name")} />
            <ListCell loading label={t("dateAndTime")} />
            <ListCell loading label={t("location")} />
          </ListRow>
        </Fragment>
      ))}
    </Fragment>
  );
};

const EventRow: FunctionComponent<{
  event: Event;
  createCartMutationLoading: boolean;
  hidePrices: boolean;
  hidePricesBeforePricingAgreementApplied: boolean;
  gridColumns: string;
  validPassHeld: boolean;
  contentPasses: TrainingPassType[];
  learnerPasses: TrainingPass[];
  mutationRunning: boolean;
  setMutationRunning: (loading: boolean) => void;
  cartlessCheckout?: boolean;
  viewersLastCart: Cart | null;
  viewersCartLoading: boolean;
  addCartLineItem: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<ExecutionResult<any>>;
  createCart: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<ExecutionResult<any>>;
  setApiError: React.Dispatch<React.SetStateAction<boolean>>;
  setPassRegisterError: React.Dispatch<
    React.SetStateAction<Maybe<React.ReactNode>>
  >;
}> = ({
  event,
  createCartMutationLoading,
  hidePrices,
  hidePricesBeforePricingAgreementApplied,
  gridColumns,
  validPassHeld,
  contentPasses,
  learnerPasses,
  mutationRunning,
  setMutationRunning,
  cartlessCheckout,
  viewersLastCart,
  viewersCartLoading,
  addCartLineItem,
  createCart,
  setApiError,
  setPassRegisterError,
}) => {
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);
  const { t } = useTranslation();
  const [financialUnit, setFinancialUnit] =
    useState<string | undefined>(undefined);

  const hasClassroomDates = event.classroomStart || event.classroomEnd;

  return (
    <Fragment>
      <ListRow
        gridColumns={gridColumns}
        extra={
          <BookNowButton
            bookable={event}
            financialUnit={financialUnit}
            hidePrices={hidePrices}
            disabled={mutationRunning || createCartMutationLoading}
            setMutationRunning={setMutationRunning}
            contentPasses={contentPasses}
            cartlessCheckout={cartlessCheckout}
            viewersLastCart={viewersLastCart}
            viewersCartLoading={viewersCartLoading}
            addCartLineItem={addCartLineItem}
            createCart={createCart}
            setApiError={setApiError}
            setPassRegisterError={setPassRegisterError}
          />
        }
        extraWidth={160}
        dataLabel={event.name}
      >
        <ListCell label={t("language")}>
          <p>{event.locale?.language ?? "English"}</p>
        </ListCell>
        <ListCell label={t("learningMode")}>
          <LearningMode event={event} />
        </ListCell>
        <ListCell label={t("location")}>
          {event.location &&
            `${event.location.name}, ${event.location.region?.name}`}
        </ListCell>
        <ListCell
          label={t("dateAndTime")}
          link={
            hasClassroomDates
              ? {
                  onClick: () => {
                    setSelectedEventId(event.id);
                  },
                }
              : undefined
          }
        >
          <EventDateRange event={event} />{" "}
          <span className="sr-only">
            . {t("clickToOpenDialogForCourseSessions")}
          </span>
        </ListCell>
        <ListCell label={t("duration")}>
          <Duration start={event.classroomStart} end={event.classroomEnd} />
        </ListCell>
        <ListCell label={t("availability")}>
          <span className="pr-2 glyphicon-pro glyphicon-pro-user-parents-alt"></span>
          <AvailableSeats bookable={event} />
        </ListCell>

        {!hidePrices && (
          <EventPrice
            prices={event.prices}
            onFinancialUnitSelection={code => setFinancialUnit(code)}
            hidePricesBeforePricingAgreementApplied={
              hidePricesBeforePricingAgreementApplied
            }
            disabled={validPassHeld}
          />
        )}

        <ListCell label={t("pass")} className="pl-2">
          <TrainingPassContentStatusLabel
            courseCardId={event.id}
            learnerPasses={learnerPasses}
            contentPasses={contentPasses}
            hasPricing={(event?.price ?? null) !== null}
          />
        </ListCell>
      </ListRow>
      {selectedEventId && (
        <SessionModal
          onDone={() => setSelectedEventId(null)}
          eventId={selectedEventId}
        />
      )}
    </Fragment>
  );
};

export const EventPrice: FunctionComponent<{
  prices?: Price[];
  onFinancialUnitSelection: (financialUnit?: string) => void;
  hidePricesBeforePricingAgreementApplied: boolean;
  disabled: boolean;
}> = ({
  prices,
  onFinancialUnitSelection,
  hidePricesBeforePricingAgreementApplied,
  disabled,
}) => {
  const { viewer } = useViewer();
  const [activePrice, setActivePrice] = useState<Price>();
  const { t } = useTranslation();

  let currencyOptions: OptionType[] = [];

  let values = useTypedFormValues({
    currency: prices && prices.length === 1 && prices[0].financialUnit?.code,
  });

  if (prices) {
    if (prices?.length > 1) {
      currencyOptions = prices.map(price => ({
        label: price.financialUnit?.code ?? "",
        value: price.financialUnit?.code ?? "",
      }));
    } else if (prices?.length === 1) {
      if (!activePrice) {
        setActivePrice(prices[0]);
        onFinancialUnitSelection(prices[0].financialUnit?.code);
      }
    }
  }

  const handleFinancialUnitChange = (financialUnit: string) => {
    if (financialUnit) {
      const activePrice = prices?.filter(
        price => price.financialUnit?.code === financialUnit,
      );
      if (activePrice) {
        setActivePrice(activePrice && activePrice[0]);
        onFinancialUnitSelection(financialUnit);
      }
    } else {
      setActivePrice(undefined);
      onFinancialUnitSelection(undefined);
    }
  };

  const getPrice = (activePrice?: Price) => {
    if (!activePrice) {
      return "-";
    }

    return getFinancialFormat(
      activePrice.amount,
      activePrice?.financialUnit,
      viewer?.locale,
    );
  };
  return (
    <Fragment>
      <ListCell label={t("currency")}>
        <div className="list__cell--text">
          <Form values={values} disabled={disabled}>
            {prices && (
              <Fragment>
                {prices?.length > 1 && (
                  <div
                    className="d-block"
                    style={{ width: CURRENCY_SELECT_WIDTH }}
                  >
                    <Select
                      disabled={disabled}
                      labelHidden
                      label={t("currency")}
                      name="currency"
                      options={currencyOptions}
                      onChange={(financialUnit: string) =>
                        handleFinancialUnitChange(financialUnit)
                      }
                      isClearable={false}
                    />
                  </div>
                )}
                {prices?.length === 1 && <span>{values.currency}</span>}
              </Fragment>
            )}
          </Form>
        </div>
      </ListCell>
      {!hidePricesBeforePricingAgreementApplied && (
        <ListCell label={t("price")} className="currency">
          <div className="font-weight-bold">
            <BodyText
              disabled={disabled}
              lineThrough={disabled}
              message={getPrice(activePrice)}
            />
          </div>
        </ListCell>
      )}
    </Fragment>
  );
};

const CURRENCY_SELECT_WIDTH = 85;

const getCurrencyColumnWidth = (events: EventEdge[]) => {
  const smallFragmentWidth = "0.1fr";
  const anEventHasMultiplePrices = events.some(
    ({ node }) => node.prices.length > 1,
  );

  return `minmax(${
    anEventHasMultiplePrices
      ? `${CURRENCY_SELECT_WIDTH + 5}px, 0.7fr`
      : `45px, ${smallFragmentWidth}`
  })`;
};
