import React, { useState, useEffect, useMemo } from "react";
import { Table, Pagination, Button } from "@administrate/piston-ux";
import { HeaderConfig } from "@administrate/piston-ux/lib/Table";
import { range, get } from "lodash";

import { LMS_EVENTS_FOR_COURSE_QUERY } from "../queries/events";
import { useDateFormatter } from "../hooks/useDateFormatter";
import { EventsResponse } from "../types/Event";
import { useHistory } from "../useHistory";
import { useLazyLmsQuery } from "../hooks/lms";
import { useTranslation } from "react-i18next";

type EventsTableProps = {
  courseCode?: string;
};

type EventsTableData = {
  id: string;
  locationId: string;
  location: string | JSX.Element;
  start: string | JSX.Element;
  end: string | JSX.Element;
  places: string | number | JSX.Element;
};

const eventsTablePlaceholder: EventsTableData[] = range(5).map(
  (i: number) =>
    ({
      id: `${i}`,
      locationId: `${i}`,
      location: <span className="placeholder" />,
      start: <span className="placeholder" />,
      end: <span className="placeholder" />,
      places: <span className="placeholder" />,
      request: <span className="placeholder" />,
    } as EventsTableData),
);

export const EventsTable: React.FunctionComponent<EventsTableProps> = ({
  courseCode,
}) => {
  const { t } = useTranslation();
  const [eventsOffset, setEventsOffset] = useState(0);
  const eventTablePagination = 5;

  const [
    loadEvents,
    {
      called: eventsQueryIsCalled,
      loading: eventsAreLoading,
      data: eventsResponse,
      refetch: refetchEvents,
    },
  ] = useLazyLmsQuery<EventsResponse>(LMS_EVENTS_FOR_COURSE_QUERY, {
    variables: {
      courseCode,
      first: eventTablePagination,
      offset: 0,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    if (courseCode) {
      loadEvents();
    }
  }, [courseCode, loadEvents]);

  const { dateFormat } = useDateFormatter({ showTime: true });

  const history = useHistory();
  const eventsTableData: EventsTableData[] = useMemo(() => {
    const edges = eventsResponse?.events?.edges;
    if (!edges) return [];

    return edges.map(({ node: event }) => {
      const { id, start, end, location, timeZoneName } = event;
      return {
        id,
        locationId: location?.id || "",
        location: location?.name || "",
        start: start
          ? dateFormat({
              date: start,
              ...(event.deliveryMethod === "classroom"
                ? { customTimeZoneName: timeZoneName }
                : {}),
            })
          : `${t("unknown")}`,
        end: end
          ? dateFormat({
              date: end,
              ...(event.deliveryMethod === "classroom"
                ? { customTimeZoneName: timeZoneName }
                : {}),
            })
          : `${t("unknown")}`,
        places: event.remainingPlaces || `${t("unlimited")}`,
        request: (
          <Button
            block
            type="primary"
            onClick={() =>
              id && history.push(`/my-requests/${id}/training-request`)
            }
            label={t("request")}
          />
        ),
      };
    });
  }, [eventsResponse, dateFormat, history, t]);
  const totalEventRecords = get(eventsResponse, "events.pageInfo.totalRecords");

  return (
    <>
      <div className="event-table">
        <Table
          headings={
            [
              {
                title: t("location"),
                key: "location",
              },
              {
                title: t("startDate"),
                key: "start",
              },
              { title: t("endDate"), key: "end" },
              { title: t("places"), key: "places" },
              { title: "", key: "request" },
            ] as HeaderConfig<EventsTableData>[]
          }
          onSelect={v => history.push(`/my-requests/${v.id}/training-request`)}
          data={
            !eventsQueryIsCalled || eventsAreLoading
              ? eventsTablePlaceholder
              : eventsTableData
          }
        />
      </div>
      {eventsQueryIsCalled && !eventsAreLoading && !eventsTableData.length && (
        <div className="event-table-empty">
          {t("thereAreNoUpcomingEventsForThisCourse")}
        </div>
      )}
      <Pagination
        limit={eventTablePagination}
        offset={eventsOffset}
        totalRecords={totalEventRecords}
        setOffset={newOffset => {
          refetchEvents({
            courseCode,
            first: eventTablePagination,
            offset: newOffset,
          });
          setEventsOffset(newOffset);
        }}
      />
    </>
  );
};
