import React, { useRef, useEffect } from "react";

export const ScrollTrigger: React.FunctionComponent<{
  onEnter: () => void;
}> = ({ onEnter }) => {
  const nodeRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (!nodeRef.current) return;

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      const ref = entries[0];
      if (!ref.isIntersecting) return;
      onEnter();
    };

    const observer = new IntersectionObserver(
      entries => handleIntersection(entries),
      {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      },
    );
    observer.observe(nodeRef.current);

    return () => {
      observer.disconnect();
    };
  }, [onEnter]);

  return <span ref={nodeRef} />;
};
