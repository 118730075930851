import React, {
  FunctionComponent,
  Fragment,
  useState,
  useMemo,
  memo,
  FC,
} from "react";
import {
  List,
  ListRow,
  ListCell,
  Card,
  Pagination,
  ListDrawer,
  StatusLabel,
  Button,
  Tooltip,
} from "@administrate/piston-ux";
import { ListEmpty, ListHeader } from "@administrate/piston-ux/lib/List";
import { ColorOptions } from "@administrate/piston-ux/lib/types";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

import { useWebLinkQuery } from "../../hooks/weblink";
import { EventType } from "../../utils/eventHelpers";
import {
  WEBLINK_COURSE_BOOKINGS_QUERY,
  WEBLINK_COURSE_BOOKING_LEARNER_QUERY,
} from "../../queries/bookings";
import { useDateFormatter } from "../../hooks/useDateFormatter";
import {
  Query,
  EventLearner,
  EventField,
  OrderDirection,
  LocationField,
  FilterOperation,
  EventBooking,
} from "../../generated/weblinkTypes";
import { WEBLINK_BASIC_LOCATIONS_QUERY } from "../../queries/filters";
import { extractNodes } from "../../utils/extractNodes";

import { CourseBookingExport } from "../../components/CourseBookingExport";
import { useHistory } from "../../useHistory";
import { BookingsArticleHeader } from "./BookingsArticleHeader";
import { useViewer } from "../../providers/ViewerProvider";
import {
  startOfDayWithTimeZone,
  endOfDayWithTimeZone,
  formatDateRange,
} from "../../utils/dateTimeHelpers";
import { useWebLinkClient } from "../../hooks/weblink";
import { LearnerManagementPage } from "./LearnerManagementPage";
import moment from "moment";
import { CancelLearnerModal, LearnerCategory } from "./CancelLearnerModal";
import { OptionsMenu } from "../../components/OptionsMenu";
import { TransferLearnerModal } from "./TransferLearnerModal";
import { getLearnerName } from "../../utils/displayHelpers";
import {
  useLearnerManagementBookingFilters,
  LearnerManagementBookingFilter,
} from "./BookingFilterContext";

const gridColumns = "130px 4fr 2fr 3fr 1fr";

export const Bookings: FunctionComponent = () => {
  const { t } = useTranslation();
  const [eventsOffset, setEventsOffset] = useState(0);

  const { filters } = useLearnerManagementBookingFilters();

  const bookingPaginationLimit = 10;
  const { dateFormat } = useDateFormatter({ showTime: true });
  const { viewer } = useViewer();
  const viewerTimeZone = viewer?.timeZoneName ?? moment.tz.guess();

  const courseFilters = getCourseFilters(filters, viewerTimeZone);

  const courseQueryFilters = useMemo(() => {
    const hasCourseStartFilter =
      courseFilters.find(filter => filter.field === EventField.Start) !==
      undefined;

    return hasCourseStartFilter
      ? [...courseFilters]
      : [
          ...courseFilters,
          {
            field: EventField.IsActive,
            operation: FilterOperation.Eq,
            value: "true",
          },
        ];
  }, [courseFilters]);

  const {
    loading: areCourseBookingsLoading,
    data: courseBookingsResponse,
    refetch: refetchCourseBookings,
  } = useWebLinkQuery<Query>(WEBLINK_COURSE_BOOKINGS_QUERY, {
    variables: {
      offset: 0,
      filters: courseQueryFilters,
      order: {
        direction: OrderDirection.Asc,
        field: EventField.Start,
      },
      first: bookingPaginationLimit,
    },
    notifyOnNetworkStatusChange: true,
  });

  const courseBookings =
    courseBookingsResponse?.viewer?.eventBookings?.edges || [];
  const totalCourseBookingRecords = get(
    courseBookingsResponse,
    "viewer.eventBookings.pageInfo.totalRecords",
  );

  const client = useWebLinkClient();

  const locationOptions = (inputValue: string) =>
    client
      .query({
        query: WEBLINK_BASIC_LOCATIONS_QUERY,
        variables: {
          filters: [
            {
              field: LocationField.Name,
              operation: FilterOperation.Like,
              value: `%${inputValue}%`,
            },
          ],
        },
      })
      .then(result => extractNodes(result.data.locations.edges));

  return (
    <LearnerManagementPage tab="bookings">
      <Card>
        <BookingsArticleHeader
          loading={areCourseBookingsLoading}
          locations={locationOptions}
          extra={<CourseBookingExport id="bookings" />}
        />
        <List
          extraClass="bookings-course"
          hover
          loading={areCourseBookingsLoading}
        >
          <ListHeader
            gridColumns={gridColumns}
            headings={[
              {
                title: t("courseType"),
              },
              {
                title: t("course"),
              },
              {
                title: t("location"),
              },
              {
                title: t("dates"),
              },
              {
                title: t("learners"),
              },
            ]}
            icon
          />
          {!areCourseBookingsLoading &&
            courseBookings.map(({ node: courseBooking }, i: number) => {
              const bookingUrl = `/learner-management/bookings/${courseBooking.id}/event`;

              return (
                <Fragment key={i}>
                  <ListRow dataLabel={courseBooking.name}>
                    <Link to={bookingUrl}>
                      <ListRow gridColumns={gridColumns} arrow>
                        <ListCell label={t("courseType")}>
                          <EventType type={courseBooking.type} />
                        </ListCell>
                        <ListCell label={t("title")}>
                          {courseBooking.name}
                        </ListCell>
                        <ListCell label={t("location")}>
                          {courseBooking.location &&
                            courseBooking.location.name}
                        </ListCell>
                        <ListCell label={t("date")}>
                          {courseBooking.start && courseBooking.end
                            ? formatDateRange(
                                courseBooking.start,
                                courseBooking.end,
                                dateFormat,
                              )
                            : t("openEnrollment")}
                        </ListCell>
                        <ListCell label={t("learners")}>
                          {courseBooking.namedLearnerQuantity +
                            courseBooking.unnamedLearnerQuantity}
                          {courseBooking.unnamedLearnerQuantity >= 1 && (
                            <span className="ml-2">
                              (
                              <Tooltip
                                tooltipId={courseBooking.id}
                                content={t("numberOfUnnamedLearners")}
                              >
                                {courseBooking.unnamedLearnerQuantity}
                              </Tooltip>
                              )
                            </span>
                          )}
                        </ListCell>
                      </ListRow>
                    </Link>

                    <ListDrawer title={t("showLearners")} open={false}>
                      <LearnersOnBooking
                        bookingUrl={bookingUrl}
                        booking={courseBooking}
                        onTransferLearner={refetchCourseBookings}
                      />
                    </ListDrawer>
                  </ListRow>
                </Fragment>
              );
            })}
          {!areCourseBookingsLoading && courseBookings.length === 0 && (
            <ListEmpty text={t("noCourseBookingsFound")} />
          )}
          {areCourseBookingsLoading && <CourseBookingsLoading />}
          {!areCourseBookingsLoading &&
            totalCourseBookingRecords > bookingPaginationLimit && (
              <Pagination
                limit={bookingPaginationLimit}
                offset={eventsOffset}
                totalRecords={totalCourseBookingRecords}
                setOffset={newOffset => {
                  refetchCourseBookings({
                    first: bookingPaginationLimit,
                    offset: newOffset,
                  });
                  setEventsOffset(newOffset);
                }}
              />
            )}
        </List>
      </Card>
    </LearnerManagementPage>
  );
};

const CourseBookingsLoading: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {[...Array(3)].map((_, i) => (
        <Fragment key={i}>
          <ListRow gridColumns={`${gridColumns} 140px`}>
            <ListCell label={t("courseType")} loading />
            <ListCell label={t("title")} loading />
            <ListCell label={t("location")} loading />
            <ListCell label={t("dates")} loading />
            <ListCell label={t("learners")} loading />
          </ListRow>
        </Fragment>
      ))}
    </Fragment>
  );
};

const LearnersOnBooking: FunctionComponent<{
  bookingUrl: string;
  booking: EventBooking;
  onTransferLearner: () => void;
}> = ({ bookingUrl, booking, onTransferLearner }) => {
  const [cancellingLearnerId, setCancellingLearnerId] =
    useState<string | null>(null);

  const [transferringLearnerId, setTransferringLearnerId] =
    useState<string | null>(null);

  const { t } = useTranslation();

  const gridColumns = "2fr 2fr 2fr 2fr 1fr";

  const history = useHistory();

  const noOfLearners = 20;

  const {
    loading,
    data: learnersResponse,
    refetch: refetchLearners,
  } = useWebLinkQuery<Query>(WEBLINK_COURSE_BOOKING_LEARNER_QUERY, {
    variables: {
      filters: [
        {
          field: EventField.Id,
          operation: FilterOperation.Eq,
          value: booking.id,
        },
      ],
      noOfLearners,
      learnerOffset: 0,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const learnersConnection =
    learnersResponse?.viewer?.eventBookings.edges[0].node?.learners;

  const learners = learnersConnection?.edges || [];

  return (
    <Fragment>
      <List hover loading={loading}>
        <ListHeader
          gridColumns={gridColumns}
          headings={[
            {
              title: t("name"),
            },
            {
              title: t("account"),
            },
            {
              title: t("registration"),
            },
            {
              title: t("learningPath"),
            },
          ]}
        />
        {!loading &&
          learners.map(({ node: learner }) => {
            const eventLearner = learner as EventLearner;

            return (
              <ListRow
                key={learner.id}
                gridColumns={gridColumns}
                disabled={learner.isCancelled}
                dataLabel={getLearnerName(eventLearner)}
                extra={
                  <Fragment>
                    <OptionsMenu
                      optionsMenuItems={[
                        {
                          label: t("transferLearner"),
                          onClick: () => {
                            setTransferringLearnerId(learner.id);
                          },
                          disabled: learner.isCancelled,
                        },
                        {
                          label: t("cancelLearner"),
                          onClick: () => setCancellingLearnerId(learner.id),
                          disabled: learner.isCancelled,
                        },
                      ]}
                    />
                  </Fragment>
                }
              >
                <LearnerNameCell learner={eventLearner} />
                <LearnerAccountCell learner={eventLearner} />
                <LearnerRegistrationCell learner={eventLearner} />
                <LearnerPathCell learner={eventLearner} />
                {learner.isCancelled && (
                  <CancelledLabel learner={eventLearner} />
                )}
              </ListRow>
            );
          })}
        {!loading && learners.length === 0 && (
          <ListRow gridColumns="auto">
            <ListEmpty text={t("noLearners")} />
          </ListRow>
        )}
        {!loading && learnersConnection?.pageInfo.hasNextPage && (
          <div style={{ textAlign: "center" }}>
            <Button
              label={t("showAllLearners")}
              onClick={() => history.push(bookingUrl)}
              type="suppressed"
            />
          </div>
        )}
        {loading && (
          <Fragment>
            {[...Array(3)].map((_, i) => (
              <Fragment key={i}>
                <ListRow gridColumns={gridColumns}>
                  <ListCell label={t("name")} loading />
                  <ListCell label={t("account")} loading />
                  <ListCell label={t("registration")} loading />
                  <ListCell label={t("learningPath")} loading />
                </ListRow>
              </Fragment>
            ))}
          </Fragment>
        )}
      </List>
      {!!cancellingLearnerId && (
        <CancelLearnerModal
          onModalClose={submitted => {
            if (submitted) {
              refetchLearners();
            }
            setCancellingLearnerId(null);
          }}
          learnerId={cancellingLearnerId}
          learnerCategory={LearnerCategory.Event}
        />
      )}
      {!!transferringLearnerId && (
        <TransferLearnerModal
          onModalClose={submitted => {
            if (submitted) {
              onTransferLearner();
            }
            setTransferringLearnerId(null);
          }}
          learnerId={transferringLearnerId}
          bookingId={booking.id}
        />
      )}
    </Fragment>
  );
};

const getCourseFilters = (
  affectBarFilters: LearnerManagementBookingFilter,
  timeZoneName: string,
) => {
  const filters = [];
  if (affectBarFilters.search) {
    filters.push({
      value: `%${affectBarFilters.search}%`,
      operation: FilterOperation.Like,
      field: EventField.Name,
    });
  }
  if (affectBarFilters.location) {
    filters.push({
      value: `${affectBarFilters.location.name}`,
      operation: FilterOperation.Eq,
      field: EventField.LocationName,
    });
  }
  if (affectBarFilters.courseType) {
    filters.push({
      value: affectBarFilters.courseType,
      operation: FilterOperation.Eq,
      field: EventField.Type,
    });
  }
  if (affectBarFilters.fromDate) {
    filters.push({
      value: startOfDayWithTimeZone(affectBarFilters.fromDate, timeZoneName),
      operation: FilterOperation.Gt,
      field: EventField.Start,
    });

    if (affectBarFilters.toDate) {
      filters.push({
        value: endOfDayWithTimeZone(affectBarFilters.toDate, timeZoneName),
        operation: FilterOperation.Lt,
        field: EventField.End,
      });
    }
  }

  return filters;
};

type LearnerProp = {
  learner: EventLearner;
};

const LearnerNameCell: FC<LearnerProp> = memo(({ learner }) => {
  const { t } = useTranslation();
  if (learner.isUnnamed) {
    return (
      <ListCell label={t("name")}>
        {`${t("unnamedLearner")} x ${learner.quantity}`}
      </ListCell>
    );
  }

  if (learner.contact && learner.contact.personalName) {
    return (
      <ListCell label={t("name")}>{generateLinkToLearner(learner)}</ListCell>
    );
  }

  return <ListCell label={t("name")}>-</ListCell>;
});

const LearnerAccountCell: FC<LearnerProp> = memo(({ learner }) => {
  const { t } = useTranslation();
  if (learner.isUnnamed || !learner.contact?.account) {
    return <ListCell label={t("account")}>-</ListCell>;
  }

  return (
    <ListCell label={t("account")}>
      <Fragment>{learner.contact.account.name}</Fragment>
    </ListCell>
  );
});

const LearnerRegistrationCell: FC<LearnerProp> = memo(({ learner }) => {
  const { t } = useTranslation();
  return learner.registration ? (
    <ListCell label={t("registration")}>
      {learner.registration.registrationNumber}
    </ListCell>
  ) : (
    <ListCell label={t("registration")}>-</ListCell>
  );
});

const LearnerPathCell: FC<LearnerProp> = memo(({ learner }) => {
  const { t } = useTranslation();
  if (!learner.learningPath) {
    return <ListCell label={t("learningPath")}>-</ListCell>;
  }

  return (
    <ListCell label={t("learningPath")}>
      <Link
        className="text-hover-underline"
        to={`/learner-management/bookings/${learner.learningPath.id}/learning-path`}
      >
        {learner.learningPath.name}
      </Link>
    </ListCell>
  );
});

const CancelledLabel: FC<LearnerProp> = memo(({ learner }) => {
  const { t } = useTranslation();
  return (
    <ListCell label={t("status")}>
      <StatusLabel color={ColorOptions.LightGrey} text="Cancelled" />
    </ListCell>
  );
});

export const generateLinkToLearner = (learner: EventLearner) => {
  if (learner.contact && learner.contact.personalName) {
    const name = learner.contact.personalName;
    return (
      <Link
        to={`/learner-management/learners/${learner.contact.id}`}
        className="text-hover-underline"
      >
        {name.firstName} {name.lastName}
      </Link>
    );
  }
  return "";
};
