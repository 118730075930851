import { useWebLinkQuery } from "./weblink";
import {
  Store,
  Stripe,
  Converge,
  Invoice,
  Maybe,
  Term,
  TrainingToken,
} from "../generated/weblinkTypes";
import { WEBLINK_STORE_ATTRIBUTES_QUERY } from "../queries/courses";

export const PROD_CONVERGE_JS_URL =
  "https://api.convergepay.com/hosted-payments/Checkout.js";
export const TESTING_CONVERGE_JS_URL =
  "https://api.demo.convergepay.com/hosted-payments/Checkout.js";
export const PROD_CONVERGE_3DS_URL =
  "https://libs.fraud.elavongateway.com/sdk-web-js/1.0.5/3ds2-web-sdk.min.js";
export const TESTING_CONVERGE_3DS_URL =
  "https://dev.libs.fraud.eu.elavonaws.com/0.9.9/3ds2-web-sdk.min.js";
export const PROD_CONVERGE_EFS_URL = "https://gw.fraud.elavongateway.com/3ds2";
export const TESTING_CONVERGE_EFS_URL =
  "https://uat.gw.fraud.eu.elavonaws.com/3ds2";

export type WeblinkPaymentMethodUnion =
  | Stripe
  | Converge
  | Invoice
  | TrainingToken
  | undefined;

export type WeblinkSettingsType = {
  hidePricesBeforePricingAgreementApplied: boolean;
  hidePrices: boolean;
  canSelfCheckoutByInvoice: boolean;
  stripePaymentMethod: WeblinkPaymentMethodUnion;
  convergePaymentMethod: WeblinkPaymentMethodUnion;
  invoicePaymentMethod: WeblinkPaymentMethodUnion;
  trainingTokensPaymentMethod: WeblinkPaymentMethodUnion;
  terms?: Maybe<Array<Maybe<Term>>>;
  hasBookerInformation: boolean;
  convergeJSUrl?: string;
  converge3DSUrl?: string;
  convergeEFSUrl?: string;
  loading: boolean;
  trainingRequestWorkflowEnabled: boolean;
  cartlessCheckout: boolean;
  requireBookerBillingAddress: boolean;
  currencyCode: Maybe<string>;
  regionCode: Maybe<string>;
  regionId: Maybe<string>;
};

export const useWeblinkSettings = (): WeblinkSettingsType => {
  const { data: storeResponse, loading } = useWebLinkQuery<{ store: Store }>(
    WEBLINK_STORE_ATTRIBUTES_QUERY,
  );

  const paymentMethods: WeblinkPaymentMethodUnion[] | undefined =
    storeResponse?.store.paymentMethods;

  const stripePaymentMethod = paymentMethods?.find(
    paymentMethod => paymentMethod?.__typename === "Stripe",
  );
  const convergePaymentMethod = paymentMethods?.find(
    paymentMethod => paymentMethod?.__typename === "Converge",
  );
  const invoicePaymentMethod = paymentMethods?.find(
    paymentMethod => paymentMethod?.__typename === "Invoice",
  );
  const trainingTokensPaymentMethod = paymentMethods?.find(
    paymentMethod => paymentMethod?.__typename === "TrainingToken",
  );

  const terms = storeResponse?.store.terms;
  const hasBookerInformation = (terms && terms.length > 0) ?? false;

  const hidePricesBeforePricingAgreementApplied =
    storeResponse?.store?.hidePricesBeforePricingAgreementApplied ?? false;
  const hidePrices = storeResponse?.store?.hidePrices ?? false;
  const canSelfCheckoutByInvoice =
    storeResponse?.store?.canSelfCheckoutByInvoice ?? false;

  let convergeJSUrl = undefined;
  let converge3DSUrl = undefined;
  let convergeEFSUrl = undefined;
  if (convergePaymentMethod !== undefined) {
    convergeJSUrl = (convergePaymentMethod as Converge).isLive
      ? PROD_CONVERGE_JS_URL
      : TESTING_CONVERGE_JS_URL;
    converge3DSUrl = (convergePaymentMethod as Converge).isLive
      ? PROD_CONVERGE_3DS_URL
      : TESTING_CONVERGE_3DS_URL;
    convergeEFSUrl = (convergePaymentMethod as Converge).isLive
      ? PROD_CONVERGE_EFS_URL
      : TESTING_CONVERGE_EFS_URL;
  }

  const trainingRequestWorkflowEnabled =
    storeResponse?.store.trainingRequestWorkflow?.enabled ?? false;

  const cartlessCheckout = storeResponse?.store?.cartlessCheckout ?? false;

  const requireBookerBillingAddress =
    storeResponse?.store?.requireBookerBillingAddress ?? false;

  const currencyCode = storeResponse?.store?.currency?.code ?? null;
  const regionCode = storeResponse?.store?.region?.code ?? null;
  const regionId = storeResponse?.store?.region?.id ?? null;

  return {
    hidePrices,
    hidePricesBeforePricingAgreementApplied,
    stripePaymentMethod,
    convergePaymentMethod,
    invoicePaymentMethod,
    trainingTokensPaymentMethod,
    terms,
    hasBookerInformation,
    convergeJSUrl,
    converge3DSUrl,
    convergeEFSUrl,
    loading,
    trainingRequestWorkflowEnabled,
    cartlessCheckout,
    canSelfCheckoutByInvoice,
    requireBookerBillingAddress,
    currencyCode,
    regionCode,
    regionId,
  };
};
