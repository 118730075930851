import gql from "graphql-tag";

export const POINT_OF_SALE_ORDER_FIELDS = gql`
  query getPointOfSaleOrderFields {
    pointOfSaleOrderFields {
      key
      label
      isRequired
      type
      options {
        label
        value
      }
      section
    }
  }
`;
