import React, {
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
  useMemo,
  Fragment,
} from "react";
import { get } from "lodash";
import {
  Card,
  Submit,
  Form,
  useFormValues,
  AlertModal,
  CustomFieldInput,
  RadioButtons,
  Alert,
  FormActions,
  FutureArticleHeader,
  Row,
  Col,
  StaticInput,
  FormStateStatus,
  useDetailFormState,
  ValidationErrors,
  Placeholder,
  SaveStates,
} from "@administrate/piston-ux";
import { CustomFieldType } from "@administrate/piston-ux/lib/CustomFieldInput";
import NavigationPrompt from "react-router-navigation-prompt";
import { has, extendObservable, toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import { ApolloQueryResult } from "apollo-boost";
import { MessageOptions } from "@administrate/piston-ux/lib/utils/FormStateStatus";
import { useFormContext } from "@administrate/piston-ux/lib/Form";

import {
  AccountPicker,
  getAccountVariables,
} from "../../components/AccountPicker";
import { MANAGED_ACCOUNTS_QUERY } from "../../queries/learnerManagement";
import { extractNodes } from "../../utils/extractNodes";
import {
  Account,
  Cart,
  CartMutateResponseType,
  PaymentProviders,
  PlaceOrderInput,
  TrainingToken,
  UpdateCartFinancialUnitInput,
} from "../../generated/weblinkTypes";
import { useHistory } from "../../useHistory";
import { useWebLinkClient } from "../../hooks/weblink";
import {
  PLACE_ORDER_MUTATION,
  APPLY_PRICING_MUTATION,
  PLACE_STRIPE_ORDER,
  CREATE_PAYMENT_SESSION,
  CREATE_STRIPE_PAYMENT_INTENT,
  PLACE_TRAINING_TOKEN_ORDER,
} from "../../queries/cart";
import { useWebLinkMutation } from "../../hooks/weblink";
import { BookerIntention, BookingStep } from "./BookingPage";
import { OrderSummary } from "./OrderSummary";
import { CheckoutURLS } from "../../providers/CheckoutProvider";
import {
  WeblinkPaymentMethodUnion,
  useWeblinkSettings,
} from "../../hooks/useWeblinkSettings";
import { ConvergePaymentInfo } from "./ConvergePaymentInfo";
import { StripePaymentInfo } from "./StripePaymentInfo";
import { checkoutWithConverge } from "./checkoutWithConverge";
import { useViewer } from "../../providers/ViewerProvider";
import { checkoutWithStripe } from "./checkoutWithStripe";
import {
  TrainingTokensPaymentInfo,
  useTotalTokenBalance,
} from "./TrainingTokensPaymentInfo";
import { useUpdateCartFinancialUnit } from "../../hooks/useUpdateCartFinancialUnit";
import { usePreviousValue } from "../../hooks/usePreviousValue";
import { useCartRemovedLineItemsContext } from "../../providers/CartRemovedLineItemsProvider";
import { useCartInvalidLineItemsContext } from "../../providers/CartInvalidLineItemsProvider";
import { CartEvent } from "../../analytics/events";
import { useAnalytics } from "../../providers/AnalyticsProvider";

export type PaymentProviderField = {
  key: string;
  label: string;
  value: string;
  type: CustomFieldType;
  options?: [{ label: string; value: string }];
};

export type PaymentType =
  | "invoice"
  | "stripe"
  | "converge"
  | "trainingToken"
  | null;

export type PaymentProviderOption = {
  label: string;
  value: string;
};

export const BookingDetailForm: FunctionComponent<{
  account?: Account;
  cart?: Cart;
  wonCart?: boolean;
  bookerIntention: BookerIntention;
  usage?: "order" | "checkout";
  refetchViewer: () => Promise<ApolloQueryResult<any>>;
}> = observer(
  ({
    account,
    cart,
    wonCart,
    bookerIntention,
    usage = "order",
    refetchViewer,
  }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const stripe = useStripe();
    const elements = useElements();
    const weblinkSettings = useWeblinkSettings();
    const [hasAccount, setHasAccount] = useState(!!account);
    const [hasInsufficientFunds, setHasInsufficientFunds] = useState(false);
    const { isViewerLoading, viewer } = useViewer();
    const { messages, saveState, setSaveState, setMessages } =
      useDetailFormState();
    const { captureEvent } = useAnalytics();

    const values = useFormValues({
      account: account || null,
      paymentType: null,
      address1: cart?.buyerDetails?.billingAddress?.streetAddress1 ?? "",
      zip: cart?.buyerDetails?.billingAddress?.postcode ?? "",
    });

    const { setRemovedLineItems } = useCartRemovedLineItemsContext();
    const { setInvalidLineItemInfo } = useCartInvalidLineItemsContext();

    const [placeOrder, { loading: placeOrderLoading, error: mutationError }] =
      useWebLinkMutation(PLACE_ORDER_MUTATION);

    const [
      applyPricing,
      { loading: applyPricingLoading, error: mutationPricingError },
    ] = useWebLinkMutation<CartMutateResponseType>(APPLY_PRICING_MUTATION);

    const [createPaymentSession] = useWebLinkMutation(CREATE_PAYMENT_SESSION);

    const [createStripePaymentIntent] = useWebLinkMutation(
      CREATE_STRIPE_PAYMENT_INTENT,
    );

    const [placeStripeOrder] = useWebLinkMutation(PLACE_STRIPE_ORDER);
    const [placeTrainingTokenOrder] = useWebLinkMutation(
      PLACE_TRAINING_TOKEN_ORDER,
    );

    const client = useWebLinkClient();
    const accountOptions = (inputValue: string) =>
      client
        .query({
          query: MANAGED_ACCOUNTS_QUERY,
          variables: {
            filters: [...getAccountVariables(inputValue).filters],
          },
        })
        .then(result => extractNodes(result.data.managedAccounts.edges));

    const paymentMethods = useMemo(
      () => [
        weblinkSettings?.stripePaymentMethod,
        weblinkSettings?.invoicePaymentMethod,
        weblinkSettings?.convergePaymentMethod,
        weblinkSettings?.trainingTokensPaymentMethod,
      ],
      [weblinkSettings],
    );

    /* eslint-disable @typescript-eslint/no-unused-expressions */
    useEffect(() => {
      paymentMethods.forEach(paymentMethod => {
        paymentMethod?.paymentAttributeDefinitions?.forEach(definition => {
          if (!definition) return;

          if (!has(values, definition.key)) {
            extendObservable(values, { [definition.key]: null });
          }
        });
      });
    }, [paymentMethods, values]);
    /* eslint-enable @typescript-eslint/no-unused-expressions */

    // Turn the paymentType from the formValues (Observable) into a "real" JavaScript
    // value that React can check to see has changed
    const activePaymentType = toJS(values).paymentType;

    const paymentProviderFields = useMemo(() => {
      const getPaymentProviderFieldsFor = (
        paymentType: "Invoice" | "Stripe" | "Converge" | "TrainingToken",
      ) => {
        const paymentMethod = paymentMethods?.find(
          paymentMethod => paymentMethod?.__typename === paymentType,
        );
        return (paymentMethod?.paymentAttributeDefinitions ??
          []) as PaymentProviderField[];
      };

      switch (activePaymentType) {
        case "invoice":
          return getPaymentProviderFieldsFor("Invoice");
        case "stripe":
          return getPaymentProviderFieldsFor("Stripe");
        case "converge":
          return getPaymentProviderFieldsFor("Converge");
        case "trainingToken":
          return getPaymentProviderFieldsFor("TrainingToken");
        default:
          return [];
      }
    }, [activePaymentType, paymentMethods]);

    const completeCheckout = async () => {
      setSaveState("saved");
      setMessages({});
      setRemovedLineItems([]);
      setInvalidLineItemInfo({});

      captureEvent(
        CartEvent.fromCheckoutComplete(cart as Cart, activePaymentType),
      );

      if (usage === "order") {
        history.push(
          `/catalog/${bookerIntention}/booking/${cart?.id}/confirmation`,
        );
      }
      if (usage === "checkout") {
        history.push(`/checkout/${CheckoutURLS.OrderComplete}`);
      }
    };

    const checkoutFreeOrder = () =>
      executePlaceOrder({
        cartId: cart?.id,
      });

    const handleSubmit = async () => {
      if (!values.account) {
        return;
      }
      setSaveState("saving");
      captureEvent(
        CartEvent.fromPaymentDetailsSubmitted(cart as Cart, activePaymentType),
      );

      const paymentAttributes = paymentProviderFields
        .filter(field => values[field.key] !== null)
        .map(field => ({
          definitionKey: field.key,
          value: values[field.key],
        }));

      if (values.paymentType === "converge") {
        return checkoutWithConverge(
          values,
          convergePaymentProvider,
          cart!,
          createPaymentSession,
          executePlaceOrder,
          {
            cartId: cart?.id,
            paymentAttributes,
            paymentProvider: PaymentProviders.Converge,
            invoiceDetails: undefined,
            stripeDetails: undefined,
          },
          t,
          setMessages,
          setSaveState,
          weblinkSettings.convergeEFSUrl,
        );
      }

      if (values.paymentType === "invoice") {
        const invoiceDetails =
          bookerIntention === BookerIntention.Coordinating
            ? { billingAccountId: values.account.id }
            : { billingEmail: viewer?.emailAddress };

        return executePlaceOrder({
          cartId: cart?.id,
          invoiceDetails,
          paymentAttributes,
          paymentProvider: PaymentProviders.Invoice,
        });
      }

      if (values.paymentType === "stripe" && stripe && elements) {
        const billingAccountId =
          bookerIntention === BookerIntention.Coordinating
            ? values.account.id
            : null;

        if (!cart?.id) throw new Error("No cart found");

        return checkoutWithStripe({
          stripe,
          elements,
          cartId: cart?.id,
          billingAccountId,
          buyerBillingAddress: cart?.buyerDetails?.billingAddress ?? undefined,
          setMessages,
          setSaveState,
          createStripePaymentIntent,
          placeStripeOrder,
          completeCheckout,
          t,
        });
      }

      if (values.paymentType === "trainingToken") {
        const placeOrderResponse = await placeTrainingTokenOrder({
          variables: {
            input: {
              cartId: cart?.id,
            },
          },
        });

        const errors =
          placeOrderResponse.data?.trainingToken.placeOrder.errors || [];

        if (errors.length) {
          // TODO: Actual error message from placing Token Order
          setMessages({
            failed: t("placeOrderErrorRestartBooking"),
          });
          return setSaveState("errors");
        } else {
          return completeCheckout();
        }
      }

      return checkoutFreeOrder();
    };

    const executePlaceOrder = async (input: PlaceOrderInput) => {
      try {
        const response = await placeOrder({
          variables: {
            input: input,
          },
        });

        const mutationValidationErrors = get(
          response,
          "data.cart.placeOrder.errors",
          null,
        );

        if (!placeOrderLoading) {
          if (mutationError) {
            setMessages({
              failed: t("placeOrderErrorRestartBooking"),
            });
          } else if (
            mutationValidationErrors &&
            mutationValidationErrors.length > 0
          ) {
            setMessages({
              errors: (
                <ValidationErrors
                  title={t("Error while placing your order")}
                  mutationValidationErrors={mutationValidationErrors}
                />
              ),
            });
          } else {
            completeCheckout();
          }
        }
      } catch (e) {
        console.error(e);
        setMessages({
          failed: t("placeOrderErrorRestartBooking"),
        });
      }
    };

    const invoicePaymentProvider = weblinkSettings?.invoicePaymentMethod;
    const stripePaymentProvider = weblinkSettings?.stripePaymentMethod;
    const convergePaymentProvider = weblinkSettings?.convergePaymentMethod;
    const trainingTokensPaymentProvider =
      weblinkSettings?.trainingTokensPaymentMethod;

    const noPaymentProvidersConfigured = !(
      invoicePaymentProvider ||
      stripePaymentProvider ||
      convergePaymentProvider ||
      trainingTokensPaymentProvider
    );

    const isInvoiceCheckoutPermitted = useCallback(
      (account: Account | null) => {
        return (
          (weblinkSettings.canSelfCheckoutByInvoice ||
            account?.canSelfCheckoutByInvoice) ??
          false
        );
      },
      [weblinkSettings],
    );

    const canCheckoutByInvoice =
      (invoicePaymentProvider && isInvoiceCheckoutPermitted(values.account)) ??
      false;

    const cartHasItems = (cart?.items || []).length > 0;
    const isFreeOrder = cartHasItems && Number(cart?.price?.grandTotal) === 0;

    const hasAvailablePaymentProvider =
      canCheckoutByInvoice !== undefined ||
      stripePaymentProvider !== undefined ||
      convergePaymentProvider !== undefined ||
      trainingTokensPaymentProvider !== undefined;

    const showPaymentForm =
      values.account && !isFreeOrder && hasAvailablePaymentProvider;

    const getDefaultPaymentType = useCallback(
      (account: Account) => {
        if (cart?.tokenType) return "trainingToken";
        if (isFreeOrder) return null;
        if (invoicePaymentProvider && isInvoiceCheckoutPermitted(account))
          return "invoice";
        if (stripePaymentProvider) return "stripe";
        if (convergePaymentProvider) return "converge";
        if (trainingTokensPaymentProvider) return "trainingToken";
        return null;
      },
      [
        cart,
        invoicePaymentProvider,
        stripePaymentProvider,
        convergePaymentProvider,
        trainingTokensPaymentProvider,
        isFreeOrder,
        isInvoiceCheckoutPermitted,
      ],
    );

    const handleChangeAccount = (account: Account) => {
      setHasAccount(false);
      if (!account) {
        // remove previously chosen paymentType too
        values.paymentType = null;
        return;
      }
      values.paymentType = getDefaultPaymentType(account);
      setSaveState("saving");
      fetchPricingAgreement(account);
    };

    const fetchPricingAgreement = useCallback(
      async (account: Account) => {
        setSaveState("saving");
        const response = await applyPricing({
          variables: {
            input: {
              cartId: cart?.id,
              accountId: account.id,
            },
          },
        });
        const mutationValidationErrors = get(
          response,
          "data.cart.applyPricing.errors",
          null,
        );

        if (mutationPricingError) {
          setMessages({ failed: t("Error while applying pricing to cart") });
        } else if (
          mutationValidationErrors &&
          mutationValidationErrors.length > 0
        ) {
          setMessages({
            errors: (
              <ValidationErrors
                title={t("Error while applying pricing to cart")}
                mutationValidationErrors={mutationValidationErrors}
              />
            ),
          });
        } else {
          await refetchViewer();
          setHasAccount(true);
          setSaveState("saved");
        }
      },
      [
        applyPricing,
        cart?.id,
        mutationPricingError,
        refetchViewer,
        setMessages,
        setSaveState,
        setHasAccount,
        t,
      ],
    );

    const { totalBalance: totalTokenBalance } = useTotalTokenBalance(cart);

    useEffect(() => {
      if (values.paymentType === "trainingToken" && cart?.tokenType !== null) {
        if (totalTokenBalance < Number(cart?.price?.grandTotal)) {
          setHasInsufficientFunds(true);
        } else {
          setHasInsufficientFunds(false);
        }
      }
      if (
        trainingTokensPaymentProvider &&
        values.paymentType !== "trainingToken"
      ) {
        setHasInsufficientFunds(false);
      }
    }, [
      refetchViewer,
      setHasInsufficientFunds,
      cart?.price?.grandTotal,
      cart?.tokenType,
      totalTokenBalance,
      values.paymentType,
      trainingTokensPaymentProvider,
    ]);

    const cartPricedInTokens = !!cart?.tokenType;
    useEffect(() => {
      if (
        account &&
        bookerIntention === BookerIntention.Coordinating &&
        !cartPricedInTokens
      ) {
        fetchPricingAgreement(account);
      }
    }, [account, fetchPricingAgreement, bookerIntention, cartPricedInTokens]);

    useEffect(
      () => {
        if (account && !values.paymentType) {
          values.paymentType = getDefaultPaymentType(account);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [account, getDefaultPaymentType, values.paymentType],
    );

    const loading =
      placeOrderLoading ||
      applyPricingLoading ||
      weblinkSettings.loading ||
      isViewerLoading;

    return (
      <Row>
        {usage !== "checkout" && (
          <Col lg={3} lgOffset={1} lgPush={7} md={4} mdPush={8}>
            <OrderSummary
              loading={loading}
              cart={cart}
              hasAccount={hasAccount}
              step={BookingStep.bookingDetails}
              hidePrices={
                !!weblinkSettings?.hidePricesBeforePricingAgreementApplied
              }
              isZeroPriced={false}
            />
          </Col>
        )}
        <Col
          lg={usage !== "checkout" ? 7 : 12}
          lgPull={usage !== "checkout" ? 3 : 0}
          md={usage !== "checkout" ? 8 : 12}
          mdPull={usage !== "checkout" ? 4 : 0}
          className="booking-page"
        >
          {!loading && noPaymentProvidersConfigured && (
            <Alert
              type="warning"
              message={t("noValidPaymentMethodsFoundForPortal")}
              glyph="removeSign"
              overPage={false}
            />
          )}
          <Form
            values={values}
            onSubmit={handleSubmit}
            loading={loading}
            disabled={
              noPaymentProvidersConfigured || loading || saveState === "saving"
            }
          >
            <Card>
              <FutureArticleHeader title={t("bookingDetails")} />
              {saveState !== "saved" && (
                <FormStateStatus messages={messages} saveState={saveState} />
              )}
              <NavigationPrompt
                when={(_, nextLocation) => {
                  return (
                    !wonCart &&
                    !get(nextLocation, "pathname", "").includes(
                      usage === "order"
                        ? "confirmation"
                        : CheckoutURLS.OrderComplete,
                    )
                  );
                }}
              >
                {({ onConfirm, onCancel }) => (
                  <AlertModal
                    title={`${t(
                      "Are you sure you want to leave this page? Your current booking will be lost",
                    )}.`}
                    show
                    onDone={(confirmed: boolean) => {
                      return confirmed ? onConfirm() : onCancel();
                    }}
                    type="warning"
                  />
                )}
              </NavigationPrompt>
              {bookerIntention === BookerIntention.Coordinating && (
                <Fragment>
                  {account ? (
                    <StaticInput
                      name="account"
                      label={t("bookingAccount")}
                      formatter={(account: Account) => account.name}
                    />
                  ) : (
                    <AccountPicker
                      name="account"
                      label={t("bookingAccount")}
                      valid={v => !!v}
                      loadOptions={accountOptions}
                      onChange={handleChangeAccount}
                    />
                  )}
                </Fragment>
              )}
              {showPaymentForm && (
                <BookingDetailPaymentForm
                  paymentType={values.paymentType}
                  applyPricingLoading={applyPricingLoading}
                  canCheckoutByInvoice={canCheckoutByInvoice}
                  stripePaymentProvider={stripePaymentProvider}
                  convergePaymentProvider={convergePaymentProvider}
                  trainingTokensPaymentProvider={
                    trainingTokensPaymentProvider as TrainingToken
                  }
                  paymentProviderFields={paymentProviderFields}
                  isBillingAddressProvided={values && values.zip}
                  cart={cart}
                  refetchViewer={refetchViewer}
                  setSaveState={setSaveState}
                  setMessages={setMessages}
                />
              )}
              {weblinkSettings.loading && (
                <div className="mb-4">
                  <Placeholder />
                </div>
              )}
              <FormActions>
                <Submit
                  label={t("completeBooking")}
                  inline
                  disabled={
                    !hasAvailablePaymentProvider ||
                    !cartHasItems ||
                    hasInsufficientFunds
                  }
                />
              </FormActions>
            </Card>
          </Form>
        </Col>
      </Row>
    );
  },
);

const BookingDetailPaymentForm: FunctionComponent<{
  paymentType: PaymentType;
  applyPricingLoading: boolean;
  canCheckoutByInvoice: boolean;
  stripePaymentProvider?: WeblinkPaymentMethodUnion;
  convergePaymentProvider?: WeblinkPaymentMethodUnion;
  trainingTokensPaymentProvider?: TrainingToken;
  paymentProviderFields: PaymentProviderField[];
  isBillingAddressProvided?: boolean;
  cart?: Cart;
  refetchViewer: () => Promise<ApolloQueryResult<any>>;
  setSaveState: React.Dispatch<React.SetStateAction<SaveStates>>;
  setMessages: React.Dispatch<React.SetStateAction<MessageOptions>>;
}> = observer(
  ({
    paymentType,
    applyPricingLoading,
    canCheckoutByInvoice,
    stripePaymentProvider,
    convergePaymentProvider,
    trainingTokensPaymentProvider,
    paymentProviderFields,
    isBillingAddressProvided,
    cart,
    refetchViewer,
    setSaveState,
    setMessages,
  }) => {
    const { t } = useTranslation();
    const stripe = useStripe();
    const weblinkSettings = useWeblinkSettings();
    const { values } = useFormContext();
    const { updateCartFinancialUnit, loading } = useUpdateCartFinancialUnit();

    const previousPaymentType = usePreviousValue(values.paymentType);

    const paymentProviderOptions: PaymentProviderOption[] = [];
    if (canCheckoutByInvoice) {
      paymentProviderOptions.push({ label: t("invoice"), value: "invoice" });
    }
    if (stripePaymentProvider || convergePaymentProvider) {
      paymentProviderOptions.push({
        label: t("card"),
        value: convergePaymentProvider ? "converge" : "stripe",
      });
    }
    if (trainingTokensPaymentProvider) {
      paymentProviderOptions.push({
        label: t("trainingTokens"),
        value: "trainingToken",
      });
    }

    const tokenType = trainingTokensPaymentProvider?.tokenTypes[0];

    const handleUpdateFinancialUnit = useCallback(
      async (financialUnitId: string) => {
        const input: UpdateCartFinancialUnitInput = {
          cartId: cart?.id || "",
          financialUnitId: financialUnitId,
        };

        const updateFinancialUnitResponse = await updateCartFinancialUnit({
          variables: {
            input,
          },
        });

        const errors =
          updateFinancialUnitResponse.data?.cart?.updateFinancialUnit.errors ||
          [];

        if (errors.length) {
          setMessages({
            errors: t("switchFinancialUnitError"),
          });
          setSaveState("errors");

          if (previousPaymentType) {
            values.paymentType = previousPaymentType;
          }
        }

        await refetchViewer();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        cart?.id,
        refetchViewer,
        setMessages,
        setSaveState,
        t,
        updateCartFinancialUnit,
        values.paymentType,
      ],
    );

    useEffect(() => {
      if (
        values.paymentType === "trainingToken" &&
        !cart?.tokenType &&
        tokenType
      ) {
        handleUpdateFinancialUnit(tokenType.id);
      }

      if (
        values.paymentType !== "trainingToken" &&
        cart?.tokenType &&
        weblinkSettings.currencyCode
      ) {
        handleUpdateFinancialUnit(weblinkSettings.currencyCode);
      }
    }, [
      cart?.tokenType,
      handleUpdateFinancialUnit,
      tokenType,
      values.paymentType,
      weblinkSettings.currencyCode,
    ]);

    const hasCardPaymentProvider = stripe || convergePaymentProvider;
    const hasOtherPaymentProvider =
      canCheckoutByInvoice || trainingTokensPaymentProvider;

    return (
      <>
        {((hasCardPaymentProvider && hasOtherPaymentProvider) ||
          (canCheckoutByInvoice && trainingTokensPaymentProvider)) && (
          <RadioButtons
            name="paymentType"
            label={t("paymentType")}
            type="button"
            options={paymentProviderOptions}
            valid={v => (!!v ? true : `${t("selectPaymentOption")}`)}
            disabled={loading}
          />
        )}
        {!weblinkSettings.loading && !applyPricingLoading && (
          <React.Fragment>
            <PaymentInfo
              stripe={stripe}
              paymentType={paymentType}
              canCheckoutByInvoice={canCheckoutByInvoice}
              stripePaymentProvider={stripePaymentProvider}
              convergePaymentProvider={convergePaymentProvider}
              trainingTokensPaymentProvider={trainingTokensPaymentProvider}
              isBillingAddressProvided={isBillingAddressProvided}
              cart={cart}
              refetchViewer={refetchViewer}
            />
            {paymentProviderFields.map(field => (
              <CustomFieldInput
                key={field.key}
                label={field.label}
                name={field.key}
                type={field.type as CustomFieldType}
                options={field.options}
              />
            ))}
          </React.Fragment>
        )}
      </>
    );
  },
);

const PaymentInfo: FunctionComponent<{
  stripe: Stripe | null;
  paymentType: PaymentType;
  canCheckoutByInvoice: boolean;
  stripePaymentProvider?: WeblinkPaymentMethodUnion;
  convergePaymentProvider?: WeblinkPaymentMethodUnion;
  trainingTokensPaymentProvider?: TrainingToken;
  isBillingAddressProvided?: boolean;
  cart?: Cart;
  refetchViewer: () => Promise<ApolloQueryResult<any>>;
}> = observer(
  ({
    stripe,
    paymentType,
    canCheckoutByInvoice,
    stripePaymentProvider,
    convergePaymentProvider,
    trainingTokensPaymentProvider,
    isBillingAddressProvided,
    cart,
    refetchViewer,
  }) => {
    const { t } = useTranslation();

    if (!stripe && !convergePaymentProvider && !trainingTokensPaymentProvider) {
      return canCheckoutByInvoice ? (
        <Alert
          type="info"
          message={t("checkingOutByInvoice")}
          glyph="infoSign"
        />
      ) : (
        <Alert
          type="error"
          message={t("noValidPaymentMethodsFoundForPortalAndAccount")}
          glyph="removeSign"
        />
      );
    }

    if (canCheckoutByInvoice && (!paymentType || paymentType === "invoice")) {
      // Show nothing, since we either haven't chosen anything, or we have chosen to checkout by invoice
      return null;
    }

    if (stripePaymentProvider && paymentType === "stripe") {
      return (
        <StripePaymentInfo
          isBillingAddressProvided={!!isBillingAddressProvided}
        />
      );
    }

    if (convergePaymentProvider && paymentType === "converge") {
      return <ConvergePaymentInfo />;
    }

    if (trainingTokensPaymentProvider && paymentType === "trainingToken") {
      return (
        <TrainingTokensPaymentInfo
          paymentProvider={trainingTokensPaymentProvider}
          cart={cart}
          refetchViewer={refetchViewer}
        />
      );
    }
    return null;
  },
);
