import React, { Fragment, FunctionComponent } from "react";
import { Switch, Route, Redirect } from "react-router";
import { Alert, LoadingScreen } from "@administrate/piston-ux";

import { ProtectedRoute, ProtectedRouteProps } from "./ProtectedRoute";
import { CourseCatalog } from "./pages/CourseCatalog/CourseCatalog";
import { CourseCatalogDetail } from "./pages/CourseCatalog/CourseCatalogDetail";
import { MyRequests } from "./pages/MyRequests/MyRequests";
import { TrainingRequest } from "./pages/TrainingRequest/TrainingRequest";
import { MyProfile } from "./pages/MyProfile/MyProfile";
import { MyCourses } from "./pages/MyCourses/MyCourses";
import {
  CourseDetail,
  PreviewCourseDetail,
} from "./pages/MyCourses/CourseDetail";
import {
  ContentPlayer,
  PreviewContentPlayer,
} from "./pages/ContentPlayer/ContentPlayer";
import { LearningPathDetail } from "./pages/MyCourses/LearningPathDetail";
import { IdentityProviderSetUpRedirect } from "./components/IdentityProvider/IdentityProviderSetUpRedirect";
import { IdentityProviderResetRedirect } from "./components/IdentityProvider/IdentityProviderResetRedirect";
import { IdentityProviderVerificationRedirect } from "./components/IdentityProvider/IdentityProviderVerificationRedirect";
import { usePortal } from "./providers/PortalProvider";
import { useViewer } from "./providers/ViewerProvider";
import { MenuBar } from "./MenuBar";
import { SideBar } from "./SideBar";
import {
  AchievementDocumentResource,
  Achievements,
} from "./pages/Achievements/Achievements";
import { useAuth0 } from "./react-auth0-wrapper";
import { useGlobalStore } from "./providers/GlobalStore";
import { PreviewLogin } from "./components/PreviewLogin";
import { NoMatch } from "./pages/NoMatch";
import { previewModeJWTRegex } from "./utils/previewHelpers";
import { useTranslation } from "react-i18next";
import { CartPage } from "./pages/Cart/CartPage";
import { BookerInformation } from "./pages/Checkout/BookerInformation";
import { CheckoutProvider, CheckoutURLS } from "./providers/CheckoutProvider";
import { LearnerDetails } from "./pages/Checkout/LearnerDetails";
import { BookingDetails } from "./pages/Checkout/BookingDetails";
import { OrderComplete } from "./pages/Checkout/OrderComplete";
import { ExternalAttempt } from "./pages/ExternalAttempt/ExternalAttempt";
import { useLmsQuery } from "./hooks/lms";
import { RegisterablesCountResponse } from "./types/Registerables";
import { REGISTERABLES_COUNT_QUERY } from "./queries/registerables";
import { CatalogRoute } from "./pages/Catalog/CatalogRoute";
import { BookingConfirmation } from "./pages/Order/BookingConfirmation";
import { LearnerManagementRoute } from "./pages/LearnerManagement/LearnerManagementRoute";
import { SupportMessage } from "./components/SupportMessage";
import { ACHIEVEMENTS_COUNT_QUERY } from "./queries/achievements";
import { Query } from "./generated/lmsTypes";
import { useGoogleAnalytics } from "./hooks/useGoogleAnalytics";
import { QuickRegister } from "./components/QuickRegister";
import { CartRemovedLineItemsProvider } from "./providers/CartRemovedLineItemsProvider";
import { CartInvalidLineItemsProvider } from "./providers/CartInvalidLineItemsProvider";

export const Routes: React.FunctionComponent = () => {
  const {
    isApprovalPortal,
    isBookingPortal,
    isSelfRegistrationPortal,
    emptyPortalRedirectUrl,
  } = usePortal();
  const { viewer } = useViewer();
  const { t } = useTranslation();
  const globalStore = useGlobalStore();

  useGoogleAnalytics(
    viewer?.id,
    atob(globalStore.config.brand).split(":")[0],
    globalStore.config.analytics_enabled,
  );

  const allowCoordinatorFunctionality =
    !!viewer && viewer.isCoordinator && isBookingPortal;
  const allowRegistrationFunctionality =
    allowCoordinatorFunctionality || isSelfRegistrationPortal;

  const { logout } = useAuth0();

  if (
    viewer?.isPreviewer &&
    !previewModeJWTRegex.test(window.location.pathname) &&
    !window.location.pathname.includes("/preview")
  ) {
    logout({
      redirect_uri: window.location.origin,
      brand: globalStore.config.brand,
    });
  }

  const {
    loading: registerablesCountLoading,
    data: registerablesCountResponse,
    error: registerablesCountError,
  } = useLmsQuery<RegisterablesCountResponse>(REGISTERABLES_COUNT_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const noCourses =
    !registerablesCountLoading &&
    registerablesCountResponse?.registerablesCount.count === 0 &&
    !registerablesCountError;

  const {
    loading: achievementsCountLoading,
    data: achievementsCountResponse,
    error: achievementsCountError,
  } = useLmsQuery<Query>(ACHIEVEMENTS_COUNT_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const noAchievements =
    !achievementsCountLoading &&
    achievementsCountResponse?.achievementsCount?.count === 0 &&
    !achievementsCountError;

  if (emptyPortalRedirectUrl && noCourses && noAchievements) {
    window.location.href = emptyPortalRedirectUrl;
    return <LoadingScreen />;
  }

  const loading = registerablesCountLoading || achievementsCountLoading;

  if (emptyPortalRedirectUrl === null || (emptyPortalRedirectUrl && loading)) {
    return <LoadingScreen />;
  }

  return (
    <CartRemovedLineItemsProvider>
      <CartInvalidLineItemsProvider>
        {/* This _needs_ to be at the top for the preview */}
        {viewer?.isPreviewer && (
          <Alert
            message={t("previewAlertMessage")}
            type="warning"
            overPage={false}
            className="preview-alert"
          />
        )}
        <SupportMessage />
        <Switch>
          <ProtectedRoute
            path="/my-requests/:id/training-request"
            exact
            component={TrainingRequest}
            condition={isApprovalPortal}
          />
          <Redirect
            from="/learning-path/:learningPathId/registration/:registrationId/content/:contentId"
            to="/my-courses/learning-path/:learningPathId/course/:registrationId/content/:contentId"
          />
          <Route
            path="/my-courses/learning-path/:learningPathId/course/:registrationId/content/:contentId"
            exact
            component={ContentPlayer}
          />
          <Redirect
            from="/registration/:registrationId/content/:contentId"
            to="/my-courses/course/:registrationId/content/:contentId"
            exact
          />
          <Route
            path="/my-courses/course/:registrationId/content/:contentId"
            exact
            component={ContentPlayer}
          />
          <Route
            path="/preview/content/:contentId"
            exact
            component={PreviewContentPlayer}
          />
          <Route
            path={[
              "/my-achievements/certificate/achievement/:achievementId",
              "/my-achievements/achievements/:achievementId/documents/:documentId",
            ]}
            exact
            component={AchievementDocumentResource}
          />
          <Route
            path="/attempt/:attemptId?"
            exact
            component={ExternalAttempt}
          />
          <ProtectedCheckoutRoute
            path="/checkout"
            exact
            component={BookerInformation}
            condition={allowRegistrationFunctionality}
          />
          <ProtectedCheckoutRoute
            path={`/checkout/${CheckoutURLS.LearnerDetails}`}
            exact
            component={LearnerDetails}
            condition={allowRegistrationFunctionality}
          />
          <ProtectedCheckoutRoute
            path={`/checkout/${CheckoutURLS.BookingDetails}`}
            exact
            component={BookingDetails}
            condition={allowRegistrationFunctionality}
          />
          <ProtectedCheckoutRoute
            path={`/checkout/${CheckoutURLS.OrderComplete}`}
            exact
            component={OrderComplete}
            condition={allowRegistrationFunctionality}
          />
          <ProtectedRoute
            path="/catalog/:catalogType/booking/:bookingId/confirmation"
            exact
            component={BookingConfirmation}
            condition={allowRegistrationFunctionality}
          />
          <ProtectedRoute
            path="/catalog"
            component={CatalogRoute}
            exact={false}
            condition={allowRegistrationFunctionality}
          />
          <RoutesWithNavigation />
        </Switch>
      </CartInvalidLineItemsProvider>
    </CartRemovedLineItemsProvider>
  );
};

export const RoutesWithNavigation: React.FunctionComponent = () => {
  const portal = usePortal();
  const { isApprovalPortal, isBookingPortal, isSelfRegistrationPortal } =
    portal;
  const { viewer } = useViewer();

  const allowCoordinatorFunctionality =
    !!viewer && viewer.isCoordinator && isBookingPortal;
  const allowRegistrationFunctionality =
    allowCoordinatorFunctionality || isSelfRegistrationPortal;

  return (
    <Fragment>
      <MenuBar />
      {!viewer?.isPreviewer && <SideBar />}
      <main className={viewer?.isPreviewer ? "preview" : "general"}>
        <Switch>
          <Route path="/my-courses" exact component={MyCourses} />
          <Route path="/login/:previewJWT" exact component={PreviewLogin} />
          <Route path="/preview" exact component={PreviewCourseDetail} />
          <Redirect
            from="/registration/:id"
            to="/my-courses/course/:id"
            exact
          />
          <Route path="/my-courses/course/:id" exact component={CourseDetail} />
          <Route
            path="/my-courses/course/:id/:status"
            exact
            component={CourseDetail}
          />
          <Redirect
            from="/learning-path/:id"
            to="/my-courses/learning-path/:id"
            exact
          />
          <Redirect
            from="/learning-path/:learningPathId/registration/:id"
            to="/my-courses/learning-path/:learningPathId/course/:id"
            exact
          />
          <Route
            path="/my-courses/learning-path/:id"
            exact
            component={LearningPathDetail}
          />
          <Route
            path="/my-courses/learning-path/:id/:status"
            exact
            component={LearningPathDetail}
          />
          <Route
            path="/my-courses/learning-path/:learningPathId/course/:id"
            exact
            component={CourseDetail}
          />
          <Redirect from="/achievements" to="/my-achievements" exact />
          <Route path="/my-achievements" exact component={Achievements} />
          <Redirect from="/" to="/my-courses" exact />
          <Redirect from="/login" to="/my-courses" exact />
          <Redirect from="/profile" to="/my-profile" exact />
          <Route path="/my-profile" exact component={MyProfile} />
          <Route
            path="/quick-register/:courseTemplateId"
            exact
            component={QuickRegister}
          />
          <ProtectedRoute
            path="/course-catalog"
            exact
            component={CourseCatalog}
            condition={isApprovalPortal}
          />
          <Redirect from="/course_catalogue" to="/catalog" exact />
          <ProtectedRoute
            path="/cart"
            exact
            component={CartPage}
            condition={allowRegistrationFunctionality}
          />
          <ProtectedRoute
            path="/course-catalog/:id"
            exact
            component={CourseCatalogDetail}
            condition={isApprovalPortal}
          />
          <ProtectedRoute
            path="/my-requests"
            exact
            component={MyRequests}
            condition={isApprovalPortal}
          />
          <ProtectedRoute
            path="/learner-management"
            component={LearnerManagementRoute}
            exact={false}
            condition={allowCoordinatorFunctionality}
          />
          <Route component={NoMatch} />
          {/* ORDER MATTERS. THIS ROUTE NEEDS TO BE LAST */}
        </Switch>
      </main>
    </Fragment>
  );
};

export const UnauthorizedRoutes: React.FunctionComponent = ({ children }) => (
  <Switch>
    <Route
      path="/setup/:passwordResetToken"
      exact
      component={IdentityProviderSetUpRedirect}
    />
    <Route
      path="/forgot/:passwordResetToken"
      exact
      component={IdentityProviderResetRedirect}
    />
    <Route
      path="/verify_email/:emailVerificationToken"
      exact
      component={IdentityProviderVerificationRedirect}
    />
    <Route children={children} />
  </Switch>
);

export const ProtectedCheckoutRoute: FunctionComponent<ProtectedRouteProps> = ({
  condition,
  path,
  component,
  exact,
}) => {
  return condition ? (
    <CheckoutProvider>
      <Route path={path} component={component} exact={exact} />
    </CheckoutProvider>
  ) : (
    <span />
  );
};
