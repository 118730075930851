import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  FunctionComponent,
} from "react";

import { useDebounce } from "../../hooks/useDebounce";
import { keyPress } from "../../utils/keyPress";
import { SearchProps } from "../../types/AffectBar";
import { useTranslation } from "react-i18next";

export const SearchBar: FunctionComponent<SearchProps> = ({
  onChange,
  label,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const debouncedValue = useDebounce(value, 500);

  const useFocus = () => {
    const htmlElRef: any = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };
    return [htmlElRef, setFocus];
  };
  const [inputRef, setInputFocus] = useFocus();

  useEffect(() => {
    onChange(debouncedValue);
  }, [onChange, debouncedValue]);

  const keyFunctions = useCallback(
    event => {
      if (event.keyCode === keyPress.BACKSLASH) {
        setTimeout(() => setInputFocus(), 1);
      }
      if (value && event.keyCode === keyPress.ESCAPE) {
        onChange("");
        setValue("");
      }
    },
    [value, onChange, setInputFocus, setValue],
  );

  useEffect(() => {
    document.addEventListener("keydown", keyFunctions, false);

    return () => {
      document.removeEventListener("keydown", keyFunctions, false);
    };
  }, [keyFunctions]);

  return (
    <div className="actionsBar-search">
      <label htmlFor="searchBar">
        {t("search")} {label}
      </label>
      <input
        type="text"
        placeholder={`${t("search")} ${label}...`}
        onChange={ev => {
          setValue(ev.target.value);
        }}
        value={value}
        ref={inputRef}
        id="searchBar"
      />
      <span
        className="glyphicon glyphicon-search actionsBar-search-icon"
        aria-hidden="true"
      ></span>
    </div>
  );
};
