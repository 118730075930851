import React, { useState, FunctionComponent } from "react";
import { isInteger } from "@administrate/piston-ux/lib/utils/validators";
import {
  Input,
  DateTimeInput,
  Row,
  Col,
  Tooltip,
} from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";

import moment from "moment";

export const ConvergePaymentInfo: FunctionComponent = () => {
  const { t } = useTranslation();
  const [validCardNo, setValidCardNo] = useState(false);
  const [validExpiry, setValidExpiry] = useState(false);
  const [validCvv2, setValidCvv2] = useState(false);

  const isValidCardNumber = (cardNumber: string) =>
    setValidCardNo(isInteger(cardNumber) && cardNumber.length >= 15);

  const setCvv2isValid = (cvv2: string) => {
    setValidCvv2(isInteger(cvv2) && [3, 4].includes(cvv2.length));
  };

  const isExpiryValid = (expiry: string) => {
    const expiryMoment = moment(expiry, "MM/YY");
    const now = moment();
    const laterYear = expiryMoment.year() > now.year();
    const sameYear = expiryMoment.year() === now.year();
    const laterMonth = expiryMoment.month() >= now.month();
    return setValidExpiry(laterYear || (sameYear && laterMonth));
  };

  return (
    <React.Fragment>
      <p>{t("encryptedTransactions")}</p>
      <Row>
        <Col xs={7}>
          <Input
            label={t("cardNumber")}
            name="cardNumber"
            valid={() => validCardNo}
            maxLength={19}
            onChange={value => isValidCardNumber(value)}
          />
        </Col>
        <Col xs={3}>
          <DateTimeInput
            label={t("expiration")}
            type="monthYear"
            name="expiration"
            valid={() => validExpiry}
            onChange={value => isExpiryValid(value)}
          />
        </Col>
        <Col xs={2}>
          <Tooltip content="Card Verification Code" tooltipId={1}>
            <Input
              label={t("CVC")}
              name="cvv2"
              valid={() => validCvv2}
              maxLength={4}
              onChange={value => setCvv2isValid(value)}
            />
          </Tooltip>
        </Col>
      </Row>
      <Input label={t("address")} name="address1" valid={value => !!value} />
      <Input
        label={t("zipOrPostcalCode")}
        name="zip"
        valid={value => !!value}
      />
      <span style={{ display: "none" }}>
        <Input
          name="validCard"
          valid={() => validCardNo && validExpiry && validCvv2}
          label=""
          type="hidden"
        />
      </span>
    </React.Fragment>
  );
};
