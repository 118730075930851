import React, { FunctionComponent, useCallback } from "react";
import {
  FutureArticleHeader,
  FutureAsyncFilter,
  FutureSelectFilter,
  useTypedFormValues,
} from "@administrate/piston-ux";
import { LearnerExport } from "../../components/LearnerExport";
import { AccountNode } from "../../types/LearnerManagement";
import { AccountWithAncestors } from "../../components/Ancestors";
import { Account } from "../../generated/lmsTypes";
import { useTranslation } from "react-i18next";
import { useLearnerManagementLearnerFilters } from "./LearnerFilterContext";

export const LearnerListArticleHeader: FunctionComponent<{
  accountOptions: (inputValue: string) => Promise<Account[]>;
  onCreate: () => void;
  loading: boolean;
}> = ({ accountOptions, onCreate, loading }) => {
  const { t } = useTranslation();

  const { filters, setFilterValue } = useLearnerManagementLearnerFilters();

  const values = useTypedFormValues(filters);

  const onSearch = useCallback(
    value => {
      setFilterValue("search", value);
    },
    [setFilterValue],
  );

  return (
    <FutureArticleHeader
      title={t("learners")}
      loading={loading}
      create={{
        onClick: onCreate,
        label: t("addLearner"),
      }}
      search={{
        initialValue: filters.search,
        label: t("learners"),
        onChange: onSearch,
      }}
      extra={<LearnerExport />}
      filters={{
        values,
      }}
      id="learners-header"
    >
      <FutureAsyncFilter
        name="account"
        label={t("account")}
        loadOptions={accountOptions}
        formatter={(coordinatorManagedAccounts: AccountNode) =>
          AccountWithAncestors(coordinatorManagedAccounts)
        }
        extraClass="account-hierarchy"
        onChange={account => setFilterValue("account", account)}
        id="learners__account-hierarchy-filter"
      />
      <FutureSelectFilter
        name="userType"
        label={t("userType")}
        direction="right"
        onChange={userType =>
          setFilterValue("userType", userType.selected?.value ?? null)
        }
        options={[
          {
            label: t("coordinator"),
            value: true,
          },
        ]}
        id="learners__user-type-filter"
      />
    </FutureArticleHeader>
  );
};
