import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import moment from "moment";
import enGB from "./locales/en-GB/translation.json";
import enUS from "./locales/en-US/translation.json";
import fr from "./locales/fr/translation.json";
import { Viewer } from "./types/Viewer";

const DEFAULT_TRANSLATION_LOCALE = "en-US";
const DEFAULT_MOMENT_LOCALE = "en";

// noinspection JSIgnoredPromiseFromCall
i18n.use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: DEFAULT_TRANSLATION_LOCALE,
  returnEmptyString: false,
});

i18n.addResources("en-GB", "translation", enGB);
i18n.addResources(DEFAULT_TRANSLATION_LOCALE, "translation", enUS);
i18n.addResources("fr", "translation", fr);

export const setViewerLocale = (viewer: Viewer) => {
  const isPreviewer = viewer.isPreviewer;
  const siteTranslationsLocale = findClosestTranslation(
    viewer.locale ?? undefined,
  );

  if (isPreviewer || siteTranslationsLocale === DEFAULT_TRANSLATION_LOCALE) {
    moment.locale(DEFAULT_MOMENT_LOCALE);
  } else {
    moment.locale(siteTranslationsLocale);
  }

  if (i18n.language !== siteTranslationsLocale) {
    // noinspection JSIgnoredPromiseFromCall - the promise here doesn't really do anything
    i18n.changeLanguage(siteTranslationsLocale);
  }
};

export const findClosestTranslation = (inputLanguage?: string) => {
  const normalizedLanguage = (
    inputLanguage || DEFAULT_TRANSLATION_LOCALE
  ).toLowerCase();
  const availableTranslations = new Map<string, string>();
  Object.keys(i18n.store.data).forEach(value => {
    availableTranslations.set(value.toLowerCase(), value);
  });
  availableTranslations.set("french", "fr");

  return (
    availableTranslations.get(normalizedLanguage) ?? DEFAULT_TRANSLATION_LOCALE
  );
};
