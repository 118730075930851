import React, { FunctionComponent, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Button, List, Scrollable } from "@administrate/piston-ux";

import { useGlobalStore } from "../../providers/GlobalStore";
import { classNames } from "../../toPistonUX/classNames";
import { ContentList } from "./ContentList";

import { useViewer } from "../../providers/ViewerProvider";
import { FetchMoreCallback } from "../../types/ApolloCallbacks";

export const ContentMenu: FunctionComponent<{
  registration: any;
  activeContentId?: string;
  learningPathId?: string;
  fetchMoreCourseDetails?: FetchMoreCallback;
  isAccessible: boolean;
}> = observer(
  ({
    registration,
    activeContentId,
    learningPathId,
    fetchMoreCourseDetails,
    isAccessible,
  }) => {
    const { t } = useTranslation();
    const globalState = useGlobalStore();
    const { viewer } = useViewer();
    const [showContentMenu, setShowContentMenu] = useState(
      globalState.contentMenuOpen,
    );

    if (showContentMenu !== globalState.contentMenuOpen) {
      setShowContentMenu(globalState.contentMenuOpen);
    }
    return (
      <nav aria-label={t("courseContent")}>
        {showContentMenu && (
          <div
            className={classNames(
              "navbar-fixed-right content-menu ",
              `content-menu--${showContentMenu ? "open" : "closed"}`,
              { "content-menu-preview": viewer?.isPreviewer ?? false },
            )}
            tabIndex={-1}
          >
            <div className="content-menu--action">
              <h2>{t("courseContent")}</h2>
              <div className="float-right">
                <Button
                  type="suppressed"
                  label=""
                  ariaLabel={t("closeMenu")}
                  onClick={() =>
                    (globalState.contentMenuOpen = !globalState.contentMenuOpen)
                  }
                  icon="remove"
                />
              </div>
            </div>
            <Scrollable className="content-menu--panel" axis="y">
              <List hover>
                {registration && (
                  <ContentList
                    usage="player"
                    activeContentId={activeContentId}
                    registration={registration}
                    learningPathId={learningPathId}
                    fetchMoreCourseDetails={fetchMoreCourseDetails}
                    isAccessible={isAccessible}
                  />
                )}
              </List>
            </Scrollable>
          </div>
        )}
      </nav>
    );
  },
);
