import React, { FunctionComponent } from "react";
import { Route } from "react-router";

export type ProtectedRouteProps = {
  condition: boolean;
  path: string;
  component: React.ComponentType<any>;
  exact: boolean;
};

export const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({
  condition,
  path,
  component,
  exact,
}) => {
  return condition ? (
    <Route path={path} component={component} exact={exact} />
  ) : (
    <span></span>
  );
};
