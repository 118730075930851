import { useParams } from "../../useHistory";
import { useGlobalStore } from "../../providers/GlobalStore";

export const IdentityProviderVerificationRedirect: React.FunctionComponent =
  () => {
    const { emailVerificationToken } = useParams<{ emailVerificationToken:string }>();
    const globalStore = useGlobalStore();

    window.location.href = `https://${globalStore.config.idp.domain}/verify_email/${emailVerificationToken}`;

    return null;
  };
