import React, { useCallback } from "react";
import {
  Form,
  useFormValues,
  StaticInput,
  Submit,
  Input,
  Alert,
  Card,
  FutureArticleHeader,
  FormActions,
  Row,
  Col,
} from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";

import {
  Viewer,
  UpdateViewerCredentialsResponse,
  UpdateViewerCredentialsVariables,
} from "../../types/Viewer";
import { Maybe } from "../../types/Maybe";
import { UPDATE_VIEWER_CREDENTIALS } from "../../queries/viewer";
import { usePortal } from "../../providers/PortalProvider";
import { useLmsMutation } from "../../hooks/lms";

const lowerCaseRegex = /[a-z]/;
const lowerCaseTest = (s: string) => lowerCaseRegex.test(s);

const upperCaseRegex = /[A-Z]/;
const upperCaseTest = (s: string) => upperCaseRegex.test(s);

const passwordLength = 8;
const lengthTest = (s: string) => s.length >= passwordLength;

const numberRegex = /\d/;
const numberTest = (s: string) => numberRegex.test(s);

const symbolRegex = /[!@#$%^&*)(+=._-]/;
const symbolTest = (s: string) => symbolRegex.test(s);

function checkPassword(s: string) {
  return (
    [lowerCaseTest, upperCaseTest, lengthTest, symbolTest, numberTest].reduce(
      (acc, fn) => {
        return fn(s) ? acc + 1 : acc;
      },
      0,
    ) >= 3
  );
}

type SecurityProps = {
  viewer: Viewer;
};

type SecurityValues = {
  password?: string;
  confirmedPassword?: string;
  currentPassword?: string;
  username?: Maybe<string>;
};

export const Security: React.FunctionComponent<SecurityProps> = ({
  viewer,
}) => {
  const { t } = useTranslation();
  const initialValues: SecurityValues = {
    password: "",
    confirmedPassword: "",
    currentPassword: "",
    username: viewer.username,
  };
  const values = useFormValues(initialValues);
  const { host } = usePortal();
  const [
    updateViewerCredentials,
    {
      loading: updateViewerCredentialsIsLoading,
      called: updateViewerCredentialsIsCalled,
      data: updateViewerCredentialsData,
    },
  ] = useLmsMutation<
    UpdateViewerCredentialsResponse,
    UpdateViewerCredentialsVariables
  >(UPDATE_VIEWER_CREDENTIALS);

  const onSubmit = useCallback(
    formValues => {
      const values = formValues as SecurityValues;
      updateViewerCredentials({
        variables: {
          currentPassword: values.currentPassword!,
          newPassword: values.password!,
          host,
        },
      });
    },
    [updateViewerCredentials, host],
  );

  const updateErrors =
    updateViewerCredentialsData?.viewerCredentials?.setPassword?.errors || [];

  return (
    <div className="profile-security">
      <Form values={values} onSubmit={onSubmit}>
        <Card>
          <FutureArticleHeader title={t("security")} />
          {!updateViewerCredentialsIsLoading &&
            updateViewerCredentialsIsCalled &&
            (!updateErrors.length ? (
              <Alert
                type="success"
                message={t("Successful profile update")}
                overPage
              />
            ) : (
              <Alert type="error" message={updateErrors[0].message} />
            ))}
          {updateViewerCredentialsIsLoading && (
            <span
              className="glyphicon glyphicon-refresh glyphicon-spinner"
              style={{ marginRight: "5px" }}
            />
          )}
          <Row>
            <Col sm={5}>
              <StaticInput label={t("username")} name="username" />
            </Col>
          </Row>
          <Row>
            <Col sm={5}>
              <Input
                label={t("currentPassword")}
                name="currentPassword"
                type="password"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5}>
              <Input
                label={t("newPassword")}
                name="password"
                type="password"
                valid={v => {
                  return !!checkPassword(v) || `${t("passwordCriteria")}.`;
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={5}>
              <Input
                label={t("confirmedPassword")}
                name="confirmedPassword"
                type="password"
                valid={useCallback(
                  v => {
                    return (
                      values.password === v || `${t("passwordsMustMatch")}`
                    );
                  },
                  [t, values.password],
                )}
              />
            </Col>
          </Row>
          <FormActions>
            <Submit
              label={t("updatePassword")}
              inline
              disabled={updateViewerCredentialsIsLoading}
            />
          </FormActions>
        </Card>
      </Form>
    </div>
  );
};
