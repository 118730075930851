import { useMemo, useCallback } from "react";
import moment from "moment-timezone";

import { useViewer } from "../providers/ViewerProvider";
import { Maybe } from "../types/Maybe";

const americanDateTemplate = "MMM D, YYYY";
const nonAmericanDateTemplate = "D MMM, YYYY";

const timeTemplate = "LT";

export type DateFormat = ({
  date,
  customTimeZoneName,
}: {
  date: string;
  customTimeZoneName?: string | undefined;
}) => string;

export const useDateFormatter = (
  {
    showDayName = false,
    showTime = false,
  }: {
    showDayName?: boolean;
    showTime?: boolean;
  } = {
    showDayName: false,
    showTime: false,
  },
) => {
  const { viewer } = useViewer();

  const defaultTimeZoneName = useMemo(
    () => viewer?.timeZoneName || moment.tz.guess(),
    [viewer],
  );

  const getDateTemplate = useCallback(
    (timeZoneName: string) =>
      timeZoneName.includes("America")
        ? americanDateTemplate
        : nonAmericanDateTemplate,
    [],
  );

  const timeToTimeFormat = useCallback(
    ({
      start,
      end,
      customTimeZoneName,
    }: {
      start?: Maybe<string>;
      end?: Maybe<string>;
      customTimeZoneName?: string;
    }) => {
      if (!start) {
        return null;
      }

      const startWithTimezone = moment(start).tz(
        customTimeZoneName || defaultTimeZoneName,
      );

      if (!end) {
        return startWithTimezone.format(`${timeTemplate} z`);
      }

      const endWithTimezone = moment(end).tz(
        customTimeZoneName || defaultTimeZoneName,
      );

      return `${startWithTimezone.format(
        timeTemplate,
      )} - ${endWithTimezone.format(`${timeTemplate} z`)}`;
    },
    [defaultTimeZoneName],
  );

  const dateFormat: DateFormat = useCallback(
    ({
      date,
      customTimeZoneName,
    }: {
      date: string;
      customTimeZoneName?: string;
    }) => {
      const dateWithTimezone = moment(date).tz(
        customTimeZoneName || defaultTimeZoneName,
      );

      let format = showDayName ? "lll" : "ll";

      if (showTime) format = format.concat(` ${timeTemplate}`);
      if (customTimeZoneName) format = format.concat(" z");

      return dateWithTimezone.format(format);
    },
    [defaultTimeZoneName, showDayName, showTime],
  );

  const dateToDateFormat = useCallback(
    ({
      start,
      end,
      customTimeZoneName,
    }: {
      start?: Maybe<string>;
      end?: Maybe<string>;
      customTimeZoneName?: string;
    }) => {
      const startDate = start
        ? dateFormat({
            date: start,
            customTimeZoneName,
          })
        : null;
      const endDate = end
        ? dateFormat({
            date: end,
            customTimeZoneName,
          })
        : null;
      return startDate
        ? `${startDate}${
            endDate && endDate !== startDate ? ` - ${endDate}` : ""
          }`
        : "Unknown";
    },
    [dateFormat],
  );

  const simpleDateFormat = useCallback(
    (date: string) =>
      moment(date).format(
        `${showDayName ? "ddd " : ""}${getDateTemplate(defaultTimeZoneName)}`,
      ),
    [defaultTimeZoneName, showDayName, getDateTemplate],
  );

  return { dateFormat, dateToDateFormat, simpleDateFormat, timeToTimeFormat };
};
