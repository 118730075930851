import React, { useCallback, useEffect, useState } from "react";
import { useLmsClient } from "../../hooks/lms";
import { useGlobalStore } from "../../providers/GlobalStore";
import { useTranslation } from "react-i18next";
import { useViewer } from "../../providers/ViewerProvider";
import { ContentResultStatus, Maybe } from "../../generated/lmsTypes";
import { generateDocumentFilename } from "../../utils/generateDocumentFilename";
import { isIOS } from "react-device-detect";
import { RECORD_DOWNLOADED } from "../../mutations/registrations";

declare global {
  interface Window {
    recordDownloaded: Function;
  }
}

export const DocumentPlayer: React.FunctionComponent<{
  registrationId?: string;
  contentId: string;
  displayName?: string;
  originalExtension?: string;
  currentStatus?: Maybe<ContentResultStatus>;
  onDownload?: () => void;
}> = ({
  registrationId,
  contentId,
  displayName,
  originalExtension,
  onDownload,
  currentStatus,
}) => {
  const filename = generateDocumentFilename(displayName, originalExtension);
  const globalStore = useGlobalStore();
  const lmsClient = useLmsClient();
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const [parentStyles] = useState(() =>
    Array.prototype.slice
      .call(document.head.getElementsByTagName("style"))
      .reduce(
        (accumulator, currentValue) =>
          accumulator.concat(currentValue.outerHTML),
        "",
      ),
  );

  const [parentStylesheets] = useState(() =>
    Array.prototype.slice
      .call(document.head.getElementsByTagName("link"))
      .filter(e => e.rel === "stylesheet")
      .reduce(
        (accumulator, currentValue) =>
          accumulator.concat(
            `<link rel="stylesheet" href="${getURL(
              currentValue.getAttribute("href"),
            )}">`,
          ),
        "",
      ),
  );

  const createFrameHtml = useCallback(
    (body: string) =>
      `<html lang="en">
        <head>
          <title>Administrate Document Player</title>
          ${parentStylesheets}
          ${parentStyles}
        </head>
        <body>
          <div style="text-align: center; padding-top: 20px;">
            ${body}
          </div>
        </body>
      </html> `,
    [parentStyles, parentStylesheets],
  );

  const recordDownloaded = useCallback(() => {
    if (viewer?.isPreviewer) {
      return;
    }
    // noinspection JSIgnoredPromiseFromCall
    lmsClient.mutate({
      mutation: RECORD_DOWNLOADED,
      variables: {
        registrationId,
        contentId,
        stringContentId: contentId,
      },
    });
    if (onDownload && currentStatus !== ContentResultStatus.Downloaded) {
      onDownload();
    }
  }, [contentId, currentStatus, lmsClient, onDownload, registrationId, viewer]);

  window.recordDownloaded = recordDownloaded;

  const [frameHtml, setFrameHtml] = useState(
    createFrameHtml(`<h2>${t("preparingYourResource")}</h2>`),
  );
  const [frameSrc, setFrameSrc] = useState<string>();

  const cannotOpenInline = isIOS && originalExtension?.toLowerCase() === "pdf";
  const iframeAnchorStyles =
    "border: 0;color: #1d6dc0;box-shadow: 0 2px 4px rgba(53,69,81,0.2);";

  useEffect(() => {
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        // use window.location.origin as window.location is undefined in ie11
        const fileUri = encodeURI(
          `${window.location.origin}/files/${
            JSON.parse(this.responseText).encryptedFilePath
          }${filename ? `?filename=${filename}` : ""}`,
        );
        setFrameSrc(fileUri);
        // TODO: prevent document download via the button after registration has expired
        if (cannotOpenInline) {
          setFrameHtml(
            createFrameHtml(`
            <h2>${t(
              "To open the document in a new window, press the button below",
            )}</h2>
            <p><a id="download" role="button" class="btn btn-default" style="${iframeAnchorStyles}" href="${fileUri}"
             onclick="parent.recordDownloaded()"
             target="_blank">
              ${t("Open content")}
            </a></p>
          `),
          );
        } else {
          setFrameHtml(
            createFrameHtml(`
            <h2>${t(
              "Your resource should begin downloading automatically",
            )}</h2>
            <p>${t("If that doesn't happen, please click below")}.</p>
            <a id="download" role="button" class="btn btn-default" style="${iframeAnchorStyles}" href="${fileUri}">
              ${t("download")}
            </a>
            <script>window.location.href = "${fileUri}";</script>
          `),
          );
          recordDownloaded();
        }
      }
    };
    const fileRoute = viewer?.isPreviewer
      ? `/preview/${globalStore.previewJWT}/content/${contentId}/access`
      : `/registerables/${registrationId}/content/${contentId}/access`;

    xhttp.open("GET", fileRoute, true);
    xhttp.setRequestHeader("Authorization", `Bearer ${globalStore.token}`);
    xhttp.send();
  }, [
    registrationId,
    contentId,
    globalStore.token,
    filename,
    createFrameHtml,
    t,
    globalStore.previewJWT,
    viewer,
    cannotOpenInline,
    recordDownloaded,
  ]);

  return (
    <iframe
      title="document"
      srcDoc={frameHtml}
      src={cannotOpenInline ? undefined : frameSrc}
      style={{
        width: "1px",
        minWidth: "100%",
        height: "calc(100% - 64px)",
        position: "absolute",
        left: 0,
        top: "64px",
        border: "none",
      }}
    />
  );
};

const getURL = (path: string) => {
  try {
    const url = new URL(path, window.location.toString());
    return url.toString();
  } catch {
    return path;
  }
};
