import React, {
  FunctionComponent,
  useState,
  Fragment,
  useEffect,
  SetStateAction,
} from "react";
import {
  Card,
  List,
  ListRow,
  ListCell,
  Button,
  AlertModal,
  Modal,
  useFormValues,
  Pagination,
  FutureArticleHeader,
} from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { Maybe } from "@administrate/piston-ux/lib/types";
import { ListEmpty, ListHeader } from "@administrate/piston-ux/lib/List";

import { extractNodes } from "../../utils/extractNodes";
import {
  COORDINATED_ACCOUNTS_QUERY,
  LEARNER_MANAGED_ACCOUNTS_QUERY,
  ADD_PERMISSION_MUTATION,
  REMOVE_PERMISSION_MUTATION,
} from "../../queries/learnerManagement";
import { AccountPicker } from "../../components/AccountPicker";
import { Ancestors } from "../../components/Ancestors";
import {
  Account,
  Query,
  AccountEdge,
  ContactMutations,
} from "../../generated/lmsTypes";
import { LearnerPage } from "./LearnerPage";
import { useParams } from "../../useHistory";
import { useLazyLmsQuery, useLmsClient, useLmsMutation } from "../../hooks/lms";

export const CoordinatedAccounts: FunctionComponent = () => {
  const { t } = useTranslation();
  const { id: contactId } = useParams<{id:string}>();
  const [managedAccountsOffset, setManagedAccountsOffset] = useState(0);
  const managedAccountsTablePagination = 10;

  const [showAccountPermissionModal, setShowAccountPermissionModal] =
    useState(false);

  // TODO Update type with auto generated type
  const [showRemovePermissionModal, setShowRemovePermissionModal] =
    useState<Account>({
      id: "",
      name: "",
      ancestors: [
        {
          id: "",
          name: "",
        },
      ],
    });

  const [
    loadAccounts,
    { data: managedAccountsResponse, refetch: refetchManagedAccounts, loading },
  ] = useLazyLmsQuery<Query>(LEARNER_MANAGED_ACCOUNTS_QUERY, {
    variables: {
      offset: 0,
      contactId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const managedContact =
    managedAccountsResponse?.coordinatorManagedContacts?.edges?.[0]?.node;
  const managedAccounts = managedContact?.managedAccounts?.edges || [];
  const totalManagedRecords =
    managedContact?.managedAccounts?.pageInfo?.totalRecords;

  useEffect(() => {
    if (contactId) {
      loadAccounts();
    }
  }, [contactId, loadAccounts]);

  const [removePermissions] = useLmsMutation<ContactMutations>(
    REMOVE_PERMISSION_MUTATION,
  );

  const handleRemoveAccountPermissions = async (
    submitted: boolean,
    account: Account,
  ) => {
    if (submitted) {
      await removePermissions({
        variables: {
          input: {
            contactId,
            accountIds: [account.id],
          },
        },
      });
      setShowRemovePermissionModal({
        id: "",
        name: "",
        ancestors: [
          {
            id: "",
            name: "",
          },
        ],
      });
      refetchManagedAccounts();
    } else {
      setShowRemovePermissionModal({
        id: "",
        name: "",
        ancestors: [
          {
            id: "",
            name: "",
          },
        ],
      });
    }
  };

  const [addPermissions] = useLmsMutation<ContactMutations>(
    ADD_PERMISSION_MUTATION,
  );
  const handleAddAccountPermissions = async (values: { account: Account }) => {
    if (values) {
      await addPermissions({
        variables: {
          input: {
            contactId,
            accountIds: [values.account.id],
          },
        },
      });
      setShowAccountPermissionModal(false);
      refetchManagedAccounts();
    } else {
      setShowAccountPermissionModal(false);
    }
  };

  return (
    <LearnerPage page="coordinatedAccounts" id={contactId}>
      <Card id="learner-coordinated-accounts">
        <FutureArticleHeader
          title={t("coordinatedAccounts")}
          id="learner-coordinated-accounts__header"
          extra={
            <Button
              type="primary"
              label={t("addPermissions")}
              disabled={loading}
              onClick={() =>
                setShowAccountPermissionModal(!showAccountPermissionModal)
              }
              id="learner-coordinatedAccounts__add-permission"
            />
          }
        />

        <List hover loading={loading}>
          <ListHeader gridColumns="auto" headings={[{ title: t("account") }]} />
          {!loading &&
            managedAccounts.map((edge: Maybe<AccountEdge>, i: number) => {
              if (edge) {
                const managedAccount = edge.node;
                if (managedAccount) {
                  return (
                    <ListRow
                      gridColumns="auto"
                      key={i}
                      extra={
                        <Button
                          label={t("remove")}
                          type="suppressed"
                          onClick={() =>
                            setShowRemovePermissionModal({
                              id: managedAccount.id ? managedAccount.id : "",
                              name: managedAccount.name
                                ? managedAccount.name
                                : "",
                              ancestors: managedAccount.ancestors
                                ? managedAccount.ancestors
                                : [],
                            })
                          }
                          id="learner-coordinated-accounts__remove-permission"
                        />
                      }
                      dataLabel={managedAccount.name}
                    >
                      <ListCell label={t("account")}>
                        <Fragment>
                          {managedAccount.ancestors && (
                            <Ancestors ancestors={managedAccount.ancestors} />
                          )}
                          <span style={{ display: "block" }}>
                            {managedAccount.name}
                          </span>
                        </Fragment>
                      </ListCell>
                    </ListRow>
                  );
                }
              }
              return undefined;
            })}
          {loading && <ManagedAccountsLoading />}
          {!loading && totalManagedRecords === 0 && (
            <ListEmpty text={t("noCoordinatedAccounts")} />
          )}
          {totalManagedRecords
            ? !loading &&
              totalManagedRecords > managedAccountsTablePagination && (
                <Pagination
                  limit={managedAccountsTablePagination}
                  offset={managedAccountsOffset}
                  totalRecords={totalManagedRecords}
                  setOffset={(newOffset: SetStateAction<number>) => {
                    refetchManagedAccounts({
                      first: managedAccountsTablePagination,
                      offset: newOffset,
                    });
                    setManagedAccountsOffset(newOffset);
                  }}
                />
              )
            : undefined}
        </List>
      </Card>

      {showRemovePermissionModal.id && (
        <RemovePermission
          show={!!showRemovePermissionModal.id}
          onDone={handleRemoveAccountPermissions}
          account={{
            id: showRemovePermissionModal.id,
            name: showRemovePermissionModal.name,
            ancestors: showRemovePermissionModal.ancestors,
          }}
        />
      )}
      {showAccountPermissionModal && (
        <AddPermissions
          show={showAccountPermissionModal}
          onAddPermission={handleAddAccountPermissions}
        />
      )}
    </LearnerPage>
  );
};

type RemovePermissionType = {
  account: Account;
  onDone: Function;
  show: boolean;
};

const RemovePermission: FunctionComponent<RemovePermissionType> = ({
  show,
  onDone,
  account,
}) => {
  const { t } = useTranslation();
  return (
    <AlertModal
      show={show}
      onDone={(submitted: boolean) => onDone(submitted, account)}
      title={t("removePermissions")}
      type="warning"
    >
      <p>
        {t("removePermissionDetail")}: {account.name}
      </p>
    </AlertModal>
  );
};

const AddPermissions: FunctionComponent<{
  show: boolean;
  onAddPermission: Function;
}> = ({ show, onAddPermission }) => {
  const { t } = useTranslation();
  const values = useFormValues({
    account: "",
  });

  const client = useLmsClient();

  const loadOptions = (inputValue: string) =>
    client
      .query({
        query: COORDINATED_ACCOUNTS_QUERY,
        variables: {
          filters: [
            {
              field: "name",
              operation: "like",
              value: `%${inputValue}%`,
            },
            {
              field: "name",
              operation: "ne",
              value: "",
            },
          ],
        },
      })
      .then(result =>
        extractNodes(result.data.coordinatorManagedAccounts.edges),
      );

  const onModalDone = (result: boolean) => {
    if (result) {
      onAddPermission(values);
    } else {
      onAddPermission(undefined);
    }
  };

  return (
    <Modal
      title={t("addPermissions")}
      values={values}
      show={show}
      onDone={onModalDone}
      primaryActionText={t("OK")}
    >
      <AccountPicker
        name="account"
        loadOptions={loadOptions}
        valid={v => (!!v ? true : `${t("pleaseSelectAccount")}`)}
      />
    </Modal>
  );
};

const ManagedAccountsLoading: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <ListRow
        gridColumns="auto"
        extra={
          <Button
            label={t("remove")}
            type="suppressed"
            disabled
            onClick={() => null}
          />
        }
      >
        <ListCell label={t("account")} loading />
      </ListRow>
      <ListRow
        gridColumns="auto"
        extra={
          <Button
            label={t("remove")}
            type="suppressed"
            disabled
            onClick={() => null}
          />
        }
      >
        <ListCell label={t("account")} loading />
      </ListRow>
      <ListRow
        gridColumns="auto"
        extra={
          <Button
            label={t("remove")}
            type="suppressed"
            disabled
            onClick={() => null}
          />
        }
      >
        <ListCell label={t("account")} loading />
      </ListRow>
    </Fragment>
  );
};
