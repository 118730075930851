import React from "react";
import { AlertModal } from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { useWebLinkMutation, useWebLinkQuery } from "../../hooks/weblink";
import {
  CANCEL_EVENT_LEARNER_MUTATION,
  CANCEL_PATH_LEARNER_MUTATION,
} from "../../queries/learnerManagement";
import { PORTAL_MESSAGES_QUERY } from "../../queries/portal";
import { Query } from "../../generated/weblinkTypes";

type CancelLearnerModalProps = {
  onModalClose: (submitted: boolean) => void;
  learnerId: string;
  learnerCategory: LearnerCategory;
  hideGenericMessage?: boolean;
};

export enum LearnerCategory {
  Event,
  LearningPath,
}

export const CancelLearnerModal = ({
  onModalClose,
  learnerId,
  learnerCategory,
  hideGenericMessage = false,
}: CancelLearnerModalProps) => {
  const { t } = useTranslation();
  const [cancelEventLearner, { loading: cancelEventLearnerLoading }] =
    useWebLinkMutation(CANCEL_EVENT_LEARNER_MUTATION);
  const [cancelPathLearner, { loading: cancelPathLearnerLoading }] =
    useWebLinkMutation(CANCEL_PATH_LEARNER_MUTATION);

  const { data: messagesResponse, loading: messagesLoading } =
    useWebLinkQuery<Query>(PORTAL_MESSAGES_QUERY);

  const getCancelLearnerMutation = (learnerCategory: LearnerCategory) => {
    switch (learnerCategory) {
      case LearnerCategory.Event:
        return cancelEventLearner;
      case LearnerCategory.LearningPath:
        return cancelPathLearner;
      default:
        throw new Error("Invalid LearnerCategory for CancelLearnerModal.");
    }
  };

  const handleOnDone = async (submitted: boolean) => {
    if (submitted) {
      const cancelLearnerMutation = getCancelLearnerMutation(learnerCategory);
      await cancelLearnerMutation({
        variables: {
          input: {
            learnerId,
          },
        },
      });
    }
    onModalClose(submitted);
  };

  const cancelLoading = cancelEventLearnerLoading || cancelPathLearnerLoading;

  return (
    <AlertModal
      title={t("areYouSure")}
      type={"warning"}
      show
      onDone={handleOnDone}
      disabled={messagesLoading || cancelLoading}
    >
      {messagesLoading ? (
        <span className="glyphicon glyphicon-refresh glyphicon-spinner" />
      ) : (
        <>
          {!hideGenericMessage && <p>{t("cancelLearnerWarning")}</p>}
          {messagesResponse?.store.messages?.cancellation || ""}
        </>
      )}
    </AlertModal>
  );
};
