import React, {
  FunctionComponent,
  useState,
  useMemo,
  useEffect,
  Fragment,
} from "react";
import {
  Card,
  Alert,
  List,
  ListRow,
  ListCell,
  Pagination,
  Button,
  StatusLabel,
} from "@administrate/piston-ux";
import { get } from "lodash";
import { ListEmpty, ListHeader } from "@administrate/piston-ux/lib/List";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { useLmsQuery } from "../../hooks/lms";
import { MANAGED_PATH_REGISTRATIONS_FOR_CONTACT_QUERY } from "../../queries/registrations";
import {
  Query,
  LearningPathRegistration,
  OrderDirection,
  ContactEdge,
  LearningPathRegistrationEdge,
  LearningPathRegistrationField,
  FilterOperation,
  LearningPathLearnerLifecycleState,
} from "../../generated/lmsTypes";
import { useParams } from "../../useHistory";
import { LearnerPage } from "./LearnerPage";
import { LearnerLearningPathsListingHeader } from "./LearnerLearningPathsListingHeader";
import {
  OutcomeInfo,
  LearningOutcomeUnion,
  getOutcomeInfo,
} from "../../utils/lmsLearningPathHelpers";
import { LearnerOutcomesModal } from "./LearnerOutcomesModal";
import { ColorOptions } from "@administrate/piston-ux/lib/types";
import {
  CancelLearnerModal,
  LearnerCategory,
} from "../LearnerManagement/CancelLearnerModal";
import { OptionsMenu } from "../../components/OptionsMenu";

export const LearnerLearningPaths: FunctionComponent = () => {
  const { id: learnerId } = useParams<{id:string}>();

  return (
    <LearnerPage page="learningPaths" id={learnerId}>
      <PathRegistrations learnerId={learnerId} />
    </LearnerPage>
  );
};

const PathRegistrations: FunctionComponent<{
  learnerId: string;
}> = ({ learnerId }) => {
  const { t } = useTranslation();
  const [pathOffset, setPathOffset] = useState(0);
  const [pathTitleFilter, setPathTitleFilter] = useState("");
  const [cancellingRegistrationId, setCancellingRegistrationId] =
    useState<string | null>(null);

  useEffect(() => {
    // Set our offset back to 0 on the chance we're at an offset
    // that's no longer available due to our filters
    setPathOffset(0);
  }, [pathTitleFilter, learnerId]);

  const registrationFilters = useMemo(
    () => getFilters(pathTitleFilter),
    [pathTitleFilter],
  );
  const initialAmountToLoad = 10;
  const {
    data: pathData,
    loading: pathLoading,
    error: pathError,
    refetch: pathRefetch,
  } = useLmsQuery<Query>(MANAGED_PATH_REGISTRATIONS_FOR_CONTACT_QUERY, {
    variables: {
      contactId: learnerId,
      registrationFilters: registrationFilters,
      offset: pathOffset,
      first: initialAmountToLoad,
      order: {
        field: LearningPathRegistrationField.Id,
        direction: OrderDirection.Desc,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });
  const contact = get(pathData, "coordinatorManagedContacts.edges", []).map(
    (edge: ContactEdge) => edge.node,
  )[0];
  const registrations = get(contact, "learningPathRegistrations.edges", [])
    .map((edge: LearningPathRegistrationEdge) => (edge ? edge.node : null))
    .filter((node: LearningPathRegistration | null) => node !== null);
  const pathTotalRecords = get(
    contact,
    "learningPathRegistrations.pageInfo.totalRecords",
    0,
  );

  return (
    <>
      <Card id="learner-learning-paths">
        <LearnerLearningPathsListingHeader
          onSearchChange={setPathTitleFilter}
          title={t("learningPaths")}
        />
        <List loading={pathLoading}>
          <ListHeader
            gridColumns={`${pathGridColumns} 160px`}
            headings={[{ title: t("name") }, { title: t("result") }]}
          />
          {pathLoading ? (
            <PathRegistrationListLoading />
          ) : registrations.length > 0 ? (
            <PathRegistrationList
              registrations={registrations}
              onCancelRegistrationClick={setCancellingRegistrationId}
            />
          ) : (
            <ListEmpty text={t("noResults")} />
          )}
          {pathError && (
            <div className="m-4">
              <Alert message={t("somethingWentWrong")} />
            </div>
          )}
          {!pathLoading &&
            registrations.length > 0 &&
            pathTotalRecords > initialAmountToLoad && (
              <Pagination
                totalRecords={pathTotalRecords}
                offset={pathOffset}
                setOffset={newOffset => {
                  pathRefetch({
                    contactId: learnerId,
                    filters: registrationFilters,
                    first: initialAmountToLoad,
                    offset: newOffset,
                  });
                  setPathOffset(newOffset);
                }}
                limit={initialAmountToLoad}
              />
            )}
        </List>
      </Card>
      {!!cancellingRegistrationId && (
        <CancelLearnerModal
          onModalClose={() => {
            pathRefetch();
            setCancellingRegistrationId(null);
          }}
          learnerId={cancellingRegistrationId}
          learnerCategory={LearnerCategory.LearningPath}
        />
      )}
    </>
  );
};

const pathGridColumns = "6fr 1fr";

const PathRegistrationList: FunctionComponent<{
  registrations: (LearningPathRegistration | null | undefined)[];
  onCancelRegistrationClick: (registrationId: string) => void;
}> = ({ registrations, onCancelRegistrationClick }) => {
  const { t } = useTranslation();
  const [selectedOutcomes, setSelectedOutcomes] =
    useState<(OutcomeInfo | null)[] | null>(null);

  const getPathRegistrationListData = (
    registration: LearningPathRegistration,
  ) => {
    const { title, lastAccessed, lifecycleState } = registration;

    return {
      title: title ? title : t("unknown"),
      lastAccessed: lastAccessed,
      lifecycleState: lifecycleState,
    };
  };

  return (
    <Fragment>
      {registrations.map((registration, i: number) => {
        if (!registration) return null;

        const { title, lastAccessed, lifecycleState } =
          getPathRegistrationListData(registration);
        const lastAccessedDate = moment(lastAccessed);
        const lastAccessedString = lastAccessedDate.isValid()
          ? lastAccessedDate.format("ll")
          : t("notAccessed");
        const handleClickObjectives = (
          registration: LearningPathRegistration,
        ) => {
          const outcomes = get(registration, "results.edges", []).map(
            (edge: { node: LearningOutcomeUnion }) =>
              edge ? getOutcomeInfo(edge.node) : null,
          );
          setSelectedOutcomes(outcomes);
        };
        return (
          <ListRow
            key={`registration-row-${registration.id}`}
            gridColumns={pathGridColumns}
            extra={
              <>
                <Button
                  label={t("viewObjectives")}
                  onClick={() => handleClickObjectives(registration)}
                  type="suppressed"
                />
                <OptionsMenu
                  optionsMenuItems={[
                    {
                      label: t("cancelLearner"),
                      onClick: () => onCancelRegistrationClick(registration.id),
                      disabled: !registration.isActive,
                    },
                  ]}
                />
              </>
            }
            dataLabel={title}
            extraWidth={150}
          >
            <ListCell label={t("name")}>
              {title}
              <div>
                <span className="mt-2 d-block" style={{ fontSize: 12 }}>
                  <em>
                    ({t("updateLast")}: {lastAccessedString})
                  </em>
                </span>
              </div>
            </ListCell>
            <ListCell label={t("result")}>
              <LearnerLearningPathStatusLabel lifecycleState={lifecycleState} />
            </ListCell>
          </ListRow>
        );
      })}
      {selectedOutcomes && (
        <LearnerOutcomesModal
          outcomes={selectedOutcomes}
          onDone={() => setSelectedOutcomes(null)}
        />
      )}
    </Fragment>
  );
};

const LearnerLearningPathStatusLabel: FunctionComponent<{
  lifecycleState?: LearningPathLearnerLifecycleState;
}> = ({ lifecycleState }) => {
  if (lifecycleState === LearningPathLearnerLifecycleState.Completed) {
    return <StatusLabel text="Completed" color={ColorOptions.Green} />;
  }
  if (lifecycleState === LearningPathLearnerLifecycleState.Cancelled) {
    return <StatusLabel text="Cancelled" color={ColorOptions.Red} />;
  }
  return <StatusLabel text="Active" color={ColorOptions.LightGrey} />;
};

const PathRegistrationListLoading: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <ListRow gridColumns={`${pathGridColumns} 120px`}>
        <ListCell label={t("name")} loading />
        <ListCell label={t("result")} loading />
      </ListRow>
      <ListRow gridColumns={`${pathGridColumns} 120px`}>
        <ListCell label={t("name")} loading />
        <ListCell label={t("result")} loading />
      </ListRow>
      <ListRow gridColumns={`${pathGridColumns} 120px`}>
        <ListCell label={t("name")} loading />
        <ListCell label={t("result")} loading />
      </ListRow>
    </Fragment>
  );
};

const getFilters = (titleFilter: string) => {
  const filters = [];
  if (titleFilter) {
    filters.push({
      field: LearningPathRegistrationField.Title,
      operation: FilterOperation.Like,
      value: `%${titleFilter}%`,
    });
  }

  return filters;
};
