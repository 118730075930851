import { TABLE_LIMIT } from "@administrate/piston-ux/lib/layouts/ArticleTable/ArticleTable";
import { useState } from "react";
import {
  Maybe,
  Query,
  TokenIssueTransaction,
  TrainingTokenIssueEdge,
  TrainingTokenIssueTransactionEdge,
} from "../generated/lmsTypes";
import {
  TRAINING_TOKEN_ISSUES,
  TRAINING_TOKEN_ISSUE_LOG,
} from "../queries/trainingTokenIssues";
import { useLmsQuery } from "./lms";

export const useTrainingTokenIssues = () => {
  const { loading, data } = useLmsQuery<Query>(TRAINING_TOKEN_ISSUES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const tokenIssues = mapTokenIssueData(data?.trainingTokenIssues?.edges);

  return {
    tokenIssues: tokenIssues,
    tokenTypes: getTokenTypes(tokenIssues),
    loading,
  };
};

export type TokenTypeDictionary = { [id: string]: TokenType };

export type TokenType = {
  id: string;
  name: string;
  totalAmount: number;
};

const getTokenTypes = (tokenIssues: TokenIssueData[]) => {
  let tokenTypes: TokenTypeDictionary = {};

  tokenIssues.forEach(tokenIssue => {
    const tokenType = tokenTypes[tokenIssue.tokenTypeId];
    if (tokenType) {
      if (!tokenIssue.isExpired) {
        tokenType.totalAmount += tokenIssue.currentBalance ?? 0;
      }
    } else {
      tokenTypes[tokenIssue.tokenTypeId] = {
        id: tokenIssue.tokenTypeId,
        name: tokenIssue.tokenTypeName,
        totalAmount: tokenIssue.currentBalance ?? 0,
      };
    }
  });

  return tokenTypes;
};

export type TokenIssueData = {
  id: string;
  isExpired: boolean;
  issueDate: string;
  expiryDate: string | null;
  issueQuantity: number;
  used: number;
  currentBalance?: number;
  tokenTypeId: string;
  tokenTypeName: string;
  paymentCode?: string;
};

const mapTokenIssueData = (
  tokenIssueEdges?: Maybe<Maybe<TrainingTokenIssueEdge>[]>,
) => {
  let tokenIssueData: TokenIssueData[] = [];

  tokenIssueEdges?.forEach(tokenIssue => {
    const { node } = tokenIssue!;
    tokenIssueData.push({
      id: tokenIssue?.node?.id ?? "",
      isExpired: node?.isExpired ?? false,
      issueDate: node?.createdAt,
      expiryDate: node?.expiresAt,
      issueQuantity: node?.initialBalance ?? 0,
      used: node?.redeemed ?? 0,
      currentBalance: node?.isExpired ?? false ? 0 : node?.currentBalance ?? 0,
      tokenTypeId: node?.tokenTypeId ?? "",
      tokenTypeName: node?.tokenType ?? "",
      paymentCode: node?.paymentCode ?? "",
    });
  });
  return tokenIssueData;
};

export const useTrainingTokenIssueLog = (tokenIssueId?: string) => {
  const [offset, setOffset] = useState(0);
  const { loading, data } = useLmsQuery<Query>(
    TRAINING_TOKEN_ISSUE_LOG,
    {
      variables: {
        tokenIssueId,
        offset,
        first: TABLE_LIMIT,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      skip: !tokenIssueId,
    },
  );

  return {
    tokenIssueLog: extractIssueLogNodes(
      data?.tokenIssueTransactions?.transactions?.edges,
    ),
    loading,
    totalRecords:
      data?.tokenIssueTransactions?.transactions?.pageInfo?.totalRecords,
    offset,
    setOffset,
  };
};

export type TokenTransactionLog = {
  occurredAt?: string;
  type?: string;
  change?: string;
  notes?: string;
};

const extractIssueLogNodes = (
  edges?: Maybe<Maybe<TrainingTokenIssueTransactionEdge>[]>,
): TokenTransactionLog[] => {
  let issueLog: TokenTransactionLog[] = [];

  edges?.forEach(edge => {
    const { node } = edge!;
    issueLog.push({
      occurredAt: node?.occurredAt,
      type: node?.type ?? "",
      change: extractChangeFromNode(node),
      notes: extractNotesFromNode(node) ?? "",
    });
  });

  return issueLog;
};

const extractNotesFromNode = (node?: Maybe<TokenIssueTransaction>) => {
  if (node?.type === "adjust") return node.notes;
  if (node?.registerableTitle) return `Redeemed for ${node?.registerableTitle}`;
};

const extractChangeFromNode = (node?: Maybe<TokenIssueTransaction>) => {
  if (node?.tokenReceiptNumber)
    return `${node?.balanceDelta} (Receipt #${node?.tokenReceiptNumber})`;
  return `${node?.balanceDelta}`;
};
