import React, { useEffect } from "react";
import { PlayerStatus } from "../components/ScormPlayer/ScormLauncherPopup";
import { useGlobalStore } from "../providers/GlobalStore";

const useScormLauncherPopup = () => {
  const [childWindow, setChildWindow] = React.useState<Window | null>();
  const [playerState, setPlayerState] = React.useState<PlayerStatus>(
    PlayerStatus.Init,
  );
  const globalState = useGlobalStore();
  const openContent = (src: string) => {
    const win = window.open(src, undefined, "scrollbars");
    if (win) {
      setChildWindow(win);
      setPlayerState(PlayerStatus.Open);
    } else {
      setPlayerState(PlayerStatus.Blocked);
    }
  };
  if (playerState === PlayerStatus.Init) {
    openContent("about:blank");
  }
  useEffect(() => {
    if (playerState === PlayerStatus.Blocked) {
      globalState.contentMenuOpen = false;
    }
    if (playerState === PlayerStatus.Open) {
      globalState.contentMenuOpen = true;
    }
  }, [playerState, globalState.contentMenuOpen]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    let timer: NodeJS.Timeout;
    try {
      if (childWindow) {
        timer = setInterval(() => {
          if (childWindow.closed) {
            setPlayerState(PlayerStatus.Closed);
          }
        }, 1000);
      }
    } catch (err) {
      console.warn(err);
    }
    return () => {
      clearInterval(timer);
      childWindow && childWindow.close();
    };
  }, [childWindow]);
  return { childWindow, playerState, openContent };
};

export default useScormLauncherPopup;
