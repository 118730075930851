import gql from "graphql-tag";
import { PAGE_INFO_FRAGMENT } from "./fragments";

export const GET_ACHIEVEMENTS = gql`
  query getAchievements(
    $offset: Int
    $filters: [AchievementFieldFilter]
    $order: AchievementFieldOrder
    $first: Int
    $after: String
  ) {
    achievements(
      first: $first
      after: $after
      offset: $offset
      filters: $filters
      order: $order
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          id
          achievementType {
            id
            name
          }
          achievedAt
          expiresAt
          lifecycleState
          identifier
          registerable {
            id
            title
          }
          certificateUrl
          documentUrls
          documentIds
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

export const ACHIEVEMENTS_COUNT_QUERY = gql`
  query achievementsCount {
    achievementsCount {
      count
    }
  }
`;
