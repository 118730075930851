import React, { FunctionComponent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  CourseObjective as CourseObjectiveType,
  Event,
  EventObjective as EventObjectiveType,
  LearningPath,
  LearningPathObjective,
} from "../../generated/weblinkTypes";
import { Modal, StyleBlock, UtilityStyle } from "@administrate/piston-ux";
import { LearningPathDateAndLocations } from "./LearningPathDateAndLocations";
import { ObjectiveStatusLabel } from "./LearningPath";
import { LearningObjectiveUnion } from "../../utils/weblinkLearningPathHelpers";
import { NestedPathObjectiveOptionChoice } from "../../types/LearningPaths";
import { shouldAlternateBgColor } from "../../utils/displayHelpers";

export const NestedPathObjectiveModal: FunctionComponent<{
  learningPath: LearningPath;
  nestedObjective: LearningPathObjective & {
    nestedOptions: NestedPathObjectiveOptionChoice;
  };
  regionCode: string;
  onModalClose: (
    complete: boolean,
    objective: LearningPathObjective,
    objectiveSelection: NestedPathObjectiveOptionChoice,
  ) => void;
}> = ({ learningPath, nestedObjective, regionCode, onModalClose }) => {
  const { t } = useTranslation();

  const [objectiveEventSelection, setObjectiveEventSelection] =
    useState<NestedPathObjectiveOptionChoice>(
      nestedObjective.nestedOptions || {},
    );
  const [allObjectivesFulfilled, setAllObjectivesFulfilled] = useState(false);

  useEffect(() => {
    setAllObjectivesFulfilled(
      learningPath.learningObjectives.edges
        .filter(
          ({ node }) =>
            (node as LearningObjectiveUnion).__typename === "CourseObjective",
        )
        .every(({ node }) => node.id in objectiveEventSelection),
    );
  }, [learningPath.learningObjectives.edges, objectiveEventSelection]);

  const handleEventSelect = (objectiveId: string, event: Event) => {
    setObjectiveEventSelection({
      ...objectiveEventSelection,
      [objectiveId]: event,
    });
  };

  const validObjectives = learningPath.learningObjectives.edges.filter(edge => {
    const node = edge.node as LearningObjectiveUnion;
    return ["CourseObjective", "EventObjective"].includes(node.__typename!);
  });

  return (
    <Modal
      title={t("learningPathContent")}
      show
      onDone={complete =>
        onModalClose(complete, nestedObjective, objectiveEventSelection)
      }
      disabled={!allObjectivesFulfilled}
      size="large"
    >
      <div>
        {validObjectives.map((edge, i) => {
          const node = edge.node as LearningObjectiveUnion;
          const outerStyleOptions = [
            UtilityStyle.HorizontalPadding,
            UtilityStyle.VerticalPaddingLarge,
            UtilityStyle.BottomSpaceLarge,
          ];
          if (shouldAlternateBgColor(i)) {
            outerStyleOptions.push(UtilityStyle.BackgroundFog);
          }

          return (
            <StyleBlock styleOptions={outerStyleOptions} key={node.id}>
              {node.__typename === "EventObjective" && (
                <EventObjective node={node} />
              )}
              {node.__typename === "CourseObjective" && (
                <CourseObjective
                  node={node}
                  objectiveEventSelection={objectiveEventSelection}
                  regionCode={regionCode}
                  learningPathId={learningPath.id}
                  handleEventSelect={handleEventSelect}
                />
              )}
            </StyleBlock>
          );
        })}
      </div>
    </Modal>
  );
};

const EventObjective: FunctionComponent<{ node: EventObjectiveType }> = ({
  node,
}) => {
  const { t } = useTranslation();

  return (
    <StyleBlock styleOptions={[UtilityStyle.Flex, UtilityStyle.JustifyBetween]}>
      <ObjectiveStatusLabel text={t("Event")} errors={null} />
      <p>{node.event.name}</p>
    </StyleBlock>
  );
};

const CourseObjective: FunctionComponent<{
  node: CourseObjectiveType;
  objectiveEventSelection: NestedPathObjectiveOptionChoice;
  regionCode: string;
  learningPathId: string;
  handleEventSelect: (objectiveId: string, event: Event) => void;
}> = ({
  node,
  objectiveEventSelection,
  regionCode,
  learningPathId,
  handleEventSelect,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <StyleBlock
        styleOptions={[UtilityStyle.Flex, UtilityStyle.JustifyBetween]}
      >
        <ObjectiveStatusLabel text={t("Course")} errors={null} />
        <p>{node.course?.name}</p>
        <p>
          {objectiveEventSelection[node.id]
            ? objectiveEventSelection[node.id]?.location.name
            : ""}
        </p>
      </StyleBlock>
      <LearningPathDateAndLocations
        key={node.id}
        uniqueKey={node.id}
        courseCode={node.course?.code}
        learningPathId={learningPathId}
        regionCode={regionCode}
        onEventSelect={(event: Event) => handleEventSelect(node.id, event)}
      />
    </>
  );
};
