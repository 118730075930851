import {
  FutureArticleHeader,
  Card,
  List,
  ListRow,
  ListCell,
} from "@administrate/piston-ux";
import React, { Fragment, FunctionComponent } from "react";

export const LoadingCard: FunctionComponent<{
  title?: string;
  header?: boolean;
}> = ({ title = " ", header = true }) => (
  <Card>
    {header && <FutureArticleHeader title={title} />}
    <List>
      {[...Array(2)].map((_, i) => (
        <Fragment key={i}>
          <ListRow gridColumns={"4fr 1fr 1fr"}>
            <ListCell loading />
            <ListCell loading />
            <ListCell loading />
          </ListRow>
        </Fragment>
      ))}
    </List>
  </Card>
);
