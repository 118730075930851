import React, { useState, useEffect } from "react";
import {
  FutureArticleHeader,
  useFormValues,
  FutureAsyncFilter,
  FutureInputFilter,
  DateTimeInput,
  FutureSelectFilter,
  FilterReturn,
} from "@administrate/piston-ux";
import { observe } from "mobx";
import { Location, LocationEdge } from "../../generated/weblinkTypes";
import { DateCriteria } from "../../components/AffectBar/DatePickerFilter";
import { useDateFormatter } from "../../hooks/useDateFormatter";
import { getUniqueId } from "../../utils/displayHelpers";
import { formatFilterDateRange } from "../../utils/dateTimeHelpers";
import { useTranslation } from "react-i18next";

export type CourseRegisterableStatusFilter = {
  label: "Active" | "Completed";
  value: "active" | "completed";
};

export type LearnerCoursesFilters = {
  search: string;
  status?: FilterReturn;
  location?: Location;
  dates?: DateCriteria;
};

type LearnerCoursesListingHeaderProps = {
  loading: boolean;
  locationOptions: (inputValue: string) => Promise<LocationEdge[]>;
  onChange: (args: LearnerCoursesFilters) => void;
};

export const LearnerCoursesArticleHeader: React.FC<LearnerCoursesListingHeaderProps> =
  ({ loading, locationOptions, onChange }) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState<FilterReturn | undefined>();
    const [dates, setDates] = useState<DateCriteria | undefined>();
    const [selectedDates, setSelectedDates] =
      useState<DateCriteria | undefined>();

    const { simpleDateFormat } = useDateFormatter({ showDayName: true });
    const values = useFormValues({
      status: "",
      location: "",
      dates: {
        to: "",
        from: "",
      },
    });

    observe(values, change => {
      onChange({
        search,
        status,
        location: change.object["location"],
        dates,
      });
    });

    useEffect(() => {
      onChange({
        search,
        status,
        dates: selectedDates,
      });
    }, [search, status, onChange, selectedDates]);

    return (
      <FutureArticleHeader
        loading={loading}
        title={t("courses")}
        uniqueId={getUniqueId()}
        search={{
          label: t("course"),
          onChange: setSearch,
        }}
        filters={{ values }}
        id="learner-courses-header"
      >
        <FutureSelectFilter
          name="status"
          label={t("status")}
          direction="right"
          onChange={setStatus}
          options={[
            {
              label: t("active"),
              value: "active",
            },
            {
              label: t("completed"),
              value: "isComplete",
            },
          ]}
          id="learner-courses__status-filter"
        />
        <FutureAsyncFilter
          label={t("location")}
          name="location"
          loadOptions={locationOptions}
          formatter={(location: Location) => location.name}
          id="learner-courses__location-filter"
        />
        <FutureInputFilter
          label={t("dates")}
          selected={
            selectedDates
              ? formatFilterDateRange(selectedDates, simpleDateFormat)
              : ""
          }
          onSubmit={() => setSelectedDates(dates)}
          onClear={values => {
            values.dates.to = undefined;
            values.dates.from = undefined;
            setDates(undefined);
            setSelectedDates(undefined);
          }}
          id="learner-courses__dates-filter"
        >
          <DateTimeInput
            label={t("startDate")}
            type="date"
            name="dates.from"
            placeholder={t("today")}
            onChange={value =>
              setDates({
                ...dates,
                from: value,
              })
            }
          />
          <DateTimeInput
            label={t("endDate")}
            type="date"
            placeholder={t("future")}
            name="dates.to"
            onChange={value =>
              setDates({
                ...dates,
                to: value,
              })
            }
          />
        </FutureInputFilter>
      </FutureArticleHeader>
    );
  };
