import gql from "graphql-tag";

export const WEBLINK_BASIC_LOCATIONS_QUERY = gql`
  query basicLocationsQuery($filters: [LocationFieldFilter!]) {
    locations(filters: $filters, order: { field: name, direction: asc }) {
      edges {
        node {
          id
          name
          region {
            id
            name
          }
        }
      }
    }
  }
`;

export const WEBLINK_BASIC_LOCALES_QUERY = gql`
  query basicLocalsQuery($filters: [LocaleFieldFilter!]) {
    locales(filters: $filters, order: { field: name, direction: asc }) {
      edges {
        node {
          id
          language
        }
      }
    }
  }
`;

export const WEBLINK_BASIC_COURSES_QUERY = gql`
  query basicCoursesQuery($filters: [CourseFieldFilter!]) {
    courses(filters: $filters, order: { field: name, direction: asc }) {
      edges {
        node {
          name
          id
          code
        }
      }
    }
  }
`;

export const WEBLINK_REGIONS_QUERY = gql`
  query regions($filters: [RegionFieldFilter!]) {
    regions(filters: $filters, order: { field: name, direction: asc }) {
      edges {
        node {
          id
          name
          code
        }
      }
    }
  }
`;

export const WEBLINK_BASIC_CATEGORY_QUERY = gql`
  query basicCategoryQuery {
    categories(
      filters: { field: parentId, operation: isnull }
      order: { field: name, direction: asc }
    ) {
      edges {
        node {
          id
          name
          categories {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
