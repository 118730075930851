import {
  useQuery, // eslint-disable-line no-restricted-imports
  useMutation, // eslint-disable-line no-restricted-imports
  useLazyQuery, // eslint-disable-line no-restricted-imports
  QueryHookOptions,
  MutationTuple,
  MutationHookOptions,
  LazyQueryHookOptions,
  QueryTuple,
} from "@apollo/react-hooks";
import { DocumentNode } from "apollo-boost";
import { OperationVariables, QueryResult } from "@apollo/react-common";
import { useGraphQLContext } from "../providers/GraphQLProvider";

export const useLmsClient = (scormClient?: boolean) => {
  const context = useGraphQLContext();
  if (scormClient) {
    return context.scormLmsClient;
  }
  return context.lmsClient;
};

export function useLmsQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>,
  scormQuery?: false,
): QueryResult<TData, TVariables> {
  const client = useLmsClient(scormQuery);

  return useQuery(query, {
    ...options,
    client,
  });
}

export function useLmsMutation<TData = any, TVariables = Record<string, any>>(
  mutation: any,
  options?: MutationHookOptions<TData, TVariables> | undefined,
  scormMutation?: false,
): MutationTuple<TData, TVariables> {
  const client = useLmsClient(scormMutation);

  return useMutation(mutation, {
    ...options,
    client,
  });
}

export function useLazyLmsQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: LazyQueryHookOptions<TData, TVariables>,
  scormQuery?: false,
): QueryTuple<TData, TVariables> {
  const client = useLmsClient(scormQuery);

  return useLazyQuery(query, {
    ...options,
    client,
  });
}
