import React, { Fragment } from "react";
import {
  Card,
  FutureArticleHeader,
  ListEmpty,
  List,
  ListRow,
  ListCell,
  ListHeader,
} from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";

import { useDateFormatter } from "../../hooks/useDateFormatter";
import { Maybe, TrainingPass } from "../../generated/lmsTypes";

export const TrainingPasses: React.FunctionComponent<{
  trainingPasses?: Maybe<Array<TrainingPass>>;
}> = ({ trainingPasses }) => {
  const { t } = useTranslation();
  const { dateFormat } = useDateFormatter({ showTime: false });
  const activePasses =
    trainingPasses?.length === 0
      ? []
      : trainingPasses?.filter(pass => pass.lifecycleState === "active");

  return (
    <Card>
      <FutureArticleHeader title={t("trainingPass")} />
      <List>
        {activePasses?.length === 0 ? (
          <ListEmpty extraClass="empty-list">
            {t("noTrainingPassesAssigned")}
          </ListEmpty>
        ) : (
          <Fragment>
            <ListHeader
              gridColumns="1fr 1fr 1fr"
              headings={[
                { title: t("trainingPass") },
                { title: t("dateAssigned") },
                { title: t("expires") },
              ]}
            />
            {activePasses?.map((trainingPass, i) => (
              <ListRow key={i} gridColumns="1fr 1fr 1fr">
                <ListCell>{trainingPass?.trainingPassTypeName}</ListCell>
                <ListCell>
                  {dateFormat({ date: trainingPass?.validFrom })}
                </ListCell>
                <ListCell>
                  {dateFormat({ date: trainingPass?.validUntil })}
                </ListCell>
              </ListRow>
            ))}
          </Fragment>
        )}
      </List>
    </Card>
  );
};
