import React from "react";
import { useTranslation } from "react-i18next";
import { Contact } from "../generated/lmsTypes";
import { LEARNERS_QUERY } from "../queries/learnerManagement";
import { getOfficeNumber } from "../pages/Learner/LearnerInformation";
import { ExportModal } from "../toPistonUX/ExportModal";
import { Maybe } from "../types/Maybe";
import { useCancellableClient } from "../hooks/useCancellableClient";
import { useLmsClient } from "../hooks/lms";

export const LearnerExport = () => {
  const { t } = useTranslation();
  const client = useCancellableClient(useLmsClient());

  const fetchLearners = (cursor: Maybe<string>) => {
    try {
      const query = client.query({
        query: LEARNERS_QUERY,
        variables: {
          first: 100,
          order: {
            direction: "asc",
            field: "firstName",
          },
          after: cursor,
        },
      });

      return {
        connection: query.result.then(
          response => response.data.coordinatorManagedContacts,
        ),
        cancel: query.cancel,
      };
    } catch (e) {
      console.error("error", e);
      throw e;
    }
  };

  const transformData = (contacts: Contact[]) => {
    return contacts.map(node => {
      const phoneNumbers = node.phoneNumbers ? node.phoneNumbers : [];
      const ancestors = node.account.ancestors;
      const ancestorNames = ancestors.map(account => account.name);
      return {
        ID: atob(node.id).split(":")[1],
        "First Name": node.firstName,
        "Last Name": node.lastName,
        Email: node.emailAddress,
        Coordinator: node.isOverlappingCoordinator ? "Yes" : "No",
        "Job Role": node.jobRole ? node.jobRole : "",
        "Phone Number": getOfficeNumber(phoneNumbers) || "",
        "Account Name": node.account.name,
        "Account Breadcrumb": ancestorNames.join("/"),
      };
    });
  };

  return (
    <ExportModal
      title={t("exportLearners")}
      filenamePrefix="Learner"
      dataFetcher={fetchLearners}
      transformData={transformData}
      csvKeys={[
        "ID",
        "First Name",
        "Last Name",
        "Email",
        "Job Role",
        "Coordinator",
        "Phone Number",
        "Account Name",
        "Account Breadcrumb",
      ]}
    />
  );
};
