import React, { Fragment, FunctionComponent, useState } from "react";
import {
  Button,
  Card,
  FutureArticleHeader,
  LoadingScreen,
  StatusLabel,
  Tab,
  Title,
  V2Table,
} from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";

import { useDateFormatter } from "../../hooks/useDateFormatter";
import {
  TokenIssueData,
  TokenTypeDictionary,
  useTrainingTokenIssues,
} from "../../hooks/useTrainingTokenIssues";
import { ColorOptions } from "@administrate/piston-ux/lib/types";
import { TabMenuItemProps } from "@administrate/piston-ux/lib/Tab";
import { getNumberFormat } from "../../utils/displayHelpers";
import { Viewer } from "../../types/Viewer";
import { Maybe } from "../../generated/lmsTypes";
import { TokenIssueLog } from "./TokenIssueLog";
import {
  GridColumnAlignment,
  GridColumnWidth,
} from "@administrate/piston-ux/lib/types/table";
import { useIndependentBalanceTrainingTokensEnabled } from "../../hooks/useTrainingTokensEnabled";
import { Column } from "@administrate/piston-ux/lib/table/v2/types";

export const TrainingTokens: FunctionComponent<{ viewer: Viewer }> = ({
  viewer,
}) => {
  const { tokenIssues, tokenTypes, loading } = useTrainingTokenIssues();

  if (loading) return <LoadingScreen />;

  return (
    <TrainingTokensTable
      viewer={viewer}
      tokenIssues={tokenIssues}
      tokenTypes={tokenTypes}
    />
  );
};

export const TrainingTokensTable: FunctionComponent<{
  viewer: Viewer;
  tokenIssues: TokenIssueData[];
  tokenTypes: TokenTypeDictionary;
}> = ({ viewer, tokenIssues, tokenTypes }) => {
  const useIndependentBalances = useIndependentBalanceTrainingTokensEnabled();
  const { t } = useTranslation();
  const [showIssueLog, setShowIssueLog] = useState<string | undefined>();
  const { dateFormat } = useDateFormatter({ showTime: false });

  const viewerHasTokens = Object.keys(tokenTypes).length > 0;
  const initialTokenTypeId = viewerHasTokens
    ? Object.keys(tokenTypes)[0]
    : "empty";

  const [tokenTypesId, setTokenTypesId] = useState(initialTokenTypeId);

  const headings: Column<{
    [key: string]: any;
  }>[] = [
    {
      title: t("status"),
      key: "isExpired",
      formatter: (isExpired: boolean) => <IssueStatus isExpired={isExpired} />,
      width: GridColumnWidth.SM,
    },
    {
      title: t("issueDate"),
      key: "issueDate",
      formatter: (date: string) => dateFormat({ date }),
      width: useIndependentBalances ? GridColumnWidth.MD : GridColumnWidth.LG,
    },
    {
      title: t("expiryDate"),
      key: "expiryDate",
      formatter: (date: string | null) => date && dateFormat({ date }),
      width: useIndependentBalances ? GridColumnWidth.MD : GridColumnWidth.LG,
    },
    {
      title: t("issueQuantity"),
      key: "issueQuantity",
      formatter: (value: number) => getNumberFormat(value, viewer?.locale),
      width: useIndependentBalances ? GridColumnWidth.MD : GridColumnWidth.LG,
    },
    {
      title: t("used"),
      key: "used",
      formatter: (value: number) => getNumberFormat(value, viewer?.locale),
      width: useIndependentBalances ? GridColumnWidth.SM : GridColumnWidth.LG,
    },
    {
      title: t("balance"),
      key: "currentBalance",
      formatter: (value: number) => getNumberFormat(value, viewer?.locale),
      width: useIndependentBalances ? GridColumnWidth.SM : GridColumnWidth.AUTO,
    },
    {
      title: "",
      key: "id",
      formatter: (id: string) => (
        <Button
          type="suppressed"
          label={t("viewLog")}
          onClick={() => setShowIssueLog(id)}
        />
      ),
      width: GridColumnWidth.SM,
      alignment: GridColumnAlignment.RIGHT,
    },
  ];

  if (useIndependentBalances) {
    headings.splice(6, 0, {
      title: t("code"),
      key: "paymentCode",
      formatter: (value: string) => value && <code>{value}</code>,
      width: GridColumnWidth.AUTO,
    });
  }
  const getMappedTokenTabs = () => {
    let tabs: TabMenuItemProps[] = [];
    Object.values(tokenTypes).forEach(type => {
      tabs.push({
        label: type?.name,
        name: type?.id,
        onClick: () => setTokenTypesId(type?.id),
      });
    });
    return tabs;
  };

  return (
    <Fragment>
      <Card>
        <Tab.Container
          id="tokens"
          defaultTab={initialTokenTypeId}
          className="list-tabs"
        >
          <FutureArticleHeader
            title={t("trainingTokens")}
            tabs={viewerHasTokens ? getMappedTokenTabs() : []}
            extra={
              <CurrentBalance
                currentBalance={tokenTypes[tokenTypesId]?.totalAmount}
                locale={viewer?.locale}
                viewerHasTokens={viewerHasTokens}
              />
            }
            dataTestId="training-token-article-header"
          />
          {viewerHasTokens ? (
            Object.values(tokenTypes).map((type, i) => (
              <Tab.Pane name={type?.id} key={type.id}>
                <V2Table
                  headings={headings}
                  data={filterForIssueData(tokenIssues, type?.id)}
                  dataTestId={`training-token-table-${i}`}
                />
              </Tab.Pane>
            ))
          ) : (
            <Tab.Pane name="empty">
              <V2Table
                headings={headings}
                data={[]}
                dataTestId="training-token-empty-table"
              />
            </Tab.Pane>
          )}
        </Tab.Container>
      </Card>
      <TokenIssueLog
        show={!!showIssueLog}
        issueId={showIssueLog}
        onDone={() => setShowIssueLog(undefined)}
      />
    </Fragment>
  );
};

const filterForIssueData = (
  tokenIssues: TokenIssueData[],
  issueTypeId: string,
): TokenIssueData[] =>
  tokenIssues.filter(issue => issue.tokenTypeId === issueTypeId) ?? [];

const IssueStatus: FunctionComponent<{ isExpired: boolean }> = ({
  isExpired,
}) => {
  const { t } = useTranslation();
  return (
    <StatusLabel
      text={isExpired ? t("expired") : t("active")}
      color={isExpired ? ColorOptions.LightGrey : ColorOptions.Green}
    />
  );
};

const CurrentBalance: FunctionComponent<{
  locale?: string | null;
  viewerHasTokens: boolean;
  currentBalance: Maybe<number> | undefined;
}> = ({ locale, viewerHasTokens, currentBalance }) => {
  const { t } = useTranslation();

  return (
    <Title titleLevel="h3" dataTestId="training-token-total-balance">
      <Fragment>
        {t("totalBalance")}:{" "}
        {viewerHasTokens && currentBalance ? (
          <strong>{getNumberFormat(currentBalance, locale)}</strong>
        ) : (
          "-"
        )}
      </Fragment>
    </Title>
  );
};
