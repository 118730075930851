import gql from "graphql-tag";
import {
  ACCOUNT_ASSOCIATION_CONNECTION,
  LEARNING_TAG_CONNECTION,
  PAGE_INFO_FRAGMENT,
} from "./fragments";

const OBJECTIVES_WITHOUT_PATH_FRAGMENT = gql`
  fragment ObjectivesWithoutPathFragment on LearningObjective {
    __typename
    id
    ... on EventObjective {
      event {
        id
        name
        start
        end
        remainingPlaces
        location {
          id
          name
          region {
            id
            name
            code
          }
        }
      }
    }
    ... on CourseObjective {
      course {
        id
        code
        name
      }
    }
    ... on ExternalObjective {
      id
      name
    }
  }
`;

const LEARNING_PATH_OBJECTIVE_FRAGMENT = gql`
  fragment LPObjective on LearningPathObjective {
    id
    learningPath {
      id
      name
      learningObjectives {
        edges {
          node {
            ...ObjectivesWithoutPathFragment
          }
        }
      }
    }
  }

  ${OBJECTIVES_WITHOUT_PATH_FRAGMENT}
`;

const LEARNING_OBJECTIVES_FRAGMENT = gql`
  fragment LearningObjectives on LearningObjective {
    ...ObjectivesWithoutPathFragment
    ... on LearningPathObjective {
      ...LPObjective
    }
  }

  ${OBJECTIVES_WITHOUT_PATH_FRAGMENT}
  ${LEARNING_PATH_OBJECTIVE_FRAGMENT}
`;

export const WEBLINK_LEARNING_PATH_DETAILS_QUERY = gql`
  query getLearningPathDetails($id: String) {
    learningPaths(first: 1, filters: { field: id, value: $id, operation: eq }) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          id
          code
          name
          category
          categories {
            id
            name
          }
          lifecycleState
          imageUrl
          description
          start
          end
          customFieldValues {
            definitionKey
            value
          }
          prices {
            amount
            financialUnit {
              name
              symbol
              code
            }
            region {
              id
              code
              name
            }
          }
          price {
            amount
            financialUnit {
              code
            }
          }
          remainingPlaces
          learningObjectives {
            edges {
              cursor
              node {
                ...LearningObjectives
              }
            }
          }
          requireObjectiveFulfillment
          includedInPassTypeIds
          isFeatured
          learningTags {
            ...LearningTagDetails
          }
          accountAssociations {
            ...AccountAssociationDetails
          }
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
  ${LEARNING_OBJECTIVES_FRAGMENT}
  ${LEARNING_TAG_CONNECTION}
  ${ACCOUNT_ASSOCIATION_CONNECTION}
`;

export const WEBLINK_LEARNING_PATH_EVENT_SELECTION_OPTIONS = gql`
  query getEventSelectionOptions(
    $learningPathId: ID!
    $filters: [EventSelectionOptionFieldFilter!]
  ) {
    learningPathEventSelectionOptions(
      learningPathId: $learningPathId
      filters: $filters
    ) {
      pageInfo {
        totalRecords
      }
      edges {
        node {
          eventOptionId
          event {
            id
            name
            location {
              id
              name
              region {
                id
                code
                name
              }
            }
            locale {
              id
              language
            }
            prices {
              amount
              financialUnit {
                name
                symbol
                code
              }
              region {
                id
                code
                name
              }
            }
            start
            end
            classroomStart
            classroomEnd
            remainingPlaces
            registrationOpen
            registrationOpensAt
          }
        }
      }
    }
  }
`;

export const lms_fragments = {
  nestedDocument: gql`
    fragment nestedDocument on Document {
      id
      identifyingHash
      displayName
      htmlDescription
      type
      fileSizeStr
    }
  `,
  nestedCourse: gql`
    fragment nestedCourse on Course {
      id
      title
      learningMode
      message
      code
    }
  `,
  nestedLearningPath: gql`
    fragment nestedLearningPath on LearningPath {
      id
      name
      longDescription
      enforceObjectiveOrder
      objectives {
        edges {
          node {
            id
            order
            credits
            expectedCompletionDays
            ... on ExternalObjective {
              name
              external
            }
            ... on URLObjective {
              url
            }
            ... on DocumentObjective {
              document {
                ...nestedDocument
              }
            }
            ... on CourseObjective {
              course {
                ...nestedCourse
              }
            }
          }
        }
      }
    }
  `,
};

export const LMS_LEARNING_PATH_DETAILS_QUERY = gql`
  query getLearningPath($registrationId: String!) {
    registerables(
      filters: [{ field: id, operation: eq, value: $registrationId }]
    ) {
      edges {
        node {
          id
          achievement {
            id
            identifier
            expiresAt
            achievementType {
              id
              name
            }
            certificateUrl
            documentUrls
          }
          ... on LearningPathRegistration {
            expectedProgress
            progressMade
            startDate
            passAccessExpired
            trainingPassTypeId
            learningPath {
              id
              name
              longDescription
              imageUrl
              enforceObjectiveOrder
              achievementType {
                id
                name
              }
              objectives {
                edges {
                  node {
                    id
                    order
                    credits
                    expectedCompletionDays
                    ... on ExternalObjective {
                      name
                      external
                    }
                    ... on URLObjective {
                      url
                    }
                    ... on DocumentObjective {
                      document {
                        ...nestedDocument
                      }
                    }
                    ... on CourseObjective {
                      course {
                        ...nestedCourse
                      }
                    }
                    ... on LearningPathObjective {
                      learningPath {
                        ...nestedLearningPath
                      }
                    }
                  }
                }
              }
            }
            results {
              edges {
                node {
                  id
                  objectiveId
                  completed
                  ... on CourseOutcome {
                    registrationId
                    course {
                      id
                      startDate
                      endDate
                      location {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${lms_fragments.nestedDocument}
  ${lms_fragments.nestedCourse}
  ${lms_fragments.nestedLearningPath}
`;

export const COURSE_TEMPLATE_EVENTS = gql`
  query events($courseCode: String) {
    events(
      filters: [{ field: courseCode, operation: eq, value: $courseCode }]
    ) {
      edges {
        node {
          id
          location {
            name
          }
          start
          accessDuration
          deliveryMethod
          start
          end
          remainingPlaces
          classroomStart
          classroomEnd
          timeZoneName
        }
      }
    }
  }
`;

export const WEBLINK_LEARNING_PATH_OBJECTIVES_QUERY = gql`
  query getLearningPathObjectives(
    $learningPathId: String!
    $first: Int
    $offset: Int
  ) {
    learningPaths(
      filters: { field: id, value: $learningPathId, operation: eq }
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          id
          learningObjectives(first: $first, offset: $offset) {
            edges {
              cursor
              node {
                ...LearningObjectives
              }
            }
            pageInfo {
              ...PageInfo
            }
          }
        }
      }
    }
  }
  ${LEARNING_OBJECTIVES_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;
