/**
 * This is an arbitrary number to still add extensions to values like displayName="My File.Something Very Important".
 */
const SUFFIX_SEARCH_LENGTH = 10;

export const generateDocumentFilename = (
  displayName: string | undefined,
  originalExtension: string | undefined,
): string | undefined => {
  if (!displayName) {
    return undefined;
  }
  if (
    !originalExtension ||
    displayName.includes(".", displayName.length - SUFFIX_SEARCH_LENGTH)
  ) {
    return displayName;
  }
  return displayName + "." + originalExtension;
};
