import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FutureArticleHeader } from "@administrate/piston-ux";

type LearnerLearningPathsListingHeaderProps = {
  onSearchChange: (searchString: string) => void;
  title?: "Learning Paths";
};

export const LearnerLearningPathsListingHeader: React.FC<LearnerLearningPathsListingHeaderProps> =
  ({ onSearchChange, title = "Learning Path" }) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState("");

    useEffect(() => {
      onSearchChange(search);
    }, [search, onSearchChange]);

    return (
      <FutureArticleHeader
        title={title}
        id="learner__learning-paths-header"
        search={{
          label: t("learningPaths"),
          onChange: setSearch,
        }}
      />
    );
  };
