import gql from "graphql-tag";
import { PAGE_INFO_FRAGMENT } from "./fragments";

export const CATALOGUE_QUERY = gql`
  query catalogue($offset: Int!, $filters: [CatalogueFieldFilter!]) {
    catalogue(offset: $offset, first: 12, filters: $filters) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          id
          name
          category {
            id
            name
          }
          imageUrl
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

export const COURSE_DETAILS_QUERY = gql`
  query getCourseDetails($id: String) {
    catalogue(first: 1, filters: { field: id, value: $id, operation: eq }) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          id
          name
          description
          teaserDescription
          imageUrl
          code
          topicsCovered
          faqs
          outcomes
          prerequisites
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

export const WEBLINK_CATALOG_QUERY = gql`
  query catalogue(
    $offset: Int
    $first: Int
    $filters: [CatalogueFieldFilter!]
    $order: CatalogueFieldOrder
    $search: String
  ) {
    catalogue(
      offset: $offset
      first: $first
      filters: $filters
      order: $order
      search: $search
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          __typename
          ... on Course {
            name
            id
            category
            imageUrl
            priceRange {
              minimumPrice {
                amount
              }
              normalPrice {
                amount
              }
              maximumPrice {
                amount
              }
            }
            isFeatured
            includedInPassTypeIds
          }
          ... on LearningPath {
            id
            name
            category
            lifecycleState
            imageUrl
            description
            customFieldValues {
              definitionKey
              value
            }
            price {
              amount
              financialUnit {
                name
                symbol
                code
              }
              region {
                id
                code
                name
              }
            }
            isFeatured
            includedInPassTypeIds
          }
        }
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

export const WEBLINK_BASIC_CATALOG_QUERY = gql`
  query catalogue(
    $filters: [CatalogueFieldFilter!]
    $order: CatalogueFieldOrder
    $search: String
  ) {
    catalogue(filters: $filters, order: $order, search: $search) {
      edges {
        node {
          ... on Course {
            name
            id
          }
          ... on LearningPath {
            id
            name
          }
        }
      }
    }
  }
`;
