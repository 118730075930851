import React, { useCallback, useState } from "react";
import {
  AlertModal,
  BodyText,
  Button,
  Container,
  LoadingBar,
  Prompt,
} from "@administrate/piston-ux";
import { RawStaticInput } from "@administrate/piston-ux/lib/StaticInput";
import { useTranslation } from "react-i18next";
import {
  concat,
  groupBy,
  intersection,
  isEmpty,
  isEqual,
  map,
  sortBy,
} from "lodash";
import kryterionLogoImg from "../../../images/kryterion-webassessor.png";
import { useGlobalStore } from "../../../providers/GlobalStore";
import { useLmsMutation } from "../../../hooks/lms";
import { ENSURE_KRYTERION_ACCOUNT } from "../../../mutations/registrations";
import {
  ContentResultStatus,
  FieldError,
  Maybe,
} from "../../../generated/lmsTypes";
import { useViewer } from "../../../providers/ViewerProvider";

export const KryterionObjective: React.FunctionComponent<{
  url: string;
  instructions?: string;
  title?: string;
  externalCourseId: string;
  contentId: string;
  registrationId: string;
  status?: ContentResultStatus;
  setInteractionId: () => void;
  accessedPreviously?: boolean;
}> = ({
  url,
  instructions,
  title,
  externalCourseId,
  contentId,
  registrationId,
  status,
  setInteractionId,
  accessedPreviously,
}) => {
  const { t } = useTranslation();
  const { convertLMSImageUrl } = useGlobalStore();
  const { viewer } = useViewer();

  const kryterionExternalIdNames = ["kryterion-id", "kryterion-login"];
  const hasExternalIds = isEqual(
    intersection(viewer?.externalIdNames || [], kryterionExternalIdNames),
    kryterionExternalIdNames,
  );

  const [kryterionAccountExists, setKryterionAccountExists] =
    useState(hasExternalIds);

  const [clickedTakeTheTest, setClickedTakeTheTest] = useState(false);
  const [ensureKryterionAccountErrors, setEnsureKryterionAccountErrors] =
    useState<Maybe<FieldError>[]>([]);

  const openKryterionUrl = useCallback(() => {
    window.open(url);
  }, [url]);

  const [ensureKryterionAccount, { loading: ensureKryterionAccountIsLoading }] =
    useLmsMutation(ENSURE_KRYTERION_ACCOUNT, {
      onCompleted: data => {
        const accountExists =
          data.registration?.ensureKryterionAccount?.kryterionAccountExists ||
          false;
        const errors = data.registration?.ensureKryterionAccount?.errors || [];
        if (accountExists) {
          openKryterionUrl();
        }
        setKryterionAccountExists(accountExists);
        setEnsureKryterionAccountErrors(errors);

        if (isEmpty(errors)) {
          if (!hasExternalIds) {
            viewer!.externalIdNames = concat(
              viewer?.externalIdNames || [],
              kryterionExternalIdNames,
            );
          }

          setInteractionId();
        }
      },
    });

  const handleClickTakeTheTest = useCallback(async () => {
    setClickedTakeTheTest(true);

    if (kryterionAccountExists) {
      openKryterionUrl();
      setInteractionId();
    } else {
      await ensureKryterionAccount({
        variables: {
          registrationId: registrationId,
          contentId: contentId,
        },
      });
    }
  }, [
    contentId,
    registrationId,
    ensureKryterionAccount,
    kryterionAccountExists,
    openKryterionUrl,
    setInteractionId,
  ]);

  const showOnceYouHaveTakenTestPrompt =
    (accessedPreviously && status === ContentResultStatus.Incomplete) ||
    (kryterionAccountExists && clickedTakeTheTest);

  return (
    <Container>
      {title && <h1>{title}</h1>}
      <LoadingBar isLoading={ensureKryterionAccountIsLoading} />
      {instructions && (
        <div dangerouslySetInnerHTML={{ __html: instructions }} />
      )}
      <RawStaticInput value={externalCourseId} label={t("productCode")} />
      <Button
        label={t("takeTheTest")}
        type="primary"
        onClick={handleClickTakeTheTest}
        disabled={ensureKryterionAccountIsLoading}
      />
      <img src={convertLMSImageUrl(kryterionLogoImg)} alt="Kryterion Logo" />
      {!kryterionAccountExists && (
        <div style={{ marginTop: 15 }}>
          <Prompt type="warning" message={t("kryterionAccountCreated")} />
        </div>
      )}
      {showOnceYouHaveTakenTestPrompt && (
        <div style={{ marginTop: 15 }}>
          <Prompt type="warning" message={t("onceYouHaveTakenKryterionTest")} />
        </div>
      )}
      <KryterionErrorModal
        errors={ensureKryterionAccountErrors}
        onDone={() => setEnsureKryterionAccountErrors([])}
      />
    </Container>
  );
};

const KryterionErrorModal: React.FunctionComponent<{
  errors: Maybe<FieldError>[];
  onDone: () => void;
}> = ({ errors, onDone }) => {
  const { t } = useTranslation();

  const contactDetailsErrors = errors.filter(
    error => error != null && error?.label === "contact_details",
  ) as FieldError[];

  return (
    <AlertModal
      title={t("unableToCreateAnAccount")}
      show={errors.length > 0}
      onDone={onDone}
      type="warningWithoutCancel"
    >
      {errors && <p>{t("unableToCreateKryterionAccount")}</p>}
      <KryterionContactDetailsErrors errors={contactDetailsErrors} />
    </AlertModal>
  );
};

const KryterionContactDetailsErrors: React.FunctionComponent<{
  errors: FieldError[];
}> = ({ errors }) => {
  const { t } = useTranslation();
  const groupedErrors = groupBy(errors, error => error.message?.split(".")[0]);

  return (
    <div>
      <KryterionErrorList
        errors={groupedErrors.missing || []}
        listTitleKey="kryterionMissingInfo"
      />
      <KryterionErrorList
        errors={groupedErrors.invalid || []}
        listTitleKey="kryterionInvalidInfo"
      />
      {!isEmpty(groupedErrors) && (
        <BodyText className="text-left" message={t("kryterionPleaseUpdate")} />
      )}
    </div>
  );
};

const KryterionErrorList: React.FunctionComponent<{
  errors: FieldError[];
  listTitleKey: string;
}> = ({ errors, listTitleKey }) => {
  const { t } = useTranslation();

  const errorMessages = sortBy(
    map(errors, error => {
      return {
        key: error.message,
        value: t(`kryterionErrors.${error.message}`),
      };
    }),
    ["key"],
  );

  return (
    <div>
      {errors.length > 0 && (
        <div>
          <p className="text-left" style={{ fontWeight: "bold" }}>
            {t(listTitleKey)}{" "}
          </p>
          <ul>
            {errorMessages.map(error => (
              <li className="text-left" key={error.key!}>
                {error.value}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
