import gql from "graphql-tag";

export const CATEGORY_QUERY = gql`
  query categories {
    categories {
      edges {
        node {
          id
          name
          subcategories {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
