import React, { FunctionComponent, useCallback } from "react";
import {
  OptionType,
  FutureArticleHeader,
  FutureSelectFilter,
  useTypedFormValues,
} from "@administrate/piston-ux";
import { Maybe } from "@administrate/piston-ux/lib/types";
import { useTranslation } from "react-i18next";
import { TrainingPassType } from "../../generated/lmsTypes";
import { CategoryEdge } from "../../generated/weblinkTypes";
import { useCatalogFilters } from "./CatalogFilterContext";

export const CatalogArticleHeader: FunctionComponent<{
  categories: Maybe<CategoryEdge>[];
  trainingPassTypes: Maybe<TrainingPassType>[];
  loading: boolean;
}> = ({ categories, trainingPassTypes, loading }) => {
  const { t } = useTranslation();

  const { filters, setFilterValue } = useCatalogFilters();

  const onSearch = useCallback(
    value => {
      setFilterValue("search", value);
    },
    [setFilterValue],
  );

  const values = useTypedFormValues(filters);
  const categoryOptions = getCategoryOptions(categories);
  const trainingPasses = getPassTypeOptions(trainingPassTypes);
  const showTrainingPassTypeFilter = trainingPasses.length > 0;

  return (
    <FutureArticleHeader
      loading={loading}
      search={{
        initialValue: filters.search,
        label: t("catalog"),
        onChange: onSearch,
      }}
      filters={{
        values,
      }}
    >
      <FutureSelectFilter
        name="type"
        label={t("type")}
        direction="right"
        onChange={type => setFilterValue("type", type.selected?.value ?? null)}
        options={[
          {
            label: t("courses"),
            value: "CourseTemplate",
          },
          {
            label: t("learningPaths"),
            value: "LearningPath",
          },
        ]}
      />
      <FutureSelectFilter
        name="category"
        label={t("category")}
        direction="right"
        onChange={category => {
          setFilterValue("category", category.selected?.value ?? null);
        }}
        options={categoryOptions}
      />
      {showTrainingPassTypeFilter ? (
        <FutureSelectFilter
          name="trainingPassType"
          label={t("trainingPass")}
          direction="right"
          onChange={trainingPassType => {
            setFilterValue(
              "trainingPassType",
              trainingPassType.selected?.value ?? null,
            );
          }}
          options={trainingPasses}
        />
      ) : (
        <></>
      )}
    </FutureArticleHeader>
  );
};

const getCategoryOptions: (categories: Maybe<CategoryEdge>[]) => OptionType[] =
  categories => {
    const empty = {
      label: "",
      value: "",
    };
    return (
      categories &&
      categories.map((category: Maybe<CategoryEdge>) => {
        if (category && category.node) {
          return {
            label: category.node.name ? category.node.name : "",
            value: category.node.id ? category.node.id : "",
            subOptions:
              category.node.categories &&
              category.node.categories.edges &&
              category.node.categories.edges.length > 0
                ? getCategoryOptions(category.node.categories.edges)
                : undefined,
          };
        }
        return empty;
      })
    );
  };

const getPassTypeOptions: (
  passType: Maybe<TrainingPassType>[],
) => OptionType[] = passTypes => {
  const empty = {
    label: "",
    value: "",
  };
  return (
    passTypes &&
    passTypes.map((passType: Maybe<TrainingPassType>) => {
      if (passType) {
        return {
          label: passType.name ?? "",
          value: passType.id ?? "",
        };
      }
      return empty;
    })
  );
};
