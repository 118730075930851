import React, { Fragment, FunctionComponent } from "react";
import { AlertModal } from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { Cart } from "../generated/weblinkTypes";
import { CheckoutURLS } from "../providers/CheckoutProvider";
import { useHistory } from "../useHistory";

export const WonCartAlert: FunctionComponent<{
  cart: Cart | null | undefined;
}> = ({ cart }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const showCart = hasWonCart(cart);

  return (
    <Fragment>
      {showCart && (
        <AlertModal
          title={t("This booking is already confirmed")}
          show={true}
          onDone={() => history.push(`/checkout/${CheckoutURLS.OrderComplete}`)}
          type="warningWithoutCancel"
        />
      )}
    </Fragment>
  );
};

export const hasWonCart = (cart: Cart | null | undefined): boolean => {
  return cart !== null && cart?.state === "won";
};
