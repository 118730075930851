import React, { FunctionComponent } from "react";
import { Button, Link } from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import {
  ContentResult,
  ContentResultEdge,
  ContentResults,
  Contents,
} from "../types/Registration";
import { useHistory } from "../useHistory";
import { ContentResultStatus, Maybe } from "../generated/lmsTypes";
import { Viewer } from "../types/Viewer";
import { checkHasLockedContent } from "./TrainingPass/ContentStatusLabel";

export const NextContentButton: FunctionComponent<{
  type: "link" | "button";
  contentResults: ContentResults;
  courseContent: Contents;
  registrationId: string;
  viewer?: Maybe<Viewer>;
}> = ({ type, contentResults, registrationId, courseContent, viewer }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const label = t("continueCourse");
  const nextContent = findNextContent(
    contentResults.edges,
    courseContent,
    viewer,
  );

  const nextCourseContent =
    nextContent && findNextCourse(nextContent, courseContent);

  if (!nextContent) {
    return <Button type="primary" disabled onClick={() => []} label={label} />;
  }

  const link = `/my-courses/course/${registrationId}/content/${nextContent.contentId}`;
  let nextModuleLabel: string | undefined;

  if (nextCourseContent && nextCourseContent[0]) {
    if (nextCourseContent[0].node.displayName) {
      nextModuleLabel = nextCourseContent[0].node.displayName;
    } else if (nextCourseContent[0].node.htmlDescription) {
      nextModuleLabel = nextCourseContent[0].node.htmlDescription;
    } else if (nextCourseContent[0].node.type === "external") {
      nextModuleLabel = t("externalActivity");
    }
  }

  if (type === "button") {
    return (
      <Button
        type="primary"
        label={label}
        ariaLabel={nextModuleLabel && `${t("nextModule")}: ${nextModuleLabel}`}
        onClick={() => history.push(link)}
      />
    );
  }

  const labelAndNextModule = !!nextModuleLabel ? (
    <div>
      {label}
      <span className="sr-only">
        {" "}
        {`${t("nextModule")}: ${nextModuleLabel}`}
      </span>
    </div>
  ) : (
    label
  );

  return (
    <Link
      type="primary"
      url={link}
      label={labelAndNextModule}
      routerLink={RouterLink}
    />
  );
};

export const findNextContent = (
  contents: ContentResultEdge[],
  courseContents: Contents,
  viewer?: Maybe<Viewer>,
) => {
  const courseContentIds = courseContents.edges.map(courseContent => {
    const hasLockedPremiumContent = checkHasLockedContent(
      courseContent.node,
      viewer,
    );
    return !hasLockedPremiumContent && courseContent?.node.id;
  });

  const incompleteCourseContents = contents.filter(
    content =>
      content?.node?.status === ContentResultStatus.Incomplete &&
      content?.node?.contentId &&
      courseContentIds.includes(content.node.contentId),
  );

  return incompleteCourseContents.length > 0
    ? incompleteCourseContents[0].node
    : undefined;
};

export const findNextCourse = (
  nextContent: ContentResult,
  courseContent: Contents,
) =>
  courseContent.edges.filter(
    course => course?.node.id === nextContent.contentId,
  );
