import React, { FunctionComponent } from "react";
import { useParams, useHistory } from "../../useHistory";
import { useLmsClient, useLmsMutation } from "../../hooks/lms";
import { COORDINATED_LEARNER_QUERY } from "../../queries/learnerManagement";
import { getContactVariables } from "../../components/ContactPicker";
import { extractNodes } from "../../utils/extractNodes";
import {
  FormArray,
  useFormValues,
  Form,
} from "@administrate/piston-ux/lib/Form";
import { LearnerRow, GLOBAL_LEARNER_LIMIT } from "../Catalog/LearnerSelection";
import {
  LoadingScreen,
  Alert,
  Submit,
  Card,
  Input,
  List,
  FutureArticleHeader,
  Row,
  Col,
  FutureDetailPage,
  DetailPageContent,
} from "@administrate/piston-ux";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import {
  Contact,
  Query,
  FilterOperation,
  LearnerField,
  EventLearner,
} from "../../generated/weblinkTypes";
import { WEBLINK_BOOKING_BY_ID_QUERY } from "../../queries/bookings";
import { useWebLinkQuery } from "../../hooks/weblink";
import { ASSOCIATE_UNNAMED_LEARNER_WITH_CONTACTS } from "../../queries/events";
import { getUniqueId } from "../../utils/displayHelpers";
import { ItemInformation } from "../../components/ItemInformation";
import { formatDateRange } from "../../utils/dateTimeHelpers";
import { DEFAULT_EVENT } from "./ExistingEventBooking";
import { useDateFormatter } from "../../hooks/useDateFormatter";
import { DetailPageOverview } from "@administrate/piston-ux/lib/layouts/DetailPage/DetailPageOverview";

const DEFAULT_UNNAMED_LEARNER: EventLearner = {
  id: "unnamed",
  quantity: 0,
  isUnnamed: true,
  isCancelled: false,
  customFieldValues: [],
};

export const UnnamedLearnerDetails: FunctionComponent = () => {
  const { t } = useTranslation();
  const { dateFormat } = useDateFormatter({ showTime: true });
  const history = useHistory();
  const { bookingId, learnerId } = useParams<{bookingId:string, learnerId:string}>();
  const [
    addContactsToUnnamedLearner,
    { loading: mutationLoading, error: mutationError },
  ] = useLmsMutation(ASSOCIATE_UNNAMED_LEARNER_WITH_CONTACTS);

  const { loading, data, error } = useWebLinkQuery<Query>(
    WEBLINK_BOOKING_BY_ID_QUERY,
    {
      variables: {
        bookingId,
        learnerOffset: 0,
        learnerFilters: [
          {
            field: LearnerField.Id,
            operation: FilterOperation.Eq,
            value: learnerId,
          },
        ],
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    },
  );

  const booking =
    data?.viewer?.eventBookings?.edges?.[0]?.node || DEFAULT_EVENT;

  const learner =
    data?.viewer?.eventBookings?.edges?.[0]?.node.learners?.edges?.[0]?.node ||
    DEFAULT_UNNAMED_LEARNER;
  const eventLearner = learner as EventLearner;
  const maxLearners = eventLearner.quantity;

  const values = useFormValues({
    learnerNumber: 0,
    learners: [],
  });

  const lmsClient = useLmsClient();
  const contacts = (inputValue: string) =>
    lmsClient
      .query({
        query: COORDINATED_LEARNER_QUERY,
        variables: getContactVariables(inputValue),
      })
      .then(result => {
        return [...extractNodes(result.data.coordinatorManagedContacts.edges)];
      });

  const handleChangeLearnerNumber = (value: string) => {
    const newLearnerLength = parseInt(value);

    if (
      newLearnerLength > maxLearners ||
      newLearnerLength > GLOBAL_LEARNER_LIMIT
    ) {
      return;
    }

    if (newLearnerLength >= 0) {
      if (newLearnerLength < values.learners.length) {
        values.learners = values.learners.slice(0, newLearnerLength);
      } else if (newLearnerLength > values.learners.length) {
        const unnamedLearners = [];
        for (let i = values.learners.length; i < newLearnerLength; i++) {
          unnamedLearners.push({ contact: null });
        }

        values.learners = [...values.learners, ...unnamedLearners];
      }
    }
  };

  const handleSubmit = async () => {
    const response = await addContactsToUnnamedLearner({
      variables: {
        input: {
          registrationId: learnerId,
          contactIds: values.learners.map(
            (learner: { contact: Contact }) => learner.contact.id,
          ),
        },
      },
    });

    if (response.errors && response.errors.length > 0) {
      console.error(
        "error while associating Learner with Contacts",
        response.errors,
      );
      return;
    }

    const resBookingId = get(
      response,
      "data.registration.associateUnnamedEventLearnerWithContacts.registrations[0].id",
      null,
    );
    const mutationErrors = get(
      response,
      "data.registration.associateUnnamedEventLearnerWithContacts.errors",
      null,
    );

    if ((mutationErrors && mutationErrors.length > 0) || !resBookingId) {
      console.error(
        "Encountered an error while associating Learner with Contacts",
        resBookingId,
        bookingId,
        learnerId,
        mutationErrors,
      );
      return;
    }

    history.push(`/learner-management/bookings/${bookingId}/event`);
  };

  if (error) {
    return (
      <Alert type="error" message={t("thereWasAProblemFetchingThisBooking")} />
    );
  }

  const isLoadingItemDetails = loading && !data;

  if (isLoadingItemDetails) {
    return <LoadingScreen />;
  }

  return (
    <FutureDetailPage
      back={{
        label: t("booking"),
        onClick: () =>
          history.push(`/learner-management/bookings/${bookingId}/event`),
      }}
      title={!isLoadingItemDetails ? booking.name : ""}
      legacyId={bookingId}
      detailType="Booking"
      form={{
        saveState: null,
      }}
      page="overview"
      layout="adjacent"
    >
      <DetailPageOverview>
        <ItemInformation
          data={{
            location: booking.location ?? undefined,
            dateAndTime: booking.start
              ? formatDateRange(booking.start, booking.end || "", dateFormat)
              : undefined,
          }}
          loading={loading}
          name={t("course")}
          image={booking.imageUrl}
        />
      </DetailPageOverview>
      <DetailPageContent>
        <Form
          type="raw"
          values={values}
          onSubmit={handleSubmit}
          disabled={mutationLoading}
        >
          <Card>
            <FutureArticleHeader title={t("addLearners")} extra={<Submit />} />
            <Row>
              <Col xs={6} sm={4} lg={3}>
                <Input
                  type="number"
                  label={t("numberOfLearners")}
                  name="learnerNumber"
                  autoFocus
                  onChange={handleChangeLearnerNumber}
                  valid={v => {
                    const value = parseInt(v);
                    return (
                      !!v &&
                      value > 0 &&
                      value <= maxLearners &&
                      value <= GLOBAL_LEARNER_LIMIT
                    );
                  }}
                  min={1}
                  max={maxLearners}
                  uniqueId={getUniqueId()}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="pb-2">
                {`${t("youCanOnlyUpdate")} ${maxLearners} ${
                  maxLearners > 1 ? t("_learners") : t("_learner")
                }`}
              </Col>
            </Row>
            <hr />
            {mutationError && (
              <Alert
                type="error"
                message={t(
                  "anErrorOccurredWhileAssociatingContactsToTheLearner",
                )}
                glyph="removeSign"
                overPage={false}
              />
            )}
            <List extraClass="learners">
              <FormArray name="learners">
                <LearnerRow
                  contacts={contacts}
                  onChange={() => {}}
                  disabled={false}
                />
              </FormArray>
            </List>
          </Card>
        </Form>
      </DetailPageContent>
    </FutureDetailPage>
  );
};
