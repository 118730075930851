import React, { FunctionComponent, useState, Fragment, useEffect } from "react";
import {
  BodyText,
  Card,
  List,
  ListRow,
  ListCell,
  Form,
  useFormValues,
  Button,
  StatusLabel,
  ListDrawer,
  StaticInput,
  Input,
  SafeHTMLDisplay,
  AlertModal,
  Alert,
  Select,
  OptionType,
  FutureArticleHeader,
  Placeholder,
  Prompt,
  Tooltip,
  OpacityOverlay,
  LoadingBar,
  FutureDetailPage,
  AssistantPanel,
  DetailPageContent,
  StyleBlock,
  UtilityStyle,
} from "@administrate/piston-ux";
import { hasErrors, Submit } from "@administrate/piston-ux/lib/Submit";
import {
  FormArray,
  FormConsumer,
  FormActions,
  useTypedFormValues,
} from "@administrate/piston-ux/lib/Form";
import { ListEmpty } from "@administrate/piston-ux/lib/List";
import { useTranslation } from "react-i18next";
import { ColorOptions } from "@administrate/piston-ux/lib/types";
import { get, includes } from "lodash";
import { observer, Observer } from "mobx-react-lite";

import { useHistory, useParams } from "../../useHistory";
import { useWebLinkQuery } from "../../hooks/weblink";

import learningPathImagePlaceholder from "../../images/course-placeholder.png";
import { WEBLINK_LEARNING_PATH_DETAILS_QUERY } from "../../queries/learningPaths";
import {
  getLearningObjectiveOptions,
  getLPInfo,
  getRegionId,
  getRegions,
  handleNestedPathObjectiveSelection,
  isPathStartable,
  LearningObjectiveUnion,
} from "../../utils/weblinkLearningPathHelpers";
import {
  Query as WebLinkQuery,
  Price,
  LearningObjectiveEdge,
  Region,
  LearningPath as LearningPathType,
  LearningPathObjective,
} from "../../generated/weblinkTypes";
import { useWebLinkMutation } from "../../hooks/weblink";
import {
  ADD_CART_LINE_ITEM_MUTATION,
  CREATE_CART_MUTATION,
  PLACE_ORDER_MUTATION,
} from "../../queries/cart";
import { Errors } from "../../types/Portal";
import {
  CourseObjectiveOption,
  NestedPathObjectiveOptionChoice,
  LearningPathFormValues,
  RegisterPassholderPathMutationResponseType,
} from "../../types/LearningPaths";
import {
  Maybe,
  RegisterLearningPathLearnerResponseType,
  Query as LMSQuery,
  TrainingPass,
  TrainingPassType,
} from "../../generated/lmsTypes";
import { getUniqueId, getFinancialFormat } from "../../utils/displayHelpers";
import { LearningPathDateAndLocations } from "./LearningPathDateAndLocations";
import { NestedPathObjectiveModal } from "./NestedPathObjectives";
import { useWeblinkSettings } from "../../hooks/useWeblinkSettings";
import { useBookerIntention } from "../../hooks/useBookerIntention";
import { BookerIntention } from "../Order/BookingPage";
import { CartWidget } from "../Cart/CartWidget";
import { useViewer } from "../../providers/ViewerProvider";
import { EventDateRange } from "../../components/EventDateRange";
import { RECENT_REGISTERABLES_QUERY } from "../../queries/registerables";
import { useLmsMutation, useLmsQuery } from "../../hooks/lms";
import { checkRegistrationAndNavigate } from "../../utils/cart";
import {
  computePassStatus,
  TrainingPassContentStatusLabel,
} from "../../components/TrainingPass";
import { throwIfErrors } from "../../utils/errorHelpers";
import { REGISTER_PASSHOLDER_TO_LEARNING_PATH } from "../../mutations/registrations";
import { ExecutionResult } from "@apollo/react-common";
import { useTrainingPassTypes } from "../../hooks/useTrainingPassTypes";
import { useViewersLastCart } from "../../hooks/useViewersLastCart";
import { sendEvent } from "../../analytics";
import { ArrElement } from "../../types/ArrElement";
import { useAnalytics } from "../../providers/AnalyticsProvider";
import { CartEvent, ProductViewEvent } from "../../analytics/events";

const objectiveColumn = "150px 3fr 2fr 3fr 2fr";

const getRegisterPassholderResponseData = (
  response: ExecutionResult<RegisterPassholderPathMutationResponseType>,
) =>
  response?.data?.registration
    ?.registerLearningPathLearner as RegisterLearningPathLearnerResponseType;

const extractRegistrationId = (
  registeredLearningPathLearner: RegisterLearningPathLearnerResponseType,
) => registeredLearningPathLearner.registration?.id as string;

export const LearningPath: FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { captureEvent } = useAnalytics();
  const { learningPathId, catalogType } =
    useParams<{ learningPathId: string; catalogType: BookerIntention }>();
  const [apiError, setApiError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [startableLP, setStartableLP] = useState(false);
  const [registering, setRegistering] = useState(false);
  const { viewer } = useViewer();
  const [bookerIntention, setBookerIntention] = useBookerIntention();
  if (bookerIntention !== catalogType) {
    setBookerIntention(catalogType);
  }
  const { cart: viewersLastCart, loading: viewersCartLoading } =
    useViewersLastCart();

  const {
    hidePricesBeforePricingAgreementApplied,
    hidePrices,
    cartlessCheckout,
    regionCode: portalRegionCode,
    loading: weblinkLoading,
  } = useWeblinkSettings();

  const [createCart, { loading: mutationLoading, error: mutationError }] =
    useWebLinkMutation(CREATE_CART_MUTATION);

  const [addCartLineItem, { loading: addLineItemLoading }] = useWebLinkMutation(
    ADD_CART_LINE_ITEM_MUTATION,
  );

  const [placeOrder, { loading: placeOrderLoading }] =
    useWebLinkMutation(PLACE_ORDER_MUTATION);

  const {
    data: registerableData,
    loading: registerablesLoading,
    refetch: refetchRegistrations,
  } = useLmsQuery<LMSQuery>(RECENT_REGISTERABLES_QUERY);

  const { data: learningPathDetailsResponse, loading: isLearningPathLoading } =
    useWebLinkQuery<WebLinkQuery>(WEBLINK_LEARNING_PATH_DETAILS_QUERY, {
      variables: { id: learningPathId },
    });

  const [registerPassholder] =
    useLmsMutation<RegisterPassholderPathMutationResponseType>(
      REGISTER_PASSHOLDER_TO_LEARNING_PATH,
    );

  const learningPath =
    learningPathDetailsResponse?.learningPaths?.edges?.[0]?.node;
  const hasPricing = (learningPath?.prices[0]?.amount ?? null) !== null;
  const passTypeIds = learningPath?.includedInPassTypeIds ?? [];
  const requireObjectiveSelection =
    learningPath?.requireObjectiveFulfillment ?? true;
  const { validPassHeld, validActivePassId } = computePassStatus(
    viewer?.trainingPasses ?? [],
    passTypeIds,
  );
  const passTypes = useTrainingPassTypes();
  const pathPassTypes = passTypes.filter(pt => includes(passTypeIds, pt.id));

  const navigateToPath = (registrationId: string) => {
    history.push({
      pathname: `/my-courses/learning-path/${registrationId}/registrationSuccess`,
    });
  };

  const handlePassRegistration = (
    objectiveOptions: CourseObjectiveOption[],
    nestedPathObjectiveOptions: NestedPathObjectiveOptionChoice,
  ) => {
    sendEvent({
      location: "Catalog",
      action: "Register Now on Learning Path with Training Pass",
      value: 1,
    });

    const selectedEvents = mapObjectiveOptionsToSelectedCourseObjectives(
      objectiveOptions,
      nestedPathObjectiveOptions,
    );

    const learningPathId = learningPath?.id ?? "";
    const passTypeId =
      viewer?.trainingPasses?.find(pass => pass?.id === validActivePassId)
        ?.trainingPassTypeId ?? "";

    setRegistering(true);
    registerPassholder({
      variables: {
        learningPathId,
        passTypeId,
        selectedEvents,
      },
    })
      .then(getRegisterPassholderResponseData)
      .then(throwIfErrors)
      .then(extractRegistrationId)
      .then(navigateToPath)
      .catch(errors => {
        logError(errors, "registering passholder to learning path");
      })
      .finally(() => {
        setRegistering(false);
      });
  };

  const handleClickBookNow = async (
    regionCode: string,
    currencyCode: string,
    objectiveOptions: CourseObjectiveOption[],
    regions: Array<Region>,
    nestedPathObjectiveOptions: NestedPathObjectiveOptionChoice,
    validPassHeld?: boolean,
  ) => {
    const selectedEvents = objectiveOptions.filter(
      objectiveOption => !!objectiveOption?.objective?.event?.eventId,
    );

    if (validPassHeld) {
      return handlePassRegistration(selectedEvents, nestedPathObjectiveOptions);
    }

    const regionId = getRegionId(regions, regionCode);
    if (learningPath && regionId && currencyCode && selectedEvents) {
      if (!currencyCode || !regionId) {
        console.error(
          "This cart needs a properly configured price with a currency and region",
        );
        return;
      }

      const cartInput = {
        currencyCode,
        regionId,
      };

      if (
        bookerIntention === BookerIntention.Self &&
        !viewersCartLoading &&
        !cartlessCheckout
      ) {
        handleAddToCart(
          cartInput,
          learningPath,
          selectedEvents,
          nestedPathObjectiveOptions,
        );
      } else {
        handleCartlessCheckout(cartInput, learningPath, selectedEvents);
      }
    }
  };

  const handleAutoRegisterToLP = async (
    input: {
      currencyCode: string | undefined;
      regionId: string | undefined;
    },
    path: LearningPathType,
    objectiveOptions: CourseObjectiveOption[],
    nestedPathObjectiveOptions: NestedPathObjectiveOptionChoice,
  ) => {
    setRegistering(true);
    const toRegistrationRoute = checkRegistrationAndNavigate(
      registerableData,
      path,
    );
    if (toRegistrationRoute) {
      history.push(toRegistrationRoute);
      return;
    }

    const { addLineItemResponse, cartId } = await addToCart(
      input,
      path,
      objectiveOptions,
      nestedPathObjectiveOptions,
    );
    if (addLineItemResponse.errors && addLineItemResponse.errors.length > 0) {
      logError(addLineItemResponse.errors, "creating cart");
      return;
    }

    const placeOrderResponse = await placeOrder({
      variables: { input: { cartId } },
    });
    if (placeOrderResponse.errors && placeOrderResponse.errors.length > 0) {
      logError(placeOrderResponse.errors, "placing order");
      return;
    }
    const loadRegistrationResponse = await refetchRegistrations();

    const toNewRegistrationRoute = checkRegistrationAndNavigate(
      loadRegistrationResponse.data,
      path,
    );
    if (toNewRegistrationRoute) {
      history.push(toNewRegistrationRoute);
    } else {
      console.error("path", path);
      logError(loadRegistrationResponse.data, "redirecting to registration");
    }
  };

  const logError = (error: any, message: string) => {
    console.error(`encountered an error while ${message}`);
    console.error(error);
    setApiError(true);
    setRegistering(false);
    try {
      const includesContactAlreadyRegistered = (element: {
        label: string;
        message: string;
        value: string;
      }) => element.message === "Contact already registered on Learning Path";
      if (error.errors?.some(includesContactAlreadyRegistered)) {
        setApiErrorMessage(t("contactAlreadyRegisteredOnLearningPath"));
      }
    } catch {
      setApiErrorMessage("");
    }
  };

  const handleAddToCart = async (
    input: {
      currencyCode: string | undefined;
      regionId: string | undefined;
    },
    path: LearningPathType,
    objectiveOptions: CourseObjectiveOption[],
    nestedPathObjectiveOptions: NestedPathObjectiveOptionChoice,
  ) => {
    const { addLineItemResponse } = await addToCart(
      input,
      path,
      objectiveOptions,
      nestedPathObjectiveOptions,
    );
    if (addLineItemResponse.errors && addLineItemResponse.errors.length > 0) {
      console.error("encountered an error while creating cart");
      console.error(addLineItemResponse.errors);
      setApiError(true);
    } else {
      captureEvent(
        CartEvent.fromCartAdd(
          addLineItemResponse.data.cart.addLineItem.cart,
          path.id,
        ),
      );
      history.push({
        pathname: `/cart`,
      });
    }
  };

  const addToCart = async (
    input: {
      currencyCode: string | undefined;
      regionId: string | undefined;
    },
    path: LearningPathType,
    objectiveOptions: CourseObjectiveOption[],
    nestedPathObjectiveOptions: NestedPathObjectiveOptionChoice,
  ) => {
    let cartId = viewersLastCart?.id;

    const objectives = mapObjectiveOptionsToSelectedCourseObjectives(
      objectiveOptions,
      nestedPathObjectiveOptions,
    );

    if (!viewersLastCart) {
      const response = await createCart({
        variables: { input },
      });
      cartId = get(response, "data.cart.createCart.cart.id", "");
    }
    const addLineItemResponse = await addCartLineItem({
      variables: {
        input: {
          cartId,
          cartLineItem: {
            productOptionId: path && path.id,
            quantity: 1,
            learners: { existingLearners: [{ contactId: viewer?.id }] },
            objectives,
          },
        },
      },
    });
    return {
      addLineItemResponse,
      cartId,
    };
  };

  const handleCartlessCheckout = async (
    input: {
      currencyCode: string;
      regionId: string;
    },
    path: LearningPathType,
    objectiveOptions: CourseObjectiveOption[],
  ) => {
    const response = await createCart({
      variables: { input },
    });

    if (response.errors && response.errors.length > 0) {
      console.error("encountered an error while creating cart");
      console.error(response.errors);
      setApiError(true);
    } else {
      const cartId = get(response, "data.cart.createCart.cart.id", "");
      localStorage.setItem(cartId, JSON.stringify(objectiveOptions));
      history.push(
        `/catalog/${bookerIntention}/learning-path/${path.id}/booking/${cartId}`,
      );
    }
  };

  const LPInfo = getLPInfo(learningPath);

  useEffect(() => {
    if (LPInfo) {
      setStartableLP(
        bookerIntention === BookerIntention.Self &&
          cartlessCheckout &&
          isPathStartable(
            LPInfo.learningPath,
            LPInfo.currencyCode,
            LPInfo.regionId,
          ),
      );
    }
  }, [LPInfo, setStartableLP, bookerIntention, cartlessCheckout]);

  useEffect(() => {
    if (isLearningPathLoading || !learningPath) return;
    captureEvent(ProductViewEvent.fromLearningPathView(learningPath));
  }, [isLearningPathLoading, learningPath, captureEvent]);

  const loading = isLearningPathLoading || weblinkLoading;

  return (
    <Fragment>
      {registering && <LoadingBar isLoading />}
      <FutureDetailPage
        title={learningPath?.name || ""}
        layout="adjacent"
        detailType="Learning Path"
        legacyId={0}
        page="overview"
        form={{
          saveState: null,
        }}
        back={{
          label: t("catalog"),
          onClick: () => history.push(`/catalog/${bookerIntention}`),
        }}
        header={{
          extra: bookerIntention === BookerIntention.Self && <CartWidget />,
        }}
      >
        <DetailPageContent>
          {(mutationError || apiError) && (
            <Alert
              type="error"
              message={
                !!apiErrorMessage
                  ? apiErrorMessage
                  : `${t(
                      "An error occurred while adding learners to your cart",
                    )}.`
              }
              glyph="removeSign"
              overPage={false}
            />
          )}
          {!startableLP &&
            (!loading &&
            !(
              bookerIntention === BookerIntention.Self && viewersCartLoading
            ) ? (
              <LearningPathContent
                learningObjectives={
                  learningPath?.learningObjectives?.edges || []
                }
                description={learningPath?.description}
                handleClickBookNow={handleClickBookNow}
                prices={learningPath?.prices}
                learningPathId={learningPathId}
                mutationLoading={
                  mutationLoading ||
                  addLineItemLoading ||
                  placeOrderLoading ||
                  registerablesLoading
                }
                hidePrices={hidePrices}
                hidePricesBeforePricingAgreementApplied={
                  hidePricesBeforePricingAgreementApplied
                }
                pathPassTypes={pathPassTypes}
                learnerPasses={viewer?.trainingPasses ?? []}
                hasPricing={hasPricing}
                validPassHeld={validPassHeld}
                portalRegionCode={portalRegionCode}
                requireObjectiveSelection={requireObjectiveSelection}
                isFullyBooked={learningPath?.remainingPlaces === 0}
              />
            ) : (
              <LearningPathSkeleton />
            ))}
        </DetailPageContent>
        <AssistantPanel>
          <Card
            label={{
              color: ColorOptions.LightGrey,
              text: t("learningPath"),
              extraClass: "learning-path-color",
            }}
            imageSource={learningPath?.imageUrl || learningPathImagePlaceholder}
          >
            {loading ? (
              <ListCell loading />
            ) : (
              startableLP &&
              learningPath &&
              LPInfo && (
                <Button
                  label={t("startLP")}
                  type="primary"
                  id="auto-register-start"
                  disabled={registering}
                  onClick={() => {
                    handleAutoRegisterToLP(
                      {
                        currencyCode: LPInfo.currencyCode,
                        regionId: LPInfo.regionId,
                      },
                      learningPath,
                      [],
                      {},
                    );
                  }}
                ></Button>
              )
            )}
          </Card>
          {learningPath && (
            <div className="floating-pass-status">
              <TrainingPassContentStatusLabel
                courseCardId={learningPath.id ?? ""}
                learnerPasses={viewer?.trainingPasses ?? []}
                contentPasses={pathPassTypes}
                hasPricing={hasPricing}
              />
            </div>
          )}
        </AssistantPanel>
      </FutureDetailPage>
    </Fragment>
  );
};

const LearningPathContent: FunctionComponent<{
  learningObjectives: LearningObjectiveEdge[];
  description?: Maybe<string>;
  handleClickBookNow: Function;
  prices?: Maybe<Price>[] | null;
  learningPathId: string;
  mutationLoading: boolean;
  hidePrices: boolean;
  hidePricesBeforePricingAgreementApplied: boolean;
  pathPassTypes: TrainingPassType[];
  learnerPasses: TrainingPass[];
  hasPricing: boolean;
  validPassHeld: boolean;
  portalRegionCode: string | null | undefined;
  requireObjectiveSelection: boolean;
  isFullyBooked: boolean;
}> = ({
  learningObjectives,
  description,
  handleClickBookNow,
  prices,
  learningPathId,
  mutationLoading,
  hidePrices,
  hidePricesBeforePricingAgreementApplied,
  pathPassTypes,
  learnerPasses,
  hasPricing,
  validPassHeld,
  portalRegionCode,
  requireObjectiveSelection,
  isFullyBooked,
}) => {
  const { t } = useTranslation();
  const { viewer } = useViewer();

  const [selectedRegion, setSelectedRegion] =
    useState<"unsetRegion" | string | null | undefined>(portalRegionCode);
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>();
  const [changedRegion, setChangedRegion] =
    useState<"unsetRegion" | string | null>();
  const [currencies, setCurrencies] = useState<OptionType[]>();
  const [showLearningPathModal, setShowLearningPathModal] = useState<
    Record<string, boolean>
  >({});
  const [
    nestedPathObjectiveCompletionState,
    setNestedPathObjectiveCompletionState,
  ] = useState<Record<string, boolean>>({});

  const onChangeRegionAlertClose = (selected: boolean) => {
    if (selected) {
      if (changedRegion === "unsetRegion") {
        setSelectedRegion(null);
      } else {
        setSelectedRegion(changedRegion);
      }
      setChangedRegion(null);
    } else {
      setChangedRegion(null);
    }
  };

  useEffect(() => {
    learningObjectives.forEach(edge => {
      const node = edge.node as LearningObjectiveUnion;
      if (node.__typename !== "LearningPathObjective") {
        return;
      }

      setNestedPathObjectiveCompletionState(
        nestedPathObjectiveCompletionState => ({
          ...nestedPathObjectiveCompletionState,
          [node.id]: node.learningPath.learningObjectives.edges.every(
            ({ node }) =>
              (node as LearningObjectiveUnion).__typename !== "CourseObjective",
          ),
        }),
      );
    });
  }, [learningObjectives]);

  const statusLabelText = (values: { objective: { type: string } }) => {
    if (values.objective.type === "LearningPath") {
      return t("learningPath");
    } else if (values.objective.type === "Event") {
      return t("course");
    } else if (values.objective.type === "External") {
      return t("external");
    } else {
      return values.objective.type;
    }
  };

  const getRegionOptions = (): OptionType[] => {
    return getRegions(prices).map((region: Region) => ({
      label: region.name || "",
      value: region.code || "",
    }));
  };

  const regions = getRegionOptions();

  const initializeRegion = () => {
    if (regions.length === 1) {
      if (!selectedRegion) {
        setSelectedRegion(regions[0].value);
      }
      if (!currencies) {
        setCurrencies(getRegionsCurrencies(regions[0].value));
      }
      return regions[0].label;
    }
    return undefined;
  };

  const getRegionsCurrencies = (region: string): OptionType[] => {
    if (prices) {
      const currencies = prices.filter(price => price?.region?.code === region);
      return currencies.map(currency => ({
        label: currency?.financialUnit?.code || "",
        value: currency?.financialUnit?.code || "",
      }));
    }
    return [{ label: "", value: "" }];
  };

  const setRegion = (selected: string | null) => {
    if (selectedRegion && selected === null) {
      setChangedRegion("unsetRegion");
    } else if (!selectedRegion) {
      setSelectedRegion(selected);
    } else {
      setChangedRegion(selected);
    }
    if (selected !== null) {
      const currencies = getRegionsCurrencies(selected);
      setCurrencies(currencies);

      if (currencies.length === 1) {
        setSelectedCurrency(currencies[0].value);
        formValues.currency = currencies[0].value;
      }
    } else {
      setCurrencies([]);
      setSelectedCurrency(null);
    }
  };

  const initializeCurrency = () => {
    if (regions.length === 1) {
      const currencies = getRegionsCurrencies(regions[0].value);
      if (currencies.length === 1 && !selectedCurrency) {
        setSelectedCurrency(currencies[0].value);
        return currencies[0].value;
      }
    }
    return undefined;
  };

  const formValues = useTypedFormValues<LearningPathFormValues>({
    region: initializeRegion(),
    currency: initializeCurrency(),
    objectives: getLearningObjectiveOptions(learningObjectives),
    nestedPathObjectiveOptions: {},
  });
  const firstCourseArrayIndex = formValues.objectives.findIndex(
    (node: { objective: { type: string } }) => node.objective.type === "Course",
  );

  const getPrice = () => {
    if (selectedRegion && selectedCurrency && prices) {
      const price = prices.filter(
        price =>
          price?.region?.code === selectedRegion &&
          price?.financialUnit?.code === selectedCurrency,
      );
      if (price[0]) {
        return getFinancialFormat(
          price[0].amount,
          price[0].financialUnit,
          viewer?.locale,
        );
      }
    }
    return "-";
  };

  const isTrainingPassContent = !!pathPassTypes.length;
  const hasPrices = !!prices?.length;
  const showRegionSelector =
    hasPrices || (!hasPrices && !isTrainingPassContent);

  const openDrawer = (arrayIndex: number, eventId?: string) => {
    if (eventId) return false;
    return arrayIndex === firstCourseArrayIndex;
  };

  const hidePriceForPassholders = () => {
    if (!hasPrices && isTrainingPassContent) {
      return true;
    }

    if (validPassHeld) {
      if (formValues.currency) {
        return hidePrices;
      } else {
        return true;
      }
    } else {
      return hidePrices;
    }
  };

  const onNestedPathModalClose = (
    complete: boolean,
    nestedObjective: LearningPathObjective,
    nestedObjectiveSelection: NestedPathObjectiveOptionChoice,
    values: ArrElement<ReturnType<typeof getLearningObjectiveOptions>>,
  ) => {
    if (!complete) {
      setShowLearningPathModal({
        ...showLearningPathModal,
        [values.objective.id]: false,
      });
      return;
    }

    handleNestedPathObjectiveSelection(
      formValues,
      nestedObjective,
      nestedObjectiveSelection,
    );
    setShowLearningPathModal({
      ...showLearningPathModal,
      [values.objective.id]: false,
    });
    setNestedPathObjectiveCompletionState({
      ...nestedPathObjectiveCompletionState,
      [values.objective.id]: true,
    });
  };

  const nestedObjectivesComplete =
    !requireObjectiveSelection ||
    Object.values(nestedPathObjectiveCompletionState).every(Boolean);

  return (
    <Fragment>
      <h2 className="mt-0">{t("description")}</h2>
      <div className="mb-4">
        <SafeHTMLDisplay html={description || ""} />
      </div>

      <OpacityOverlay on={mutationLoading}>
        <Form
          values={formValues}
          disabled={mutationLoading}
          onSubmit={() =>
            handleClickBookNow(
              selectedRegion,
              selectedCurrency,
              formValues.objectives,
              getRegions(prices),
              formValues.nestedPathObjectiveOptions,
              validPassHeld,
            )
          }
        >
          <Card>
            <FutureArticleHeader
              title={t("objectives")}
              extra={
                <Fragment>
                  <div className="sr-only" role="note">
                    <h3>{t("howToGuide")}</h3>
                    <ul>
                      {regions.length !== 1 && (
                        <li>{t("cannotSelectNoCurrency")}</li>
                      )}
                      <li>{t("cannotBookNow")}</li>
                    </ul>
                  </div>
                  {showRegionSelector && (
                    <div className="region-select">
                      {regions.length !== 1 && (
                        <Select
                          prepend
                          options={regions}
                          label={t("region")}
                          name="region"
                          onChange={v => setRegion(v)}
                          valid={v => !!v}
                          autoFocus={true}
                        />
                      )}
                      {regions.length === 1 && (
                        <Input
                          label={t("region")}
                          name="region"
                          prepend
                          disabled
                        />
                      )}
                    </div>
                  )}
                </Fragment>
              }
            />
            <List hover={false} extraClass="objectives">
              <LoadingBar isLoading={mutationLoading} />
              <FormArray name="objectives">
                <FormConsumer>
                  {({ values, arrayIndex }) => (
                    <Observer>
                      {() => (
                        <Fragment>
                          {values.objective &&
                            (values.objective.type === "Course" ||
                              values.objective.type === "Event") && (
                              <ListRow
                                gridColumns={objectiveColumn}
                                dataLabel={values.objective.type}
                              >
                                <ListCell label={t("objectiveType")}>
                                  <FormConsumer>
                                    {({ errors }) => (
                                      <ObjectiveStatusLabel
                                        text={t("Course")}
                                        errors={errors}
                                      />
                                    )}
                                  </FormConsumer>
                                </ListCell>
                                <ListCell
                                  label={t("objectiveName")}
                                  extraClass="objectives--title"
                                >
                                  <StaticInput name="objective.name" label="" />
                                  <div className="d-none">
                                    <Input
                                      type="hidden"
                                      name="objective.event.eventId"
                                      valid={
                                        requireObjectiveSelection
                                          ? v => !!v || "Value is Required"
                                          : undefined
                                      }
                                      label={t("objectiveName")}
                                      uniqueId={getUniqueId()}
                                    />
                                  </div>
                                </ListCell>
                                <ListCell
                                  label={t("location")}
                                  extraClass={
                                    values.objective.event.location
                                      ? "hide-label pt-2"
                                      : "d-none"
                                  }
                                >
                                  <StaticInput
                                    name="objective.event.location"
                                    label={t("location value")}
                                  />
                                  <div className="d-none">
                                    <Input
                                      type="hidden"
                                      name="objective.event.location"
                                      valid={
                                        requireObjectiveSelection
                                          ? v => !!v || "Value is Required"
                                          : undefined
                                      }
                                      label={t("objectiveLocation")}
                                      uniqueId={getUniqueId()}
                                    />
                                  </div>
                                </ListCell>
                                <ListCell
                                  label={t("dates")}
                                  extraClass={
                                    values.objective.event.classroomStart ||
                                    values.objective.event.classroomEnd
                                      ? "hide-label"
                                      : "d-none hide-label"
                                  }
                                >
                                  <EventDateRange
                                    event={values.objective.event}
                                  />
                                </ListCell>

                                <ListCell>
                                  <FormConsumer>
                                    {({ errors }) => (
                                      <SelectDateWarning errors={errors} />
                                    )}
                                  </FormConsumer>
                                  {values.objective.type === "Event" &&
                                    values.objective.event.remainingPlaces ===
                                      0 && (
                                      <Prompt
                                        type="warning"
                                        message={t("noPlacesRemaining")}
                                      />
                                    )}
                                </ListCell>

                                {values.objective.type === "Course" && (
                                  <ListDrawer
                                    title={t("selectDates")}
                                    open={openDrawer(
                                      arrayIndex,
                                      values.objective.event.eventId,
                                    )}
                                  >
                                    <LearningPathDateAndLocations
                                      courseCode={values.objective.courseCode}
                                      learningPathId={learningPathId}
                                      regionCode={
                                        selectedRegion ? selectedRegion : ""
                                      }
                                    />
                                  </ListDrawer>
                                )}
                              </ListRow>
                            )}
                          {values.objective &&
                            values.objective.type === "LearningPath" && (
                              <>
                                <ListRow
                                  gridColumns="150px 3fr"
                                  dataLabel={values.objective.type}
                                >
                                  <ListCell label={t("objectiveType")}>
                                    <StatusLabel
                                      color={
                                        !requireObjectiveSelection ||
                                        nestedPathObjectiveCompletionState[
                                          values.objective.id
                                        ]
                                          ? ColorOptions.Green
                                          : ColorOptions.LightGrey
                                      }
                                      text={t("learningPath")}
                                    />
                                  </ListCell>
                                  <ListCell label={t("objectiveName")}>
                                    <StaticInput
                                      name="objective.name"
                                      label=""
                                    />
                                  </ListCell>
                                  <div>
                                    {requireObjectiveSelection &&
                                      !nestedPathObjectiveCompletionState[
                                        values.objective.id
                                      ] && (
                                        <StyleBlock
                                          styleOptions={[
                                            UtilityStyle.InlineBlock,
                                            UtilityStyle.AlignMiddle,
                                          ]}
                                        >
                                          <Prompt
                                            type="warning"
                                            message=""
                                            styleOptions={[
                                              UtilityStyle.FontBold,
                                            ]}
                                          />
                                        </StyleBlock>
                                      )}
                                    <Button
                                      type="suppressed"
                                      label={t("seeContent")}
                                      onClick={() =>
                                        setShowLearningPathModal({
                                          ...showLearningPathModal,
                                          [values.objective.id]: true,
                                        })
                                      }
                                    />
                                  </div>
                                  {showLearningPathModal[
                                    values.objective.id
                                  ] && (
                                    <NestedPathObjectiveModal
                                      learningPath={
                                        values.objective.learningPath
                                      }
                                      nestedObjective={values.objective}
                                      regionCode={
                                        selectedRegion ? selectedRegion : ""
                                      }
                                      onModalClose={(
                                        complete,
                                        objective,
                                        objectiveSelection,
                                      ) =>
                                        onNestedPathModalClose(
                                          complete,
                                          objective,
                                          objectiveSelection,
                                          values,
                                        )
                                      }
                                    />
                                  )}
                                </ListRow>
                              </>
                            )}
                          {values.objective &&
                            values.objective.type !== "LearningPath" &&
                            values.objective.type !== "Course" &&
                            values.objective.type !== "Event" && (
                              <ListRow
                                gridColumns={objectiveColumn}
                                dataLabel={values.objective.type}
                              >
                                <ListCell label={t("objectiveType")}>
                                  <StatusLabel
                                    color={ColorOptions.Green}
                                    text={statusLabelText(values)}
                                  />
                                </ListCell>
                                <ListCell label={t("objectiveName")}>
                                  <StaticInput name="objective.name" label="" />
                                </ListCell>
                              </ListRow>
                            )}
                          {!values.objective && (
                            <ListEmpty
                              text={t(
                                "No objectives available for this Learning Path",
                              )}
                            />
                          )}
                        </Fragment>
                      )}
                    </Observer>
                  )}
                </FormConsumer>
              </FormArray>
            </List>
            <FormActions>
              {!hidePriceForPassholders() &&
                (currencies?.length === 1 ? (
                  <div className="objectives--static-currency">
                    <Input
                      prepend={true}
                      label={t("currency")}
                      name="currency"
                      disabled
                      valid={v => !!v}
                    />
                  </div>
                ) : (
                  <div
                    className={`objectives--select-currency ${
                      !selectedRegion
                        ? "objectives--select-currency--isDisabled"
                        : ""
                    }`}
                  >
                    {!selectedRegion ? (
                      <Tooltip
                        content={t("cannotSelectNoCurrency")}
                        tooltipId={1}
                        iconShown={false}
                        placement="top"
                        disabled
                      >
                        <Select
                          prepend={true}
                          options={[]}
                          label={t("currency")}
                          name="currency"
                          disabled={!selectedRegion}
                          valid={v => !!v}
                        />
                      </Tooltip>
                    ) : (
                      <Select
                        prepend={true}
                        options={currencies || []}
                        label={t("currency")}
                        name="currency"
                        onChange={selected => setSelectedCurrency(selected)}
                        valid={v => !!v}
                      />
                    )}
                  </div>
                ))}
              {!(
                hidePriceForPassholders() ||
                hidePricesBeforePricingAgreementApplied
              ) ? (
                <div className="d-inline-flex ml-4 mr-5 font-weight-bold">
                  <BodyText
                    message={`${t("price")}: ${getPrice()}`}
                    disabled={validPassHeld}
                    lineThrough={validPassHeld}
                  />
                </div>
              ) : (
                <div className="d-inline-flex ml-4" />
              )}
              <div className="d-inline-flex mr-5">
                <TrainingPassContentStatusLabel
                  courseCardId={"form-" + learningPathId}
                  learnerPasses={learnerPasses}
                  contentPasses={pathPassTypes}
                  hasPricing={hasPricing}
                />
              </div>
              <FormConsumer>
                {({ errors }) => (
                  <BookNowButton
                    errors={errors}
                    hasPrices={hasPrices}
                    validPassHeld={validPassHeld}
                    nestedObjectivesComplete={nestedObjectivesComplete}
                    isFullyBooked={isFullyBooked}
                  />
                )}
              </FormConsumer>
            </FormActions>
          </Card>
        </Form>
      </OpacityOverlay>
      {!!changedRegion && (
        <ChangeRegionAlert
          show={!!changedRegion}
          onDone={onChangeRegionAlertClose}
        />
      )}
    </Fragment>
  );
};

const BookNowButton: FunctionComponent<{
  errors: any;
  validPassHeld?: boolean;
  hasPrices: boolean;
  nestedObjectivesComplete: boolean;
  isFullyBooked: boolean;
}> = observer(
  ({
    errors,
    validPassHeld,
    hasPrices,
    nestedObjectivesComplete,
    isFullyBooked,
  }) => {
    const { t } = useTranslation();

    const disabled =
      isFullyBooked ||
      hasErrors(errors) ||
      (!hasPrices && !validPassHeld) ||
      !nestedObjectivesComplete;

    const [bookerIntention] = useBookerIntention();
    const buttonLabel = validPassHeld
      ? t("registerNow")
      : bookerIntention === BookerIntention.Self
      ? t("register")
      : t("bookNow");
    return (
      <Fragment>
        {disabled ? (
          <div className="objectives--booknow">
            <Tooltip
              content={t("cannotBookNow")}
              tooltipId={1}
              iconShown={false}
              placement="top"
              disabled
            >
              <Button
                type="primary"
                label={buttonLabel}
                disabled
                onClick={() => []}
              />
            </Tooltip>
          </div>
        ) : (
          <div className="objectives--booknow">
            <Submit inline label={buttonLabel} />
          </div>
        )}
      </Fragment>
    );
  },
);

const ChangeRegionAlert: FunctionComponent<{
  show: boolean;
  onDone: (done: boolean) => void;
}> = ({ show, onDone }) => {
  const { t } = useTranslation();
  return (
    <AlertModal
      show={show}
      type="warning"
      title={t("areYouSure")}
      onDone={onDone}
    >
      {t("changeRegionWarning")}
    </AlertModal>
  );
};

export const ObjectiveStatusLabel: FunctionComponent<{
  text: string;
  errors: Errors;
}> = observer(({ text, errors }) => {
  return (
    <StatusLabel
      color={hasErrors(errors) ? ColorOptions.LightGrey : ColorOptions.Green}
      text={text}
    />
  );
});

const SelectDateWarning: FunctionComponent<{
  errors: Errors;
}> = observer(({ errors }) => {
  const { t } = useTranslation();
  if (hasErrors(errors)) {
    return <Prompt type="warning" message={t("selectDate")} />;
  }
  return <Fragment></Fragment>;
});

const mapObjectiveOptionsToSelectedCourseObjectives = (
  objectiveOptions: CourseObjectiveOption[],
  nestedPathObjectiveOptions: NestedPathObjectiveOptionChoice,
) => {
  const courseObjectiveOptions = objectiveOptions.filter(
    objective => objective.objective.type === "Course",
  );

  const selectedCourseObjectives = courseObjectiveOptions.map(objective => ({
    eventId: objective?.objective?.event?.eventId,
    objectiveId: objective.id,
  }));

  const selectedNestedCourseObjectives = Object.entries(
    nestedPathObjectiveOptions,
  ).map(([objectiveId, event]) => ({
    objectiveId,
    eventId: event.id,
  }));

  return [...selectedCourseObjectives, ...selectedNestedCourseObjectives];
};

const LearningPathSkeleton: FunctionComponent = () => {
  const { t } = useTranslation();

  const [bookerIntention] = useBookerIntention();
  const buttonLabel =
    bookerIntention === BookerIntention.Self ? t("register") : t("bookNow");
  return (
    <Fragment>
      <h2 className="mt-0">{t("description")}</h2>
      <div className="mb-4">
        <Placeholder />
        <Placeholder width="90%" />
        <Placeholder width="80%" />
      </div>
      <Card>
        <FutureArticleHeader
          title={t("objectives")}
          extra={
            <Form values={useFormValues()}>
              <Input
                label={t("region")}
                prepend
                name="loadingRegions"
                disabled
              />
            </Form>
          }
        />
        <List hover={false} extraClass="objectives">
          <ListRow gridColumns={objectiveColumn}>
            <ListCell label={t("objectiveType")} loading />
            <ListCell label={t("objectiveName")} loading />
          </ListRow>
          <ListRow gridColumns={objectiveColumn}>
            <ListCell label={t("objectiveType")} loading />
            <ListCell label={t("objectiveName")} loading />
          </ListRow>
          <ListRow gridColumns={objectiveColumn}>
            <ListCell label={t("objectiveType")} loading />
            <ListCell label={t("objectiveName")} loading />
          </ListRow>
        </List>
        <FormActions>
          <Button
            type="primary"
            disabled
            onClick={() => []}
            label={buttonLabel}
          />
        </FormActions>
      </Card>
    </Fragment>
  );
};
