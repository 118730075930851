import React, { FunctionComponent } from "react";
import { AlertModal } from "@administrate/piston-ux";
import { observer } from "mobx-react-lite";

import { WEBLINK_EVENT_BY_ID_QUERY } from "../../queries/events";
import { LearnerSelection, LearnerSelectionSkeleton } from "./LearnerSelection";
import { useParams, useHistory } from "../../useHistory";
import { useWebLinkQuery } from "../../hooks/weblink";
import {
  Query,
  PointOfSaleFieldEntityType,
  FilterOperation,
  PointOfSaleField,
} from "../../generated/weblinkTypes";
import { GET_CART } from "../../queries/cart";
import { useTranslation } from "react-i18next";
import { LearnerDetailsContainer } from "../Order/LearnerDetailsContainer";
import { BookerIntention } from "../Order/BookingPage";
import { useBookerIntention } from "../../hooks/useBookerIntention";

export const EventLearnerSelection: FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const { eventId, cartId } = useParams<{eventId:string, cartId:string}>();

  const [bookerIntention, setBookerIntention] = useBookerIntention();
  if (bookerIntention !== BookerIntention.Coordinating) {
    setBookerIntention(BookerIntention.Coordinating);
  }

  const history = useHistory();

  const { data: eventsResponse, loading: eventLoading } =
    useWebLinkQuery<Query>(WEBLINK_EVENT_BY_ID_QUERY, {
      variables: { id: eventId },
    });

  const events = eventsResponse?.events.edges || [];
  const event = events.length > 0 ? events[0].node : undefined;

  const { data: cartResponse, loading: cartLoading } = useWebLinkQuery<Query>(
    GET_CART,
    {
      variables: { id: cartId },
    },
  );

  const cart = cartResponse && cartResponse.cart;
  const fullCart = !cartLoading && cart && cart.items && cart.items.length > 0;
  const wonCart =
    !cartLoading && cart && (cart.state === "won" || cart.invoiceId !== null);
  const showAlert = fullCart || wonCart;

  const getAlertTitle = (wonCart: boolean | undefined | null) => {
    return wonCart
      ? t("bookingAlreadyConfirmed")
      : t("alreadyHasLearnersAssigned");
  };

  const getRedirectUrl = (wonCart: boolean | undefined | null) => {
    return wonCart
      ? `/catalog/${BookerIntention.Coordinating}/booking/${
          cart && cart.id
        }/confirmation`
      : `/catalog/${BookerIntention.Coordinating}/booking/${cart && cart.id}`;
  };

  const maxLearners = event && event.remainingPlaces;
  const getMaxLearnersMessages = (learnerAmount: number) => {
    return [`${t("availableLearnerSpaces")}: ${maxLearners}`];
  };
  return (
    <LearnerDetailsContainer
      event={event}
      loading={eventLoading || cartLoading}
    >
      <AlertModal
        title={getAlertTitle(wonCart)}
        show={!!showAlert}
        onDone={() => history.push(getRedirectUrl(wonCart))}
        type="warningWithoutCancel"
      />
      {event ? (
        <LearnerSelection
          cartId={cart && cart.id}
          product={event}
          loading={eventLoading || cartLoading}
          pointOfSaleFieldVariables={{
            entityType: PointOfSaleFieldEntityType.CartLineItemEventLearner,
            filters: [
              {
                value: event?.course?.id,
                field: PointOfSaleField.IsValidForCourseTemplate,
                operation: FilterOperation.Eq,
              },
            ],
          }}
          maxLearners={maxLearners}
          getMaxLearnersReachedMessages={getMaxLearnersMessages}
          invalidStateAlertVisible={!!showAlert}
        />
      ) : (
        <LearnerSelectionSkeleton />
      )}
    </LearnerDetailsContainer>
  );
});
