import {
  Contact,
  Maybe,
  PointOfSaleLearnerFieldDefinition,
  LearningOutcomeEdge,
  Registration,
} from "../../generated/weblinkTypes";
import React, { FunctionComponent, Fragment, useMemo, useState } from "react";
import { ApolloError } from "apollo-boost";
import { observer } from "mobx-react-lite";
import { ListEmpty, ListDrawer } from "@administrate/piston-ux/lib/List";
import { FormConsumer, FormArray } from "@administrate/piston-ux/lib/Form";
import {
  List,
  Button,
  Alert,
  StatusLabel,
  ListRow,
  ListCell,
  StaticInput,
  Modal,
  Link,
} from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { ColorOptions } from "@administrate/piston-ux/lib/types";
import { Link as RouterLink } from "react-router-dom";

import { Errors } from "../../types/Portal";
import { hasErrors, generateLearnerName } from "../../utils/bookingHelpers";
import { PointOfSaleLearnerFields } from "../../components/PointOfSaleLearnerFields";
import { getLearningOutcomeOptions } from "../../utils/weblinkLearningPathHelpers";
import { EventDateRange } from "../../components/EventDateRange";
import { OptionsMenu } from "../../components/OptionsMenu";

const gridColumns = "150px 1fr 1fr";

export type FormLearner = {
  id: string;
  contact: Contact;
  account: string;
  posFieldValues: Record<string, Maybe<string>>;
  isCancelled: boolean;
  isUnnamed: boolean;
  quantity: number;
  registration: Registration;
};

export const BookingLearnersForm: FunctionComponent<{
  totalNoOfLearners: number;
  fetchMoreLearners: Function;
  error?: ApolloError;
  // TODO: Remove this boolean when path PoS fields have sections
  isEvent: boolean;
  values: any;
  mappedPosFields: PointOfSaleLearnerFieldDefinition[];
  loading: boolean;
  onCancelLearnerClick: (learnerId: string) => void;
  onTransferLearnerClick?: (learnerId: string) => void;
}> = observer(
  ({
    totalNoOfLearners,
    fetchMoreLearners,
    error,
    isEvent,
    values,
    mappedPosFields,
    loading,
    onCancelLearnerClick,
    onTransferLearnerClick,
  }) => {
    const { t } = useTranslation();

    return (
      <Fragment>
        <List extraClass="learners">
          <FormArray name="learners">
            <FormConsumer>
              {({ arrayIndex }) => (
                <LearnerRow
                  posFields={mappedPosFields}
                  arrayIndex={arrayIndex}
                  readonly={true}
                  isEvent={isEvent}
                  onCancelLearnerClick={onCancelLearnerClick}
                  onTransferLearnerClick={onTransferLearnerClick}
                />
              )}
            </FormConsumer>
          </FormArray>
          {loading && <LearnerRowLoading isEvent={isEvent} />}
          {values.learners.length < totalNoOfLearners && (
            <div style={{ textAlign: "center" }}>
              <Button
                type="suppressed"
                label={t("showMore")}
                onClick={() => fetchMoreLearners(values.learners.length)}
              />
            </div>
          )}
          {!loading && values.learners.length === 0 && (
            <ListEmpty text={t("noLearnersFound")} />
          )}
          {error && (
            <div className="m-4">
              <Alert message={t("somethingWentWrong")} />
            </div>
          )}
        </List>
      </Fragment>
    );
  },
);

const WhichStatusLabel: FunctionComponent<{
  errors: Errors;
  arrayIndex: number;
  learner: FormLearner;
}> = observer(({ errors, learner }) => {
  const { t } = useTranslation();

  const isInvalid = hasErrors(errors);

  const label = useMemo(() => {
    if (isInvalid) {
      return <StatusLabel color={ColorOptions.Red} text={t("incomplete")} />;
    }

    if (learner.isCancelled) {
      return (
        <StatusLabel color={ColorOptions.LightGrey} text={t("cancelled")} />
      );
    }

    return <StatusLabel color={ColorOptions.Green} text={t("active")} />;
  }, [isInvalid, learner, t]);

  return label;
});

const LearnerRow: FunctionComponent<{
  posFields: PointOfSaleLearnerFieldDefinition[];
  arrayIndex: number;
  isEvent: boolean;
  readonly: boolean;
  onCancelLearnerClick: (learnerId: string) => void;
  onTransferLearnerClick?: (learnerId: string) => void;
}> = ({
  posFields,
  readonly,
  isEvent,
  onCancelLearnerClick,
  onTransferLearnerClick,
}) => {
  const { t } = useTranslation();
  const [showOutcomes, setShowOutcomes] = useState(false);

  return (
    <FormConsumer>
      {({ values: learner, errors, arrayIndex }) => (
        <ListRow
          gridColumns={gridColumns}
          extra={
            <Fragment>
              {isEvent && learner.isUnnamed && (
                <Link
                  type="default"
                  url={`unnamed-learner/${learner.id}`}
                  label={t("assignLearner")}
                  routerLink={RouterLink}
                />
              )}
              {!isEvent && (
                <Button
                  label={t("viewObjectives")}
                  onClick={() => setShowOutcomes(true)}
                  type="suppressed"
                  id="booking__view-objectives"
                />
              )}
              <OptionsMenu
                optionsMenuItems={[
                  ...(onTransferLearnerClick
                    ? [
                        {
                          label: t("transferLearner"),
                          onClick: () => onTransferLearnerClick(learner.id),
                          disabled: learner.isCancelled,
                        },
                      ]
                    : []),
                  {
                    label: t("cancelLearner"),
                    onClick: () => onCancelLearnerClick(learner.id),
                    disabled: learner.isCancelled,
                  },
                ]}
              />
            </Fragment>
          }
          dataLabel={generateLearnerName(learner)}
        >
          <ListCell label={t("status")}>
            <WhichStatusLabel
              errors={errors}
              arrayIndex={arrayIndex}
              learner={learner}
            />
          </ListCell>
          <ListCell label={t("name")} extraClass="learners--name">
            {learner.isUnnamed ? (
              <span>{generateLearnerName(learner)}</span>
            ) : (
              <RouterLink
                to={`/learner-management/learners/${learner.contact.id}`}
              >
                {generateLearnerName(learner)}
              </RouterLink>
            )}
          </ListCell>
          <ListCell label={t("account")} extraClass="learners--name">
            <StaticInput name="account" label={t("account")} />
          </ListCell>

          {learner.isCancelled || learner.isUnnamed
            ? undefined
            : posFields && (
                <ListDrawer
                  title={t("learnerInformation")}
                  open={false}
                  display="hidden"
                >
                  <PointOfSaleLearnerFields
                    posFields={posFields}
                    learnerNumber={arrayIndex}
                    errors={errors}
                    readonly={readonly}
                  />
                </ListDrawer>
              )}
          {showOutcomes && (
            <OutcomesModal
              outcomes={learner.learningOutcomes.edges}
              onDone={() => setShowOutcomes(!showOutcomes)}
            />
          )}
        </ListRow>
      )}
    </FormConsumer>
  );
};

export const OutcomesModal: FunctionComponent<{
  outcomes: LearningOutcomeEdge[];
  onDone: () => void;
}> = ({ outcomes, onDone }) => {
  const { t } = useTranslation();
  const gridColumns = "2fr 3fr 2fr 3fr";
  const learnerOutcomes = getLearningOutcomeOptions(outcomes);

  // As far as user is concered these are Objectives, however we are using Outcomes
  // in order to get Learner specific information such as booked Event details.
  return (
    <Modal
      onDone={onDone}
      title={t("objectives")}
      show={true}
      size="large"
      primaryActionText={t("OK")}
    >
      <List>
        {learnerOutcomes.length === 0 && (
          <ListEmpty text={t("thereAreNoObjectivesForThisLearner")} />
        )}
        {learnerOutcomes.map(outcome => (
          <ListRow gridColumns={gridColumns} key={outcome.id}>
            <ListCell label={t("type")}>
              <StatusLabel color={ColorOptions.Green} text={outcome.type} />
            </ListCell>
            {outcome.event && (
              <React.Fragment>
                <ListCell label={t("name")}>
                  <RouterLink
                    className="text-underline"
                    to={`/learner-management/bookings/${outcome.event.id}/event`}
                  >
                    {outcome.event.name}
                  </RouterLink>
                </ListCell>
                <ListCell label={t("location")}>
                  {outcome.event.location.name}
                </ListCell>
                <ListCell label={t("dates")}>
                  <EventDateRange event={outcome.event} />
                </ListCell>
              </React.Fragment>
            )}
          </ListRow>
        ))}
      </List>
    </Modal>
  );
};

export const LearnerRowLoading: FunctionComponent<{ isEvent: boolean }> = ({
  isEvent,
}) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {[...Array(3)].map((_, i) => (
        <Fragment key={i}>
          <ListRow
            gridColumns={gridColumns}
            extra={
              !isEvent && (
                <Button
                  label={t("viewObjectives")}
                  onClick={() => []}
                  disabled
                  type="suppressed"
                />
              )
            }
          >
            <ListCell loading label={t("status")} />
            <ListCell loading label={t("learnerName")} />
            <ListCell loading label={t("account")} />
          </ListRow>
        </Fragment>
      ))}
    </Fragment>
  );
};
