import React, { FunctionComponent } from "react";
import {
  LocationEdge,
  Location,
  Region,
  RegionEdge,
} from "../../generated/weblinkTypes";
import { DateCriteria } from "../../components/AffectBar/DatePickerFilter";
import {
  FutureArticleHeader,
  FutureAsyncFilter,
  Input,
  DateTimeInput,
  useTypedFormValues,
  Button,
} from "@administrate/piston-ux";
import { FutureInputFilter } from "@administrate/piston-ux/lib/future/ArticleHeader/FutureInputFilter";
import { useDateFormatter } from "../../hooks/useDateFormatter";
import {
  isNotInPast,
  formatFilterDateRange,
} from "../../utils/dateTimeHelpers";
import { useTranslation } from "react-i18next";
import { DetailFilter, useDetailFilters } from "./DetailFilterContext";

export type DetailEventListHeaderCriteria = {
  location?: Location;
  region?: Region;
  dates?: DateCriteria;
  minSeats?: string;
};

export type DetailEventListHeaderProps = {
  locations: (inputValue: string) => Promise<LocationEdge[]>;
  regions: (inputValue: string) => Promise<RegionEdge[]>;
  loading: boolean;
};

export const DetailEventListHeader: FunctionComponent<DetailEventListHeaderProps> =
  ({ locations, regions, loading }) => {
    const { t } = useTranslation();

    const { filters, setFilterValue } = useDetailFilters();
    const { simpleDateFormat } = useDateFormatter({ showDayName: true });

    const values = useTypedFormValues(filters);

    const clearFilters = () => {
      Object.keys(filters).forEach(key => {
        setFilterValue(key as keyof DetailFilter, undefined);
        values[key as keyof DetailFilter] = undefined;
      });
    };

    const hasFilters = Object.keys(filters).some(
      key => !!filters[key as keyof DetailFilter],
    );

    return (
      <FutureArticleHeader
        loading={loading}
        title={t("dates")}
        filters={{
          values,
        }}
        extra={
          hasFilters && (
            <Button
              type="suppressed"
              label={t("Clear Filters")}
              onClick={clearFilters}
            />
          )
        }
      >
        <FutureAsyncFilter
          label={t("location")}
          name="location"
          loadOptions={locations}
          formatter={(location: Location) => location.name}
          onChange={location => setFilterValue("location", location)}
          direction="left"
        />
        <FutureAsyncFilter
          label={t("region")}
          name="region"
          loadOptions={regions}
          formatter={(region: Region) => region.name}
          onChange={(region: Region) => setFilterValue("region", region)}
          direction="left"
        />
        <FutureInputFilter
          label={t("dates")}
          selected={
            filters.fromDate || filters.toDate
              ? formatFilterDateRange(
                  { from: filters.fromDate, to: filters.toDate },
                  simpleDateFormat,
                )
              : ""
          }
          onSubmit={() => {
            setFilterValue("fromDate", values.fromDate);
            setFilterValue("toDate", values.toDate);
          }}
          onClear={() => {
            setFilterValue("fromDate", undefined);
            setFilterValue("toDate", undefined);
          }}
          direction="left"
        >
          <DateTimeInput
            label={t("startDate")}
            type="date"
            name="fromDate"
            placeholder={t("today")}
            isValidDate={isNotInPast}
          />
          <DateTimeInput
            label={t("endDate")}
            type="date"
            placeholder={t("future")}
            name="toDate"
            isValidDate={isNotInPast}
          />
        </FutureInputFilter>
        <FutureInputFilter
          label={t("learnerNumbers")}
          selected={filters.minSeats ?? ""}
          onSubmit={() => setFilterValue("minSeats", values.minSeats)}
          onClear={() => {
            setFilterValue("minSeats", "");
          }}
          direction="left"
        >
          <Input
            name="minSeats"
            label={t("learnerNumbers")}
            type="number"
            min={1}
          />
        </FutureInputFilter>
      </FutureArticleHeader>
    );
  };
