import React from "react";
import { FunctionComponent } from "react";
import { Button, ValidationErrors } from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";
import { useBookerIntention } from "../../hooks/useBookerIntention";
import { BookerIntention } from "../../pages/Order/BookingPage";
import { hasUnlimitedPlaces } from "../../utils/eventHelpers";
import { Bookable, Cart, Price } from "../../generated/weblinkTypes";
import {
  Maybe,
  RegisterLearningPathLearnerResponseType,
  RegisterPassholderResponseType,
  TrainingPassType,
} from "../../generated/lmsTypes";
import { sendEvent } from "../../analytics";
import { useLmsMutation } from "../../hooks/lms";
import {
  REGISTER_PASSHOLDER_TO_EVENT,
  REGISTER_PASSHOLDER_TO_LEARNING_PATH,
} from "../../mutations/registrations";
import { computePassStatus } from "../TrainingPass";
import { useViewer } from "../../providers/ViewerProvider";
import { ExecutionResult, MutationFunctionOptions } from "@apollo/react-common";
import { MutationErrors, throwIfErrors } from "../../utils/errorHelpers";
import { useHistory } from "../../useHistory";
import { get } from "lodash";
import { useLocation } from "react-router-dom";
import { bookableIsLearningPath } from "./bookables";
import { getLearningObjectiveOptions } from "../../utils/weblinkLearningPathHelpers";
import { useDateFormatter } from "../../hooks/useDateFormatter";
import { useAnalytics } from "../../providers/AnalyticsProvider";
import { CartEvent } from "../../analytics/events";

type RegisterPassholderMutationResponseType = {
  registration: {
    registerPassholder: RegisterPassholderResponseType;
  };
};

interface RegisterPassholderPathMutationResponseType {
  registration: {
    registerLearningPathLearner: RegisterLearningPathLearnerResponseType;
  };
}

export const BookNowButton: FunctionComponent<{
  bookable: Bookable;
  financialUnit?: string;
  hidePrices?: boolean;
  disabled: boolean;
  setMutationRunning: (loading: boolean) => void;
  contentPasses: TrainingPassType[];
  cartlessCheckout?: boolean;
  viewersLastCart: Cart | null;
  viewersCartLoading: boolean;
  addCartLineItem: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<ExecutionResult<any>>;
  createCart: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<ExecutionResult<any>>;
  setApiError: React.Dispatch<React.SetStateAction<boolean>>;
  setPassRegisterError: React.Dispatch<
    React.SetStateAction<Maybe<React.ReactNode>>
  >;
}> = ({
  bookable,
  financialUnit,
  hidePrices,
  disabled,
  setMutationRunning,
  contentPasses,
  cartlessCheckout,
  viewersLastCart,
  viewersCartLoading,
  addCartLineItem,
  createCart,
  setApiError,
  setPassRegisterError,
}) => {
  const { t } = useTranslation();

  const [registerPassHolderToEvent] =
    useLmsMutation<RegisterPassholderMutationResponseType>(
      REGISTER_PASSHOLDER_TO_EVENT,
    );

  const [registerPassHolderToPath] =
    useLmsMutation<RegisterPassholderPathMutationResponseType>(
      REGISTER_PASSHOLDER_TO_LEARNING_PATH,
    );

  const { viewer } = useViewer();
  const { captureEvent } = useAnalytics();

  const { validPassHeld, validActivePassId } = computePassStatus(
    viewer?.trainingPasses ?? [],
    contentPasses.map(cp => cp.id ?? ""),
  );

  const history = useHistory();

  const location = useLocation();
  const quickRegister = new URLSearchParams(location.search).get(
    "quickRegister",
  );

  const quickRegisterSearchParam = "quickRegister=1";

  const isQuickRegister = () => {
    return quickRegister === "1";
  };

  const shouldSkipBookerInformation = (cartLength: number) => {
    return cartLength === 1;
  };

  const [bookerIntention] = useBookerIntention();
  const buttonLabel = validPassHeld
    ? t("registerNow")
    : bookerIntention === BookerIntention.Self
    ? t("register")
    : t("bookNow");

  const hasRemainingPlaces = (bookable: Bookable) =>
    bookable.remainingPlaces && bookable.remainingPlaces > 0;

  if (hidePrices) {
    financialUnit = bookable && bookable.prices[0].financialUnit?.code;
  }

  const canBeBooked =
    hasUnlimitedPlaces(bookable) || hasRemainingPlaces(bookable);

  const canBook = canBeBooked && (validPassHeld || financialUnit);

  const onClickBook = (
    bookable: Bookable,
    financialUnit?: string,
    validPassHeld?: boolean,
  ) => {
    setMutationRunning(true);

    if (bookerIntention === BookerIntention.Self && validPassHeld) {
      handlePassHolderClickAccessNow(bookable);
      return;
    }

    const selectedPrice =
      bookable.prices &&
      bookable.prices.find(
        (price: Price) => price.financialUnit?.code === financialUnit,
      );

    if (selectedPrice) {
      const cartInput = {
        currencyCode: selectedPrice.financialUnit?.code,
        regionId: selectedPrice.region?.id,
      };

      if (bookerIntention === BookerIntention.Self && !cartlessCheckout) {
        handleAddToCart(cartInput, bookable);
      } else {
        handleCartlessCheckout(cartInput, bookable);
      }
    }
  };

  const handleCartlessCheckout = async (
    input: {
      currencyCode?: string;
      regionId?: string;
    },
    bookable: Bookable,
  ) => {
    const response = await createCart({
      variables: { input },
    });
    const cartId = get(response, "data.cart.createCart.cart.id", "");
    if (response.errors && response.errors.length > 0) {
      console.error("encountered an error while creating cart");
      console.error(response.errors);
      setApiError(true);
    } else {
      if (bookableIsLearningPath(bookable)) {
        const objectiveOptions = getLearningObjectiveOptions(
          bookable.learningObjectives.edges,
        );
        localStorage.setItem(cartId, JSON.stringify(objectiveOptions));
        // TODO: Refactor `LearningPathLearnerSelection` so that it doesn't require these to be
        // set in local storage. They aren't used by `LearningPathLearnerSelection` since the Paths
        // we show in Bookables are Event Bundles (e.g. Event Objectives only), so we end up not
        // even specifying any objectives when we add the itme to the Cart.
      }
      history.push({
        pathname: `/catalog/${bookerIntention}/${getBookingPathFromBookable(
          bookable,
          cartId,
        )}`,
      });
    }
  };

  const handlePassHolderClickAccessNow = (bookable: Bookable) => {
    if (bookableIsLearningPath(bookable)) {
      handlePassPathRegistration(bookable);
    } else {
      handlePassRegistration(bookable);
    }
  };

  const navigateToCourse = (registrationId: string) => {
    history.push({
      pathname: `/my-courses/course/${registrationId}/registrationSuccess`,
    });
  };

  const navigateToPath = (registrationId: string) => {
    history.push({
      pathname: `/my-courses/learning-path/${registrationId}/registrationSuccess`,
    });
  };

  const handlePassPathRegistration = (bookable: Bookable) => {
    sendEvent({
      location: "Catalog",
      action: "Register Now on Learning Path with Training Pass",
      value: 1,
    });

    const learningPathId = bookable?.id ?? "";
    const passTypeId =
      viewer?.trainingPasses?.find(pass => pass?.id === validActivePassId)
        ?.trainingPassTypeId ?? "";

    setMutationRunning(true);
    registerPassHolderToPath({ variables: { learningPathId, passTypeId } })
      .then(getRegisterPassholderPathResponseData)
      .then(throwIfErrors)
      .then(extractRegistrationId)
      .then(navigateToPath)
      .catch(errors => {
        try {
          const includesContactAlreadyRegistered = (element: {
            label: string;
            message: string;
            value: string;
          }) =>
            element.message === "Contact already registered on Learning Path";
          if (errors.errors?.some(includesContactAlreadyRegistered)) {
            setPassRegisterError(t("contactAlreadyRegisteredOnLearningPath"));
          }
        } catch {
          setPassRegisterError("");
        }
      })
      .finally(() => {
        setMutationRunning(false);
      });
  };

  const handlePassRegistration = (bookable: Bookable) => {
    sendEvent({
      location: "Catalog",
      action: "Register Now on Event with Training Pass",
      value: 1,
    });

    setMutationRunning(true);
    registerPassHolderToEvent({
      variables: {
        eventId: bookable.id,
        passId: validActivePassId,
      },
    })
      .then(getRegisterPassholderResponseData)
      .then(throwIfErrors)
      .then(getCourseIdFromPassHolderResponse)
      .then(navigateToCourse)
      .catch((err: MutationErrors | any) => {
        if (err instanceof MutationErrors) {
          setPassRegisterError(
            <ValidationErrors
              title={t("encounteredAnErrorWhileRequestingACourse")}
              mutationValidationErrors={err.errors as any} // force any to make the heavy Maybe'd FieldError compat with piston-ux's FieldError
            />,
          );
        } else {
          setPassRegisterError(t("anErrorOccurredWhileLoadingCourses"));
          console.error(err);
        }
        return;
      })
      .finally(() => {
        setMutationRunning(false);
      });
  };

  const handleAddToCart = async (
    input: {
      currencyCode?: string;
      regionId?: string;
    },
    event: Bookable,
  ) => {
    let cartId = viewersLastCart?.id;
    if (!viewersCartLoading && !viewersLastCart) {
      const response = await createCart({
        variables: { input },
      });
      cartId = get(response, "data.cart.createCart.cart.id", "");
    }
    const addLineItemResponse = await addCartLineItem({
      variables: {
        input: {
          cartId,
          cartLineItem: {
            productOptionId: event && event.id,
            learners: { existingLearners: [{ contactId: viewer?.id }] },
            quantity: 1,
          },
        },
      },
    });
    let cartLength = get(
      addLineItemResponse,
      "data.cart.addLineItem.cart.items.length",
    );
    let cartErrors = get(
      addLineItemResponse,
      "data.cart.addLineItem.errors.length",
    );
    if (
      (addLineItemResponse.errors && addLineItemResponse.errors.length > 0) ||
      cartErrors
    ) {
      setApiError(true);
    } else {
      captureEvent(
        CartEvent.fromCartAdd(
          addLineItemResponse.data.cart.addLineItem.cart,
          event.id,
        ),
      );
      if (isQuickRegister()) {
        if (shouldSkipBookerInformation(cartLength)) {
          history.push({
            pathname: "/checkout/learner-details",
          });
        } else {
          history.push(`/cart?${quickRegisterSearchParam}`);
        }
      } else {
        history.push({
          pathname: `/cart`,
        });
      }
    }
  };

  const { dateFormat } = useDateFormatter({
    showDayName: false,
    showTime: true,
  });

  const registrationOpen =
    bookable.__typename === "LearningPath" ||
    (bookable.__typename === "Event" && bookable.registrationOpen);
  const registrationOpensAt =
    bookable.__typename === "Event" ? bookable.registrationOpensAt : null;

  return registrationOpen ? (
    <Button
      type="suppressed"
      label={canBeBooked ? buttonLabel : t("fullyBooked")}
      preventDefault={false}
      disabled={disabled || !canBook}
      onClick={_evt =>
        (financialUnit || validPassHeld) &&
        onClickBook(bookable, financialUnit, validPassHeld)
      }
      ariaLabel={`${buttonLabel} - ${bookable.name}`}
    />
  ) : (
    <div>
      <small>{t("registrationOpensOn")}</small>
      <p>{dateFormat({ date: registrationOpensAt })}</p>
    </div>
  );
};

const getRegisterPassholderResponseData = (
  resp: ExecutionResult<RegisterPassholderMutationResponseType>,
) =>
  resp.data?.registration?.registerPassholder as RegisterPassholderResponseType;

const getCourseIdFromPassHolderResponse = (
  registerPassholder: RegisterPassholderResponseType,
) => registerPassholder?.registration?.id as string;

const getBookingPathFromBookable = (bookable: Bookable, cartId: string) => {
  switch (bookable.__typename) {
    case "Event":
      return `event/${bookable.id}/booking/${cartId}`;
    case "LearningPath":
      return `learning-path/${bookable.id}/booking/${cartId}`;
  }
};

const extractRegistrationId = (
  registeredLearningPathLearner: RegisterLearningPathLearnerResponseType,
) => registeredLearningPathLearner.registration?.id as string;

const getRegisterPassholderPathResponseData = (
  response: ExecutionResult<RegisterPassholderPathMutationResponseType>,
) =>
  response?.data?.registration
    ?.registerLearningPathLearner as RegisterLearningPathLearnerResponseType;
