import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { BrowseCatalog } from "./BrowseCatalog";
import { useBookerIntention } from "../../hooks/useBookerIntention";
import { BookerIntention } from "../Order/BookingPage";

export const SelfRegistrationCatalog: FunctionComponent = () => {
  const { t } = useTranslation();

  const [bookerIntention, setBookerIntention] = useBookerIntention(
    BookerIntention.Self,
  );

  if (bookerIntention !== BookerIntention.Self) {
    setBookerIntention(BookerIntention.Self);
  }

  return <BrowseCatalog title={t("myCatalog")} />;
};
