import React from "react";
import {
  Card,
  FutureDetailPage,
  DetailPageContent,
} from "@administrate/piston-ux";
import { TabPane, Tab } from "@administrate/piston-ux/lib/Tab";

import { CardNavigation } from "../../toPistonUX/CardNavigation";
import { useViewer } from "../../providers/ViewerProvider";
import { Preferences } from "./Preferences";
import { ContactDetail } from "./ContactDetail";
import { Security } from "./Security";
import { useTranslation } from "react-i18next";
import { usePortal } from "../../providers/PortalProvider";
import { useTrainingTokensEnabled } from "../../hooks/useTrainingTokensEnabled";
import { DetailPageOverview } from "@administrate/piston-ux/lib/layouts/DetailPage/DetailPageOverview";
import { TrainingPasses } from "./TrainingPasses";
import { TrainingTokens } from "./TrainingTokens";

export const MyProfile: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { isViewerLoading, viewer } = useViewer();
  const useTrainingTokens = useTrainingTokensEnabled();
  const portal = usePortal();
  const { isSingleSignOnOnly } = portal;

  // TODO: cut this if statement after useFormValues can be updated
  if (isViewerLoading || !viewer) {
    return null;
  }

  let menuOptions = [
    {
      name: "personalInformation",
      icon: "user",
      label: t("personalInformation"),
    },
    {
      name: "preferences",
      icon: "cogwheels",
      label: t("preferences"),
    },
    {
      name: "trainingPass",
      icon: "id-badge",
      label: t("trainingPass"),
    },
  ];

  if (!isSingleSignOnOnly) {
    menuOptions.splice(2, 0, {
      name: "security",
      icon: "lock",
      label: t("security"),
    });
  }
  if (useTrainingTokens) {
    menuOptions.push({
      name: "trainingTokens",
      icon: "tag",
      label: t("trainingTokens"),
    });
  }

  return (
    <Tab.Container defaultTab="personalInformation" id="my-profile-navigation">
      <FutureDetailPage
        title={t("myProfile")}
        detailType="Profile"
        legacyId={0}
        form={{ saveState: null }}
        page="overview"
        layout="adjacent"
      >
        <DetailPageOverview>
          <Card
            profile={{
              type: "contact",
              alignment: "vertical",
            }}
            title={`${viewer.firstName} ${viewer.lastName}`}
          >
            <div className="center-block">
              <span className="d-block font-weight-bold">{viewer.company}</span>
            </div>
            <div className="mb-3" />
            <CardNavigation menu={menuOptions} />
          </Card>
        </DetailPageOverview>
        <DetailPageContent>
          <Tab.Content>
            <TabPane name="personalInformation">
              <ContactDetail viewer={viewer} />
            </TabPane>
            <TabPane name="preferences">
              <Preferences viewer={viewer} />
            </TabPane>
            <TabPane name="security">
              <Security viewer={viewer} />
            </TabPane>
            <TabPane name="trainingPass">
              <TrainingPasses trainingPasses={viewer.trainingPasses} />
            </TabPane>
            <TabPane name="trainingTokens">
              <TrainingTokens viewer={viewer} />
            </TabPane>
          </Tab.Content>
        </DetailPageContent>
      </FutureDetailPage>
    </Tab.Container>
  );
};
