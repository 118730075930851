import gql from "graphql-tag";
import { debounce } from "lodash";

function getAttribute(obj, key, defaultArg = undefined) {
  const output = key
    .split(".")
    .reduce((o, x) => (typeof o === "undefined" || o === null ? o : o[x]), obj);
  if (output) {
    return output;
  }
  return defaultArg === undefined ? output : defaultArg;
}

const noop = () => {};

function createSingleCallback(func) {
  let callback = debounce(func);
  return () => {
    callback();
    callback = noop;
  };
}

const resultsEdges =
  "registration.attemptScormContent.registration.contentResults.edges";
const progressEdges =
  "registration.recordScormProgress.registration.contentResults.edges";

export const previewModeFlag = "preview_mode";
const loadRequest = gql`
  mutation scormApiLoad(
    $registrationId: ID!
    $contentId: ID!
    $strContentId: String!
  ) {
    registration {
      attemptScormContent(
        input: { registrationId: $registrationId, contentId: $contentId }
      ) {
        registration {
          contentResults(
            filters: [{ field: contentId, operation: eq, value: $strContentId }]
          ) {
            edges {
              node {
                ... on ScormContentResult {
                  latestAttempt {
                    id
                    data
                  }
                  status
                }
              }
            }
          }
        }
      }
    }
  }
`;

const commitRequest = gql`
  mutation scormApiCommit(
    $registrationId: ID!
    $contentId: ID!
    $strContentId: String!
    $attemptId: ID!
    $data: String!
    $sessionFinished: Boolean!
  ) {
    registration {
      recordScormProgress(
        input: {
          registrationId: $registrationId
          contentId: $contentId
          attemptId: $attemptId
          data: $data
          sessionFinished: $sessionFinished
        }
      ) {
        registration {
          contentResults(
            filters: [{ field: contentId, operation: eq, value: $strContentId }]
          ) {
            edges {
              node {
                ... on ScormContentResult {
                  latestAttempt {
                    id
                    data
                  }
                  status
                }
              }
            }
          }
        }
      }
    }
  }
`;

export class ScormDataModel {
  static _getLatestAttemptAndStatus(data, contentResultsPath) {
    const contentResults = getAttribute(data, contentResultsPath);
    if (
      Array.isArray(contentResults) &&
      contentResults[0] &&
      contentResults[0].node
    ) {
      const { status, latestAttempt } = contentResults[0].node;
      return { status, latestAttempt };
    }
    return {};
  }

  constructor(
    registrationId,
    contentId,
    apolloClient,
    completedCallback,
    apolloErrorCallback,
    forceSaveConfiguration,
  ) {
    this._registrationId = registrationId;
    this._contentId = contentId;
    this._decodedContentId = atob(this._contentId).split(":")[1];
    this._apolloClient = apolloClient;
    this._completedCallback = createSingleCallback(completedCallback || noop);
    this._apolloErrorCallback = apolloErrorCallback;
    this._data = {};
    this._resetChangeState();
    this._resetPersist();
    this._attemptId = null;
    this._isLoaded = false;
    this._isSessionFinished = false;

    this._forceSaveConfiguration = forceSaveConfiguration || {
      importantElements: new Set([
        "cmi.completion_status",
        "cmi.success_status",
        "cmi.core.lesson_status",
      ]),
      minimumChanges: 10,
      minimumTimeSec: 3 * 60,
    };

    // this is quite spammy, but can help you debug SCORM, so turn it on if you need it and then commit turned off
    this._debugLogging = false;
  }

  get(element) {
    this._log(`read: ${element}`);
    const parent = this._parent(element);
    const key = element.split(".").pop();
    switch (key) {
      case "_count":
        return parent.length;
      default:
        if (parent[key] !== undefined) {
          return parent[key];
        }
        return "";
    }
  }

  set(element, value) {
    const parent = this._parent(element);
    const key = element.split(".").pop();
    if (parent[key] === value) {
      this._log(`not changed: ${element}`);
      return;
    }

    this._dirty = true;
    this._changesCount++;
    parent[key] = value;
    this._log(`changed (${this._changesCount}): ${element}`);

    if (this._shouldForceSave(element)) {
      this.commit(false, true);
    }
  }

  load(callback) {
    // Nothing to load if this is a preview
    if (this._registrationId === previewModeFlag) {
      this._log("preview mode - not initializing");
      if (callback) callback();
      return;
    }

    if (this._isLoaded) {
      this._log("already initialized");
      if (callback) callback();
      return;
    }

    // Create a handler to process the mutation response
    const mutationHandler = ({ data }) => {
      const { status, latestAttempt } =
        ScormDataModel._getLatestAttemptAndStatus(data, resultsEdges);
      if (!latestAttempt) {
        throw Error("SCORM load failed");
      }
      this._attemptId = latestAttempt.id;
      this._data = JSON.parse(latestAttempt.data);
      this._isLoaded = true;
      if (status === "completed" || status === "passed")
        this._completedCallback = noop;
      if (callback) callback();
    };

    // Load the data
    this._apolloClient
      .mutate({
        mutation: loadRequest,
        variables: {
          registrationId: this._registrationId,
          contentId: this._contentId,
          strContentId: this._contentId,
        },
      })
      .then(mutationHandler)
      .catch(error => this._apolloErrorCallback(error));

    this._log("initialized");
  }

  defaults(defaults) {
    const extend = (to, from) => {
      const keys = Object.keys(from);
      for (let i = 0; i < keys.length; i += 1) {
        const val = from[keys[i]];
        const scalar =
          ["string", "number", "array", "boolean"].indexOf(typeof val) >= 0;
        to[keys[i]] = scalar ? val : extend(to[keys[i]] || {}, val);
      }
      return to;
    };

    extend(this._data, defaults);
  }

  commit(finish = false, forced = false) {
    const operationName = `${forced ? "forced " : ""}commit${
      finish ? " on finish" : ""
    }`;
    this._isSessionFinished = finish;
    if (
      this._registrationId === previewModeFlag ||
      !this._attemptId ||
      !this._dirty
    ) {
      this._log(`no ${operationName} needed`);
      return;
    }

    if (this._isPersisting || this._isPersistQueued) {
      this._log(`${operationName} postponed`);
      this._isPersistQueued = true;
      return;
    }

    this._log(`calling persist for ${operationName}`);
    this._persist();
  }

  _persist() {
    this._log(
      `persisting changes ${this._isSessionFinished ? "on finish " : ""}...`,
    );
    this._isPersisting = true;
    this._apolloClient.stop();
    this._apolloClient
      .mutate({
        mutation: commitRequest,
        variables: {
          registrationId: this._registrationId,
          contentId: this._contentId,
          strContentId: this._contentId,
          attemptId: this._attemptId,
          data: JSON.stringify(this._data),
          sessionFinished: this._isSessionFinished,
        },
      })
      .then(this._mutationHandler.bind(this))
      .catch(error => {
        this._log("mutation error");
        this._resetPersist();
        this._apolloErrorCallback(error);
      });
  }

  _mutationHandler({ data }) {
    const wasPersistQueued = this._isPersistQueued;
    this._resetPersist();

    const { status, latestAttempt } = ScormDataModel._getLatestAttemptAndStatus(
      data,
      progressEdges,
    );
    if (!latestAttempt) {
      throw Error("SCORM commit failed");
    }

    this._resetChangeState();
    if (status === "completed" || status === "passed") {
      this._completedCallback();
    }

    if (wasPersistQueued) {
      this._log("repeating persist for previously queued commit(s)");
      this._persist();
    }
  }

  /**
   * Builds the "parent" data structure needed to store the element.
   */
  _parent(element) {
    let parent = this._data;
    const path = element.split(".");
    for (let i = 0; i < path.length; i++) {
      const current = path[i];
      const next = path[i + 1];
      if (next) {
        if (!parent[current]) {
          this._dirty = true;
          if (next.match(/^([0-9]+|_count)$/)) {
            parent[current] = [];
          } else {
            parent[current] = {};
          }
        }
        parent = parent[current];
      }
    }
    return parent;
  }

  _resetChangeState() {
    this._dirty = false;
    this._changesCount = 0;
    this._lastSaveTimestamp = this._getCurrentTimestamp();
    this._log(
      `state marked as clean and changes count reset at ${this._lastSaveTimestamp}`,
    );
  }

  _resetPersist() {
    this._isPersisting = false;
    this._isPersistQueued = false;
    this._log("persist status and persist queue reset");
  }

  _shouldForceSave(element) {
    if (this._forceSaveConfiguration.importantElements.has(element)) {
      this._log(
        `forced save triggered for important change in element ${element}`,
      );
      return true;
    }

    if (this._changesCount >= this._forceSaveConfiguration.minimumChanges) {
      const currentTimestamp = this._getCurrentTimestamp();
      const timeDifferenceSec = currentTimestamp - this._lastSaveTimestamp;
      if (timeDifferenceSec >= this._forceSaveConfiguration.minimumTimeSec) {
        this._log(
          `forced save triggered for changes count (${this._changesCount}) and time passed (${timeDifferenceSec} seconds)`,
        );

        // reset the time to only perform one save attempt every minimumTimeSec seconds, regardless of the outcome
        this._lastSaveTimestamp = currentTimestamp;

        return true;
      }
    }

    return false;
  }

  _log(message) {
    if (!this._debugLogging || process.env.NODE_ENV !== "development") {
      return;
    }
    console.log(`[SDM:${this._decodedContentId}] ${message}`);
  }

  _getCurrentTimestamp() {
    return Math.round(Date.now() / 1000);
  }
}
