import { useGlobalStore } from "../providers/GlobalStore";
import { useLmsClient } from "./lms";
import { useViewer } from "../providers/ViewerProvider";
import { useEffect, useState } from "react";
import { ScormDataModel } from "../components/ScormPlayer/ScormDataModel";
import { useAuth0 } from "../react-auth0-wrapper";
import { SCORM_VERSIONS } from "../components/OnlineContent/SCORMPlayer";
import { COURSE_REGISTRATION_DETAILS_QUERY } from "../queries/registrations";
import { Maybe } from "../types/Maybe";
import { setStateType } from "../types/SetStateType";

const useScormSetup = (
  contentId: string,
  entrypoint: string,
  version: string,
  setApolloError: setStateType<any>,
  registrationId?: string,
  childWindow?: Window,
) => {
  const globalStore = useGlobalStore();
  const scormLmsClient = useLmsClient(true);
  const mainLmsClient = useLmsClient(false);
  const { viewer } = useViewer();
  const [scormDataModel, setScormDataModel] =
    useState<Maybe<ScormDataModel>>(null);
  const [scormApiReady, setScormApiReady] = useState(false);
  const [frameSrc, setFrameSrc] = useState<string>("");

  useEffect(() => {
    // update scormDataModel's contentId if contentId changes
    setScormDataModel(
      new ScormDataModel(
        viewer && viewer.isPreviewer ? "preview_mode" : registrationId,
        contentId,
        scormLmsClient,
        () => {
          // noinspection JSIgnoredPromiseFromCall
          mainLmsClient.query({
            query: COURSE_REGISTRATION_DETAILS_QUERY,
            variables: {
              id: registrationId,
            },
            fetchPolicy: "network-only",
          });
        },
        (error: any) => {
          setApolloError(error);
        },
      ),
    );
  }, [
    setApolloError,
    contentId,
    viewer,
    scormLmsClient,
    registrationId,
    mainLmsClient,
  ]);

  const { user } = useAuth0();

  useEffect(() => {
    const { ApiType, apiName, userMapper } =
      SCORM_VERSIONS[version as keyof typeof SCORM_VERSIONS];

    if (scormDataModel) {
      scormDataModel.load(() => {
        (window as any)[apiName] = new ApiType(scormDataModel, {
          cmi: {
            core: {
              ...userMapper(user),
            },
          },
        });
        setScormApiReady(true);
      });

      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          let src = `/files/${
            JSON.parse(this.responseText).encryptedFilePath
          }/${entrypoint.split("/").slice(1).join("/")}`;
          setFrameSrc(src);
          if (childWindow) {
            childWindow.location.href = src;
          }
        }
      };
      const fileRoute = viewer?.isPreviewer
        ? `/preview/${globalStore.previewJWT}/content/${contentId}/access`
        : `/registerables/${registrationId}/content/${contentId}/access`;

      xhttp.open("GET", fileRoute, true);
      xhttp.setRequestHeader("Authorization", `Bearer ${globalStore.token}`);
      xhttp.send();
    }

    return () => {
      (window as any)[apiName] = null;
    };
  }, [
    registrationId,
    contentId,
    entrypoint,
    globalStore.token,
    user,
    scormDataModel,
    version,
    viewer,
    globalStore.previewJWT,
    childWindow,
  ]);

  return { scormApiReady, frameSrc, scormDataModel };
};

export default useScormSetup;
