import gql from "graphql-tag";

export const APPLY_PAYMENT_CODE = gql`
  mutation applyPaymentCode($input: ApplyPaymentCodeInput!) {
    cart {
      applyPaymentCode(input: $input) {
        cart {
          id
          paymentCodeApplications {
            paymentCode
            tokenIssueBalance
          }
        }
        errors {
          message
          value
        }
      }
    }
  }
`;
