import React, {
  FunctionComponent,
  useEffect,
  useState,
  useCallback,
} from "react";
import {
  Card,
  useFormValues,
  Form,
  FutureDetailPage,
  DetailPageContent,
} from "@administrate/piston-ux";
import { useTranslation } from "react-i18next";

import { useHistory, useParams } from "../../useHistory";
import { useWebLinkQuery } from "../../hooks/weblink";
import { WEBLINK_LEARNING_PATH_BOOKINGS_BY_ID } from "../../queries/bookings";
import {
  Query,
  LearningPathBooking,
  LearnerField,
  FilterOperation,
} from "../../generated/weblinkTypes";
import { ItemInformation } from "../../components/ItemInformation";
import { BookingLearnersForm } from "./BookingLearnersForm";
import {
  BookingLearnersHeader,
  BookingLearnersFilters,
} from "./BookingLearnersHeader";
import {
  updateFormLearners,
  mergeLearnerResults,
} from "../../utils/bookingHelpers";
import { BookingQueryVariables } from "../../types/Bookings";
import { DetailPageOverview } from "@administrate/piston-ux/lib/layouts/DetailPage/DetailPageOverview";
import { CancelLearnerModal, LearnerCategory } from "./CancelLearnerModal";

const DEFAULT_LEARNING_PATH: LearningPathBooking = {
  id: "",
  name: "",
  start: "",
  end: "",
  imageUrl: "",
  price: { amount: 0, financialUnit: { name: "", code: "", symbol: "" } },
  customFieldValues: [],
  learners: {
    edges: [],
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
      totalRecords: 0,
      startCursor: "",
      endCursor: "",
    },
  },
  __typename: "LearningPathBooking",
};

const DEFAULT_NUM_LEARNERS = 50;

export const ExistingLearningPathBooking: FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { bookingId } = useParams<{bookingId:string}>();

  const [cancellingLearnerId, setCancellingLearnerId] =
    useState<string | null>(null);

  const [headerFilters, setHeaderFilters] = useState<BookingLearnersFilters>({
    search: "",
    learnerStatus: undefined,
  });
  const filterChange = useCallback(
    filters => setHeaderFilters(filters),
    [setHeaderFilters],
  );

  const queryVariables = useCallback(
    (learnerOffset?: number) => {
      const variables: BookingQueryVariables = {
        bookingId,
        noOfLearners: DEFAULT_NUM_LEARNERS,
        learnerOffset: learnerOffset ? learnerOffset : 0,
        learnerFilters: [],
      };

      const { search, learnerStatus } = headerFilters;

      if (search) {
        variables.learnerFilters.push({
          field: LearnerField.ContactName,
          operation: FilterOperation.Like,
          value: `%${search}%`,
        });
      }
      if (learnerStatus) {
        const { value } = learnerStatus;

        if (value === "active") {
          variables.learnerFilters.push({
            field: LearnerField.IsCancelled,
            operation: FilterOperation.Eq,
            value: "false",
          });
        }
        if (value === "cancelled") {
          variables.learnerFilters.push({
            field: LearnerField.IsCancelled,
            operation: FilterOperation.Eq,
            value: "true",
          });
        }
      }

      return variables;
    },
    [headerFilters, bookingId],
  );

  const {
    loading,
    data,
    refetch: refetchBooking,
    fetchMore,
    error,
  } = useWebLinkQuery<Query>(WEBLINK_LEARNING_PATH_BOOKINGS_BY_ID, {
    variables: queryVariables(),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const booking =
    data?.viewer?.learningPathBookings?.edges?.[0]?.node ||
    DEFAULT_LEARNING_PATH;

  const fetchMoreLearners = (learnerOffset: number) => {
    fetchMore({
      variables: queryVariables(learnerOffset),
      updateQuery: (prev, { fetchMoreResult }) =>
        mergeLearnerResults("learningPathBookings", prev, fetchMoreResult),
    });
  };

  const firstBooking = data?.viewer?.learningPathBookings?.edges?.[0]?.node;

  const totalLearnerRecords =
    firstBooking?.learners?.pageInfo?.totalRecords || 0;

  const canAddMoreLearners = () => {
    return false;
  };

  const pointOfSaleFields = data?.pointOfSaleLearnerFields || [];

  const mappedPosFields = pointOfSaleFields.filter(field => field.mappedField);

  const values = useFormValues({
    learners: [],
  });

  useEffect(() => {
    const learners = firstBooking?.learners?.edges || [];
    updateFormLearners(learners, values, mappedPosFields);
  }, [firstBooking, mappedPosFields, values]);

  // Since we re-use the item query to fetch more learners, we need to avoid putting the item details into a loading
  // state each time
  const isLoadingItemDetails = loading && !data;

  return (
    <>
      <FutureDetailPage
        back={{
          label: t("bookings"),
          onClick: () => history.push("/learner-management/bookings/"),
        }}
        title={!isLoadingItemDetails ? booking.name : ""}
        legacyId={bookingId}
        detailType="Booking"
        form={{
          saveState: null,
        }}
        page="overview"
        layout="adjacent"
      >
        <DetailPageOverview>
          <ItemInformation
            data={{}}
            loading={isLoadingItemDetails}
            name={t("learningPath")}
            image={booking.imageUrl}
          />
        </DetailPageOverview>
        <DetailPageContent>
          <Form type="raw" values={values}>
            <Card>
              <BookingLearnersHeader
                loading={isLoadingItemDetails}
                onChange={filterChange}
                type="LearningPath"
                booking={booking}
                canAddMoreLearners={canAddMoreLearners}
              />
              <BookingLearnersForm
                values={values}
                mappedPosFields={mappedPosFields}
                totalNoOfLearners={totalLearnerRecords}
                fetchMoreLearners={fetchMoreLearners}
                error={error}
                isEvent={false}
                loading={loading}
                onCancelLearnerClick={setCancellingLearnerId}
              />
            </Card>
          </Form>
        </DetailPageContent>
      </FutureDetailPage>
      {!!cancellingLearnerId && (
        <CancelLearnerModal
          onModalClose={() => {
            refetchBooking();
            setCancellingLearnerId(null);
          }}
          learnerId={cancellingLearnerId}
          learnerCategory={LearnerCategory.LearningPath}
        />
      )}
    </>
  );
};
