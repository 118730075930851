import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  Fragment,
} from "react";
import {
  Container,
  useTypedFormValues,
  Form,
  Submit,
  Card,
  StaticInput,
  FormActions,
  FutureArticleHeader,
  LoadingScreen,
  Header,
  Row,
  Col,
  Alert,
} from "@administrate/piston-ux";
import { ensureObservableValue } from "@administrate/piston-ux/lib/withFormWrapper";
import { useTranslation } from "react-i18next";

import { ContactDetailForm } from "../../forms/ContactDetailForm";
import { ViewerCustomFieldValue } from "../../types/Viewer";
import { EventsResponse, Event } from "../../types/Event";
import { useHistory, useParams } from "../../useHistory";
import { TRAINING_REQUEST_MUTATION } from "../../queries/trainingRequests";
import { EVENT_BY_ID_QUERY } from "../../queries/events";
import { usePortal } from "../../providers/PortalProvider";
import { useViewer } from "../../providers/ViewerProvider";
import { TermsForm } from "./TermsForm";
import { useDateFormatter } from "../../hooks/useDateFormatter";
import { useLmsQuery, useLmsMutation } from "../../hooks/lms";
import { OrderSummary } from "../Order/OrderSummary";
import { MenuBar } from "../../MenuBar";
import { PointOfSaleLearnerFields } from "../../components/PointOfSaleLearnerFields";
import { POINT_OF_SALE_LEARNER_FIELD_QUERY } from "../../queries/cart";
import {
  PointOfSaleFieldEntityType,
  Query,
  PointOfSaleLearnerFieldDefinition,
} from "../../generated/lmsTypes";
import { has, extendObservable } from "mobx";
import { BookingStep } from "../Order/BookingPage";
import { mapPointOfSaleFieldValues } from "../../utils/customFieldValues";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum TrainingRequestStep {
  DETAILS,
  TERMS,
}

export enum StaticEventInputs {
  eventName,
  eventLocation,
  eventDate,
}

type RouteParams = {
  id: string;
};

type TrainingRequestFormProps = {
  trainingRequestItemId: string;
  event: Event;
  loading: boolean;
};

type FormValues = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  eventName: string | undefined;
  eventLocation: string | undefined;
  eventDate: string;
  posFieldValues: Record<string, string>;
};

const mapCustomFieldValuesToFormValues = (
  customFieldValues: ViewerCustomFieldValue[],
  posFields: PointOfSaleLearnerFieldDefinition[],
  formValues: FormValues,
) => {
  customFieldValues.forEach(({ definitionKey, value }) => {
    if (!definitionKey) return;

    // Find the PoS field that has a mapped field which matches the ContactCustom (Viewer) field.
    const posField = posFields.find(
      field => field.mappedField?.field === definitionKey,
    );
    if (!posField) return;

    if (!has(formValues.posFieldValues, posField.key)) {
      extendObservable(formValues.posFieldValues, { [posField.key]: value });
    }
  });
};

export const TrainingRequestForm: React.FunctionComponent<TrainingRequestFormProps> =
  ({ trainingRequestItemId, event, loading }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [bookingStep, setBookingStep] = useState<TrainingRequestStep>(
      TrainingRequestStep.DETAILS,
    );

    const [
      createTrainingRequest,
      {
        loading: createTrainingRequestIsLoading,
        data: createTrainingRequestData,
        called: createTrainingRequestCalled,
      },
    ] = useLmsMutation(TRAINING_REQUEST_MUTATION);

    const { termsAndConditions } = usePortal();
    const { viewer, isViewerLoading } = useViewer();

    const {
      data: pointOfSaleFieldsData,
      loading: pointOfSaleFieldsLoading,
      error: pointOfSaleFieldsError,
    } = useLmsQuery<Query>(POINT_OF_SALE_LEARNER_FIELD_QUERY, {
      variables: { entityType: PointOfSaleFieldEntityType.EventRegistration },
    });

    const posFields = useMemo(
      () =>
        pointOfSaleFieldsData && pointOfSaleFieldsData.pointOfSaleLearnerFields
          ? pointOfSaleFieldsData.pointOfSaleLearnerFields
          : [],
      [pointOfSaleFieldsData],
    );

    const { dateToDateFormat } = useDateFormatter();
    const eventDate = useMemo(
      () =>
        dateToDateFormat({
          start: event.start,
          end: event.end,
          ...(event.deliveryMethod === "classroom"
            ? { customTimeZoneName: event.timeZoneName }
            : {}),
        }),
      [event, dateToDateFormat],
    );

    const initialValues = {
      firstName: viewer?.firstName || "",
      lastName: viewer?.lastName || "",
      emailAddress: viewer?.emailAddress || "",
      eventName: event.name,
      eventLocation: event?.location?.name,
      eventDate: eventDate,
      posFieldValues: {},
    };
    const formValues = useTypedFormValues(initialValues);

    mapCustomFieldValuesToFormValues(
      viewer?.customFieldValues ?? [],
      posFields,
      formValues,
    );

    const acceptedTerms = useCallback(
      async (success: boolean) => {
        if (success) {
          const pointOfSaleFieldValues = mapPointOfSaleFieldValues(
            posFields,
            formValues.posFieldValues,
          );
          await createTrainingRequest({
            variables: {
              input: {
                trainingRequestItemId,
                pointOfSaleFieldValues,
              },
            },
          });
        } else {
          setBookingStep(TrainingRequestStep.DETAILS);
        }
      },
      [
        createTrainingRequest,
        setBookingStep,
        trainingRequestItemId,
        formValues.posFieldValues,
        posFields,
      ],
    );

    const addedBookerDetails = useCallback(
      async formValues => {
        if (termsAndConditions) {
          setBookingStep(TrainingRequestStep.TERMS);
        } else {
          acceptedTerms(true);
        }
      },
      [setBookingStep, termsAndConditions, acceptedTerms],
    );

    const isLoading =
      pointOfSaleFieldsLoading ||
      isViewerLoading ||
      createTrainingRequestIsLoading;

    useEffect(() => {
      const createTrainingRequestErrors =
        createTrainingRequestData?.trainingRequest?.createTrainingRequest
          ?.errors || [];
      if (
        createTrainingRequestCalled &&
        !createTrainingRequestIsLoading &&
        !createTrainingRequestErrors.length
      ) {
        history.push("/my-requests");
      }
    }, [
      createTrainingRequestCalled,
      createTrainingRequestIsLoading,
      createTrainingRequestData,
      history,
    ]);

    return (
      <>
        <Row>
          <Col lg={3} lgOffset={1} lgPush={7} md={4} mdPush={8}>
            <OrderSummary
              loading={isLoading || loading}
              trainingRequest={event}
              step={BookingStep.learnerDetails}
              hidePrices={false}
              isZeroPriced={true}
            />
          </Col>
          <Col lg={7} lgPull={3} md={8} mdPull={4} className="booking-page">
            {pointOfSaleFieldsError && (
              <div className="m-4">
                <Alert message={t("somethingWentWrong")} />
              </div>
            )}
            <Form
              values={formValues}
              onSubmit={addedBookerDetails}
              disabled={isLoading || loading}
            >
              <Card>
                <FutureArticleHeader title={t("bookingDetails")} />
                <ContactDetailForm
                  customViewerFields={[]}
                  isCustomViewerFieldsLoading={false}
                />
                <PointOfSaleFields
                  formValues={formValues}
                  posFields={posFields}
                />
                <div className="d-none">
                  <StaticInput label={t("name")} name="eventName" />
                  <StaticInput label={t("location")} name="eventLocation" />
                  <StaticInput label={t("date")} name="eventDate" />
                </div>
                <FormActions>
                  <Submit
                    label={t("completeBooking")}
                    inline
                    disabled={isLoading}
                  />
                </FormActions>
              </Card>
            </Form>
          </Col>
        </Row>
        <TermsForm
          onDone={acceptedTerms}
          show={bookingStep === TrainingRequestStep.TERMS}
          termsAndConditions={termsAndConditions}
          disabled={isLoading}
        />
      </>
    );
  };

// TODO: cut this wrapper component after useFormValues can be updated
export const TrainingRequest: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id: trainingRequestItemId } = useParams<RouteParams>();
  const { isViewerLoading, viewer } = useViewer();

  const { loading: eventIsLoading, data: eventResponse } =
    useLmsQuery<EventsResponse>(EVENT_BY_ID_QUERY, {
      variables: {
        id: trainingRequestItemId,
      },
      fetchPolicy: "cache-and-network",
    });
  const event = useMemo(
    () => eventResponse?.events?.edges?.[0]?.node,
    [eventResponse],
  );

  if (isViewerLoading || !viewer || eventIsLoading) {
    return <LoadingScreen />;
  }
  return (
    <Fragment>
      <MenuBar
        type="basic"
        back={{
          label: t("catalog"),
          onClick: () => history.push("/course-catalog"),
        }}
      />
      <main>
        <Header title={t("booking")} usage="portal" />
        <Container>
          {event ? (
            <TrainingRequestForm
              trainingRequestItemId={trainingRequestItemId}
              event={event}
              loading={isViewerLoading || !viewer || eventIsLoading}
            />
          ) : (
            t("eventNotFound")
          )}
        </Container>
      </main>
    </Fragment>
  );
};

const PointOfSaleFields = ({
  formValues,
  posFields,
}: {
  formValues: FormValues;
  posFields: PointOfSaleLearnerFieldDefinition[];
}) => {
  posFields.forEach((field: any) => {
    ensureObservableValue(formValues.posFieldValues, field.key);
  });

  return <PointOfSaleLearnerFields posFields={posFields} learnerNumber={0} />;
};
