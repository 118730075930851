import React, { useEffect, useState } from "react";
import { ScormApi12 } from "../ScormPlayer/ScormApi12";
import { ScormApi2004 } from "../ScormPlayer/ScormApi2004";
import { ScormLauncherPopup } from "../ScormPlayer/ScormLauncherPopup";
import { ScormLauncherInline } from "../ScormPlayer/ScormLauncherInline";
import { useGlobalStore } from "../../providers/GlobalStore";
import { setStateType } from "../../types/SetStateType";
import { GraphQLErrorTypes, hasErrorType } from "../../utils/errorHelpers";
export const SCORM_VERSIONS = {
  SCORM12: {
    ApiType: ScormApi12,
    apiName: "API",
    userMapper: (user: any) => ({
      student_id: user.sub,
      student_name: user.given_name,
    }),
  },
  SCORM2004: {
    ApiType: ScormApi2004,
    apiName: "API_1484_11",
    userMapper: (user: any) => ({
      learner_id: user.sub,
      learner_name: user.given_name,
    }),
  },
};

export const ScormPlayer: React.FunctionComponent<{
  registrationId?: string;
  contentId: string;
  entrypoint: string | undefined;
  version: string | undefined;
  setHasInvalidRegistration: setStateType<boolean>;
}> = ({
  registrationId,
  contentId,
  entrypoint = "/index.html",
  version = "SCORM2004",
  setHasInvalidRegistration,
}) => {
  const [apolloError, setApolloError] = useState<any>(null);

  useEffect(() => {
    if (!apolloError) {
      return;
    }
    if (!hasErrorType(apolloError, GraphQLErrorTypes.invalidRegistration)) {
      throw apolloError;
    }
    setHasInvalidRegistration(true);
  }, [setHasInvalidRegistration, apolloError]);

  const globalStore = useGlobalStore();
  return globalStore.config.open_scorm_new_window ? (
    <ScormLauncherPopup
      key={contentId}
      contentId={contentId}
      registrationId={registrationId}
      entrypoint={entrypoint}
      version={version}
      setApolloError={setApolloError}
    />
  ) : (
    <ScormLauncherInline
      contentId={contentId}
      registrationId={registrationId}
      entrypoint={entrypoint}
      version={version}
      setApolloError={setApolloError}
    />
  );
};
