import { Col, Container, Row } from "@administrate/piston-ux";
import React, { FunctionComponent } from "react";
import {
  Event as WeblinkEvent,
  LearningPath,
} from "../../generated/weblinkTypes";
import { useWeblinkSettings } from "../../hooks/useWeblinkSettings";
import { BookingPage, BookingStep } from "./BookingPage";
import { OrderSummary } from "./OrderSummary";

export const LearnerDetailsContainer: FunctionComponent<{
  loading: boolean;
  path?: LearningPath;
  event?: WeblinkEvent;
}> = ({ children, loading, event, path }) => {
  const {
    hidePricesBeforePricingAgreementApplied: hidePrices,
    loading: weblinkLoading,
  } = useWeblinkSettings();

  return (
    <BookingPage step={1}>
      <Container>
        <Row>
          <Col
            lg={3}
            lgOffset={1}
            lgPush={7}
            md={4}
            mdPush={8}
            data-label="Order Summary"
          >
            <OrderSummary
              event={event}
              path={path}
              loading={loading || weblinkLoading}
              step={BookingStep.learnerDetails}
              hidePrices={hidePrices}
              isZeroPriced={false}
            />
          </Col>
          <Col lg={7} lgPull={3} md={8} mdPull={4}>
            {children}
          </Col>
        </Row>
      </Container>
    </BookingPage>
  );
};
