import React, { useCallback, Fragment, useReducer } from "react";
import { Modal, Button } from "@administrate/piston-ux";
import { Maybe } from "../types/Maybe";
import { createObjectCsvStringifier } from "csv-writer";
import { useTranslation } from "react-i18next";

type PageInfo = {
  hasNextPage: boolean;
  totalRecords: number;
  endCursor: string;
};

type Edge<T> = {
  node: T;
};

type Connection<T> = {
  pageInfo: PageInfo;
  edges: Edge<T>[];
};

type DataFetcherResult<T> = {
  connection: Promise<Connection<T>>;
  cancel: () => void;
};

type DataFetcher<T> = (cursor: Maybe<string>) => DataFetcherResult<T>;

type ExportModalProps<T extends unknown> = {
  title: string;
  dataFetcher: DataFetcher<T>;
  transformData: (data: T[]) => {}[];
  csvKeys: string[];
  filenamePrefix?: string;
  id?: string;
};

type CancellableExport<T> = {
  data: Promise<Maybe<T[]>>;
  cancel: () => void;
};

type State<T> = {
  status: "closed" | "pending" | "in progress" | "rejected" | "resolved";
  error: Maybe<string>;
  recordsProcessed: Maybe<number>;
  totalRecords: Maybe<number>;
  currentExport: Maybe<CancellableExport<T>>;
};

type Action<T> =
  | { type: "close" }
  | { type: "start"; currentExport: CancellableExport<T> }
  | { type: "progress"; recordsProcessed: number; totalRecords: number }
  | { type: "error"; error: string }
  | { type: "success" };

function dataFetchReducer<T>(state: State<T>, action: Action<T>): State<T> {
  switch (action.type) {
    case "close":
      if (state.currentExport) {
        state.currentExport.cancel();
      }

      return {
        ...state,
        status: "closed",
      };
    case "start":
      return {
        ...state,
        status: "pending",
        currentExport: action.currentExport,
      };
    case "progress":
      return {
        ...state,
        status: "in progress",
        recordsProcessed: action.recordsProcessed,
        totalRecords: action.totalRecords,
      };
    case "error":
      return {
        ...state,
        status: "rejected",
        error: action.error,
      };
    case "success":
      return {
        ...state,
        status: "resolved",
      };
  }
}

export function ExportModal<T>({
  title,
  dataFetcher,
  transformData,
  csvKeys,
  filenamePrefix,
  id,
}: ExportModalProps<T>) {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(dataFetchReducer, {
    status: "closed",
    error: null,
    recordsProcessed: null,
    totalRecords: null,
    currentExport: null,
  });

  const modalOpen = state.status !== "closed";
  const isFetching = ["pending", "in progress"].includes(state.status);

  const sendCsvToBrowser = useCallback(
    (data: Array<Record<string, ReturnType<typeof transformData>>>) => {
      const fileName = `${filenamePrefix}_Export_${new Date().toLocaleDateString()}.csv`;

      const csvCreator = createObjectCsvStringifier({
        header: csvKeys.map(key => ({ id: key, title: key })),
      });
      let csv = "data:text/csv;charset=utf-8,";
      csv += csvCreator.getHeaderString();
      csv += csvCreator.stringifyRecords(data);

      const encodedUri = encodeURI(csv);
      const link = document.createElement("a");
      link.setAttribute("id", "csv-download-link");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    [csvKeys, filenamePrefix],
  );

  const fetchData = useCallback((): CancellableExport<T> => {
    let isCancelled = false;
    let currentQuery: Maybe<DataFetcherResult<T>> = null;

    const doExport = async () => {
      let data: T[] = [];
      let cursor = null;
      let connection = null;

      do {
        currentQuery = dataFetcher(cursor);
        connection = await currentQuery.connection;
        data = data.concat(connection.edges.map(edge => edge.node));
        cursor = connection.pageInfo.endCursor;

        if (!isCancelled) {
          dispatch({
            type: "progress",
            recordsProcessed: data.length,
            totalRecords: connection.pageInfo.totalRecords,
          });
        }
      } while (connection.pageInfo.hasNextPage && !isCancelled);

      if (isCancelled) {
        return null;
      }

      if (!data || data.length === 0) {
        throw new Error("Encountered an error while exporting data");
      }

      return data;
    };

    return {
      data: doExport(),
      cancel: () => {
        isCancelled = true;
        if (currentQuery) {
          currentQuery.cancel();
        }
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetcher]);

  const exportData = async () => {
    const currentExport = fetchData();

    dispatch({ type: "start", currentExport });

    try {
      const data = await currentExport.data;
      if (data === null) {
        return;
      }
      const transformedForCsv = transformData(data);
      sendCsvToBrowser(transformedForCsv);
      dispatch({ type: "success" });
    } catch (e) {
      dispatch({
        type: "error",
        error: e as string,
      });
    }
  };

  const onModalDone = (ok: boolean) => {
    dispatch({ type: "close" });
    return;
  };

  return (
    <Fragment>
      <Button
        label={title}
        onClick={exportData}
        type="suppressed"
        id={`${id ? `${id}-` : ""}export-action`}
      />
      <Modal
        title={title}
        show={modalOpen}
        disabled={isFetching}
        onDone={onModalDone}
        error={state.error}
        loading={isFetching}
        primaryActionText={t("OK")}
        id={`${id ? `${id}-` : ""}export-modal`}
      >
        {state.status === "pending" && `${t("pleaseWaitGeneratingDownload")}`}
        {state.status === "in progress" &&
          `${t("pleaseWaitGeneratingDownload")} ${state.recordsProcessed} ${t(
            "recordsOf",
          )} ${state.totalRecords}`}
        {state.status === "resolved" && (
          <Fragment>
            {t("exportFileHasBeenDownloaded")}
            <span className="sr-only">
              {t("toYourBrowsersDownloadsFolder")}
            </span>
          </Fragment>
        )}
      </Modal>
    </Fragment>
  );
}
