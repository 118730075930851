import React, { FunctionComponent, Fragment } from "react";
import { Maybe } from "@administrate/piston-ux/lib/types";

import { AccountNode } from "../types/LearnerManagement";
import { AccountAncestor } from "../generated/lmsTypes";

export const Ancestors: FunctionComponent<{
  ancestors: Maybe<AccountAncestor>[];
}> = ({ ancestors }) => {
  return (
    <span className="secondary-text">
      {ancestors.map((ancestor: Maybe<AccountAncestor>, i: number) => {
        if (ancestor) {
          return <Fragment key={i}>{ancestor.name} / </Fragment>;
        }
        return undefined;
      })}
    </span>
  );
};

export const AccountWithAncestors = (account: AccountNode) => {
  if (account.ancestors) {
    return (
      <Fragment>
        <Ancestors ancestors={account.ancestors} />
        <span style={{ display: "block" }}>{account.name}</span>
      </Fragment>
    );
  }
  return account.name;
};
