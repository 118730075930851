export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A datetime as an ISO 8601 formatted string */
  Datetime: any;
  /** A fixed-point number as an integer and fractional part String */
  Decimal: any;
  /** A universal resource locator that can be used to fetch data */
  URL: any;
  /** A hexidecimal code of format #RRGGBB that represents a colour */
  Colour: any;
};


export type Account = {
  __typename?: 'Account';
  id: Scalars['ID'];
  name: Scalars['String'];
  ancestors: Array<AccountAncestor>;
};

export type AccountAncestor = {
  __typename?: 'AccountAncestor';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AccountConnection = {
  __typename?: 'AccountConnection';
  pageInfo: PageInfo;
  edges: Array<AccountEdge>;
};

export type AccountEdge = {
  __typename?: 'AccountEdge';
  cursor: Scalars['String'];
  node: Account;
};

export enum AccountField {
  Id = 'id',
  Name = 'name'
}

export type AccountFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field?: Maybe<AccountField>;
  operation?: Maybe<FilterOperation>;
};

export type AccountFieldOrder = {
  field?: Maybe<AccountField>;
  direction?: Maybe<OrderDirection>;
};

export type Achievement = Node & {
  __typename?: 'Achievement';
  id: Scalars['ID'];
  achievementType?: Maybe<AchievementType>;
  achievedAt?: Maybe<Scalars['Datetime']>;
  expiresAt?: Maybe<Scalars['Datetime']>;
  lifecycleState?: Maybe<AchievementState>;
  registerable?: Maybe<Registerable>;
  identifier?: Maybe<Scalars['String']>;
  certificateUrl?: Maybe<Scalars['String']>;
  documentUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  documentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type AchievementConnection = {
  __typename?: 'AchievementConnection';
  pageInfo: PageInfo;
  edges: Array<AchievementEdge>;
};

export type AchievementEdge = {
  __typename?: 'AchievementEdge';
  cursor: Scalars['String'];
  node: Achievement;
};

export enum AchievementField {
  Id = 'id',
  ExpiresAt = 'expiresAt',
  AchievementTypeName = 'achievementTypeName'
}

export type AchievementFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field?: Maybe<AchievementField>;
  operation?: Maybe<FilterOperation>;
};

export type AchievementFieldOrder = {
  field?: Maybe<AchievementField>;
  direction?: Maybe<OrderDirection>;
};

export type AchievementsCount = {
  __typename?: 'AchievementsCount';
  count?: Maybe<Scalars['Int']>;
};

export enum AchievementState {
  Active = 'active',
  Revoked = 'revoked',
  Expired = 'expired'
}

export type AchievementType = Node & {
  __typename?: 'AchievementType';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  certificateType?: Maybe<CertificateType>;
};

export type AssociateUnnamedLearnerWithContactsInput = {
  registrationId: Scalars['ID'];
  contactIds: Array<Scalars['ID']>;
};

export type AssociateUnnamedLearnerWithContactsResponseType = {
  __typename?: 'AssociateUnnamedLearnerWithContactsResponseType';
  registrations?: Maybe<Array<Maybe<Registration>>>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type Attempt = {
  __typename?: 'Attempt';
  id: Scalars['ID'];
  registration: Registration;
  content: ExternalContentResult;
  accompanyingDocumentation?: Maybe<Scalars['String']>;
};

export type AttemptMutateResponseType = {
  __typename?: 'AttemptMutateResponseType';
  errors: Array<FieldError>;
  attempt?: Maybe<Attempt>;
};

export type AttemptMutations = {
  __typename?: 'AttemptMutations';
  recordExternalProgress?: Maybe<AttemptMutateResponseType>;
};


export type AttemptMutationsRecordExternalProgressArgs = {
  input?: Maybe<RecordExternalProgressInput>;
};

export type AttemptScormContentInput = {
  registrationId?: Maybe<Scalars['ID']>;
  contentId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Attendance = {
  __typename?: 'Attendance';
  sessionDetail?: Maybe<SessionDetailConnection>;
  sessionsAttended?: Maybe<Scalars['Int']>;
  sessionsMissed?: Maybe<Scalars['Int']>;
  totalSessions?: Maybe<Scalars['Int']>;
};

export type Brand = {
  __typename?: 'Brand';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  branding?: Maybe<Branding>;
  singleSignOnOnly: Scalars['Boolean'];
  welcomeMessage?: Maybe<WelcomeMessage>;
};

export type Branding = {
  __typename?: 'Branding';
  images?: Maybe<BrandingImages>;
  colours?: Maybe<BrandingColours>;
  buttons?: Maybe<BrandingButtons>;
};

export type BrandingButtons = {
  __typename?: 'BrandingButtons';
  borderRadius?: Maybe<Scalars['String']>;
};

export type BrandingColours = {
  __typename?: 'BrandingColours';
  contrastText?: Maybe<Scalars['Colour']>;
  mainText?: Maybe<Scalars['Colour']>;
  navigationBackground?: Maybe<Scalars['Colour']>;
  navigationButtonText?: Maybe<Scalars['Colour']>;
  primary?: Maybe<Scalars['Colour']>;
  primaryActionButton?: Maybe<Scalars['Colour']>;
  primaryActionButtonText?: Maybe<Scalars['Colour']>;
  secondary?: Maybe<Scalars['Colour']>;
  secondaryActionButton?: Maybe<Scalars['Colour']>;
  secondaryActionButtonText?: Maybe<Scalars['Colour']>;
};

export type BrandingImage = {
  __typename?: 'BrandingImage';
  imageUrl?: Maybe<Scalars['String']>;
};

export type BrandingImages = {
  __typename?: 'BrandingImages';
  favicon?: Maybe<BrandingImage>;
};

export type CancellationReason = Node & {
  __typename?: 'CancellationReason';
  id?: Maybe<Scalars['ID']>;
  text?: Maybe<Scalars['String']>;
};

export type CancellationReasonConnection = {
  __typename?: 'CancellationReasonConnection';
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<CancellationReasonEdge>>>;
};

export type CancellationReasonEdge = {
  __typename?: 'CancellationReasonEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CancellationReason>;
};

export enum CancellationReasonField {
  Id = 'id'
}

export type CancellationReasonFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field?: Maybe<CancellationReasonField>;
  operation?: Maybe<FilterOperation>;
};

export type CancellationReasonFieldOrder = {
  field?: Maybe<CancellationReasonField>;
  direction?: Maybe<OrderDirection>;
};

export type CancellationRequest = Node & {
  __typename?: 'CancellationRequest';
  id?: Maybe<Scalars['ID']>;
  registerableId?: Maybe<Scalars['ID']>;
  approvalState?: Maybe<Scalars['String']>;
  learner?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  reasonNotes?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  event?: Maybe<Event>;
};

export type CancellationRequestConnection = {
  __typename?: 'CancellationRequestConnection';
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<CancellationRequestEdge>>>;
};

export type CancellationRequestEdge = {
  __typename?: 'CancellationRequestEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CancellationRequest>;
};

export enum CancellationRequestField {
  Id = 'id',
  ContactId = 'contactId',
  BrandId = 'brandId',
  ApprovalState = 'approvalState'
}

export type CancellationRequestFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field?: Maybe<CancellationRequestField>;
  operation?: Maybe<FilterOperation>;
};

export type CancellationRequestFieldOrder = {
  field?: Maybe<CancellationRequestField>;
  direction?: Maybe<OrderDirection>;
};

export type CancellationRequestMutations = {
  __typename?: 'CancellationRequestMutations';
  create?: Maybe<CreateCancellationRequestMutationResponseType>;
};


export type CancellationRequestMutationsCreateArgs = {
  input?: Maybe<CreateCancellationRequestInputType>;
};

export type CatalogueConnection = {
  __typename?: 'CatalogueConnection';
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<CatalogueEdge>>>;
};

export type CatalogueEdge = {
  __typename?: 'CatalogueEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CatalogueItem>;
};

export enum CatalogueField {
  Id = 'id',
  LearningCategoryId = 'learningCategoryId',
  Type = 'type',
  Name = 'name'
}

export type CatalogueFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field?: Maybe<CatalogueField>;
  operation?: Maybe<FilterOperation>;
};

export type CatalogueFieldOrder = {
  field?: Maybe<CatalogueField>;
  direction?: Maybe<OrderDirection>;
};

export type CatalogueItem = {
  __typename?: 'CatalogueItem';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  code?: Maybe<Scalars['String']>;
  teaserDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  prerequisites?: Maybe<Scalars['String']>;
  topicsCovered?: Maybe<Scalars['String']>;
  faqs?: Maybe<Scalars['String']>;
  outcomes?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};

export type Category = {
  __typename?: 'Category';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  subcategories?: Maybe<SubcategoryConnection>;
};

export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  edges?: Maybe<Array<Maybe<CategoryEdge>>>;
};

export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  node?: Maybe<Category>;
};

export type CertificateType = Node & {
  __typename?: 'CertificateType';
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type Comment = {
  __typename?: 'Comment';
  id: Scalars['ID'];
  comment: Scalars['String'];
  createdAt: Scalars['Datetime'];
  contact: Contact;
};

export type CommentConnection = {
  __typename?: 'CommentConnection';
  pageInfo: PageInfo;
  edges: Array<CommentEdge>;
};

export type CommentEdge = {
  __typename?: 'CommentEdge';
  cursor: Scalars['String'];
  node: Comment;
};

export enum CommentField {
  Comment = 'comment',
  CreatedAt = 'createdAt'
}

export type CommentFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field?: Maybe<CommentField>;
  operation?: Maybe<FilterOperation>;
};

export type CommentFieldOrder = {
  field?: Maybe<CommentField>;
  direction?: Maybe<OrderDirection>;
};

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress?: Maybe<Scalars['String']>;
  jobRole?: Maybe<Scalars['String']>;
  faxPhoneNumber?: Maybe<Scalars['String']>;
  homePhoneNumber?: Maybe<Scalars['String']>;
  mobilePhoneNumber?: Maybe<Scalars['String']>;
  officePhoneNumber?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<PostalAddress>;
  account: Account;
  managedAccounts: AccountConnection;
  registrations: RegistrationConnection;
  learningPathRegistrations: LearningPathRegistrationConnection;
  phoneNumbers?: Maybe<Array<Maybe<ContactPhoneNumber>>>;
  /** A coordinator for any Account */
  isCoordinator: Scalars['Boolean'];
  customFieldValues?: Maybe<Array<Maybe<ContactCustomFieldValue>>>;
  /** A coordinator managing at least one Account the current Viewer manages */
  isOverlappingCoordinator: Scalars['Boolean'];
};


export type ContactManagedAccountsArgs = {
  filters?: Maybe<Array<Maybe<AccountFieldFilter>>>;
  order?: Maybe<AccountFieldOrder>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ContactRegistrationsArgs = {
  filters?: Maybe<Array<Maybe<RegistrationFieldFilter>>>;
  order?: Maybe<RegistrationFieldOrder>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ContactLearningPathRegistrationsArgs = {
  filters?: Maybe<Array<Maybe<LearningPathRegistrationFieldFilter>>>;
  order?: Maybe<LearningPathRegistrationFieldOrder>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ContactConnection = {
  __typename?: 'ContactConnection';
  pageInfo: PageInfo;
  edges: Array<ContactEdge>;
};

export type ContactCoordinatorInput = {
  accountIds?: Maybe<Array<Scalars['ID']>>;
  contactId: Scalars['ID'];
};

export type ContactCreateInput = {
  accountId: Scalars['ID'];
  personalName: PersonalNameInputType;
  jobRole?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<Array<Maybe<ContactPhoneNumberInputType>>>;
  emailAddresses?: Maybe<Array<Maybe<ContactEmailAddressesInputType>>>;
};

export type ContactCustomFieldValue = {
  __typename?: 'ContactCustomFieldValue';
  definitionKey?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ContactEdge = {
  __typename?: 'ContactEdge';
  cursor: Scalars['String'];
  node: Contact;
};

export type ContactEmailAddressesInputType = {
  usage?: Maybe<ContactEmailAddressUsage>;
  address?: Maybe<Scalars['String']>;
};

export enum ContactEmailAddressUsage {
  Primary = 'primary',
  Secondary = 'secondary'
}

export enum ContactField {
  AccountId = 'accountId',
  EmailAddress = 'emailAddress',
  FirstName = 'firstName',
  Id = 'id',
  IsCoordinator = 'isCoordinator',
  LastName = 'lastName',
  Name = 'name',
  IsOverlappingCoordinator = 'isOverlappingCoordinator'
}

export type ContactFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field?: Maybe<ContactField>;
  operation?: Maybe<FilterOperation>;
};

export type ContactFieldOrder = {
  field?: Maybe<ContactField>;
  direction?: Maybe<OrderDirection>;
};

export type ContactMutateResponseType = {
  __typename?: 'ContactMutateResponseType';
  contact?: Maybe<Contact>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type ContactMutations = {
  __typename?: 'ContactMutations';
  update?: Maybe<ContactMutateResponseType>;
  create?: Maybe<ContactMutateResponseType>;
  makeCoordinatorForAccounts?: Maybe<ContactMutateResponseType>;
  revokeCoordinatorForAccounts?: Maybe<ContactMutateResponseType>;
};


export type ContactMutationsUpdateArgs = {
  input?: Maybe<ContactUpdateInput>;
};


export type ContactMutationsCreateArgs = {
  input?: Maybe<ContactCreateInput>;
};


export type ContactMutationsMakeCoordinatorForAccountsArgs = {
  input?: Maybe<ContactCoordinatorInput>;
};


export type ContactMutationsRevokeCoordinatorForAccountsArgs = {
  input?: Maybe<ContactCoordinatorInput>;
};

export type ContactPhoneNumber = {
  __typename?: 'ContactPhoneNumber';
  usage?: Maybe<ContactPhoneNumberUsage>;
  number?: Maybe<Scalars['String']>;
};

export type ContactPhoneNumberInputType = {
  usage?: Maybe<ContactPhoneNumberUsage>;
  number?: Maybe<Scalars['String']>;
};

export enum ContactPhoneNumberUsage {
  Fax = 'fax',
  Home = 'home',
  Mobile = 'mobile',
  Office = 'office'
}

export type ContactUpdateInput = {
  contactId: Scalars['ID'];
  personalName?: Maybe<PersonalNameInputType>;
  accountId?: Maybe<Scalars['ID']>;
  jobRole?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<Array<Maybe<ContactPhoneNumberInputType>>>;
};

export type Content = {
  id?: Maybe<Scalars['ID']>;
  displayName?: Maybe<Scalars['String']>;
  htmlDescription?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in favor of htmlDescription for content of type Discussion, Kryterion, and External. Otherwise in favour of displayName. */
  description?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  autoComplete?: Maybe<Scalars['Boolean']>;
  isPremium?: Maybe<Scalars['Boolean']>;
  includedInPassTypeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ContentAndRegistration = {
  __typename?: 'ContentAndRegistration';
  content?: Maybe<Content>;
  registration?: Maybe<RegistrationDetails>;
};

export type ContentConnection = {
  __typename?: 'ContentConnection';
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<ContentEdge>>>;
};

export type ContentEdge = {
  __typename?: 'ContentEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Content>;
};

export type ContentResult = {
  id?: Maybe<Scalars['ID']>;
  contentId?: Maybe<Scalars['ID']>;
  content?: Maybe<Content>;
  attempts?: Maybe<Scalars['Int']>;
  completedAttempts?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['Decimal']>;
  score?: Maybe<Scalars['Decimal']>;
  scorePercent?: Maybe<Scalars['Decimal']>;
  lastAccessed?: Maybe<Scalars['Datetime']>;
  status?: Maybe<ContentResultStatus>;
};

export type ContentResultConnection = {
  __typename?: 'ContentResultConnection';
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<ContentResultEdge>>>;
};

export type ContentResultEdge = {
  __typename?: 'ContentResultEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ContentResult>;
};

export enum ContentResultField {
  ContentId = 'contentId'
}

export type ContentResultFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field?: Maybe<ContentResultField>;
  operation?: Maybe<FilterOperation>;
};

export type ContentResultFieldOrder = {
  field?: Maybe<ContentResultField>;
  direction?: Maybe<OrderDirection>;
};

export enum ContentResultStatus {
  Completed = 'completed',
  Downloaded = 'downloaded',
  Failed = 'failed',
  Incomplete = 'incomplete',
  Passed = 'passed'
}

export type Country = {
  __typename?: 'Country';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Course = Node & {
  __typename?: 'Course';
  id: Scalars['ID'];
  title: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  learningMode?: Maybe<LearningMode>;
  start?: Maybe<Scalars['Datetime']>;
  startDate?: Maybe<Scalars['Datetime']>;
  end?: Maybe<Scalars['Datetime']>;
  endDate?: Maybe<Scalars['Datetime']>;
  lmsStart?: Maybe<Scalars['Datetime']>;
  lmsEnd?: Maybe<Scalars['Datetime']>;
  imageUrl?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  resources: Array<CourseResource>;
  categories?: Maybe<CategoryConnection>;
  location?: Maybe<Location>;
  remainingPlaces?: Maybe<Scalars['Int']>;
  accessDuration?: Maybe<Scalars['Int']>;
  achievementType?: Maybe<AchievementType>;
  achievementTypes?: Maybe<Array<Maybe<AchievementType>>>;
  sessions?: Maybe<SessionsConnection>;
  content?: Maybe<ContentConnection>;
  documents: Array<DmsDocument>;
  lmsAnnouncements?: Maybe<Scalars['String']>;
  allowedMissedSessions?: Maybe<Scalars['Int']>;
};


export type CourseContentArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  learnerId?: Maybe<Scalars['String']>;
};

export type CourseObjective = Node & LearningObjective & {
  __typename?: 'CourseObjective';
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  credits?: Maybe<Scalars['Int']>;
  expectedCompletionDays?: Maybe<Scalars['Decimal']>;
  course?: Maybe<Course>;
};

export type CourseOutcome = Node & LearningPathResult & {
  __typename?: 'CourseOutcome';
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  credits?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['Boolean']>;
  objectiveId?: Maybe<Scalars['ID']>;
  course?: Maybe<Course>;
  registrationId?: Maybe<Scalars['ID']>;
};

export type CoursePreviewConnection = {
  __typename?: 'CoursePreviewConnection';
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<RegistrationEdge>>>;
};

export type CourseResource = {
  __typename?: 'CourseResource';
  label: Scalars['String'];
  link: Scalars['String'];
};

export type CreateCancellationRequestInputType = {
  learnerId?: Maybe<Scalars['ID']>;
  reasonId?: Maybe<Scalars['ID']>;
  reasonNotes?: Maybe<Scalars['String']>;
};

export type CreateCancellationRequestMutationResponseType = {
  __typename?: 'CreateCancellationRequestMutationResponseType';
  cancellationRequest?: Maybe<CancellationRequest>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type CreateTrainingRequestInputType = {
  trainingRequestItemId?: Maybe<Scalars['ID']>;
  pointOfSaleFieldValues?: Maybe<Array<PointOfSaleFieldValueType>>;
};

export type CustomFieldValueInputType = {
  definitionKey?: Maybe<Scalars['ID']>;
  value?: Maybe<Scalars['String']>;
};



export type DeleteDiscussionCommentInput = {
  commentId: Scalars['ID'];
};

export enum DeliveryMethod {
  Lms = 'lms',
  Classroom = 'classroom',
  Blended = 'blended'
}

export type DiscussionTopic = Node & Content & {
  __typename?: 'DiscussionTopic';
  id: Scalars['ID'];
  displayName?: Maybe<Scalars['String']>;
  htmlDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  type: Scalars['String'];
  comments: CommentConnection;
  autoComplete?: Maybe<Scalars['Boolean']>;
  isPremium?: Maybe<Scalars['Boolean']>;
  includedInPassTypeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type DiscussionTopicCommentsArgs = {
  filters?: Maybe<Array<Maybe<CommentFieldFilter>>>;
  order?: Maybe<CommentFieldOrder>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DmsDocument = {
  __typename?: 'DmsDocument';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated This field does not support values larger than 2 gigabytes. Please use "fileSizeStr". */
  fileSize?: Maybe<Scalars['Int']>;
  fileSizeStr?: Maybe<Scalars['String']>;
  identifyingHash?: Maybe<Scalars['String']>;
};

export type Document = Node & Content & {
  __typename?: 'Document';
  id?: Maybe<Scalars['ID']>;
  displayName?: Maybe<Scalars['String']>;
  htmlDescription?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in favor of htmlDescription */
  description?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in favor of displayName */
  name?: Maybe<Scalars['String']>;
  identifyingHash?: Maybe<Scalars['String']>;
  /** @deprecated This field does not support values larger than 2 gigabytes. Please use "fileSizeStr". */
  fileSize?: Maybe<Scalars['Int']>;
  fileSizeStr?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  originalExtension?: Maybe<Scalars['String']>;
  autoComplete?: Maybe<Scalars['Boolean']>;
  isPremium?: Maybe<Scalars['Boolean']>;
  includedInPassTypeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type DocumentObjective = Node & LearningObjective & {
  __typename?: 'DocumentObjective';
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  credits?: Maybe<Scalars['Int']>;
  expectedCompletionDays?: Maybe<Scalars['Decimal']>;
  document?: Maybe<Document>;
};

export type DocumentOutcome = Node & LearningPathResult & {
  __typename?: 'DocumentOutcome';
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  credits?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['Boolean']>;
  objectiveId?: Maybe<Scalars['ID']>;
  document?: Maybe<Document>;
};

export type EnsureKryterionAccountInput = {
  registrationId?: Maybe<Scalars['ID']>;
  contentId?: Maybe<Scalars['ID']>;
};

export type EnsureKryterionAccountResponseType = {
  __typename?: 'EnsureKryterionAccountResponseType';
  clientMutationId?: Maybe<Scalars['String']>;
  registration?: Maybe<Registration>;
  kryterionAccountExists?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type Event = {
  __typename?: 'Event';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  deliveryMethod?: Maybe<DeliveryMethod>;
  location?: Maybe<Location>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  timeZoneName?: Maybe<Scalars['String']>;
  remainingPlaces?: Maybe<Scalars['Int']>;
  accessDuration?: Maybe<Scalars['Int']>;
  classroomStart?: Maybe<Scalars['String']>;
  classroomEnd?: Maybe<Scalars['String']>;
  lmsStart?: Maybe<Scalars['String']>;
  lmsEnd?: Maybe<Scalars['String']>;
  imageHash?: Maybe<Scalars['String']>;
  lifecycleState?: Maybe<Scalars['String']>;
  documents: Array<DmsDocument>;
  lmsAnnouncements?: Maybe<Scalars['String']>;
};

export type EventConnection = {
  __typename?: 'EventConnection';
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<EventEdge>>>;
};

export type EventEdge = {
  __typename?: 'EventEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Event>;
};

export enum EventField {
  Id = 'id',
  Title = 'title',
  CourseCode = 'courseCode',
  Start = 'start',
  RegisterBy = 'registerBy',
  TimeZonedStart = 'timeZonedStart',
  LifecycleState = 'lifecycleState'
}

export type EventFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field?: Maybe<EventField>;
  operation?: Maybe<FilterOperation>;
};

export type EventFieldOrder = {
  field?: Maybe<EventField>;
  direction?: Maybe<OrderDirection>;
};

export type ExternalActivity = Node & Content & {
  __typename?: 'ExternalActivity';
  id?: Maybe<Scalars['ID']>;
  displayName?: Maybe<Scalars['String']>;
  htmlDescription?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in favor of displayName */
  description?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in favor of htmlDescription */
  externalActivityInstructions?: Maybe<Scalars['String']>;
  externalActivityUrl?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  autoComplete?: Maybe<Scalars['Boolean']>;
  isPremium?: Maybe<Scalars['Boolean']>;
  includedInPassTypeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export enum ExternalActivityStatus {
  Completed = 'completed',
  Passed = 'passed',
  Failed = 'failed'
}

export type ExternalAttempt = Node & {
  __typename?: 'ExternalAttempt';
  id?: Maybe<Scalars['ID']>;
  accompanyingDocumentation?: Maybe<Scalars['String']>;
};

export type ExternalContentResult = Node & ContentResult & {
  __typename?: 'ExternalContentResult';
  id?: Maybe<Scalars['ID']>;
  contentId?: Maybe<Scalars['ID']>;
  content?: Maybe<Content>;
  attempts?: Maybe<Scalars['Int']>;
  completedAttempts?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['Decimal']>;
  score?: Maybe<Scalars['Decimal']>;
  scorePercent?: Maybe<Scalars['Decimal']>;
  lastAccessed?: Maybe<Scalars['Datetime']>;
  status?: Maybe<ContentResultStatus>;
  latestAttempt?: Maybe<ExternalAttempt>;
};

export type ExternalLink = {
  __typename?: 'ExternalLink';
  displayText?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
  iconName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

export type ExternalObjective = Node & LearningObjective & {
  __typename?: 'ExternalObjective';
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  credits?: Maybe<Scalars['Int']>;
  expectedCompletionDays?: Maybe<Scalars['Decimal']>;
  external?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ExternalOutcome = Node & LearningPathResult & {
  __typename?: 'ExternalOutcome';
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  credits?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['Boolean']>;
  objectiveId?: Maybe<Scalars['ID']>;
  external?: Maybe<Scalars['String']>;
};

export type FieldError = {
  __typename?: 'FieldError';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum FilterOperation {
  /** Field must be equal to value. */
  Eq = 'eq',
  /** Field must be greater than or equal to value. */
  Ge = 'ge',
  /** Field must be greater than value. */
  Gt = 'gt',
  /** Field must be like value, % is any String. */
  Like = 'like',
  /** Field must be less than or equal to value. */
  Le = 'le',
  /** Field must be less than value. */
  Lt = 'lt',
  /** Field must not equal value. */
  Ne = 'ne'
}

export type FulfilObjectiveInput = {
  eventId?: Maybe<Scalars['ID']>;
  learningPathRegistrationId?: Maybe<Scalars['ID']>;
  objectiveId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type IdentityProvider = {
  __typename?: 'IdentityProvider';
  name?: Maybe<Scalars['String']>;
  singleSignOnInitiateEndpoint?: Maybe<Scalars['String']>;
  signOutUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type IdentityProviderConnection = {
  __typename?: 'IdentityProviderConnection';
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<IdentityProviderEdge>>>;
};

export type IdentityProviderEdge = {
  __typename?: 'IdentityProviderEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<IdentityProvider>;
};

export type KryterionActivity = Node & Content & {
  __typename?: 'KryterionActivity';
  id?: Maybe<Scalars['ID']>;
  displayName?: Maybe<Scalars['String']>;
  htmlDescription?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in favor of displayName */
  description?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in favor of htmlDescription */
  instructions?: Maybe<Scalars['String']>;
  kryterionUrl?: Maybe<Scalars['String']>;
  kryterionCourseId?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  autoComplete?: Maybe<Scalars['Boolean']>;
  isPremium?: Maybe<Scalars['Boolean']>;
  includedInPassTypeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export enum LearningMode {
  /** Blended */
  Blended = 'blended',
  /** Classroom */
  Classroom = 'classroom',
  /** Self-paced */
  SelfPaced = 'selfPaced'
}

export type LearningObjective = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  credits?: Maybe<Scalars['Int']>;
  expectedCompletionDays?: Maybe<Scalars['Decimal']>;
};

export type LearningObjectiveConnection = {
  __typename?: 'LearningObjectiveConnection';
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<LearningObjectiveEdge>>>;
};

export type LearningObjectiveEdge = {
  __typename?: 'LearningObjectiveEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<LearningObjective>;
};

export enum LearningObjectiveField {
  Id = 'id'
}

export type LearningObjectiveFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field?: Maybe<LearningObjectiveField>;
  operation?: Maybe<FilterOperation>;
};

export type LearningObjectiveFieldOrder = {
  field?: Maybe<LearningObjectiveField>;
  direction?: Maybe<OrderDirection>;
};

export type LearningPath = Node & {
  __typename?: 'LearningPath';
  id: Scalars['ID'];
  longDescription?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  start?: Maybe<Scalars['Datetime']>;
  end?: Maybe<Scalars['Datetime']>;
  imageUrl?: Maybe<Scalars['String']>;
  achievementType?: Maybe<AchievementType>;
  enforceObjectiveOrder: Scalars['Boolean'];
  objectives?: Maybe<LearningObjectiveConnection>;
};


export type LearningPathObjectivesArgs = {
  filters?: Maybe<Array<Maybe<LearningObjectiveFieldFilter>>>;
  order?: Maybe<LearningObjectiveFieldOrder>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum LearningPathLearnerLifecycleState {
  Active = 'active',
  Cancelled = 'cancelled',
  Completed = 'completed'
}

export type LearningPathObjective = Node & LearningObjective & {
  __typename?: 'LearningPathObjective';
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  credits?: Maybe<Scalars['Int']>;
  expectedCompletionDays?: Maybe<Scalars['Decimal']>;
  learningPath?: Maybe<LearningPath>;
};

export type LearningPathOutcome = Node & LearningPathResult & {
  __typename?: 'LearningPathOutcome';
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  credits?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['Boolean']>;
  objectiveId?: Maybe<Scalars['ID']>;
  learningPath?: Maybe<LearningPath>;
};

export type LearningPathRegistration = Node & Registerable & {
  __typename?: 'LearningPathRegistration';
  id: Scalars['ID'];
  contactId?: Maybe<Scalars['ID']>;
  trackingIdentifier?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  passAccessExpired: Scalars['Boolean'];
  trainingPassTypeId?: Maybe<Scalars['ID']>;
  brandId?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  expectedProgress?: Maybe<Scalars['Decimal']>;
  lifecycleState: LearningPathLearnerLifecycleState;
  progressMade?: Maybe<Scalars['Decimal']>;
  startDate?: Maybe<Scalars['Datetime']>;
  learningPath?: Maybe<LearningPath>;
  lastAccessed?: Maybe<Scalars['Datetime']>;
  achievement?: Maybe<Achievement>;
  results?: Maybe<LearningPathResultConnection>;
};


export type LearningPathRegistrationResultsArgs = {
  filters?: Maybe<Array<Maybe<LearningPathResultFieldFilter>>>;
  order?: Maybe<LearningPathResultFieldOrder>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type LearningPathRegistrationConnection = {
  __typename?: 'LearningPathRegistrationConnection';
  pageInfo: PageInfo;
  edges: Array<LearningPathRegistrationEdge>;
};

export type LearningPathRegistrationEdge = {
  __typename?: 'LearningPathRegistrationEdge';
  cursor: Scalars['String'];
  node: LearningPathRegistration;
};

export enum LearningPathRegistrationField {
  Id = 'id',
  IsActive = 'isActive',
  IsComplete = 'isComplete',
  ContactId = 'contactId',
  ManagedContactId = 'managedContactId',
  BrandId = 'brandId',
  Title = 'title',
  LastAccessed = 'lastAccessed',
  HasParent = 'hasParent'
}

export type LearningPathRegistrationFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field: LearningPathRegistrationField;
  operation: FilterOperation;
};

export type LearningPathRegistrationFieldOrder = {
  field: LearningPathRegistrationField;
  direction: OrderDirection;
};

export type LearningPathResult = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  credits?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['Boolean']>;
  objectiveId?: Maybe<Scalars['ID']>;
};

export type LearningPathResultConnection = {
  __typename?: 'LearningPathResultConnection';
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<LearningPathResultEdge>>>;
};

export type LearningPathResultEdge = {
  __typename?: 'LearningPathResultEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<LearningPathResult>;
};

export enum LearningPathResultField {
  Completed = 'completed'
}

export type LearningPathResultFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field?: Maybe<LearningPathResultField>;
  operation?: Maybe<FilterOperation>;
};

export type LearningPathResultFieldOrder = {
  field?: Maybe<LearningPathResultField>;
  direction?: Maybe<OrderDirection>;
};

export type Location = Node & {
  __typename?: 'Location';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** A POS Custom Field that is mapped to a specific Entity's Field. */
export type MappedField = {
  __typename?: 'MappedField';
  entityType: MappedFieldEntityType;
  field: Scalars['String'];
};

export enum MappedFieldEntityType {
  /** Maps to a field on the Contact entity */
  Contact = 'contact',
  /** Maps to a custom field on the Contact entity */
  CustomContact = 'customContact',
  /** Maps to a custom field on the Learner entity */
  Learner = 'learner',
  /** Maps to a custom field on the Learning Path Learner entity */
  PathLearner = 'pathLearner'
}

export type MappedFieldInput = {
  entityType: MappedFieldEntityType;
  /** Can be omitted for contact fields, but required for learner or pathLearner fields. */
  entityId?: Maybe<Scalars['ID']>;
  fieldKey?: Maybe<Scalars['String']>;
};

/** A Mapped Field value to submit for a learner into the specific Entity's Custom Field. */
export type MappedFieldValueInput = {
  mappedField: MappedFieldInput;
  fieldValue: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  attempt?: Maybe<AttemptMutations>;
  registration?: Maybe<RegistrationMutations>;
  registerable?: Maybe<RegisterableMutations>;
  viewer?: Maybe<ViewerMutations>;
  viewerCredentials?: Maybe<ViewerCredentialsMutations>;
  trainingRequest?: Maybe<TrainingRequestMutations>;
  cancellationRequest?: Maybe<CancellationRequestMutations>;
  contact?: Maybe<ContactMutations>;
};

/** An entity with an ID */
export type Node = {
  /** The id of the node */
  id?: Maybe<Scalars['ID']>;
};

export type OpportunityStep = {
  __typename?: 'OpportunityStep';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export enum OrderDirection {
  /** Ascending */
  Asc = 'asc',
  /** Descending */
  Desc = 'desc'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** If there are more items when paginating forwards. */
  hasNextPage: Scalars['Boolean'];
  /** If there are more items when paginating backwards. */
  hasPreviousPage: Scalars['Boolean'];
  /** The cursor to continue paginating forwards. */
  startCursor: Scalars['String'];
  /** The cursor to continue paginating backwards. */
  endCursor: Scalars['String'];
  /** The total number of records available for pagination. */
  totalRecords: Scalars['Int'];
};

export type PaymentMethodType = {
  __typename?: 'PaymentMethodType';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PersonalNameInputType = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export enum PointOfSaleField {
  IsValidForCourseTemplate = 'isValidForCourseTemplate'
}

export enum PointOfSaleFieldEntityType {
  EventRegistration = 'EventRegistration',
  PathRegistration = 'PathRegistration'
}

export type PointOfSaleFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field?: Maybe<PointOfSaleField>;
  operation?: Maybe<FilterOperation>;
};

export type PointOfSaleFieldValueType = {
  definitionKey: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type PointOfSaleLearnerFieldDefinition = {
  __typename?: 'PointOfSaleLearnerFieldDefinition';
  label: Scalars['String'];
  key: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  isRequired: Scalars['Boolean'];
  section?: Maybe<Scalars['String']>;
  options?: Maybe<Array<PointOfSaleLearnerFieldDefinitionOption>>;
  mappedField?: Maybe<MappedField>;
};

export type PointOfSaleLearnerFieldDefinitionOption = {
  __typename?: 'PointOfSaleLearnerFieldDefinitionOption';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type Portal = {
  __typename?: 'Portal';
  identityProviders?: Maybe<IdentityProviderConnection>;
  singleSignOnOnly?: Maybe<Scalars['Boolean']>;
  profileSettings: ProfileSettings;
  emptyPortalRedirectUrl?: Maybe<Scalars['String']>;
  externalLinks?: Maybe<Array<Maybe<ExternalLink>>>;
  linkedWebLinkPortals?: Maybe<Array<Maybe<WebLinkPortal>>>;
  termsAndConditions?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand>;
  isBookingPortal?: Maybe<Scalars['Boolean']>;
  isApprovalPortal?: Maybe<Scalars['Boolean']>;
  isSelfRegistrationPortal: Scalars['Boolean'];
};

export type PortalCustomFieldTemplate = {
  __typename?: 'PortalCustomFieldTemplate';
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<PortalCustomFieldTemplateOption>>>;
  type?: Maybe<Scalars['String']>;
};

export type PortalCustomFieldTemplateOption = {
  __typename?: 'PortalCustomFieldTemplateOption';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PostalAddress = {
  __typename?: 'PostalAddress';
  streetAddress1?: Maybe<Scalars['String']>;
  streetAddress2?: Maybe<Scalars['String']>;
  streetAddress3?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
};

export type ProfileSettings = {
  __typename?: 'ProfileSettings';
  allowEditName: Scalars['Boolean'];
  allowEditJobTitle: Scalars['Boolean'];
  showJobTitle: Scalars['Boolean'];
  allowEditEmail: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  hello?: Maybe<Scalars['String']>;
  coursePreview?: Maybe<CoursePreviewConnection>;
  achievements: AchievementConnection;
  achievementsCount?: Maybe<AchievementsCount>;
  trainingPassTypes?: Maybe<TrainingPassTypeConnection>;
  trainingRequests?: Maybe<TrainingRequestConnection>;
  portalCustomFieldTemplates?: Maybe<Array<Maybe<PortalCustomFieldTemplate>>>;
  registerables?: Maybe<RegisterableConnection>;
  registerablesCount?: Maybe<RegisterablesCount>;
  registrations?: Maybe<RegistrationConnection>;
  registrationsWithProgress?: Maybe<RegistrationWithProgressConnection>;
  events?: Maybe<EventConnection>;
  viewer?: Maybe<Viewer>;
  portalForHost?: Maybe<Portal>;
  categories?: Maybe<CategoryConnection>;
  catalogue?: Maybe<CatalogueConnection>;
  cancellationReasons?: Maybe<CancellationReasonConnection>;
  cancellationRequests?: Maybe<CancellationRequestConnection>;
  coordinatorManagedAccounts: AccountConnection;
  coordinatorManagedContacts: ContactConnection;
  pointOfSaleLearnerFields?: Maybe<Array<PointOfSaleLearnerFieldDefinition>>;
  contentAndRegistrationDetails?: Maybe<ContentAndRegistration>;
  trainingTokenIssues?: Maybe<TrainingTokenIssueConnection>;
  tokenIssueTransactions?: Maybe<TokenIssueTransactions>;
};


export type QueryCoursePreviewArgs = {
  jwt?: Maybe<Scalars['String']>;
};


export type QueryAchievementsArgs = {
  filters?: Maybe<Array<Maybe<AchievementFieldFilter>>>;
  order?: Maybe<AchievementFieldOrder>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAchievementsCountArgs = {
  filters?: Maybe<Array<Maybe<AchievementFieldFilter>>>;
};


export type QueryTrainingRequestsArgs = {
  filters?: Maybe<Array<Maybe<TrainingRequestFieldFilter>>>;
  order?: Maybe<TrainingRequestFieldOrder>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryPortalCustomFieldTemplatesArgs = {
  type?: Maybe<SupportsCustomFieldPortalEntities>;
};


export type QueryRegisterablesArgs = {
  filters?: Maybe<Array<Maybe<RegisterableFieldFilter>>>;
  order?: Maybe<RegisterableFieldOrder>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryRegisterablesCountArgs = {
  filters?: Maybe<Array<Maybe<RegisterableFieldFilter>>>;
};


export type QueryRegistrationsArgs = {
  filters?: Maybe<Array<Maybe<RegistrationFieldFilter>>>;
  order?: Maybe<RegistrationFieldOrder>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryRegistrationsWithProgressArgs = {
  filters?: Maybe<Array<Maybe<RegistrationFieldFilter>>>;
  order?: Maybe<RegistrationFieldOrder>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryEventsArgs = {
  filters?: Maybe<Array<Maybe<EventFieldFilter>>>;
  order?: Maybe<EventFieldOrder>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryPortalForHostArgs = {
  host?: Maybe<Scalars['URL']>;
};


export type QueryCatalogueArgs = {
  filters?: Maybe<Array<Maybe<CatalogueFieldFilter>>>;
  order?: Maybe<CatalogueFieldOrder>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryCancellationReasonsArgs = {
  filters?: Maybe<Array<Maybe<CancellationReasonFieldFilter>>>;
  order?: Maybe<CancellationReasonFieldOrder>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryCancellationRequestsArgs = {
  filters?: Maybe<Array<Maybe<CancellationRequestFieldFilter>>>;
  order?: Maybe<CancellationRequestFieldOrder>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryCoordinatorManagedAccountsArgs = {
  filters?: Maybe<Array<Maybe<AccountFieldFilter>>>;
  order?: Maybe<AccountFieldOrder>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryCoordinatorManagedContactsArgs = {
  filters?: Maybe<Array<Maybe<ContactFieldFilter>>>;
  order?: Maybe<ContactFieldOrder>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryPointOfSaleLearnerFieldsArgs = {
  entityType?: Maybe<PointOfSaleFieldEntityType>;
  filters?: Maybe<Array<Maybe<PointOfSaleFieldFilter>>>;
};


export type QueryContentAndRegistrationDetailsArgs = {
  contentId?: Maybe<Scalars['ID']>;
};


export type QueryTokenIssueTransactionsArgs = {
  tokenIssueId?: Maybe<Scalars['ID']>;
  order?: Maybe<TokenIssueTransactionFieldOrder>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type RecordDiscussionCommentInput = {
  registrationId: Scalars['ID'];
  contentId: Scalars['ID'];
  comment: Scalars['String'];
};

export type RecordExternalContentAccessedInput = {
  registrationId?: Maybe<Scalars['ID']>;
  contentId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RecordExternalProgressInput = {
  attemptId: Scalars['ID'];
  status: ExternalActivityStatus;
  accompanyingDocumentation?: Maybe<Scalars['String']>;
};

export type RecordResourceDownloadInput = {
  registrationId?: Maybe<Scalars['ID']>;
  contentId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RecordScormProgressInput = {
  registrationId?: Maybe<Scalars['ID']>;
  contentId?: Maybe<Scalars['ID']>;
  attemptId?: Maybe<Scalars['ID']>;
  data?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
  sessionFinished?: Maybe<Scalars['Boolean']>;
};

export type RecordVideoProgressInput = {
  registrationId?: Maybe<Scalars['ID']>;
  contentId?: Maybe<Scalars['ID']>;
  data?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Registerable = {
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  contactId?: Maybe<Scalars['ID']>;
  brandId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  lastAccessed?: Maybe<Scalars['Datetime']>;
  achievement?: Maybe<Achievement>;
};

export type RegisterableConnection = {
  __typename?: 'RegisterableConnection';
  pageInfo?: Maybe<PageInfo>;
  edges: Array<Maybe<RegisterableEdge>>;
};

export type RegisterableEdge = {
  __typename?: 'RegisterableEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Registerable>;
};

export enum RegisterableField {
  Id = 'id',
  IsActive = 'isActive',
  PassAccessExpired = 'passAccessExpired',
  IsComplete = 'isComplete',
  ContactId = 'contactId',
  ManagedContactId = 'managedContactId',
  BrandId = 'brandId',
  Title = 'title',
  LastAccessed = 'lastAccessed',
  HasParent = 'hasParent'
}

export type RegisterableFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field?: Maybe<RegisterableField>;
  operation?: Maybe<FilterOperation>;
};

export type RegisterableFieldOrder = {
  field?: Maybe<RegisterableField>;
  direction?: Maybe<OrderDirection>;
};

export type RegisterableMutateResponseType = {
  __typename?: 'RegisterableMutateResponseType';
  clientMutationId?: Maybe<Scalars['String']>;
  registerable?: Maybe<Registerable>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type RegisterableMutations = {
  __typename?: 'RegisterableMutations';
  fulfilObjective?: Maybe<RegisterableMutateResponseType>;
};


export type RegisterableMutationsFulfilObjectiveArgs = {
  input?: Maybe<FulfilObjectiveInput>;
};

export type RegisterablesCount = {
  __typename?: 'RegisterablesCount';
  count?: Maybe<Scalars['Int']>;
};

export type RegisterEventParticipationInput = {
  eventId: Scalars['ID'];
};

export type RegisterEventParticipationResponseType = {
  __typename?: 'RegisterEventParticipationResponseType';
  registration?: Maybe<Registration>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type RegisterLearningPathLearnerInput = {
  learningPathId: Scalars['ID'];
  trainingPassTypeId?: Maybe<Scalars['String']>;
};

export type RegisterLearningPathLearnerResponseType = {
  __typename?: 'RegisterLearningPathLearnerResponseType';
  registration?: Maybe<LearningPathRegistration>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type RegisterPassholderInput = {
  eventId: Scalars['ID'];
  trainingPassId: Scalars['ID'];
};

export type RegisterPassholderResponseType = {
  __typename?: 'RegisterPassholderResponseType';
  registration?: Maybe<Registration>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type Registration = Node & Registerable & {
  __typename?: 'Registration';
  id: Scalars['ID'];
  eventId?: Maybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  passAccessExpired?: Maybe<Scalars['Boolean']>;
  contactId?: Maybe<Scalars['ID']>;
  trackingId?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['Datetime']>;
  totalPiecesOfContent: Scalars['Int'];
  completedPiecesOfContent: Scalars['Int'];
  hasPassed?: Maybe<Scalars['Boolean']>;
  type: Scalars['String'];
  course?: Maybe<Course>;
  lastAccessed?: Maybe<Scalars['Datetime']>;
  achievement?: Maybe<Achievement>;
  achievements?: Maybe<Array<Maybe<Achievement>>>;
  quantity: Scalars['Int'];
  contentResults?: Maybe<ContentResultConnection>;
  learningPathRegistration?: Maybe<LearningPathRegistration>;
  attendance?: Maybe<Attendance>;
  trainingPassTypeId?: Maybe<Scalars['ID']>;
};


export type RegistrationContentResultsArgs = {
  filters?: Maybe<Array<Maybe<ContentResultFieldFilter>>>;
  order?: Maybe<ContentResultFieldOrder>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type RegistrationConnection = {
  __typename?: 'RegistrationConnection';
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<RegistrationEdge>>>;
};

export type RegistrationDetails = {
  __typename?: 'RegistrationDetails';
  id?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['ID']>;
  trackingId?: Maybe<Scalars['String']>;
  isUnnamed?: Maybe<Scalars['Boolean']>;
  contentResult?: Maybe<ContentResult>;
  learningPath?: Maybe<LearningPath>;
};

export type RegistrationEdge = {
  __typename?: 'RegistrationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Registration>;
};

export enum RegistrationField {
  Id = 'id',
  Active = 'active',
  CourseTitle = 'courseTitle',
  CourseLastAccessed = 'courseLastAccessed',
  LastAccessed = 'lastAccessed',
  CourseStart = 'courseStart',
  CourseStartDate = 'courseStartDate',
  CourseEnd = 'courseEnd',
  CourseEndDate = 'courseEndDate',
  CourseLocationName = 'courseLocationName',
  HasParentLearningPathRegistration = 'hasParentLearningPathRegistration',
  IsComplete = 'isComplete',
  CourseId = 'courseId',
  CourseTemplateId = 'courseTemplateId'
}

export type RegistrationFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field?: Maybe<RegistrationField>;
  operation?: Maybe<FilterOperation>;
};

export type RegistrationFieldOrder = {
  field?: Maybe<RegistrationField>;
  direction?: Maybe<OrderDirection>;
};

export type RegistrationMutateResponseType = {
  __typename?: 'RegistrationMutateResponseType';
  clientMutationId?: Maybe<Scalars['String']>;
  registration?: Maybe<Registration>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type RegistrationMutations = {
  __typename?: 'RegistrationMutations';
  recordResourceDownload?: Maybe<RegistrationMutateResponseType>;
  recordVideoProgress?: Maybe<RegistrationMutateResponseType>;
  attemptScormContent?: Maybe<RegistrationMutateResponseType>;
  recordScormProgress?: Maybe<RegistrationMutateResponseType>;
  recordExternalContentAccessed?: Maybe<RegistrationMutateResponseType>;
  associateUnnamedEventLearnerWithContacts?: Maybe<AssociateUnnamedLearnerWithContactsResponseType>;
  registerEventParticipation?: Maybe<RegisterEventParticipationResponseType>;
  registerPassholder?: Maybe<RegisterPassholderResponseType>;
  registerLearningPathLearner?: Maybe<RegisterLearningPathLearnerResponseType>;
  recordDiscussionComment?: Maybe<RegistrationMutateResponseType>;
  deleteDiscussionComment: RegistrationMutateResponseType;
  ensureKryterionAccount: EnsureKryterionAccountResponseType;
  /** @deprecated Deprecated in favor of cart.updateEventLineItem mutation in the Weblink API. Mapped fields can be set in learnerDetails[].attributes. */
  setMappedFields?: Maybe<SetMappedFieldsResponseType>;
};


export type RegistrationMutationsRecordResourceDownloadArgs = {
  input?: Maybe<RecordResourceDownloadInput>;
};


export type RegistrationMutationsRecordVideoProgressArgs = {
  input?: Maybe<RecordVideoProgressInput>;
};


export type RegistrationMutationsAttemptScormContentArgs = {
  input?: Maybe<AttemptScormContentInput>;
};


export type RegistrationMutationsRecordScormProgressArgs = {
  input?: Maybe<RecordScormProgressInput>;
};


export type RegistrationMutationsRecordExternalContentAccessedArgs = {
  input?: Maybe<RecordExternalContentAccessedInput>;
};


export type RegistrationMutationsAssociateUnnamedEventLearnerWithContactsArgs = {
  input: AssociateUnnamedLearnerWithContactsInput;
};


export type RegistrationMutationsRegisterEventParticipationArgs = {
  input: RegisterEventParticipationInput;
};


export type RegistrationMutationsRegisterPassholderArgs = {
  input: RegisterPassholderInput;
};


export type RegistrationMutationsRegisterLearningPathLearnerArgs = {
  input: RegisterLearningPathLearnerInput;
};


export type RegistrationMutationsRecordDiscussionCommentArgs = {
  input?: Maybe<RecordDiscussionCommentInput>;
};


export type RegistrationMutationsDeleteDiscussionCommentArgs = {
  input: DeleteDiscussionCommentInput;
};


export type RegistrationMutationsEnsureKryterionAccountArgs = {
  input?: Maybe<EnsureKryterionAccountInput>;
};


export type RegistrationMutationsSetMappedFieldsArgs = {
  input: SetMappedFieldsInput;
};

export type RegistrationWithProgress = Node & Registerable & {
  __typename?: 'RegistrationWithProgress';
  id: Scalars['ID'];
  eventId?: Maybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  passAccessExpired?: Maybe<Scalars['Boolean']>;
  contactId?: Maybe<Scalars['ID']>;
  trackingId?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['Datetime']>;
  totalPiecesOfContent: Scalars['Int'];
  completedPiecesOfContent: Scalars['Int'];
  hasPassed?: Maybe<Scalars['Boolean']>;
  type: Scalars['String'];
  course?: Maybe<Course>;
  lastAccessed?: Maybe<Scalars['Datetime']>;
  achievement?: Maybe<Achievement>;
  achievements?: Maybe<Array<Maybe<Achievement>>>;
  quantity: Scalars['Int'];
  contentResults?: Maybe<ContentResultConnection>;
  learningPathRegistration?: Maybe<LearningPathRegistration>;
  attendance?: Maybe<Attendance>;
  trainingPassTypeId?: Maybe<Scalars['ID']>;
};

export type RegistrationWithProgressConnection = {
  __typename?: 'RegistrationWithProgressConnection';
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<RegistrationWithProgressEdge>>>;
};

export type RegistrationWithProgressEdge = {
  __typename?: 'RegistrationWithProgressEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<RegistrationWithProgress>;
};

export type ResourceContentResult = Node & ContentResult & {
  __typename?: 'ResourceContentResult';
  id?: Maybe<Scalars['ID']>;
  contentId?: Maybe<Scalars['ID']>;
  content?: Maybe<Content>;
  attempts?: Maybe<Scalars['Int']>;
  completedAttempts?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['Decimal']>;
  score?: Maybe<Scalars['Decimal']>;
  scorePercent?: Maybe<Scalars['Decimal']>;
  lastAccessed?: Maybe<Scalars['Datetime']>;
  status?: Maybe<ContentResultStatus>;
};

export type Scorm = Node & Content & {
  __typename?: 'Scorm';
  id?: Maybe<Scalars['ID']>;
  displayName?: Maybe<Scalars['String']>;
  htmlDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  identifyingHash?: Maybe<Scalars['String']>;
  /** @deprecated This field does not support values larger than 2 gigabytes. Please use "fileSizeStr". */
  fileSize?: Maybe<Scalars['Int']>;
  fileSizeStr?: Maybe<Scalars['String']>;
  version?: Maybe<Version>;
  entrypoint?: Maybe<Scalars['String']>;
  maxAttempts?: Maybe<Scalars['Int']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  autoComplete?: Maybe<Scalars['Boolean']>;
  isPremium?: Maybe<Scalars['Boolean']>;
  includedInPassTypeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ScormAttempt = Node & {
  __typename?: 'ScormAttempt';
  id?: Maybe<Scalars['ID']>;
  data?: Maybe<Scalars['String']>;
};

export type ScormContentResult = Node & ContentResult & {
  __typename?: 'ScormContentResult';
  id?: Maybe<Scalars['ID']>;
  contentId?: Maybe<Scalars['ID']>;
  content?: Maybe<Content>;
  attempts?: Maybe<Scalars['Int']>;
  completedAttempts?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['Decimal']>;
  score?: Maybe<Scalars['Decimal']>;
  scorePercent?: Maybe<Scalars['Decimal']>;
  lastAccessed?: Maybe<Scalars['Datetime']>;
  status?: Maybe<ContentResultStatus>;
  latestAttempt?: Maybe<ScormAttempt>;
};

export type Separator = Node & Content & {
  __typename?: 'Separator';
  id?: Maybe<Scalars['ID']>;
  displayName?: Maybe<Scalars['String']>;
  htmlDescription?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in favor of displayName */
  description?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  autoComplete?: Maybe<Scalars['Boolean']>;
  isPremium?: Maybe<Scalars['Boolean']>;
  includedInPassTypeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Session = {
  __typename?: 'Session';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  timeZonedStart?: Maybe<Scalars['Datetime']>;
  timeZonedEnd?: Maybe<Scalars['Datetime']>;
  location?: Maybe<Location>;
  remoteMeetingUrl?: Maybe<Scalars['String']>;
};

export type SessionDetail = {
  __typename?: 'SessionDetail';
  session?: Maybe<Session>;
  attendanceMark?: Maybe<Scalars['String']>;
};

export type SessionDetailConnection = {
  __typename?: 'SessionDetailConnection';
  edges?: Maybe<Array<Maybe<SessionDetailEdge>>>;
};

export type SessionDetailEdge = {
  __typename?: 'SessionDetailEdge';
  node?: Maybe<SessionDetail>;
};

export type SessionsConnection = {
  __typename?: 'SessionsConnection';
  edges?: Maybe<Array<Maybe<SessionsEdge>>>;
};

export type SessionsEdge = {
  __typename?: 'SessionsEdge';
  node?: Maybe<Session>;
};

/** A list of values to set custom field values for the learner. */
export type SetMappedFieldsInput = {
  mappedFieldValues: Array<Maybe<MappedFieldValueInput>>;
};

/** Returns any validation or mutation errors while setting the mapped fields. */
export type SetMappedFieldsResponseType = {
  __typename?: 'SetMappedFieldsResponseType';
  errors: Array<Maybe<FieldError>>;
};

export type SetPasswordInputType = {
  newPassword?: Maybe<Scalars['String']>;
  currentPassword?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  verifyEmail?: Maybe<Scalars['Boolean']>;
};

export type SingleSignOnError = SingleSignOnResult & {
  __typename?: 'SingleSignOnError';
  success?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
};

export type SingleSignOnResult = {
  success?: Maybe<Scalars['Boolean']>;
};

export type SingleSignOnSuccess = SingleSignOnResult & {
  __typename?: 'SingleSignOnSuccess';
  success?: Maybe<Scalars['Boolean']>;
  portalToken?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  previousPath?: Maybe<Scalars['String']>;
};

export type Subcategory = {
  __typename?: 'Subcategory';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SubcategoryConnection = {
  __typename?: 'SubcategoryConnection';
  edges?: Maybe<Array<Maybe<SubcategoryEdge>>>;
};

export type SubcategoryEdge = {
  __typename?: 'SubcategoryEdge';
  node?: Maybe<Subcategory>;
};

export enum SupportsCustomFieldPortalEntities {
  Viewer = 'Viewer'
}

export type TokenIssueTransaction = {
  __typename?: 'TokenIssueTransaction';
  type?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  balanceDelta?: Maybe<Scalars['Int']>;
  occurredAt?: Maybe<Scalars['Datetime']>;
  registerableTitle?: Maybe<Scalars['String']>;
  tokenReceiptNumber?: Maybe<Scalars['String']>;
};

export enum TokenIssueTransactionField {
  OccurredAt = 'occurredAt'
}

export type TokenIssueTransactionFieldOrder = {
  field?: Maybe<TokenIssueTransactionField>;
  direction?: Maybe<OrderDirection>;
};

export type TokenIssueTransactions = {
  __typename?: 'TokenIssueTransactions';
  transactions?: Maybe<TrainingTokenIssueTransactionConnection>;
};

export type TrainingPass = {
  __typename?: 'TrainingPass';
  id?: Maybe<Scalars['ID']>;
  validFrom?: Maybe<Scalars['Datetime']>;
  validUntil?: Maybe<Scalars['Datetime']>;
  lifecycleState?: Maybe<Scalars['String']>;
  trainingPassTypeId?: Maybe<Scalars['ID']>;
  trainingPassTypeCode?: Maybe<Scalars['String']>;
  trainingPassTypeName?: Maybe<Scalars['String']>;
};

export type TrainingPassType = {
  __typename?: 'TrainingPassType';
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type TrainingPassTypeConnection = {
  __typename?: 'TrainingPassTypeConnection';
  edges?: Maybe<Array<Maybe<TrainingPassTypeEdge>>>;
};

export type TrainingPassTypeEdge = {
  __typename?: 'TrainingPassTypeEdge';
  node?: Maybe<TrainingPassType>;
};

export type TrainingRequest = Node & {
  __typename?: 'TrainingRequest';
  id?: Maybe<Scalars['ID']>;
  registerableId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  stepName?: Maybe<Scalars['String']>;
  stageName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  training?: Maybe<TrainingRequestItem>;
};

export type TrainingRequestConnection = {
  __typename?: 'TrainingRequestConnection';
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<TrainingRequestEdge>>>;
};

export type TrainingRequestEdge = {
  __typename?: 'TrainingRequestEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<TrainingRequest>;
};

export enum TrainingRequestField {
  Id = 'id',
  State = 'state',
  StepName = 'stepName',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CourseTemplateId = 'courseTemplateId'
}

export type TrainingRequestFieldFilter = {
  value?: Maybe<Scalars['String']>;
  field?: Maybe<TrainingRequestField>;
  operation?: Maybe<FilterOperation>;
};

export type TrainingRequestFieldOrder = {
  field?: Maybe<TrainingRequestField>;
  direction?: Maybe<OrderDirection>;
};

export type TrainingRequestItem = {
  __typename?: 'TrainingRequestItem';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Datetime']>;
  end?: Maybe<Scalars['Datetime']>;
  imageUrl?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export type TrainingRequestMutations = {
  __typename?: 'TrainingRequestMutations';
  createTrainingRequest?: Maybe<TrainingRequestMutationsResponseType>;
};


export type TrainingRequestMutationsCreateTrainingRequestArgs = {
  input?: Maybe<CreateTrainingRequestInputType>;
};

export type TrainingRequestMutationsResponseType = {
  __typename?: 'TrainingRequestMutationsResponseType';
  trainingRequest?: Maybe<TrainingRequest>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type TrainingTokenIssue = {
  __typename?: 'TrainingTokenIssue';
  id?: Maybe<Scalars['ID']>;
  initialBalance?: Maybe<Scalars['Int']>;
  currentBalance?: Maybe<Scalars['Int']>;
  redeemed?: Maybe<Scalars['Int']>;
  tokenType?: Maybe<Scalars['String']>;
  tokenTypeId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  expiresAt?: Maybe<Scalars['Datetime']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  paymentCode?: Maybe<Scalars['String']>;
  legacyCode?: Maybe<Scalars['String']>;
};

export type TrainingTokenIssueConnection = {
  __typename?: 'TrainingTokenIssueConnection';
  edges?: Maybe<Array<Maybe<TrainingTokenIssueEdge>>>;
};

export type TrainingTokenIssueEdge = {
  __typename?: 'TrainingTokenIssueEdge';
  node?: Maybe<TrainingTokenIssue>;
};

export type TrainingTokenIssueTransactionConnection = {
  __typename?: 'TrainingTokenIssueTransactionConnection';
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<TrainingTokenIssueTransactionEdge>>>;
};

export type TrainingTokenIssueTransactionEdge = {
  __typename?: 'TrainingTokenIssueTransactionEdge';
  node?: Maybe<TokenIssueTransaction>;
};


export type UrlObjective = Node & LearningObjective & {
  __typename?: 'URLObjective';
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  credits?: Maybe<Scalars['Int']>;
  expectedCompletionDays?: Maybe<Scalars['Decimal']>;
  url?: Maybe<Scalars['String']>;
};

export type UrlOutcome = Node & LearningPathResult & {
  __typename?: 'URLOutcome';
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  credits?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['Boolean']>;
  objectiveId?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
};

export enum Version {
  Scorm12 = 'SCORM12',
  Scorm2004 = 'SCORM2004'
}

export type Video = Node & Content & {
  __typename?: 'Video';
  id?: Maybe<Scalars['ID']>;
  displayName?: Maybe<Scalars['String']>;
  htmlDescription?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in favor of htmlDescription */
  description?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in favor of displayName */
  name?: Maybe<Scalars['String']>;
  wistiaVideoId?: Maybe<Scalars['String']>;
  /** @deprecated This field does not support values larger than 2 gigabytes. Please use "fileSizeStr". */
  fileSize?: Maybe<Scalars['Int']>;
  fileSizeStr?: Maybe<Scalars['String']>;
  autoComplete?: Maybe<Scalars['Boolean']>;
  isPremium?: Maybe<Scalars['Boolean']>;
  includedInPassTypeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type VideoContentResult = Node & ContentResult & {
  __typename?: 'VideoContentResult';
  id?: Maybe<Scalars['ID']>;
  contentId?: Maybe<Scalars['ID']>;
  content?: Maybe<Content>;
  attempts?: Maybe<Scalars['Int']>;
  completedAttempts?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['Decimal']>;
  score?: Maybe<Scalars['Decimal']>;
  scorePercent?: Maybe<Scalars['Decimal']>;
  lastAccessed?: Maybe<Scalars['Datetime']>;
  status?: Maybe<ContentResultStatus>;
  startAt?: Maybe<Scalars['Int']>;
};

export type Viewer = {
  __typename?: 'Viewer';
  id?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  jobRole?: Maybe<Scalars['String']>;
  isCoordinator?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  timeZoneName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  learnerWelcomed?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Scalars['String']>;
  customFieldValues?: Maybe<Array<Maybe<ViewerCustomFieldValue>>>;
  externalIdNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  trainingPasses?: Maybe<Array<Maybe<TrainingPass>>>;
};

export type ViewerCredentials = {
  __typename?: 'ViewerCredentials';
  id?: Maybe<Scalars['ID']>;
};

export type ViewerCredentialsMutations = {
  __typename?: 'ViewerCredentialsMutations';
  setPassword?: Maybe<ViewerCredentialsMutationsResponseType>;
  markLearnerWelcomed?: Maybe<ViewerCredentialsMutationsResponseType>;
};


export type ViewerCredentialsMutationsSetPasswordArgs = {
  input?: Maybe<SetPasswordInputType>;
};

export type ViewerCredentialsMutationsResponseType = {
  __typename?: 'ViewerCredentialsMutationsResponseType';
  errors?: Maybe<Array<Maybe<FieldError>>>;
  viewerCredentials?: Maybe<ViewerCredentials>;
};

export type ViewerCustomFieldValue = {
  __typename?: 'ViewerCustomFieldValue';
  key?: Maybe<Scalars['String']>;
  locator?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ViewerMutateResponseType = {
  __typename?: 'ViewerMutateResponseType';
  viewer?: Maybe<Viewer>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type ViewerMutations = {
  __typename?: 'ViewerMutations';
  update?: Maybe<ViewerMutateResponseType>;
};


export type ViewerMutationsUpdateArgs = {
  input: ViewerUpdateInput;
};

export type ViewerUpdateInput = {
  timeZoneName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  personalName?: Maybe<PersonalNameInputType>;
  jobRole?: Maybe<Scalars['String']>;
  customFieldValues?: Maybe<Array<Maybe<CustomFieldValueInputType>>>;
};

export type WebLinkApprovalPaymentProvider = WebLinkPaymentProvider & {
  __typename?: 'WebLinkApprovalPaymentProvider';
  approvalSignOffSteps?: Maybe<Array<Maybe<OpportunityStep>>>;
  approvalEntryStep?: Maybe<OpportunityStep>;
  requestApprovedStep?: Maybe<OpportunityStep>;
  requestRejectedStep?: Maybe<OpportunityStep>;
  requestOnWaitlistStep?: Maybe<OpportunityStep>;
  paymentMethod?: Maybe<PaymentMethodType>;
};

export type WebLinkInvoicePaymentProvider = WebLinkPaymentProvider & {
  __typename?: 'WebLinkInvoicePaymentProvider';
  name?: Maybe<Scalars['String']>;
  opportunityStep?: Maybe<OpportunityStep>;
  paymentMethod?: Maybe<PaymentMethodType>;
};

export type WebLinkPaymentProvider = {
  paymentMethod?: Maybe<PaymentMethodType>;
};

export type WebLinkPortal = {
  __typename?: 'WebLinkPortal';
  id?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  paymentProviders?: Maybe<Array<Maybe<WebLinkPaymentProvider>>>;
  isSelfRegistrationPortal: Scalars['Boolean'];
  isBookingPortal: Scalars['Boolean'];
};

export type WebLinkStripePaymentProvider = WebLinkPaymentProvider & {
  __typename?: 'WebLinkStripePaymentProvider';
  name?: Maybe<Scalars['String']>;
  stripeSecretKey?: Maybe<Scalars['String']>;
  stripePublicKey?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethodType>;
};

/** Use the Payment Provider from the Linked WebLink Portal instead */
export type WebLinkUnsupportedPaymentProvider = WebLinkPaymentProvider & {
  __typename?: 'WebLinkUnsupportedPaymentProvider';
  name?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethodType>;
};

export type WebLinkWpmPaymentProvider = WebLinkPaymentProvider & {
  __typename?: 'WebLinkWpmPaymentProvider';
  callbackUrl?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pathwayId?: Maybe<Scalars['String']>;
  pathwayUrl?: Maybe<Scalars['String']>;
  sharedKey?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethodType>;
};

export type WelcomeMessage = {
  __typename?: 'WelcomeMessage';
  body?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};
