import { ApolloError } from "apollo-boost";
import { get } from "lodash";
import { FieldError, Maybe } from "../generated/lmsTypes";
import { useCallback, useEffect } from "react";
import { captureException } from "@sentry/browser";

export class MutationErrors extends Error {
  constructor(errors: FieldError[]) {
    super();
    this.errors = errors;
  }

  errors: FieldError[] = [];
}

export enum GraphQLErrorTypes {
  invalidRegistration = "invalid-registration",
}

export const hasErrorType = (
  error: ApolloError,
  errorType: GraphQLErrorTypes,
): boolean => {
  if (!error || !error.graphQLErrors) {
    return false;
  }
  const errorFound = error.graphQLErrors.find(GraphQLError => {
    return GraphQLError.type === errorType;
  });
  return !!errorFound;
};

export const hasGraphqlErrors = (objectToMap: object): boolean => {
  const mutationObject = getMutationObject(objectToMap);
  return hasErrorsInObject(objectToMap) || hasErrorsInObject(mutationObject);
};

function getMutationObject(response: object) {
  const dataObject = get(response, "data");
  if (!dataObject) {
    return {};
  }

  const mutationNameObject = dataObject[Object.keys(dataObject)[0]];
  if (!mutationNameObject) {
    return {};
  }

  const mutationObject = mutationNameObject[Object.keys(mutationNameObject)[0]];
  if (!mutationObject) {
    return {};
  }

  return mutationObject;
}

function hasErrorsInObject(
  objectToMap: object,
): objectToMap is { errors: FieldError[] } {
  const errors = get(objectToMap, "errors", []);
  return errors && Array.isArray(errors) && !!errors.length;
}

export function throwIfErrors<
  T extends { errors?: Maybe<Array<Maybe<FieldError>>> },
>(resp: T) {
  if (hasErrorsInObject(resp)) {
    throw new MutationErrors(resp.errors);
  }

  return resp;
}

export interface ErrorDescription {
  name: string;
  qualifier: string;
  status: string;
  required: boolean;
  fullError?: any;
}

const ERROR_RAISED_EVENT_NAME = "ERROR_RAISED";

declare global {
  interface HTMLElementEventMap {
    [ERROR_RAISED_EVENT_NAME]: CustomEvent<ErrorDescription>;
  }
}

/** Something critical that prevents the LMS from loading we want diagnostic on
 * to report back to the user
 *
 * @param name Friendly short name of what the thing is
 * @param qualifier Long specifier (URL) of exactly what or where the thing is
 * @param status The state or status like has "Failed to Fetch" or has "become unreachable"
 * @param error The raw error object we caught if any
 */
export function addDiagnosticError(
  name: string,
  qualifier: string,
  status: string,
  required: boolean,
  error?: any,
) {
  // ensure sentry has the error
  if (error) {
    captureException(error);
  }

  const errEvent = new CustomEvent<ErrorDescription>(ERROR_RAISED_EVENT_NAME, {
    detail: { name, qualifier, status, required, fullError: error },
  });
  document.body.dispatchEvent(errEvent);
}

/** Helper to call addDiagnosticError in a promise chain
 *
 * @example
 * getThing()
 *   .then(happyPath)
 *   .catch(asDiagnostic("Thing", fullPathToThing, "Failed to Fetch"))
 *
 * @param name Friendly short name of what the thing is
 * @param qualifier Long specifier (URL) of exactly what or where the thing is
 * @param status The state or status like has "Failed to Fetch" or has "become unreachable"
 * @param required If this failing would crash the app
 */
export function asDiagnostic(
  name: string,
  qualifier: string,
  status: string,
  required = true,
) {
  return (error: any) =>
    addDiagnosticError(name, qualifier, status, required, error);
}

export function useOnDiagnosticErrors(
  onError: (diagnostic: ErrorDescription) => void,
) {
  const onNewError = useCallback(
    (e: CustomEvent<ErrorDescription>) => onError(e.detail),
    [onError],
  );

  useEffect(() => {
    document.body.addEventListener(ERROR_RAISED_EVENT_NAME, onNewError, true);

    return () => {
      document.body.removeEventListener(
        ERROR_RAISED_EVENT_NAME,
        onNewError,
        true,
      );
    };
  }, [onNewError]);
}
