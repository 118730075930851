import { TFunction } from "i18next";
import React, { createContext, useContext } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";

export const INVALID_API_ERROR_TO_DISPLAY_ERROR = (
  t: TFunction,
): Record<string, string> => ({
  "An Interest on the Opportunity has a start date after the Token Issue expiry date":
    t("itemStartDateOutsideTokenExpiry"),
  "An Interest on the Opportunity has an end date after the Token Issue expiry date":
    t("itemEndDateOutsideTokenExpiry"),
});

export type InvalidLineItemInfo = Record<string, string>;

export type CartInvalidLineItemsContextType = {
  invalidLineItemInfo: InvalidLineItemInfo;
  setInvalidLineItemInfo: React.Dispatch<
    React.SetStateAction<InvalidLineItemInfo>
  >;
};

export const CartInvalidLineItemsContext =
  createContext<CartInvalidLineItemsContextType | undefined>(undefined);

export const useCartInvalidLineItemsContext = () => {
  const context = useContext(CartInvalidLineItemsContext);
  if (context === undefined) {
    throw new Error(
      "`useCartInvalidLineItemsContext` has been used outside of the CartInvalidLineItems Provider component",
    );
  }
  return context;
};

export const CartInvalidLineItemsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const key = "CART_INVALID_LINE_ITEMS";

  const [invalidLineItemInfo, setInvalidLineItemInfo] =
    useLocalStorage<InvalidLineItemInfo>(key, {});

  return (
    <CartInvalidLineItemsContext.Provider
      value={{
        invalidLineItemInfo,
        setInvalidLineItemInfo,
      }}
    >
      {children}
    </CartInvalidLineItemsContext.Provider>
  );
};
