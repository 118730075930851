import React, { FunctionComponent, useCallback, useEffect } from "react";
import { Button, Card, Col, GridRow } from "@administrate/piston-ux";
import { range, includes } from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ColorOptions } from "@administrate/piston-ux/lib/types";
import { WEBLINK_CATALOG_QUERY } from "../../queries/catalogue";
import { useGlobalStore } from "../../providers/GlobalStore";
import catalogPlaceholderSquare from "../../images/course-placeholder_square.png";
import { useWebLinkQuery } from "../../hooks/weblink";
import {
  Catalogue,
  Query,
  FilterOperation,
  CatalogueField,
} from "../../generated/weblinkTypes";
import { WEBLINK_BASIC_CATEGORY_QUERY } from "../../queries/filters";
import { CatalogArticleHeader } from "./CatalogArticleHeader";
import { CatalogPage } from "./CatalogPage";
import { BookerIntention } from "../Order/BookingPage";
import { useBookerIntention } from "../../hooks/useBookerIntention";
import { CatalogFilter, useCatalogFilters } from "./CatalogFilterContext";
import { TrainingPassContentStatusLabel } from "../../components/TrainingPass";
import { useViewer } from "../../providers/ViewerProvider";
import { useTrainingPassTypes } from "../../hooks/useTrainingPassTypes";
import { TrainingPassType } from "../../generated/lmsTypes";
import { useAnalytics } from "../../providers/AnalyticsProvider";
import { SearchEvent } from "../../analytics/events";

export const BrowseCatalog: FunctionComponent<{
  title: string;
}> = ({ title }) => {
  const { viewer } = useViewer();
  const { filters } = useCatalogFilters();
  const { captureEvent } = useAnalytics();

  const { t } = useTranslation();
  const [bookerIntention] = useBookerIntention();

  const initialAmountToLoad = 12;
  const order = { field: "name", direction: "asc" };
  const graphQLFilters = getFilters(filters);

  const {
    loading: isCatalogLoading,
    data: catalogData,
    fetchMore: fetchMoreCatalog,
  } = useWebLinkQuery<Query>(WEBLINK_CATALOG_QUERY, {
    variables: {
      offset: 0,
      first: initialAmountToLoad,
      filters: graphQLFilters,
      search: filters.search,
      order,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const { data: categoryResponse } = useWebLinkQuery<Query>(
    WEBLINK_BASIC_CATEGORY_QUERY,
  );
  const categoryData = categoryResponse?.categories?.edges || [];
  const passTypes = useTrainingPassTypes();

  const filterPassTypes = (
    passTypes: TrainingPassType[],
    passTypeIds: string[],
  ) => {
    if (passTypeIds.length === 0) {
      return [];
    }
    return passTypes.filter(pt => includes(passTypeIds, pt.id));
  };

  const loadNextPage = useCallback(() => {
    fetchMoreCatalog({
      variables: { offset: catalogData?.catalogue?.edges?.length || 0 },
      updateQuery: (prev: Query, { fetchMoreResult }) => {
        if (!(fetchMoreResult && fetchMoreResult.catalogue)) {
          return prev;
        }

        return {
          ...prev,
          catalogue: {
            ...prev.catalogue,
            pageInfo: fetchMoreResult.catalogue.pageInfo,
            edges: [
              ...prev.catalogue.edges,
              ...fetchMoreResult.catalogue.edges,
            ],
          },
        };
      },
    });
  }, [fetchMoreCatalog, catalogData]);

  const { convertLMSImageUrl } = useGlobalStore();

  const hasNextPage = catalogData?.catalogue?.pageInfo?.hasNextPage ?? false;

  const catalog =
    catalogData?.catalogue?.edges?.map(({ node: catalogNode }) =>
      parseCatalogItem(catalogNode),
    ) || [];

  const getDefaultTitle = () => {
    if (bookerIntention === BookerIntention.Coordinating) {
      return t("coordinatorCatalog");
    }
    return t("myCatalog");
  };

  useEffect(() => {
    if (isCatalogLoading || !catalogData) return;
    captureEvent(
      SearchEvent.fromCatalogueSearch(
        catalogData.catalogue.pageInfo.totalRecords,
        filters.search,
        graphQLFilters,
      ),
    );
  }, [
    isCatalogLoading,
    catalogData,
    captureEvent,
    filters.search,
    graphQLFilters,
  ]);

  return (
    <CatalogPage tab="browse" title={title || getDefaultTitle()}>
      <CatalogArticleHeader
        loading={isCatalogLoading}
        categories={categoryData}
        trainingPassTypes={passTypes}
      />
      <h2 className="sr-only">{t("results")}</h2>
      <GridRow>
        {catalog.map(catalogItem => {
          const pathBase = catalogItem.isPath ? "learning-path" : "course";
          return (
            <Col
              sm={6}
              md={4}
              lg={3}
              key={`card-${catalogItem.id}`}
              data-label={catalogItem.title}
            >
              <Link
                to={`/catalog/${bookerIntention}/${pathBase}/${catalogItem.id}`}
                className="no-underline"
              >
                <Card
                  title={catalogItem.title}
                  imageSource={convertLMSImageUrl(
                    catalogItem.image || catalogPlaceholderSquare,
                  )}
                  stacked={!!catalogItem.isPath}
                  label={
                    catalogItem.isPath
                      ? {
                          color: ColorOptions.LightGrey,
                          text: t("learningPath"),
                          extraClass: "learning-path-color",
                        }
                      : {
                          color: ColorOptions.Green,
                          text: t("course"),
                          extraClass: "course-color",
                        }
                  }
                  tag={
                    catalogItem.isFeatured
                      ? {
                          text: t("featured"),
                          color: ColorOptions.Yellow,
                        }
                      : undefined
                  }
                >
                  <TrainingPassContentStatusLabel
                    courseCardId={catalogItem.id}
                    learnerPasses={viewer?.trainingPasses ?? []}
                    contentPasses={filterPassTypes(
                      passTypes,
                      catalogItem.contentPassTypeIds,
                    )}
                    hasPricing={(catalogItem.price?.amount ?? null) !== null}
                  />
                </Card>
              </Link>
            </Col>
          );
        })}
      </GridRow>
      {isCatalogLoading && <LoadingPlaceholder />}
      {!isCatalogLoading && hasNextPage && (
        <Button
          label={t("showMore")}
          onClick={loadNextPage}
          id="show-more-catalog-button"
        />
      )}
      {!isCatalogLoading && !hasNextPage && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p className="text-muted">{t("youveReachedTheEnd")}</p>
        </div>
      )}
    </CatalogPage>
  );
};

const getFilters = (affectBarFilters: CatalogFilter) => {
  const filters = [];
  if (affectBarFilters.category) {
    filters.push({
      field: CatalogueField.CategoryId,
      operation: FilterOperation.Eq,
      value: affectBarFilters.category,
    });
  }
  if (affectBarFilters.type) {
    filters.push({
      field: CatalogueField.Type,
      operation: FilterOperation.Eq,
      value: affectBarFilters.type,
    });
  }
  if (affectBarFilters.trainingPassType) {
    filters.push({
      field: CatalogueField.IncludedInTrainingPass,
      operation: FilterOperation.Eq,
      value: affectBarFilters.trainingPassType,
    });
  }
  return filters;
};

const parseCatalogItem = (catalogNode: Catalogue) => ({
  id: catalogNode.id,
  title: catalogNode.name,
  image: catalogNode.imageUrl,
  isPath: catalogNode.__typename === "LearningPath",
  isFeatured: catalogNode.isFeatured,
  contentPassTypeIds: catalogNode?.includedInPassTypeIds ?? [],
  price: parseCatalogItemPrice(catalogNode),
});

const parseCatalogItemPrice = (catalogNode: Catalogue) =>
  catalogNode.__typename === "Course"
    ? { amount: catalogNode.priceRange?.normalPrice?.amount }
    : catalogNode.__typename === "LearningPath"
    ? { amount: catalogNode?.price?.amount }
    : undefined;

const LoadingPlaceholder = () => {
  const { convertLMSImageUrl } = useGlobalStore();
  return (
    <div className="loading">
      <GridRow>
        {range(4).map(i => (
          <Col sm={6} md={4} lg={3} key={`loading-${i}`}>
            <Card
              imageSource={convertLMSImageUrl(catalogPlaceholderSquare)}
              label={{ color: ColorOptions.LightGrey, text: "" }}
            >
              <span className="font-weight-bold placeholder animated placeholder-text-value" />
              <span className="placeholder animated placeholder-h4" />
            </Card>
          </Col>
        ))}
      </GridRow>
    </div>
  );
};
